import React, {useState, useEffect, createContext} from "react";
import {auth} from "../fireAuth/firebase";

export const UserContext = createContext();

export const UserProvider = (props) => {
	const [currentUser, setCurrentUser] =useState(null); // The whole user object from firebase and more if needed from backend
	const [loading, setLoading] =useState(true);
	
	useEffect( () => {
		const unsubscribe =auth.onAuthStateChanged(async userAuth => {
				if(userAuth !== null)
				{
					const response = await fetch(process.env.REACT_APP_FLASK_API+'auth/login',{
						method: 'POST',
						cache: 'no-cache',
						headers: {'Content-Type': 'application/json'},
						body: JSON.stringify({email: userAuth.email})
					});
					const data = await response.json();// Just an option if anything needed to be returned
					// Store usertype
					let newUserAuth ={...userAuth}
					newUserAuth['utype']=data.utype;
					setCurrentUser(newUserAuth); 
					setLoading(false);
				}
				else {
					setCurrentUser(null);
					setLoading(false);
				}
			});

		return unsubscribe;
	}, []);

	const value ={
		currentUser
	}
	return (
		<UserContext.Provider value={value}>
			{!loading && props.children}
		</UserContext.Provider>
	);
};