export const bhattiVocab = [
	{'slp1': 'aKilaH', 'deva': 'अखिलः', 'def': ['सर्वे']},
	{'slp1': 'agryam', 'deva': 'अग्र्यम्', 'def': ['गुरुं']},
	{'slp1': 'aNgavAn', 'deva': 'अङ्गवान्', 'def': ['साङ्गः']},
	{'slp1': 'atArpsIt', 'deva': 'अतार्प्सीत्', 'def': ['तर्पितवान्']},
	{'slp1': 'aTa', 'deva': 'अथ', 'def': ['अनन्तरम्']},
	{'slp1': 'aDigatAsu', 'deva': 'अधिगतासु', 'def': ['गृहीतासु']},
	{'slp1': 'aDyagAyi', 'deva': 'अध्यगायि', 'def': ['अधीताः']},
	{'slp1': 'aDyavAtsIt', 'deva': 'अध्यवात्सीत्', 'def': ['स्थितवान्']},
	{'slp1': 'aDyavAtsuH', 'deva': 'अध्यवात्सुः', 'def': ['अवात्सुः']},
	{'slp1': 'aDyAsitAm', 'deva': 'अध्यासिताम्', 'def': ['अधिष्ठिताम्']},
	{'slp1': 'aDyAsta', 'deva': 'अध्यास्त', 'def': ['अधिष्ठितवान्']},
	{'slp1': 'aDyEzwa', 'deva': 'अध्यैष्ट', 'def': ['अधीतवान्']},
	{'slp1': 'aDvare', 'deva': 'अध्वरे', 'def': ['यज्ञे']},
	{'slp1': 'anIcEH', 'deva': 'अनीचैः', 'def': ['उच्चैः']},
	{'slp1': 'anumene', 'deva': 'अनुमेने', 'def': ['अङ्गीचकार']},
	{'slp1': 'abudDAH', 'deva': 'अबुद्धाः', 'def': ['अज्ञासीः']},
	{'slp1': 'aBitaH', 'deva': 'अभितः', 'def': ['उभयतः']},
	{'slp1': 'aBinizpatantyaH', 'deva': 'अभिनिष्पतन्त्यः', 'def': ['बहिर्निष्पतन्त्यः']},
	{'slp1': 'aBimataPalaSaMsI', 'deva': 'अभिमतफलशंसी', 'def': ['इष्टार्थसिद्धिसूचकः सन्']},
	{'slp1': 'aBireme', 'deva': 'अभिरेमे', 'def': ['विजहार']},
	{'slp1': 'aBUt', 'deva': 'अभूत्', 'def': ['सत्तामलब्ध', 'जातः']},
	{'slp1': 'aByagAt', 'deva': 'अभ्यगात्', 'def': ['आपत्']},
	{'slp1': 'aByarcya', 'deva': 'अभ्यर्च्य', 'def': ['संपूज्य']},
	{'slp1': 'aByAhatakarmavfttiH', 'deva': 'अभ्याहतकर्मवृत्तिः', 'def': ['विहतानुष्ठानप्रवृत्तिः']},
	{'slp1': 'amArgIt', 'deva': 'अमार्गीत्', 'def': ['प्रार्थितवान्']},
	{'slp1': 'ayazwa', 'deva': 'अयष्ट', 'def': ['यागैः पूजितवान्']},
	{'slp1': 'ayAkzIt', 'deva': 'अयाक्षीत्', 'def': ['इष्टवान्']},
	{'slp1': 'ayogye', 'deva': 'अयोग्ये', 'def': ['असमर्थे']},
	{'slp1': 'araMsta', 'deva': 'अरंस्त', 'def': ['विस्रब्धं व्यवहृतवान्']},
	{'slp1': 'arIn', 'deva': 'अरीन्', 'def': ['बाह्यशत्रून्']},
	{'slp1': 'arudan', 'deva': 'अरुदन्', 'def': ['अश्रूणि मुमुचुः']},
	{'slp1': 'avAdIt', 'deva': 'अवादीत्', 'def': ['प्रत्यवादीत्', 'कुशलमप्राक्षीत्']},
	{'slp1': 'avAluloce', 'deva': 'अवालुलोचे', 'def': ['आलोचितवान्']},
	{'slp1': 'asahyam', 'deva': 'असह्यम्', 'def': ['दुःसहं']},
	{'slp1': 'asAvi', 'deva': 'असावि', 'def': ['प्रसूतः']},
	{'slp1': 'ahaMyunA', 'deva': 'अहंयुना', 'def': ['अहंकारयुता']},
	{'slp1': 'AKyat', 'deva': 'आख्यत्', 'def': ['आख्यातवान्']},
	{'slp1': 'AjaGnuH', 'deva': 'आजघ्नुः', 'def': ['ताडयामासुः']},
	{'slp1': 'AnAyi', 'deva': 'आनायि', 'def': ['आनायितः']},
	{'slp1': 'ApTAH', 'deva': 'आप्थाः', 'def': ['प्राप्तो ऽसि']},
	{'slp1': 'Apsmahi', 'deva': 'आप्स्महि', 'def': ['प्राप्ताः स्मः']},
	{'slp1': 'ArcIt', 'deva': 'आर्चीत्', 'def': ['पूजयामास']},
	{'slp1': 'Arjihat', 'deva': 'आर्जिहत्', 'def': ['अपूजयत्']},
	{'slp1': 'ASIrBiH', 'deva': 'आशीर्भिः', 'def': ['आशीवादैः']},
	{'slp1': 'ASramAya', 'deva': 'आश्रमाय', 'def': ['आश्रमं गन्तुम्']},
	{'slp1': 'idDaboDEH', 'deva': 'इद्धबोधैः', 'def': ['दीप्तप्रज्ञानैः']},
	{'slp1': 'iyAya', 'deva': 'इयाय', 'def': ['अनुयात']},
	{'slp1': 'izumati', 'deva': 'इषुमति', 'def': ['प्रशस्तबाणमति']},
	{'slp1': 'uccEH', 'deva': 'उच्चैः', 'def': ['तारं']},
	{'slp1': 'uttamAsu', 'deva': 'उत्तमासु', 'def': ['महत्यवरोधे सत्यपि प्रधानभूतासु']},
	{'slp1': 'udAracezwam', 'deva': 'उदारचेष्टम्', 'def': ['महापराक्रमम्']},
	{'slp1': 'udAravaMSyAH', 'deva': 'उदारवंश्याः', 'def': ['महाकुलप्रसूताः']},
	{'slp1': 'udAhftaH', 'deva': 'उदाहृतः', 'def': ['विख्यातः']},
	{'slp1': 'udejayAn', 'deva': 'उदेजयान्', 'def': ['भीकरान्']},
	{'slp1': 'upAgamat', 'deva': 'उपागमत्', 'def': ['उपागतः']},
	{'slp1': 'upAnezwa', 'deva': 'उपानेष्ट', 'def': ['उपनीतवान्']},
	{'slp1': 'upAyaMsata', 'deva': 'उपायंसत', 'def': ['उपयेमिरे']},
	{'slp1': 'upAsTita', 'deva': 'उपास्थित', 'def': ['असेविष्ट']},
	{'slp1': 'Uce', 'deva': 'ऊचे', 'def': ['उक्तः']},
	{'slp1': 'fSyaSfNgaH', 'deva': 'ऋश्यशृङ्गः', 'def': ['विभाण्डकसुतः']},
	{'slp1': 'EzIH', 'deva': 'ऐषीः', 'def': ['अज्ञासीः']},
	{'slp1': 'Ehizwa', 'deva': 'ऐहिष्ट', 'def': ['ईहितवान्', 'ऐच्छत्']},
	{'slp1': 'kaTam api', 'deva': 'कथम् अपि', 'def': ['महता यत्नेन']},
	{'slp1': 'karmaWaH', 'deva': 'कर्मठः', 'def': ['कर्मशूरः']},
	{'slp1': 'kAle', 'deva': 'काले', 'def': ['योग्यकाले']},
	{'slp1': 'kftAtmA', 'deva': 'कृतात्मा', 'def': ['एवं करिष्यामीति निश्चितबुद्धिः']},
	{'slp1': 'kfTAH', 'deva': 'कृथाः', 'def': ['कुरु']},
	{'slp1': 'kekayItaH', 'deva': 'केकयीतः', 'def': ['कैकेय्याः']},
	{'slp1': 'kratum', 'deva': 'क्रतुम्', 'def': ['इष्टिं']},
	{'slp1': 'kratuzu', 'deva': 'क्रतुषु', 'def': ['यज्ञेषु']},
	{'slp1': 'kriyAvAn', 'deva': 'क्रियावान्', 'def': ['कर्मानुष्ठानवान्']},
	{'slp1': 'kruDyan', 'deva': 'क्रुध्यन्', 'def': ['क्षुभ्यन्']},
	{'slp1': 'kzAtram', 'deva': 'क्षात्रम्', 'def': ['क्षत्रतेजः']},
	{'slp1': 'kzitipaH', 'deva': 'क्षितिपः', 'def': ['राजा']},
	{'slp1': 'gavAkzajAlEH', 'deva': 'गवाक्षजालैः', 'def': ['गवाक्षसमूहैः']},
	{'slp1': 'gADisutaH', 'deva': 'गाधिसुतः', 'def': ['विश्वामित्रः']},
	{'slp1': 'guRinaH', 'deva': 'गुणिनः', 'def': ['गुणाढ्याः']},
	{'slp1': 'guREH', 'deva': 'गुणैः', 'def': ['शौर्यादिभिः']},
	{'slp1': 'gotraBidA', 'deva': 'गोत्रभिदा', 'def': ['इन्द्रेण']},
	{'slp1': 'GAnizyate', 'deva': 'घानिष्यते', 'def': ['हनिष्यते']},
	{'slp1': 'cAru', 'deva': 'चारु', 'def': ['साधु']},
	{'slp1': 'cukuvuH', 'deva': 'चुकुवुः', 'def': ['चुकुजुः']},
	{'slp1': 'jajvAla', 'deva': 'जज्वाल', 'def': ['दिदीपे']},
	{'slp1': 'jiGAMsO', 'deva': 'जिघांसौ', 'def': ['हन्तुम् इच्छौ']},
	{'slp1': 'jizRoH', 'deva': 'जिष्णोः', 'def': ['जयशीलस्य']},
	{'slp1': 'jYAtASayaH', 'deva': 'ज्ञाताशयः', 'def': ['ज्ञातनृपाशयः सन्']},
	{'slp1': 'tanUjAn', 'deva': 'तनूजान्', 'def': ['पुत्रान्']},
	{'slp1': 'tapsyati', 'deva': 'तप्स्यति', 'def': ['तापयिष्यति']},
	{'slp1': 'tApasakuYjareRa', 'deva': 'तापसकुञ्जरेण', 'def': ['पूज्यतापसेन']},
	{'slp1': 'tfReQu', 'deva': 'तृणेढु', 'def': ['हिनस्तु']},
	{'slp1': 'te', 'deva': 'ते', 'def': ['तव']},
	{'slp1': 'tridaSAn', 'deva': 'त्रिदशान्', 'def': ['देवाः']},
	{'slp1': 'tryambakAt', 'deva': 'त्र्यम्बकात्', 'def': ['त्रिलोचनात्']},
	{'slp1': 'daMdaSUkAn', 'deva': 'दंदशूकान्', 'def': ['हिंस्रान् राक्षसान्']},
	{'slp1': 'dvijatvam', 'deva': 'द्विजत्वम्', 'def': ['ब्रह्मतेजः']},
	{'slp1': 'dvijAtIn', 'deva': 'द्विजातीन्', 'def': ['ब्राह्मणान्']},
	{'slp1': 'Dakzyati', 'deva': 'धक्ष्यति', 'def': ['भस्मीकरिष्यति']},
	{'slp1': 'DarmyAsu', 'deva': 'धर्म्यासु', 'def': ['धर्मादनपेतासु']},
	{'slp1': 'DfzRuH', 'deva': 'धृष्णुः', 'def': ['धृष्टः']},
	{'slp1': 'namraH', 'deva': 'नम्रः', 'def': ['प्रणतः']},
	{'slp1': 'nAkasadAm', 'deva': 'नाकसदाम्', 'def': ['दिविषदाम्']},
	{'slp1': 'nirAsTat', 'deva': 'निरास्थत्', 'def': ['निरस्तवान्']},
	{'slp1': 'nirmARadakzasya', 'deva': 'निर्माणदक्षस्य', 'def': ['अपूर्वसृष्टिसमर्थस्य']},
	{'slp1': 'nizWAm', 'deva': 'निष्ठाम्', 'def': ['समाप्तिं']},
	{'slp1': 'nItO', 'deva': 'नीतौ', 'def': ['सामादिसंध्यादिप्रयोगविधौ']},
	{'slp1': 'nfpaH', 'deva': 'नृपः', 'def': ['नराधिपः']},
	{'slp1': 'nyavaDIt', 'deva': 'न्यवधीत्', 'def': ['निहितवान्']},
	{'slp1': 'nyavftat', 'deva': 'न्यवृतत्', 'def': ['न्यवर्त्तिष्ट']},
	{'slp1': 'nyazeDIt', 'deva': 'न्यषेधीत्', 'def': ['उच्चाटयामास']},
	{'slp1': 'nyasyati', 'deva': 'न्यस्यति', 'def': ['निधत्ते']},
	{'slp1': 'parantapaH', 'deva': 'परन्तपः', 'def': ['शत्रुघाती']},
	{'slp1': 'parasparArTam', 'deva': 'परस्परार्थम्', 'def': ['अन्योन्योपकारार्थम्']},
	{'slp1': 'paritaH', 'deva': 'परितः', 'def': ['सर्वतः']},
	{'slp1': 'pariBUH', 'deva': 'परिभूः', 'def': ['अवमन्यस्व']},
	{'slp1': 'parirakzaRArTam', 'deva': 'परिरक्षणार्थम्', 'def': ['यज्ञरक्षणार्थं']},
	{'slp1': 'pitaram', 'deva': 'पितरम्', 'def': ['जनकं']},
	{'slp1': 'puRyaH', 'deva': 'पुण्यः', 'def': ['पावनः']},
	{'slp1': 'puRyavanAya', 'deva': 'पुण्यवनाय', 'def': ['तपोवनाय']},
	{'slp1': 'putrIyatA', 'deva': 'पुत्रीयता', 'def': ['पुत्रकामेन']},
	{'slp1': 'punarjanmajayAya', 'deva': 'पुनर्जन्मजयाय', 'def': ['अपुनर्भवाय']},
	{'slp1': 'purataruRyaH', 'deva': 'पुरतरुण्यः', 'def': ['पौराङ्गनाः']},
	{'slp1': 'purastAt', 'deva': 'पुरस्तात्', 'def': ['अग्रे']},
	{'slp1': 'pusPora', 'deva': 'पुस्फोर', 'def': ['स्फुरितवान्']},
	{'slp1': 'pUrvam', 'deva': 'पूर्वम्', 'def': ['प्रथमम्']},
	{'slp1': 'pfzWataH', 'deva': 'पृष्ठतः', 'def': ['पश्चाद्']},
	{'slp1': 'pratanUni', 'deva': 'प्रतनूनि', 'def': ['सूक्ष्माणि']},
	{'slp1': 'pratasTe', 'deva': 'प्रतस्थे', 'def': ['प्रस्थितः']},
	{'slp1': 'prayAsyataH', 'deva': 'प्रयास्यतः', 'def': ['गमिष्यतः']},
	{'slp1': 'prazWam', 'deva': 'प्रष्ठम्', 'def': ['अग्रगामिनम्']},
	{'slp1': 'prasotum', 'deva': 'प्रसोतुम्', 'def': ['यथायोगं प्रसवितुं']},
	{'slp1': 'prahiRu', 'deva': 'प्रहिणु', 'def': ['प्रेषय']},
	{'slp1': 'prASuH', 'deva': 'प्राशुः', 'def': ['भक्षयामासुः']},
	{'slp1': 'prAsozwa', 'deva': 'प्रासोष्ट', 'def': ['प्रसूतवती']},
	{'slp1': 'banDUn', 'deva': 'बन्धून्', 'def': ['स्वजनान्']},
	{'slp1': 'brahmaBiH', 'deva': 'ब्रह्मभिः', 'def': ['ब्राह्मणैः']},
	{'slp1': 'Bayezu', 'deva': 'भयेषु', 'def': ['आपत्सु']},
	{'slp1': 'BinnavfttIni', 'deva': 'भिन्नवृत्तीनि', 'def': ['भिन्नरुचीनि']},
	{'slp1': 'BuvanahitacCalena', 'deva': 'भुवनहितच्छलेन', 'def': ['रावणवधादिलोकोपकारव्याजेन']},
	{'slp1': 'BUtagaRAn', 'deva': 'भूतगणान्', 'def': ['बालग्रहादिसमूहान्']},
	{'slp1': 'maGonaH', 'deva': 'मघोनः', 'def': ['इन्द्रस्य']},
	{'slp1': 'matAsu', 'deva': 'मतासु', 'def': ['पूजितासु']},
	{'slp1': 'madviDaH', 'deva': 'मद्विधः', 'def': ['मादृशः']},
	{'slp1': 'maDuparkapARiH', 'deva': 'मधुपर्कपाणिः', 'def': ['दधिमधुयुक्तपाणिः']},
	{'slp1': 'manasvI', 'deva': 'मनस्वी', 'def': ['स्थिरचित्तः']},
	{'slp1': 'mahAtman', 'deva': 'महात्मन्', 'def': ['महानुभाव']},
	{'slp1': 'mahendralokapratimAm', 'deva': 'महेन्द्रलोकप्रतिमाम्', 'def': ['अमरावतीकल्पां']},
	{'slp1': 'mA', 'deva': 'मा', 'def': ['माम्']},
	{'slp1': 'mANgalikyaH', 'deva': 'माङ्गलिक्यः', 'def': ['मङ्गलप्रयोजनाः']},
	{'slp1': 'mAnyaH', 'deva': 'मान्यः', 'def': ['लोकपूज्यः']},
	{'slp1': 'mumoha', 'deva': 'मुमोह', 'def': ['मोहं गतः']},
	{'slp1': 'yatiH', 'deva': 'यतिः', 'def': ['जितेन्द्रियः']},
	{'slp1': 'yaTA', 'deva': 'यथा', 'def': ['इव']},
	{'slp1': 'yaminAm', 'deva': 'यमिनाम्', 'def': ['योगिनां']},
	{'slp1': 'yAsyan', 'deva': 'यास्यन्', 'def': ['प्रयास्यन्']},
	{'slp1': 'rakzAMsi', 'deva': 'रक्षांसि', 'def': ['राक्षसान्', 'राक्षसाः']},
	{'slp1': 'raGusiMhe', 'deva': 'रघुसिंहे', 'def': ['रघुश्रेष्ठे']},
	{'slp1': 'rUpAdiboDAt', 'deva': 'रूपादिबोधात्', 'def': ['रूपादिविषयज्ञानात्']},
	{'slp1': 'rocizRumuKasya', 'deva': 'रोचिष्णुमुखस्य', 'def': ['रोचमानमुखस्य']},
	{'slp1': 'vacaH', 'deva': 'वचः', 'def': ['वाचम्']},
	{'slp1': 'varam', 'deva': 'वरम्', 'def': ['श्रेष्ठं']},
	{'slp1': 'varizWaH', 'deva': 'वरिष्ठः', 'def': ['श्रेष्ठः']},
	{'slp1': 'varItum', 'deva': 'वरीतुम्', 'def': ['स्वीकर्त्तुम्']},
	{'slp1': 'vareRyaH', 'deva': 'वरेण्यः', 'def': ['वरणीयः', 'श्रेष्ठः']},
	{'slp1': 'vasizWaH', 'deva': 'वसिष्ठः', 'def': ['पुरोधाः']},
	{'slp1': 'vasUni', 'deva': 'वसूनि', 'def': ['धनानि']},
	{'slp1': 'viGnanti', 'deva': 'विघ्नन्ति', 'def': ['प्रतिबध्नन्ति']},
	{'slp1': 'vidvAn', 'deva': 'विद्वान्', 'def': ['विपश्चित्', 'शास्त्रार्थनिर्णेता', 'विधिज्ञः']},
	{'slp1': 'vipaktrimajYAnaniDiH', 'deva': 'विपक्त्रिमज्ञाननिधिः', 'def': ['तत्त्ववेत्ता']},
	{'slp1': 'vipakzaH', 'deva': 'विपक्षः', 'def': ['शत्रुः']},
	{'slp1': 'vibuDasaKaH', 'deva': 'विबुधसखः', 'def': ['विद्वत्सेवी देवेन्द्रसहचरः']},
	{'slp1': 'vihasya', 'deva': 'विहस्य', 'def': ['हसित्वा']},
	{'slp1': 'vihitrime', 'deva': 'विहित्रिमे', 'def': ['विधिनिर्वृत्ते', 'विहिते']},
	{'slp1': 'vedAn', 'deva': 'वेदान्', 'def': ['ऋग्यजुःसामानि']},
	{'slp1': 'vyajezwa', 'deva': 'व्यजेष्ट', 'def': ['विजितवान्']},
	{'slp1': 'vyatAnIt', 'deva': 'व्यतानीत्', 'def': ['विततान']},
	{'slp1': 'vyatArIt', 'deva': 'व्यतारीत्', 'def': ['वितीर्णवान्']},
	{'slp1': 'vrajati', 'deva': 'व्रजति', 'def': ['पुरा निर्गच्छति सति']},
	{'slp1': 'SaNkAm', 'deva': 'शङ्काम्', 'def': ['अनर्थोत्प्रेक्षां']},
	{'slp1': 'SatamanyukalpaH', 'deva': 'शतमन्युकल्पः', 'def': ['शतक्रतूपमः']},
	{'slp1': 'SaraRam', 'deva': 'शरणम्', 'def': ['रक्षिता']},
	{'slp1': 'SirAMsi', 'deva': 'शिरांसि', 'def': ['शिखराणि']},
	{'slp1': 'Sivam', 'deva': 'शिवम्', 'def': ['अक्षतम्', 'अक्षतिम्']},
	{'slp1': 'SuBaMyuH', 'deva': 'शुभंयुः', 'def': ['शुभवान्']},
	{'slp1': 'SuSruvAn', 'deva': 'शुश्रुवान्', 'def': ['संश्रुतवान्']},
	{'slp1': 'SrutAnvitaH', 'deva': 'श्रुतान्वितः', 'def': ['विद्वान्']},
	{'slp1': 'Sreyasi', 'deva': 'श्रेयसि', 'def': ['हितकृत्ये']},
	{'slp1': 'zaqvargam', 'deva': 'षड्वर्गम्', 'def': ['कामक्रोधाद्यरिषड्वर्गः']},
	{'slp1': 'sakAnanAni', 'deva': 'सकाननानि', 'def': ['सोपवनानि']},
	{'slp1': 'saDryaN', 'deva': 'सध्र्यङ्', 'def': ['सहचरः', 'सहायः']},
	{'slp1': 'sanAtanaH', 'deva': 'सनातनः', 'def': ['नित्यः पुराणपुरुषः']},
	{'slp1': 'samanyum', 'deva': 'समन्युम्', 'def': ['सशोकम्']},
	{'slp1': 'samam', 'deva': 'समम्', 'def': ['अविषमम्']},
	{'slp1': 'samamaMsta', 'deva': 'सममंस्त', 'def': ['दानसंमानादिभिः सत्कृतवान्']},
	{'slp1': 'samADeH', 'deva': 'समाधेः', 'def': ['ध्यानस्य']},
	{'slp1': 'samIhitezu', 'deva': 'समीहितेषु', 'def': ['संकल्पितार्थेषु']},
	{'slp1': 'samfdDyA', 'deva': 'समृद्ध्या', 'def': ['संपदा']},
	{'slp1': 'sarvezuBftAm', 'deva': 'सर्वेषुभृताम्', 'def': ['धनुर्भृतां']},
	{'slp1': 'sImA', 'deva': 'सीमा', 'def': ['परमावधिः']},
	{'slp1': 'suKena', 'deva': 'सुखेन', 'def': ['अक्लेशेन']},
	{'slp1': 'sutasaMpade', 'deva': 'सुतसंपदे', 'def': ['पुत्रलाभार्थं']},
	{'slp1': 'sutAn', 'deva': 'सुतान्', 'def': ['कुमारान्']},
	{'slp1': 'sutAnubanDam', 'deva': 'सुतानुबन्धम्', 'def': ['सन्तनोति']},
	{'slp1': 'strIBiH', 'deva': 'स्त्रीभिः', 'def': ['स्त्रीसंघैः']},
	{'slp1': 'sTAyizyate', 'deva': 'स्थायिष्यते', 'def': ['स्थास्यते']},
	{'slp1': 'svakAle', 'deva': 'स्वकाले', 'def': ['गर्भैकादशे वर्षे']},
	{'slp1': 'hutocCizwam', 'deva': 'हुतोच्छिष्टम्', 'def': ['हुतशेषं']}
]

