import React, { useState, useEffect, useContext } from 'react';

// import Alert from "react-bootstrap/Alert";
import Button from 'react-bootstrap/Button';
import { ButtonGroup } from 'react-bootstrap';
import Breadcrumb from 'react-bootstrap/Breadcrumb';

import Accordion from 'react-bootstrap/Accordion';
import AccordionContext from 'react-bootstrap/AccordionContext';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import Tooltip from 'react-bootstrap/Tooltip'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'

import Error from './Error';
import QnA from './qparts/QnA';
import RightSidebar from './RightSidebar';
import { UserContext } from '../providers/UserProvider';

import styled from 'styled-components';
import './css/Components.css';

const Sentence =styled.div`
	// font-weight: ${props=>props.isCur?600:"light"};
	color: ${props=>props.isCur?"black":"rgb(95, 95, 95)"};
	font-family: 'Praja';
	font-size: 30px;
	margin-left: 20px;
`;
const Speaker =styled.div`
	color: var(--primary-color);
	font-family: 'Praja';
	font-size: 32px;
	margin-left: 5px;
`;

const OneQuestion = ({match}) =>{
	// Alert on leaving page
	const [ansSaved, setAnsSaved] =useState(false);
	const [btnClicked, setBtnClicked] =useState(false);
	const [blockNavigation, setBlockNavigation]=useState(true);
	
	const [gotoLink, setGotoLink] =useState("");

	const confirmNavigation=(e)=>{
		e.preventDefault();
		var message = "Are you sure you want to leave?";
		return blockNavigation ?message : void(0);
	}
	window.onbeforeunload = confirmNavigation;

	// Utility Functions
	// console.log('blockNavigation:',blockNavigation)
	useEffect(() => {
		console.log('useEffect ansSaved=', ansSaved);
		if (ansSaved===true){
			// Reset
			setAnsSaved(false);
			setBtnClicked(false);
			
			// Goes to link only when answer is saved
			window.location.href =gotoLink;
		};
	}, [ansSaved, gotoLink]);

	const goto =async (link)=>{
		setGotoLink(link);
		setBlockNavigation(false);
		
		setBtnClicked(true);
		/*
			When btnClicked is made true, 
				useEffect in question parts will be triggered and
				this will set ansSaved to true and 
					the above useEffect will be called
		*/
	};
	const NextButton =({showNext, completed, link, chLink, css})=>{
		if (!showNext){
			if (completed){
				return <Button onClick={()=>goto(chLink)} variant="light" className={css}>Finished</Button>
			}
			return <></>
		}

		// console.log('nxtLink:', link);
		if (completed){
			return <Button onClick={()=>goto(link)} variant="light" className={css}>Next</Button>
		}
		return (
			<OverlayTrigger
				placement="top"
				delay={{ show: 100, hide: 200 }}
				overlay={
					<Tooltip id={`tooltip-top`}>
						Please complete all parts
					</Tooltip>
					}
			>
				<Button href="#" variant="dark" className={css}>Next</Button>
			</OverlayTrigger>
		)
	}

	// Variables
	const {currentUser} =useContext(UserContext);

	const ch =match.params.ch, exIndex =match.params.i, qIndex = match.params.j;

	const [done, setDone] = useState(false);
	const [access, setAccess] = useState({restricted: true, msg: "Context Restricted"});

	const [total, setTotal] =useState(0);
	
	// Question Details
	const [curQ, setQ] = useState({});				 // curQ: current Question's Details !
	const [parts, setParts] = useState([]);			// Parts of the question (transliteration, sandhi...etc)
	const [curPi, setPartIndex] =useState(0);	//Index of the part
	const [partInst, setPartInst] =useState("");//Instruction for the specific part
	const [context, setContext] =useState([]);
	const [speaker, setSpeaker] =useState("");

	// secondPartBtn
	// const [partTwoBtn, setPartTwoBtn]=useState(null);
	const partTwoBtn =React.createRef();

	const [curQComplete, setCurQComplete] =useState(false);

	// instruction or context
	const [selectedInfo, setSelectedInfo] =useState('Instruction');

	// Help sidebar
	const [sidebar, setSidebar] =useState([]);

	useEffect(() => {		
		setSelectedInfo('Instruction');
	}, [partInst]);

	useEffect(() => {
		const getDetails =async () => {
			setDone(false);
			const postObj ={
				method: 'POST',
				cache: 'no-cache',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({ch:ch, i:exIndex,
												j:qIndex, p:curPi, email:currentUser.email }) // i: exercise's index, j: question's index, p: part's index
			}
			const response = await fetch(process.env.REACT_APP_FLASK_API+'get_question', postObj);
			const data = await response.json();
			// console.log(data);
			
			// console.log("DATA", data);
			setQ(data.curQ);
			setCurQComplete(data.curQ.completed);
			
			setTotal(data.curQ.total);
			setParts(data.curQ.parts); 
			setPartInst(data.curQ.partInst);
			
			setContext(data.curQ.context.context);
			setSpeaker(data.curQ.context.speaker);
			setAccess({restricted: data.restricted, msg: data.msg});	

			setSidebar(data.curQ.sidebar);
			setDone(true);
		};
		getDetails();
   }, [ch, exIndex, qIndex, curPi, currentUser.email]);

	const CustomToggle = ({idx, btnPart, eventKey, callback }) => {
		const currentEventKey = useContext(AccordionContext);

		const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;
		const decoratedOnClick = useAccordionButton(eventKey, 
			() => callback && callback(eventKey)
		);
	   
		var i = parts.indexOf(btnPart);
		
		// console.log('accordion', idx, btnPart);
		if (idx ===1){
			return (
				<Button key={btnPart} variant="outline-primary" 
					ref={partTwoBtn} active={isCurrentEventKey}
					onClick={() =>{
						decoratedOnClick();
						setPartIndex(i);
					}}>
					
					{btnPart.charAt(0).toUpperCase()+btnPart.toLowerCase().replaceAll('-', ' ').substring(1)}
				</Button>
			);
		}
		else {
			return (
				<Button key={btnPart} variant="outline-primary" 
					active={isCurrentEventKey}
					onClick={() =>{
						decoratedOnClick();
						setPartIndex(i);
					}}>
					
					{btnPart.charAt(0).toUpperCase()+btnPart.toLowerCase().replaceAll('-', ' ').substring(1)}
				</Button>
			);
		}
	};
	const toggleText=(i)=>{
		var dots = document.getElementsByClassName("dots");
		var moreText = document.getElementsByClassName("more");
		var btnText = document.getElementsByClassName("triggerText");

		if (dots[i].style.display === "none") {
			dots[i].style.display = "inline";
			btnText[i].innerHTML = "See more";
			moreText[i].style.display = "none";
		} else {
			dots[i].style.display = "none";
			btnText[i].innerHTML = "See less";
			moreText[i].style.display = "inline";
		}
	};

	const isEmpty = (obj) =>{
		return Object.keys(obj).length===0 &&
					obj.constructor === Object;
	};

   	if(done && access.restricted){
	   return( <Error message={access.msg} />)
   }
   	else if(done && isEmpty(curQ)){
		return(<h1>No such page</h1>)
	}
	else if(done){	
		return (
		<div className='OneQuestion'>
			
			<div className='oqQuestionPart' >
				<div className='breadcrumbs' > {/* oq- One Question's Header */}
					<Breadcrumb className="widthVW">
						<Breadcrumb.Item href={ `/ch=${ch}`}>{`Chapter ${ch}`}</Breadcrumb.Item>
						{!["pronoun-declension", "verb-conjugation", "verb-identification-drill"].includes(curQ.curPart) &&
							<>
								<Breadcrumb.Item href={ `/ch=${ch}/${exIndex}`} >{curQ.exercise}</Breadcrumb.Item>
								<Breadcrumb.Item href="#" active>{`Question ${curQ.n}`}</Breadcrumb.Item>
								<Breadcrumb.Item href="#" active>{`Sentence ${curQ.s}`}</Breadcrumb.Item>
							</>
						}
						{["pronoun-declension", "verb-conjugation", "verb-identification-drill"].includes(curQ.curPart) &&
							<Breadcrumb.Item href="#" active >{curQ.exercise}</Breadcrumb.Item>
						}
					</Breadcrumb>
				</div>

				{/* each parts button and it's collapsible instruction */}
				<Accordion defaultActiveKey={parts[curPi]}>
					<div className="widthVW d-flex justify-content-between">	
						<div>
							{parts.map((part, idx) => (
								<CustomToggle idx ={idx} key={part} 
									btnPart={part} eventKey={part}/>
							))}
						</div>
						{context.length ?
							<div>
								<ButtonGroup>
									{['Instruction','Context'].map(info=>(
										<Button
											key={info}
											type="radio"
											variant="info"
											active={info===selectedInfo}
											onClick={e=>setSelectedInfo(info)}
										>
											{info}
										</Button>
									))}
								</ButtonGroup>
							</div>
							:<></>
						}
					</div>
					{/* {console.log('OneQ: partInst', partInst)} */}
					{parts.map((part, i) => (
						<Accordion.Collapse key={part} eventKey={part}>
							<div className="partInst widthVW">
							{
								selectedInfo==='Context' 
								?
									<div className='contextArea'>
										{speaker &&
											<Speaker>{speaker} {'\u2012'}</Speaker>
										}
										{context.map((cur, i)=>(
											<div style={{display:'flex', flexFlow:'row nowrap', alignContent:'center'}}>
												<div style={{marginLeft:'15px', maxHeight:'10px'}}>{i+1}</div> 
												<Sentence isCur={cur.isCur} key={i}>
													{cur.sentence}
												</Sentence>
											</div>
										))}
									</div>
								:
									<>
										<span dangerouslySetInnerHTML={{__html: partInst.slice(0,100)}} />
										{partInst.length>100 &&
										<>
											<span className="dots">...</span>
											<span className="more" dangerouslySetInnerHTML={{__html: partInst.slice(100)}} />
											<span className="triggerText" variant="secondary" 
														size="sm" onClick={()=>toggleText(i)}>See more</span>
										</>
										}
									</>
							}
							</div>
						</Accordion.Collapse>
					))}
				</Accordion>

				{/* {console.log('2. Before QnA = ', curQ)} */}
				<QnA curQ={curQ} setQ={setQ} setPartInst={setPartInst} ch={ch} curPi={curPi} 
						qIndex={qIndex} email={currentUser.email} 
						curQComplete={curQComplete} setCurQComplete={setCurQComplete} 
						// states required to implement autosave on next and previous buttons
						btnClicked={btnClicked} setAnsSaved={setAnsSaved}
						// button to click sandhi part 
						partTwoBtn={partTwoBtn}
				/>
			</div>

			{!["pronoun-declension", "verb-conjugation", "verb-identification-drill"].includes(curQ.curPart) &&
				<div className="oqBtns widthVW p-2 d-flex">
					<div className="prevNxtBtns">
						{qIndex>0 && <Button onClick={()=>goto(`/ch=${ch}/${exIndex}/${parseInt(qIndex)-1}`)} variant="light" className="leftBtn">Previous</Button>}
						{<NextButton showNext={qIndex<(total-1)} completed={curQComplete} link={`/ch=${ch}/${exIndex}/${parseInt(qIndex)+1}`} chLink ={`/ch=${ch}`} css="rightBtn"/> }
					</div>
				</div>
			}
			{["pronoun-declension", "verb-conjugation", "verb-identification-drill"].includes(curQ.curPart) &&
				<div className="oqBtns widthVW p-2 d-flex"/>
			}

			<div className="oqRightArea">
				<div  className="rightGrid">
					<RightSidebar curQ={curQ} curPi={curPi} sidebar={sidebar}/>
				</div>
			</div>
			
		</div>
		);

	} 
	else {
		return (<></>);
	}

}

export default OneQuestion;