import React, {useContext, useEffect, useState} from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';
import SandhiAnalysisPopUp from '../errorPopUp/SandhiAnalysisPopUp';

import BigText from '../helpers/BigText';
import LoadingPage from "../helpers/LoadingPage";

import { keyListener } from '../helpers/blocks';
import { UserContext } from '../../../providers/UserProvider';

import '../../css/Components.css';

// This function handles both sandhi and sandhi analysis type of question's parts
const CompoundSandhiAnalysis =({curQ, setPartInst, ch, curPi, qIndex,
												setCurQComplete, btnClicked, setAnsSaved}) =>{
	// To autosave when next or previous buttons are clicked
	useEffect(()=>{
		if (btnClicked===true){
			checkAns();
			setAnsSaved(true);
		};
	}, [btnClicked]);
	
	const [loading, setLoading] =useState(false);
	const {currentUser} =useContext(UserContext);

	const [uans, setUans] = useState(curQ.uans); 

	//Check User's Answer
	const [analysis, setAnalysis] = useState({});
	const [, setQtext] = useState(curQ.text);//Just a provision to use in the future if the question text is changed after the backend call
	const checkAns = () => {
		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ uans:uans, pi: curPi,email:currentUser.email,
											qObj: {'ch':ch,'exercise':curQ.exercise, 'n':curQ.n,'s': curQ.s,'i':qIndex, 
													'parts': curQ.parts, 'text': curQ.text, 'xmlid':curQ.xmlid, 'total':curQ.total }
										})
		}
		const getFeedback =async () => {
			setLoading(true);

			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			// console.log("data.analysis:", data.analysis);
			setCurQComplete(data.completed);
			if(Object.keys(data.analysis).length){
				console.log('analysis', data.analysis);
				setAnalysis(data.analysis);
				setPartInst(data.analysis.inst);
		
				setQtext(curQ.text);
			} else {
				setQtext(curQ.text);
			}

			setLoading(false);
		}
		getFeedback();
	};

	//Enter = Submit
	const [btn, setBtn]=useState(null);
	useEffect(() => {
		if (btn){
			// create an eventListener
			console.log('compound sandhi analysis keyListener');
			window.addEventListener('keydown', e=>keyListener(e, btn));
		}
	}, [btn]);
	useEffect(() =>{
		console.log('mounted Compound Sandhi Analysis');
		// cleanup this component
		return () => {
			console.log('unmounted Compound Sandhi Analysis');
			window.removeEventListener('keydown', e=>keyListener(e, btn));
		};
	}, [])

	return (
		<div>
			<LoadingPage loading={loading} />
			
			{curQ.takeInput &&
			<>
				<span style={{fontFamily:'Praja'}}>
					{/* {console.log('curQ.text:', typeof(curQ.text[0]))} */}
					{typeof(curQ.text)==='object'
					?
						<div className="d-flex align-items-center">
							<BigText ch={ch} sp="Q:"/>
							<div className="analysis">
								<BigText ch={ch} text={curQ.text[0]} />
								<h4>{curQ.text[1]}</h4>
							</div>
						</div>
					:
						<BigText ch={ch} sp="Q:" text={curQ.text}/>
					}
				</span>
	
				<div className="FlexBox">
					<h5><span className="blueText">A:</span></h5>
					<div className="AnsForm">
						{analysis.show && 
							// <Feedback />
							<div>
								<div className="analysis">					
									<SandhiAnalysisPopUp ch={ch}
											devaUnits={analysis.text.devaUnits}
											romanUnits={analysis.text.romanUnits}
									/>
								</div>
							</div>
						}

						<Form>
							<Form.Control autoFocus as="textarea" value={uans}
												placeholder="Type your answer"
												onChange={e=>setUans(e.target.value)}
												onFocus={e => {
													var val = e.target.value;
													e.target.value = '';
													e.target.value = val;
												}}
							/>
							<div className="KeepRight">
								<Button onClick={()=>checkAns()} ref={node=>setBtn(node)}>Check</Button>
							</div>
						</Form>

					</div>
				</div>
			</>
			}
		</div>
	);
};

export default CompoundSandhiAnalysis;