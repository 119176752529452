import React from 'react';

import '../../css/Components.css';

const BigText = ({ch, sp='', text='', inner=false, colored=false})=>{
	ch = parseInt(ch);

	let size='24pt';

	if ([3, 4].includes(ch)) {
		size='48pt';
	}
	else if ([5, 6].includes(ch)) {
		size ='36pt';
	}
	else if ([7, 8].includes(ch)) {
		size ='30pt';
	}

	if (inner)
		return <div style={{fontSize:size}} dangerouslySetInnerHTML={{__html: text}} />
	else
		if (sp.length && text.length)
			return <div className="d-flex align-items-center">
						<h4 className="blueText" style={{fontFamily: "var(--bs-font-sans-serif)", marginRight:'5px'}}>{sp}</h4>
						{colored
						?<div style={{fontSize:size}} dangerouslySetInnerHTML={{__html: text}} />
						:<span style={{fontSize:size}}>{text}</span>
						}
					</div>
		else if (text.length)
			return <span style={{fontSize:size}}>{text}</span>
		else if (sp.length)
			return <h4 className="blueText" style={{fontFamily: "var(--bs-font-sans-serif)", marginRight:'5px'}}>{sp}</h4>

};

export default BigText