import React, { useState, useContext, useEffect } from 'react';

import { Modal, Button,
			Accordion, AccordionContext 
		} from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import Verb from './Verb';
import { InterfaceError } from '../helpers/blocks';

import '../helpers/blocks.css';
import '../../css/Components.css';

const VerbInterface=props=>{
	
	const [inputParts, setInputParts]=useState({'root':'p'});

	useEffect(()=>{
		setInputParts({'root':'p'});
		console.log('resetting verbInterface !');

		['rootOptions', 'tenseOptions', 
		'voiceOptions', 'personOptions', 'numberOptions'].map((radioName, idx)=>{
			var radios =document.getElementsByName(radioName);
			for(var i=0; i<radios.length; i++){
				// Default checked in rootOptions
				if(radioName==='rootOptions' && i===0)
					continue;
				radios[i].checked=false;
			}
		});
	}, [props.ansState]);

	const CustomToggle = ({ btnPart, eventKey, callback }) => {
		const currentEventKey = useContext(AccordionContext);
		const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;
		const decoratedOnClick = useAccordionButton(eventKey, 
			() => callback && callback(eventKey),
		);
	
		return (
			<Button key={btnPart} variant="outline-primary" 
				active={isCurrentEventKey}
				onClick={() =>{
					decoratedOnClick();
				}}>
				{btnPart}
			</Button>
		);
	};
	
	const [showInterfaceError, setShowInterfaceError]=useState(false);

	const [interfaceBtn, setInterfaceBtn] =useState(null)
	useEffect(() => {
		if (interfaceBtn){
			// create an eventListener
			window.addEventListener('keydown', e=>handleKeyDown(e));
			// cleanup this component
			return () => {
				window.removeEventListener('keydown', e=>handleKeyDown(e));
			};
		}
	}, [interfaceBtn]);

	const handleKeyDown=(event)=>{
		if(event.key==='Enter'){
			event.preventDefault();
			// console.log('MODAL Enter:')
			interfaceBtn.click();	
		}
	};

	// Used in submit button
	const isDisabled =()=>{
		return Object.keys(inputParts).length<5;
	};

	// Update answer (inflection) and close modal
	const handleSubmit =() =>{
		if (isDisabled()){
			setShowInterfaceError(true);
			return;
		}
		let values = null;
		if (props.partType && props.partType==="verb-identification-drill")
			values = props.ansstate;
		else
			values = [...props.ansstate];

		let input ="";
		input=inputParts['tense']+'_'+inputParts['voice']+inputParts['person']+inputParts['number'];
		if (inputParts['root']!=='p')
			input=inputParts['root']+'_'+input
		
		// Update the value
		// console.log('vid:',props.index);
		if (props.partType && props.partType==="verb-identification-drill")
			values[props.index[0]].inflectionBoxes[props.index[1]].inflection =input
		else
			values[props.index].inflection=input;

		// console.log('newAns', values);
		props.setans(values);
		props.onHide();
	};

	return(
		<Modal
			{...props}
			size="lg"
			centered
		>
			<InterfaceError showInterfaceError={showInterfaceError} setShowInterfaceError={setShowInterfaceError} />

			<Accordion defaultActiveKey={2}>
			
				<Modal.Header closeButton>
					<div className="widthVW interfaceTitle">
						{props.title}
					</div>
				</Modal.Header>
				
				<Modal.Body>
					<div className="widthVW mb-2">
						<CustomToggle key={2} btnPart="Verb" eventKey={2} />
					</div>
					<Accordion.Collapse key={2} eventKey={2}>
						<Verb inputParts={inputParts} setInputParts={setInputParts}/>
					</Accordion.Collapse>
				</Modal.Body>
				
				<Modal.Footer>
					<Button variant="primary"
								// disabled={isDisabled()}
								ref ={node=>setInterfaceBtn(node)}
								onClick={handleSubmit}
					>
						Submit
					</Button>
					<Button onClick={()=>props.onHide()} variant="outline-danger">Close</Button>
				</Modal.Footer>

			</Accordion>
		</Modal>
	)
};

export default VerbInterface;