import React, {useContext, useEffect, useState} from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import BigText from './helpers/BigText';
import { keyListener } from './helpers/blocks';
import LoadingPage from './helpers/LoadingPage';

import {UserContext} from '../../providers/UserProvider';
import TransliterationPopUp from './errorPopUp/TransliterationPopUp';

import '../css/Components.css';

const Devanagari =({curQ, setQ, setPartInst, ch, curPi, qIndex, 
							setCurQComplete, btnClicked, setAnsSaved, partTwoBtn}) =>{
	
	// methods/variables/states/context
	const [loading, setLoading] =useState(false);

	const [uans, setUans] =useState(curQ.uans);
	const {currentUser} =useContext(UserContext);
	
	// Update question text based on user answer (if answer is correct)
	const updateQText=qText=>{
		let newQ = curQ;
		newQ.text=qText;
		setQ(newQ);
	};
	//Check User's Answer
	const [analysis, setAnalysis] = useState({});
	const checkAns =() => {
		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ uans:uans, pi: curPi, email:currentUser.email,
											qObj: {'ch':ch,'exercise':curQ.exercise, 'n':curQ.n,'s': curQ.s,'i':qIndex, 
													 'parts': curQ.parts, 'text': curQ.text, 'xmlid':curQ.xmlid, 'total':curQ.total }
										})
		};
		const getFeedback =async () => {
			setLoading(true);

			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			
			updateQText(data.analysis.qText);
			
			setAnalysis(data.analysis);
			setPartInst(data.analysis.inst);
			setCurQComplete(data.completed);
		
			setLoading(false);

			if (data.analysis.show===false) //Means no error analysis to show
				setGoToSandhi(true);
		};
		getFeedback();
	};

	//Enter = Submit
	const [btn, setBtn]=useState(null);
	useEffect(() => {
		if (btn){
			// create an eventListener
			window.addEventListener('keydown', e=>keyListener(e, btn));
			// cleanup this component
			return () => {
				window.removeEventListener('keydown', e=>keyListener(e, btn));
			};
		}
	}, [btn]);

	// To autosave when next or previous buttons are clicked
	useEffect(()=>{
		if (btnClicked===true){
			checkAns();
			setAnsSaved(true);
		};
	}, [btnClicked, checkAns, setAnsSaved]);

	// Automatically go to sandhi when correct
	const [goToSandhi, setGoToSandhi] =useState(false);
	useEffect(()=>{
		if (goToSandhi===true && partTwoBtn.current){
			// console.log('partTwoBtn', partTwoBtn);
			partTwoBtn.current.click();
		};
	}, [goToSandhi, partTwoBtn]);
	
	return (
		<div>
			<LoadingPage loading={loading} />

			{curQ.takeInput &&
			<>
				<span style={{fontFamily:'Praja'}}>
					{/* {console.log('curQ.text:', typeof(curQ.text), curQ)} */}
					{typeof(curQ.text)==='object'
					?
						<div className="d-flex">
							<BigText ch={ch} sp="Q:"/>
							<div className="analysis">
								<BigText ch={ch} text={curQ.text[0]} />
								<h4>{curQ.text[1]}</h4>
							</div>
						</div>
					:
						<BigText ch={ch} sp="Q:" text={curQ.text}/>
					}
				</span>
	
				<div className="FlexBox">
					<BigText ch={ch} sp="A:" />
					<div className="AnsForm">

						{analysis.show && 
							// <Feedback />
							<div className="analysis" style={{fontFamily:'Praja'}}>
								<BigText ch={ch} text={analysis.text.deva} inner={true} />
								<TransliterationPopUp htmlText={analysis.text.roman} />
							</div>
						}

						<Form>
							<Form.Control autoFocus as="textarea" value={uans}
												placeholder="Type your answer"
												onChange={e=>setUans(e.target.value)}
												onFocus={e => {
													var val = e.target.value;
													e.target.value = '';
													e.target.value = val;
												}}
							/>
							<div className="KeepRight">
								<Button onClick={()=>checkAns()} ref={node=>setBtn(node)}>Check</Button>
							</div>
						</Form>
						
					</div>
				</div>
			</>
			}
		</div>
	);
};

export default Devanagari;