import React, {useState, useEffect, useContext} from 'react';

// import { Link } from 'react-router-dom';
import { Row, Col, Nav, Tab } from 'react-bootstrap';
// import { OverlayTrigger, Tooltip } from 'react-bootstrap';

import Error from "../Error";

import UploadImage from './UploadImage';

import About from "./About";
import RecentActivity from './RecentActivity';
import ChangePassword from './ChangePassword';
import { UserContext } from '../../providers/UserProvider';

import './Profile.css';
import '../css/Components.css';

import defaultPic from '../../static/default.jpg';

const Profile =(props) =>{
	let ownProfile =true; // Later make it dynamic

	const {currentUser} =useContext(UserContext);
	const joinDate =currentUser.metadata.creationTime.split(/(\s+)/).filter( e => e.trim().length > 0).splice(1,3).join(' ');

	const [user, setUser] = useState({});
	const [done, setDone] =useState(false);
	useEffect(()=>{
		const getDetails =async () => {
			setDone(false);
			const response = await fetch(process.env.REACT_APP_FLASK_API+`profile_details/${currentUser.email}`);
			const data = await response.json();
			console.log("profileData:", data);
			setUser(data);
			setDone(true);
		};
		getDetails();
	}, [currentUser.email])

	// make this a generic page for other users too ?
	// const [error, setError] =useState('');

	// Image uploading
	const [img, setImg] =useState({}) //used in uploadImage.js to receive uploaded image
	const [, setModalShow] = useState(false);

	const [dpURL, setDpUrl] =useState('');
	const upload = async() => {
		setDone(false);
		setModalShow(false);
	
		const imgData = new FormData();
		imgData.append('file', img);
		imgData.append('filetype', img.type);
		imgData.append('filename', img.filename);
		const postObj ={
			method: 'POST',
			cache: 'no-cache',
			body: imgData
			// headers: {'Content-Type': 'multipart/form-data'}
		}

		const response=await fetch(process.env.REACT_APP_FLASK_API+`img_update/${currentUser.email}`, postObj);
		// const data =await response.json();
		console.log('UPLOAD response-data:', response);
		// const blob = await data.imgBytes.blob();
  		// const url = URL.createObjectURL(blob);
		setDpUrl(response);

		setDone(true);
	};


	if (!done){
		return <Error message="Loading..." />
	}
	return (
		<>
			<div className="glass">
				<div className="header">
					{/* IMAGE */}
					<UploadImage
						// show={modalShow}
						show={false}
						onHide={() => setModalShow(false)}
						img={img}
						setImg={setImg}
						upload={upload}
					/>
					{/* <OverlayTrigger
						placement="left"
						overlay={
							<Tooltip id={`imgUpdate`}>
								Click to update
							</Tooltip>
						}
					> */}
						{dpURL 
						?
							<img className='dp' src={dpURL} alt="" onClick={()=>setModalShow(true)}/>
						:
							<img className='dp' src={defaultPic} alt="" onClick={()=>setModalShow(true)}/>
						}
					{/* </OverlayTrigger> */}
					
					{/* INTRO */} 
					<div className="intro">
						{currentUser.displayName &&
						<span>
							<span className="spChar">
								{currentUser.displayName.charAt(0)}
							</span>
							{currentUser.displayName.slice(1)}
						</span>
						}
						<span style={{color:'rgb(0,123,255)'}}>{user.utype.charAt(0).toUpperCase()+user.utype.slice(1)}</span>			
						<span>Joined {joinDate}</span>
					</div>
				</div>
				
				{/* Tabs and details */}
				<Tab.Container id="left-tabs" defaultActiveKey="activity">
					<Row className="profileRow">
					<Col sm={3}>
						<Nav variant="pills" className="flex-column">
							<Nav.Item>
								<Nav.Link eventKey="about">About</Nav.Link>
							</Nav.Item>
							{ownProfile &&
								<>
								<Nav.Item>
									<Nav.Link eventKey="activity">Recent Activity</Nav.Link>
								</Nav.Item>
								<Nav.Item>
									<Nav.Link eventKey="pass">Change Password</Nav.Link>
								</Nav.Item>
								</>
							}
							<Nav.Item style={{ marginTop: "2em" }}>
								<Nav.Link onClick={() => {props.history.goBack();}}>Back</Nav.Link>
							</Nav.Item>
						</Nav>
					</Col>
					<Col sm={8}>
						<Tab.Content className="profileTabContent">
							<Tab.Pane eventKey="about">
								<About currentUser={currentUser} />
							</Tab.Pane>
							{ownProfile &&
								<>
								<Tab.Pane eventKey="activity" style={{width:'100%'}}>
									<RecentActivity />
								</Tab.Pane>
								<Tab.Pane eventKey="pass">
									<ChangePassword />
								</Tab.Pane>
								</>
							}
						</Tab.Content>
					</Col>
					</Row>
				</Tab.Container>
			</div>
		</>
	);
};

export default Profile;