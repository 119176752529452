import React, {useContext} from 'react';
import {Route, Redirect} from 'react-router-dom';
import  {UserContext} from "./providers/UserProvider";

const ProtectedRoute = ({component: Component, ...rest}) =>{
	const {currentUser}= useContext(UserContext);
	return (
		<Route {...rest} 
			render={props => {
				if(currentUser){
					return <Component {...props} user={currentUser}/>;
				} else {
					return <Redirect to={{
						pathname: "/login",
						state: {
							from: props.location
						}     
					}} />
				}
			}} 
		/>
	);
};

export default ProtectedRoute;