import React, { useState, useEffect, useContext, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { Modal, OverlayTrigger, Form, 
			Button, ProgressBar, Tooltip } from 'react-bootstrap';

// appAuth is the auth from the secondary app (second instance of the same app)
// used so that the current user is not logged out
import { appImage } from '../../fireAuth/firebase'; 

import Error from '../Error';
import plusPic from '../../static/plus.png';
import defaultPic from '../../static/default.jpg';
import { UserContext } from "../../providers/UserProvider";

import { SearchBar, FilterOptions, Icon } from './StudentsHelper';

import './StudentStyles.css';

const Students =()=>{
	const {currentUser} = useContext(UserContext);

	const [done, setDone] =useState(false);
	const [students, setStudents] =useState([]);
	const [finalStudents, setFinalStudents] =useState([]);

	const [query, setQuery] =useState("");
	const [sortType, setSortType] =useState("dsc");
	const [studentType, setStudentType] =useState("all");


	// Add New students
	const password ='test1234';
	const [userData, setUserData] =useState({});
	const [showAddUser, setShowAddUser]=useState(false);
	const handleCloseAddUser=()=>setShowAddUser(false);
	
	const handleChange=(newString)=>{
		var data = userData;
		var newEmails =newString.split(' ');
		var oldEmails =Object.keys(data);

		var removeEmails =oldEmails.filter(x => !newEmails.includes(x));
		removeEmails.forEach(email => {
			delete data[email];
		});

		var addEmails =newEmails.filter(x => !oldEmails.includes(x)&&x.length>0);
		addEmails.forEach(email => {
			data[email]={show:'false'};
		});

		setUserData(prev=>({...data}));
	};

	const createAccounts=()=>{
		console.log('CLICKED: creating accounts !')
		Object.keys(userData).map(email=>{
			appImage
				.createUserWithEmailAndPassword(email, password)
				.then(user=>{
						console.log(email, 'created:', user);
						var newData=userData;
						newData[email]={show:'true', variant: 'success'};
						setUserData(()=>({...newData}));
					})
				.catch(err=>{
						console.log('FAILED', email, err.message);
						var newData=userData;
						newData[email]={show:'true', variant: 'danger', err:err.message};
						setUserData(()=>({...newData}));
					});
			return <></>;
		});
	};

	//Enter = Submit
	const [btn, setBtn]=useState(null);
	const keyListener=(event)=>{
		// console.log('key=', event.key)
		if(event.key==='Enter'){
			event.preventDefault();
			btn.click();
		}
	}
	
	useEffect(()=>{
		const getStudents = async() =>{
			const response = await fetch(process.env.REACT_APP_FLASK_API+'get_students_details');
			
			const data = await response.json();
			console.log('DATA:', data);

			setFinalStudents(data.response);
			setStudents(data.response);

			setDone(true);
		};
		getStudents();
	}, []);

	//Filtering based on student email
	const filterStudents =useCallback(()=>{
		console.log('filter', studentType);

		var filteredStudents =students.filter(student=>{
			if (studentType==="all") {
				return student;
			}
			//else if (studentType==="iiit" & student.email.toLowerCase().endsWith(".iiit.ac.in")) {
			// 	return student;
			// }
			return null;
		});
		
		return filteredStudents;

	}, [studentType, students]);

	//Sort based on student progress
	const sortStudents =useCallback(async (searchedStudents)=>{
		// Sort
		console.log('sort', sortType);
		const compare=(a,b)=>{
			var mul = 1;
			if (sortType==="dsc")
				mul = -1;

			// console.log('mul=', mul);
			if (a.total===b.total){
				if (a.email < b.email)
					return -1
				else 
					return 1
			}
			else if (a.total < b.total)
				return -1*mul
			else 
				return 1*mul
		};
		
		await searchedStudents.sort(compare);
		setFinalStudents(searchedStudents);
	}, [sortType]);
	
	//Filtering based on search query
	useEffect(() =>{
		// console.log("in search useEffect");
		
		// Filter
		var filteredStudents =filterStudents();

		// Search
		console.log('search', query);
		var searchedStudents =filteredStudents.filter(student=>{
			if (query.length===0 || query.trim()==='') {
				return student;
			} else if (student.utype.toLowerCase().includes(query.trim().toLowerCase())) {
				return student;
			} else if (student.name.toLowerCase().includes(query.trim().toLowerCase())) {
				return student;
			} else if (student.email.toLowerCase().includes(query.trim().toLowerCase())) {
				return student;
			} 
			return null;
		});

		// Sort
		sortStudents(searchedStudents);

   	}, [query, filterStudents, sortStudents]);

	
	// console.log('length:', Object.keys(students).length)
	if (!['dev', 'prof'].includes(currentUser.utype))
		return <Error message="No such page" />
	else if (Object.keys(students).length===0 || !done)
		return <Error message="Loading..." />
	return (
		<div>
			{/*  Modal & Toasts */}
			<Modal show={showAddUser} onHide={handleCloseAddUser} centered size="lg">
				<Modal.Header closeButton>
					<Modal.Title>Add Users</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					Enter the emails of new users to create their accounts. Separate the emails with a space.
					<Form.Control autoFocus name="newEmails" as="textarea" className="mt-3"
										rows="3" style={{maxHeight:'200px', overflowY:'auto'}}
										placeholder="Emails" defaultValue={Object.keys(userData).join(' ')} 
										onChange={e=>handleChange(e.target.value)}
										onFocus={e => {
											var val = e.target.value;
											e.target.value = '';
											e.target.value = val;
										}}
										onKeyPress={keyListener}
					/>
					{Object.keys(userData).length>0 && 
						<div className='emailsArea'>
							{Object.keys(userData).map(email=>(
								<div key={email} style={{marginRight:'20px'}}>
									<Icon data={userData[email]} />
									<span style={{marginLeft:'5px', color:'rgb(50,50,50)'}}>{email}</span>
								</div>
							))}
						</div>
					}
				</Modal.Body>
				<Modal.Footer>
					<Button variant="outline-danger" onClick={handleCloseAddUser}>
						Close
					</Button>
					<Button variant="outline-primary" 
								ref={node=>setBtn(node)}
								onClick={createAccounts}>
						Create
					</Button>
				</Modal.Footer>
			</Modal>


			{/* STUDENTS DETAILS */}
			<div className="studentHeader">
				<div className="studentTitle">Students</div>

				<div className="studentCenter">
					<SearchBar query={query} setQuery={setQuery} />
					<FilterOptions sortType={sortType} setSortType={setSortType} 
						studentType={studentType} setStudentType={setStudentType} />
				</div>
				
				<div className="studentCountSent">
					<span className="studentCount">{finalStudents.length}</span> 
					{finalStudents.length===1?'Student':'Students'}
				</div>
			</div>
	
			<div className="studentsArea">
				{currentUser 
					?<>
						{['dev', 'prof'].includes(currentUser.utype) &&
							<div className="studentBox" onClick={()=>setShowAddUser(true)}>
								<img className="dp" src={plusPic} alt="plus" />
								<div className="utype">Add</div>
								<div className="text">User</div>
							</div>
						}
					</>
					: <></>
				}

				{finalStudents.map(student => (
					<Link to={`/student=${student.email}`} key={student.email} className="studentBox">
						<img className="dp" src={defaultPic} alt="your dp" />
						<div className="utype">{student.utype}</div>
						<div className="text">{student.name}</div>
						<div className="text">{student.email}</div>
						<div style={{width:'100%', marginTop:'10px'}}>
							<OverlayTrigger
								placement="bottom"
								overlay={<Tooltip>
													<div className="d-flex flex-column align-items-center">
														<strong>{student.total}%</strong>
														<div className="line" />
														<div>{student.percentages}</div>
													</div>
												</Tooltip>}
							>
								<ProgressBar variant="success" now={student.total} />
							</OverlayTrigger>
						</div>
					</Link>
				))}
			</div>
		</div>
	);
};

export default Students;