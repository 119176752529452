import React, { useState, useEffect } from 'react';

import { rgb } from 'chroma-js';
import {useDropzone} from 'react-dropzone';
import { Modal, Button } from 'react-bootstrap';

import styled from 'styled-components';

import './Profile.css';

const DropZone = styled.div`
	flex: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	padding: 20px;
	border-width: 2px;
	border-radius: 2px;
	border-color: ${props=>props.entered?rgb(0,123,255):rgb(238,238,238)};
	border-style: dashed;
	background-color: #fafafa;
	color: #bdbdbd;
	outline: none;
	transition: border .24s ease-in-out;
	cursor: pointer;
`;

const UploadImage=({show, onHide, img, setImg, upload})=>{
	// console.log('showMODAL:', show);
	const [preview, setPreview] =useState({});
	const [entered, setEntered] = useState(false);

	const {acceptedFiles, fileRejections, getRootProps, getInputProps} = useDropzone({
		multiple: false,
		accept: 'image/png, image/jpeg, image/jpg',
		onDrop: file => setEntered(false),
		onDragEnter: e =>setEntered(true),
		onDragLeave: e =>setEntered(false)
	});
	
	// console.log('acceptedFiles:', acceptedFiles);
	// console.log('rejectedFiles:', fileRejections.length, fileRejections,);

	useEffect(()=>{
		if(acceptedFiles.length===1){
			// console.log('detected acceptable images !', typeof(acceptedFiles[0]));
			setImg(acceptedFiles[0]);
			setPreview({img: URL.createObjectURL(acceptedFiles[0]), name: acceptedFiles[0].name});
		}
	}, [acceptedFiles, setImg]);

	// console.log('img:', typeof(img), img);

	return (
		<Modal
			show={show}
			onHide={onHide}

			size="md"
			centered
			aria-labelledby="image_upload_modal"
		>
			<Modal.Header closeButton>
				<Modal.Title id="image_upload_modal">
					New Photo
				</Modal.Title>
			</Modal.Header>

			<Modal.Body>
			<section className="container">
				{fileRejections.length===1 && 
					<div className="uploadError">
						<pre style={{margin:'0px'}}>Please upload an <strong>image</strong> (jpeg / jpg / png)</pre>
					</div>
				}

				<DropZone {...getRootProps()} entered={entered}>
					{acceptedFiles.length===0 &&
						<>
						<input {...getInputProps()} />
						<p>Drop your new photo here !</p>
						<p>OR</p>
				
						<p>click to browse</p>
						</>
					}
					{acceptedFiles.length===1 &&
						<>
							<input {...getInputProps()} />
							<img className='dp dp_border' src={preview.img} alt={preview.name} />	
						</>
					}
				</DropZone>
			
			</section>
			</Modal.Body>

			<Modal.Footer>
				<Button vairant='primary' onClick={()=>upload()}>Upload</Button>
			</Modal.Footer>
		</Modal>
	);
};

export default UploadImage;