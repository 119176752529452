import React,{useState} from 'react';

import {Button, Alert, Row, Form} from "react-bootstrap";

import { auth } from "../../fireAuth/firebase";
import "./Profile.css";

const About =({currentUser})=>{
	// console.log('displayName:', currentUser.displayName);
	let defaultName = currentUser.displayName;
	if(!defaultName){
		defaultName=''
	}
	const [name, setName] =useState(defaultName);

	const [error, setError] =useState('');
	const [success, setSuccess] =useState(false);

	const modifyAbout =()=>{
		auth.currentUser.updateProfile({
			displayName: name,
			photoURL: null
		}).then(()=>{
			window.location.reload();
			setSuccess(true);
		}).catch(error=>{
			setError(error.message);
		})
	}

	return (
		<Form style={{width:'100%'}}>
			{error && <Alert onClose={()=>setError('')} variant="danger" dismissible>{error}</Alert>}
			{success && <Alert onClose={()=>setSuccess(false)} variant="success" dismissible>User name has been changed !</Alert>}

		  <Form.Group className="mb-3" controlId="name">
				<Form.Label>User Name</Form.Label>
				<Form.Control
					size="md"
					value={name}
					placeholder=''
					onChange={(e) =>{
						setName(e.target.value);
					}}
				/>
		  </Form.Group>
	
		  <Form.Group className="mb-3" controlId="email">
				<Form.Label>Email</Form.Label>
				<Form.Control
					size="md"
					readOnly
					value={currentUser.email}
				/>
		  </Form.Group>
	
		  <Row>
				<Button
					className="ml-auto"
					variant="primary"
					onClick={() => {
						modifyAbout();
					}}
				>
					Modify
				</Button>
		  </Row>

		</Form>
	);
};

export default About;