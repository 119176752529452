import React, {useState, useRef, useEffect, useContext} from 'react';

import { Button, Modal, 
			OverlayTrigger, Tooltip
		} from "react-bootstrap";
import ImageFadeIn from "react-image-fade-in";


import Select, { createFilter } from "react-select";
import createFilterOptions from "react-select-fast-filter-options";

import {vocab, pos} from './helpers/data';
import {UserContext} from "../../providers/UserProvider";
import {RowBox, Box, colorStyles } from "./helpers/blocks";

// import VerbInterface from './interfaces/VerbInterface';
import MorphologicalInterface from './interfaces/MorphologicalInterface';

import BigText from './helpers/BigText';
import cross from './helpers/cross.png';
import LoadingPage from "./helpers/LoadingPage";
import question from './helpers/questionOrange.png';

import './helpers/blocks.css';
import '../css/Components.css';

const ParticipleIdentification =({curQ, setPartInst, ch, curPi, qIndex,
											setCurQComplete, btnClicked, setAnsSaved}) =>{
	// To autosave when next or previous buttons are clicked
	useEffect(()=>{
		if (btnClicked===true){
			check(0, 'All');
			setAnsSaved(true);
		};
	}, [btnClicked, check, setAnsSaved]);

	const [loading, setLoading] =useState(false);
	const {currentUser} = useContext(UserContext);

	const partType =curQ.parts[curPi];
	const interfaceTitle = ''; const interfaceIndex =0; 
	// const [interfaceTitle, setInterfaceTitle] =useState(''); 
	// const [interfaceIndex, setInterfaceIndex] =useState(0);
	const [showInterface, setShowInterface] =useState(false);
	
	//Enter = Submit
	const [btn, setBtn]=useState(null)

	const interfaceOpen =useRef(null);
	useEffect(()=>{
		interfaceOpen.current =showInterface;
	}, [showInterface]);
	
	const handleKeyDown=(event)=>{
		// console.log('key=', event.key)
		if(event.key==='Enter' & !interfaceOpen.current){
			event.preventDefault();
			btn.click();
		}
	};
	useEffect(() => {
		if (btn){
			// create an eventListener
			window.addEventListener('keydown', e=>handleKeyDown(e));
			// cleanup this component
			return () => {
				window.removeEventListener('keydown', e=>handleKeyDown(e));
			};
		}
	}, [btn, handleKeyDown]);

	//Misc Functions !
	const possibleRoots =vocab.filter((obj)=>obj.isLemma===1);
	const rootFilterOptions =createFilterOptions({possibleRoots}); 
	const filterOptions = createFilterOptions({vocab});

	const getBorderColor =(error, correct)=>{
		if (error)
			return 'red';
		
		if (correct)
			return 'green';

		return 'transparent';
	};

	const getPlaceholder =(selected, temp, options=[])=>{
		// console.log(selected, temp, options);
		if (selected.length>0)
			return selected
		else if (options.length>1)
			return options[0].value+'?';
		else
			return temp;
	};

	// This is constructed in the backend (python) and passed as part of the curQ
	const [ansState, setAnsState] =useState(curQ.uans['forms']); // 4 Forms
	const [commonState, setCommonState] =useState(curQ.uans['common']); // common part 

	// useEffect(()=>{
	// 	console.log("newState:", ansState);
	// 	console.log("newCommon:", commonState);
	// }, [ansState])
	
	const handleInputChange = (index, event, word) => {
		const common = {...commonState};
		switch(event.target.name){
			case "inflection":
				common.inflection = event.target.value;
				if (common.inflection==='?' || common.inflection==='/'){
					setShowInterface(true);
				}
				break;
			default:
				break;
		}
	
		setCommonState(common);
	};

	const handleSelectChange = (index, name, selected) => {
		const values = [...ansState];

		const common = {...commonState};

		// console.log('selected:', selected);
		if (selected===null){
			return;
		}

		var options =[];
		switch(name){
			case "lemma":
				values[index].lemma = selected.value;
				// console.log("lemma", values[index].lemma);

				options =[];
				pos[selected.value].forEach(tag =>{
					options.push({ label:tag, value: tag })
				});
				values[index].posOptions =options
				if (options.length===1)
					values[index].pos =options[0].value;
				else
					values[index].pos =''
				// console.log("posOptions", values[index].posOptions);
				setAnsState(values);
				break;

			case "pos":
				values[index].pos =selected.value;
				setAnsState(values);
				break;

			case "root":
				common.root = selected.value;
				// console.log("root", common.root);

				options =[]; //for rootPos
				pos[selected.value].forEach(tag =>{
					options.push({ label:tag, value: tag })
				});
				common.rootPosOptions = options
				if (options.length===1)
						common.rootPos =options[0].value;
					else
						common.rootPos =''
				// console.log("rootPosOptions", common.rootPosOptions);
				setCommonState(common);
				break

			case "rootPos":
				common.rootPos =selected.value;
				setCommonState(common);
				break;
			
			default:
				break;
		}
	};

	// Checking the user's answers

	//checking box or all depending on checkType
	const check =(index, checkType) =>{	
		// console.log("checking", checkType);
		// console.log("values@", ansState[index]);
		
		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ uans: {'common':commonState, 'forms':ansState}, pi: curPi, email: currentUser.email, checkType: checkType,
											qObj: {'ch':ch,'exercise':curQ.exercise, 'n':curQ.n, 's': curQ.s, 'ai':index,
													'i':qIndex, 'parts': curQ.parts, 'text': curQ.text, 'xmlid':curQ.xmlid, 'total':curQ.total}
										})
		};
		
		const getFeedback =async () => {
			setLoading(true);

			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			// console.log("data.analysis:", data.analysis);

			setPartInst(data.analysis.inst);
			setAnsState(data.analysis.afterState['forms']);
			setCommonState(data.analysis.afterState['common']);
			setCurQComplete(data.completed);
		
			setLoading(false);
		};
		getFeedback();
	};

	const [showPdf, setShowPdf] =useState(false);
	const [pdfFile, setPDFfile]=useState('appendix-p1');
	const InflectionError =(title, pdf, blank=false) => {
		if (pdf.startsWith('appendix')){
			if (blank)
				return <OverlayTrigger
							placement="top"
							overlay={<Tooltip>Your inflectional identifier is blank.</Tooltip>}
						>
							<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
								width={25} height={25} src={question} alt='empty'/>
						</OverlayTrigger>
			else {
				return<>
							<Modal 
									show={showPdf} 
									onHide={()=>setShowPdf(false)} 
									dialogClassName="pdfmodal-90w"
							>
								<Modal.Header closeButton> 
									<div style={{display:'flex', justifyContent:'center', width:'100%'}}>
										<span style={{fontSize:'24pt', fontFamily:'Praja'}}>{title}</span>
									</div>
								</Modal.Header>
								<embed
									title="helpPdf"
									src={require("../../docs/"+pdfFile+".pdf")+"#zoom=150"}
									frameBorder="0"
									scrolling="auto"
									height="100%"
									width="100%"
								></embed>
							</Modal>

							<OverlayTrigger
								placement="top"
								overlay={<Tooltip>Click to see the help.</Tooltip>}
							>
								<ImageFadeIn opacityTransition={3} 
									style={{float:'right'}} width={25} height={25}
									onClick={()=>{setPDFfile(pdf);setShowPdf(true)}} src={cross} alt='wrong'/>
							</OverlayTrigger>
						</>
			}
		}
		else {
			if (blank)
				return <OverlayTrigger
							placement="top"
							overlay={<Tooltip>Your inflectional identifier is blank.</Tooltip>}
						>
							<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
								width={25} height={25} src={question} alt='empty'/>
						</OverlayTrigger>
			else
				return <OverlayTrigger
							placement="top"
							overlay={<Tooltip>{pdf}</Tooltip>}
						>
							<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
								width={25} height={25} src={cross} alt='wrong'/>
						</OverlayTrigger>
		}
	}
	
	return (
		<div>
			<LoadingPage loading={loading} />
			
			<MorphologicalInterface
				show={showInterface}
				onHide={()=>setShowInterface(false)}
				title={interfaceTitle}
				index={interfaceIndex}
				ansstate={commonState}
				setans={(newAns)=>setCommonState(newAns)}
				partType={partType}
			/>
			
			{curQ.takeInput &&
			<>
				<span style={{fontFamily:'Praja'}}>
					<BigText ch={ch} sp="Q:" text={curQ.text} colored={false}/> 
				</span>
				<div className="FlexBox">
					<h4><span className="blueText">A:</span></h4>
					<div>
						<div className="commonBox">
							<RowBox borderColor={getBorderColor(commonState.errorFound, commonState.allCorrect)}>
								
								<div className="form-group common-field">
									<label htmlFor={`"root"${commonState.index}`} className="smText">Root
									{commonState.rootError===true&&
										<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
										width={25} height={25} src={cross} alt='wrong'/>
									}
									{commonState.rootError==='?'&&
										<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
										width={25} height={25} src={question} alt='empty'/>
									}
									</label>
									<Select
										backspaceRemovesValue
										isClearable
										
										name="root"
										id={`"root"${commonState.index}`}
										aria-describedby={`"rootError"${commonState.index}`}

										options={possibleRoots}
										styles={colorStyles}
										maxMenuHeight={200}

										filterOptions={rootFilterOptions}
										filterOption={createFilter({ignoreCase:false, ignoreAccents:false, trim:true,  matchFrom:'start'})}

										placeholder={getPlaceholder(commonState.root, "First", commonState.rootOptions)}
										onChange={(selectedOption) => handleSelectChange(commonState.index, "root", selectedOption )}

										// menuPortalTarget={document.querySelector("body")}
									/>
								</div>
								<div className="form-group common-field">
									<label htmlFor={`"root"${commonState.index}`} className="smText">
										Root's lexical category
										{commonState.rootPosError===true&&
											<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
											width={25} height={25} src={cross} alt='wrong'/>
										}
										{commonState.rootPosError==='?'&&
											<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
											width={25} height={25} src={question} alt='empty'/>
										}
									</label>
									<Select
										backspaceRemovesValue
										name="rootPos"
										id={`"rootPos"${commonState.index}`}
										aria-describedby={`"rootPosError"${commonState.index}`}

										options={commonState.rootPosOptions}

										placeholder={getPlaceholder(commonState.rootPos, "Second", commonState.rootPosOptions)}
										// selectValue ={cur.rootPos}
										onChange={(selectedOption) => handleSelectChange(commonState.index, "rootPos", selectedOption )}

										// menuPortalTarget={document.querySelector("body")}
									/>
								</div>
								<div className="form-group common-field">
									<label htmlFor={`"inflection"${commonState.index}`} className="smText">Inflection
										{commonState.inflectionError===true&&
											InflectionError('', commonState.pdf)
										}
										{commonState.inflectionError==='?'&&
											InflectionError('', commonState.pdf, true)
										}
									</label>
									<input
										type="text"
										autoComplete="off"
										className="form-control"

										name="inflection"
										id={`"inflection"${commonState.index}`}
										aria-describedby={`"inflectionError"${commonState.index}`}
										
										placeholder="Third"
										value={commonState.inflection}
										onChange={(event) => handleInputChange(commonState.index, event, commonState.title)}
									/>
								</div>
								<Button variant='outline-primary' size='sm' 
											style={{'float':'right', 'marginLeft':'20px', 'marginTop':'25px', 'maxHeight':'30px'}}
									// commonState.index being -1, will inform the check function
									// 'common' will indicate the backend to check common box
									onClick={()=>check(commonState.index, 'common')}>Check
								</Button>

							</RowBox>
							
						</div>
						<div className="containerBox">
							{ansState.map((cur, index)=>(
								<Box key={`${cur.title}${index}`} borderColor={getBorderColor(cur.errorFound, cur.allCorrect)}>

									<div className="boxTitle">
										<div style={{fontSize:'28px', fontFamily:'Praja'}}>
											{cur.title}
										</div>
										<div className="line" />
									</div>

									<div className="form-group">
										<label htmlFor={`"lemma"${index}`} className="smText">Lemma
										{cur.lemmaError===true&&
											<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
											width={25} height={25} src={cross} alt='wrong'/>
										}
										{cur.lemmaError==='?'&&
											<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
											width={25} height={25} src={question} alt='empty'/>
										}
										</label>
										<Select
											backspaceRemovesValue
											autoFocus={index===0}
											isClearable
											
											name="lemma"
											id={`"lemma"${index}`}
											aria-describedby={`"lemmaError"${index}`}
											
											options={vocab}
											styles={colorStyles}
											maxMenuHeight={200}

											filterOptions={filterOptions}
											filterOption={createFilter({ignoreCase:false, ignoreAccents:false, trim:true,  matchFrom:'start'})}
											
											placeholder={getPlaceholder(cur.lemma, "First")}
											onChange={(selectedOption) => handleSelectChange(index, "lemma", selectedOption )}

											// menuPortalTarget={document.body}
										/>
										
									</div>

									<div className="form-group">
										<label htmlFor={`"pos"${index}`} className="smText">Lexical category
										{cur.posError===true&&
											<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
											width={25} height={25} src={cross} alt='wrong'/>
										}
										{cur.posError==='?'&&
											<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
											width={25} height={25} src={question} alt='empty'/>
										}
										</label>
										<Select
											backspaceRemovesValue
											name="pos"
											id={`"pos"${index}`}
											aria-describedby={`"posError"${index}`}

											options={cur.posOptions}

											placeholder={getPlaceholder(cur.pos, "Second", cur.posOptions)}
											// selectValue ={cur.pos}
											onChange={(selectedOption) => handleSelectChange(index, "pos", selectedOption )}

											// menuPortalTarget={document.querySelector("body")}
										/>	
									</div>

									<Button variant='outline-primary' size='sm' style={{'float':'right', 'marginTop':'5px'}}
										onClick={()=>check(index, 'Box')}>Check
									</Button>

								</Box>
							))}
						</div>
					</div>
				</div>
				
				<div className="KeepRight">
					<Button variant='primary' 
						onClick={()=>check(0, 'All')} ref={node=>setBtn(node)}>Check all
					</Button>
				</div>

			</>
			}
		</div>
	);
};

export default ParticipleIdentification;


// Archives

/*
{cur.addRoot && 
	<>
		<div className="form-group">
			<label htmlFor={`"root"${index}`} className="smText">Root
			{cur.rootError===true&&
				<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
				width={25} height={25} src={cross} alt='wrong'/>
			}
			{cur.rootError==='?'&&
				<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
				width={25} height={25} src={question} alt='empty'/>
			}
			</label>
			<Select
				backspaceRemovesValue
				name="root"
				id={`"root"${index}`}
				aria-describedby={`"rootError"${index}`}

				options={cur.rootOptions}

				placeholder={getPlaceholder(cur.root, "Fourth", cur.rootOptions)}
				// selectValue ={cur.root}
				onChange={(selectedOption) => handleSelectChange(index, "root", selectedOption )}

				// menuPortalTarget={document.querySelector("body")}
			/>
		</div>
		<div className="form-group">
			<label htmlFor={`"root"${index}`} className="smText">
				Root's Lexical Category
				{cur.rootPosError===true&&
					<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
					width={25} height={25} src={cross} alt='wrong'/>
				}
				{cur.rootPosError==='?'&&
					<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
					width={25} height={25} src={question} alt='empty'/>
				}
			</label>
			<Select
				backspaceRemovesValue
				name="rootPos"
				id={`"rootPos"${index}`}
				aria-describedby={`"rootPosError"${index}`}

				options={cur.rootPosOptions}

				placeholder={getPlaceholder(cur.rootPos, "Fifth", cur.rootPosOptions)}
				// selectValue ={cur.rootPos}
				onChange={(selectedOption) => handleSelectChange(index, "rootPos", selectedOption )}

				// menuPortalTarget={document.querySelector("body")}
			/>
		</div>
	</>
}
*/


/* before {cur.addRoot} 
		{!cur.addRoot &&
			<div style={{display:'flex'}}>
				<OverlayTrigger
					key='top'
					placement='top'
					overlay={
						<Tooltip id={`tooltipAdd${index}`}>
							Adds Root Fields
						</Tooltip>
					}
				>
					<Button variant='primary' size='sm' style={{'display':'block'}}
						onClick={e=>addRootFields(index)}>+
					</Button>
				</OverlayTrigger>
				{cur.addRootError&&
					<div className="redText" style={{marginLeft:'10px'}}>
						Please Add Root Fields.
					</div>
				}
			</div>
		} 
*/

/* Inside {cur.addRoot}
		<div style={{display:'flex'}}>
			<OverlayTrigger
				key='top'
				placement='top'
				overlay={
					<Tooltip id={`tooltipRemove${index}`}>
						Removes Root Fields
					</Tooltip>
				}
			>
				<Button variant='danger' size='sm' 
					onClick={e=>removeRootFields(index)}> - 
				</Button>
			</OverlayTrigger>
			{cur.removeRootError&&
				<div className="redText" style={{marginLeft:'10px'}}>
					Please Remove Root Fields.
				</div>
			}
		</div> 
*/