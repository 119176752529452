import React, {useState} from 'react';

import { Link } from 'react-router-dom';
import {Button, Alert} from 'react-bootstrap';

import { auth } from './firebase';

import '../components/css/Form.css';


const ForgotPassword=()=>{
	const [msg, setMsg] =useState('');
	const [error, setError] =useState('');
	
	const [email, setEmail] = useState('');
	const [emailError, setEmailError] = useState(null);

	const handleSubmit = (event) =>{
		event.preventDefault();
		setEmailError(''); setError('');
		auth
			.sendPasswordResetEmail(email)
			.then(()=>{
				setMsg('Check your inbox further instructions !');
			})
			.catch(err => {
				console.log("error-",err.code, err.message);
				switch(err.code){
					case "auth/invalid-email":
					case "auth/user-disabled":
					case "auth/user-not-found":
						setEmailError(err.message);
						setError("Failed to reset password");
						break;
					default:
						break;
				}
			})
	};

	return(
		<form className="wholeForm">
			<h2 className="mb-4" style={{textAlign: "center"}}>Forgot Password</h2>
			{msg && <Alert variant="success">{msg}</Alert>}
			{error && <Alert variant="danger">{error}</Alert>}
			
			<div className="innerForm">
			<div className="formItem">
				<input type="text" name="email" autoFocus required value={email}  
						onChange={e =>setEmail(e.target.value)} style={{backgroundColor: "transparent"}}/>
				<label htmlFor="email" className="label-name">
					<span className="content-name" style={{backgroundColor: "transparent"}}>Email</span>
				</label>
			</div>
			<p className="errorMsg">{emailError}</p>

			
			<div className=" mt-3 textcenter">
				<Button size="md" block onClick={(event) => {return handleSubmit(event)}}>Reset Password</Button>
			</div>

			<div className="btnContainer text-center" style={{fontSize:'120%'}}>
				<Link to="/login" >Login</Link>
			</div>
			</div>
		</form>


		
	);

};

export default ForgotPassword;