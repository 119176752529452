import React from 'react';

import {Row, Col} from "react-bootstrap";
import Form from "react-bootstrap/Form";

import "../../css/Components.css";

const GenderOptions=({updateInput})=>(
	<Form.Group>
		<Form.Check 
			id="gender_m"
			type="radio"
			label="m masculine"
			name="genderOptions"
			onClick={()=>updateInput('gender', 'm')}
		/>
		<Form.Check 
			id="gender_f"
			type="radio"
			label="f feminine"
			name="genderOptions"
			onClick={()=>updateInput('gender', 'f')}
		/>
		<Form.Check 
			id="gender_n"
			type="radio"
			label="n neuter"
			name="genderOptions"
			onClick={()=>updateInput('gender', 'n')}
		/>
	</Form.Group>
);

const CaseOptions=({updateInput})=>(
	<Form.Group>
		<Form.Check 
			id="case_1"
			type="radio"
			label="1 nominative"
			name="caseOptions"
			onClick={()=>updateInput('case', '1')}
		/>
		<Form.Check 
			id="case_v"
			type="radio"
			label="v vocative"
			name="caseOptions"
			onClick={()=>updateInput('case', 'v')}
		/>
		<Form.Check 
			id="case_2"
			type="radio"
			label="2 accusative"
			name="caseOptions"
			onClick={()=>updateInput('case', '2')}
		/>
		<Form.Check 
			id="case_3"
			type="radio"
			label="3 instrumental"
			name="caseOptions"
			onClick={()=>updateInput('case', '3')}
		/>
		<Form.Check 
			id="case_4"
			type="radio"
			label="4 dative"
			name="caseOptions"
			onClick={()=>updateInput('case', '4')}
		/>
		<Form.Check 
			id="case_5"
			type="radio"
			label="5 ablative"
			name="caseOptions"
			onClick={()=>updateInput('case', '5')}
		/>
		<Form.Check 
			id="case_6"
			type="radio"
			label="6 genitive"
			name="caseOptions"
			onClick={()=>updateInput('case', '6')}
		/>
		<Form.Check 
			id="case_7"
			type="radio"
			label="7 locative"
			name="caseOptions"
			onClick={()=>updateInput('case', '7')}
		/>
	</Form.Group>
);

const NumberOptions=({updateInput})=>(
	<Form.Group>
		<Form.Check 
			id="number_s"
			type="radio"
			label="s singular"
			name="numberOptions"
			onClick={()=>updateInput('number', 's')}
		/>
		<Form.Check 
			id="number_d"
			type="radio"
			label="d dual"
			name="numberOptions"
			onClick={()=>updateInput('number', 'd')}
		/>
		<Form.Check 
			id="number_p"
			type="radio"
			label="p plural"
			name="numberOptions"
			onClick={()=>updateInput('number', 'p')}
		/>
	</Form.Group>
);

const Nominal=({inputParts, setInputParts})=>{

	const updateInput=(key, value)=>{
		let newParts = {...inputParts};
		newParts[key]=value;
		setInputParts(newParts);
		console.log(newParts);
	}

	return (
		<>
		<Row hidden={inputParts['i']}>
			<Col md className="interfaceCol">
				<div className="colHeader">
					<span className="spChar">G</span>ender
				</div>
				<GenderOptions updateInput={(key, value)=>updateInput(key, value)}/>
			</Col>
			<Col md className="interfaceCol">
				<div className="colHeader">
					<span className="spChar">C</span>ase
				</div>
				<CaseOptions updateInput={(key, value)=>updateInput(key, value)}/>
			</Col>
			<Col md className="interfaceCol">
				<div className="colHeader">
					<span className="spChar">N</span>umber
				</div>
				<NumberOptions updateInput={(key, value)=>updateInput(key, value)}/>
			</Col>
		</Row>
		<Row>
			<Col className="interfaceCol">
				<div className="colHeader">
					<span className="spChar">I</span>ndeclinable
				</div>
				
				<Form.Check 
					id="i"
					type="radio"
					name="indeclinable"
					label="i indeclinable"
					checked={inputParts['i']}
					onChange={()=>console.log(inputParts['i'])}
					onClick={()=>updateInput('i', !inputParts['i'])}
				/>
			</Col>
		</Row>
		</>
	);
};

export default Nominal;