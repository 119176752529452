import React, { useState, useEffect, useContext } from 'react';

import Title from './Title';
// import Pagination from './Pagination';
import Error from '../Error';

import  {UserContext} from "../../providers/UserProvider";

import '../css/Scroll.css';
import '../css/Components.css';

const Chapters=() => {

	const {currentUser} = useContext(UserContext);
	const [done, setDone] = useState(false);

	const [allChapters, setChapters] = useState([]);

	useEffect(() => {
		const getChapters = async () => {
			const response = await fetch(process.env.REACT_APP_FLASK_API+'get_chaptersList',{
				method: 'POST',
				cache: 'no-cache',
				headers: {'Content-Type': 'application/json'},
				body: JSON.stringify({email: currentUser.email})
			});
			// const response = await fetch('/get_chaptersList');
			const data = await response.json();
			console.log(data);
			setChapters(data.list);
			
			setDone(true);
		}

		getChapters();
   }, [currentUser.email]);
	
	// // Pagination
	// const [currentPage, setCurrentPage] = useState(1);
	// const objsPerPage = 5;

	// // Get current Title
	// const indexOfLastObj = currentPage * objsPerPage;
	// const indexOfFirstObj = indexOfLastObj - objsPerPage;
	// const currentObjs = allChapters.slice(indexOfFirstObj, indexOfLastObj);

	// // Change Page
	// const paginate =pageNumber => setCurrentPage(pageNumber);

   if(done && allChapters.length===0){
		return <Error />
	}
	return(
		<div>
			<div style={{textAlign:'center', width:'100%'}}>
				<h3>All Chapters</h3>
			</div>

			<div className="titleContainer chScroll">
			{allChapters.map(chapter => (
				<Title key={chapter.ch} head={`${chapter.type} ${chapter.ch}`} disabled={chapter.disabled}
						title={chapter.title} progress={chapter.progress} to={`/ch=${chapter.ch}`}
						restricted={chapter.restricted}
						/>
			))}
			</div>
		</div>
	);
};

export default Chapters; 