import React from 'react';

import Sandhi from './Sandhi';
import Devanagari from './Devanagari';
import Transliteration from './Transliteration';
import Identification from './Identification';

import Syntax from './Syntax';
import Translation from './Translation';

// Compounds
import Vigraha from './compounds/Vigraha';
import CompoundInflectionAndIdentification from './compounds/CompoundInflectionAndIdentification';
import CompoundSandhiAnalysis from './compounds/CompoundSandhiAnalysis';
import CompoundConstituents from './compounds/compoundConstituents';

// Drills
import PronounDeclension from './PronounDeclension';
import VerbIdentificaitonDrill from './VerbIdentificationDrill';

// import RightSidebar from '../RightSidebar';
// import {UserContext} from '../../providers/UserProvider';

import '../css/Components.css';
import ParticipleIdentification from './ParticipleIdentification';

const QnA =({curQ, setQ, setPartInst, ch, curPi, qIndex, curQComplete,
					setCurQComplete, btnClicked, setAnsSaved, partTwoBtn}) =>{
	
	// const {currentUser} =useContext(UserContext); 

	const partComponent = (curPart, takeInput) =>{
		switch(curPart){
			case "transliteration":
				return <Transliteration curQ={curQ} setQ={setQ} setPartInst={setPartInst} 
							ch={ch} curPi={curPi} qIndex={qIndex} setCurQComplete={setCurQComplete} 
							btnClicked={btnClicked} setAnsSaved={setAnsSaved} partTwoBtn={partTwoBtn}/>;
			case "devanagari":
				return <Devanagari curQ={curQ} setQ={setQ} setPartInst={setPartInst} 
							ch={ch} curPi={curPi} qIndex={qIndex} setCurQComplete={setCurQComplete} 
							btnClicked={btnClicked} setAnsSaved={setAnsSaved} partTwoBtn={partTwoBtn}/>;
			case "sandhi":
				return <Sandhi curQ={curQ} setPartInst={setPartInst} ch={ch} curPi={curPi} qIndex={qIndex}
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}/>;
			case "sandhi-analysis":
				return <Sandhi curQ={curQ} setPartInst={setPartInst} ch={ch} curPi={curPi} qIndex={qIndex}
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}/>;

			case "identification":
				return <Identification curQ={curQ} setPartInst={setPartInst} ch={ch} curPi={curPi} qIndex={qIndex} 
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}/>;
			case "verb-identification":
				return <Identification curQ={curQ} setPartInst={setPartInst} ch={ch} curPi={curPi} qIndex={qIndex}
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}/>;
			
			case "syntax":
				return <Syntax curQ={curQ} ch={ch} curPi={curPi} qIndex={qIndex} setPartInst={setPartInst}
							curQComplete={curQComplete} setCurQComplete={setCurQComplete} btnClicked={btnClicked} 
							setAnsSaved={setAnsSaved}/>;
			case "translation":
				return <Translation curQ={curQ} ch={ch} curPi={curPi} qIndex={qIndex} setPartInst={setPartInst}
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}/>;

			// Compounds: Ch 20
			case "compound-inflection-and-identification":
				return <CompoundInflectionAndIdentification curQ={curQ} setPartInst={setPartInst} ch={ch} curPi={curPi} qIndex={qIndex}
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}/>;
			case "compound-sandhi-analysis":
				return <CompoundSandhiAnalysis curQ={curQ} setPartInst={setPartInst} ch={ch} curPi={curPi} qIndex={qIndex} 
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}/>;
			case "compound-constituents":
				return <CompoundConstituents curQ={curQ} setPartInst={setPartInst} ch={ch} curPi={curPi} qIndex={qIndex}
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}/>;
			case "vigraha":
				return <Vigraha curQ={curQ} setPartInst={setPartInst} ch={ch} curPi={curPi} qIndex={qIndex} 
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}/>;

			// Drills
			case "pronoun-declension":
				return <PronounDeclension curQ={curQ} setPartInst={setPartInst} ch={ch} 
							curPi={curPi} qIndex={qIndex} setCurQComplete={setCurQComplete}/>;
			case "verb-conjugation":
				return <PronounDeclension curQ={curQ} setPartInst={setPartInst} ch={ch} 
							curPi={curPi} qIndex={qIndex} setCurQComplete={setCurQComplete}/>;
			case "verb-identification-drill":
				return <VerbIdentificaitonDrill curQ={curQ} setPartInst={setPartInst} ch={ch} 
							curPi={curPi} qIndex={qIndex} setCurQComplete={setCurQComplete}/>;
			case "participle-identification":
				return <ParticipleIdentification curQ={curQ} setPartInst={setPartInst} ch={ch} curPi={curPi} qIndex={qIndex}
							setCurQComplete={setCurQComplete} btnClicked={btnClicked} setAnsSaved={setAnsSaved}/>;

			default:
				if (takeInput)
					return "Work in progress ! Please come back later !";
				else
					return '';
		}
	}

	// console.log('3. @ QnA = ', curQ)
	return (
		<div>
			<div className="QnA widthVW">
				{/* {console.log("@QnA", curQ.curPart, curQ.takeInput)} */}
				{partComponent(curQ.curPart, curQ.takeInput)}
			</div>
		</div>
	);
};

export default QnA;