import React from 'react';

import { OverlayTrigger, Popover } from 'react-bootstrap';
	
import { FaSearch } from 'react-icons/fa';
import { CgDanger } from 'react-icons/cg';
import { GoSettings } from 'react-icons/go';
import { AiOutlineMail, AiOutlineCheckSquare, AiOutlineCloseCircle } from 'react-icons/ai';

import './StudentStyles.css';


const SearchBar =({query, setQuery})=>{
	return (		
		<div className="searchContainer">
			<FaSearch className="searchIcon" />
			<input className="searchInput" type='text' value={query} 
						onChange={(e)=>setQuery(e.target.value)}/>
		</div>
	);
};

const FilterOptions =({sortType, setSortType, studentType, setStudentType})=>{
	return (
		<div className="filtersWhole">
			<div className="filterIcon"><GoSettings size={21} color="var(--primary-color)"/></div>
			<div className="filterBox">
				<div className="studentOptionSection">
					<div><strong>Students</strong></div>
					<div className="studentOption">
						<input type="radio" name="students" checked={studentType==="all"}
									id="all" value="all" onChange={e=>setStudentType(e.target.value)}/>
						<label htmlFor="all">All</label>
					</div>
					{/* <div className="studentOption">
						<input type="radio" name="students" checked={studentType==="iiit"}
									id="iiit" value="iiit" onChange={e=>setStudentType(e.target.value)}/>
						<label htmlFor="iiit">IIIT</label>
					</div> */}
				</div>
				<div className="studentOptionSection">
					<div style={{marginTop:'10px'}}><strong>Sort Progress</strong></div>
					<div className="studentOption">
						<input type="radio" name="progress" checked={sortType==="asc"}
									id="asc" value="asc" onChange={e=>setSortType(e.target.value)}/>
						<label htmlFor="asc">Ascending</label>
					</div>
					<div className="studentOption">
						<input type="radio" name="progress" checked={sortType==="dsc"}
									id="dsc" value="dsc" onChange={e=>setSortType(e.target.value)}/>
						<label htmlFor="dsc">Descending</label>
					</div>
				</div>
			</div>
		</div>
	);
};

const Icon=({data})=>{
	if (Object.keys(data).includes('variant')){
		if (data['variant']==='success')
			return (
				<OverlayTrigger
					placement='top'
					overlay={
						<Popover>
							<Popover.Header as="h3" 
								style={{textAlign:'center', 
											backgroundColor:'var(--popoverGreen)'
										}}
							>
								Account Created
							</Popover.Header>
						</Popover>
					}
				>
					<AiOutlineCheckSquare color='green'/>
				</OverlayTrigger>
			);
		else if (data['variant']==='danger')
			return (
				<OverlayTrigger
					placement='top'
					overlay={
						<Popover>
							<Popover.Header as="h3" 
								style={{textAlign:'center', 
											backgroundColor:'var(--popoverRed)'
										}}
							>
								Error
							</Popover.Header>
							<Popover.Body>
								{data.err}
							</Popover.Body>
						</Popover>
					}
				>
					<AiOutlineCloseCircle color='red'/>
				</OverlayTrigger>
			);
		else {
			console.log('unknown variant:', data);
			return <CgDanger color='goldenrod' />
		}
	}
	else
		return <AiOutlineMail color='var(--primary-color)' />
};

export {SearchBar, FilterOptions, Icon};