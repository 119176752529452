import React, { useState } from "react";
import {Popover, OverlayTrigger, Modal} from 'react-bootstrap';

import BigText  from "../helpers/BigText";
import SandhiHelp from "./SandhiHelp";

import '../../css/Components.css';

const GENERIC_HELP =[
	"appendixD2.1-VowelSandhi.pdf",
	"appendixD2.2.1-FinalStopSandhi.pdf",
	"appendixD2.2.2-FinalNasalSandhi.pdf",
	"appendixD2.2.3-VisargaSandhi.pdf"
]

const generateTokens =(units, setSandhiHelpOpen, setSandhiAnalysisHelpOpen, setPDFfile) =>{
	let tokens = [];
	console.log('units:', units);
	for (let i=0; i<units.length; i++){
		let unit = units[i];
		// if true, no need of popover and highlighting
		if(unit['type'] === 'm')
			tokens.push(<span key={i}>{unit['text']}</span>);
		
		// else, error which needs to be highlighted
		else{
			// console.log("popUP2:", unit)
			let styleColor ={}
			let backgroundColor={}
			if (unit['popover']['color']==="red") {
				styleColor ={backgroundColor:"red"};
				backgroundColor="var(--popoverRed)";
			}
			else {
				styleColor ={backgroundColor:"rgb(0, 123, 255)"};
				backgroundColor="var(--popoverBlue)";
			}

			let popover =(
				<Popover id="popover-basic">
					<Popover.Header as="h3"  style={{backgroundColor: backgroundColor, textAlign:"center"}}>
						{unit['popover']['head']}
					</Popover.Header>
					<Popover.Body>
						{unit['popover']['message']}
					</Popover.Body>
				</Popover>
			);
			

			if (unit['help']['type']==="static") {
				tokens.push(
					<a key={i} target="_blank" rel="noopener noreferrer">
						<OverlayTrigger key={i} trigger={["hover", "focus"]} placement="right" overlay={popover}>
							<span style={styleColor}>
								{unit['text']}
							</span>
						</OverlayTrigger>
					</a>
				);
			}
			// else if (unit['help']['type']==="link") {
			// 	tokens.push(
			// 		<a key={i} href={unit['help']['target']} target="_blank" rel="noopener noreferrer" onClick={()=>{setPDFfile(unit['help']['pdf']);setSandhiAnalysisHelpOpen(true)}}>
			// 			<OverlayTrigger key={i} trigger={["hover", "focus"]} placement="right" overlay={popover}>
			// 				<span style={styleColor}>
			// 					{unit['text']}
			// 				</span>
			// 			</OverlayTrigger>
			// 		</a>
			// 	);
			// }
			else if (unit['help']['type']==="file") {
				// console.log(unit['help']['pdf'], GENERIC_HELP.includes(unit['help']['pdf']));
				if (GENERIC_HELP.includes(unit['help']['pdf'])){
					tokens.push(
						<a key={i} target="_blank" rel="noopener noreferrer" onClick={()=>{setPDFfile(unit['help']['pdf']);setSandhiHelpOpen(true)}}>
							<OverlayTrigger key={i} trigger={["hover", "focus"]} placement="right" overlay={popover}>
								<span style={styleColor}>
									{unit['text']}
								</span>
							</OverlayTrigger>
						</a>
					);
				}
				else {
					tokens.push(
						<a key={i} target="_blank" rel="noopener noreferrer" onClick={()=>{setPDFfile(unit['help']['pdf']);setSandhiAnalysisHelpOpen(true)}}>
							<OverlayTrigger key={i} trigger={["hover", "focus"]} placement="right" overlay={popover}>
								<span style={styleColor}>
									{unit['text']}
								</span>
							</OverlayTrigger>
						</a>
					);
				}
			}
			else { // Means generic help 
				tokens.push(
					<a key={i} target="_blank" rel="noopener noreferrer" onClick={()=>setSandhiHelpOpen(true)}>
						<OverlayTrigger key={i} trigger={["hover", "focus"]} placement="right" overlay={popover}>
							<span style={styleColor}>
								{unit['text']}
							</span>
						</OverlayTrigger>
					</a>
				);
			}
		}
	};

	return tokens;
};


const SandhiAnalysisPopUp = (props) => {
	// Part 1 help
	const [sandhiHelpOpen, setSandhiHelpOpen] =useState(false);
	
	// Part 2 help
	const [pdfFile, setPDFfile]=useState("appendixD2.1-VowelSandhi.pdf");
	const [sandhiAnalysisHelpOpen, setSandhiAnalysisHelpOpen] =useState(false);

	const SandhiAnalysisHelp=({open, setOpen}) =>{
		
		return (
			<Modal 
				show={open} 
				onHide={()=>setOpen(false)} 
				dialogClassName="pdfmodal-90w"
			>
				<embed
					title="helpPdf"
					src={require("../../../docs/"+pdfFile)+"#zoom=150"}
					frameBorder="0"
					scrolling="auto"
					height="100%"
					width="100%"
				/>
			</Modal>
		);
	}

	console.log(props)
	let devaTokens = generateTokens(props.devaUnits, setSandhiHelpOpen, setSandhiAnalysisHelpOpen, setPDFfile);
	let romanTokens =generateTokens(props.romanUnits, setSandhiHelpOpen, setSandhiAnalysisHelpOpen, setPDFfile);

	return(
		<>
			<span style={{fontFamily:'Praja'}}>
				<BigText ch={props.ch} text={devaTokens} />
			</span>
			<br/>
			<SandhiHelp open={sandhiHelpOpen} setOpen={setSandhiHelpOpen} helpPDF={pdfFile} setHelpPDF={setPDFfile}/>
			<SandhiAnalysisHelp open={sandhiAnalysisHelpOpen} setOpen={setSandhiAnalysisHelpOpen} />
			{romanTokens}
		</>
	);
};

export default SandhiAnalysisPopUp;