export const vocab = [
	{'value': 'agni', 'label': 'agni', 'attrib': [{'pos': 'm', 'defs': 'fire'}], 'isLemma': 1},
	{'value': 'agra', 'label': 'agra', 'attrib': [{'pos': 'n', 'defs': 'tip, beginning'}], 'isLemma': 1},
	{'value': 'aNgulIya', 'label': 'aNgulIya', 'attrib': [{'pos': 'n', 'defs': 'ring'}], 'isLemma': 1},
	{'value': 'atas', 'label': 'atas', 'attrib': [{'pos': 'dem_adv', 'defs': 'hence'}], 'isLemma': 1},
	{'value': 'ati', 'label': 'ati', 'attrib': [{'pos': 'preverb', 'defs': 'beyond'}], 'isLemma': 1},
	{'value': 'ati_i', 'label': 'ati_i', 'attrib': [{'pos': 'vt2a', 'defs': 'go beyond, pass'}], 'isLemma': 1},
	{'value': 'atiTi', 'label': 'atiTi', 'attrib': [{'pos': 'm', 'defs': 'guest'}], 'isLemma': 1},
	{'value': 'atiyat', 'label': 'atiyat', 'attrib': [{'pos': 'prap', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atIta', 'label': 'atIta', 'attrib': [{'pos': 'ppp', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atItavat', 'label': 'atItavat', 'attrib': [{'pos': 'pap', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atItavya', 'label': 'atItavya', 'attrib': [{'pos': 'ger', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atItya', 'label': 'atItya', 'attrib': [{'pos': 'abs', 'defs': 'go beyond, pass'}, {'pos': 'ger', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atIyamAna', 'label': 'atIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atIyAna', 'label': 'atIyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atIyivas', 'label': 'atIyivas', 'attrib': [{'pos': 'pfap', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'attavya', 'label': 'attavya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'attum', 'label': 'attum', 'attrib': [{'pos': 'inf', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'attvA', 'label': 'attvA', 'attrib': [{'pos': 'abs', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'atyayanIya', 'label': 'atyayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atyetum', 'label': 'atyetum', 'attrib': [{'pos': 'inf', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atyezyat', 'label': 'atyezyat', 'attrib': [{'pos': 'fap', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atyezyamARa', 'label': 'atyezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atra', 'label': 'atra', 'attrib': [{'pos': 'dem_adv', 'defs': 'here'}], 'isLemma': 1},
	{'value': 'atsyat', 'label': 'atsyat', 'attrib': [{'pos': 'fap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'atsyamAna', 'label': 'atsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'aTa', 'label': 'aTa', 'attrib': [{'pos': 'pcl', 'defs': 'then, now'}], 'isLemma': 1},
	{'value': 'ad', 'label': 'ad', 'attrib': [{'pos': 'vt2a', 'defs': 'eat'}], 'isLemma': 1},
	{'value': 'adat', 'label': 'adat', 'attrib': [{'pos': 'prap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'adanIya', 'label': 'adanIya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'adas', 'label': 'adas', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'that'}], 'isLemma': 1},
	{'value': 'adya', 'label': 'adya', 'attrib': [{'pos': 'adv', 'defs': 'today'}], 'isLemma': 1},
	{'value': 'adyamAna', 'label': 'adyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'aDi', 'label': 'aDi', 'attrib': [{'pos': 'preverb', 'defs': 'over'}], 'isLemma': 1},
	{'value': 'aDijagAna', 'label': 'aDijagAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDi_i', 'label': 'aDi_i', 'attrib': [{'pos': 'vt2m', 'defs': 'go over, study, read, recite'}], 'isLemma': 1},
	{'value': 'aDIta', 'label': 'aDIta', 'attrib': [{'pos': 'ppp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDItavat', 'label': 'aDItavat', 'attrib': [{'pos': 'pap', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDItya', 'label': 'aDItya', 'attrib': [{'pos': 'abs', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDIyamAna', 'label': 'aDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDIyAna', 'label': 'aDIyAna', 'attrib': [{'pos': 'prmp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDunA', 'label': 'aDunA', 'attrib': [{'pos': 'adv', 'defs': 'now'}], 'isLemma': 1},
	{'value': 'aDyayanIya', 'label': 'aDyayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDyetavya', 'label': 'aDyetavya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDyetum', 'label': 'aDyetum', 'attrib': [{'pos': 'inf', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDyeya', 'label': 'aDyeya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDyezyamARa', 'label': 'aDyezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'aDvaryu', 'label': 'aDvaryu', 'attrib': [{'pos': 'm', 'defs': 'adhvaryu'}], 'isLemma': 1},
	{'value': 'anu', 'label': 'anu', 'attrib': [{'pos': 'preverb', 'defs': 'after'}], 'isLemma': 1},
	{'value': 'anugacCat', 'label': 'anugacCat', 'attrib': [{'pos': 'prap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugata', 'label': 'anugata', 'attrib': [{'pos': 'ppp', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugatavat', 'label': 'anugatavat', 'attrib': [{'pos': 'pap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugatya', 'label': 'anugatya', 'attrib': [{'pos': 'abs', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugantavya', 'label': 'anugantavya', 'attrib': [{'pos': 'ger', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugantum', 'label': 'anugantum', 'attrib': [{'pos': 'inf', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugamanIya', 'label': 'anugamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugamizyat', 'label': 'anugamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugamizyamARa', 'label': 'anugamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugamya', 'label': 'anugamya', 'attrib': [{'pos': 'abs', 'defs': 'go after, follow'}, {'pos': 'ger', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugamyamAna', 'label': 'anugamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anujaganvas', 'label': 'anujaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anujagmAna', 'label': 'anujagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anujagmivas', 'label': 'anujagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anujajYAna', 'label': 'anujajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujajYivas', 'label': 'anujajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujAnat', 'label': 'anujAnat', 'attrib': [{'pos': 'prap', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYA', 'label': 'anujYA', 'attrib': [{'pos': 'f', 'defs': 'permission'}], 'isLemma': 1},
	{'value': 'anujYAta', 'label': 'anujYAta', 'attrib': [{'pos': 'ppp', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAtavat', 'label': 'anujYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAtavya', 'label': 'anujYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAtum', 'label': 'anujYAtum', 'attrib': [{'pos': 'inf', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAnIya', 'label': 'anujYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAya', 'label': 'anujYAya', 'attrib': [{'pos': 'abs', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAyamAna', 'label': 'anujYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAsyat', 'label': 'anujYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYAsyamAna', 'label': 'anujYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujYeya', 'label': 'anujYeya', 'attrib': [{'pos': 'ger', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anu_A_gam', 'label': 'anu_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come after, follow'}], 'isLemma': 1},
	{'value': 'anu_iz', 'label': 'anu_iz', 'attrib': [{'pos': 'vt6a', 'defs': 'seek after, search for'}], 'isLemma': 1},
	{'value': 'anu_gam', 'label': 'anu_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go after, follow'}], 'isLemma': 1},
	{'value': 'anu_jYA', 'label': 'anu_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'permit, assent'}], 'isLemma': 1},
	{'value': 'anu_pat', 'label': 'anu_pat', 'attrib': [{'pos': 'vt1a', 'defs': 'fly after, chase'}], 'isLemma': 1},
	{'value': 'anupatat', 'label': 'anupatat', 'attrib': [{'pos': 'prap', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatanIya', 'label': 'anupatanIya', 'attrib': [{'pos': 'ger', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatita', 'label': 'anupatita', 'attrib': [{'pos': 'ppp', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatitavat', 'label': 'anupatitavat', 'attrib': [{'pos': 'pap', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatitavya', 'label': 'anupatitavya', 'attrib': [{'pos': 'ger', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatitum', 'label': 'anupatitum', 'attrib': [{'pos': 'inf', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatizyat', 'label': 'anupatizyat', 'attrib': [{'pos': 'fap', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatizyamARa', 'label': 'anupatizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatya', 'label': 'anupatya', 'attrib': [{'pos': 'abs', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatyamAna', 'label': 'anupatyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupAtya', 'label': 'anupAtya', 'attrib': [{'pos': 'ger', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupetAna', 'label': 'anupetAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupetivas', 'label': 'anupetivas', 'attrib': [{'pos': 'pfap', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anta', 'label': 'anta', 'attrib': [{'pos': 'm', 'defs': 'end, edge, border'}], 'isLemma': 1},
	{'value': 'anna', 'label': 'anna', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}, {'pos': 'n', 'defs': 'food'}], 'isLemma': 1},
	{'value': 'anna', 'label': 'anna', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}, {'pos': 'n', 'defs': 'food'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'annavat', 'label': 'annavat', 'attrib': [{'pos': 'pap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'anya', 'label': 'anya', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'other, else'}], 'isLemma': 1},
	{'value': 'anyatas', 'label': 'anyatas', 'attrib': [{'pos': 'pron_adv', 'defs': 'from elsewhere'}], 'isLemma': 1},
	{'value': 'anyatra', 'label': 'anyatra', 'attrib': [{'pos': 'pron_adv', 'defs': 'elsewhere'}], 'isLemma': 1},
	{'value': 'anyaTA', 'label': 'anyaTA', 'attrib': [{'pos': 'pron_adv', 'defs': 'otherwise'}], 'isLemma': 1},
	{'value': 'anyadA', 'label': 'anyadA', 'attrib': [{'pos': 'pron_adv', 'defs': 'at another time'}], 'isLemma': 1},
	{'value': 'anvAgacCat', 'label': 'anvAgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAgata', 'label': 'anvAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAgatavat', 'label': 'anvAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAgatya', 'label': 'anvAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAgantavya', 'label': 'anvAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAgantum', 'label': 'anvAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAgamanIya', 'label': 'anvAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAgamizyat', 'label': 'anvAgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAgamizyamARa', 'label': 'anvAgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAgamya', 'label': 'anvAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come after, follow'}, {'pos': 'ger', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAgamyamAna', 'label': 'anvAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAjaganvas', 'label': 'anvAjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAjagmAna', 'label': 'anvAjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvAjagmivas', 'label': 'anvAjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvicCat', 'label': 'anvicCat', 'attrib': [{'pos': 'prap', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvizwa', 'label': 'anvizwa', 'attrib': [{'pos': 'ppp', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvizwavat', 'label': 'anvizwavat', 'attrib': [{'pos': 'pap', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvizya', 'label': 'anvizya', 'attrib': [{'pos': 'abs', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvizyamARa', 'label': 'anvizyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvIzARa', 'label': 'anvIzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvIzivas', 'label': 'anvIzivas', 'attrib': [{'pos': 'pfap', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvezaRIya', 'label': 'anvezaRIya', 'attrib': [{'pos': 'ger', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvezizyat', 'label': 'anvezizyat', 'attrib': [{'pos': 'fap', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvezizyamARa', 'label': 'anvezizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvezwavya', 'label': 'anvezwavya', 'attrib': [{'pos': 'ger', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvezwum', 'label': 'anvezwum', 'attrib': [{'pos': 'inf', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvezya', 'label': 'anvezya', 'attrib': [{'pos': 'ger', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'apa', 'label': 'apa', 'attrib': [{'pos': 'preverb', 'defs': 'away'}], 'isLemma': 1},
	{'value': 'apakraMsyat', 'label': 'apakraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakraMsyamAna', 'label': 'apakraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakramaRIya', 'label': 'apakramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakramitavya', 'label': 'apakramitavya', 'attrib': [{'pos': 'ger', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakramitum', 'label': 'apakramitum', 'attrib': [{'pos': 'inf', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakramya', 'label': 'apakramya', 'attrib': [{'pos': 'abs', 'defs': 'go away, retreat'}, {'pos': 'ger', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakramyamARa', 'label': 'apakramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakrAnta', 'label': 'apakrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakrAntavat', 'label': 'apakrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakrAmat', 'label': 'apakrAmat', 'attrib': [{'pos': 'prap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakrAmyat', 'label': 'apakrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apagacCat', 'label': 'apagacCat', 'attrib': [{'pos': 'prap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagata', 'label': 'apagata', 'attrib': [{'pos': 'ppp', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagatavat', 'label': 'apagatavat', 'attrib': [{'pos': 'pap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagatya', 'label': 'apagatya', 'attrib': [{'pos': 'abs', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagantavya', 'label': 'apagantavya', 'attrib': [{'pos': 'ger', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagantum', 'label': 'apagantum', 'attrib': [{'pos': 'inf', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagamanIya', 'label': 'apagamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagamizyat', 'label': 'apagamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagamizyamARa', 'label': 'apagamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagamya', 'label': 'apagamya', 'attrib': [{'pos': 'abs', 'defs': 'go away'}, {'pos': 'ger', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagamyamAna', 'label': 'apagamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apacakramARa', 'label': 'apacakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apacakramvas', 'label': 'apacakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apajaganvas', 'label': 'apajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apajagmAna', 'label': 'apajagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apajagmivas', 'label': 'apajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apajahfvas', 'label': 'apajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apajahrARa', 'label': 'apajahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apa_kram', 'label': 'apa_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'go away, retreat'}], 'isLemma': 1},
	{'value': 'apa_gam', 'label': 'apa_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go away'}], 'isLemma': 1},
	{'value': 'apa_hf', 'label': 'apa_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'take away'}], 'isLemma': 1},
	{'value': 'apaharaRIya', 'label': 'apaharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apaharat', 'label': 'apaharat', 'attrib': [{'pos': 'prap', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apaharamARa', 'label': 'apaharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apaharizyat', 'label': 'apaharizyat', 'attrib': [{'pos': 'fap', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apaharizyamARa', 'label': 'apaharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahartavya', 'label': 'apahartavya', 'attrib': [{'pos': 'ger', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahartum', 'label': 'apahartum', 'attrib': [{'pos': 'inf', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahArya', 'label': 'apahArya', 'attrib': [{'pos': 'ger', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahfta', 'label': 'apahfta', 'attrib': [{'pos': 'ppp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahftavat', 'label': 'apahftavat', 'attrib': [{'pos': 'pap', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahftya', 'label': 'apahftya', 'attrib': [{'pos': 'abs', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahriyamARa', 'label': 'apahriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'api', 'label': 'api', 'attrib': [{'pos': 'pcl', 'defs': 'even, also, too'}], 'isLemma': 1},
	{'value': 'apsaras', 'label': 'apsaras', 'attrib': [{'pos': 'f', 'defs': 'naiad'}], 'isLemma': 1},
	{'value': 'aBi', 'label': 'aBi', 'attrib': [{'pos': 'preverb', 'defs': 'toward'}], 'isLemma': 1},
	{'value': 'aBi_A_gam', 'label': 'aBi_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come up to'}], 'isLemma': 1},
	{'value': 'aBi_vad', 'label': 'aBi_vad', 'attrib': [{'pos': 'vt1a', 'defs': 'speak to'}], 'isLemma': 1},
	{'value': 'aBivadat', 'label': 'aBivadat', 'attrib': [{'pos': 'prap', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'aBivadanIya', 'label': 'aBivadanIya', 'attrib': [{'pos': 'ger', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'aBivaditavya', 'label': 'aBivaditavya', 'attrib': [{'pos': 'ger', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'aBivaditum', 'label': 'aBivaditum', 'attrib': [{'pos': 'inf', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'aBivadizyat', 'label': 'aBivadizyat', 'attrib': [{'pos': 'fap', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'aBivadizyamARa', 'label': 'aBivadizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'aBivAdya', 'label': 'aBivAdya', 'attrib': [{'pos': 'ger', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'aByAgacCat', 'label': 'aByAgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAgata', 'label': 'aByAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAgatavat', 'label': 'aByAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAgatya', 'label': 'aByAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAgantavya', 'label': 'aByAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAgantum', 'label': 'aByAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAgamanIya', 'label': 'aByAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAgamizyat', 'label': 'aByAgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAgamizyamARa', 'label': 'aByAgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAgamya', 'label': 'aByAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come up to'}, {'pos': 'ger', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAgamyamAna', 'label': 'aByAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAjaganvas', 'label': 'aByAjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAjagmAna', 'label': 'aByAjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByAjagmivas', 'label': 'aByAjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'aByudita', 'label': 'aByudita', 'attrib': [{'pos': 'ppp', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'aByuditavat', 'label': 'aByuditavat', 'attrib': [{'pos': 'pap', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'aByudya', 'label': 'aByudya', 'attrib': [{'pos': 'abs', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'aByudyamAna', 'label': 'aByudyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'aByUdAna', 'label': 'aByUdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'aByUdivas', 'label': 'aByUdivas', 'attrib': [{'pos': 'pfap', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'ayanIya', 'label': 'ayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'araRi', 'label': 'araRi', 'attrib': [{'pos': 'f', 'defs': 'kindling stick'}], 'isLemma': 1},
	{'value': 'ari', 'label': 'ari', 'attrib': [{'pos': 'm', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'arjuna', 'label': 'arjuna', 'attrib': [{'pos': 'm_pn', 'defs': 'Arjuna'}], 'isLemma': 1},
	{'value': 'arTa', 'label': 'arTa', 'attrib': [{'pos': 'm', 'defs': 'object, meaning, purpose'}], 'isLemma': 1},
	{'value': 'arh', 'label': 'arh', 'attrib': [{'pos': 'vt1a', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 1},
	{'value': 'arhaRIya', 'label': 'arhaRIya', 'attrib': [{'pos': 'ger', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhat', 'label': 'arhat', 'attrib': [{'pos': 'prap', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhita', 'label': 'arhita', 'attrib': [{'pos': 'ppp', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhitavat', 'label': 'arhitavat', 'attrib': [{'pos': 'pap', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhitavya', 'label': 'arhitavya', 'attrib': [{'pos': 'ger', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhitum', 'label': 'arhitum', 'attrib': [{'pos': 'inf', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhitvA', 'label': 'arhitvA', 'attrib': [{'pos': 'abs', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhizyat', 'label': 'arhizyat', 'attrib': [{'pos': 'fap', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhizyamARa', 'label': 'arhizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhya', 'label': 'arhya', 'attrib': [{'pos': 'ger', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'alpa', 'label': 'alpa', 'attrib': [{'pos': 'adj', 'defs': 'small'}], 'isLemma': 1},
	{'value': 'ava', 'label': 'ava', 'attrib': [{'pos': 'preverb', 'defs': 'down'}], 'isLemma': 1},
	{'value': 'avagacCat', 'label': 'avagacCat', 'attrib': [{'pos': 'prap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagata', 'label': 'avagata', 'attrib': [{'pos': 'ppp', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagatavat', 'label': 'avagatavat', 'attrib': [{'pos': 'pap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagatya', 'label': 'avagatya', 'attrib': [{'pos': 'abs', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagantavya', 'label': 'avagantavya', 'attrib': [{'pos': 'ger', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagantum', 'label': 'avagantum', 'attrib': [{'pos': 'inf', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagamanIya', 'label': 'avagamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagamizyat', 'label': 'avagamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagamizyamARa', 'label': 'avagamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagamya', 'label': 'avagamya', 'attrib': [{'pos': 'abs', 'defs': 'go down, descend, understand'}, {'pos': 'ger', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagamyamAna', 'label': 'avagamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avajaganvas', 'label': 'avajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avajagmAna', 'label': 'avajagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avajagmivas', 'label': 'avajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avajahfvas', 'label': 'avajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avajahrARa', 'label': 'avajahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'ava_Ap', 'label': 'ava_Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'reach, attain, obtain'}], 'isLemma': 1},
	{'value': 'ava_gam', 'label': 'ava_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go down, descend, understand'}], 'isLemma': 1},
	{'value': 'ava_sTA', 'label': 'ava_sTA', 'attrib': [{'pos': 'vi1m', 'defs': 'remain'}], 'isLemma': 1},
	{'value': 'ava_hf', 'label': 'ava_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'take down'}], 'isLemma': 1},
	{'value': 'avatasTAna', 'label': 'avatasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avatizWamAna', 'label': 'avatizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasTAtavya', 'label': 'avasTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasTAtum', 'label': 'avasTAtum', 'attrib': [{'pos': 'inf', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasTAnIya', 'label': 'avasTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasTAya', 'label': 'avasTAya', 'attrib': [{'pos': 'abs', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasTAsyamAna', 'label': 'avasTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasTita', 'label': 'avasTita', 'attrib': [{'pos': 'ppp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasTitavat', 'label': 'avasTitavat', 'attrib': [{'pos': 'pap', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasTIyamAna', 'label': 'avasTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasTeya', 'label': 'avasTeya', 'attrib': [{'pos': 'ger', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avaharaRIya', 'label': 'avaharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avaharat', 'label': 'avaharat', 'attrib': [{'pos': 'prap', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avaharamARa', 'label': 'avaharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avaharizyat', 'label': 'avaharizyat', 'attrib': [{'pos': 'fap', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avaharizyamARa', 'label': 'avaharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahartavya', 'label': 'avahartavya', 'attrib': [{'pos': 'ger', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahartum', 'label': 'avahartum', 'attrib': [{'pos': 'inf', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahArya', 'label': 'avahArya', 'attrib': [{'pos': 'ger', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahfta', 'label': 'avahfta', 'attrib': [{'pos': 'ppp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahftavat', 'label': 'avahftavat', 'attrib': [{'pos': 'pap', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahftya', 'label': 'avahftya', 'attrib': [{'pos': 'abs', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahriyamARa', 'label': 'avahriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avApanIya', 'label': 'avApanIya', 'attrib': [{'pos': 'ger', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avApAna', 'label': 'avApAna', 'attrib': [{'pos': 'pfpp', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avApivas', 'label': 'avApivas', 'attrib': [{'pos': 'pfap', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avApta', 'label': 'avApta', 'attrib': [{'pos': 'ppp', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avAptavat', 'label': 'avAptavat', 'attrib': [{'pos': 'pap', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avAptavya', 'label': 'avAptavya', 'attrib': [{'pos': 'ger', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avAptum', 'label': 'avAptum', 'attrib': [{'pos': 'inf', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avApnuvat', 'label': 'avApnuvat', 'attrib': [{'pos': 'prap', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avApya', 'label': 'avApya', 'attrib': [{'pos': 'abs', 'defs': 'reach, attain, obtain'}, {'pos': 'ger', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avApyamAna', 'label': 'avApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avApsyat', 'label': 'avApsyat', 'attrib': [{'pos': 'fap', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avApsyamAna', 'label': 'avApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avi', 'label': 'avi', 'attrib': [{'pos': 'm, f', 'defs': 'ram/ewe'}], 'isLemma': 1},
	{'value': 'aSva', 'label': 'aSva', 'attrib': [{'pos': 'm', 'defs': 'horse'}], 'isLemma': 1},
	{'value': 'azwan', 'label': 'azwan', 'attrib': [{'pos': 'card_num', 'defs': 'eight'}], 'isLemma': 1},
	{'value': 'azwama', 'label': 'azwama', 'attrib': [{'pos': 'ord_num', 'defs': 'eighth'}], 'isLemma': 1},
	{'value': 'as', 'label': 'as', 'attrib': [{'pos': 'vi2a', 'defs': 'be'}], 'isLemma': 1},
	{'value': 'asi', 'label': 'asi', 'attrib': [{'pos': 'm', 'defs': 'sword'}], 'isLemma': 1},
	{'value': 'asura', 'label': 'asura', 'attrib': [{'pos': 'm', 'defs': 'spirit, demon'}], 'isLemma': 1},
	{'value': 'asta', 'label': 'asta', 'attrib': [{'pos': 'n', 'defs': 'home'}], 'isLemma': 1},
	{'value': 'asmad', 'label': 'asmad', 'attrib': [{'pos': 'pers_pron', 'defs': 'I/we'}], 'isLemma': 1},
	{'value': 'ah', 'label': 'ah', 'attrib': [{'pos': 'vta', 'defs': 'say'}], 'isLemma': 1},
	{'value': 'ahan', 'label': 'ahan', 'attrib': [{'pos': 'n', 'defs': 'day'}], 'isLemma': 1},
	{'value': 'A', 'label': 'A', 'attrib': [{'pos': 'preverb', 'defs': 'unto'}], 'isLemma': 1},
	{'value': 'AkraMsyat', 'label': 'AkraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'AkraMsyamAna', 'label': 'AkraMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'AkramaRIya', 'label': 'AkramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'AkramamARa', 'label': 'AkramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'Akramitavya', 'label': 'Akramitavya', 'attrib': [{'pos': 'ger', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'Akramitum', 'label': 'Akramitum', 'attrib': [{'pos': 'inf', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'Akramya', 'label': 'Akramya', 'attrib': [{'pos': 'abs', 'defs': 'mount, attack; rise'}, {'pos': 'ger', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'AkramyamARa', 'label': 'AkramyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'mount, attack; rise'}, {'pos': 'prpp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'AkrAnta', 'label': 'AkrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'AkrAntavat', 'label': 'AkrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'AkrAntum', 'label': 'AkrAntum', 'attrib': [{'pos': 'inf', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'AkrAmat', 'label': 'AkrAmat', 'attrib': [{'pos': 'prap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'AkrAmyat', 'label': 'AkrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'AgacCat', 'label': 'AgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'Agata', 'label': 'Agata', 'attrib': [{'pos': 'ppp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'Agatavat', 'label': 'Agatavat', 'attrib': [{'pos': 'pap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'Agatya', 'label': 'Agatya', 'attrib': [{'pos': 'abs', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'Agantavya', 'label': 'Agantavya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'Agantum', 'label': 'Agantum', 'attrib': [{'pos': 'inf', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'AgamanIya', 'label': 'AgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'Agamizyat', 'label': 'Agamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'AgamizyamARa', 'label': 'AgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'Agamya', 'label': 'Agamya', 'attrib': [{'pos': 'abs', 'defs': 'come'}, {'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'AgamyamAna', 'label': 'AgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'AcakramARa', 'label': 'AcakramARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'Acakramvas', 'label': 'Acakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'AcArya', 'label': 'AcArya', 'attrib': [{'pos': 'm', 'defs': 'teacher'}], 'isLemma': 1},
	{'value': 'Ajaganvas', 'label': 'Ajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'AjagmAna', 'label': 'AjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'Ajagmivas', 'label': 'Ajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'AjajYAna', 'label': 'AjajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'AjajYivas', 'label': 'AjajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'Ajahfvas', 'label': 'Ajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'AjahrARa', 'label': 'AjahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'AjAnat', 'label': 'AjAnat', 'attrib': [{'pos': 'prap', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'AjuhuvAna', 'label': 'AjuhuvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'AjuhUvas', 'label': 'AjuhUvas', 'attrib': [{'pos': 'pfap', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'AjYA', 'label': 'AjYA', 'attrib': [{'pos': 'f', 'defs': 'command'}], 'isLemma': 1},
	{'value': 'AjYAta', 'label': 'AjYAta', 'attrib': [{'pos': 'ppp', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'AjYAtavat', 'label': 'AjYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'AjYAtavya', 'label': 'AjYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'AjYAtum', 'label': 'AjYAtum', 'attrib': [{'pos': 'inf', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'AjYAnIya', 'label': 'AjYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'AjYAya', 'label': 'AjYAya', 'attrib': [{'pos': 'abs', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'AjYAyamAna', 'label': 'AjYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'AjYAsyat', 'label': 'AjYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'AjYAsyamAna', 'label': 'AjYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'AjYeya', 'label': 'AjYeya', 'attrib': [{'pos': 'ger', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'A_i', 'label': 'A_i', 'attrib': [{'pos': 'vt2a', 'defs': 'come'}], 'isLemma': 1},
	{'value': 'A_kram', 'label': 'A_kram', 'attrib': [{'pos': 'vt1am', 'defs': 'mount, attack; rise'}], 'isLemma': 1},
	{'value': 'A_gam', 'label': 'A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come'}], 'isLemma': 1},
	{'value': 'A_jYA', 'label': 'A_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'notice; caus.: command'}], 'isLemma': 1},
	{'value': 'A_dA', 'label': 'A_dA', 'attrib': [{'pos': 'vt3am', 'defs': 'receive, take'}], 'isLemma': 1},
	{'value': 'A_nI', 'label': 'A_nI', 'attrib': [{'pos': 'vt1am', 'defs': 'lead to, bring'}], 'isLemma': 1},
	{'value': 'A_pad', 'label': 'A_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'approach'}], 'isLemma': 1},
	{'value': 'A_sTA', 'label': 'A_sTA', 'attrib': [{'pos': 'vt1a', 'defs': 'resort to'}], 'isLemma': 1},
	{'value': 'A_hf', 'label': 'A_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'bring, fetch'}], 'isLemma': 1},
	{'value': 'A_hve', 'label': 'A_hve', 'attrib': [{'pos': 'vt1am', 'defs': 'call, summon'}], 'isLemma': 1},
	{'value': 'AtasTAna', 'label': 'AtasTAna', 'attrib': [{'pos': 'pfpp', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'AtasTivas', 'label': 'AtasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'Ati', 'label': 'Ati', 'attrib': [{'pos': 'f', 'defs': 'duck'}], 'isLemma': 1},
	{'value': 'AtizWat', 'label': 'AtizWat', 'attrib': [{'pos': 'prap', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'Atta', 'label': 'Atta', 'attrib': [{'pos': 'ppp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'Adattavat', 'label': 'Adattavat', 'attrib': [{'pos': 'pap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'Adadat', 'label': 'Adadat', 'attrib': [{'pos': 'prap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdadAna', 'label': 'AdadAna', 'attrib': [{'pos': 'prmp', 'defs': 'receive, take'}, {'pos': 'pfmp, pfpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'Adadivas', 'label': 'Adadivas', 'attrib': [{'pos': 'pfap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdAtavya', 'label': 'AdAtavya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdAtum', 'label': 'AdAtum', 'attrib': [{'pos': 'inf', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdAna', 'label': 'AdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'AdAnIya', 'label': 'AdAnIya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdAya', 'label': 'AdAya', 'attrib': [{'pos': 'abs', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdAsyat', 'label': 'AdAsyat', 'attrib': [{'pos': 'fap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'AdAsyamAna', 'label': 'AdAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'Adivas', 'label': 'Adivas', 'attrib': [{'pos': 'pfap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'AdIyamAna', 'label': 'AdIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'Adeya', 'label': 'Adeya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'Adya', 'label': 'Adya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'Anayat', 'label': 'Anayat', 'attrib': [{'pos': 'prap', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'AnayanIya', 'label': 'AnayanIya', 'attrib': [{'pos': 'ger', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'AnayamAna', 'label': 'AnayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'AnarhARa', 'label': 'AnarhARa', 'attrib': [{'pos': 'pfpp', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'Anarhivas', 'label': 'Anarhivas', 'attrib': [{'pos': 'pfap', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'AninIvas', 'label': 'AninIvas', 'attrib': [{'pos': 'pfap', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'AninyAna', 'label': 'AninyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'AnIta', 'label': 'AnIta', 'attrib': [{'pos': 'ppp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'AnItavat', 'label': 'AnItavat', 'attrib': [{'pos': 'pap', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'AnIya', 'label': 'AnIya', 'attrib': [{'pos': 'abs', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'AnIyamAna', 'label': 'AnIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'Anetavya', 'label': 'Anetavya', 'attrib': [{'pos': 'ger', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'Anetum', 'label': 'Anetum', 'attrib': [{'pos': 'inf', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'Aneya', 'label': 'Aneya', 'attrib': [{'pos': 'ger', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'Anezyat', 'label': 'Anezyat', 'attrib': [{'pos': 'fap', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'AnezyamARa', 'label': 'AnezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'Ap', 'label': 'Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'attain'}], 'isLemma': 1},
	{'value': 'ApaRa', 'label': 'ApaRa', 'attrib': [{'pos': 'm', 'defs': 'market'}], 'isLemma': 1},
	{'value': 'Apattavya', 'label': 'Apattavya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'Apattum', 'label': 'Apattum', 'attrib': [{'pos': 'inf', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'ApatsyamAna', 'label': 'ApatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'ApadanIya', 'label': 'ApadanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'Apadya', 'label': 'Apadya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'ApadyamAna', 'label': 'ApadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'approach'}, {'pos': 'prpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'ApanIya', 'label': 'ApanIya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Apanna', 'label': 'Apanna', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'Apannavat', 'label': 'Apannavat', 'attrib': [{'pos': 'pap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'ApAdya', 'label': 'ApAdya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'ApAna', 'label': 'ApAna', 'attrib': [{'pos': 'pfpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Apivas', 'label': 'Apivas', 'attrib': [{'pos': 'pfap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'ApedAna', 'label': 'ApedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'Apta', 'label': 'Apta', 'attrib': [{'pos': 'ppp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Aptavat', 'label': 'Aptavat', 'attrib': [{'pos': 'pap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Aptavya', 'label': 'Aptavya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Aptum', 'label': 'Aptum', 'attrib': [{'pos': 'inf', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'AptvA', 'label': 'AptvA', 'attrib': [{'pos': 'abs', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Apnuvat', 'label': 'Apnuvat', 'attrib': [{'pos': 'prap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Apya', 'label': 'Apya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'ApyamAna', 'label': 'ApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Apsyat', 'label': 'Apsyat', 'attrib': [{'pos': 'fap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'ApsyamAna', 'label': 'ApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'Ayat', 'label': 'Ayat', 'attrib': [{'pos': 'prap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'AyanIya', 'label': 'AyanIya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'Ayus', 'label': 'Ayus', 'attrib': [{'pos': 'n', 'defs': 'life'}], 'isLemma': 1},
	{'value': 'AvirbaBUvas', 'label': 'AvirbaBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'AvirBavat', 'label': 'AvirBavat', 'attrib': [{'pos': 'prap', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'AvirBavanIya', 'label': 'AvirBavanIya', 'attrib': [{'pos': 'ger', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'AvirBavitavya', 'label': 'AvirBavitavya', 'attrib': [{'pos': 'ger', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'AvirBavitum', 'label': 'AvirBavitum', 'attrib': [{'pos': 'inf', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'AvirBavizyat', 'label': 'AvirBavizyat', 'attrib': [{'pos': 'fap', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'AvirBavizyamARa', 'label': 'AvirBavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'AvirBavya', 'label': 'AvirBavya', 'attrib': [{'pos': 'ger', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'AvirBUta', 'label': 'AvirBUta', 'attrib': [{'pos': 'ppp', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'AvirBUtavat', 'label': 'AvirBUtavat', 'attrib': [{'pos': 'pap', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'AvirBUya', 'label': 'AvirBUya', 'attrib': [{'pos': 'abs', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'AvirBUyamAna', 'label': 'AvirBUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'Avis', 'label': 'Avis', 'attrib': [{'pos': 'preverb', 'defs': 'clear, manifest'}], 'isLemma': 1},
	{'value': 'Avis_BU', 'label': 'Avis_BU', 'attrib': [{'pos': 'vi1a', 'defs': 'become manifest, appear'}], 'isLemma': 1},
	{'value': 'ASrama', 'label': 'ASrama', 'attrib': [{'pos': 'm, n', 'defs': 'hermitage'}], 'isLemma': 1},
	{'value': 'As', 'label': 'As', 'attrib': [{'pos': 'vi2m', 'defs': 'sit'}], 'isLemma': 1},
	{'value': 'AsanIya', 'label': 'AsanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'AsAm', 'label': 'AsAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'Asita', 'label': 'Asita', 'attrib': [{'pos': 'ppp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'Asitavat', 'label': 'Asitavat', 'attrib': [{'pos': 'pap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'Asitum', 'label': 'Asitum', 'attrib': [{'pos': 'inf', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'AsitvA', 'label': 'AsitvA', 'attrib': [{'pos': 'abs', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'AsizyamARa', 'label': 'AsizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'AsIna', 'label': 'AsIna', 'attrib': [{'pos': 'prmp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'Astavya', 'label': 'Astavya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'AsTAtavya', 'label': 'AsTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'AsTAtum', 'label': 'AsTAtum', 'attrib': [{'pos': 'inf', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'AsTAnIya', 'label': 'AsTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'AsTAya', 'label': 'AsTAya', 'attrib': [{'pos': 'abs', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'AsTAsyat', 'label': 'AsTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'AsTAsyamAna', 'label': 'AsTAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'AsTita', 'label': 'AsTita', 'attrib': [{'pos': 'ppp', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'AsTitavat', 'label': 'AsTitavat', 'attrib': [{'pos': 'pap', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'AsTIyamAna', 'label': 'AsTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'AsTeya', 'label': 'AsTeya', 'attrib': [{'pos': 'ger', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'Asya', 'label': 'Asya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'AsyamAna', 'label': 'AsyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'AharaRIya', 'label': 'AharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'Aharat', 'label': 'Aharat', 'attrib': [{'pos': 'prap', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'AharamARa', 'label': 'AharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'Aharizyat', 'label': 'Aharizyat', 'attrib': [{'pos': 'fap', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'AharizyamARa', 'label': 'AharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'Ahartavya', 'label': 'Ahartavya', 'attrib': [{'pos': 'ger', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'Ahartum', 'label': 'Ahartum', 'attrib': [{'pos': 'inf', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'AhArya', 'label': 'AhArya', 'attrib': [{'pos': 'ger', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'AhUta', 'label': 'AhUta', 'attrib': [{'pos': 'ppp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'AhUtavat', 'label': 'AhUtavat', 'attrib': [{'pos': 'pap', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'AhUya', 'label': 'AhUya', 'attrib': [{'pos': 'abs', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'AhUyamAna', 'label': 'AhUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'Ahfta', 'label': 'Ahfta', 'attrib': [{'pos': 'ppp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'Ahftavat', 'label': 'Ahftavat', 'attrib': [{'pos': 'pap', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'Ahftya', 'label': 'Ahftya', 'attrib': [{'pos': 'abs', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'AhriyamARa', 'label': 'AhriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'Ahvayat', 'label': 'Ahvayat', 'attrib': [{'pos': 'prap', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'AhvayamAna', 'label': 'AhvayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'AhvAtavya', 'label': 'AhvAtavya', 'attrib': [{'pos': 'ger', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'AhvAtum', 'label': 'AhvAtum', 'attrib': [{'pos': 'inf', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'AhvAnIya', 'label': 'AhvAnIya', 'attrib': [{'pos': 'ger', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'AhvAya', 'label': 'AhvAya', 'attrib': [{'pos': 'ger', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'AhvAsyat', 'label': 'AhvAsyat', 'attrib': [{'pos': 'fap', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'AhvAsyamAna', 'label': 'AhvAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'i', 'label': 'i', 'attrib': [{'pos': 'vt2a', 'defs': 'go'}], 'isLemma': 1},
	{'value': 'icCat', 'label': 'icCat', 'attrib': [{'pos': 'prap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ijyamAna', 'label': 'ijyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'ita', 'label': 'ita', 'attrib': [{'pos': 'ppp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'itara', 'label': 'itara', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'other'}], 'isLemma': 1},
	{'value': 'itavat', 'label': 'itavat', 'attrib': [{'pos': 'pap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'itas', 'label': 'itas', 'attrib': [{'pos': 'dem_adv', 'defs': 'from this, hence'}], 'isLemma': 1},
	{'value': 'iti', 'label': 'iti', 'attrib': [{'pos': 'pcl', 'defs': 'thus, close quote'}], 'isLemma': 1},
	{'value': 'itTam', 'label': 'itTam', 'attrib': [{'pos': 'dem_adv', 'defs': 'in this way, thus'}], 'isLemma': 1},
	{'value': 'itya', 'label': 'itya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'itvA', 'label': 'itvA', 'attrib': [{'pos': 'abs', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'idam', 'label': 'idam', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'this'}], 'isLemma': 1},
	{'value': 'indra', 'label': 'indra', 'attrib': [{'pos': 'm', 'defs': 'Indra'}], 'isLemma': 1},
	{'value': 'iva', 'label': 'iva', 'attrib': [{'pos': 'pcl', 'defs': 'like, as'}], 'isLemma': 1},
	{'value': 'iz', 'label': 'iz', 'attrib': [{'pos': 'vt6a', 'defs': 'desire'}], 'isLemma': 1},
	{'value': 'izwa', 'label': 'izwa', 'attrib': [{'pos': 'ppp', 'defs': 'desire'}, {'pos': 'ppp', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['iz', 'yaj']},
	{'value': 'izwavat', 'label': 'izwavat', 'attrib': [{'pos': 'pap', 'defs': 'desire'}, {'pos': 'pap', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['iz', 'yaj']},
	{'value': 'izwvA', 'label': 'izwvA', 'attrib': [{'pos': 'abs', 'defs': 'desire'}, {'pos': 'abs', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['iz', 'yaj']},
	{'value': 'izyamARa', 'label': 'izyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'iha', 'label': 'iha', 'attrib': [{'pos': 'dem_adv', 'defs': 'here'}], 'isLemma': 1},
	{'value': 'Ikz', 'label': 'Ikz', 'attrib': [{'pos': 'vt1m', 'defs': 'see'}], 'isLemma': 1},
	{'value': 'IkzaRIya', 'label': 'IkzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'IkzamARa', 'label': 'IkzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'IkzAm', 'label': 'IkzAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'Ikzita', 'label': 'Ikzita', 'attrib': [{'pos': 'ppp', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'Ikzitavat', 'label': 'Ikzitavat', 'attrib': [{'pos': 'pap', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'Ikzitavya', 'label': 'Ikzitavya', 'attrib': [{'pos': 'ger', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'Ikzitum', 'label': 'Ikzitum', 'attrib': [{'pos': 'inf', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'IkzitvA', 'label': 'IkzitvA', 'attrib': [{'pos': 'abs', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'IkzizyamARa', 'label': 'IkzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'Ikzya', 'label': 'Ikzya', 'attrib': [{'pos': 'ger', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'IkzyamARa', 'label': 'IkzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'IjAna', 'label': 'IjAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'Ijivas', 'label': 'Ijivas', 'attrib': [{'pos': 'pfap', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'IyamAna', 'label': 'IyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'IyAna', 'label': 'IyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'Iyivas', 'label': 'Iyivas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'IzARa', 'label': 'IzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'Izivas', 'label': 'Izivas', 'attrib': [{'pos': 'pfap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ukta', 'label': 'ukta', 'attrib': [{'pos': 'ppp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'uktavat', 'label': 'uktavat', 'attrib': [{'pos': 'pap', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'uktvA', 'label': 'uktvA', 'attrib': [{'pos': 'abs', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'uccakramARa', 'label': 'uccakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'uccakramvas', 'label': 'uccakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'ucyamAna', 'label': 'ucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'ujjaganvas', 'label': 'ujjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'ujjagmAna', 'label': 'ujjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'ujjagmivas', 'label': 'ujjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'ujjahfvas', 'label': 'ujjahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'ujjahrARa', 'label': 'ujjahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'utkraMsyat', 'label': 'utkraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkraMsyamAna', 'label': 'utkraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkramaRIya', 'label': 'utkramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkramitavya', 'label': 'utkramitavya', 'attrib': [{'pos': 'ger', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkramitum', 'label': 'utkramitum', 'attrib': [{'pos': 'inf', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkramya', 'label': 'utkramya', 'attrib': [{'pos': 'abs', 'defs': 'go up, ascend, transgress'}, {'pos': 'ger', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkramyamARa', 'label': 'utkramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkrAnta', 'label': 'utkrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkrAntavat', 'label': 'utkrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkrAmat', 'label': 'utkrAmat', 'attrib': [{'pos': 'prap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkrAmyat', 'label': 'utkrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'uttasTAna', 'label': 'uttasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttasTivas', 'label': 'uttasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttizWat', 'label': 'uttizWat', 'attrib': [{'pos': 'prap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttizWamAna', 'label': 'uttizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTAtavya', 'label': 'utTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTAtum', 'label': 'utTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTAnIya', 'label': 'utTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTAya', 'label': 'utTAya', 'attrib': [{'pos': 'abs', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTAsyat', 'label': 'utTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTAsyamAna', 'label': 'utTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTita', 'label': 'utTita', 'attrib': [{'pos': 'ppp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTitavat', 'label': 'utTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utTeya', 'label': 'utTeya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utpattavya', 'label': 'utpattavya', 'attrib': [{'pos': 'ger', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpattum', 'label': 'utpattum', 'attrib': [{'pos': 'inf', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpatsyamAna', 'label': 'utpatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpadanIya', 'label': 'utpadanIya', 'attrib': [{'pos': 'ger', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpadya', 'label': 'utpadya', 'attrib': [{'pos': 'abs', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpadyamAna', 'label': 'utpadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'step up, arise'}, {'pos': 'prpp', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpanna', 'label': 'utpanna', 'attrib': [{'pos': 'ppp', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpannavat', 'label': 'utpannavat', 'attrib': [{'pos': 'pap', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpAdya', 'label': 'utpAdya', 'attrib': [{'pos': 'ger', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpedAna', 'label': 'utpedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utsTIyamAna', 'label': 'utsTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'ud', 'label': 'ud', 'attrib': [{'pos': 'preverb', 'defs': 'up'}], 'isLemma': 1},
	{'value': 'udaka', 'label': 'udaka', 'attrib': [{'pos': 'n', 'defs': 'water'}], 'isLemma': 1},
	{'value': 'udita', 'label': 'udita', 'attrib': [{'pos': 'ppp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'uditavat', 'label': 'uditavat', 'attrib': [{'pos': 'pap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'uditvA', 'label': 'uditvA', 'attrib': [{'pos': 'abs', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'udgacCat', 'label': 'udgacCat', 'attrib': [{'pos': 'prap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgata', 'label': 'udgata', 'attrib': [{'pos': 'ppp', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgatavat', 'label': 'udgatavat', 'attrib': [{'pos': 'pap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgatya', 'label': 'udgatya', 'attrib': [{'pos': 'abs', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgantavya', 'label': 'udgantavya', 'attrib': [{'pos': 'ger', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgantum', 'label': 'udgantum', 'attrib': [{'pos': 'inf', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgamanIya', 'label': 'udgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgamizyat', 'label': 'udgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgamizyamARa', 'label': 'udgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgamya', 'label': 'udgamya', 'attrib': [{'pos': 'abs', 'defs': 'go up, rise'}, {'pos': 'ger', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgamyamAna', 'label': 'udgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgAtf', 'label': 'udgAtf', 'attrib': [{'pos': 'm', 'defs': 'udgātṛ'}], 'isLemma': 1},
	{'value': 'ud_kram', 'label': 'ud_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'go up, ascend, transgress'}], 'isLemma': 1},
	{'value': 'ud_gam', 'label': 'ud_gam', 'attrib': [{'pos': 'vi1a', 'defs': 'go up, rise'}], 'isLemma': 1},
	{'value': 'ud_pad', 'label': 'ud_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'step up, arise'}], 'isLemma': 1},
	{'value': 'ud_banD', 'label': 'ud_banD', 'attrib': [{'pos': 'vt9am', 'defs': "bind up, hang one's self"}], 'isLemma': 1},
	{'value': 'ud_yam', 'label': 'ud_yam', 'attrib': [{'pos': 'vt1am', 'defs': 'lift up, undertake, strive'}], 'isLemma': 1},
	{'value': 'ud_sTA', 'label': 'ud_sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stand up, arise'}], 'isLemma': 1},
	{'value': 'ud_hf', 'label': 'ud_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'take up, raise, rescue'}], 'isLemma': 1},
	{'value': 'udDaraRIya', 'label': 'udDaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udDarat', 'label': 'udDarat', 'attrib': [{'pos': 'prap', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udDaramARa', 'label': 'udDaramARa', 'attrib': [{'pos': 'prmp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udDarizyat', 'label': 'udDarizyat', 'attrib': [{'pos': 'fap', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udDarizyamARa', 'label': 'udDarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udDartavya', 'label': 'udDartavya', 'attrib': [{'pos': 'ger', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udDartum', 'label': 'udDartum', 'attrib': [{'pos': 'inf', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udDArya', 'label': 'udDArya', 'attrib': [{'pos': 'ger', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udDfta', 'label': 'udDfta', 'attrib': [{'pos': 'ppp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udDftavat', 'label': 'udDftavat', 'attrib': [{'pos': 'pap', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udDftya', 'label': 'udDftya', 'attrib': [{'pos': 'abs', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udDriyamARa', 'label': 'udDriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udbadDa', 'label': 'udbadDa', 'attrib': [{'pos': 'ppp', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbadDavat', 'label': 'udbadDavat', 'attrib': [{'pos': 'pap', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbaDnat', 'label': 'udbaDnat', 'attrib': [{'pos': 'prap', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbaDya', 'label': 'udbaDya', 'attrib': [{'pos': 'abs', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbaDyamAna', 'label': 'udbaDyamAna', 'attrib': [{'pos': 'prpp', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbandDavya', 'label': 'udbandDavya', 'attrib': [{'pos': 'ger', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbandDum', 'label': 'udbandDum', 'attrib': [{'pos': 'inf', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbanDanIya', 'label': 'udbanDanIya', 'attrib': [{'pos': 'ger', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbanDya', 'label': 'udbanDya', 'attrib': [{'pos': 'ger', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbabanDAna', 'label': 'udbabanDAna', 'attrib': [{'pos': 'pfpp', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbabanDvas', 'label': 'udbabanDvas', 'attrib': [{'pos': 'pfap', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udBantsyat', 'label': 'udBantsyat', 'attrib': [{'pos': 'fap', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udBantsyamAna', 'label': 'udBantsyamAna', 'attrib': [{'pos': 'fpp', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udyaMsyat', 'label': 'udyaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyaMsyamAna', 'label': 'udyaMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyacCat', 'label': 'udyacCat', 'attrib': [{'pos': 'prap', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyacCamAna', 'label': 'udyacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyata', 'label': 'udyata', 'attrib': [{'pos': 'ppp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyatavat', 'label': 'udyatavat', 'attrib': [{'pos': 'pap', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyatya', 'label': 'udyatya', 'attrib': [{'pos': 'abs', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyantavya', 'label': 'udyantavya', 'attrib': [{'pos': 'ger', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyantum', 'label': 'udyantum', 'attrib': [{'pos': 'inf', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyamanIya', 'label': 'udyamanIya', 'attrib': [{'pos': 'ger', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyamAna', 'label': 'udyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'udyamya', 'label': 'udyamya', 'attrib': [{'pos': 'abs', 'defs': 'lift up, undertake, strive'}, {'pos': 'ger', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyamyamAna', 'label': 'udyamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyemAna', 'label': 'udyemAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyemivas', 'label': 'udyemivas', 'attrib': [{'pos': 'pfap', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'upa', 'label': 'upa', 'attrib': [{'pos': 'preverb', 'defs': 'near'}], 'isLemma': 1},
	{'value': 'upakraMsyat', 'label': 'upakraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakraMsyamAna', 'label': 'upakraMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakramaRIya', 'label': 'upakramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakramamARa', 'label': 'upakramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakramitavya', 'label': 'upakramitavya', 'attrib': [{'pos': 'ger', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakramitum', 'label': 'upakramitum', 'attrib': [{'pos': 'inf', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakramya', 'label': 'upakramya', 'attrib': [{'pos': 'abs', 'defs': 'approach (active); begin (middle)'}, {'pos': 'ger', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakramyamARa', 'label': 'upakramyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'approach (active); begin (middle)'}, {'pos': 'prpp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakrAnta', 'label': 'upakrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakrAntavat', 'label': 'upakrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakrAntum', 'label': 'upakrAntum', 'attrib': [{'pos': 'inf', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakrAmat', 'label': 'upakrAmat', 'attrib': [{'pos': 'prap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakrAmyat', 'label': 'upakrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upagacCat', 'label': 'upagacCat', 'attrib': [{'pos': 'prap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagata', 'label': 'upagata', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagatavat', 'label': 'upagatavat', 'attrib': [{'pos': 'pap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagatya', 'label': 'upagatya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagantavya', 'label': 'upagantavya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagantum', 'label': 'upagantum', 'attrib': [{'pos': 'inf', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagamanIya', 'label': 'upagamanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagamizyat', 'label': 'upagamizyat', 'attrib': [{'pos': 'fap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagamizyamARa', 'label': 'upagamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagamya', 'label': 'upagamya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}, {'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagamyamAna', 'label': 'upagamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upacakramARa', 'label': 'upacakramARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upacakramvas', 'label': 'upacakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upajaganvas', 'label': 'upajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upajagmAna', 'label': 'upajagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upajagmivas', 'label': 'upajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upajahfvas', 'label': 'upajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upajahrARa', 'label': 'upajahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upa_i', 'label': 'upa_i', 'attrib': [{'pos': 'vt2a', 'defs': 'approach'}], 'isLemma': 1},
	{'value': 'upa_kram', 'label': 'upa_kram', 'attrib': [{'pos': 'vt1am', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 1},
	{'value': 'upa_gam', 'label': 'upa_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'approach'}], 'isLemma': 1},
	{'value': 'upa_pad', 'label': 'upa_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'approach, occur, be suitable'}], 'isLemma': 1},
	{'value': 'upa_banD', 'label': 'upa_banD', 'attrib': [{'pos': 'vt9am', 'defs': 'bind near'}], 'isLemma': 1},
	{'value': 'upa_yam', 'label': 'upa_yam', 'attrib': [{'pos': 'vt1am', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 1},
	{'value': 'upa_viS', 'label': 'upa_viS', 'attrib': [{'pos': 'vi6a', 'defs': 'sit'}], 'isLemma': 1},
	{'value': 'upa_sTA', 'label': 'upa_sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stay near, be present'}], 'isLemma': 1},
	{'value': 'upa_hf', 'label': 'upa_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'bring near, offer'}], 'isLemma': 1},
	{'value': 'upatasTAna', 'label': 'upatasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upatasTivas', 'label': 'upatasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upatizWat', 'label': 'upatizWat', 'attrib': [{'pos': 'prap', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upatizWamAna', 'label': 'upatizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upapattavya', 'label': 'upapattavya', 'attrib': [{'pos': 'ger', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapattum', 'label': 'upapattum', 'attrib': [{'pos': 'inf', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapatsyamAna', 'label': 'upapatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapadanIya', 'label': 'upapadanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapadya', 'label': 'upapadya', 'attrib': [{'pos': 'abs', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapadyamAna', 'label': 'upapadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'approach, occur, be suitable'}, {'pos': 'prpp', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapanna', 'label': 'upapanna', 'attrib': [{'pos': 'ppp', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapannavat', 'label': 'upapannavat', 'attrib': [{'pos': 'pap', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapAdya', 'label': 'upapAdya', 'attrib': [{'pos': 'ger', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapedAna', 'label': 'upapedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upabadDa', 'label': 'upabadDa', 'attrib': [{'pos': 'ppp', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabadDavat', 'label': 'upabadDavat', 'attrib': [{'pos': 'pap', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabaDnat', 'label': 'upabaDnat', 'attrib': [{'pos': 'prap', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabaDya', 'label': 'upabaDya', 'attrib': [{'pos': 'abs', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabaDyamAna', 'label': 'upabaDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabandDavya', 'label': 'upabandDavya', 'attrib': [{'pos': 'ger', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabandDum', 'label': 'upabandDum', 'attrib': [{'pos': 'inf', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabanDanIya', 'label': 'upabanDanIya', 'attrib': [{'pos': 'ger', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabanDya', 'label': 'upabanDya', 'attrib': [{'pos': 'ger', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upababanDAna', 'label': 'upababanDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upababanDvas', 'label': 'upababanDvas', 'attrib': [{'pos': 'pfap', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upaBantsyat', 'label': 'upaBantsyat', 'attrib': [{'pos': 'fap', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upaBantsyamAna', 'label': 'upaBantsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upayaMsyat', 'label': 'upayaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayaMsyamAna', 'label': 'upayaMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayacCat', 'label': 'upayacCat', 'attrib': [{'pos': 'prap', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayacCamAna', 'label': 'upayacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayat', 'label': 'upayat', 'attrib': [{'pos': 'prap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upayata', 'label': 'upayata', 'attrib': [{'pos': 'ppp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayatavat', 'label': 'upayatavat', 'attrib': [{'pos': 'pap', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayatya', 'label': 'upayatya', 'attrib': [{'pos': 'abs', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayantavya', 'label': 'upayantavya', 'attrib': [{'pos': 'ger', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayantum', 'label': 'upayantum', 'attrib': [{'pos': 'inf', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayamanIya', 'label': 'upayamanIya', 'attrib': [{'pos': 'ger', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayamya', 'label': 'upayamya', 'attrib': [{'pos': 'abs', 'defs': 'hold near, take, offer (active), embrace (middle)'}, {'pos': 'ger', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayamyamAna', 'label': 'upayamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayemAna', 'label': 'upayemAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayemivas', 'label': 'upayemivas', 'attrib': [{'pos': 'pfap', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upaviviSAna', 'label': 'upaviviSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaviviSivas', 'label': 'upaviviSivas', 'attrib': [{'pos': 'pfap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaviviSvas', 'label': 'upaviviSvas', 'attrib': [{'pos': 'pfap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaviSat', 'label': 'upaviSat', 'attrib': [{'pos': 'prap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaviSya', 'label': 'upaviSya', 'attrib': [{'pos': 'abs', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaviSyamAna', 'label': 'upaviSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upavizwa', 'label': 'upavizwa', 'attrib': [{'pos': 'ppp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upavizwavat', 'label': 'upavizwavat', 'attrib': [{'pos': 'pap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upavekzyat', 'label': 'upavekzyat', 'attrib': [{'pos': 'fap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upavekzyamARa', 'label': 'upavekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaveSanIya', 'label': 'upaveSanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaveSya', 'label': 'upaveSya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upavezwavya', 'label': 'upavezwavya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upavezwum', 'label': 'upavezwum', 'attrib': [{'pos': 'inf', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upasTAtavya', 'label': 'upasTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasTAtum', 'label': 'upasTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasTAnIya', 'label': 'upasTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasTAya', 'label': 'upasTAya', 'attrib': [{'pos': 'abs', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasTAsyat', 'label': 'upasTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasTAsyamAna', 'label': 'upasTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasTita', 'label': 'upasTita', 'attrib': [{'pos': 'ppp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasTitavat', 'label': 'upasTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasTIyamAna', 'label': 'upasTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasTeya', 'label': 'upasTeya', 'attrib': [{'pos': 'ger', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upaharaRIya', 'label': 'upaharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upaharat', 'label': 'upaharat', 'attrib': [{'pos': 'prap', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upaharamARa', 'label': 'upaharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upaharizyat', 'label': 'upaharizyat', 'attrib': [{'pos': 'fap', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upaharizyamARa', 'label': 'upaharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahartavya', 'label': 'upahartavya', 'attrib': [{'pos': 'ger', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahartum', 'label': 'upahartum', 'attrib': [{'pos': 'inf', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahArya', 'label': 'upahArya', 'attrib': [{'pos': 'ger', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahfta', 'label': 'upahfta', 'attrib': [{'pos': 'ppp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahftavat', 'label': 'upahftavat', 'attrib': [{'pos': 'pap', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahftya', 'label': 'upahftya', 'attrib': [{'pos': 'abs', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahriyamARa', 'label': 'upahriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upAyanIya', 'label': 'upAyanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeta', 'label': 'upeta', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upetavat', 'label': 'upetavat', 'attrib': [{'pos': 'pap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upetya', 'label': 'upetya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}, {'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeyamAna', 'label': 'upeyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeyAna', 'label': 'upeyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeyivas', 'label': 'upeyivas', 'attrib': [{'pos': 'pfap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upEtavya', 'label': 'upEtavya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upEtum', 'label': 'upEtum', 'attrib': [{'pos': 'inf', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upEzyat', 'label': 'upEzyat', 'attrib': [{'pos': 'fap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upEzyamARa', 'label': 'upEzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'uBa', 'label': 'uBa', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'both'}], 'isLemma': 1},
	{'value': 'uraRa', 'label': 'uraRa', 'attrib': [{'pos': 'm', 'defs': 'lamb'}], 'isLemma': 1},
	{'value': 'urvaSI', 'label': 'urvaSI', 'attrib': [{'pos': 'f_pn', 'defs': 'Urvaśī'}], 'isLemma': 1},
	{'value': 'uzas', 'label': 'uzas', 'attrib': [{'pos': 'f', 'defs': 'dawn'}], 'isLemma': 1},
	{'value': 'uzita', 'label': 'uzita', 'attrib': [{'pos': 'ppp', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uzitavat', 'label': 'uzitavat', 'attrib': [{'pos': 'pap', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uzitvA', 'label': 'uzitvA', 'attrib': [{'pos': 'abs', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uzRa', 'label': 'uzRa', 'attrib': [{'pos': 'adj', 'defs': 'warm'}], 'isLemma': 1},
	{'value': 'uzyamARa', 'label': 'uzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uhyamAna', 'label': 'uhyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'UcAna', 'label': 'UcAna', 'attrib': [{'pos': 'pfpp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'Ucivas', 'label': 'Ucivas', 'attrib': [{'pos': 'pfap', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'UQa', 'label': 'UQa', 'attrib': [{'pos': 'ppp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'UQavat', 'label': 'UQavat', 'attrib': [{'pos': 'pap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'UQvA', 'label': 'UQvA', 'attrib': [{'pos': 'abs', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'UdAna', 'label': 'UdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'Udivas', 'label': 'Udivas', 'attrib': [{'pos': 'pfap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'UzARa', 'label': 'UzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'Uzivas', 'label': 'Uzivas', 'attrib': [{'pos': 'pfap', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'UhAna', 'label': 'UhAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'Uhivas', 'label': 'Uhivas', 'attrib': [{'pos': 'pfap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'fgveda', 'label': 'fgveda', 'attrib': [{'pos': 'm_pn', 'defs': '<i>Ṛgveda</i>'}], 'isLemma': 1},
	{'value': 'fc', 'label': 'fc', 'attrib': [{'pos': 'f', 'defs': 'Ṛgvedic verse'}], 'isLemma': 1},
	{'value': 'ftvij', 'label': 'ftvij', 'attrib': [{'pos': 'm', 'defs': 'officiant in Vedic performance'}], 'isLemma': 1},
	{'value': 'fzi', 'label': 'fzi', 'attrib': [{'pos': 'm', 'defs': 'Vedic seer'}], 'isLemma': 1},
	{'value': 'eka', 'label': 'eka', 'attrib': [{'pos': 'card_num', 'defs': 'one'}], 'isLemma': 1},
	{'value': 'eta', 'label': 'eta', 'attrib': [{'pos': 'ppp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'etad', 'label': 'etad', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'this'}], 'isLemma': 1},
	{'value': 'etarhi', 'label': 'etarhi', 'attrib': [{'pos': 'dem_adv', 'defs': 'for this reason'}], 'isLemma': 1},
	{'value': 'etavat', 'label': 'etavat', 'attrib': [{'pos': 'pap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'etavya', 'label': 'etavya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'etAvat', 'label': 'etAvat', 'attrib': [{'pos': 'dem_adj', 'defs': 'of this extent'}, {'pos': 'dem_adv', 'defs': 'to this extent'}], 'isLemma': 1},
	{'value': 'etum', 'label': 'etum', 'attrib': [{'pos': 'inf', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'etya', 'label': 'etya', 'attrib': [{'pos': 'abs', 'defs': 'come'}, {'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'enad', 'label': 'enad', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'this, it (2, 3s, 6d, 7d)'}], 'isLemma': 1},
	{'value': 'eyamAna', 'label': 'eyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'eyAna', 'label': 'eyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'eyivas', 'label': 'eyivas', 'attrib': [{'pos': 'pfap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'eva', 'label': 'eva', 'attrib': [{'pos': 'pcl', 'defs': 'only, just, very'}], 'isLemma': 1},
	{'value': 'evam', 'label': 'evam', 'attrib': [{'pos': 'dem_adv', 'defs': 'in this way, thus'}], 'isLemma': 1},
	{'value': 'ezaRIya', 'label': 'ezaRIya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ezizyat', 'label': 'ezizyat', 'attrib': [{'pos': 'fap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ezizyamARa', 'label': 'ezizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ezwavya', 'label': 'ezwavya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ezwum', 'label': 'ezwum', 'attrib': [{'pos': 'inf', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ezya', 'label': 'ezya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ezyat', 'label': 'ezyat', 'attrib': [{'pos': 'fap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'ezyamARa', 'label': 'ezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'Etavya', 'label': 'Etavya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'Etum', 'label': 'Etum', 'attrib': [{'pos': 'inf', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'Ezyat', 'label': 'Ezyat', 'attrib': [{'pos': 'fap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'EzyamARa', 'label': 'EzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'kaT', 'label': 'kaT', 'attrib': [{'pos': 'vt10am', 'defs': 'tell, narrate'}], 'isLemma': 1},
	{'value': 'kaTanIya', 'label': 'kaTanIya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTam', 'label': 'kaTam', 'attrib': [{'pos': 'int_adv', 'defs': 'how'}], 'isLemma': 1},
	{'value': 'kaTayat', 'label': 'kaTayat', 'attrib': [{'pos': 'prap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayamAna', 'label': 'kaTayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayAm', 'label': 'kaTayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayitavya', 'label': 'kaTayitavya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayitum', 'label': 'kaTayitum', 'attrib': [{'pos': 'inf', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayitvA', 'label': 'kaTayitvA', 'attrib': [{'pos': 'abs', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayizyat', 'label': 'kaTayizyat', 'attrib': [{'pos': 'fap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTayizyamARa', 'label': 'kaTayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTA', 'label': 'kaTA', 'attrib': [{'pos': 'f', 'defs': 'story'}], 'isLemma': 1},
	{'value': 'kaTita', 'label': 'kaTita', 'attrib': [{'pos': 'ppp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTitavat', 'label': 'kaTitavat', 'attrib': [{'pos': 'pap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTya', 'label': 'kaTya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kaTyamAna', 'label': 'kaTyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kadA', 'label': 'kadA', 'attrib': [{'pos': 'int_adv', 'defs': 'when'}], 'isLemma': 1},
	{'value': 'kanyA', 'label': 'kanyA', 'attrib': [{'pos': 'f', 'defs': 'girl'}], 'isLemma': 1},
	{'value': 'kam', 'label': 'kam', 'attrib': [{'pos': 'vt1m', 'defs': 'desire, love'}], 'isLemma': 1},
	{'value': 'kamanIya', 'label': 'kamanIya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kamitavya', 'label': 'kamitavya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kamitum', 'label': 'kamitum', 'attrib': [{'pos': 'inf', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kamitvA', 'label': 'kamitvA', 'attrib': [{'pos': 'abs', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kamizyamARa', 'label': 'kamizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'karaRIya', 'label': 'karaRIya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'karizyat', 'label': 'karizyat', 'attrib': [{'pos': 'fap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'karizyamARa', 'label': 'karizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kartavya', 'label': 'kartavya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kartum', 'label': 'kartum', 'attrib': [{'pos': 'inf', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'karman', 'label': 'karman', 'attrib': [{'pos': 'n', 'defs': 'action, deed'}], 'isLemma': 1},
	{'value': 'kAnta', 'label': 'kAnta', 'attrib': [{'pos': 'ppp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kAntavat', 'label': 'kAntavat', 'attrib': [{'pos': 'pap', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kAntvA', 'label': 'kAntvA', 'attrib': [{'pos': 'abs', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kAma', 'label': 'kAma', 'attrib': [{'pos': 'm', 'defs': 'love, the god of love'}], 'isLemma': 1},
	{'value': 'kAmanIya', 'label': 'kAmanIya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kAmayamAna', 'label': 'kAmayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kAmayAm', 'label': 'kAmayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kAmayitavya', 'label': 'kAmayitavya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kAmayitum', 'label': 'kAmayitum', 'attrib': [{'pos': 'inf', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kAmayitvA', 'label': 'kAmayitvA', 'attrib': [{'pos': 'abs', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kAmayizyamARa', 'label': 'kAmayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kAmya', 'label': 'kAmya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kAmyamAna', 'label': 'kAmyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kArya', 'label': 'kArya', 'attrib': [{'pos': 'n', 'defs': 'work'}, {'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 1},
	{'value': 'kArya', 'label': 'kArya', 'attrib': [{'pos': 'n', 'defs': 'work'}, {'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kAla', 'label': 'kAla', 'attrib': [{'pos': 'm', 'defs': 'time'}], 'isLemma': 1},
	{'value': 'kim', 'label': 'kim', 'attrib': [{'pos': 'int_pron, int_adj', 'defs': 'which/who/what'}, {'pos': 'pcl', 'defs': 'question marker'}], 'isLemma': 1},
	{'value': 'kiyat', 'label': 'kiyat', 'attrib': [{'pos': 'int_adj', 'defs': 'of what extent'}, {'pos': 'int_adv', 'defs': 'to what extent'}], 'isLemma': 1},
	{'value': 'kutas', 'label': 'kutas', 'attrib': [{'pos': 'int_adv', 'defs': 'whence, why'}], 'isLemma': 1},
	{'value': 'kutra', 'label': 'kutra', 'attrib': [{'pos': 'int_adv', 'defs': 'where'}], 'isLemma': 1},
	{'value': 'kumAra', 'label': 'kumAra', 'attrib': [{'pos': 'm', 'defs': 'boy, youth'}], 'isLemma': 1},
	{'value': 'kurukzetra', 'label': 'kurukzetra', 'attrib': [{'pos': 'n_pn', 'defs': 'field of the Kuru s, Kurukṣetra'}], 'isLemma': 1},
	{'value': 'kurvat', 'label': 'kurvat', 'attrib': [{'pos': 'prap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kurvARa', 'label': 'kurvARa', 'attrib': [{'pos': 'prmp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kula', 'label': 'kula', 'attrib': [{'pos': 'n', 'defs': 'family'}], 'isLemma': 1},
	{'value': 'kuSala', 'label': 'kuSala', 'attrib': [{'pos': 'adj', 'defs': 'skillful'}, {'pos': 'n', 'defs': 'welfare'}], 'isLemma': 1},
	{'value': 'kf', 'label': 'kf', 'attrib': [{'pos': 'vt8am', 'defs': 'do, make'}], 'isLemma': 1},
	{'value': 'kfta', 'label': 'kfta', 'attrib': [{'pos': 'ppp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kftavat', 'label': 'kftavat', 'attrib': [{'pos': 'pap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kftya', 'label': 'kftya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kftvA', 'label': 'kftvA', 'attrib': [{'pos': 'abs', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kfSa', 'label': 'kfSa', 'attrib': [{'pos': 'adj', 'defs': 'thin'}], 'isLemma': 1},
	{'value': 'kevala', 'label': 'kevala', 'attrib': [{'pos': 'adj', 'defs': 'alone, lone'}], 'isLemma': 1},
	{'value': 'kraMsyat', 'label': 'kraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kraMsyamAna', 'label': 'kraMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krantvA', 'label': 'krantvA', 'attrib': [{'pos': 'abs', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kram', 'label': 'kram', 'attrib': [{'pos': 'vt1am', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 1},
	{'value': 'kramaRIya', 'label': 'kramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kramamARa', 'label': 'kramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kramitavya', 'label': 'kramitavya', 'attrib': [{'pos': 'ger', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kramitum', 'label': 'kramitum', 'attrib': [{'pos': 'inf', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kramitvA', 'label': 'kramitvA', 'attrib': [{'pos': 'abs', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kramya', 'label': 'kramya', 'attrib': [{'pos': 'ger', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kramyamARa', 'label': 'kramyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'step (active); continue, go, increase (middle)'}, {'pos': 'prpp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krayaRIya', 'label': 'krayaRIya', 'attrib': [{'pos': 'ger', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krAnta', 'label': 'krAnta', 'attrib': [{'pos': 'ppp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krAntavat', 'label': 'krAntavat', 'attrib': [{'pos': 'pap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krAntum', 'label': 'krAntum', 'attrib': [{'pos': 'inf', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krAntvA', 'label': 'krAntvA', 'attrib': [{'pos': 'abs', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krAmat', 'label': 'krAmat', 'attrib': [{'pos': 'prap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krAmyat', 'label': 'krAmyat', 'attrib': [{'pos': 'prap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kriyamARa', 'label': 'kriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'krI', 'label': 'krI', 'attrib': [{'pos': 'vt9am', 'defs': 'buy'}], 'isLemma': 1},
	{'value': 'krIRat', 'label': 'krIRat', 'attrib': [{'pos': 'prap', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krIRAna', 'label': 'krIRAna', 'attrib': [{'pos': 'prmp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krIta', 'label': 'krIta', 'attrib': [{'pos': 'ppp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krItavat', 'label': 'krItavat', 'attrib': [{'pos': 'pap', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krItvA', 'label': 'krItvA', 'attrib': [{'pos': 'abs', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krIyamARa', 'label': 'krIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krudDa', 'label': 'krudDa', 'attrib': [{'pos': 'ppp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krudDavat', 'label': 'krudDavat', 'attrib': [{'pos': 'pap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krudDvA', 'label': 'krudDvA', 'attrib': [{'pos': 'abs', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kruD', 'label': 'kruD', 'attrib': [{'pos': 'vi4a', 'defs': 'be angry'}], 'isLemma': 1},
	{'value': 'kruDyat', 'label': 'kruDyat', 'attrib': [{'pos': 'prap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kruDyamAna', 'label': 'kruDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kretavya', 'label': 'kretavya', 'attrib': [{'pos': 'ger', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'kretum', 'label': 'kretum', 'attrib': [{'pos': 'inf', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'kreya', 'label': 'kreya', 'attrib': [{'pos': 'ger', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krezyat', 'label': 'krezyat', 'attrib': [{'pos': 'fap', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krezyamARa', 'label': 'krezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krotsyat', 'label': 'krotsyat', 'attrib': [{'pos': 'fap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krotsyamAna', 'label': 'krotsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krodDavya', 'label': 'krodDavya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krodDum', 'label': 'krodDum', 'attrib': [{'pos': 'inf', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kroDanIya', 'label': 'kroDanIya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kroDya', 'label': 'kroDya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kva', 'label': 'kva', 'attrib': [{'pos': 'int_adv', 'defs': 'where'}], 'isLemma': 1},
	{'value': 'kzaRa', 'label': 'kzaRa', 'attrib': [{'pos': 'm', 'defs': 'moment'}], 'isLemma': 1},
	{'value': 'kzatriya', 'label': 'kzatriya', 'attrib': [{'pos': 'm', 'defs': 'warrior'}], 'isLemma': 1},
	{'value': 'gacCat', 'label': 'gacCat', 'attrib': [{'pos': 'prap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gata', 'label': 'gata', 'attrib': [{'pos': 'ppp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gatavat', 'label': 'gatavat', 'attrib': [{'pos': 'pap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gatvA', 'label': 'gatvA', 'attrib': [{'pos': 'abs', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gantavya', 'label': 'gantavya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gantum', 'label': 'gantum', 'attrib': [{'pos': 'inf', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'ganDarva', 'label': 'ganDarva', 'attrib': [{'pos': 'm', 'defs': 'gandharva'}], 'isLemma': 1},
	{'value': 'gam', 'label': 'gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go'}], 'isLemma': 1},
	{'value': 'gamanIya', 'label': 'gamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamizyat', 'label': 'gamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamizyamARa', 'label': 'gamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamya', 'label': 'gamya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamyamAna', 'label': 'gamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'garitum', 'label': 'garitum', 'attrib': [{'pos': 'inf', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'garizyat', 'label': 'garizyat', 'attrib': [{'pos': 'fap', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'garizyamARa', 'label': 'garizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'garBa', 'label': 'garBa', 'attrib': [{'pos': 'm', 'defs': 'womb, embryo'}], 'isLemma': 1},
	{'value': 'garBin', 'label': 'garBin', 'attrib': [{'pos': 'adj', 'defs': 'pregnant'}], 'isLemma': 1},
	{'value': 'gIrRa', 'label': 'gIrRa', 'attrib': [{'pos': 'ppp', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'gIrRavat', 'label': 'gIrRavat', 'attrib': [{'pos': 'pap', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'gIrtvA', 'label': 'gIrtvA', 'attrib': [{'pos': 'abs', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'gIryamARa', 'label': 'gIryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'guru', 'label': 'guru', 'attrib': [{'pos': 'adj', 'defs': 'heavy, important'}, {'pos': 'm', 'defs': 'teacher'}], 'isLemma': 1},
	{'value': 'gfRat', 'label': 'gfRat', 'attrib': [{'pos': 'prap', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'gfha', 'label': 'gfha', 'attrib': [{'pos': 'n, m', 'defs': 'house'}], 'isLemma': 1},
	{'value': 'gfhARa', 'label': 'gfhARa', 'attrib': [{'pos': 'prmp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'gfhIta', 'label': 'gfhIta', 'attrib': [{'pos': 'ppp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'gfhItavat', 'label': 'gfhItavat', 'attrib': [{'pos': 'pap', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'gfhItvA', 'label': 'gfhItvA', 'attrib': [{'pos': 'abs', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'gfhRat', 'label': 'gfhRat', 'attrib': [{'pos': 'prap', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'gfhyamARa', 'label': 'gfhyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'gF', 'label': 'gF', 'attrib': [{'pos': 'vt9a', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 1},
	{'value': 'grah', 'label': 'grah', 'attrib': [{'pos': 'vt9am', 'defs': 'grab, grasp, seize'}], 'isLemma': 1},
	{'value': 'grahaRIya', 'label': 'grahaRIya', 'attrib': [{'pos': 'ger', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'grahItavya', 'label': 'grahItavya', 'attrib': [{'pos': 'ger', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'grahItum', 'label': 'grahItum', 'attrib': [{'pos': 'inf', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'grahIzyat', 'label': 'grahIzyat', 'attrib': [{'pos': 'fap', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'grahIzyamARa', 'label': 'grahIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'grAma', 'label': 'grAma', 'attrib': [{'pos': 'm', 'defs': 'village'}], 'isLemma': 1},
	{'value': 'grAhya', 'label': 'grAhya', 'attrib': [{'pos': 'ger', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'Gawa', 'label': 'Gawa', 'attrib': [{'pos': 'm', 'defs': 'pot'}], 'isLemma': 1},
	{'value': 'GAtya', 'label': 'GAtya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'Gora', 'label': 'Gora', 'attrib': [{'pos': 'adj', 'defs': 'horrible'}], 'isLemma': 1},
	{'value': 'Gnat', 'label': 'Gnat', 'attrib': [{'pos': 'prap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'ca', 'label': 'ca', 'attrib': [{'pos': 'conj_pcl', 'defs': 'and'}], 'isLemma': 1},
	{'value': 'cakamAna', 'label': 'cakamAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'cakfvas', 'label': 'cakfvas', 'attrib': [{'pos': 'pfap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'cakramARa', 'label': 'cakramARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'cakramvas', 'label': 'cakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'cakrARa', 'label': 'cakrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'catur', 'label': 'catur', 'attrib': [{'pos': 'card_num', 'defs': 'four'}], 'isLemma': 1},
	{'value': 'caturTa', 'label': 'caturTa', 'attrib': [{'pos': 'ord_num', 'defs': 'fourth'}], 'isLemma': 1},
	{'value': 'candra', 'label': 'candra', 'attrib': [{'pos': 'm', 'defs': 'moon'}, {'pos': 'm_pn', 'defs': 'Hari Candra'}], 'isLemma': 1},
	{'value': 'car', 'label': 'car', 'attrib': [{'pos': 'vt1am', 'defs': 'move, do'}], 'isLemma': 1},
	{'value': 'caraRIya', 'label': 'caraRIya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carat', 'label': 'carat', 'attrib': [{'pos': 'prap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caramARa', 'label': 'caramARa', 'attrib': [{'pos': 'prmp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carita', 'label': 'carita', 'attrib': [{'pos': 'ppp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritavat', 'label': 'caritavat', 'attrib': [{'pos': 'pap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritavya', 'label': 'caritavya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritum', 'label': 'caritum', 'attrib': [{'pos': 'inf', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritvA', 'label': 'caritvA', 'attrib': [{'pos': 'abs', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carizyat', 'label': 'carizyat', 'attrib': [{'pos': 'fap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carizyamARa', 'label': 'carizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carya', 'label': 'carya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caryamARa', 'label': 'caryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'cArin', 'label': 'cArin', 'attrib': [{'pos': 'adj', 'defs': 'doing, practicing'}], 'isLemma': 1},
	{'value': 'cikriyARa', 'label': 'cikriyARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'cikrIvas', 'label': 'cikrIvas', 'attrib': [{'pos': 'pfap', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'cicCidAna', 'label': 'cicCidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'cicCidvas', 'label': 'cicCidvas', 'attrib': [{'pos': 'pfap', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'cit', 'label': 'cit', 'attrib': [{'pos': 'pcl', 'defs': '/'}], 'isLemma': 1},
	{'value': 'citra', 'label': 'citra', 'attrib': [{'pos': 'adj', 'defs': 'conspicuous, bright, variegated, different'}], 'isLemma': 1},
	{'value': 'cint', 'label': 'cint', 'attrib': [{'pos': 'vt10am', 'defs': 'consider'}], 'isLemma': 1},
	{'value': 'cintanIya', 'label': 'cintanIya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayat', 'label': 'cintayat', 'attrib': [{'pos': 'prap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayamAna', 'label': 'cintayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayAm', 'label': 'cintayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayizyat', 'label': 'cintayizyat', 'attrib': [{'pos': 'fap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayizyamARa', 'label': 'cintayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintita', 'label': 'cintita', 'attrib': [{'pos': 'ppp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitavat', 'label': 'cintitavat', 'attrib': [{'pos': 'pap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitavya', 'label': 'cintitavya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitum', 'label': 'cintitum', 'attrib': [{'pos': 'inf', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitvA', 'label': 'cintitvA', 'attrib': [{'pos': 'abs', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintya', 'label': 'cintya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintyamAna', 'label': 'cintyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cira', 'label': 'cira', 'attrib': [{'pos': 'adj', 'defs': 'long'}], 'isLemma': 1},
	{'value': 'cukruDAna', 'label': 'cukruDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'cukruDvas', 'label': 'cukruDvas', 'attrib': [{'pos': 'pfap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'cur', 'label': 'cur', 'attrib': [{'pos': 'vt10a', 'defs': 'steal'}], 'isLemma': 1},
	{'value': 'cerARa', 'label': 'cerARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'cerivas', 'label': 'cerivas', 'attrib': [{'pos': 'pfap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'corayat', 'label': 'corayat', 'attrib': [{'pos': 'prap', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'corayAm', 'label': 'corayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'corayitum', 'label': 'corayitum', 'attrib': [{'pos': 'inf', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'corayitvA', 'label': 'corayitvA', 'attrib': [{'pos': 'abs', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'corayizyat', 'label': 'corayizyat', 'attrib': [{'pos': 'fap', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'corayizyamARa', 'label': 'corayizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'corita', 'label': 'corita', 'attrib': [{'pos': 'ppp', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'coritavat', 'label': 'coritavat', 'attrib': [{'pos': 'pap', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'coryamARa', 'label': 'coryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'cOra', 'label': 'cOra', 'attrib': [{'pos': 'm', 'defs': 'thief'}], 'isLemma': 1},
	{'value': 'CittvA', 'label': 'CittvA', 'attrib': [{'pos': 'abs', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cid', 'label': 'Cid', 'attrib': [{'pos': 'vt7am', 'defs': 'cleave'}], 'isLemma': 1},
	{'value': 'CidyamAna', 'label': 'CidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cindat', 'label': 'Cindat', 'attrib': [{'pos': 'prap', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'CindAna', 'label': 'CindAna', 'attrib': [{'pos': 'prmp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cinna', 'label': 'Cinna', 'attrib': [{'pos': 'ppp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cinnavat', 'label': 'Cinnavat', 'attrib': [{'pos': 'pap', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cettavya', 'label': 'Cettavya', 'attrib': [{'pos': 'ger', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cettum', 'label': 'Cettum', 'attrib': [{'pos': 'inf', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cetsyat', 'label': 'Cetsyat', 'attrib': [{'pos': 'fap', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'CetsyamAna', 'label': 'CetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'CedanIya', 'label': 'CedanIya', 'attrib': [{'pos': 'ger', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'Cedya', 'label': 'Cedya', 'attrib': [{'pos': 'ger', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'jagat', 'label': 'jagat', 'attrib': [{'pos': 'n', 'defs': 'moving, world'}], 'isLemma': 1},
	{'value': 'jaganvas', 'label': 'jaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'jagarARa', 'label': 'jagarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'jagarvas', 'label': 'jagarvas', 'attrib': [{'pos': 'pfap', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'jagfhARa', 'label': 'jagfhARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'jagfhvas', 'label': 'jagfhvas', 'attrib': [{'pos': 'pfap', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'jagmAna', 'label': 'jagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'jagmivas', 'label': 'jagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'jaGanvas', 'label': 'jaGanvas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'jaGnAna', 'label': 'jaGnAna', 'attrib': [{'pos': 'pfpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'jaGnivas', 'label': 'jaGnivas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'jajarARa', 'label': 'jajarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jajarvas', 'label': 'jajarvas', 'attrib': [{'pos': 'pfap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jajYAna', 'label': 'jajYAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be born, arise; caus. generate'}, {'pos': 'pfpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jan', 'jYA']},
	{'value': 'jajYivas', 'label': 'jajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jan', 'label': 'jan', 'attrib': [{'pos': 'vi4m', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 1},
	{'value': 'jana', 'label': 'jana', 'attrib': [{'pos': 'm', 'defs': 'people or person'}], 'isLemma': 1},
	{'value': 'jananIya', 'label': 'jananIya', 'attrib': [{'pos': 'ger', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'janayAm', 'label': 'janayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'janitavya', 'label': 'janitavya', 'attrib': [{'pos': 'ger', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'janitum', 'label': 'janitum', 'attrib': [{'pos': 'inf', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'janitvA', 'label': 'janitvA', 'attrib': [{'pos': 'abs', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'janizyamARa', 'label': 'janizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'janya', 'label': 'janya', 'attrib': [{'pos': 'ger', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'jayat', 'label': 'jayat', 'attrib': [{'pos': 'prap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jayanIya', 'label': 'jayanIya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jaraRIya', 'label': 'jaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jaritavya', 'label': 'jaritavya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jaritum', 'label': 'jaritum', 'attrib': [{'pos': 'inf', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jaritvA', 'label': 'jaritvA', 'attrib': [{'pos': 'abs', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarizyat', 'label': 'jarizyat', 'attrib': [{'pos': 'fap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarizyamARa', 'label': 'jarizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarItavya', 'label': 'jarItavya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarItum', 'label': 'jarItum', 'attrib': [{'pos': 'inf', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarItvA', 'label': 'jarItvA', 'attrib': [{'pos': 'abs', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarya', 'label': 'jarya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jahat', 'label': 'jahat', 'attrib': [{'pos': 'prap', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'jahAna', 'label': 'jahAna', 'attrib': [{'pos': 'prmp', 'defs': 'leave, abandon'}, {'pos': 'pfmp, pfpp', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'jahivas', 'label': 'jahivas', 'attrib': [{'pos': 'pfap', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'jahfvas', 'label': 'jahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'jahrARa', 'label': 'jahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'jAta', 'label': 'jAta', 'attrib': [{'pos': 'ppp', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'jAtavat', 'label': 'jAtavat', 'attrib': [{'pos': 'pap', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'jAnat', 'label': 'jAnat', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jAyamAna', 'label': 'jAyamAna', 'attrib': [{'pos': 'prmp, prpp', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'jArya', 'label': 'jArya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'ji', 'label': 'ji', 'attrib': [{'pos': 'vt1a', 'defs': 'conquer'}], 'isLemma': 1},
	{'value': 'jigivas', 'label': 'jigivas', 'attrib': [{'pos': 'pfap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jigyAna', 'label': 'jigyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jijIvas', 'label': 'jijIvas', 'attrib': [{'pos': 'pfap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jijIvAna', 'label': 'jijIvAna', 'attrib': [{'pos': 'pfpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jita', 'label': 'jita', 'attrib': [{'pos': 'ppp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jitavat', 'label': 'jitavat', 'attrib': [{'pos': 'pap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jitvA', 'label': 'jitvA', 'attrib': [{'pos': 'abs', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jIyamAna', 'label': 'jIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jIrRa', 'label': 'jIrRa', 'attrib': [{'pos': 'ppp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jIrRavat', 'label': 'jIrRavat', 'attrib': [{'pos': 'pap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jIryat', 'label': 'jIryat', 'attrib': [{'pos': 'prap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jIryamARa', 'label': 'jIryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jIv', 'label': 'jIv', 'attrib': [{'pos': 'vi1a', 'defs': 'live'}], 'isLemma': 1},
	{'value': 'jIvat', 'label': 'jIvat', 'attrib': [{'pos': 'prap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvanIya', 'label': 'jIvanIya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvita', 'label': 'jIvita', 'attrib': [{'pos': 'ppp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvitavat', 'label': 'jIvitavat', 'attrib': [{'pos': 'pap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvitavya', 'label': 'jIvitavya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvitum', 'label': 'jIvitum', 'attrib': [{'pos': 'inf', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvitvA', 'label': 'jIvitvA', 'attrib': [{'pos': 'abs', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvizyat', 'label': 'jIvizyat', 'attrib': [{'pos': 'fap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvizyamARa', 'label': 'jIvizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvya', 'label': 'jIvya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jIvyamAna', 'label': 'jIvyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'juhavAm', 'label': 'juhavAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'juhuvAna', 'label': 'juhuvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'juhUvas', 'label': 'juhUvas', 'attrib': [{'pos': 'pfap', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'juhvat', 'label': 'juhvat', 'attrib': [{'pos': 'prap', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'jF', 'label': 'jF', 'attrib': [{'pos': 'vi4a', 'defs': 'wither, age, grow old'}], 'isLemma': 1},
	{'value': 'jetavya', 'label': 'jetavya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jetum', 'label': 'jetum', 'attrib': [{'pos': 'inf', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jeya', 'label': 'jeya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jerARa', 'label': 'jerARa', 'attrib': [{'pos': 'pfpp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jerivas', 'label': 'jerivas', 'attrib': [{'pos': 'pfap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jezyat', 'label': 'jezyat', 'attrib': [{'pos': 'fap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jezyamARa', 'label': 'jezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jYA', 'label': 'jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'know'}], 'isLemma': 1},
	{'value': 'jYAta', 'label': 'jYAta', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAtavat', 'label': 'jYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAtavya', 'label': 'jYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAtum', 'label': 'jYAtum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAtvA', 'label': 'jYAtvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAna', 'label': 'jYAna', 'attrib': [{'pos': 'n', 'defs': 'knowledge'}], 'isLemma': 1},
	{'value': 'jYAnIya', 'label': 'jYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAyamAna', 'label': 'jYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAsyat', 'label': 'jYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYAsyamAna', 'label': 'jYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jYeya', 'label': 'jYeya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'taMsyat', 'label': 'taMsyat', 'attrib': [{'pos': 'fap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'taMsyamAna', 'label': 'taMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tata', 'label': 'tata', 'attrib': [{'pos': 'ppp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tatavat', 'label': 'tatavat', 'attrib': [{'pos': 'pap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tatas', 'label': 'tatas', 'attrib': [{'pos': 'dem_adv', 'defs': 'from that, therefore, then'}], 'isLemma': 1},
	{'value': 'tatyajAna', 'label': 'tatyajAna', 'attrib': [{'pos': 'pfpp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tatyajvas', 'label': 'tatyajvas', 'attrib': [{'pos': 'pfap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tatra', 'label': 'tatra', 'attrib': [{'pos': 'dem_adv', 'defs': 'there'}], 'isLemma': 1},
	{'value': 'tatrARa', 'label': 'tatrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'taTA', 'label': 'taTA', 'attrib': [{'pos': 'dem_adv', 'defs': 'in that manner, thus'}], 'isLemma': 1},
	{'value': 'tad', 'label': 'tad', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'that, the'}, {'pos': 'dem_adv', 'defs': 'so'}], 'isLemma': 1},
	{'value': 'tadA', 'label': 'tadA', 'attrib': [{'pos': 'dem_adv', 'defs': 'at that time, then'}], 'isLemma': 1},
	{'value': 'tan', 'label': 'tan', 'attrib': [{'pos': 'vt8am', 'defs': 'extend'}], 'isLemma': 1},
	{'value': 'tananIya', 'label': 'tananIya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanitavya', 'label': 'tanitavya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanitum', 'label': 'tanitum', 'attrib': [{'pos': 'inf', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanitvA', 'label': 'tanitvA', 'attrib': [{'pos': 'abs', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanizyat', 'label': 'tanizyat', 'attrib': [{'pos': 'fap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanizyamARa', 'label': 'tanizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanyamAna', 'label': 'tanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanvat', 'label': 'tanvat', 'attrib': [{'pos': 'prap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanvAna', 'label': 'tanvAna', 'attrib': [{'pos': 'prmp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tarhi', 'label': 'tarhi', 'attrib': [{'pos': 'dem_adv', 'defs': 'then, therefore'}], 'isLemma': 1},
	{'value': 'tasTAna', 'label': 'tasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tasTivas', 'label': 'tasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tAnya', 'label': 'tAnya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tAvat', 'label': 'tAvat', 'attrib': [{'pos': 'dem_adj', 'defs': 'of that extent'}, {'pos': 'dem_adv', 'defs': 'to that extent'}], 'isLemma': 1},
	{'value': 'tityajAna', 'label': 'tityajAna', 'attrib': [{'pos': 'pfpp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tityajvas', 'label': 'tityajvas', 'attrib': [{'pos': 'pfap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tiras', 'label': 'tiras', 'attrib': [{'pos': 'preverb', 'defs': 'across, horizontal'}], 'isLemma': 1},
	{'value': 'tiras_BU', 'label': 'tiras_BU', 'attrib': [{'pos': 'vi1a', 'defs': 'become horizontal, disappear'}], 'isLemma': 1},
	{'value': 'tirobaBUvas', 'label': 'tirobaBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tiroBavat', 'label': 'tiroBavat', 'attrib': [{'pos': 'prap', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tiroBavanIya', 'label': 'tiroBavanIya', 'attrib': [{'pos': 'ger', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tiroBavitavya', 'label': 'tiroBavitavya', 'attrib': [{'pos': 'ger', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tiroBavitum', 'label': 'tiroBavitum', 'attrib': [{'pos': 'inf', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tiroBavizyat', 'label': 'tiroBavizyat', 'attrib': [{'pos': 'fap', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tiroBavizyamARa', 'label': 'tiroBavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tiroBavya', 'label': 'tiroBavya', 'attrib': [{'pos': 'ger', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tiroBUta', 'label': 'tiroBUta', 'attrib': [{'pos': 'ppp', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tiroBUtavat', 'label': 'tiroBUtavat', 'attrib': [{'pos': 'pap', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tiroBUya', 'label': 'tiroBUya', 'attrib': [{'pos': 'abs', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tiroBUyamAna', 'label': 'tiroBUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tizWat', 'label': 'tizWat', 'attrib': [{'pos': 'prap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tizWamAna', 'label': 'tizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tu', 'label': 'tu', 'attrib': [{'pos': 'conj_pcl', 'defs': 'but'}], 'isLemma': 1},
	{'value': 'tftIya', 'label': 'tftIya', 'attrib': [{'pos': 'ord_num', 'defs': 'third'}], 'isLemma': 1},
	{'value': 'tenAna', 'label': 'tenAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tenivas', 'label': 'tenivas', 'attrib': [{'pos': 'pfap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tyakta', 'label': 'tyakta', 'attrib': [{'pos': 'ppp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyaktavat', 'label': 'tyaktavat', 'attrib': [{'pos': 'pap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyaktavya', 'label': 'tyaktavya', 'attrib': [{'pos': 'ger', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyaktum', 'label': 'tyaktum', 'attrib': [{'pos': 'inf', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyaktvA', 'label': 'tyaktvA', 'attrib': [{'pos': 'abs', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyakzyat', 'label': 'tyakzyat', 'attrib': [{'pos': 'fap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyakzyamARa', 'label': 'tyakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyaj', 'label': 'tyaj', 'attrib': [{'pos': 'vt1a', 'defs': 'abandon'}], 'isLemma': 1},
	{'value': 'tyajat', 'label': 'tyajat', 'attrib': [{'pos': 'prap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyajanIya', 'label': 'tyajanIya', 'attrib': [{'pos': 'ger', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyajyamAna', 'label': 'tyajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyAjya', 'label': 'tyAjya', 'attrib': [{'pos': 'ger', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'trARa', 'label': 'trARa', 'attrib': [{'pos': 'ppp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'trAta', 'label': 'trAta', 'attrib': [{'pos': 'ppp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'trAtavat', 'label': 'trAtavat', 'attrib': [{'pos': 'pap', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'trAtum', 'label': 'trAtum', 'attrib': [{'pos': 'inf', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'trAtvA', 'label': 'trAtvA', 'attrib': [{'pos': 'abs', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'trAyamARa', 'label': 'trAyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'save'}, {'pos': 'prpp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'trAsyamAna', 'label': 'trAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'tri', 'label': 'tri', 'attrib': [{'pos': 'card_num', 'defs': 'three'}], 'isLemma': 1},
	{'value': 'trE', 'label': 'trE', 'attrib': [{'pos': 'vt1m', 'defs': 'save'}], 'isLemma': 1},
	{'value': 'dagDa', 'label': 'dagDa', 'attrib': [{'pos': 'ppp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagDavat', 'label': 'dagDavat', 'attrib': [{'pos': 'pap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagDavya', 'label': 'dagDavya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagDum', 'label': 'dagDum', 'attrib': [{'pos': 'inf', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagDvA', 'label': 'dagDvA', 'attrib': [{'pos': 'abs', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'datta', 'label': 'datta', 'attrib': [{'pos': 'ppp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dattavat', 'label': 'dattavat', 'attrib': [{'pos': 'pap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dattvA', 'label': 'dattvA', 'attrib': [{'pos': 'abs', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadat', 'label': 'dadat', 'attrib': [{'pos': 'prap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadahAna', 'label': 'dadahAna', 'attrib': [{'pos': 'pfpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dadahvas', 'label': 'dadahvas', 'attrib': [{'pos': 'pfap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dadAna', 'label': 'dadAna', 'attrib': [{'pos': 'prmp', 'defs': 'give'}, {'pos': 'pfmp, pfpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadivas', 'label': 'dadivas', 'attrib': [{'pos': 'pfap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadfSAna', 'label': 'dadfSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dadfSivas', 'label': 'dadfSivas', 'attrib': [{'pos': 'pfap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dadfSvas', 'label': 'dadfSvas', 'attrib': [{'pos': 'pfap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'daDat', 'label': 'daDat', 'attrib': [{'pos': 'prap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'daDAna', 'label': 'daDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put'}, {'pos': 'pfmp, pfpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'daDivas', 'label': 'daDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'danta', 'label': 'danta', 'attrib': [{'pos': 'm', 'defs': 'tooth, tusk'}], 'isLemma': 1},
	{'value': 'darSanIya', 'label': 'darSanIya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'daSan', 'label': 'daSan', 'attrib': [{'pos': 'card_num', 'defs': 'ten'}], 'isLemma': 1},
	{'value': 'daSama', 'label': 'daSama', 'attrib': [{'pos': 'ord_num', 'defs': 'tenth'}], 'isLemma': 1},
	{'value': 'dah', 'label': 'dah', 'attrib': [{'pos': 'vt1a', 'defs': 'burn'}], 'isLemma': 1},
	{'value': 'dahat', 'label': 'dahat', 'attrib': [{'pos': 'prap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dahanIya', 'label': 'dahanIya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dahyamAna', 'label': 'dahyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dA', 'label': 'dA', 'attrib': [{'pos': 'vt3am', 'defs': 'give'}], 'isLemma': 1},
	{'value': 'dAtavya', 'label': 'dAtavya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dAtum', 'label': 'dAtum', 'attrib': [{'pos': 'inf', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dAnIya', 'label': 'dAnIya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dAsyat', 'label': 'dAsyat', 'attrib': [{'pos': 'fap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dAsyamAna', 'label': 'dAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dAhya', 'label': 'dAhya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'didivas', 'label': 'didivas', 'attrib': [{'pos': 'pfap', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'didivAna', 'label': 'didivAna', 'attrib': [{'pos': 'pfpp', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'dina', 'label': 'dina', 'attrib': [{'pos': 'n', 'defs': 'day'}], 'isLemma': 1},
	{'value': 'div', 'label': 'div', 'attrib': [{'pos': 'm, f', 'defs': 'sky, heaven, day'}, {'pos': 'vt4a', 'defs': 'play'}], 'isLemma': 1},
	{'value': 'diS', 'label': 'diS', 'attrib': [{'pos': 'f', 'defs': 'direction'}], 'isLemma': 1},
	{'value': 'dIyamAna', 'label': 'dIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dIrGa', 'label': 'dIrGa', 'attrib': [{'pos': 'adj', 'defs': 'long'}], 'isLemma': 1},
	{'value': 'dIrGAyus', 'label': 'dIrGAyus', 'attrib': [{'pos': 'adj', 'defs': 'whose life is long, long-lived'}], 'isLemma': 1},
	{'value': 'dIvyat', 'label': 'dIvyat', 'attrib': [{'pos': 'prap', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'dIvyamAna', 'label': 'dIvyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'duHKa', 'label': 'duHKa', 'attrib': [{'pos': 'adj', 'defs': 'uncomfortable'}, {'pos': 'n', 'defs': 'sorrow'}], 'isLemma': 1},
	{'value': 'duHKin', 'label': 'duHKin', 'attrib': [{'pos': 'adj', 'defs': 'sorrowful, sad'}], 'isLemma': 1},
	{'value': 'durApa', 'label': 'durApa', 'attrib': [{'pos': 'adj', 'defs': 'difficult to obtain'}], 'isLemma': 1},
	{'value': 'durmanas', 'label': 'durmanas', 'attrib': [{'pos': 'adj', 'defs': 'low-spirited, sad'}], 'isLemma': 1},
	{'value': 'duzzanta', 'label': 'duzzanta', 'attrib': [{'pos': 'm_pn', 'defs': 'Duṣṣanta'}], 'isLemma': 1},
	{'value': 'dus', 'label': 'dus', 'attrib': [{'pos': 'preverb', 'defs': 'ill'}], 'isLemma': 1},
	{'value': 'duhitf', 'label': 'duhitf', 'attrib': [{'pos': 'f', 'defs': 'daughter'}], 'isLemma': 1},
	{'value': 'dUra', 'label': 'dUra', 'attrib': [{'pos': 'adj', 'defs': 'distant'}], 'isLemma': 1},
	{'value': 'dfS', 'label': 'dfS', 'attrib': [{'pos': 'vt1a', 'defs': 'see, watch'}], 'isLemma': 1},
	{'value': 'dfSya', 'label': 'dfSya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dfSyamAna', 'label': 'dfSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dfzwa', 'label': 'dfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dfzwavat', 'label': 'dfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dfzwvA', 'label': 'dfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'deya', 'label': 'deya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'deva', 'label': 'deva', 'attrib': [{'pos': 'm', 'defs': 'god'}], 'isLemma': 1},
	{'value': 'devanIya', 'label': 'devanIya', 'attrib': [{'pos': 'ger', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'devitavya', 'label': 'devitavya', 'attrib': [{'pos': 'ger', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'devitum', 'label': 'devitum', 'attrib': [{'pos': 'inf', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'devitvA', 'label': 'devitvA', 'attrib': [{'pos': 'abs', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'devizyat', 'label': 'devizyat', 'attrib': [{'pos': 'fap', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'devizyamARa', 'label': 'devizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'devI', 'label': 'devI', 'attrib': [{'pos': 'f', 'defs': 'goddess'}], 'isLemma': 1},
	{'value': 'devya', 'label': 'devya', 'attrib': [{'pos': 'ger', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'deSa', 'label': 'deSa', 'attrib': [{'pos': 'm', 'defs': 'place, country'}], 'isLemma': 1},
	{'value': 'deha', 'label': 'deha', 'attrib': [{'pos': 'm', 'defs': 'body'}], 'isLemma': 1},
	{'value': 'dyUta', 'label': 'dyUta', 'attrib': [{'pos': 'ppp', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'dyUtavat', 'label': 'dyUtavat', 'attrib': [{'pos': 'pap', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'dyUtvA', 'label': 'dyUtvA', 'attrib': [{'pos': 'abs', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'drakzyat', 'label': 'drakzyat', 'attrib': [{'pos': 'fap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'drakzyamARa', 'label': 'drakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'drazwavya', 'label': 'drazwavya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'drazwum', 'label': 'drazwum', 'attrib': [{'pos': 'inf', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dvi', 'label': 'dvi', 'attrib': [{'pos': 'card_num', 'defs': 'two'}], 'isLemma': 1},
	{'value': 'dvitIya', 'label': 'dvitIya', 'attrib': [{'pos': 'ord_num', 'defs': 'second'}], 'isLemma': 1},
	{'value': 'Dakzyat', 'label': 'Dakzyat', 'attrib': [{'pos': 'fap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'DakzyamARa', 'label': 'DakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'Dana', 'label': 'Dana', 'attrib': [{'pos': 'n', 'defs': 'wealth, money'}], 'isLemma': 1},
	{'value': 'Danin', 'label': 'Danin', 'attrib': [{'pos': 'adj', 'defs': 'wealthy'}], 'isLemma': 1},
	{'value': 'Danus', 'label': 'Danus', 'attrib': [{'pos': 'n', 'defs': 'bow'}], 'isLemma': 1},
	{'value': 'Darma', 'label': 'Darma', 'attrib': [{'pos': 'm', 'defs': 'dharma , duty, right action'}], 'isLemma': 1},
	{'value': 'DA', 'label': 'DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put'}], 'isLemma': 1},
	{'value': 'DAtavya', 'label': 'DAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'DAtum', 'label': 'DAtum', 'attrib': [{'pos': 'inf', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'DAnIya', 'label': 'DAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'DArtarAzwra', 'label': 'DArtarAzwra', 'attrib': [{'pos': 'm', 'defs': 'descendant of Dhṛtarāṣṭra'}], 'isLemma': 1},
	{'value': 'DAsyat', 'label': 'DAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'DAsyamAna', 'label': 'DAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'DI', 'label': 'DI', 'attrib': [{'pos': 'f', 'defs': 'intelligence, thought'}], 'isLemma': 1},
	{'value': 'DImat', 'label': 'DImat', 'attrib': [{'pos': 'adj', 'defs': 'wise, intelligent'}], 'isLemma': 1},
	{'value': 'DIyamAna', 'label': 'DIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'Denu', 'label': 'Denu', 'attrib': [{'pos': 'f', 'defs': 'cow'}], 'isLemma': 1},
	{'value': 'Deya', 'label': 'Deya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'na', 'label': 'na', 'attrib': [{'pos': 'neg_pcl', 'defs': 'not'}], 'isLemma': 1},
	{'value': 'nagara', 'label': 'nagara', 'attrib': [{'pos': 'n', 'defs': 'city'}], 'isLemma': 1},
	{'value': 'nagna', 'label': 'nagna', 'attrib': [{'pos': 'adj', 'defs': 'naked'}], 'isLemma': 1},
	{'value': 'nadI', 'label': 'nadI', 'attrib': [{'pos': 'f', 'defs': 'river'}], 'isLemma': 1},
	{'value': 'nayat', 'label': 'nayat', 'attrib': [{'pos': 'prap', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nayanIya', 'label': 'nayanIya', 'attrib': [{'pos': 'ger', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nayamAna', 'label': 'nayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nara', 'label': 'nara', 'attrib': [{'pos': 'm', 'defs': 'man'}], 'isLemma': 1},
	{'value': 'navan', 'label': 'navan', 'attrib': [{'pos': 'card_num', 'defs': 'nine'}], 'isLemma': 1},
	{'value': 'navama', 'label': 'navama', 'attrib': [{'pos': 'ord_num', 'defs': 'ninth'}], 'isLemma': 1},
	{'value': 'nAma', 'label': 'nAma', 'attrib': [{'pos': 'adv', 'defs': 'by name'}], 'isLemma': 1},
	{'value': 'nAman', 'label': 'nAman', 'attrib': [{'pos': 'n', 'defs': 'name'}], 'isLemma': 1},
	{'value': 'ni', 'label': 'ni', 'attrib': [{'pos': 'preverb', 'defs': 'in'}], 'isLemma': 1},
	{'value': 'ni_DA', 'label': 'ni_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put down, deposit'}], 'isLemma': 1},
	{'value': 'nidaDat', 'label': 'nidaDat', 'attrib': [{'pos': 'prap', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nidaDAna', 'label': 'nidaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put down, deposit'}, {'pos': 'pfmp, pfpp', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nidaDivas', 'label': 'nidaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'niDAtavya', 'label': 'niDAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'niDAtum', 'label': 'niDAtum', 'attrib': [{'pos': 'inf', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'niDAnIya', 'label': 'niDAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'niDAya', 'label': 'niDAya', 'attrib': [{'pos': 'abs', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'niDAsyat', 'label': 'niDAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'niDAsyamAna', 'label': 'niDAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'niDIyamAna', 'label': 'niDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'niDeya', 'label': 'niDeya', 'attrib': [{'pos': 'ger', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'ninIvas', 'label': 'ninIvas', 'attrib': [{'pos': 'pfap', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'ninyAna', 'label': 'ninyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nirgacCat', 'label': 'nirgacCat', 'attrib': [{'pos': 'prap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgata', 'label': 'nirgata', 'attrib': [{'pos': 'ppp', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgatavat', 'label': 'nirgatavat', 'attrib': [{'pos': 'pap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgatya', 'label': 'nirgatya', 'attrib': [{'pos': 'abs', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgantavya', 'label': 'nirgantavya', 'attrib': [{'pos': 'ger', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgantum', 'label': 'nirgantum', 'attrib': [{'pos': 'inf', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgamanIya', 'label': 'nirgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgamizyat', 'label': 'nirgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgamizyamARa', 'label': 'nirgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgamya', 'label': 'nirgamya', 'attrib': [{'pos': 'abs', 'defs': 'go out, emerge'}, {'pos': 'ger', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgamyamAna', 'label': 'nirgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirjaganvas', 'label': 'nirjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirjagmAna', 'label': 'nirjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirjagmivas', 'label': 'nirjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'niScakramARa', 'label': 'niScakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niScakramvas', 'label': 'niScakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nizkraMsyat', 'label': 'nizkraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nizkraMsyamAna', 'label': 'nizkraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nizkramaRIya', 'label': 'nizkramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nizkramitavya', 'label': 'nizkramitavya', 'attrib': [{'pos': 'ger', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nizkramitum', 'label': 'nizkramitum', 'attrib': [{'pos': 'inf', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nizkramya', 'label': 'nizkramya', 'attrib': [{'pos': 'abs', 'defs': 'go out, depart'}, {'pos': 'ger', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nizkramyamARa', 'label': 'nizkramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nizkrAnta', 'label': 'nizkrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nizkrAntavat', 'label': 'nizkrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nizkrAmat', 'label': 'nizkrAmat', 'attrib': [{'pos': 'prap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nizkrAmyat', 'label': 'nizkrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nis', 'label': 'nis', 'attrib': [{'pos': 'preverb', 'defs': 'out'}], 'isLemma': 1},
	{'value': 'nis_kram', 'label': 'nis_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'go out, depart'}], 'isLemma': 1},
	{'value': 'nis_gam', 'label': 'nis_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go out, emerge'}], 'isLemma': 1},
	{'value': 'nihita', 'label': 'nihita', 'attrib': [{'pos': 'ppp', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nihitavat', 'label': 'nihitavat', 'attrib': [{'pos': 'pap', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nI', 'label': 'nI', 'attrib': [{'pos': 'vt1am', 'defs': 'lead'}], 'isLemma': 1},
	{'value': 'nIta', 'label': 'nIta', 'attrib': [{'pos': 'ppp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nItavat', 'label': 'nItavat', 'attrib': [{'pos': 'pap', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nItvA', 'label': 'nItvA', 'attrib': [{'pos': 'abs', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nIyamAna', 'label': 'nIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nfpa', 'label': 'nfpa', 'attrib': [{'pos': 'm', 'defs': 'king'}], 'isLemma': 1},
	{'value': 'netavya', 'label': 'netavya', 'attrib': [{'pos': 'ger', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'netum', 'label': 'netum', 'attrib': [{'pos': 'inf', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'neya', 'label': 'neya', 'attrib': [{'pos': 'ger', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nezyat', 'label': 'nezyat', 'attrib': [{'pos': 'fap', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nezyamARa', 'label': 'nezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'paktavya', 'label': 'paktavya', 'attrib': [{'pos': 'ger', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'paktum', 'label': 'paktum', 'attrib': [{'pos': 'inf', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'paktvA', 'label': 'paktvA', 'attrib': [{'pos': 'abs', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pakva', 'label': 'pakva', 'attrib': [{'pos': 'ppp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pakvavat', 'label': 'pakvavat', 'attrib': [{'pos': 'pap', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pakza', 'label': 'pakza', 'attrib': [{'pos': 'm', 'defs': 'wing, side'}], 'isLemma': 1},
	{'value': 'pakzyat', 'label': 'pakzyat', 'attrib': [{'pos': 'fap', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pakzyamARa', 'label': 'pakzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pac', 'label': 'pac', 'attrib': [{'pos': 'vt1am', 'defs': 'cook'}], 'isLemma': 1},
	{'value': 'pacat', 'label': 'pacat', 'attrib': [{'pos': 'prap', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pacanIya', 'label': 'pacanIya', 'attrib': [{'pos': 'ger', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pacamAna', 'label': 'pacamAna', 'attrib': [{'pos': 'prmp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pacyamAna', 'label': 'pacyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'paYcan', 'label': 'paYcan', 'attrib': [{'pos': 'card_num', 'defs': 'five'}], 'isLemma': 1},
	{'value': 'paYcama', 'label': 'paYcama', 'attrib': [{'pos': 'ord_num', 'defs': 'fifth'}], 'isLemma': 1},
	{'value': 'paW', 'label': 'paW', 'attrib': [{'pos': 'vt1a', 'defs': 'read aloud, recite'}], 'isLemma': 1},
	{'value': 'paWat', 'label': 'paWat', 'attrib': [{'pos': 'prap', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paWanIya', 'label': 'paWanIya', 'attrib': [{'pos': 'ger', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paWita', 'label': 'paWita', 'attrib': [{'pos': 'ppp', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paWitavat', 'label': 'paWitavat', 'attrib': [{'pos': 'pap', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paWitavya', 'label': 'paWitavya', 'attrib': [{'pos': 'ger', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paWitum', 'label': 'paWitum', 'attrib': [{'pos': 'inf', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paWitvA', 'label': 'paWitvA', 'attrib': [{'pos': 'abs', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paWizyat', 'label': 'paWizyat', 'attrib': [{'pos': 'fap', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paWizyamARa', 'label': 'paWizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paWyamAna', 'label': 'paWyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'pat', 'label': 'pat', 'attrib': [{'pos': 'vt1a', 'defs': 'fall, fly'}], 'isLemma': 1},
	{'value': 'patat', 'label': 'patat', 'attrib': [{'pos': 'prap', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patanIya', 'label': 'patanIya', 'attrib': [{'pos': 'ger', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'pati', 'label': 'pati', 'attrib': [{'pos': 'm', 'defs': 'lord, husband'}], 'isLemma': 1},
	{'value': 'patita', 'label': 'patita', 'attrib': [{'pos': 'ppp', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patitavat', 'label': 'patitavat', 'attrib': [{'pos': 'pap', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patitavya', 'label': 'patitavya', 'attrib': [{'pos': 'ger', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patitum', 'label': 'patitum', 'attrib': [{'pos': 'inf', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patitvA', 'label': 'patitvA', 'attrib': [{'pos': 'abs', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patizyat', 'label': 'patizyat', 'attrib': [{'pos': 'fap', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patizyamARa', 'label': 'patizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'pattavya', 'label': 'pattavya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pattum', 'label': 'pattum', 'attrib': [{'pos': 'inf', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pattvA', 'label': 'pattvA', 'attrib': [{'pos': 'abs', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'patnI', 'label': 'patnI', 'attrib': [{'pos': 'f', 'defs': 'wife'}], 'isLemma': 1},
	{'value': 'patyamAna', 'label': 'patyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patsyamAna', 'label': 'patsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pad', 'label': 'pad', 'attrib': [{'pos': 'vt4m', 'defs': 'step'}], 'isLemma': 1},
	{'value': 'pada', 'label': 'pada', 'attrib': [{'pos': 'n', 'defs': 'step'}], 'isLemma': 1},
	{'value': 'padanIya', 'label': 'padanIya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'padyamAna', 'label': 'padyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'step'}, {'pos': 'prpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'panna', 'label': 'panna', 'attrib': [{'pos': 'ppp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pannavat', 'label': 'pannavat', 'attrib': [{'pos': 'pap', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'paparARa', 'label': 'paparARa', 'attrib': [{'pos': 'pfpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'paparvas', 'label': 'paparvas', 'attrib': [{'pos': 'pfap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'papAna', 'label': 'papAna', 'attrib': [{'pos': 'pfpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'papivas', 'label': 'papivas', 'attrib': [{'pos': 'pfap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'papFvas', 'label': 'papFvas', 'attrib': [{'pos': 'pfap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'papracCAna', 'label': 'papracCAna', 'attrib': [{'pos': 'pfpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'papracCvas', 'label': 'papracCvas', 'attrib': [{'pos': 'pfap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'paprARa', 'label': 'paprARa', 'attrib': [{'pos': 'pfpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'para', 'label': 'para', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'farther'}], 'isLemma': 1},
	{'value': 'param', 'label': 'param', 'attrib': [{'pos': 'adv', 'defs': 'after, beyond'}], 'isLemma': 1},
	{'value': 'pari', 'label': 'pari', 'attrib': [{'pos': 'preverb', 'defs': 'around'}], 'isLemma': 1},
	{'value': 'paricaraRIya', 'label': 'paricaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricarat', 'label': 'paricarat', 'attrib': [{'pos': 'prap', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricaramARa', 'label': 'paricaramARa', 'attrib': [{'pos': 'prmp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricarita', 'label': 'paricarita', 'attrib': [{'pos': 'ppp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricaritavat', 'label': 'paricaritavat', 'attrib': [{'pos': 'pap', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricaritavya', 'label': 'paricaritavya', 'attrib': [{'pos': 'ger', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricaritum', 'label': 'paricaritum', 'attrib': [{'pos': 'inf', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricarizyat', 'label': 'paricarizyat', 'attrib': [{'pos': 'fap', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricarizyamARa', 'label': 'paricarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricarya', 'label': 'paricarya', 'attrib': [{'pos': 'abs', 'defs': 'move around'}, {'pos': 'ger', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricaryamARa', 'label': 'paricaryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricerARa', 'label': 'paricerARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricerivas', 'label': 'paricerivas', 'attrib': [{'pos': 'pfap', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'pari_car', 'label': 'pari_car', 'attrib': [{'pos': 'vt1am', 'defs': 'move around'}], 'isLemma': 1},
	{'value': 'pari_DA', 'label': 'pari_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'wear, put on (clothes)'}], 'isLemma': 1},
	{'value': 'paridaDat', 'label': 'paridaDat', 'attrib': [{'pos': 'prap', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'paridaDAna', 'label': 'paridaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'wear, put on (clothes)'}, {'pos': 'pfmp, pfpp', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'paridaDivas', 'label': 'paridaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'pariDAtavya', 'label': 'pariDAtavya', 'attrib': [{'pos': 'ger', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'pariDAtum', 'label': 'pariDAtum', 'attrib': [{'pos': 'inf', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'pariDAnIya', 'label': 'pariDAnIya', 'attrib': [{'pos': 'ger', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'pariDAya', 'label': 'pariDAya', 'attrib': [{'pos': 'abs', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'pariDAsyat', 'label': 'pariDAsyat', 'attrib': [{'pos': 'fap', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'pariDAsyamAna', 'label': 'pariDAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'pariDIyamAna', 'label': 'pariDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'pariDeya', 'label': 'pariDeya', 'attrib': [{'pos': 'ger', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'parizyat', 'label': 'parizyat', 'attrib': [{'pos': 'fap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'parizyamARa', 'label': 'parizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'parihita', 'label': 'parihita', 'attrib': [{'pos': 'ppp', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'parihitavat', 'label': 'parihitavat', 'attrib': [{'pos': 'pap', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'parIzyat', 'label': 'parIzyat', 'attrib': [{'pos': 'fap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'parIzyamARa', 'label': 'parIzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'parvata', 'label': 'parvata', 'attrib': [{'pos': 'm', 'defs': 'mountain'}], 'isLemma': 1},
	{'value': 'pavanIya', 'label': 'pavanIya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavita', 'label': 'pavita', 'attrib': [{'pos': 'ppp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitavat', 'label': 'pavitavat', 'attrib': [{'pos': 'pap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitavya', 'label': 'pavitavya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitum', 'label': 'pavitum', 'attrib': [{'pos': 'inf', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitvA', 'label': 'pavitvA', 'attrib': [{'pos': 'abs', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavizyat', 'label': 'pavizyat', 'attrib': [{'pos': 'fap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavizyamARa', 'label': 'pavizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavya', 'label': 'pavya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'paSu', 'label': 'paSu', 'attrib': [{'pos': 'm', 'defs': 'domestic animal'}], 'isLemma': 1},
	{'value': 'paScAt', 'label': 'paScAt', 'attrib': [{'pos': 'adv', 'defs': 'afterwards, later'}], 'isLemma': 1},
	{'value': 'paSyat', 'label': 'paSyat', 'attrib': [{'pos': 'prap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'paspfSAna', 'label': 'paspfSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'paspfSvas', 'label': 'paspfSvas', 'attrib': [{'pos': 'pfap', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'pA', 'label': 'pA', 'attrib': [{'pos': 'vt1a', 'defs': 'drink'}], 'isLemma': 1},
	{'value': 'pAkya', 'label': 'pAkya', 'attrib': [{'pos': 'ger', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pAwaliputra', 'label': 'pAwaliputra', 'attrib': [{'pos': 'n_pn', 'defs': 'Pāṭaliputra'}], 'isLemma': 1},
	{'value': 'pAWya', 'label': 'pAWya', 'attrib': [{'pos': 'ger', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'pARqava', 'label': 'pARqava', 'attrib': [{'pos': 'm', 'defs': 'descendant of Pāṇḍu'}], 'isLemma': 1},
	{'value': 'pAtavya', 'label': 'pAtavya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pAtum', 'label': 'pAtum', 'attrib': [{'pos': 'inf', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pAtya', 'label': 'pAtya', 'attrib': [{'pos': 'ger', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'pAda', 'label': 'pAda', 'attrib': [{'pos': 'm', 'defs': 'foot'}], 'isLemma': 1},
	{'value': 'pAdya', 'label': 'pAdya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pAnIya', 'label': 'pAnIya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pApa', 'label': 'pApa', 'attrib': [{'pos': 'adj', 'defs': 'bad, evil'}, {'pos': 'n', 'defs': 'evil, sin'}], 'isLemma': 1},
	{'value': 'pArvatI', 'label': 'pArvatI', 'attrib': [{'pos': 'f_pn', 'defs': 'Pārvatī'}], 'isLemma': 1},
	{'value': 'pAl', 'label': 'pAl', 'attrib': [{'pos': 'vt10am', 'defs': 'protect'}], 'isLemma': 1},
	{'value': 'pAlanIya', 'label': 'pAlanIya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAlayat', 'label': 'pAlayat', 'attrib': [{'pos': 'prap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAlayamAna', 'label': 'pAlayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAlayAm', 'label': 'pAlayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAlayitum', 'label': 'pAlayitum', 'attrib': [{'pos': 'inf', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAlayitvA', 'label': 'pAlayitvA', 'attrib': [{'pos': 'abs', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAlayizyat', 'label': 'pAlayizyat', 'attrib': [{'pos': 'fap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAlayizyamARa', 'label': 'pAlayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAlita', 'label': 'pAlita', 'attrib': [{'pos': 'ppp', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAlitavat', 'label': 'pAlitavat', 'attrib': [{'pos': 'pap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAlitavya', 'label': 'pAlitavya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAlya', 'label': 'pAlya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAlyamAna', 'label': 'pAlyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pAsyat', 'label': 'pAsyat', 'attrib': [{'pos': 'fap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pAsyamAna', 'label': 'pAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pitAmaha', 'label': 'pitAmaha', 'attrib': [{'pos': 'm', 'defs': 'grandfather'}], 'isLemma': 1},
	{'value': 'pitf', 'label': 'pitf', 'attrib': [{'pos': 'm', 'defs': 'father'}], 'isLemma': 1},
	{'value': 'pibat', 'label': 'pibat', 'attrib': [{'pos': 'prap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pIta', 'label': 'pIta', 'attrib': [{'pos': 'ppp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pItavat', 'label': 'pItavat', 'attrib': [{'pos': 'pap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pItvA', 'label': 'pItvA', 'attrib': [{'pos': 'abs', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pIyamAna', 'label': 'pIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'putra', 'label': 'putra', 'attrib': [{'pos': 'm', 'defs': 'son, child'}], 'isLemma': 1},
	{'value': 'putrI', 'label': 'putrI', 'attrib': [{'pos': 'f', 'defs': 'daughter'}], 'isLemma': 1},
	{'value': 'punat', 'label': 'punat', 'attrib': [{'pos': 'prap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'punar', 'label': 'punar', 'attrib': [{'pos': 'adv', 'defs': 'again'}], 'isLemma': 1},
	{'value': 'punAna', 'label': 'punAna', 'attrib': [{'pos': 'prmp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pupuvAna', 'label': 'pupuvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pupUvas', 'label': 'pupUvas', 'attrib': [{'pos': 'pfap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pupluvAna', 'label': 'pupluvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'purA', 'label': 'purA', 'attrib': [{'pos': 'adv', 'defs': 'previously, long ago'}], 'isLemma': 1},
	{'value': 'puruza', 'label': 'puruza', 'attrib': [{'pos': 'm', 'defs': 'man, person, soul'}], 'isLemma': 1},
	{'value': 'purUravas', 'label': 'purUravas', 'attrib': [{'pos': 'm_pn', 'defs': 'Purūravas'}], 'isLemma': 1},
	{'value': 'puzpa', 'label': 'puzpa', 'attrib': [{'pos': 'n', 'defs': 'flower'}], 'isLemma': 1},
	{'value': 'pU', 'label': 'pU', 'attrib': [{'pos': 'vt9am', 'defs': 'purify'}], 'isLemma': 1},
	{'value': 'pUj', 'label': 'pUj', 'attrib': [{'pos': 'vt10m', 'defs': 'honor, perform a pūjā'}], 'isLemma': 1},
	{'value': 'pUjanIya', 'label': 'pUjanIya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjayamAna', 'label': 'pUjayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjayAm', 'label': 'pUjayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjayitum', 'label': 'pUjayitum', 'attrib': [{'pos': 'inf', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjayitvA', 'label': 'pUjayitvA', 'attrib': [{'pos': 'abs', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjayizyamARa', 'label': 'pUjayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjA', 'label': 'pUjA', 'attrib': [{'pos': 'f', 'defs': 'honor, pūjā'}], 'isLemma': 1},
	{'value': 'pUjita', 'label': 'pUjita', 'attrib': [{'pos': 'ppp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjitavat', 'label': 'pUjitavat', 'attrib': [{'pos': 'pap', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjitavya', 'label': 'pUjitavya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjya', 'label': 'pUjya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUjyamAna', 'label': 'pUjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pUta', 'label': 'pUta', 'attrib': [{'pos': 'ppp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pUtavat', 'label': 'pUtavat', 'attrib': [{'pos': 'pap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pUtvA', 'label': 'pUtvA', 'attrib': [{'pos': 'abs', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pUyamAna', 'label': 'pUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pUraRIya', 'label': 'pUraRIya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUritavya', 'label': 'pUritavya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUritum', 'label': 'pUritum', 'attrib': [{'pos': 'inf', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUrRa', 'label': 'pUrRa', 'attrib': [{'pos': 'adj', 'defs': 'filled, full'}, {'pos': 'ppp', 'defs': 'fill'}], 'isLemma': 1},
	{'value': 'pUrRa', 'label': 'pUrRa', 'attrib': [{'pos': 'adj', 'defs': 'filled, full'}, {'pos': 'ppp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUrta', 'label': 'pUrta', 'attrib': [{'pos': 'ppp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUrtavat', 'label': 'pUrtavat', 'attrib': [{'pos': 'pap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUrtvA', 'label': 'pUrtvA', 'attrib': [{'pos': 'abs', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUrya', 'label': 'pUrya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUryamARa', 'label': 'pUryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pUrva', 'label': 'pUrva', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'previous'}], 'isLemma': 1},
	{'value': 'pUrvam', 'label': 'pUrvam', 'attrib': [{'pos': 'adv', 'defs': 'before, previously'}], 'isLemma': 1},
	{'value': 'pfcCat', 'label': 'pfcCat', 'attrib': [{'pos': 'prap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pfcCya', 'label': 'pfcCya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pfcCyamAna', 'label': 'pfcCyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pfRat', 'label': 'pfRat', 'attrib': [{'pos': 'prap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pfTivI', 'label': 'pfTivI', 'attrib': [{'pos': 'f', 'defs': 'earth, ground'}], 'isLemma': 1},
	{'value': 'pfTu', 'label': 'pfTu', 'attrib': [{'pos': 'adj', 'defs': 'broad'}], 'isLemma': 1},
	{'value': 'pfzwa', 'label': 'pfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pfzwavat', 'label': 'pfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pfzwvA', 'label': 'pfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pF', 'label': 'pF', 'attrib': [{'pos': 'vt9a', 'defs': 'fill'}], 'isLemma': 1},
	{'value': 'pecAna', 'label': 'pecAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pecivas', 'label': 'pecivas', 'attrib': [{'pos': 'pfap', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'peWAna', 'label': 'peWAna', 'attrib': [{'pos': 'pfpp', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'peWivas', 'label': 'peWivas', 'attrib': [{'pos': 'pfap', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'petAna', 'label': 'petAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'petivas', 'label': 'petivas', 'attrib': [{'pos': 'pfap', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'pedAna', 'label': 'pedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'peya', 'label': 'peya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pra', 'label': 'pra', 'attrib': [{'pos': 'preverb', 'defs': 'forth'}], 'isLemma': 1},
	{'value': 'prakraMsyat', 'label': 'prakraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakraMsyamAna', 'label': 'prakraMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakramaRIya', 'label': 'prakramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakramamARa', 'label': 'prakramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakramitavya', 'label': 'prakramitavya', 'attrib': [{'pos': 'ger', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakramitum', 'label': 'prakramitum', 'attrib': [{'pos': 'inf', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakramya', 'label': 'prakramya', 'attrib': [{'pos': 'abs', 'defs': 'set out, proceed, go forth (active); begin (middle)'}, {'pos': 'ger', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakramyamARa', 'label': 'prakramyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}, {'pos': 'prpp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakrAnta', 'label': 'prakrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakrAntavat', 'label': 'prakrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakrAntum', 'label': 'prakrAntum', 'attrib': [{'pos': 'inf', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakrAmat', 'label': 'prakrAmat', 'attrib': [{'pos': 'prap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakrAmyat', 'label': 'prakrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakzyat', 'label': 'prakzyat', 'attrib': [{'pos': 'fap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'prakzyamARa', 'label': 'prakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pragacCat', 'label': 'pragacCat', 'attrib': [{'pos': 'prap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragata', 'label': 'pragata', 'attrib': [{'pos': 'ppp', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragatavat', 'label': 'pragatavat', 'attrib': [{'pos': 'pap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragatya', 'label': 'pragatya', 'attrib': [{'pos': 'abs', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragantavya', 'label': 'pragantavya', 'attrib': [{'pos': 'ger', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragantum', 'label': 'pragantum', 'attrib': [{'pos': 'inf', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragamanIya', 'label': 'pragamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragamizyat', 'label': 'pragamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragamizyamARa', 'label': 'pragamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragamya', 'label': 'pragamya', 'attrib': [{'pos': 'abs', 'defs': 'go forth, set out'}, {'pos': 'ger', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragamyamAna', 'label': 'pragamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pracakramARa', 'label': 'pracakramARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'pracakramvas', 'label': 'pracakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'pracC', 'label': 'pracC', 'attrib': [{'pos': 'vt6a', 'defs': 'ask'}], 'isLemma': 1},
	{'value': 'pracCanIya', 'label': 'pracCanIya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'prajaganvas', 'label': 'prajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'prajagmAna', 'label': 'prajagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'prajagmivas', 'label': 'prajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'prajahfvas', 'label': 'prajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prajahrARa', 'label': 'prajahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'pra_ah', 'label': 'pra_ah', 'attrib': [{'pos': 'vta', 'defs': 'tell, narrate'}], 'isLemma': 1},
	{'value': 'pra_Ap', 'label': 'pra_Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'attain, obtain'}], 'isLemma': 1},
	{'value': 'pra_kram', 'label': 'pra_kram', 'attrib': [{'pos': 'vt1am', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 1},
	{'value': 'pra_gam', 'label': 'pra_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go forth, set out'}], 'isLemma': 1},
	{'value': 'pra_dA', 'label': 'pra_dA', 'attrib': [{'pos': 'vt3am', 'defs': 'present, bestow'}], 'isLemma': 1},
	{'value': 'pra_pat', 'label': 'pra_pat', 'attrib': [{'pos': 'vt1a', 'defs': 'fall forth'}], 'isLemma': 1},
	{'value': 'pra_brU', 'label': 'pra_brU', 'attrib': [{'pos': 'vt2am', 'defs': 'say, speak to, proclaim'}], 'isLemma': 1},
	{'value': 'pra_yam', 'label': 'pra_yam', 'attrib': [{'pos': 'vt1a', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 1},
	{'value': 'pra_viS', 'label': 'pra_viS', 'attrib': [{'pos': 'vt6a', 'defs': 'enter'}], 'isLemma': 1},
	{'value': 'pra_sTA', 'label': 'pra_sTA', 'attrib': [{'pos': 'vt1m', 'defs': 'set out'}], 'isLemma': 1},
	{'value': 'pra_hf', 'label': 'pra_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'throw, attack'}], 'isLemma': 1},
	{'value': 'pratasTAna', 'label': 'pratasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prati', 'label': 'prati', 'attrib': [{'pos': 'preverb', 'defs': 'against'}], 'isLemma': 1},
	{'value': 'pratikraMsyat', 'label': 'pratikraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikraMsyamAna', 'label': 'pratikraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikramaRIya', 'label': 'pratikramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikramitavya', 'label': 'pratikramitavya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikramitum', 'label': 'pratikramitum', 'attrib': [{'pos': 'inf', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikramya', 'label': 'pratikramya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}, {'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikramyamARa', 'label': 'pratikramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikrAnta', 'label': 'pratikrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikrAntavat', 'label': 'pratikrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikrAmat', 'label': 'pratikrAmat', 'attrib': [{'pos': 'prap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikrAmyat', 'label': 'pratikrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratigacCat', 'label': 'pratigacCat', 'attrib': [{'pos': 'prap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigata', 'label': 'pratigata', 'attrib': [{'pos': 'ppp', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigatavat', 'label': 'pratigatavat', 'attrib': [{'pos': 'pap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigatya', 'label': 'pratigatya', 'attrib': [{'pos': 'abs', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigantavya', 'label': 'pratigantavya', 'attrib': [{'pos': 'ger', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigantum', 'label': 'pratigantum', 'attrib': [{'pos': 'inf', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigamanIya', 'label': 'pratigamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigamizyat', 'label': 'pratigamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigamizyamARa', 'label': 'pratigamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigamya', 'label': 'pratigamya', 'attrib': [{'pos': 'abs', 'defs': 'go back, return'}, {'pos': 'ger', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigamyamAna', 'label': 'pratigamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'praticakramARa', 'label': 'praticakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'praticakramvas', 'label': 'praticakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratijaganvas', 'label': 'pratijaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratijagmAna', 'label': 'pratijagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratijagmivas', 'label': 'pratijagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratijajYAna', 'label': 'pratijajYAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijahfvas', 'label': 'pratijahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratijahrARa', 'label': 'pratijahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratijAnAna', 'label': 'pratijAnAna', 'attrib': [{'pos': 'prmp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijYA', 'label': 'pratijYA', 'attrib': [{'pos': 'f', 'defs': 'promise'}], 'isLemma': 1},
	{'value': 'pratijYAta', 'label': 'pratijYAta', 'attrib': [{'pos': 'ppp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijYAtavat', 'label': 'pratijYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijYAtavya', 'label': 'pratijYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijYAtum', 'label': 'pratijYAtum', 'attrib': [{'pos': 'inf', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijYAnIya', 'label': 'pratijYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijYAya', 'label': 'pratijYAya', 'attrib': [{'pos': 'abs', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijYAyamAna', 'label': 'pratijYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijYAsyamAna', 'label': 'pratijYAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijYeya', 'label': 'pratijYeya', 'attrib': [{'pos': 'ger', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'prati_aBi_jYA', 'label': 'prati_aBi_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'recognize'}], 'isLemma': 1},
	{'value': 'prati_ah', 'label': 'prati_ah', 'attrib': [{'pos': 'vta', 'defs': 'answer'}], 'isLemma': 1},
	{'value': 'prati_A_i', 'label': 'prati_A_i', 'attrib': [{'pos': 'vt2a', 'defs': 'come back'}], 'isLemma': 1},
	{'value': 'prati_A_gam', 'label': 'prati_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come back, return'}], 'isLemma': 1},
	{'value': 'prati_A_nI', 'label': 'prati_A_nI', 'attrib': [{'pos': 'vt1am', 'defs': 'bring back'}], 'isLemma': 1},
	{'value': 'prati_A_hf', 'label': 'prati_A_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'bring back'}], 'isLemma': 1},
	{'value': 'prati_i', 'label': 'prati_i', 'attrib': [{'pos': 'vt2a', 'defs': 'go back, recognize'}], 'isLemma': 1},
	{'value': 'prati_kram', 'label': 'prati_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'come back, return'}], 'isLemma': 1},
	{'value': 'prati_gam', 'label': 'prati_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go back, return'}], 'isLemma': 1},
	{'value': 'prati_jYA', 'label': 'prati_jYA', 'attrib': [{'pos': 'vt9m', 'defs': 'promise'}], 'isLemma': 1},
	{'value': 'prati_brU', 'label': 'prati_brU', 'attrib': [{'pos': 'vt2am', 'defs': 'reply'}], 'isLemma': 1},
	{'value': 'prati_BAz', 'label': 'prati_BAz', 'attrib': [{'pos': 'vt1m', 'defs': 'answer'}], 'isLemma': 1},
	{'value': 'prati_laB', 'label': 'prati_laB', 'attrib': [{'pos': 'vt1m', 'defs': 'get back, regain'}], 'isLemma': 1},
	{'value': 'prati_vac', 'label': 'prati_vac', 'attrib': [{'pos': 'vt2a', 'defs': 'answer'}], 'isLemma': 1},
	{'value': 'prati_vad', 'label': 'prati_vad', 'attrib': [{'pos': 'vt1a', 'defs': 'answer'}], 'isLemma': 1},
	{'value': 'prati_sTA', 'label': 'prati_sTA', 'attrib': [{'pos': 'vt1a', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 1},
	{'value': 'prati_hf', 'label': 'prati_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'strike back or against, take back'}], 'isLemma': 1},
	{'value': 'pratitazWAna', 'label': 'pratitazWAna', 'attrib': [{'pos': 'pfpp', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratitazWivas', 'label': 'pratitazWivas', 'attrib': [{'pos': 'pfap', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratitizWat', 'label': 'pratitizWat', 'attrib': [{'pos': 'prap', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratibaBAzARa', 'label': 'pratibaBAzARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratibruvat', 'label': 'pratibruvat', 'attrib': [{'pos': 'prap', 'defs': 'reply'}], 'isLemma': 0, 'parents': ['prati_brU']},
	{'value': 'pratibruvARa', 'label': 'pratibruvARa', 'attrib': [{'pos': 'prmp', 'defs': 'reply'}], 'isLemma': 0, 'parents': ['prati_brU']},
	{'value': 'pratiBAzaRIya', 'label': 'pratiBAzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratiBAzamARa', 'label': 'pratiBAzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratiBAzita', 'label': 'pratiBAzita', 'attrib': [{'pos': 'ppp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratiBAzitavat', 'label': 'pratiBAzitavat', 'attrib': [{'pos': 'pap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratiBAzitavya', 'label': 'pratiBAzitavya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratiBAzitum', 'label': 'pratiBAzitum', 'attrib': [{'pos': 'inf', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratiBAzizyamARa', 'label': 'pratiBAzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratiBAzya', 'label': 'pratiBAzya', 'attrib': [{'pos': 'abs', 'defs': 'answer'}, {'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratiBAzyamARa', 'label': 'pratiBAzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratiyat', 'label': 'pratiyat', 'attrib': [{'pos': 'prap', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratilapsyamAna', 'label': 'pratilapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilabDa', 'label': 'pratilabDa', 'attrib': [{'pos': 'ppp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilabDavat', 'label': 'pratilabDavat', 'attrib': [{'pos': 'pap', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilabDavya', 'label': 'pratilabDavya', 'attrib': [{'pos': 'ger', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilabDum', 'label': 'pratilabDum', 'attrib': [{'pos': 'inf', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilaBanIya', 'label': 'pratilaBanIya', 'attrib': [{'pos': 'ger', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilaBamAna', 'label': 'pratilaBamAna', 'attrib': [{'pos': 'prmp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilaBya', 'label': 'pratilaBya', 'attrib': [{'pos': 'abs', 'defs': 'get back, regain'}, {'pos': 'ger', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilaByamAna', 'label': 'pratilaByamAna', 'attrib': [{'pos': 'prpp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratileBAna', 'label': 'pratileBAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'prativaktavya', 'label': 'prativaktavya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'prativaktum', 'label': 'prativaktum', 'attrib': [{'pos': 'inf', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'prativakzyat', 'label': 'prativakzyat', 'attrib': [{'pos': 'fap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'prativakzyamARa', 'label': 'prativakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'prativacanIya', 'label': 'prativacanIya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'prativadat', 'label': 'prativadat', 'attrib': [{'pos': 'prap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'prativadanIya', 'label': 'prativadanIya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'prativaditavya', 'label': 'prativaditavya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'prativaditum', 'label': 'prativaditum', 'attrib': [{'pos': 'inf', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'prativadizyat', 'label': 'prativadizyat', 'attrib': [{'pos': 'fap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'prativadizyamARa', 'label': 'prativadizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'prativAkya', 'label': 'prativAkya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'prativAdya', 'label': 'prativAdya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratizWamAna', 'label': 'pratizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'pratizWAtavya', 'label': 'pratizWAtavya', 'attrib': [{'pos': 'ger', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratizWAtum', 'label': 'pratizWAtum', 'attrib': [{'pos': 'inf', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratizWAnIya', 'label': 'pratizWAnIya', 'attrib': [{'pos': 'ger', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratizWAya', 'label': 'pratizWAya', 'attrib': [{'pos': 'abs', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratizWAsyat', 'label': 'pratizWAsyat', 'attrib': [{'pos': 'fap', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratizWAsyamAna', 'label': 'pratizWAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratizWita', 'label': 'pratizWita', 'attrib': [{'pos': 'ppp', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratizWitavat', 'label': 'pratizWitavat', 'attrib': [{'pos': 'pap', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratizWIyamAna', 'label': 'pratizWIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratizWeya', 'label': 'pratizWeya', 'attrib': [{'pos': 'ger', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratiharaRIya', 'label': 'pratiharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratiharat', 'label': 'pratiharat', 'attrib': [{'pos': 'prap', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratiharamARa', 'label': 'pratiharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratiharizyat', 'label': 'pratiharizyat', 'attrib': [{'pos': 'fap', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratiharizyamARa', 'label': 'pratiharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihartavya', 'label': 'pratihartavya', 'attrib': [{'pos': 'ger', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihartum', 'label': 'pratihartum', 'attrib': [{'pos': 'inf', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihArya', 'label': 'pratihArya', 'attrib': [{'pos': 'ger', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihfta', 'label': 'pratihfta', 'attrib': [{'pos': 'ppp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihftavat', 'label': 'pratihftavat', 'attrib': [{'pos': 'pap', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihftya', 'label': 'pratihftya', 'attrib': [{'pos': 'abs', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihriyamARa', 'label': 'pratihriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratIta', 'label': 'pratIta', 'attrib': [{'pos': 'ppp', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratItavat', 'label': 'pratItavat', 'attrib': [{'pos': 'pap', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratItavya', 'label': 'pratItavya', 'attrib': [{'pos': 'ger', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratItya', 'label': 'pratItya', 'attrib': [{'pos': 'abs', 'defs': 'go back, recognize'}, {'pos': 'ger', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratIyamAna', 'label': 'pratIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratIyAna', 'label': 'pratIyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratIyivas', 'label': 'pratIyivas', 'attrib': [{'pos': 'pfap', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratta', 'label': 'pratta', 'attrib': [{'pos': 'ppp', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pratyaBijajYAna', 'label': 'pratyaBijajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyaBijajYivas', 'label': 'pratyaBijajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyaBijAnat', 'label': 'pratyaBijAnat', 'attrib': [{'pos': 'prap', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyaBijYA', 'label': 'pratyaBijYA', 'attrib': [{'pos': 'f', 'defs': 'recognition'}], 'isLemma': 1},
	{'value': 'pratyaBijYAta', 'label': 'pratyaBijYAta', 'attrib': [{'pos': 'ppp', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyaBijYAtavat', 'label': 'pratyaBijYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyaBijYAtavya', 'label': 'pratyaBijYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyaBijYAtum', 'label': 'pratyaBijYAtum', 'attrib': [{'pos': 'inf', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyaBijYAnIya', 'label': 'pratyaBijYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyaBijYAya', 'label': 'pratyaBijYAya', 'attrib': [{'pos': 'abs', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyaBijYAyamAna', 'label': 'pratyaBijYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyaBijYAsyat', 'label': 'pratyaBijYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyaBijYAsyamAna', 'label': 'pratyaBijYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyaBijYeya', 'label': 'pratyaBijYeya', 'attrib': [{'pos': 'ger', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyayanIya', 'label': 'pratyayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratyAgacCat', 'label': 'pratyAgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgata', 'label': 'pratyAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgatavat', 'label': 'pratyAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgatya', 'label': 'pratyAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgantavya', 'label': 'pratyAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgantum', 'label': 'pratyAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgamanIya', 'label': 'pratyAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgamizyat', 'label': 'pratyAgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgamizyamARa', 'label': 'pratyAgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgamya', 'label': 'pratyAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}, {'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAgamyamAna', 'label': 'pratyAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAjaganvas', 'label': 'pratyAjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAjagmAna', 'label': 'pratyAjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAjagmivas', 'label': 'pratyAjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyAjahfvas', 'label': 'pratyAjahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAjahrARa', 'label': 'pratyAjahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAnayat', 'label': 'pratyAnayat', 'attrib': [{'pos': 'prap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAnayanIya', 'label': 'pratyAnayanIya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAnayamAna', 'label': 'pratyAnayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAninIvas', 'label': 'pratyAninIvas', 'attrib': [{'pos': 'pfap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAninyAna', 'label': 'pratyAninyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAnIta', 'label': 'pratyAnIta', 'attrib': [{'pos': 'ppp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAnItavat', 'label': 'pratyAnItavat', 'attrib': [{'pos': 'pap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAnIya', 'label': 'pratyAnIya', 'attrib': [{'pos': 'abs', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAnIyamAna', 'label': 'pratyAnIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAnetavya', 'label': 'pratyAnetavya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAnetum', 'label': 'pratyAnetum', 'attrib': [{'pos': 'inf', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAneya', 'label': 'pratyAneya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAnezyat', 'label': 'pratyAnezyat', 'attrib': [{'pos': 'fap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAnezyamARa', 'label': 'pratyAnezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyAyat', 'label': 'pratyAyat', 'attrib': [{'pos': 'prap', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyAyanIya', 'label': 'pratyAyanIya', 'attrib': [{'pos': 'ger', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyAharaRIya', 'label': 'pratyAharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAharat', 'label': 'pratyAharat', 'attrib': [{'pos': 'prap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAharamARa', 'label': 'pratyAharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAharizyat', 'label': 'pratyAharizyat', 'attrib': [{'pos': 'fap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAharizyamARa', 'label': 'pratyAharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAhartavya', 'label': 'pratyAhartavya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAhartum', 'label': 'pratyAhartum', 'attrib': [{'pos': 'inf', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAhArya', 'label': 'pratyAhArya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAhfta', 'label': 'pratyAhfta', 'attrib': [{'pos': 'ppp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAhftavat', 'label': 'pratyAhftavat', 'attrib': [{'pos': 'pap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAhftya', 'label': 'pratyAhftya', 'attrib': [{'pos': 'abs', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyAhriyamARa', 'label': 'pratyAhriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyukta', 'label': 'pratyukta', 'attrib': [{'pos': 'ppp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'pratyuktavat', 'label': 'pratyuktavat', 'attrib': [{'pos': 'pap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'pratyucya', 'label': 'pratyucya', 'attrib': [{'pos': 'abs', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'pratyucyamAna', 'label': 'pratyucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'pratyudita', 'label': 'pratyudita', 'attrib': [{'pos': 'ppp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratyuditavat', 'label': 'pratyuditavat', 'attrib': [{'pos': 'pap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratyudya', 'label': 'pratyudya', 'attrib': [{'pos': 'abs', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratyudyamAna', 'label': 'pratyudyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratyUcAna', 'label': 'pratyUcAna', 'attrib': [{'pos': 'pfpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'pratyUcivas', 'label': 'pratyUcivas', 'attrib': [{'pos': 'pfap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'pratyUdAna', 'label': 'pratyUdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratyUdivas', 'label': 'pratyUdivas', 'attrib': [{'pos': 'pfap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratyeta', 'label': 'pratyeta', 'attrib': [{'pos': 'ppp', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyetavat', 'label': 'pratyetavat', 'attrib': [{'pos': 'pap', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyetum', 'label': 'pratyetum', 'attrib': [{'pos': 'inf', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratyetya', 'label': 'pratyetya', 'attrib': [{'pos': 'abs', 'defs': 'come back'}, {'pos': 'ger', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyeyamAna', 'label': 'pratyeyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyeyAna', 'label': 'pratyeyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyeyivas', 'label': 'pratyeyivas', 'attrib': [{'pos': 'pfap', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyezyat', 'label': 'pratyezyat', 'attrib': [{'pos': 'fap', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratyezyamARa', 'label': 'pratyezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratyEtavya', 'label': 'pratyEtavya', 'attrib': [{'pos': 'ger', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyEtum', 'label': 'pratyEtum', 'attrib': [{'pos': 'inf', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyEzyat', 'label': 'pratyEzyat', 'attrib': [{'pos': 'fap', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyEzyamARa', 'label': 'pratyEzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'praTama', 'label': 'praTama', 'attrib': [{'pos': 'ord_num', 'defs': 'first'}], 'isLemma': 1},
	{'value': 'pradattavat', 'label': 'pradattavat', 'attrib': [{'pos': 'pap', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradadat', 'label': 'pradadat', 'attrib': [{'pos': 'prap', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradadAna', 'label': 'pradadAna', 'attrib': [{'pos': 'prmp', 'defs': 'present, bestow'}, {'pos': 'pfmp, pfpp', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradadivas', 'label': 'pradadivas', 'attrib': [{'pos': 'pfap', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradAtavya', 'label': 'pradAtavya', 'attrib': [{'pos': 'ger', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradAtum', 'label': 'pradAtum', 'attrib': [{'pos': 'inf', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradAnIya', 'label': 'pradAnIya', 'attrib': [{'pos': 'ger', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradAya', 'label': 'pradAya', 'attrib': [{'pos': 'abs', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradAsyat', 'label': 'pradAsyat', 'attrib': [{'pos': 'fap', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradAsyamAna', 'label': 'pradAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradIyamAna', 'label': 'pradIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradeya', 'label': 'pradeya', 'attrib': [{'pos': 'ger', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'praDAna', 'label': 'praDAna', 'attrib': [{'pos': 'adj', 'defs': 'principal'}], 'isLemma': 1},
	{'value': 'prapatat', 'label': 'prapatat', 'attrib': [{'pos': 'prap', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatanIya', 'label': 'prapatanIya', 'attrib': [{'pos': 'ger', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatita', 'label': 'prapatita', 'attrib': [{'pos': 'ppp', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatitavat', 'label': 'prapatitavat', 'attrib': [{'pos': 'pap', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatitavya', 'label': 'prapatitavya', 'attrib': [{'pos': 'ger', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatitum', 'label': 'prapatitum', 'attrib': [{'pos': 'inf', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatizyat', 'label': 'prapatizyat', 'attrib': [{'pos': 'fap', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatizyamARa', 'label': 'prapatizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatya', 'label': 'prapatya', 'attrib': [{'pos': 'abs', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatyamAna', 'label': 'prapatyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapAtya', 'label': 'prapAtya', 'attrib': [{'pos': 'ger', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapetAna', 'label': 'prapetAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapetivas', 'label': 'prapetivas', 'attrib': [{'pos': 'pfap', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prabruvat', 'label': 'prabruvat', 'attrib': [{'pos': 'prap', 'defs': 'say, speak to, proclaim'}], 'isLemma': 0, 'parents': ['pra_brU']},
	{'value': 'prabruvARa', 'label': 'prabruvARa', 'attrib': [{'pos': 'prmp', 'defs': 'say, speak to, proclaim'}], 'isLemma': 0, 'parents': ['pra_brU']},
	{'value': 'pramAda', 'label': 'pramAda', 'attrib': [{'pos': 'm', 'defs': 'carelessness, error'}], 'isLemma': 1},
	{'value': 'prayaMsyat', 'label': 'prayaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayaMsyamAna', 'label': 'prayaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayacCat', 'label': 'prayacCat', 'attrib': [{'pos': 'prap', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayata', 'label': 'prayata', 'attrib': [{'pos': 'ppp', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayatavat', 'label': 'prayatavat', 'attrib': [{'pos': 'pap', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayatya', 'label': 'prayatya', 'attrib': [{'pos': 'abs', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayantavya', 'label': 'prayantavya', 'attrib': [{'pos': 'ger', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayantum', 'label': 'prayantum', 'attrib': [{'pos': 'inf', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayamaRIya', 'label': 'prayamaRIya', 'attrib': [{'pos': 'ger', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayamya', 'label': 'prayamya', 'attrib': [{'pos': 'abs', 'defs': 'hold forth, give, offer (acc. to dat.)'}, {'pos': 'ger', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayamyamAna', 'label': 'prayamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayemAna', 'label': 'prayemAna', 'attrib': [{'pos': 'pfpp', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayemivas', 'label': 'prayemivas', 'attrib': [{'pos': 'pfap', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'praviviSAna', 'label': 'praviviSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praviviSivas', 'label': 'praviviSivas', 'attrib': [{'pos': 'pfap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praviviSvas', 'label': 'praviviSvas', 'attrib': [{'pos': 'pfap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praviSat', 'label': 'praviSat', 'attrib': [{'pos': 'prap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praviSya', 'label': 'praviSya', 'attrib': [{'pos': 'abs', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praviSyamAna', 'label': 'praviSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'pravizwa', 'label': 'pravizwa', 'attrib': [{'pos': 'ppp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'pravizwavat', 'label': 'pravizwavat', 'attrib': [{'pos': 'pap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'pravekzyat', 'label': 'pravekzyat', 'attrib': [{'pos': 'fap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'pravekzyamARa', 'label': 'pravekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praveSanIya', 'label': 'praveSanIya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praveSya', 'label': 'praveSya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'pravezwavya', 'label': 'pravezwavya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'pravezwum', 'label': 'pravezwum', 'attrib': [{'pos': 'inf', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'prazwavya', 'label': 'prazwavya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'prazwum', 'label': 'prazwum', 'attrib': [{'pos': 'inf', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'prasTa', 'label': 'prasTa', 'attrib': [{'pos': 'adj', 'defs': 'setting out or having set out to'}], 'isLemma': 1},
	{'value': 'prasTAtavya', 'label': 'prasTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTAtum', 'label': 'prasTAtum', 'attrib': [{'pos': 'inf', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTAnIya', 'label': 'prasTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTAya', 'label': 'prasTAya', 'attrib': [{'pos': 'abs', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTAsyamAna', 'label': 'prasTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTita', 'label': 'prasTita', 'attrib': [{'pos': 'ppp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTitavat', 'label': 'prasTitavat', 'attrib': [{'pos': 'pap', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTIyamAna', 'label': 'prasTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasTeya', 'label': 'prasTeya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'praharaRIya', 'label': 'praharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'praharat', 'label': 'praharat', 'attrib': [{'pos': 'prap', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'praharamARa', 'label': 'praharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'praharizyat', 'label': 'praharizyat', 'attrib': [{'pos': 'fap', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'praharizyamARa', 'label': 'praharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahartavya', 'label': 'prahartavya', 'attrib': [{'pos': 'ger', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahartum', 'label': 'prahartum', 'attrib': [{'pos': 'inf', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahArya', 'label': 'prahArya', 'attrib': [{'pos': 'ger', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahfta', 'label': 'prahfta', 'attrib': [{'pos': 'ppp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahftavat', 'label': 'prahftavat', 'attrib': [{'pos': 'pap', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahftya', 'label': 'prahftya', 'attrib': [{'pos': 'abs', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahriyamARa', 'label': 'prahriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prAtar', 'label': 'prAtar', 'attrib': [{'pos': 'adv', 'defs': 'at dawn, in the early morning'}], 'isLemma': 1},
	{'value': 'prApaRIya', 'label': 'prApaRIya', 'attrib': [{'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApARa', 'label': 'prApARa', 'attrib': [{'pos': 'pfpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApivas', 'label': 'prApivas', 'attrib': [{'pos': 'pfap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApta', 'label': 'prApta', 'attrib': [{'pos': 'ppp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prAptavat', 'label': 'prAptavat', 'attrib': [{'pos': 'pap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prAptavya', 'label': 'prAptavya', 'attrib': [{'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prAptum', 'label': 'prAptum', 'attrib': [{'pos': 'inf', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApnuvat', 'label': 'prApnuvat', 'attrib': [{'pos': 'prap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApya', 'label': 'prApya', 'attrib': [{'pos': 'abs', 'defs': 'attain, obtain'}, {'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApyamAna', 'label': 'prApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApsyat', 'label': 'prApsyat', 'attrib': [{'pos': 'fap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prApsyamAna', 'label': 'prApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'priya', 'label': 'priya', 'attrib': [{'pos': 'adj', 'defs': 'dear'}], 'isLemma': 1},
	{'value': 'plavanIya', 'label': 'plavanIya', 'attrib': [{'pos': 'ger', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plavamAna', 'label': 'plavamAna', 'attrib': [{'pos': 'prmp', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plavitavya', 'label': 'plavitavya', 'attrib': [{'pos': 'ger', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plavitum', 'label': 'plavitum', 'attrib': [{'pos': 'inf', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plavya', 'label': 'plavya', 'attrib': [{'pos': 'ger', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plu', 'label': 'plu', 'attrib': [{'pos': 'vt1m', 'defs': 'float, swim'}], 'isLemma': 1},
	{'value': 'pluta', 'label': 'pluta', 'attrib': [{'pos': 'ppp', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plutavat', 'label': 'plutavat', 'attrib': [{'pos': 'pap', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plutvA', 'label': 'plutvA', 'attrib': [{'pos': 'abs', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plozyamARa', 'label': 'plozyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'Pala', 'label': 'Pala', 'attrib': [{'pos': 'n', 'defs': 'fruit'}], 'isLemma': 1},
	{'value': 'Palavat', 'label': 'Palavat', 'attrib': [{'pos': 'adj', 'defs': 'fruitful, successful'}], 'isLemma': 1},
	{'value': 'badDa', 'label': 'badDa', 'attrib': [{'pos': 'ppp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'badDavat', 'label': 'badDavat', 'attrib': [{'pos': 'pap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'badDvA', 'label': 'badDvA', 'attrib': [{'pos': 'abs', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'baDnat', 'label': 'baDnat', 'attrib': [{'pos': 'prap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'baDyamAna', 'label': 'baDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'bandDavya', 'label': 'bandDavya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'bandDum', 'label': 'bandDum', 'attrib': [{'pos': 'inf', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'banD', 'label': 'banD', 'attrib': [{'pos': 'vt9am', 'defs': 'bind'}], 'isLemma': 1},
	{'value': 'banDanIya', 'label': 'banDanIya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'banDya', 'label': 'banDya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'babanDAna', 'label': 'babanDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'babanDvas', 'label': 'babanDvas', 'attrib': [{'pos': 'pfap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'baBAzARa', 'label': 'baBAzARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'baBUvas', 'label': 'baBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'baBfvas', 'label': 'baBfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'baBrARa', 'label': 'baBrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bala', 'label': 'bala', 'attrib': [{'pos': 'n', 'defs': 'strength'}], 'isLemma': 1},
	{'value': 'balavat', 'label': 'balavat', 'attrib': [{'pos': 'adj', 'defs': 'strong'}], 'isLemma': 1},
	{'value': 'balin', 'label': 'balin', 'attrib': [{'pos': 'adj', 'defs': 'strong'}], 'isLemma': 1},
	{'value': 'bahu', 'label': 'bahu', 'attrib': [{'pos': 'adj', 'defs': 'much, many, abundant'}, {'pos': 'adv', 'defs': 'much'}], 'isLemma': 1},
	{'value': 'bAla', 'label': 'bAla', 'attrib': [{'pos': 'm', 'defs': 'boy, child'}], 'isLemma': 1},
	{'value': 'biBayAm', 'label': 'biBayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'biBarAm', 'label': 'biBarAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'biBidAna', 'label': 'biBidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'biBidvas', 'label': 'biBidvas', 'attrib': [{'pos': 'pfap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'biBIvas', 'label': 'biBIvas', 'attrib': [{'pos': 'pfap', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'biByat', 'label': 'biByat', 'attrib': [{'pos': 'prap', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'biByAna', 'label': 'biByAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'biBrat', 'label': 'biBrat', 'attrib': [{'pos': 'prap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'biBrARa', 'label': 'biBrARa', 'attrib': [{'pos': 'prmp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'budDa', 'label': 'budDa', 'attrib': [{'pos': 'ppp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'budDavat', 'label': 'budDavat', 'attrib': [{'pos': 'pap', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'budDi', 'label': 'budDi', 'attrib': [{'pos': 'f', 'defs': 'intelligence, intellect'}], 'isLemma': 1},
	{'value': 'budDvA', 'label': 'budDvA', 'attrib': [{'pos': 'abs', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buD', 'label': 'buD', 'attrib': [{'pos': 'vt1am', 'defs': 'know'}, {'pos': 'vt4m', 'defs': 'be aware, know'}], 'isLemma': 1},
	{'value': 'buDita', 'label': 'buDita', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buDitavat', 'label': 'buDitavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buDitvA', 'label': 'buDitvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buDyamAna', 'label': 'buDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}, {'pos': 'prmp', 'defs': 'be aware, know'}, {'pos': 'prpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bubuDAna', 'label': 'bubuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'know'}, {'pos': 'pfmp, pfpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bubuDvas', 'label': 'bubuDvas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buBujAna', 'label': 'buBujAna', 'attrib': [{'pos': 'pfpp', 'defs': 'protect'}, {'pos': 'pfmp, pfpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'buBujvas', 'label': 'buBujvas', 'attrib': [{'pos': 'pfap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bodDavya', 'label': 'bodDavya', 'attrib': [{'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodDum', 'label': 'bodDum', 'attrib': [{'pos': 'inf', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDat', 'label': 'boDat', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDanIya', 'label': 'boDanIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDamAna', 'label': 'boDamAna', 'attrib': [{'pos': 'prmp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDitavya', 'label': 'boDitavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDitum', 'label': 'boDitum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDitvA', 'label': 'boDitvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boDya', 'label': 'boDya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'brAhmaRa', 'label': 'brAhmaRa', 'attrib': [{'pos': 'm', 'defs': 'brahmin'}], 'isLemma': 1},
	{'value': 'bruvat', 'label': 'bruvat', 'attrib': [{'pos': 'prap', 'defs': 'say, tell'}], 'isLemma': 0, 'parents': ['brU']},
	{'value': 'bruvARa', 'label': 'bruvARa', 'attrib': [{'pos': 'prmp', 'defs': 'say, tell'}], 'isLemma': 0, 'parents': ['brU']},
	{'value': 'brU', 'label': 'brU', 'attrib': [{'pos': 'vt2am', 'defs': 'say, tell'}], 'isLemma': 1},
	{'value': 'Bakz', 'label': 'Bakz', 'attrib': [{'pos': 'vt10a', 'defs': 'eat'}], 'isLemma': 1},
	{'value': 'BakzaRIya', 'label': 'BakzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'Bakzayat', 'label': 'Bakzayat', 'attrib': [{'pos': 'prap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'BakzayAm', 'label': 'BakzayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'Bakzayitum', 'label': 'Bakzayitum', 'attrib': [{'pos': 'inf', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'BakzayitvA', 'label': 'BakzayitvA', 'attrib': [{'pos': 'abs', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'Bakzayizyat', 'label': 'Bakzayizyat', 'attrib': [{'pos': 'fap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'BakzayizyamARa', 'label': 'BakzayizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'Bakzita', 'label': 'Bakzita', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'Bakzitavat', 'label': 'Bakzitavat', 'attrib': [{'pos': 'pap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'Bakzitavya', 'label': 'Bakzitavya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'Bakzya', 'label': 'Bakzya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'BakzyamARa', 'label': 'BakzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'Bantsyat', 'label': 'Bantsyat', 'attrib': [{'pos': 'fap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'BantsyamAna', 'label': 'BantsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'Baya', 'label': 'Baya', 'attrib': [{'pos': 'n', 'defs': 'fear'}], 'isLemma': 1},
	{'value': 'BayanIya', 'label': 'BayanIya', 'attrib': [{'pos': 'ger', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'BaraRIya', 'label': 'BaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Barata', 'label': 'Barata', 'attrib': [{'pos': 'm_pn', 'defs': 'Bharata or his descendants'}], 'isLemma': 1},
	{'value': 'Barizyat', 'label': 'Barizyat', 'attrib': [{'pos': 'fap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'BarizyamARa', 'label': 'BarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Bartavya', 'label': 'Bartavya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Bartum', 'label': 'Bartum', 'attrib': [{'pos': 'inf', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Bavat', 'label': 'Bavat', 'attrib': [{'pos': 'pers_pron', 'defs': 'your lord/ladyship, sir/madam, you'}, {'pos': 'prap', 'defs': 'be, become'}], 'isLemma': 1},
	{'value': 'Bavat', 'label': 'Bavat', 'attrib': [{'pos': 'pers_pron', 'defs': 'your lord/ladyship, sir/madam, you'}, {'pos': 'prap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BavanIya', 'label': 'BavanIya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'Bavitavya', 'label': 'Bavitavya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'Bavitum', 'label': 'Bavitum', 'attrib': [{'pos': 'inf', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'Bavizyat', 'label': 'Bavizyat', 'attrib': [{'pos': 'fap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BavizyamARa', 'label': 'BavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'Bavya', 'label': 'Bavya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BAz', 'label': 'BAz', 'attrib': [{'pos': 'vt1m', 'defs': 'speak, say'}], 'isLemma': 1},
	{'value': 'BAzaRIya', 'label': 'BAzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzamARa', 'label': 'BAzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzita', 'label': 'BAzita', 'attrib': [{'pos': 'ppp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzitavat', 'label': 'BAzitavat', 'attrib': [{'pos': 'pap', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzitavya', 'label': 'BAzitavya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzitum', 'label': 'BAzitum', 'attrib': [{'pos': 'inf', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzitvA', 'label': 'BAzitvA', 'attrib': [{'pos': 'abs', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzizyamARa', 'label': 'BAzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzya', 'label': 'BAzya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BAzyamARa', 'label': 'BAzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'BittvA', 'label': 'BittvA', 'attrib': [{'pos': 'abs', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Bid', 'label': 'Bid', 'attrib': [{'pos': 'vt7am', 'defs': 'split'}], 'isLemma': 1},
	{'value': 'BidyamAna', 'label': 'BidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Bindat', 'label': 'Bindat', 'attrib': [{'pos': 'prap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'BindAna', 'label': 'BindAna', 'attrib': [{'pos': 'prmp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Binna', 'label': 'Binna', 'attrib': [{'pos': 'ppp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Binnavat', 'label': 'Binnavat', 'attrib': [{'pos': 'pap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'BI', 'label': 'BI', 'attrib': [{'pos': 'vi3a', 'defs': 'fear (from abl.)'}], 'isLemma': 1},
	{'value': 'BIta', 'label': 'BIta', 'attrib': [{'pos': 'ppp', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'BItavat', 'label': 'BItavat', 'attrib': [{'pos': 'pap', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'BItvA', 'label': 'BItvA', 'attrib': [{'pos': 'abs', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'BIyamAna', 'label': 'BIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'BIru', 'label': 'BIru', 'attrib': [{'pos': 'adj', 'defs': 'timid'}], 'isLemma': 1},
	{'value': 'Bukta', 'label': 'Bukta', 'attrib': [{'pos': 'ppp', 'defs': 'protect'}, {'pos': 'ppp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'Buktavat', 'label': 'Buktavat', 'attrib': [{'pos': 'pap', 'defs': 'protect'}, {'pos': 'pap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'BuktvA', 'label': 'BuktvA', 'attrib': [{'pos': 'abs', 'defs': 'protect'}, {'pos': 'abs', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'Buj', 'label': 'Buj', 'attrib': [{'pos': 'vt7a', 'defs': 'protect'}, {'pos': 'vt7m', 'defs': 'eat'}], 'isLemma': 1},
	{'value': 'BujyamAna', 'label': 'BujyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'protect'}, {'pos': 'prpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'BuYjat', 'label': 'BuYjat', 'attrib': [{'pos': 'prap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'BuYjAna', 'label': 'BuYjAna', 'attrib': [{'pos': 'prmp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'BU', 'label': 'BU', 'attrib': [{'pos': 'f', 'defs': 'earth'}, {'pos': 'vi1a', 'defs': 'be, become'}], 'isLemma': 1},
	{'value': 'BUta', 'label': 'BUta', 'attrib': [{'pos': 'ppp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BUtavat', 'label': 'BUtavat', 'attrib': [{'pos': 'pap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BUtvA', 'label': 'BUtvA', 'attrib': [{'pos': 'abs', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'BUyamAna', 'label': 'BUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'Bf', 'label': 'Bf', 'attrib': [{'pos': 'vt3am', 'defs': 'bear'}], 'isLemma': 1},
	{'value': 'Bfta', 'label': 'Bfta', 'attrib': [{'pos': 'ppp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Bftavat', 'label': 'Bftavat', 'attrib': [{'pos': 'pap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Bftya', 'label': 'Bftya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'BftvA', 'label': 'BftvA', 'attrib': [{'pos': 'abs', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'Betavya', 'label': 'Betavya', 'attrib': [{'pos': 'ger', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'Betum', 'label': 'Betum', 'attrib': [{'pos': 'inf', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'Bettavya', 'label': 'Bettavya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Bettum', 'label': 'Bettum', 'attrib': [{'pos': 'inf', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Betsyat', 'label': 'Betsyat', 'attrib': [{'pos': 'fap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'BetsyamAna', 'label': 'BetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'BedanIya', 'label': 'BedanIya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Bedya', 'label': 'Bedya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'Beya', 'label': 'Beya', 'attrib': [{'pos': 'ger', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'Bezyat', 'label': 'Bezyat', 'attrib': [{'pos': 'fap', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'BezyamARa', 'label': 'BezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'Boktavya', 'label': 'Boktavya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}, {'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'Boktum', 'label': 'Boktum', 'attrib': [{'pos': 'inf', 'defs': 'protect'}, {'pos': 'inf', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'Bokzyat', 'label': 'Bokzyat', 'attrib': [{'pos': 'fap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'BokzyamARa', 'label': 'BokzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'protect'}, {'pos': 'fmp, fpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'Bogya', 'label': 'Bogya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}, {'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'Bojana', 'label': 'Bojana', 'attrib': [{'pos': 'n', 'defs': 'eating, a meal'}], 'isLemma': 1},
	{'value': 'BojanIya', 'label': 'BojanIya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}, {'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'Botsyat', 'label': 'Botsyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'BotsyamAna', 'label': 'BotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'know'}, {'pos': 'fmp, fpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'BrAtf', 'label': 'BrAtf', 'attrib': [{'pos': 'm', 'defs': 'brother'}], 'isLemma': 1},
	{'value': 'BriyamARa', 'label': 'BriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'BrU', 'label': 'BrU', 'attrib': [{'pos': 'f', 'defs': 'eyebrow'}], 'isLemma': 1},
	{'value': 'maMsyamAna', 'label': 'maMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mata', 'label': 'mata', 'attrib': [{'pos': 'ppp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'matavat', 'label': 'matavat', 'attrib': [{'pos': 'pap', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mati', 'label': 'mati', 'attrib': [{'pos': 'f', 'defs': 'thought'}], 'isLemma': 1},
	{'value': 'matta', 'label': 'matta', 'attrib': [{'pos': 'ppp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'mattavat', 'label': 'mattavat', 'attrib': [{'pos': 'pap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'matvA', 'label': 'matvA', 'attrib': [{'pos': 'abs', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mad', 'label': 'mad', 'attrib': [{'pos': 'vi4a', 'defs': 'rejoice'}], 'isLemma': 1},
	{'value': 'madanIya', 'label': 'madanIya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maditavya', 'label': 'maditavya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maditum', 'label': 'maditum', 'attrib': [{'pos': 'inf', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maditvA', 'label': 'maditvA', 'attrib': [{'pos': 'abs', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madizyat', 'label': 'madizyat', 'attrib': [{'pos': 'fap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madizyamARa', 'label': 'madizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madya', 'label': 'madya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madyamAna', 'label': 'madyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maDu', 'label': 'maDu', 'attrib': [{'pos': 'adj', 'defs': 'sweet'}, {'pos': 'n', 'defs': 'honey'}], 'isLemma': 1},
	{'value': 'maDya', 'label': 'maDya', 'attrib': [{'pos': 'adj', 'defs': 'middle'}, {'pos': 'n', 'defs': 'middle, waist'}], 'isLemma': 1},
	{'value': 'man', 'label': 'man', 'attrib': [{'pos': 'vt4m, vt8m', 'defs': 'think'}], 'isLemma': 1},
	{'value': 'mananIya', 'label': 'mananIya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'manas', 'label': 'manas', 'attrib': [{'pos': 'n', 'defs': 'mind'}], 'isLemma': 1},
	{'value': 'manuzya', 'label': 'manuzya', 'attrib': [{'pos': 'm', 'defs': 'human being'}], 'isLemma': 1},
	{'value': 'mantavya', 'label': 'mantavya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mantum', 'label': 'mantum', 'attrib': [{'pos': 'inf', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'manyamAna', 'label': 'manyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'think'}, {'pos': 'prpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'manvAna', 'label': 'manvAna', 'attrib': [{'pos': 'prmp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mamfvas', 'label': 'mamfvas', 'attrib': [{'pos': 'pfap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mamrARa', 'label': 'mamrARa', 'attrib': [{'pos': 'pfpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'maraRIya', 'label': 'maraRIya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'marizyat', 'label': 'marizyat', 'attrib': [{'pos': 'fap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'marizyamARa', 'label': 'marizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'martavya', 'label': 'martavya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'martum', 'label': 'martum', 'attrib': [{'pos': 'inf', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mahat', 'label': 'mahat', 'attrib': [{'pos': 'adj', 'defs': 'great'}], 'isLemma': 1},
	{'value': 'mahABArata', 'label': 'mahABArata', 'attrib': [{'pos': 'n_pn', 'defs': 'the <i>Mahābhārata</i>'}], 'isLemma': 1},
	{'value': 'mahAyaSas', 'label': 'mahAyaSas', 'attrib': [{'pos': 'adj', 'defs': 'whose glory is great, very glorious'}], 'isLemma': 1},
	{'value': 'mA', 'label': 'mA', 'attrib': [{'pos': 'neg_pcl', 'defs': "don't"}], 'isLemma': 1},
	{'value': 'mAtf', 'label': 'mAtf', 'attrib': [{'pos': 'f', 'defs': 'mother'}], 'isLemma': 1},
	{'value': 'mAdyat', 'label': 'mAdyat', 'attrib': [{'pos': 'prap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'mAnya', 'label': 'mAnya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mArya', 'label': 'mArya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mAlA', 'label': 'mAlA', 'attrib': [{'pos': 'f', 'defs': 'garland'}], 'isLemma': 1},
	{'value': 'mAsa', 'label': 'mAsa', 'attrib': [{'pos': 'm', 'defs': 'moon, month'}], 'isLemma': 1},
	{'value': 'mukta', 'label': 'mukta', 'attrib': [{'pos': 'ppp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muktavat', 'label': 'muktavat', 'attrib': [{'pos': 'pap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muktvA', 'label': 'muktvA', 'attrib': [{'pos': 'abs', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muKa', 'label': 'muKa', 'attrib': [{'pos': 'n', 'defs': 'mouth, face'}], 'isLemma': 1},
	{'value': 'muc', 'label': 'muc', 'attrib': [{'pos': 'vt6am', 'defs': 'release'}], 'isLemma': 1},
	{'value': 'mucyamAna', 'label': 'mucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muYcat', 'label': 'muYcat', 'attrib': [{'pos': 'prap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muYcamAna', 'label': 'muYcamAna', 'attrib': [{'pos': 'prmp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mumucAna', 'label': 'mumucAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mumucvas', 'label': 'mumucvas', 'attrib': [{'pos': 'pfap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muhUrta', 'label': 'muhUrta', 'attrib': [{'pos': 'm, n', 'defs': '1/30th of a day, 48 minutes, hour'}], 'isLemma': 1},
	{'value': 'mf', 'label': 'mf', 'attrib': [{'pos': 'vi6am', 'defs': 'die'}], 'isLemma': 1},
	{'value': 'mfga', 'label': 'mfga', 'attrib': [{'pos': 'm', 'defs': 'deer'}], 'isLemma': 1},
	{'value': 'mfta', 'label': 'mfta', 'attrib': [{'pos': 'ppp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mftavat', 'label': 'mftavat', 'attrib': [{'pos': 'pap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mftvA', 'label': 'mftvA', 'attrib': [{'pos': 'abs', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mfdu', 'label': 'mfdu', 'attrib': [{'pos': 'adj', 'defs': 'soft'}], 'isLemma': 1},
	{'value': 'medAna', 'label': 'medAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'medivas', 'label': 'medivas', 'attrib': [{'pos': 'pfap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'menAna', 'label': 'menAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'moktavya', 'label': 'moktavya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'moktum', 'label': 'moktum', 'attrib': [{'pos': 'inf', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mokzyat', 'label': 'mokzyat', 'attrib': [{'pos': 'fap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mokzyamARa', 'label': 'mokzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mocanIya', 'label': 'mocanIya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mocya', 'label': 'mocya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mriyamARa', 'label': 'mriyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'die'}, {'pos': 'prpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'yaMsyat', 'label': 'yaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yaMsyamAna', 'label': 'yaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yakzyat', 'label': 'yakzyat', 'attrib': [{'pos': 'fap', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yakzyamARa', 'label': 'yakzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yacCat', 'label': 'yacCat', 'attrib': [{'pos': 'prap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yaj', 'label': 'yaj', 'attrib': [{'pos': 'vt1am', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 1},
	{'value': 'yajat', 'label': 'yajat', 'attrib': [{'pos': 'prap', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yajanIya', 'label': 'yajanIya', 'attrib': [{'pos': 'ger', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yajamAna', 'label': 'yajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'worship, perform a Vedic ceremony'}, {'pos': 'm', 'defs': 'yajamāna , worshipper, performer and sponsor of a Vedic ceremony'}], 'isLemma': 1},
	{'value': 'yajamAna', 'label': 'yajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'worship, perform a Vedic ceremony'}, {'pos': 'm', 'defs': 'yajamāna , worshipper, performer and sponsor of a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yajurveda', 'label': 'yajurveda', 'attrib': [{'pos': 'm', 'defs': '<i>Yajurveda</i>'}], 'isLemma': 1},
	{'value': 'yajus', 'label': 'yajus', 'attrib': [{'pos': 'n', 'defs': 'mantra of the <i>Yajurveda</i>'}], 'isLemma': 1},
	{'value': 'yajYa', 'label': 'yajYa', 'attrib': [{'pos': 'm', 'defs': 'worship, ceremony'}], 'isLemma': 1},
	{'value': 'yat', 'label': 'yat', 'attrib': [{'pos': 'prap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'yata', 'label': 'yata', 'attrib': [{'pos': 'ppp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yatavat', 'label': 'yatavat', 'attrib': [{'pos': 'pap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yatas', 'label': 'yatas', 'attrib': [{'pos': 'rel_adv', 'defs': 'whence, because'}], 'isLemma': 1},
	{'value': 'yatra', 'label': 'yatra', 'attrib': [{'pos': 'rel_adv', 'defs': 'where'}], 'isLemma': 1},
	{'value': 'yatvA', 'label': 'yatvA', 'attrib': [{'pos': 'abs', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yaTA', 'label': 'yaTA', 'attrib': [{'pos': 'rel_adv', 'defs': 'in which manner, as'}], 'isLemma': 1},
	{'value': 'yad', 'label': 'yad', 'attrib': [{'pos': 'rel_pron, rel_adj', 'defs': 'which/who/what'}], 'isLemma': 1},
	{'value': 'yadA', 'label': 'yadA', 'attrib': [{'pos': 'rel_adv', 'defs': 'at which time, when'}], 'isLemma': 1},
	{'value': 'yadi', 'label': 'yadi', 'attrib': [{'pos': 'rel_adv', 'defs': 'if'}], 'isLemma': 1},
	{'value': 'yantavya', 'label': 'yantavya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yantum', 'label': 'yantum', 'attrib': [{'pos': 'inf', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yam', 'label': 'yam', 'attrib': [{'pos': 'vt1a', 'defs': 'hold'}], 'isLemma': 1},
	{'value': 'yamanIya', 'label': 'yamanIya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yamya', 'label': 'yamya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yamyamAna', 'label': 'yamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yaSas', 'label': 'yaSas', 'attrib': [{'pos': 'n', 'defs': 'glory'}], 'isLemma': 1},
	{'value': 'yazwavya', 'label': 'yazwavya', 'attrib': [{'pos': 'ger', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yazwum', 'label': 'yazwum', 'attrib': [{'pos': 'inf', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yAjya', 'label': 'yAjya', 'attrib': [{'pos': 'ger', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yAvat', 'label': 'yAvat', 'attrib': [{'pos': 'rel_adj', 'defs': 'of which extent'}, {'pos': 'rel_adv', 'defs': 'to which extent'}], 'isLemma': 1},
	{'value': 'yukta', 'label': 'yukta', 'attrib': [{'pos': 'ppp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuktavat', 'label': 'yuktavat', 'attrib': [{'pos': 'pap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuktvA', 'label': 'yuktvA', 'attrib': [{'pos': 'abs', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuj', 'label': 'yuj', 'attrib': [{'pos': 'vt7am', 'defs': 'yoke, join, unite'}], 'isLemma': 1},
	{'value': 'yujyamAna', 'label': 'yujyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuYjat', 'label': 'yuYjat', 'attrib': [{'pos': 'prap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuYjAna', 'label': 'yuYjAna', 'attrib': [{'pos': 'prmp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yudDa', 'label': 'yudDa', 'attrib': [{'pos': 'ppp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yudDavat', 'label': 'yudDavat', 'attrib': [{'pos': 'pap', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yudDvA', 'label': 'yudDvA', 'attrib': [{'pos': 'abs', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuD', 'label': 'yuD', 'attrib': [{'pos': 'vt4m', 'defs': 'fight'}], 'isLemma': 1},
	{'value': 'yuDizWira', 'label': 'yuDizWira', 'attrib': [{'pos': 'm_pn', 'defs': 'Yudhiṣṭhira'}], 'isLemma': 1},
	{'value': 'yuDizWirArjuna', 'label': 'yuDizWirArjuna', 'attrib': [{'pos': 'm', 'defs': 'Yudhiṣṭhira and Arjuna'}], 'isLemma': 1},
	{'value': 'yuDyamAna', 'label': 'yuDyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'fight'}, {'pos': 'prpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuyujAna', 'label': 'yuyujAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuyujvas', 'label': 'yuyujvas', 'attrib': [{'pos': 'pfap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuyuDAna', 'label': 'yuyuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuzmad', 'label': 'yuzmad', 'attrib': [{'pos': 'pers_pron', 'defs': 'you'}], 'isLemma': 1},
	{'value': 'yUpa', 'label': 'yUpa', 'attrib': [{'pos': 'm', 'defs': 'sacrificial post'}], 'isLemma': 1},
	{'value': 'yemAna', 'label': 'yemAna', 'attrib': [{'pos': 'pfpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yemivas', 'label': 'yemivas', 'attrib': [{'pos': 'pfap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yoktavya', 'label': 'yoktavya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yoktum', 'label': 'yoktum', 'attrib': [{'pos': 'inf', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yokzyat', 'label': 'yokzyat', 'attrib': [{'pos': 'fap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yokzyamARa', 'label': 'yokzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yogya', 'label': 'yogya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yojanIya', 'label': 'yojanIya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yotsyamAna', 'label': 'yotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yodDavya', 'label': 'yodDavya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yodDum', 'label': 'yodDum', 'attrib': [{'pos': 'inf', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yoDanIya', 'label': 'yoDanIya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yoDya', 'label': 'yoDya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'raMsyamAna', 'label': 'raMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'rajju', 'label': 'rajju', 'attrib': [{'pos': 'f', 'defs': 'rope'}], 'isLemma': 1},
	{'value': 'raRa', 'label': 'raRa', 'attrib': [{'pos': 'n', 'defs': 'battle'}], 'isLemma': 1},
	{'value': 'rata', 'label': 'rata', 'attrib': [{'pos': 'ppp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ratavat', 'label': 'ratavat', 'attrib': [{'pos': 'pap', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ratna', 'label': 'ratna', 'attrib': [{'pos': 'n', 'defs': 'gift, gem'}], 'isLemma': 1},
	{'value': 'ratvA', 'label': 'ratvA', 'attrib': [{'pos': 'abs', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'raTa', 'label': 'raTa', 'attrib': [{'pos': 'm', 'defs': 'chariot'}], 'isLemma': 1},
	{'value': 'rantavya', 'label': 'rantavya', 'attrib': [{'pos': 'ger', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'rantum', 'label': 'rantum', 'attrib': [{'pos': 'inf', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ram', 'label': 'ram', 'attrib': [{'pos': 'vi1m', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 1},
	{'value': 'ramaRIya', 'label': 'ramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ramamARa', 'label': 'ramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ramya', 'label': 'ramya', 'attrib': [{'pos': 'ger', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ramyamARa', 'label': 'ramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'rAjan', 'label': 'rAjan', 'attrib': [{'pos': 'm', 'defs': 'king'}], 'isLemma': 1},
	{'value': 'rAjya', 'label': 'rAjya', 'attrib': [{'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 1},
	{'value': 'rAtri', 'label': 'rAtri', 'attrib': [{'pos': 'f', 'defs': 'night'}], 'isLemma': 1},
	{'value': 'rAmAyaRa', 'label': 'rAmAyaRa', 'attrib': [{'pos': 'n_pn', 'defs': 'the <i>Rāmāyana</i>'}], 'isLemma': 1},
	{'value': 'rAzwra', 'label': 'rAzwra', 'attrib': [{'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 1},
	{'value': 'rudDa', 'label': 'rudDa', 'attrib': [{'pos': 'ppp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rudDavat', 'label': 'rudDavat', 'attrib': [{'pos': 'pap', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rudDvA', 'label': 'rudDvA', 'attrib': [{'pos': 'abs', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'ruD', 'label': 'ruD', 'attrib': [{'pos': 'vt7am', 'defs': 'obstruct'}], 'isLemma': 1},
	{'value': 'ruDyamAna', 'label': 'ruDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'runDat', 'label': 'runDat', 'attrib': [{'pos': 'prap', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'runDAna', 'label': 'runDAna', 'attrib': [{'pos': 'prmp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'ruruDAna', 'label': 'ruruDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'ruruDvas', 'label': 'ruruDvas', 'attrib': [{'pos': 'pfap', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'remARa', 'label': 'remARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'roga', 'label': 'roga', 'attrib': [{'pos': 'm', 'defs': 'illness'}], 'isLemma': 1},
	{'value': 'rotsyat', 'label': 'rotsyat', 'attrib': [{'pos': 'fap', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rotsyamAna', 'label': 'rotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rodDavya', 'label': 'rodDavya', 'attrib': [{'pos': 'ger', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rodDum', 'label': 'rodDum', 'attrib': [{'pos': 'inf', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'roDanIya', 'label': 'roDanIya', 'attrib': [{'pos': 'ger', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'roDya', 'label': 'roDya', 'attrib': [{'pos': 'ger', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'lapsyamAna', 'label': 'lapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labDa', 'label': 'labDa', 'attrib': [{'pos': 'ppp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labDavat', 'label': 'labDavat', 'attrib': [{'pos': 'pap', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labDavya', 'label': 'labDavya', 'attrib': [{'pos': 'ger', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labDum', 'label': 'labDum', 'attrib': [{'pos': 'inf', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labDvA', 'label': 'labDvA', 'attrib': [{'pos': 'abs', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'laB', 'label': 'laB', 'attrib': [{'pos': 'vt1m', 'defs': 'grab, get, obtain'}], 'isLemma': 1},
	{'value': 'laBanIya', 'label': 'laBanIya', 'attrib': [{'pos': 'ger', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'laBamAna', 'label': 'laBamAna', 'attrib': [{'pos': 'prmp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'laBya', 'label': 'laBya', 'attrib': [{'pos': 'ger', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'laByamAna', 'label': 'laByamAna', 'attrib': [{'pos': 'prpp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'leBAna', 'label': 'leBAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'loka', 'label': 'loka', 'attrib': [{'pos': 'm', 'defs': 'region, world'}], 'isLemma': 1},
	{'value': 'vaktavya', 'label': 'vaktavya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vaktum', 'label': 'vaktum', 'attrib': [{'pos': 'inf', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vakzyat', 'label': 'vakzyat', 'attrib': [{'pos': 'fap', 'defs': 'say, speak'}, {'pos': 'fap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vac', 'vah']},
	{'value': 'vakzyamARa', 'label': 'vakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'say, speak'}, {'pos': 'fmp, fpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vac', 'vah']},
	{'value': 'vac', 'label': 'vac', 'attrib': [{'pos': 'vt2a', 'defs': 'say, speak'}], 'isLemma': 1},
	{'value': 'vacana', 'label': 'vacana', 'attrib': [{'pos': 'n', 'defs': 'speech, word'}], 'isLemma': 1},
	{'value': 'vacanIya', 'label': 'vacanIya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vatsyat', 'label': 'vatsyat', 'attrib': [{'pos': 'fap', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vatsyamAna', 'label': 'vatsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vad', 'label': 'vad', 'attrib': [{'pos': 'vt1a', 'defs': 'tell'}], 'isLemma': 1},
	{'value': 'vadat', 'label': 'vadat', 'attrib': [{'pos': 'prap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadanIya', 'label': 'vadanIya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vaditavya', 'label': 'vaditavya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vaditum', 'label': 'vaditum', 'attrib': [{'pos': 'inf', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadizyat', 'label': 'vadizyat', 'attrib': [{'pos': 'fap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadizyamARa', 'label': 'vadizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vaDU', 'label': 'vaDU', 'attrib': [{'pos': 'f', 'defs': 'bride, wife'}], 'isLemma': 1},
	{'value': 'vana', 'label': 'vana', 'attrib': [{'pos': 'n', 'defs': 'forest'}], 'isLemma': 1},
	{'value': 'vara', 'label': 'vara', 'attrib': [{'pos': 'adj', 'defs': 'choice, excellent'}, {'pos': 'm', 'defs': 'boon, bridegroom'}], 'isLemma': 1},
	{'value': 'varaRIya', 'label': 'varaRIya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varitavya', 'label': 'varitavya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varitum', 'label': 'varitum', 'attrib': [{'pos': 'inf', 'defs': 'cover'}, {'pos': 'inf', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varizyat', 'label': 'varizyat', 'attrib': [{'pos': 'fap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varizyamARa', 'label': 'varizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover'}, {'pos': 'fmp, fpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varItavya', 'label': 'varItavya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varItum', 'label': 'varItum', 'attrib': [{'pos': 'inf', 'defs': 'cover'}, {'pos': 'inf', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varIzyat', 'label': 'varIzyat', 'attrib': [{'pos': 'fap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varIzyamARa', 'label': 'varIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover'}, {'pos': 'fmp, fpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varuRa', 'label': 'varuRa', 'attrib': [{'pos': 'm_pn', 'defs': 'Varuṇa'}], 'isLemma': 1},
	{'value': 'vartanIya', 'label': 'vartanIya', 'attrib': [{'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartamAna', 'label': 'vartamAna', 'attrib': [{'pos': 'prmp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartitavya', 'label': 'vartitavya', 'attrib': [{'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartitum', 'label': 'vartitum', 'attrib': [{'pos': 'inf', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartitvA', 'label': 'vartitvA', 'attrib': [{'pos': 'abs', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartizyamARa', 'label': 'vartizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'varza', 'label': 'varza', 'attrib': [{'pos': 'm', 'defs': 'rain, year'}], 'isLemma': 1},
	{'value': 'vavftAna', 'label': 'vavftAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vavfvas', 'label': 'vavfvas', 'attrib': [{'pos': 'pfap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vavrajAna', 'label': 'vavrajAna', 'attrib': [{'pos': 'pfpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vavrajvas', 'label': 'vavrajvas', 'attrib': [{'pos': 'pfap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vavrARa', 'label': 'vavrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cover'}, {'pos': 'pfmp, pfpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vas', 'label': 'vas', 'attrib': [{'pos': 'vi1a', 'defs': 'dwell'}], 'isLemma': 1},
	{'value': 'vasat', 'label': 'vasat', 'attrib': [{'pos': 'prap', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vasanIya', 'label': 'vasanIya', 'attrib': [{'pos': 'ger', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vasitavya', 'label': 'vasitavya', 'attrib': [{'pos': 'ger', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vastum', 'label': 'vastum', 'attrib': [{'pos': 'inf', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vah', 'label': 'vah', 'attrib': [{'pos': 'vt1am', 'defs': 'carry, flow, wed'}], 'isLemma': 1},
	{'value': 'vahat', 'label': 'vahat', 'attrib': [{'pos': 'prap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vahamAna', 'label': 'vahamAna', 'attrib': [{'pos': 'prmp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vA', 'label': 'vA', 'attrib': [{'pos': 'disj_pcl', 'defs': 'or'}], 'isLemma': 1},
	{'value': 'vAkya', 'label': 'vAkya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vAc', 'label': 'vAc', 'attrib': [{'pos': 'f', 'defs': 'speech'}], 'isLemma': 1},
	{'value': 'vAdya', 'label': 'vAdya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vAra', 'label': 'vAra', 'attrib': [{'pos': 'm', 'defs': 'occasion, day'}], 'isLemma': 1},
	{'value': 'vAraRIya', 'label': 'vAraRIya', 'attrib': [{'pos': 'ger', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayat', 'label': 'vArayat', 'attrib': [{'pos': 'prap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayamARa', 'label': 'vArayamARa', 'attrib': [{'pos': 'prmp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayAm', 'label': 'vArayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayitavya', 'label': 'vArayitavya', 'attrib': [{'pos': 'ger', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayitum', 'label': 'vArayitum', 'attrib': [{'pos': 'inf', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayitvA', 'label': 'vArayitvA', 'attrib': [{'pos': 'abs', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayizyat', 'label': 'vArayizyat', 'attrib': [{'pos': 'fap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArayizyamARa', 'label': 'vArayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vAri', 'label': 'vAri', 'attrib': [{'pos': 'n', 'defs': 'water'}], 'isLemma': 1},
	{'value': 'vArita', 'label': 'vArita', 'attrib': [{'pos': 'ppp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vAritavat', 'label': 'vAritavat', 'attrib': [{'pos': 'pap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vArya', 'label': 'vArya', 'attrib': [{'pos': 'ger', 'defs': 'block'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vAryamARa', 'label': 'vAryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vAlmIki', 'label': 'vAlmIki', 'attrib': [{'pos': 'm_pn', 'defs': 'Vālmīki'}], 'isLemma': 1},
	{'value': 'vAsas', 'label': 'vAsas', 'attrib': [{'pos': 'n', 'defs': 'cloth, clothes'}], 'isLemma': 1},
	{'value': 'vAsya', 'label': 'vAsya', 'attrib': [{'pos': 'ger', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vAhanIya', 'label': 'vAhanIya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vAhya', 'label': 'vAhya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vi', 'label': 'vi', 'attrib': [{'pos': 'preverb', 'defs': 'apart'}], 'isLemma': 1},
	{'value': 'vikrayaRIya', 'label': 'vikrayaRIya', 'attrib': [{'pos': 'ger', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrIRat', 'label': 'vikrIRat', 'attrib': [{'pos': 'prap', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrIRAna', 'label': 'vikrIRAna', 'attrib': [{'pos': 'prmp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrIta', 'label': 'vikrIta', 'attrib': [{'pos': 'ppp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrItavat', 'label': 'vikrItavat', 'attrib': [{'pos': 'pap', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrIya', 'label': 'vikrIya', 'attrib': [{'pos': 'abs', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrIyamARa', 'label': 'vikrIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikretavya', 'label': 'vikretavya', 'attrib': [{'pos': 'ger', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikretum', 'label': 'vikretum', 'attrib': [{'pos': 'inf', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikreya', 'label': 'vikreya', 'attrib': [{'pos': 'ger', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrezyat', 'label': 'vikrezyat', 'attrib': [{'pos': 'fap', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrezyamARa', 'label': 'vikrezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vigacCat', 'label': 'vigacCat', 'attrib': [{'pos': 'prap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigata', 'label': 'vigata', 'attrib': [{'pos': 'ppp', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigatavat', 'label': 'vigatavat', 'attrib': [{'pos': 'pap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigatya', 'label': 'vigatya', 'attrib': [{'pos': 'abs', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigantavya', 'label': 'vigantavya', 'attrib': [{'pos': 'ger', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigantum', 'label': 'vigantum', 'attrib': [{'pos': 'inf', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigama', 'label': 'vigama', 'attrib': [{'pos': 'm', 'defs': 'separation, departure'}], 'isLemma': 1},
	{'value': 'vigamanIya', 'label': 'vigamanIya', 'attrib': [{'pos': 'ger', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigamizyat', 'label': 'vigamizyat', 'attrib': [{'pos': 'fap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigamizyamARa', 'label': 'vigamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigamya', 'label': 'vigamya', 'attrib': [{'pos': 'abs', 'defs': 'separate, depart'}, {'pos': 'ger', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigamyamAna', 'label': 'vigamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vicikriyARa', 'label': 'vicikriyARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vicikrIvas', 'label': 'vicikrIvas', 'attrib': [{'pos': 'pfap', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vijaganvas', 'label': 'vijaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vijagmAna', 'label': 'vijagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vijagmivas', 'label': 'vijagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vijajYAna', 'label': 'vijajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijajYivas', 'label': 'vijajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijAnat', 'label': 'vijAnat', 'attrib': [{'pos': 'prap', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijYAta', 'label': 'vijYAta', 'attrib': [{'pos': 'ppp', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijYAtavat', 'label': 'vijYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijYAtavya', 'label': 'vijYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijYAtum', 'label': 'vijYAtum', 'attrib': [{'pos': 'inf', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijYAna', 'label': 'vijYAna', 'attrib': [{'pos': 'n', 'defs': 'understanding'}], 'isLemma': 1},
	{'value': 'vijYAnIya', 'label': 'vijYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijYAya', 'label': 'vijYAya', 'attrib': [{'pos': 'abs', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijYAyamAna', 'label': 'vijYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijYAsyat', 'label': 'vijYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijYAsyamAna', 'label': 'vijYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijYeya', 'label': 'vijYeya', 'attrib': [{'pos': 'ger', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vi_krI', 'label': 'vi_krI', 'attrib': [{'pos': 'vt9am', 'defs': 'sell'}], 'isLemma': 1},
	{'value': 'vi_gam', 'label': 'vi_gam', 'attrib': [{'pos': 'vi1a', 'defs': 'separate, depart'}], 'isLemma': 1},
	{'value': 'vi_jYA', 'label': 'vi_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'discern, understand'}], 'isLemma': 1},
	{'value': 'vi_sad', 'label': 'vi_sad', 'attrib': [{'pos': 'vi1a, vi6a', 'defs': 'be sad, grieve'}], 'isLemma': 1},
	{'value': 'vi_smf', 'label': 'vi_smf', 'attrib': [{'pos': 'vt1a', 'defs': 'forget'}], 'isLemma': 1},
	{'value': 'vitta', 'label': 'vitta', 'attrib': [{'pos': 'ppp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vittavat', 'label': 'vittavat', 'attrib': [{'pos': 'pap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vittvA', 'label': 'vittvA', 'attrib': [{'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vid', 'label': 'vid', 'attrib': [{'pos': 'vt2a', 'defs': 'know'}, {'pos': 'vt6am', 'defs': 'find, get'}], 'isLemma': 1},
	{'value': 'vidAm', 'label': 'vidAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidita', 'label': 'vidita', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viditavat', 'label': 'viditavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viditvA', 'label': 'viditvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}, {'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidDa', 'label': 'vidDa', 'attrib': [{'pos': 'ppp', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vidDavat', 'label': 'vidDavat', 'attrib': [{'pos': 'pap', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vidDvA', 'label': 'vidDvA', 'attrib': [{'pos': 'abs', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vidyamAna', 'label': 'vidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}, {'pos': 'prpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidyut', 'label': 'vidyut', 'attrib': [{'pos': 'm', 'defs': 'lightning'}], 'isLemma': 1},
	{'value': 'vidvas', 'label': 'vidvas', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viDyat', 'label': 'viDyat', 'attrib': [{'pos': 'prap', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'viDyamAna', 'label': 'viDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vinA', 'label': 'vinA', 'attrib': [{'pos': 'pcl', 'defs': 'without (2/3/5)'}], 'isLemma': 1},
	{'value': 'vindat', 'label': 'vindat', 'attrib': [{'pos': 'prap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vindamAna', 'label': 'vindamAna', 'attrib': [{'pos': 'prmp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vividAna', 'label': 'vividAna', 'attrib': [{'pos': 'pfpp', 'defs': 'know'}, {'pos': 'pfmp, pfpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vividivas', 'label': 'vividivas', 'attrib': [{'pos': 'pfap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vividvas', 'label': 'vividvas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}, {'pos': 'pfap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viviSAna', 'label': 'viviSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'viviSivas', 'label': 'viviSivas', 'attrib': [{'pos': 'pfap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'viviSvas', 'label': 'viviSvas', 'attrib': [{'pos': 'pfap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vivyaDAna', 'label': 'vivyaDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vivyaDvas', 'label': 'vivyaDvas', 'attrib': [{'pos': 'pfap', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'viS', 'label': 'viS', 'attrib': [{'pos': 'm', 'defs': 'settler'}, {'pos': 'vt6a', 'defs': 'enter'}], 'isLemma': 1},
	{'value': 'viSat', 'label': 'viSat', 'attrib': [{'pos': 'prap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'viSyamAna', 'label': 'viSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vizaRRa', 'label': 'vizaRRa', 'attrib': [{'pos': 'ppp', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizaRRavat', 'label': 'vizaRRavat', 'attrib': [{'pos': 'pap', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizattavya', 'label': 'vizattavya', 'attrib': [{'pos': 'ger', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizattum', 'label': 'vizattum', 'attrib': [{'pos': 'inf', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizatsyat', 'label': 'vizatsyat', 'attrib': [{'pos': 'fap', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizatsyamAna', 'label': 'vizatsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizadanIya', 'label': 'vizadanIya', 'attrib': [{'pos': 'ger', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizadya', 'label': 'vizadya', 'attrib': [{'pos': 'abs', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizadyamAna', 'label': 'vizadyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizAdya', 'label': 'vizAdya', 'attrib': [{'pos': 'ger', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizIdat', 'label': 'vizIdat', 'attrib': [{'pos': 'prap', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizedAna', 'label': 'vizedAna', 'attrib': [{'pos': 'pfpp', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizedivas', 'label': 'vizedivas', 'attrib': [{'pos': 'pfap', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'vizwa', 'label': 'vizwa', 'attrib': [{'pos': 'ppp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vizwavat', 'label': 'vizwavat', 'attrib': [{'pos': 'pap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vizwvA', 'label': 'vizwvA', 'attrib': [{'pos': 'abs', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'visasmarARa', 'label': 'visasmarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'visasmarvas', 'label': 'visasmarvas', 'attrib': [{'pos': 'pfap', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismaraRIya', 'label': 'vismaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismarat', 'label': 'vismarat', 'attrib': [{'pos': 'prap', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismarizyat', 'label': 'vismarizyat', 'attrib': [{'pos': 'fap', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismarizyamARa', 'label': 'vismarizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismartavya', 'label': 'vismartavya', 'attrib': [{'pos': 'ger', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismartum', 'label': 'vismartum', 'attrib': [{'pos': 'inf', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismaryamARa', 'label': 'vismaryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismArya', 'label': 'vismArya', 'attrib': [{'pos': 'ger', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismfta', 'label': 'vismfta', 'attrib': [{'pos': 'ppp', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismftavat', 'label': 'vismftavat', 'attrib': [{'pos': 'pap', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismftya', 'label': 'vismftya', 'attrib': [{'pos': 'abs', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vIra', 'label': 'vIra', 'attrib': [{'pos': 'adj', 'defs': 'heroic'}, {'pos': 'm', 'defs': 'hero'}], 'isLemma': 1},
	{'value': 'vf', 'label': 'vf', 'attrib': [{'pos': 'vt5am', 'defs': 'cover'}, {'pos': 'vt10am', 'defs': 'block'}, {'pos': 'vt9m', 'defs': 'choose'}], 'isLemma': 1},
	{'value': 'vfka', 'label': 'vfka', 'attrib': [{'pos': 'm', 'defs': 'wolf'}], 'isLemma': 1},
	{'value': 'vfkza', 'label': 'vfkza', 'attrib': [{'pos': 'm', 'defs': 'tree'}], 'isLemma': 1},
	{'value': 'vfRAna', 'label': 'vfRAna', 'attrib': [{'pos': 'prmp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vfRvat', 'label': 'vfRvat', 'attrib': [{'pos': 'prap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vfRvAna', 'label': 'vfRvAna', 'attrib': [{'pos': 'prmp', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vft', 'label': 'vft', 'attrib': [{'pos': 'vi1m', 'defs': 'occur, be present'}], 'isLemma': 1},
	{'value': 'vfta', 'label': 'vfta', 'attrib': [{'pos': 'ppp', 'defs': 'cover'}, {'pos': 'ppp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vftavat', 'label': 'vftavat', 'attrib': [{'pos': 'pap', 'defs': 'cover'}, {'pos': 'pap', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vftta', 'label': 'vftta', 'attrib': [{'pos': 'ppp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vfttavat', 'label': 'vfttavat', 'attrib': [{'pos': 'pap', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vftya', 'label': 'vftya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vf', 'vft']},
	{'value': 'vftvA', 'label': 'vftvA', 'attrib': [{'pos': 'abs', 'defs': 'cover'}, {'pos': 'abs', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vekzyat', 'label': 'vekzyat', 'attrib': [{'pos': 'fap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vekzyamARa', 'label': 'vekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vettavya', 'label': 'vettavya', 'attrib': [{'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vettum', 'label': 'vettum', 'attrib': [{'pos': 'inf', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vetsyat', 'label': 'vetsyat', 'attrib': [{'pos': 'fap', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vetsyamAna', 'label': 'vetsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'veda', 'label': 'veda', 'attrib': [{'pos': 'm', 'defs': 'knowledge, veda , N. of most ancient texts'}], 'isLemma': 1},
	{'value': 'vedanIya', 'label': 'vedanIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vedi', 'label': 'vedi', 'attrib': [{'pos': 'f', 'defs': 'altar'}], 'isLemma': 1},
	{'value': 'veditavya', 'label': 'veditavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veditum', 'label': 'veditum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veditvA', 'label': 'veditvA', 'attrib': [{'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vedizyat', 'label': 'vedizyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}, {'pos': 'fap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vedizyamARa', 'label': 'vedizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'know'}, {'pos': 'fmp, fpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vedDavya', 'label': 'vedDavya', 'attrib': [{'pos': 'ger', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vedDum', 'label': 'vedDum', 'attrib': [{'pos': 'inf', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vedya', 'label': 'vedya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veDanIya', 'label': 'veDanIya', 'attrib': [{'pos': 'ger', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'veDya', 'label': 'veDya', 'attrib': [{'pos': 'ger', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'veSanIya', 'label': 'veSanIya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'veSya', 'label': 'veSya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vezwavya', 'label': 'vezwavya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vezwum', 'label': 'vezwum', 'attrib': [{'pos': 'inf', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vE', 'label': 'vE', 'attrib': [{'pos': 'pcl', 'defs': 'verily, truly'}], 'isLemma': 1},
	{'value': 'voQavya', 'label': 'voQavya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'voQum', 'label': 'voQum', 'attrib': [{'pos': 'inf', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vyaD', 'label': 'vyaD', 'attrib': [{'pos': 'vt4a', 'defs': 'pierce'}], 'isLemma': 1},
	{'value': 'vyAsa', 'label': 'vyAsa', 'attrib': [{'pos': 'm_pn', 'defs': 'Vyāsa'}], 'isLemma': 1},
	{'value': 'vraj', 'label': 'vraj', 'attrib': [{'pos': 'vt1a', 'defs': 'walk'}], 'isLemma': 1},
	{'value': 'vrajat', 'label': 'vrajat', 'attrib': [{'pos': 'prap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajanIya', 'label': 'vrajanIya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajita', 'label': 'vrajita', 'attrib': [{'pos': 'ppp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitavat', 'label': 'vrajitavat', 'attrib': [{'pos': 'pap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitavya', 'label': 'vrajitavya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitum', 'label': 'vrajitum', 'attrib': [{'pos': 'inf', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitvA', 'label': 'vrajitvA', 'attrib': [{'pos': 'abs', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajizyat', 'label': 'vrajizyat', 'attrib': [{'pos': 'fap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajizyamARa', 'label': 'vrajizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajyamAna', 'label': 'vrajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrAjya', 'label': 'vrAjya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vriyamARa', 'label': 'vriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'cover'}, {'pos': 'prpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'Sak', 'label': 'Sak', 'attrib': [{'pos': 'vi5a', 'defs': 'be able'}], 'isLemma': 1},
	{'value': 'SakanIya', 'label': 'SakanIya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'SakuntalA', 'label': 'SakuntalA', 'attrib': [{'pos': 'f_pn', 'defs': 'Śakuntalā'}], 'isLemma': 1},
	{'value': 'Sakta', 'label': 'Sakta', 'attrib': [{'pos': 'ppp', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Saktavat', 'label': 'Saktavat', 'attrib': [{'pos': 'pap', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Saktavya', 'label': 'Saktavya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Saktum', 'label': 'Saktum', 'attrib': [{'pos': 'inf', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'SaktvA', 'label': 'SaktvA', 'attrib': [{'pos': 'abs', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Saknuvat', 'label': 'Saknuvat', 'attrib': [{'pos': 'prap', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Sakya', 'label': 'Sakya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'SakyamAna', 'label': 'SakyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'Sata', 'label': 'Sata', 'attrib': [{'pos': 'card_num', 'defs': 'hundred'}], 'isLemma': 1},
	{'value': 'Satru', 'label': 'Satru', 'attrib': [{'pos': 'm', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'Sap', 'label': 'Sap', 'attrib': [{'pos': 'vt1am, vt4am', 'defs': 'swear (an oath), curse'}], 'isLemma': 1},
	{'value': 'Sapat', 'label': 'Sapat', 'attrib': [{'pos': 'prap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'SapanIya', 'label': 'SapanIya', 'attrib': [{'pos': 'ger', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'SapamAna', 'label': 'SapamAna', 'attrib': [{'pos': 'prmp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'Sapta', 'label': 'Sapta', 'attrib': [{'pos': 'ppp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'Saptavat', 'label': 'Saptavat', 'attrib': [{'pos': 'pap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'Saptavya', 'label': 'Saptavya', 'attrib': [{'pos': 'ger', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'Saptum', 'label': 'Saptum', 'attrib': [{'pos': 'inf', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'SaptvA', 'label': 'SaptvA', 'attrib': [{'pos': 'abs', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'Sapya', 'label': 'Sapya', 'attrib': [{'pos': 'ger', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'Sapyat', 'label': 'Sapyat', 'attrib': [{'pos': 'prap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'SapyamAna', 'label': 'SapyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'swear (an oath), curse'}, {'pos': 'prpp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'Sapsyat', 'label': 'Sapsyat', 'attrib': [{'pos': 'fap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'SapsyamAna', 'label': 'SapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'Sabda', 'label': 'Sabda', 'attrib': [{'pos': 'm', 'defs': 'speech, word'}], 'isLemma': 1},
	{'value': 'Sayana', 'label': 'Sayana', 'attrib': [{'pos': 'n', 'defs': 'bed, couch'}], 'isLemma': 1},
	{'value': 'SayanIya', 'label': 'SayanIya', 'attrib': [{'pos': 'ger', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'SayAna', 'label': 'SayAna', 'attrib': [{'pos': 'prmp', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'Sayita', 'label': 'Sayita', 'attrib': [{'pos': 'ppp', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'Sayitavat', 'label': 'Sayitavat', 'attrib': [{'pos': 'pap', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'Sayitavya', 'label': 'Sayitavya', 'attrib': [{'pos': 'ger', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'Sayitum', 'label': 'Sayitum', 'attrib': [{'pos': 'inf', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'SayitvA', 'label': 'SayitvA', 'attrib': [{'pos': 'abs', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'Sayya', 'label': 'Sayya', 'attrib': [{'pos': 'ger', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'Sara', 'label': 'Sara', 'attrib': [{'pos': 'm', 'defs': 'arrow'}], 'isLemma': 1},
	{'value': 'SaSAsAna', 'label': 'SaSAsAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SaSAsvas', 'label': 'SaSAsvas', 'attrib': [{'pos': 'pfap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAKA', 'label': 'SAKA', 'attrib': [{'pos': 'f', 'defs': 'branch'}], 'isLemma': 1},
	{'value': 'SApa', 'label': 'SApa', 'attrib': [{'pos': 'm', 'defs': 'promise, curse, vow'}], 'isLemma': 1},
	{'value': 'SAlA', 'label': 'SAlA', 'attrib': [{'pos': 'f', 'defs': 'hall'}], 'isLemma': 1},
	{'value': 'SAs', 'label': 'SAs', 'attrib': [{'pos': 'vt2a', 'defs': 'rule, govern, teach'}], 'isLemma': 1},
	{'value': 'SAsat', 'label': 'SAsat', 'attrib': [{'pos': 'prap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAsanIya', 'label': 'SAsanIya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAsitavya', 'label': 'SAsitavya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAsitum', 'label': 'SAsitum', 'attrib': [{'pos': 'inf', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAsitvA', 'label': 'SAsitvA', 'attrib': [{'pos': 'abs', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAsizyat', 'label': 'SAsizyat', 'attrib': [{'pos': 'fap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SAsizyamARa', 'label': 'SAsizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'Sita', 'label': 'Sita', 'attrib': [{'pos': 'adj', 'defs': 'sharp'}], 'isLemma': 1},
	{'value': 'Siva', 'label': 'Siva', 'attrib': [{'pos': 'adj', 'defs': 'auspicious'}, {'pos': 'm_pn', 'defs': 'Śiva'}], 'isLemma': 1},
	{'value': 'Sizwa', 'label': 'Sizwa', 'attrib': [{'pos': 'ppp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'Sizwavat', 'label': 'Sizwavat', 'attrib': [{'pos': 'pap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SizwvA', 'label': 'SizwvA', 'attrib': [{'pos': 'abs', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'Sizya', 'label': 'Sizya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}, {'pos': 'm', 'defs': 'student'}], 'isLemma': 1},
	{'value': 'Sizya', 'label': 'Sizya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}, {'pos': 'm', 'defs': 'student'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SizyamARa', 'label': 'SizyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'SI', 'label': 'SI', 'attrib': [{'pos': 'vi2m', 'defs': 'lie, sleep'}], 'isLemma': 1},
	{'value': 'SIGra', 'label': 'SIGra', 'attrib': [{'pos': 'adj', 'defs': 'swift, quick'}], 'isLemma': 1},
	{'value': 'SIGram', 'label': 'SIGram', 'attrib': [{'pos': 'adv', 'defs': 'swiftly, quickly'}], 'isLemma': 1},
	{'value': 'Suc', 'label': 'Suc', 'attrib': [{'pos': 'vt1a', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 1},
	{'value': 'Suci', 'label': 'Suci', 'attrib': [{'pos': 'adj', 'defs': 'pure'}], 'isLemma': 1},
	{'value': 'Sucita', 'label': 'Sucita', 'attrib': [{'pos': 'ppp', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'Sucitavat', 'label': 'Sucitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'SucitvA', 'label': 'SucitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'SuSucvas', 'label': 'SuSucvas', 'attrib': [{'pos': 'pfap', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'SfRvat', 'label': 'SfRvat', 'attrib': [{'pos': 'prap', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'SepAna', 'label': 'SepAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'Sepivas', 'label': 'Sepivas', 'attrib': [{'pos': 'pfap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'Seza', 'label': 'Seza', 'attrib': [{'pos': 'm', 'defs': 'remainder'}], 'isLemma': 1},
	{'value': 'Socat', 'label': 'Socat', 'attrib': [{'pos': 'prap', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'SocanIya', 'label': 'SocanIya', 'attrib': [{'pos': 'ger', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'SocayitvA', 'label': 'SocayitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'Socita', 'label': 'Socita', 'attrib': [{'pos': 'ppp', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'Socitavat', 'label': 'Socitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'Socitavya', 'label': 'Socitavya', 'attrib': [{'pos': 'ger', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'Socitum', 'label': 'Socitum', 'attrib': [{'pos': 'inf', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'SocitvA', 'label': 'SocitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'Socya', 'label': 'Socya', 'attrib': [{'pos': 'ger', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'SravaRIya', 'label': 'SravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'Sravya', 'label': 'Sravya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'SrI', 'label': 'SrI', 'attrib': [{'pos': 'f', 'defs': 'glory'}], 'isLemma': 1},
	{'value': 'Sru', 'label': 'Sru', 'attrib': [{'pos': 'vt1am', 'defs': 'hear, listen'}], 'isLemma': 1},
	{'value': 'Sruta', 'label': 'Sruta', 'attrib': [{'pos': 'ppp', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'Srutavat', 'label': 'Srutavat', 'attrib': [{'pos': 'pap', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'SrutvA', 'label': 'SrutvA', 'attrib': [{'pos': 'abs', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'SrUyamARa', 'label': 'SrUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'Srotavya', 'label': 'Srotavya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'Srotum', 'label': 'Srotum', 'attrib': [{'pos': 'inf', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'Svan', 'label': 'Svan', 'attrib': [{'pos': 'm', 'defs': 'dog'}], 'isLemma': 1},
	{'value': 'Svas', 'label': 'Svas', 'attrib': [{'pos': 'adv', 'defs': 'tomorrow'}], 'isLemma': 1},
	{'value': 'zaz', 'label': 'zaz', 'attrib': [{'pos': 'card_num', 'defs': 'six'}], 'isLemma': 1},
	{'value': 'zazWa', 'label': 'zazWa', 'attrib': [{'pos': 'ord_num', 'defs': 'sixth'}], 'isLemma': 1},
	{'value': 'saMkraMsyat', 'label': 'saMkraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMkraMsyamAna', 'label': 'saMkraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMkramaRIya', 'label': 'saMkramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMkramitavya', 'label': 'saMkramitavya', 'attrib': [{'pos': 'ger', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMkramitum', 'label': 'saMkramitum', 'attrib': [{'pos': 'inf', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMkramya', 'label': 'saMkramya', 'attrib': [{'pos': 'abs', 'defs': 'meet'}, {'pos': 'ger', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMkramyamARa', 'label': 'saMkramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMkrAnta', 'label': 'saMkrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMkrAntavat', 'label': 'saMkrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMkrAmat', 'label': 'saMkrAmat', 'attrib': [{'pos': 'prap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMkrAmyat', 'label': 'saMkrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMgacCamAna', 'label': 'saMgacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgata', 'label': 'saMgata', 'attrib': [{'pos': 'ppp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgatavat', 'label': 'saMgatavat', 'attrib': [{'pos': 'pap', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgatya', 'label': 'saMgatya', 'attrib': [{'pos': 'abs', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgantavya', 'label': 'saMgantavya', 'attrib': [{'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgantum', 'label': 'saMgantum', 'attrib': [{'pos': 'inf', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgamanIya', 'label': 'saMgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgamizyamARa', 'label': 'saMgamizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgamya', 'label': 'saMgamya', 'attrib': [{'pos': 'abs', 'defs': 'go together, unite, meet'}, {'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMgamyamAna', 'label': 'saMgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMcakramARa', 'label': 'saMcakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMcakramvas', 'label': 'saMcakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saMjagmAna', 'label': 'saMjagmAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saMjahfvas', 'label': 'saMjahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMjahrARa', 'label': 'saMjahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMpattavya', 'label': 'saMpattavya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpattum', 'label': 'saMpattum', 'attrib': [{'pos': 'inf', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpatsyamAna', 'label': 'saMpatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpadanIya', 'label': 'saMpadanIya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpadya', 'label': 'saMpadya', 'attrib': [{'pos': 'abs', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpadyamAna', 'label': 'saMpadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'succeed, arise'}, {'pos': 'prpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpanna', 'label': 'saMpanna', 'attrib': [{'pos': 'ppp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpannavat', 'label': 'saMpannavat', 'attrib': [{'pos': 'pap', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpapracCAna', 'label': 'saMpapracCAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saMpAdya', 'label': 'saMpAdya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMpfcCamAna', 'label': 'saMpfcCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saMpfcCya', 'label': 'saMpfcCya', 'attrib': [{'pos': 'abs', 'defs': 'consult'}, {'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saMpfcCyamAna', 'label': 'saMpfcCyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saMpfzwa', 'label': 'saMpfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saMpfzwavat', 'label': 'saMpfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saMpedAna', 'label': 'saMpedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saMprakzyamARa', 'label': 'saMprakzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saMpracCanIya', 'label': 'saMpracCanIya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saMprazwavya', 'label': 'saMprazwavya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saMprazwum', 'label': 'saMprazwum', 'attrib': [{'pos': 'inf', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saMbaBUvas', 'label': 'saMbaBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavat', 'label': 'saMBavat', 'attrib': [{'pos': 'prap', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavanIya', 'label': 'saMBavanIya', 'attrib': [{'pos': 'ger', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavitavya', 'label': 'saMBavitavya', 'attrib': [{'pos': 'ger', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavitum', 'label': 'saMBavitum', 'attrib': [{'pos': 'inf', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavizyat', 'label': 'saMBavizyat', 'attrib': [{'pos': 'fap', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavizyamARa', 'label': 'saMBavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBavya', 'label': 'saMBavya', 'attrib': [{'pos': 'ger', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBUta', 'label': 'saMBUta', 'attrib': [{'pos': 'ppp', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBUtavat', 'label': 'saMBUtavat', 'attrib': [{'pos': 'pap', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBUya', 'label': 'saMBUya', 'attrib': [{'pos': 'abs', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMBUyamAna', 'label': 'saMBUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saMvadanIya', 'label': 'saMvadanIya', 'attrib': [{'pos': 'ger', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'saMvadamAna', 'label': 'saMvadamAna', 'attrib': [{'pos': 'prmp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'saMvaditavya', 'label': 'saMvaditavya', 'attrib': [{'pos': 'ger', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'saMvaditum', 'label': 'saMvaditum', 'attrib': [{'pos': 'inf', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'saMvadizyamARa', 'label': 'saMvadizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'saMvAda', 'label': 'saMvAda', 'attrib': [{'pos': 'm', 'defs': 'conversation'}], 'isLemma': 1},
	{'value': 'saMvAdya', 'label': 'saMvAdya', 'attrib': [{'pos': 'ger', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'saMSaya', 'label': 'saMSaya', 'attrib': [{'pos': 'm', 'defs': 'doubt'}], 'isLemma': 1},
	{'value': 'saMSayanIya', 'label': 'saMSayanIya', 'attrib': [{'pos': 'ger', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saMSayAna', 'label': 'saMSayAna', 'attrib': [{'pos': 'prmp', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saMSayita', 'label': 'saMSayita', 'attrib': [{'pos': 'ppp', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saMSayitavat', 'label': 'saMSayitavat', 'attrib': [{'pos': 'pap', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saMSayitavya', 'label': 'saMSayitavya', 'attrib': [{'pos': 'ger', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saMSayitum', 'label': 'saMSayitum', 'attrib': [{'pos': 'inf', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saMSayya', 'label': 'saMSayya', 'attrib': [{'pos': 'abs', 'defs': 'lie down, hesitate'}, {'pos': 'ger', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saMskfta', 'label': 'saMskfta', 'attrib': [{'pos': 'adj', 'defs': 'refined'}, {'pos': 'n', 'defs': 'Sanskrit'}], 'isLemma': 1},
	{'value': 'saMharaRIya', 'label': 'saMharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMharat', 'label': 'saMharat', 'attrib': [{'pos': 'prap', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMharamARa', 'label': 'saMharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMharizyat', 'label': 'saMharizyat', 'attrib': [{'pos': 'fap', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMharizyamARa', 'label': 'saMharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMhartavya', 'label': 'saMhartavya', 'attrib': [{'pos': 'ger', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMhartum', 'label': 'saMhartum', 'attrib': [{'pos': 'inf', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMhArya', 'label': 'saMhArya', 'attrib': [{'pos': 'ger', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMhfta', 'label': 'saMhfta', 'attrib': [{'pos': 'ppp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMhftavat', 'label': 'saMhftavat', 'attrib': [{'pos': 'pap', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMhftya', 'label': 'saMhftya', 'attrib': [{'pos': 'abs', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saMhriyamARa', 'label': 'saMhriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saKI', 'label': 'saKI', 'attrib': [{'pos': 'f', 'defs': 'friend'}], 'isLemma': 1},
	{'value': 'saKya', 'label': 'saKya', 'attrib': [{'pos': 'n', 'defs': 'friendship'}], 'isLemma': 1},
	{'value': 'sat', 'label': 'sat', 'attrib': [{'pos': 'prap', 'defs': 'be'}], 'isLemma': 0, 'parents': ['as']},
	{'value': 'sattavya', 'label': 'sattavya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sattum', 'label': 'sattum', 'attrib': [{'pos': 'inf', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sattvA', 'label': 'sattvA', 'attrib': [{'pos': 'abs', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'satya', 'label': 'satya', 'attrib': [{'pos': 'adj', 'defs': 'true, real'}, {'pos': 'n', 'defs': 'truth'}], 'isLemma': 1},
	{'value': 'satsyat', 'label': 'satsyat', 'attrib': [{'pos': 'fap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'satsyamAna', 'label': 'satsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sad', 'label': 'sad', 'attrib': [{'pos': 'vi1a, vi6a', 'defs': 'sit, be sad'}], 'isLemma': 1},
	{'value': 'sadanIya', 'label': 'sadanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sadA', 'label': 'sadA', 'attrib': [{'pos': 'pron_adv', 'defs': 'always'}], 'isLemma': 1},
	{'value': 'sadyamAna', 'label': 'sadyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sanna', 'label': 'sanna', 'attrib': [{'pos': 'ppp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sannavat', 'label': 'sannavat', 'attrib': [{'pos': 'pap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'saptan', 'label': 'saptan', 'attrib': [{'pos': 'card_num', 'defs': 'seven'}], 'isLemma': 1},
	{'value': 'saptama', 'label': 'saptama', 'attrib': [{'pos': 'ord_num', 'defs': 'seventh'}], 'isLemma': 1},
	{'value': 'sam', 'label': 'sam', 'attrib': [{'pos': 'preverb', 'defs': 'together'}], 'isLemma': 1},
	{'value': 'samAgacCamAna', 'label': 'samAgacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samAgata', 'label': 'samAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samAgatavat', 'label': 'samAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samAgatya', 'label': 'samAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samAgantavya', 'label': 'samAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samAgantum', 'label': 'samAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samAgamanIya', 'label': 'samAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samAgamizyamARa', 'label': 'samAgamizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samAgamya', 'label': 'samAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come together, meet'}, {'pos': 'ger', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samAgamyamAna', 'label': 'samAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samAjagmAna', 'label': 'samAjagmAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samIpa', 'label': 'samIpa', 'attrib': [{'pos': 'adj', 'defs': 'near'}], 'isLemma': 1},
	{'value': 'samudita', 'label': 'samudita', 'attrib': [{'pos': 'ppp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'samuditavat', 'label': 'samuditavat', 'attrib': [{'pos': 'pap', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'samudya', 'label': 'samudya', 'attrib': [{'pos': 'abs', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'samudyamAna', 'label': 'samudyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'samUdAna', 'label': 'samUdAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'sam_A_gam', 'label': 'sam_A_gam', 'attrib': [{'pos': 'vi1m', 'defs': 'come together, meet'}], 'isLemma': 1},
	{'value': 'sam_kram', 'label': 'sam_kram', 'attrib': [{'pos': 'vi1a', 'defs': 'meet'}], 'isLemma': 1},
	{'value': 'sam_gam', 'label': 'sam_gam', 'attrib': [{'pos': 'vi1m', 'defs': 'go together, unite, meet'}], 'isLemma': 1},
	{'value': 'sam_pad', 'label': 'sam_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'succeed, arise'}], 'isLemma': 1},
	{'value': 'sam_pracC', 'label': 'sam_pracC', 'attrib': [{'pos': 'vi6m', 'defs': 'consult'}], 'isLemma': 1},
	{'value': 'sam_BU', 'label': 'sam_BU', 'attrib': [{'pos': 'vi1a', 'defs': 'be together, arise, be possible'}], 'isLemma': 1},
	{'value': 'sam_vad', 'label': 'sam_vad', 'attrib': [{'pos': 'vi1m', 'defs': 'speak together, converse, discuss'}], 'isLemma': 1},
	{'value': 'sam_SI', 'label': 'sam_SI', 'attrib': [{'pos': 'vi2m', 'defs': 'lie down, hesitate'}], 'isLemma': 1},
	{'value': 'sam_hf', 'label': 'sam_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'collect'}], 'isLemma': 1},
	{'value': 'sarit', 'label': 'sarit', 'attrib': [{'pos': 'f', 'defs': 'flowing, river'}], 'isLemma': 1},
	{'value': 'sarva', 'label': 'sarva', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'all, whole, every'}], 'isLemma': 1},
	{'value': 'sarvatas', 'label': 'sarvatas', 'attrib': [{'pos': 'pron_adv', 'defs': 'from everywhere'}], 'isLemma': 1},
	{'value': 'sarvatra', 'label': 'sarvatra', 'attrib': [{'pos': 'pron_adv', 'defs': 'everywhere'}], 'isLemma': 1},
	{'value': 'sarvaTA', 'label': 'sarvaTA', 'attrib': [{'pos': 'pron_adv', 'defs': 'in every way'}], 'isLemma': 1},
	{'value': 'sarvadA', 'label': 'sarvadA', 'attrib': [{'pos': 'pron_adv', 'defs': 'always'}], 'isLemma': 1},
	{'value': 'savanIya', 'label': 'savanIya', 'attrib': [{'pos': 'ger', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'savya', 'label': 'savya', 'attrib': [{'pos': 'ger', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sasmarARa', 'label': 'sasmarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'sasmarvas', 'label': 'sasmarvas', 'attrib': [{'pos': 'pfap', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'saha', 'label': 'saha', 'attrib': [{'pos': 'pcl', 'defs': 'with'}], 'isLemma': 1},
	{'value': 'sAdya', 'label': 'sAdya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sADu', 'label': 'sADu', 'attrib': [{'pos': 'adj', 'defs': 'straight, right, good'}, {'pos': 'm', 'defs': 'good man, sage'}], 'isLemma': 1},
	{'value': 'sAman', 'label': 'sAman', 'attrib': [{'pos': 'n', 'defs': 'verse of the <i>Sāmaveda</i>'}], 'isLemma': 1},
	{'value': 'sAmaveda', 'label': 'sAmaveda', 'attrib': [{'pos': 'm', 'defs': '<i>Sāmaveda</i>'}], 'isLemma': 1},
	{'value': 'sizevARa', 'label': 'sizevARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sizRihAna', 'label': 'sizRihAna', 'attrib': [{'pos': 'pfpp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'sizRihvas', 'label': 'sizRihvas', 'attrib': [{'pos': 'pfap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'sIdat', 'label': 'sIdat', 'attrib': [{'pos': 'prap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'su', 'label': 'su', 'attrib': [{'pos': 'preverb', 'defs': 'well'}, {'pos': 'vt5am', 'defs': 'press'}], 'isLemma': 1},
	{'value': 'suKa', 'label': 'suKa', 'attrib': [{'pos': 'adj', 'defs': 'comfortable, happy'}, {'pos': 'n', 'defs': 'happiness'}], 'isLemma': 1},
	{'value': 'suKam', 'label': 'suKam', 'attrib': [{'pos': 'adv', 'defs': 'comfortably, happily'}], 'isLemma': 1},
	{'value': 'suKin', 'label': 'suKin', 'attrib': [{'pos': 'adj', 'defs': 'happy'}], 'isLemma': 1},
	{'value': 'suta', 'label': 'suta', 'attrib': [{'pos': 'ppp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sutavat', 'label': 'sutavat', 'attrib': [{'pos': 'pap', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sutvA', 'label': 'sutvA', 'attrib': [{'pos': 'abs', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sudeva', 'label': 'sudeva', 'attrib': [{'pos': 'adj', 'defs': 'very noble'}], 'isLemma': 1},
	{'value': 'sundara', 'label': 'sundara', 'attrib': [{'pos': 'adj', 'defs': 'beautiful'}], 'isLemma': 1},
	{'value': 'sunvat', 'label': 'sunvat', 'attrib': [{'pos': 'prap', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sunvAna', 'label': 'sunvAna', 'attrib': [{'pos': 'prmp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sumanas', 'label': 'sumanas', 'attrib': [{'pos': 'adj', 'defs': 'good-minded, well-disposed, benevolent, cheerful, wise'}], 'isLemma': 1},
	{'value': 'suzuvas', 'label': 'suzuvas', 'attrib': [{'pos': 'pfap', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'suzuvARa', 'label': 'suzuvARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'suhfd', 'label': 'suhfd', 'attrib': [{'pos': 'adj', 'defs': 'good-hearted, well-disposed'}, {'pos': 'm, f', 'defs': 'friend'}], 'isLemma': 1},
	{'value': 'sUyamAna', 'label': 'sUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sUrya', 'label': 'sUrya', 'attrib': [{'pos': 'm', 'defs': 'sun'}], 'isLemma': 1},
	{'value': 'sedAna', 'label': 'sedAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sedivas', 'label': 'sedivas', 'attrib': [{'pos': 'pfap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sev', 'label': 'sev', 'attrib': [{'pos': 'vt1m', 'defs': 'serve'}], 'isLemma': 1},
	{'value': 'sevanIya', 'label': 'sevanIya', 'attrib': [{'pos': 'ger', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevamAna', 'label': 'sevamAna', 'attrib': [{'pos': 'prmp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevA', 'label': 'sevA', 'attrib': [{'pos': 'f', 'defs': 'service'}], 'isLemma': 1},
	{'value': 'sevita', 'label': 'sevita', 'attrib': [{'pos': 'ppp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevitavat', 'label': 'sevitavat', 'attrib': [{'pos': 'pap', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevitavya', 'label': 'sevitavya', 'attrib': [{'pos': 'ger', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevitum', 'label': 'sevitum', 'attrib': [{'pos': 'inf', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevitvA', 'label': 'sevitvA', 'attrib': [{'pos': 'abs', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevizyamARa', 'label': 'sevizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevya', 'label': 'sevya', 'attrib': [{'pos': 'ger', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevyamAna', 'label': 'sevyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sEnya', 'label': 'sEnya', 'attrib': [{'pos': 'n', 'defs': 'army'}], 'isLemma': 1},
	{'value': 'sotavya', 'label': 'sotavya', 'attrib': [{'pos': 'ger', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sotum', 'label': 'sotum', 'attrib': [{'pos': 'inf', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'soma', 'label': 'soma', 'attrib': [{'pos': 'm', 'defs': 'juice'}], 'isLemma': 1},
	{'value': 'sozyat', 'label': 'sozyat', 'attrib': [{'pos': 'fap', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sozyamARa', 'label': 'sozyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'strI', 'label': 'strI', 'attrib': [{'pos': 'f', 'defs': 'woman'}], 'isLemma': 1},
	{'value': 'sTA', 'label': 'sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stand, stay, be present'}], 'isLemma': 1},
	{'value': 'sTAtavya', 'label': 'sTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTAtum', 'label': 'sTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTAna', 'label': 'sTAna', 'attrib': [{'pos': 'n', 'defs': 'place'}], 'isLemma': 1},
	{'value': 'sTAnIya', 'label': 'sTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTApayitum', 'label': 'sTApayitum', 'attrib': [{'pos': 'inf', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTAlI', 'label': 'sTAlI', 'attrib': [{'pos': 'f', 'defs': 'pot, pan'}], 'isLemma': 1},
	{'value': 'sTAsyat', 'label': 'sTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTAsyamAna', 'label': 'sTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTita', 'label': 'sTita', 'attrib': [{'pos': 'ppp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTitavat', 'label': 'sTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTitvA', 'label': 'sTitvA', 'attrib': [{'pos': 'abs', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTIyamAna', 'label': 'sTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sTeya', 'label': 'sTeya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'snigDa', 'label': 'snigDa', 'attrib': [{'pos': 'ppp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snigDavat', 'label': 'snigDavat', 'attrib': [{'pos': 'pap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snih', 'label': 'snih', 'attrib': [{'pos': 'vi4a', 'defs': 'stick, be affectionate'}], 'isLemma': 1},
	{'value': 'snihitvA', 'label': 'snihitvA', 'attrib': [{'pos': 'abs', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snihyat', 'label': 'snihyat', 'attrib': [{'pos': 'prap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snihyamAna', 'label': 'snihyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snekzyat', 'label': 'snekzyat', 'attrib': [{'pos': 'fap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snekzyamARa', 'label': 'snekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'sneha', 'label': 'sneha', 'attrib': [{'pos': 'm', 'defs': 'affection'}], 'isLemma': 1},
	{'value': 'snehanIya', 'label': 'snehanIya', 'attrib': [{'pos': 'ger', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snehitavya', 'label': 'snehitavya', 'attrib': [{'pos': 'ger', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snehitum', 'label': 'snehitum', 'attrib': [{'pos': 'inf', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snehitvA', 'label': 'snehitvA', 'attrib': [{'pos': 'abs', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snehizyat', 'label': 'snehizyat', 'attrib': [{'pos': 'fap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snehizyamARa', 'label': 'snehizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snehya', 'label': 'snehya', 'attrib': [{'pos': 'ger', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'sparSanIya', 'label': 'sparSanIya', 'attrib': [{'pos': 'ger', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spfS', 'label': 'spfS', 'attrib': [{'pos': 'vt6a', 'defs': 'touch'}], 'isLemma': 1},
	{'value': 'spfSat', 'label': 'spfSat', 'attrib': [{'pos': 'prap', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spfSya', 'label': 'spfSya', 'attrib': [{'pos': 'ger', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spfSyamAna', 'label': 'spfSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spfzwa', 'label': 'spfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spfzwavat', 'label': 'spfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spfzwvA', 'label': 'spfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'sprakzyat', 'label': 'sprakzyat', 'attrib': [{'pos': 'fap', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'sprakzyamARa', 'label': 'sprakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'sprazwavya', 'label': 'sprazwavya', 'attrib': [{'pos': 'ger', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'sprazwum', 'label': 'sprazwum', 'attrib': [{'pos': 'inf', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'sma', 'label': 'sma', 'attrib': [{'pos': 'pcl', 'defs': 'ever, certainly'}], 'isLemma': 1},
	{'value': 'smaraRIya', 'label': 'smaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smarat', 'label': 'smarat', 'attrib': [{'pos': 'prap', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smarizyat', 'label': 'smarizyat', 'attrib': [{'pos': 'fap', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smarizyamARa', 'label': 'smarizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smartavya', 'label': 'smartavya', 'attrib': [{'pos': 'ger', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smartum', 'label': 'smartum', 'attrib': [{'pos': 'inf', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smaryamARa', 'label': 'smaryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smArya', 'label': 'smArya', 'attrib': [{'pos': 'ger', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smf', 'label': 'smf', 'attrib': [{'pos': 'vt1a', 'defs': 'remember'}], 'isLemma': 1},
	{'value': 'smfta', 'label': 'smfta', 'attrib': [{'pos': 'ppp', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smftavat', 'label': 'smftavat', 'attrib': [{'pos': 'pap', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smfti', 'label': 'smfti', 'attrib': [{'pos': 'f', 'defs': 'memory, traditional texts'}], 'isLemma': 1},
	{'value': 'smftvA', 'label': 'smftvA', 'attrib': [{'pos': 'abs', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'sraj', 'label': 'sraj', 'attrib': [{'pos': 'f', 'defs': 'garland'}], 'isLemma': 1},
	{'value': 'sva', 'label': 'sva', 'attrib': [{'pos': 'pron, pron_adj', 'defs': "one's own"}], 'isLemma': 1},
	{'value': 'svarAzwra', 'label': 'svarAzwra', 'attrib': [{'pos': 'n', 'defs': 'own kingdom'}], 'isLemma': 1},
	{'value': 'svarga', 'label': 'svarga', 'attrib': [{'pos': 'm', 'defs': 'heaven'}], 'isLemma': 1},
	{'value': 'svasf', 'label': 'svasf', 'attrib': [{'pos': 'f', 'defs': 'sister'}], 'isLemma': 1},
	{'value': 'ha', 'label': 'ha', 'attrib': [{'pos': 'pcl', 'defs': 'indeed, of course'}], 'isLemma': 1},
	{'value': 'hata', 'label': 'hata', 'attrib': [{'pos': 'ppp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hatavat', 'label': 'hatavat', 'attrib': [{'pos': 'pap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hatvA', 'label': 'hatvA', 'attrib': [{'pos': 'abs', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'han', 'label': 'han', 'attrib': [{'pos': 'vt2a', 'defs': 'smite'}], 'isLemma': 1},
	{'value': 'hananIya', 'label': 'hananIya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hanizyat', 'label': 'hanizyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hanizyamARa', 'label': 'hanizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hantavya', 'label': 'hantavya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hantum', 'label': 'hantum', 'attrib': [{'pos': 'inf', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hanyamAna', 'label': 'hanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hayanIya', 'label': 'hayanIya', 'attrib': [{'pos': 'ger', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'haraRIya', 'label': 'haraRIya', 'attrib': [{'pos': 'ger', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'harat', 'label': 'harat', 'attrib': [{'pos': 'prap', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'haramARa', 'label': 'haramARa', 'attrib': [{'pos': 'prmp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hari', 'label': 'hari', 'attrib': [{'pos': 'adj', 'defs': 'yellow'}, {'pos': 'm', 'defs': 'yellow, horse'}, {'pos': 'm_pn', 'defs': 'Hari Candra'}], 'isLemma': 1},
	{'value': 'harizyat', 'label': 'harizyat', 'attrib': [{'pos': 'fap', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'harizyamARa', 'label': 'harizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hartavya', 'label': 'hartavya', 'attrib': [{'pos': 'ger', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hartum', 'label': 'hartum', 'attrib': [{'pos': 'inf', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'havanIya', 'label': 'havanIya', 'attrib': [{'pos': 'ger', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'havis', 'label': 'havis', 'attrib': [{'pos': 'n', 'defs': 'oblation'}], 'isLemma': 1},
	{'value': 'havya', 'label': 'havya', 'attrib': [{'pos': 'ger', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hasta', 'label': 'hasta', 'attrib': [{'pos': 'm', 'defs': 'hand'}], 'isLemma': 1},
	{'value': 'hastin', 'label': 'hastin', 'attrib': [{'pos': 'adj', 'defs': 'having a hand'}, {'pos': 'm', 'defs': 'elephant'}], 'isLemma': 1},
	{'value': 'hA', 'label': 'hA', 'attrib': [{'pos': 'vt3a', 'defs': 'leave, abandon'}], 'isLemma': 1},
	{'value': 'hAtavya', 'label': 'hAtavya', 'attrib': [{'pos': 'ger', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hAtum', 'label': 'hAtum', 'attrib': [{'pos': 'inf', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hAnIya', 'label': 'hAnIya', 'attrib': [{'pos': 'ger', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hArya', 'label': 'hArya', 'attrib': [{'pos': 'ger', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hAsyat', 'label': 'hAsyat', 'attrib': [{'pos': 'fap', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hAsyamAna', 'label': 'hAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hi', 'label': 'hi', 'attrib': [{'pos': 'sub_pcl', 'defs': 'because, for'}, {'pos': 'vt5a', 'defs': 'impel, send'}], 'isLemma': 1},
	{'value': 'hita', 'label': 'hita', 'attrib': [{'pos': 'ppp', 'defs': 'put'}, {'pos': 'ppp', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['DA', 'hi']},
	{'value': 'hitavat', 'label': 'hitavat', 'attrib': [{'pos': 'pap', 'defs': 'put'}, {'pos': 'pap', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['DA', 'hi']},
	{'value': 'hitvA', 'label': 'hitvA', 'attrib': [{'pos': 'abs', 'defs': 'put'}, {'pos': 'abs', 'defs': 'leave, abandon'}, {'pos': 'abs', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['DA', 'hA', 'hi']},
	{'value': 'hinvat', 'label': 'hinvat', 'attrib': [{'pos': 'prap', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'himAlaya', 'label': 'himAlaya', 'attrib': [{'pos': 'm_pn', 'defs': 'Himālaya , the Himalayan mountain range'}], 'isLemma': 1},
	{'value': 'hIna', 'label': 'hIna', 'attrib': [{'pos': 'ppp', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hInavat', 'label': 'hInavat', 'attrib': [{'pos': 'pap', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hIyamAna', 'label': 'hIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'leave, abandon'}, {'pos': 'prpp', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hA', 'hi']},
	{'value': 'hu', 'label': 'hu', 'attrib': [{'pos': 'vt3a', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 1},
	{'value': 'huta', 'label': 'huta', 'attrib': [{'pos': 'ppp', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hutavat', 'label': 'hutavat', 'attrib': [{'pos': 'pap', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hutvA', 'label': 'hutvA', 'attrib': [{'pos': 'abs', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hUta', 'label': 'hUta', 'attrib': [{'pos': 'ppp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hUtavat', 'label': 'hUtavat', 'attrib': [{'pos': 'pap', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hUtvA', 'label': 'hUtvA', 'attrib': [{'pos': 'abs', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hUyamAna', 'label': 'hUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}, {'pos': 'prpp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hu', 'hve']},
	{'value': 'hf', 'label': 'hf', 'attrib': [{'pos': 'vt1am', 'defs': 'take'}], 'isLemma': 1},
	{'value': 'hfta', 'label': 'hfta', 'attrib': [{'pos': 'ppp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hftavat', 'label': 'hftavat', 'attrib': [{'pos': 'pap', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hftvA', 'label': 'hftvA', 'attrib': [{'pos': 'abs', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'he', 'label': 'he', 'attrib': [{'pos': 'pcl', 'defs': 'hey, O'}], 'isLemma': 1},
	{'value': 'hetavya', 'label': 'hetavya', 'attrib': [{'pos': 'ger', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'hetu', 'label': 'hetu', 'attrib': [{'pos': 'm', 'defs': 'reason, cause'}], 'isLemma': 1},
	{'value': 'hetum', 'label': 'hetum', 'attrib': [{'pos': 'inf', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'heya', 'label': 'heya', 'attrib': [{'pos': 'ger', 'defs': 'leave, abandon'}, {'pos': 'ger', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hA', 'hi']},
	{'value': 'hezyat', 'label': 'hezyat', 'attrib': [{'pos': 'fap', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'hezyamARa', 'label': 'hezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'hotavya', 'label': 'hotavya', 'attrib': [{'pos': 'ger', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hotum', 'label': 'hotum', 'attrib': [{'pos': 'inf', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hotf', 'label': 'hotf', 'attrib': [{'pos': 'm', 'defs': 'hotṛ'}], 'isLemma': 1},
	{'value': 'hozyat', 'label': 'hozyat', 'attrib': [{'pos': 'fap', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hozyamARa', 'label': 'hozyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hyas', 'label': 'hyas', 'attrib': [{'pos': 'adv', 'defs': 'yesterday'}], 'isLemma': 1},
	{'value': 'hriyamARa', 'label': 'hriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hrI', 'label': 'hrI', 'attrib': [{'pos': 'f', 'defs': 'modesty'}], 'isLemma': 1},
	{'value': 'hvayat', 'label': 'hvayat', 'attrib': [{'pos': 'prap', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvayamAna', 'label': 'hvayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvAtavya', 'label': 'hvAtavya', 'attrib': [{'pos': 'ger', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvAtum', 'label': 'hvAtum', 'attrib': [{'pos': 'inf', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvAnIya', 'label': 'hvAnIya', 'attrib': [{'pos': 'ger', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvAya', 'label': 'hvAya', 'attrib': [{'pos': 'ger', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvAsyat', 'label': 'hvAsyat', 'attrib': [{'pos': 'fap', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvAsyamAna', 'label': 'hvAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hve', 'label': 'hve', 'attrib': [{'pos': 'vt1am', 'defs': 'call'}], 'isLemma': 1}
]

export const pos ={
	'agni':['m'],
	'agra':['n'],
	'aNgulIya':['n'],
	'atas':['dem_adv'],
	'ati':['preverb'],
	'ati_i':['vt2a'],
	'atiyat':['prap'],
	'atIyamAna':['prpp'],
	'atyezyat':['fap'],
	'atyezyamARa':['fpp'],
	'atIyivas':['pfap'],
	'atIyAna':['pfpp'],
	'atIta':['ppp'],
	'atItavat':['pap'],
	'atyetum':['inf'],
	'atItya':['abs', 'ger'],
	'atItavya':['ger'],
	'atyayanIya':['ger'],
	'atiTi':['m'],
	'atra':['dem_adv'],
	'aTa':['pcl'],
	'ad':['vt2a'],
	'adat':['prap'],
	'adyamAna':['prpp'],
	'atsyat':['fap'],
	'atsyamAna':['fpp'],
	'Adivas':['pfap'],
	'AdAna':['pfpp'],
	'anna':['ppp', 'n'],
	'annavat':['pap'],
	'attum':['inf'],
	'attvA':['abs'],
	'Adya':['ger'],
	'attavya':['ger'],
	'adanIya':['ger'],
	'adas':['dem_pron', 'dem_adj'],
	'adya':['adv'],
	'aDi':['preverb'],
	'aDi_i':['vt2m'],
	'aDIyAna':['prmp'],
	'aDIyamAna':['prpp'],
	'aDyezyamARa':['fmp', 'fpp'],
	'aDijagAna':['pfmp', 'pfpp'],
	'aDIta':['ppp'],
	'aDItavat':['pap'],
	'aDyetum':['inf'],
	'aDItya':['abs'],
	'aDyeya':['ger'],
	'aDyetavya':['ger'],
	'aDyayanIya':['ger'],
	'aDunA':['adv'],
	'aDvaryu':['m'],
	'anu':['preverb'],
	'anu_A_gam':['vt1a'],
	'anvAgacCat':['prap'],
	'anvAgamyamAna':['prpp'],
	'anvAgamizyat':['fap'],
	'anvAgamizyamARa':['fpp'],
	'anvAjagmivas':['pfap'],
	'anvAjaganvas':['pfap'],
	'anvAjagmAna':['pfpp'],
	'anvAgata':['ppp'],
	'anvAgatavat':['pap'],
	'anvAgantum':['inf'],
	'anvAgamya':['abs', 'ger'],
	'anvAgatya':['abs'],
	'anvAgantavya':['ger'],
	'anvAgamanIya':['ger'],
	'anu_iz':['vt6a'],
	'anvicCat':['prap'],
	'anvizyamARa':['prpp'],
	'anvezizyat':['fap'],
	'anvezizyamARa':['fpp'],
	'anvIzivas':['pfap'],
	'anvIzARa':['pfpp'],
	'anvizwa':['ppp'],
	'anvizwavat':['pap'],
	'anvezwum':['inf'],
	'anvizya':['abs'],
	'anvezya':['ger'],
	'anvezwavya':['ger'],
	'anvezaRIya':['ger'],
	'anu_gam':['vt1a'],
	'anugacCat':['prap'],
	'anugamyamAna':['prpp'],
	'anugamizyat':['fap'],
	'anugamizyamARa':['fpp'],
	'anujagmivas':['pfap'],
	'anujaganvas':['pfap'],
	'anujagmAna':['pfpp'],
	'anugata':['ppp'],
	'anugatavat':['pap'],
	'anugantum':['inf'],
	'anugamya':['abs', 'ger'],
	'anugatya':['abs'],
	'anugantavya':['ger'],
	'anugamanIya':['ger'],
	'anu_jYA':['vt9a'],
	'anujAnat':['prap'],
	'anujYAyamAna':['prpp'],
	'anujYAsyat':['fap'],
	'anujYAsyamAna':['fpp'],
	'anujajYivas':['pfap'],
	'anujajYAna':['pfpp'],
	'anujYAta':['ppp'],
	'anujYAtavat':['pap'],
	'anujYAtum':['inf'],
	'anujYAya':['abs'],
	'anujYeya':['ger'],
	'anujYAtavya':['ger'],
	'anujYAnIya':['ger'],
	'anujYA':['f'],
	'anu_pat':['vt1a'],
	'anupatat':['prap'],
	'anupatyamAna':['prpp'],
	'anupatizyat':['fap'],
	'anupatizyamARa':['fpp'],
	'anupetivas':['pfap'],
	'anupetAna':['pfpp'],
	'anupatita':['ppp'],
	'anupatitavat':['pap'],
	'anupatitum':['inf'],
	'anupatya':['abs'],
	'anupAtya':['ger'],
	'anupatitavya':['ger'],
	'anupatanIya':['ger'],
	'anta':['m'],
	'anya':['pron', 'pron_adj'],
	'anyatas':['pron_adv'],
	'anyatra':['pron_adv'],
	'anyaTA':['pron_adv'],
	'anyadA':['pron_adv'],
	'apa':['preverb'],
	'apa_kram':['vt1a'],
	'apakrAmat':['prap'],
	'apakrAmyat':['prap'],
	'apakramyamARa':['prpp'],
	'apakraMsyat':['fap'],
	'apakraMsyamAna':['fpp'],
	'apacakramvas':['pfap'],
	'apacakramARa':['pfpp'],
	'apakrAnta':['ppp'],
	'apakrAntavat':['pap'],
	'apakramitum':['inf'],
	'apakramya':['abs', 'ger'],
	'apakramitavya':['ger'],
	'apakramaRIya':['ger'],
	'apa_gam':['vt1a'],
	'apagacCat':['prap'],
	'apagamyamAna':['prpp'],
	'apagamizyat':['fap'],
	'apagamizyamARa':['fpp'],
	'apajagmivas':['pfap'],
	'apajaganvas':['pfap'],
	'apajagmAna':['pfpp'],
	'apagata':['ppp'],
	'apagatavat':['pap'],
	'apagantum':['inf'],
	'apagamya':['abs', 'ger'],
	'apagatya':['abs'],
	'apagantavya':['ger'],
	'apagamanIya':['ger'],
	'apa_hf':['vt1am'],
	'apaharat':['prap'],
	'apaharamARa':['prmp'],
	'apahriyamARa':['prpp'],
	'apaharizyat':['fap'],
	'apaharizyamARa':['fmp', 'fpp'],
	'apajahfvas':['pfap'],
	'apajahrARa':['pfmp', 'pfpp'],
	'apahfta':['ppp'],
	'apahftavat':['pap'],
	'apahartum':['inf'],
	'apahftya':['abs'],
	'apahArya':['ger'],
	'apahartavya':['ger'],
	'apaharaRIya':['ger'],
	'api':['pcl'],
	'apsaras':['f'],
	'aBi':['preverb'],
	'aBi_A_gam':['vt1a'],
	'aByAgacCat':['prap'],
	'aByAgamyamAna':['prpp'],
	'aByAgamizyat':['fap'],
	'aByAgamizyamARa':['fpp'],
	'aByAjagmivas':['pfap'],
	'aByAjaganvas':['pfap'],
	'aByAjagmAna':['pfpp'],
	'aByAgata':['ppp'],
	'aByAgatavat':['pap'],
	'aByAgantum':['inf'],
	'aByAgamya':['abs', 'ger'],
	'aByAgatya':['abs'],
	'aByAgantavya':['ger'],
	'aByAgamanIya':['ger'],
	'aBi_vad':['vt1a'],
	'aBivadat':['prap'],
	'aByudyamAna':['prpp'],
	'aBivadizyat':['fap'],
	'aBivadizyamARa':['fpp'],
	'aByUdivas':['pfap'],
	'aByUdAna':['pfpp'],
	'aByudita':['ppp'],
	'aByuditavat':['pap'],
	'aBivaditum':['inf'],
	'aByudya':['abs'],
	'aBivAdya':['ger'],
	'aBivaditavya':['ger'],
	'aBivadanIya':['ger'],
	'araRi':['f'],
	'ari':['m'],
	'arjuna':['m_pn'],
	'arTa':['m'],
	'arh':['vt1a'],
	'arhat':['prap'],
	'arhizyat':['fap'],
	'arhizyamARa':['fpp'],
	'Anarhivas':['pfap'],
	'AnarhARa':['pfpp'],
	'arhita':['ppp'],
	'arhitavat':['pap'],
	'arhitum':['inf'],
	'arhitvA':['abs'],
	'arhya':['ger'],
	'arhitavya':['ger'],
	'arhaRIya':['ger'],
	'alpa':['adj'],
	'ava':['preverb'],
	'ava_Ap':['vt5a'],
	'avApnuvat':['prap'],
	'avApyamAna':['prpp'],
	'avApsyat':['fap'],
	'avApsyamAna':['fpp'],
	'avApivas':['pfap'],
	'avApAna':['pfpp'],
	'avApta':['ppp'],
	'avAptavat':['pap'],
	'avAptum':['inf'],
	'avApya':['abs', 'ger'],
	'avAptavya':['ger'],
	'avApanIya':['ger'],
	'ava_gam':['vt1a'],
	'avagacCat':['prap'],
	'avagamyamAna':['prpp'],
	'avagamizyat':['fap'],
	'avagamizyamARa':['fpp'],
	'avajagmivas':['pfap'],
	'avajaganvas':['pfap'],
	'avajagmAna':['pfpp'],
	'avagata':['ppp'],
	'avagatavat':['pap'],
	'avagantum':['inf'],
	'avagamya':['abs', 'ger'],
	'avagatya':['abs'],
	'avagantavya':['ger'],
	'avagamanIya':['ger'],
	'ava_sTA':['vi1m'],
	'avatizWamAna':['prmp'],
	'avasTIyamAna':['prpp'],
	'avasTAsyamAna':['fmp', 'fpp'],
	'avatasTAna':['pfmp', 'pfpp'],
	'avasTita':['ppp'],
	'avasTitavat':['pap'],
	'avasTAtum':['inf'],
	'avasTAya':['abs'],
	'avasTeya':['ger'],
	'avasTAtavya':['ger'],
	'avasTAnIya':['ger'],
	'ava_hf':['vt1am'],
	'avaharat':['prap'],
	'avaharamARa':['prmp'],
	'avahriyamARa':['prpp'],
	'avaharizyat':['fap'],
	'avaharizyamARa':['fmp', 'fpp'],
	'avajahfvas':['pfap'],
	'avajahrARa':['pfmp', 'pfpp'],
	'avahfta':['ppp'],
	'avahftavat':['pap'],
	'avahartum':['inf'],
	'avahftya':['abs'],
	'avahArya':['ger'],
	'avahartavya':['ger'],
	'avaharaRIya':['ger'],
	'avi':['m', 'f'],
	'aSva':['m'],
	'azwan':['card_num'],
	'azwama':['ord_num'],
	'as':['vi2a'],
	'sat':['prap'],
	'asi':['m'],
	'asura':['m'],
	'asta':['n'],
	'asmad':['pers_pron'],
	'ah':['vta'],
	'ahan':['n'],
	'A':['preverb'],
	'A_i':['vt2a'],
	'Ayat':['prap'],
	'eyamAna':['prpp'],
	'Ezyat':['fap'],
	'EzyamARa':['fpp'],
	'eyivas':['pfap'],
	'eyAna':['pfpp'],
	'eta':['ppp'],
	'etavat':['pap'],
	'Etum':['inf'],
	'etya':['abs', 'ger'],
	'Etavya':['ger'],
	'AyanIya':['ger'],
	'A_kram':['vt1am'],
	'AkrAmat':['prap'],
	'AkrAmyat':['prap'],
	'AkramamARa':['prmp'],
	'AkramyamARa':['prmp', 'prpp'],
	'AkraMsyat':['fap'],
	'AkraMsyamAna':['fmp', 'fpp'],
	'Acakramvas':['pfap'],
	'AcakramARa':['pfmp', 'pfpp'],
	'AkrAnta':['ppp'],
	'AkrAntavat':['pap'],
	'AkrAntum':['inf'],
	'Akramitum':['inf'],
	'Akramya':['abs', 'ger'],
	'Akramitavya':['ger'],
	'AkramaRIya':['ger'],
	'A_gam':['vt1a'],
	'AgacCat':['prap'],
	'AgamyamAna':['prpp'],
	'Agamizyat':['fap'],
	'AgamizyamARa':['fpp'],
	'Ajagmivas':['pfap'],
	'Ajaganvas':['pfap'],
	'AjagmAna':['pfpp'],
	'Agata':['ppp'],
	'Agatavat':['pap'],
	'Agantum':['inf'],
	'Agamya':['abs', 'ger'],
	'Agatya':['abs'],
	'Agantavya':['ger'],
	'AgamanIya':['ger'],
	'AcArya':['m'],
	'AjYA':['f'],
	'A_jYA':['vt9a'],
	'AjAnat':['prap'],
	'AjYAyamAna':['prpp'],
	'AjYAsyat':['fap'],
	'AjYAsyamAna':['fpp'],
	'AjajYivas':['pfap'],
	'AjajYAna':['pfpp'],
	'AjYAta':['ppp'],
	'AjYAtavat':['pap'],
	'AjYAtum':['inf'],
	'AjYAya':['abs'],
	'AjYeya':['ger'],
	'AjYAtavya':['ger'],
	'AjYAnIya':['ger'],
	'Ati':['f'],
	'A_dA':['vt3am'],
	'Adadat':['prap'],
	'AdadAna':['prmp', 'pfmp', 'pfpp'],
	'AdIyamAna':['prpp'],
	'AdAsyat':['fap'],
	'AdAsyamAna':['fmp', 'fpp'],
	'Adadivas':['pfap'],
	'Atta':['ppp'],
	'Adattavat':['pap'],
	'AdAtum':['inf'],
	'AdAya':['abs'],
	'Adeya':['ger'],
	'AdAtavya':['ger'],
	'AdAnIya':['ger'],
	'A_nI':['vt1am'],
	'Anayat':['prap'],
	'AnayamAna':['prmp'],
	'AnIyamAna':['prpp'],
	'Anezyat':['fap'],
	'AnezyamARa':['fmp', 'fpp'],
	'AninIvas':['pfap'],
	'AninyAna':['pfmp', 'pfpp'],
	'AnIta':['ppp'],
	'AnItavat':['pap'],
	'Anetum':['inf'],
	'AnIya':['abs'],
	'Aneya':['ger'],
	'Anetavya':['ger'],
	'AnayanIya':['ger'],
	'Ap':['vt5a'],
	'Apnuvat':['prap'],
	'ApyamAna':['prpp'],
	'Apsyat':['fap'],
	'ApsyamAna':['fpp'],
	'Apivas':['pfap'],
	'ApAna':['pfpp'],
	'Apta':['ppp'],
	'Aptavat':['pap'],
	'Aptum':['inf'],
	'AptvA':['abs'],
	'Apya':['ger'],
	'Aptavya':['ger'],
	'ApanIya':['ger'],
	'ApaRa':['m'],
	'A_pad':['vt4m'],
	'ApadyamAna':['prmp', 'prpp'],
	'ApatsyamAna':['fmp', 'fpp'],
	'ApedAna':['pfmp', 'pfpp'],
	'Apanna':['ppp'],
	'Apannavat':['pap'],
	'Apattum':['inf'],
	'Apadya':['abs'],
	'ApAdya':['ger'],
	'Apattavya':['ger'],
	'ApadanIya':['ger'],
	'Ayus':['n'],
	'Avis':['preverb'],
	'Avis_BU':['vi1a'],
	'AvirBavat':['prap'],
	'AvirBUyamAna':['prpp'],
	'AvirBavizyat':['fap'],
	'AvirBavizyamARa':['fpp'],
	'AvirbaBUvas':['pfap'],
	'AvirBUta':['ppp'],
	'AvirBUtavat':['pap'],
	'AvirBavitum':['inf'],
	'AvirBUya':['abs'],
	'AvirBavya':['ger'],
	'AvirBavitavya':['ger'],
	'AvirBavanIya':['ger'],
	'ASrama':['m', 'n'],
	'As':['vi2m'],
	'AsIna':['prmp'],
	'AsyamAna':['prpp'],
	'AsizyamARa':['fmp', 'fpp'],
	'AsAm':['ppf_actn'],
	'Asita':['ppp'],
	'Asitavat':['pap'],
	'Asitum':['inf'],
	'AsitvA':['abs'],
	'Asya':['ger'],
	'Astavya':['ger'],
	'AsanIya':['ger'],
	'A_sTA':['vt1a'],
	'AtizWat':['prap'],
	'AsTIyamAna':['prpp'],
	'AsTAsyat':['fap'],
	'AsTAsyamAna':['fpp'],
	'AtasTivas':['pfap'],
	'AtasTAna':['pfpp'],
	'AsTita':['ppp'],
	'AsTitavat':['pap'],
	'AsTAtum':['inf'],
	'AsTAya':['abs'],
	'AsTeya':['ger'],
	'AsTAtavya':['ger'],
	'AsTAnIya':['ger'],
	'A_hf':['vt1am'],
	'Aharat':['prap'],
	'AharamARa':['prmp'],
	'AhriyamARa':['prpp'],
	'Aharizyat':['fap'],
	'AharizyamARa':['fmp', 'fpp'],
	'Ajahfvas':['pfap'],
	'AjahrARa':['pfmp', 'pfpp'],
	'Ahfta':['ppp'],
	'Ahftavat':['pap'],
	'Ahartum':['inf'],
	'Ahftya':['abs'],
	'AhArya':['ger'],
	'Ahartavya':['ger'],
	'AharaRIya':['ger'],
	'A_hve':['vt1am'],
	'Ahvayat':['prap'],
	'AhvayamAna':['prmp'],
	'AhUyamAna':['prpp'],
	'AhvAsyat':['fap'],
	'AhvAsyamAna':['fmp', 'fpp'],
	'AjuhUvas':['pfap'],
	'AjuhuvAna':['pfmp', 'pfpp'],
	'AhUta':['ppp'],
	'AhUtavat':['pap'],
	'AhvAtum':['inf'],
	'AhUya':['abs'],
	'AhvAya':['ger'],
	'AhvAtavya':['ger'],
	'AhvAnIya':['ger'],
	'i':['vt2a'],
	'yat':['prap'],
	'IyamAna':['prpp'],
	'ezyat':['fap'],
	'ezyamARa':['fpp'],
	'Iyivas':['pfap'],
	'IyAna':['pfpp'],
	'ita':['ppp'],
	'itavat':['pap'],
	'etum':['inf'],
	'itvA':['abs'],
	'itya':['ger'],
	'etavya':['ger'],
	'ayanIya':['ger'],
	'itara':['pron', 'pron_adj'],
	'itas':['dem_adv'],
	'iti':['pcl'],
	'itTam':['dem_adv'],
	'idam':['dem_pron', 'dem_adj'],
	'indra':['m'],
	'iva':['pcl'],
	'iz':['vt6a'],
	'icCat':['prap'],
	'izyamARa':['prpp'],
	'ezizyat':['fap'],
	'ezizyamARa':['fpp'],
	'Izivas':['pfap'],
	'IzARa':['pfpp'],
	'izwa':['ppp'],
	'izwavat':['pap'],
	'ezwum':['inf'],
	'izwvA':['abs'],
	'ezya':['ger'],
	'ezwavya':['ger'],
	'ezaRIya':['ger'],
	'iha':['dem_adv'],
	'Ikz':['vt1m'],
	'IkzamARa':['prmp'],
	'IkzyamARa':['prpp'],
	'IkzizyamARa':['fmp', 'fpp'],
	'IkzAm':['ppf_actn'],
	'Ikzita':['ppp'],
	'Ikzitavat':['pap'],
	'Ikzitum':['inf'],
	'IkzitvA':['abs'],
	'Ikzya':['ger'],
	'Ikzitavya':['ger'],
	'IkzaRIya':['ger'],
	'ud':['preverb'],
	'udaka':['n'],
	'ud_kram':['vt1a'],
	'utkrAmat':['prap'],
	'utkrAmyat':['prap'],
	'utkramyamARa':['prpp'],
	'utkraMsyat':['fap'],
	'utkraMsyamAna':['fpp'],
	'uccakramvas':['pfap'],
	'uccakramARa':['pfpp'],
	'utkrAnta':['ppp'],
	'utkrAntavat':['pap'],
	'utkramitum':['inf'],
	'utkramya':['abs', 'ger'],
	'utkramitavya':['ger'],
	'utkramaRIya':['ger'],
	'ud_gam':['vi1a'],
	'udgacCat':['prap'],
	'udgamyamAna':['prpp'],
	'udgamizyat':['fap'],
	'udgamizyamARa':['fpp'],
	'ujjagmivas':['pfap'],
	'ujjaganvas':['pfap'],
	'ujjagmAna':['pfpp'],
	'udgata':['ppp'],
	'udgatavat':['pap'],
	'udgantum':['inf'],
	'udgamya':['abs', 'ger'],
	'udgatya':['abs'],
	'udgantavya':['ger'],
	'udgamanIya':['ger'],
	'udgAtf':['m'],
	'ud_pad':['vt4m'],
	'utpadyamAna':['prmp', 'prpp'],
	'utpatsyamAna':['fmp', 'fpp'],
	'utpedAna':['pfmp', 'pfpp'],
	'utpanna':['ppp'],
	'utpannavat':['pap'],
	'utpattum':['inf'],
	'utpadya':['abs'],
	'utpAdya':['ger'],
	'utpattavya':['ger'],
	'utpadanIya':['ger'],
	'ud_banD':['vt9am'],
	'udbaDnat':['prap'],
	'udbaDyamAna':['prpp'],
	'udBantsyat':['fap'],
	'udBantsyamAna':['fpp'],
	'udbabanDvas':['pfap'],
	'udbabanDAna':['pfpp'],
	'udbadDa':['ppp'],
	'udbadDavat':['pap'],
	'udbandDum':['inf'],
	'udbaDya':['abs'],
	'udbanDya':['ger'],
	'udbandDavya':['ger'],
	'udbanDanIya':['ger'],
	'ud_yam':['vt1am'],
	'udyacCat':['prap'],
	'udyacCamAna':['prmp'],
	'udyamyamAna':['prpp'],
	'udyaMsyat':['fap'],
	'udyaMsyamAna':['fmp', 'fpp'],
	'udyemivas':['pfap'],
	'udyemAna':['pfmp', 'pfpp'],
	'udyata':['ppp'],
	'udyatavat':['pap'],
	'udyantum':['inf'],
	'udyamya':['abs', 'ger'],
	'udyatya':['abs'],
	'udyantavya':['ger'],
	'udyamanIya':['ger'],
	'ud_sTA':['vi1am'],
	'uttizWat':['prap'],
	'uttizWamAna':['prmp'],
	'utsTIyamAna':['prpp'],
	'utTAsyat':['fap'],
	'utTAsyamAna':['fmp', 'fpp'],
	'uttasTivas':['pfap'],
	'uttasTAna':['pfmp', 'pfpp'],
	'utTita':['ppp'],
	'utTitavat':['pap'],
	'utTAtum':['inf'],
	'utTAya':['abs'],
	'utTeya':['ger'],
	'utTAtavya':['ger'],
	'utTAnIya':['ger'],
	'ud_hf':['vt1am'],
	'udDarat':['prap'],
	'udDaramARa':['prmp'],
	'udDriyamARa':['prpp'],
	'udDarizyat':['fap'],
	'udDarizyamARa':['fmp', 'fpp'],
	'ujjahfvas':['pfap'],
	'ujjahrARa':['pfmp', 'pfpp'],
	'udDfta':['ppp'],
	'udDftavat':['pap'],
	'udDartum':['inf'],
	'udDftya':['abs'],
	'udDArya':['ger'],
	'udDartavya':['ger'],
	'udDaraRIya':['ger'],
	'upa':['preverb'],
	'upa_i':['vt2a'],
	'upayat':['prap'],
	'upeyamAna':['prpp'],
	'upEzyat':['fap'],
	'upEzyamARa':['fpp'],
	'upeyivas':['pfap'],
	'upeyAna':['pfpp'],
	'upeta':['ppp'],
	'upetavat':['pap'],
	'upEtum':['inf'],
	'upetya':['abs', 'ger'],
	'upEtavya':['ger'],
	'upAyanIya':['ger'],
	'upa_kram':['vt1am'],
	'upakrAmat':['prap'],
	'upakrAmyat':['prap'],
	'upakramamARa':['prmp'],
	'upakramyamARa':['prmp', 'prpp'],
	'upakraMsyat':['fap'],
	'upakraMsyamAna':['fmp', 'fpp'],
	'upacakramvas':['pfap'],
	'upacakramARa':['pfmp', 'pfpp'],
	'upakrAnta':['ppp'],
	'upakrAntavat':['pap'],
	'upakrAntum':['inf'],
	'upakramitum':['inf'],
	'upakramya':['abs', 'ger'],
	'upakramitavya':['ger'],
	'upakramaRIya':['ger'],
	'upa_gam':['vt1a'],
	'upagacCat':['prap'],
	'upagamyamAna':['prpp'],
	'upagamizyat':['fap'],
	'upagamizyamARa':['fpp'],
	'upajagmivas':['pfap'],
	'upajaganvas':['pfap'],
	'upajagmAna':['pfpp'],
	'upagata':['ppp'],
	'upagatavat':['pap'],
	'upagantum':['inf'],
	'upagamya':['abs', 'ger'],
	'upagatya':['abs'],
	'upagantavya':['ger'],
	'upagamanIya':['ger'],
	'upa_pad':['vt4m'],
	'upapadyamAna':['prmp', 'prpp'],
	'upapatsyamAna':['fmp', 'fpp'],
	'upapedAna':['pfmp', 'pfpp'],
	'upapanna':['ppp'],
	'upapannavat':['pap'],
	'upapattum':['inf'],
	'upapadya':['abs'],
	'upapAdya':['ger'],
	'upapattavya':['ger'],
	'upapadanIya':['ger'],
	'upa_banD':['vt9am'],
	'upabaDnat':['prap'],
	'upabaDyamAna':['prpp'],
	'upaBantsyat':['fap'],
	'upaBantsyamAna':['fpp'],
	'upababanDvas':['pfap'],
	'upababanDAna':['pfpp'],
	'upabadDa':['ppp'],
	'upabadDavat':['pap'],
	'upabandDum':['inf'],
	'upabaDya':['abs'],
	'upabanDya':['ger'],
	'upabandDavya':['ger'],
	'upabanDanIya':['ger'],
	'upa_yam':['vt1am'],
	'upayacCat':['prap'],
	'upayacCamAna':['prmp'],
	'upayamyamAna':['prpp'],
	'upayaMsyat':['fap'],
	'upayaMsyamAna':['fmp', 'fpp'],
	'upayemivas':['pfap'],
	'upayemAna':['pfmp', 'pfpp'],
	'upayata':['ppp'],
	'upayatavat':['pap'],
	'upayantum':['inf'],
	'upayamya':['abs', 'ger'],
	'upayatya':['abs'],
	'upayantavya':['ger'],
	'upayamanIya':['ger'],
	'upa_viS':['vi6a'],
	'upaviSat':['prap'],
	'upaviSyamAna':['prpp'],
	'upavekzyat':['fap'],
	'upavekzyamARa':['fpp'],
	'upaviviSvas':['pfap'],
	'upaviviSivas':['pfap'],
	'upaviviSAna':['pfpp'],
	'upavizwa':['ppp'],
	'upavizwavat':['pap'],
	'upavezwum':['inf'],
	'upaviSya':['abs'],
	'upaveSya':['ger'],
	'upavezwavya':['ger'],
	'upaveSanIya':['ger'],
	'upa_sTA':['vi1am'],
	'upatizWat':['prap'],
	'upatizWamAna':['prmp'],
	'upasTIyamAna':['prpp'],
	'upasTAsyat':['fap'],
	'upasTAsyamAna':['fmp', 'fpp'],
	'upatasTivas':['pfap'],
	'upatasTAna':['pfmp', 'pfpp'],
	'upasTita':['ppp'],
	'upasTitavat':['pap'],
	'upasTAtum':['inf'],
	'upasTAya':['abs'],
	'upasTeya':['ger'],
	'upasTAtavya':['ger'],
	'upasTAnIya':['ger'],
	'upa_hf':['vt1am'],
	'upaharat':['prap'],
	'upaharamARa':['prmp'],
	'upahriyamARa':['prpp'],
	'upaharizyat':['fap'],
	'upaharizyamARa':['fmp', 'fpp'],
	'upajahfvas':['pfap'],
	'upajahrARa':['pfmp', 'pfpp'],
	'upahfta':['ppp'],
	'upahftavat':['pap'],
	'upahartum':['inf'],
	'upahftya':['abs'],
	'upahArya':['ger'],
	'upahartavya':['ger'],
	'upaharaRIya':['ger'],
	'uBa':['pron', 'pron_adj'],
	'uraRa':['m'],
	'urvaSI':['f_pn'],
	'uzas':['f'],
	'uzRa':['adj'],
	'fgveda':['m_pn'],
	'fc':['f'],
	'ftvij':['m'],
	'fzi':['m'],
	'eka':['card_num'],
	'etad':['dem_pron', 'dem_adj'],
	'etarhi':['dem_adv'],
	'etAvat':['dem_adj', 'dem_adv'],
	'enad':['dem_pron', 'dem_adj'],
	'eva':['pcl'],
	'evam':['dem_adv'],
	'kaT':['vt10am'],
	'kaTayat':['prap'],
	'kaTayamAna':['prmp'],
	'kaTyamAna':['prpp'],
	'kaTayizyat':['fap'],
	'kaTayizyamARa':['fmp', 'fpp'],
	'kaTayAm':['ppf_actn'],
	'kaTita':['ppp'],
	'kaTitavat':['pap'],
	'kaTayitum':['inf'],
	'kaTayitvA':['abs'],
	'kaTya':['ger'],
	'kaTayitavya':['ger'],
	'kaTanIya':['ger'],
	'kaTam':['int_adv'],
	'kaTA':['f'],
	'kadA':['int_adv'],
	'kanyA':['f'],
	'kam':['vt1m'],
	'kAmayamAna':['prmp'],
	'kAmyamAna':['prpp'],
	'kamizyamARa':['fmp', 'fpp'],
	'kAmayizyamARa':['fmp', 'fpp'],
	'cakamAna':['pfmp', 'pfpp'],
	'kAmayAm':['ppf_actn'],
	'kAnta':['ppp'],
	'kAntavat':['pap'],
	'kamitum':['inf'],
	'kAmayitum':['inf'],
	'kAntvA':['abs'],
	'kamitvA':['abs'],
	'kAmayitvA':['abs'],
	'kAmya':['ger'],
	'kamitavya':['ger'],
	'kAmayitavya':['ger'],
	'kamanIya':['ger'],
	'kAmanIya':['ger'],
	'karman':['n'],
	'kAma':['m'],
	'kArya':['n', 'ger'],
	'kAla':['m'],
	'kim':['int_pron', 'int_adj', 'pcl'],
	'kiyat':['int_adj', 'int_adv'],
	'kutas':['int_adv'],
	'kutra':['int_adv'],
	'kumAra':['m'],
	'kurukzetra':['n_pn'],
	'kula':['n'],
	'kuSala':['adj', 'n'],
	'kf':['vt8am'],
	'kurvat':['prap'],
	'kurvARa':['prmp'],
	'kriyamARa':['prpp'],
	'karizyat':['fap'],
	'karizyamARa':['fmp', 'fpp'],
	'cakfvas':['pfap'],
	'cakrARa':['pfmp', 'pfpp'],
	'kfta':['ppp'],
	'kftavat':['pap'],
	'kartum':['inf'],
	'kftvA':['abs'],
	'kftya':['ger'],
	'kartavya':['ger'],
	'karaRIya':['ger'],
	'kfSa':['adj'],
	'kevala':['adj'],
	'kram':['vt1am'],
	'krAmat':['prap'],
	'krAmyat':['prap'],
	'kramamARa':['prmp'],
	'kramyamARa':['prmp', 'prpp'],
	'kraMsyat':['fap'],
	'kraMsyamAna':['fmp', 'fpp'],
	'cakramvas':['pfap'],
	'cakramARa':['pfmp', 'pfpp'],
	'krAnta':['ppp'],
	'krAntavat':['pap'],
	'krAntum':['inf'],
	'kramitum':['inf'],
	'krantvA':['abs'],
	'krAntvA':['abs'],
	'kramitvA':['abs'],
	'kramya':['ger'],
	'kramitavya':['ger'],
	'kramaRIya':['ger'],
	'krI':['vt9am'],
	'krIRat':['prap'],
	'krIRAna':['prmp'],
	'krIyamARa':['prpp'],
	'krezyat':['fap'],
	'krezyamARa':['fmp', 'fpp'],
	'cikrIvas':['pfap'],
	'cikriyARa':['pfmp', 'pfpp'],
	'krIta':['ppp'],
	'krItavat':['pap'],
	'kretum':['inf'],
	'krItvA':['abs'],
	'kreya':['ger'],
	'kretavya':['ger'],
	'krayaRIya':['ger'],
	'kruD':['vi4a'],
	'kruDyat':['prap'],
	'kruDyamAna':['prpp'],
	'krotsyat':['fap'],
	'krotsyamAna':['fpp'],
	'cukruDvas':['pfap'],
	'cukruDAna':['pfpp'],
	'krudDa':['ppp'],
	'krudDavat':['pap'],
	'krodDum':['inf'],
	'krudDvA':['abs'],
	'kroDya':['ger'],
	'krodDavya':['ger'],
	'kroDanIya':['ger'],
	'kva':['int_adv'],
	'kzaRa':['m'],
	'kzatriya':['m'],
	'ganDarva':['m'],
	'gam':['vt1a'],
	'gacCat':['prap'],
	'gamyamAna':['prpp'],
	'gamizyat':['fap'],
	'gamizyamARa':['fpp'],
	'jagmivas':['pfap'],
	'jaganvas':['pfap'],
	'jagmAna':['pfpp'],
	'gata':['ppp'],
	'gatavat':['pap'],
	'gantum':['inf'],
	'gatvA':['abs'],
	'gamya':['ger'],
	'gantavya':['ger'],
	'gamanIya':['ger'],
	'garBa':['m'],
	'garBin':['adj'],
	'guru':['adj', 'm'],
	'gfha':['n', 'm'],
	'gF':['vt9a'],
	'gfRat':['prap'],
	'gIryamARa':['prpp'],
	'garizyat':['fap'],
	'garizyamARa':['fpp'],
	'jagarvas':['pfap'],
	'jagarARa':['pfpp'],
	'gIrRa':['ppp'],
	'gIrRavat':['pap'],
	'garitum':['inf'],
	'gIrtvA':['abs'],
	'grah':['vt9am'],
	'gfhRat':['prap'],
	'gfhARa':['prmp'],
	'gfhyamARa':['prpp'],
	'grahIzyat':['fap'],
	'grahIzyamARa':['fmp', 'fpp'],
	'jagfhvas':['pfap'],
	'jagfhARa':['pfmp', 'pfpp'],
	'gfhIta':['ppp'],
	'gfhItavat':['pap'],
	'grahItum':['inf'],
	'gfhItvA':['abs'],
	'grAhya':['ger'],
	'grahItavya':['ger'],
	'grahaRIya':['ger'],
	'grAma':['m'],
	'Gawa':['m'],
	'Gora':['adj'],
	'ca':['conj_pcl'],
	'catur':['card_num'],
	'caturTa':['ord_num'],
	'candra':['m', 'm_pn'],
	'car':['vt1am'],
	'carat':['prap'],
	'caramARa':['prmp'],
	'caryamARa':['prpp'],
	'carizyat':['fap'],
	'carizyamARa':['fmp', 'fpp'],
	'cerivas':['pfap'],
	'cerARa':['pfmp', 'pfpp'],
	'carita':['ppp'],
	'caritavat':['pap'],
	'caritum':['inf'],
	'caritvA':['abs'],
	'carya':['ger'],
	'caritavya':['ger'],
	'caraRIya':['ger'],
	'cArin':['adj'],
	'cit':['pcl'],
	'citra':['adj'],
	'cint':['vt10am'],
	'cintayat':['prap'],
	'cintayamAna':['prmp'],
	'cintyamAna':['prpp'],
	'cintayizyat':['fap'],
	'cintayizyamARa':['fmp', 'fpp'],
	'cintayAm':['ppf_actn'],
	'cintita':['ppp'],
	'cintitavat':['pap'],
	'cintitum':['inf'],
	'cintitvA':['abs'],
	'cintya':['ger'],
	'cintitavya':['ger'],
	'cintanIya':['ger'],
	'cira':['adj'],
	'cur':['vt10a'],
	'corayat':['prap'],
	'coryamARa':['prpp'],
	'corayizyat':['fap'],
	'corayizyamARa':['fpp'],
	'corayAm':['ppf_actn'],
	'corita':['ppp'],
	'coritavat':['pap'],
	'corayitum':['inf'],
	'corayitvA':['abs'],
	'cOra':['m'],
	'Cid':['vt7am'],
	'Cindat':['prap'],
	'CindAna':['prmp'],
	'CidyamAna':['prpp'],
	'Cetsyat':['fap'],
	'CetsyamAna':['fmp', 'fpp'],
	'cicCidvas':['pfap'],
	'cicCidAna':['pfmp', 'pfpp'],
	'Cinna':['ppp'],
	'Cinnavat':['pap'],
	'Cettum':['inf'],
	'CittvA':['abs'],
	'Cedya':['ger'],
	'Cettavya':['ger'],
	'CedanIya':['ger'],
	'jagat':['n'],
	'jan':['vi4m'],
	'jAyamAna':['prmp', 'prpp'],
	'janizyamARa':['fmp', 'fpp'],
	'jajYAna':['pfmp', 'pfpp'],
	'jAta':['ppp'],
	'jAtavat':['pap'],
	'janitum':['inf'],
	'janitvA':['abs'],
	'janya':['ger'],
	'janitavya':['ger'],
	'jananIya':['ger'],
	'janayAm':['ppf_actn'],
	'jana':['m'],
	'ji':['vt1a'],
	'jayat':['prap'],
	'jIyamAna':['prpp'],
	'jezyat':['fap'],
	'jezyamARa':['fpp'],
	'jigivas':['pfap'],
	'jigyAna':['pfpp'],
	'jita':['ppp'],
	'jitavat':['pap'],
	'jetum':['inf'],
	'jitvA':['abs'],
	'jeya':['ger'],
	'jetavya':['ger'],
	'jayanIya':['ger'],
	'jIv':['vi1a'],
	'jIvat':['prap'],
	'jIvyamAna':['prpp'],
	'jIvizyat':['fap'],
	'jIvizyamARa':['fpp'],
	'jijIvas':['pfap'],
	'jijIvAna':['pfpp'],
	'jIvita':['ppp'],
	'jIvitavat':['pap'],
	'jIvitum':['inf'],
	'jIvitvA':['abs'],
	'jIvya':['ger'],
	'jIvitavya':['ger'],
	'jIvanIya':['ger'],
	'jF':['vi4a'],
	'jIryat':['prap'],
	'jIryamARa':['prpp'],
	'jarizyat':['fap'],
	'jarizyamARa':['fpp'],
	'jajarvas':['pfap'],
	'jerivas':['pfap'],
	'jajarARa':['pfpp'],
	'jerARa':['pfpp'],
	'jIrRa':['ppp'],
	'jIrRavat':['pap'],
	'jaritum':['inf'],
	'jarItum':['inf'],
	'jaritvA':['abs'],
	'jarItvA':['abs'],
	'jarya':['ger'],
	'jArya':['ger'],
	'jaritavya':['ger'],
	'jarItavya':['ger'],
	'jaraRIya':['ger'],
	'jYA':['vt9a'],
	'jAnat':['prap'],
	'jYAyamAna':['prpp'],
	'jYAsyat':['fap'],
	'jYAsyamAna':['fpp'],
	'jajYivas':['pfap'],
	'jYAta':['ppp'],
	'jYAtavat':['pap'],
	'jYAtum':['inf'],
	'jYAtvA':['abs'],
	'jYeya':['ger'],
	'jYAtavya':['ger'],
	'jYAnIya':['ger'],
	'jYAna':['n'],
	'tatas':['dem_adv'],
	'tatra':['dem_adv'],
	'taTA':['dem_adv'],
	'tad':['dem_pron', 'dem_adj', 'dem_adv'],
	'tadA':['dem_adv'],
	'tan':['vt8am'],
	'tanvat':['prap'],
	'tanvAna':['prmp'],
	'tanyamAna':['prpp'],
	'taMsyat':['fap'],
	'tanizyat':['fap'],
	'taMsyamAna':['fmp', 'fpp'],
	'tanizyamARa':['fmp', 'fpp'],
	'tenivas':['pfap'],
	'tenAna':['pfmp', 'pfpp'],
	'tata':['ppp'],
	'tatavat':['pap'],
	'tanitum':['inf'],
	'tanitvA':['abs'],
	'tAnya':['ger'],
	'tanitavya':['ger'],
	'tananIya':['ger'],
	'tarhi':['dem_adv'],
	'tAvat':['dem_adj', 'dem_adv'],
	'tiras':['preverb'],
	'tiras_BU':['vi1a'],
	'tiroBavat':['prap'],
	'tiroBUyamAna':['prpp'],
	'tiroBavizyat':['fap'],
	'tiroBavizyamARa':['fpp'],
	'tirobaBUvas':['pfap'],
	'tiroBUta':['ppp'],
	'tiroBUtavat':['pap'],
	'tiroBavitum':['inf'],
	'tiroBUya':['abs'],
	'tiroBavya':['ger'],
	'tiroBavitavya':['ger'],
	'tiroBavanIya':['ger'],
	'tu':['conj_pcl'],
	'tftIya':['ord_num'],
	'tyaj':['vt1a'],
	'tyajat':['prap'],
	'tyajyamAna':['prpp'],
	'tyakzyat':['fap'],
	'tyakzyamARa':['fpp'],
	'tatyajvas':['pfap'],
	'tityajvas':['pfap'],
	'tatyajAna':['pfpp'],
	'tityajAna':['pfpp'],
	'tyakta':['ppp'],
	'tyaktavat':['pap'],
	'tyaktum':['inf'],
	'tyaktvA':['abs'],
	'tyAjya':['ger'],
	'tyaktavya':['ger'],
	'tyajanIya':['ger'],
	'tri':['card_num'],
	'trE':['vt1m'],
	'trAyamARa':['prmp', 'prpp'],
	'trAsyamAna':['fmp', 'fpp'],
	'tatrARa':['pfmp', 'pfpp'],
	'trAta':['ppp'],
	'trARa':['ppp'],
	'trAtavat':['pap'],
	'trAtum':['inf'],
	'trAtvA':['abs'],
	'danta':['m'],
	'daSan':['card_num'],
	'daSama':['ord_num'],
	'dah':['vt1a'],
	'dahat':['prap'],
	'dahyamAna':['prpp'],
	'Dakzyat':['fap'],
	'DakzyamARa':['fpp'],
	'dadahvas':['pfap'],
	'dadahAna':['pfpp'],
	'dagDa':['ppp'],
	'dagDavat':['pap'],
	'dagDum':['inf'],
	'dagDvA':['abs'],
	'dAhya':['ger'],
	'dagDavya':['ger'],
	'dahanIya':['ger'],
	'dA':['vt3am'],
	'dadat':['prap'],
	'dadAna':['prmp', 'pfmp', 'pfpp'],
	'dIyamAna':['prpp'],
	'dAsyat':['fap'],
	'dAsyamAna':['fmp', 'fpp'],
	'dadivas':['pfap'],
	'datta':['ppp'],
	'dattavat':['pap'],
	'dAtum':['inf'],
	'dattvA':['abs'],
	'deya':['ger'],
	'dAtavya':['ger'],
	'dAnIya':['ger'],
	'dina':['n'],
	'div':['m', 'f', 'vt4a'],
	'dIvyat':['prap'],
	'dIvyamAna':['prpp'],
	'devizyat':['fap'],
	'devizyamARa':['fpp'],
	'didivas':['pfap'],
	'didivAna':['pfpp'],
	'dyUta':['ppp'],
	'dyUtavat':['pap'],
	'devitum':['inf'],
	'dyUtvA':['abs'],
	'devitvA':['abs'],
	'devya':['ger'],
	'devitavya':['ger'],
	'devanIya':['ger'],
	'diS':['f'],
	'dIrGa':['adj'],
	'dIrGAyus':['adj'],
	'duHKa':['adj', 'n'],
	'duHKin':['adj'],
	'durApa':['adj'],
	'durmanas':['adj'],
	'duzzanta':['m_pn'],
	'dus':['preverb'],
	'duhitf':['f'],
	'dUra':['adj'],
	'dfS':['vt1a'],
	'paSyat':['prap'],
	'dfSyamAna':['prpp'],
	'drakzyat':['fap'],
	'drakzyamARa':['fpp'],
	'dadfSvas':['pfap'],
	'dadfSivas':['pfap'],
	'dadfSAna':['pfpp'],
	'dfzwa':['ppp'],
	'dfzwavat':['pap'],
	'drazwum':['inf'],
	'dfzwvA':['abs'],
	'dfSya':['ger'],
	'drazwavya':['ger'],
	'darSanIya':['ger'],
	'deva':['m'],
	'devI':['f'],
	'deSa':['m'],
	'deha':['m'],
	'dvi':['card_num'],
	'dvitIya':['ord_num'],
	'Dana':['n'],
	'Danin':['adj'],
	'Danus':['n'],
	'Darma':['m'],
	'DA':['vt3am'],
	'daDat':['prap'],
	'daDAna':['prmp', 'pfmp', 'pfpp'],
	'DIyamAna':['prpp'],
	'DAsyat':['fap'],
	'DAsyamAna':['fmp', 'fpp'],
	'daDivas':['pfap'],
	'hita':['ppp'],
	'hitavat':['pap'],
	'DAtum':['inf'],
	'hitvA':['abs'],
	'Deya':['ger'],
	'DAtavya':['ger'],
	'DAnIya':['ger'],
	'DArtarAzwra':['m'],
	'DI':['f'],
	'DImat':['adj'],
	'Denu':['f'],
	'na':['neg_pcl'],
	'nagara':['n'],
	'nagna':['adj'],
	'nadI':['f'],
	'nara':['m'],
	'navan':['card_num'],
	'navama':['ord_num'],
	'nAma':['adv'],
	'nAman':['n'],
	'ni':['preverb'],
	'ni_DA':['vt3am'],
	'nidaDat':['prap'],
	'nidaDAna':['prmp', 'pfmp', 'pfpp'],
	'niDIyamAna':['prpp'],
	'niDAsyat':['fap'],
	'niDAsyamAna':['fmp', 'fpp'],
	'nidaDivas':['pfap'],
	'nihita':['ppp'],
	'nihitavat':['pap'],
	'niDAtum':['inf'],
	'niDAya':['abs'],
	'niDeya':['ger'],
	'niDAtavya':['ger'],
	'niDAnIya':['ger'],
	'nis':['preverb'],
	'nis_kram':['vt1a'],
	'nizkrAmat':['prap'],
	'nizkrAmyat':['prap'],
	'nizkramyamARa':['prpp'],
	'nizkraMsyat':['fap'],
	'nizkraMsyamAna':['fpp'],
	'niScakramvas':['pfap'],
	'niScakramARa':['pfpp'],
	'nizkrAnta':['ppp'],
	'nizkrAntavat':['pap'],
	'nizkramitum':['inf'],
	'nizkramya':['abs', 'ger'],
	'nizkramitavya':['ger'],
	'nizkramaRIya':['ger'],
	'nis_gam':['vt1a'],
	'nirgacCat':['prap'],
	'nirgamyamAna':['prpp'],
	'nirgamizyat':['fap'],
	'nirgamizyamARa':['fpp'],
	'nirjagmivas':['pfap'],
	'nirjaganvas':['pfap'],
	'nirjagmAna':['pfpp'],
	'nirgata':['ppp'],
	'nirgatavat':['pap'],
	'nirgantum':['inf'],
	'nirgamya':['abs', 'ger'],
	'nirgatya':['abs'],
	'nirgantavya':['ger'],
	'nirgamanIya':['ger'],
	'nI':['vt1am'],
	'nayat':['prap'],
	'nayamAna':['prmp'],
	'nIyamAna':['prpp'],
	'nezyat':['fap'],
	'nezyamARa':['fmp', 'fpp'],
	'ninIvas':['pfap'],
	'ninyAna':['pfmp', 'pfpp'],
	'nIta':['ppp'],
	'nItavat':['pap'],
	'netum':['inf'],
	'nItvA':['abs'],
	'neya':['ger'],
	'netavya':['ger'],
	'nayanIya':['ger'],
	'nfpa':['m'],
	'pakza':['m'],
	'pac':['vt1am'],
	'pacat':['prap'],
	'pacamAna':['prmp'],
	'pacyamAna':['prpp'],
	'pakzyat':['fap'],
	'pakzyamARa':['fmp', 'fpp'],
	'pecivas':['pfap'],
	'pecAna':['pfmp', 'pfpp'],
	'pakva':['ppp'],
	'pakvavat':['pap'],
	'paktum':['inf'],
	'paktvA':['abs'],
	'pAkya':['ger'],
	'paktavya':['ger'],
	'pacanIya':['ger'],
	'paYcan':['card_num'],
	'paYcama':['ord_num'],
	'paW':['vt1a'],
	'paWat':['prap'],
	'paWyamAna':['prpp'],
	'paWizyat':['fap'],
	'paWizyamARa':['fpp'],
	'peWivas':['pfap'],
	'peWAna':['pfpp'],
	'paWita':['ppp'],
	'paWitavat':['pap'],
	'paWitum':['inf'],
	'paWitvA':['abs'],
	'pAWya':['ger'],
	'paWitavya':['ger'],
	'paWanIya':['ger'],
	'pat':['vt1a'],
	'patat':['prap'],
	'patyamAna':['prpp'],
	'patizyat':['fap'],
	'patizyamARa':['fpp'],
	'petivas':['pfap'],
	'petAna':['pfpp'],
	'patita':['ppp'],
	'patitavat':['pap'],
	'patitum':['inf'],
	'patitvA':['abs'],
	'pAtya':['ger'],
	'patitavya':['ger'],
	'patanIya':['ger'],
	'pati':['m'],
	'patnI':['f'],
	'pad':['vt4m'],
	'padyamAna':['prmp', 'prpp'],
	'patsyamAna':['fmp', 'fpp'],
	'pedAna':['pfmp', 'pfpp'],
	'panna':['ppp'],
	'pannavat':['pap'],
	'pattum':['inf'],
	'pattvA':['abs'],
	'pAdya':['ger'],
	'pattavya':['ger'],
	'padanIya':['ger'],
	'pada':['n'],
	'para':['pron', 'pron_adj'],
	'param':['adv'],
	'pari':['preverb'],
	'pari_car':['vt1am'],
	'paricarat':['prap'],
	'paricaramARa':['prmp'],
	'paricaryamARa':['prpp'],
	'paricarizyat':['fap'],
	'paricarizyamARa':['fmp', 'fpp'],
	'paricerivas':['pfap'],
	'paricerARa':['pfmp', 'pfpp'],
	'paricarita':['ppp'],
	'paricaritavat':['pap'],
	'paricaritum':['inf'],
	'paricarya':['abs', 'ger'],
	'paricaritavya':['ger'],
	'paricaraRIya':['ger'],
	'pari_DA':['vt3am'],
	'paridaDat':['prap'],
	'paridaDAna':['prmp', 'pfmp', 'pfpp'],
	'pariDIyamAna':['prpp'],
	'pariDAsyat':['fap'],
	'pariDAsyamAna':['fmp', 'fpp'],
	'paridaDivas':['pfap'],
	'parihita':['ppp'],
	'parihitavat':['pap'],
	'pariDAtum':['inf'],
	'pariDAya':['abs'],
	'pariDeya':['ger'],
	'pariDAtavya':['ger'],
	'pariDAnIya':['ger'],
	'parvata':['m'],
	'paSu':['m'],
	'paScAt':['adv'],
	'pA':['vt1a'],
	'pibat':['prap'],
	'pIyamAna':['prpp'],
	'pAsyat':['fap'],
	'pAsyamAna':['fpp'],
	'papivas':['pfap'],
	'papAna':['pfpp'],
	'pIta':['ppp'],
	'pItavat':['pap'],
	'pAtum':['inf'],
	'pItvA':['abs'],
	'peya':['ger'],
	'pAtavya':['ger'],
	'pAnIya':['ger'],
	'pARqava':['m'],
	'pAwaliputra':['n_pn'],
	'pAda':['m'],
	'pApa':['adj', 'n'],
	'pArvatI':['f_pn'],
	'pAl':['vt10am'],
	'pAlayat':['prap'],
	'pAlayamAna':['prmp'],
	'pAlyamAna':['prpp'],
	'pAlayizyat':['fap'],
	'pAlayizyamARa':['fmp', 'fpp'],
	'pAlayAm':['ppf_actn'],
	'pAlita':['ppp'],
	'pAlitavat':['pap'],
	'pAlayitum':['inf'],
	'pAlayitvA':['abs'],
	'pAlya':['ger'],
	'pAlitavya':['ger'],
	'pAlanIya':['ger'],
	'pitAmaha':['m'],
	'pitf':['m'],
	'putra':['m'],
	'putrI':['f'],
	'punar':['adv'],
	'purA':['adv'],
	'puruza':['m'],
	'purUravas':['m_pn'],
	'puzpa':['n'],
	'pU':['vt9am'],
	'punat':['prap'],
	'punAna':['prmp'],
	'pUyamAna':['prpp'],
	'pavizyat':['fap'],
	'pavizyamARa':['fmp', 'fpp'],
	'pupUvas':['pfap'],
	'pupuvAna':['pfmp', 'pfpp'],
	'pUta':['ppp'],
	'pavita':['ppp'],
	'pUtavat':['pap'],
	'pavitavat':['pap'],
	'pavitum':['inf'],
	'pUtvA':['abs'],
	'pavitvA':['abs'],
	'pavya':['ger'],
	'pavitavya':['ger'],
	'pavanIya':['ger'],
	'pUj':['vt10m'],
	'pUjayamAna':['prmp'],
	'pUjyamAna':['prpp'],
	'pUjayizyamARa':['fmp', 'fpp'],
	'pUjayAm':['ppf_actn'],
	'pUjita':['ppp'],
	'pUjitavat':['pap'],
	'pUjayitum':['inf'],
	'pUjayitvA':['abs'],
	'pUjya':['ger'],
	'pUjitavya':['ger'],
	'pUjanIya':['ger'],
	'pUjA':['f'],
	'pUrRa':['adj', 'ppp'],
	'pUrva':['pron', 'pron_adj'],
	'pUrvam':['adv'],
	'pfTivI':['f'],
	'pfTu':['adj'],
	'pF':['vt9a'],
	'pfRat':['prap'],
	'pUryamARa':['prpp'],
	'parizyat':['fap'],
	'parIzyat':['fap'],
	'parizyamARa':['fpp'],
	'parIzyamARa':['fpp'],
	'papFvas':['pfap'],
	'paparvas':['pfap'],
	'paprARa':['pfpp'],
	'paparARa':['pfpp'],
	'pUrta':['ppp'],
	'pUrtavat':['pap'],
	'pUritum':['inf'],
	'pUrtvA':['abs'],
	'pUrya':['ger'],
	'pUritavya':['ger'],
	'pUraRIya':['ger'],
	'pra':['preverb'],
	'pra_ah':['vta'],
	'pra_Ap':['vt5a'],
	'prApnuvat':['prap'],
	'prApyamAna':['prpp'],
	'prApsyat':['fap'],
	'prApsyamAna':['fpp'],
	'prApivas':['pfap'],
	'prApARa':['pfpp'],
	'prApta':['ppp'],
	'prAptavat':['pap'],
	'prAptum':['inf'],
	'prApya':['abs', 'ger'],
	'prAptavya':['ger'],
	'prApaRIya':['ger'],
	'pra_kram':['vt1am'],
	'prakrAmat':['prap'],
	'prakrAmyat':['prap'],
	'prakramamARa':['prmp'],
	'prakramyamARa':['prmp', 'prpp'],
	'prakraMsyat':['fap'],
	'prakraMsyamAna':['fmp', 'fpp'],
	'pracakramvas':['pfap'],
	'pracakramARa':['pfmp', 'pfpp'],
	'prakrAnta':['ppp'],
	'prakrAntavat':['pap'],
	'prakrAntum':['inf'],
	'prakramitum':['inf'],
	'prakramya':['abs', 'ger'],
	'prakramitavya':['ger'],
	'prakramaRIya':['ger'],
	'pra_gam':['vt1a'],
	'pragacCat':['prap'],
	'pragamyamAna':['prpp'],
	'pragamizyat':['fap'],
	'pragamizyamARa':['fpp'],
	'prajagmivas':['pfap'],
	'prajaganvas':['pfap'],
	'prajagmAna':['pfpp'],
	'pragata':['ppp'],
	'pragatavat':['pap'],
	'pragantum':['inf'],
	'pragamya':['abs', 'ger'],
	'pragatya':['abs'],
	'pragantavya':['ger'],
	'pragamanIya':['ger'],
	'pracC':['vt6a'],
	'pfcCat':['prap'],
	'pfcCyamAna':['prpp'],
	'prakzyat':['fap'],
	'prakzyamARa':['fpp'],
	'papracCvas':['pfap'],
	'papracCAna':['pfpp'],
	'pfzwa':['ppp'],
	'pfzwavat':['pap'],
	'prazwum':['inf'],
	'pfzwvA':['abs'],
	'pfcCya':['ger'],
	'prazwavya':['ger'],
	'pracCanIya':['ger'],
	'prati':['preverb'],
	'prati_aBi_jYA':['vt9a'],
	'pratyaBijAnat':['prap'],
	'pratyaBijYAyamAna':['prpp'],
	'pratyaBijYAsyat':['fap'],
	'pratyaBijYAsyamAna':['fpp'],
	'pratyaBijajYivas':['pfap'],
	'pratyaBijajYAna':['pfpp'],
	'pratyaBijYAta':['ppp'],
	'pratyaBijYAtavat':['pap'],
	'pratyaBijYAtum':['inf'],
	'pratyaBijYAya':['abs'],
	'pratyaBijYeya':['ger'],
	'pratyaBijYAtavya':['ger'],
	'pratyaBijYAnIya':['ger'],
	'prati_ah':['vta'],
	'prati_A_i':['vt2a'],
	'pratyAyat':['prap'],
	'pratyeyamAna':['prpp'],
	'pratyEzyat':['fap'],
	'pratyEzyamARa':['fpp'],
	'pratyeyivas':['pfap'],
	'pratyeyAna':['pfpp'],
	'pratyeta':['ppp'],
	'pratyetavat':['pap'],
	'pratyEtum':['inf'],
	'pratyetya':['abs', 'ger'],
	'pratyEtavya':['ger'],
	'pratyAyanIya':['ger'],
	'prati_A_gam':['vt1a'],
	'pratyAgacCat':['prap'],
	'pratyAgamyamAna':['prpp'],
	'pratyAgamizyat':['fap'],
	'pratyAgamizyamARa':['fpp'],
	'pratyAjagmivas':['pfap'],
	'pratyAjaganvas':['pfap'],
	'pratyAjagmAna':['pfpp'],
	'pratyAgata':['ppp'],
	'pratyAgatavat':['pap'],
	'pratyAgantum':['inf'],
	'pratyAgamya':['abs', 'ger'],
	'pratyAgatya':['abs'],
	'pratyAgantavya':['ger'],
	'pratyAgamanIya':['ger'],
	'prati_A_nI':['vt1am'],
	'pratyAnayat':['prap'],
	'pratyAnayamAna':['prmp'],
	'pratyAnIyamAna':['prpp'],
	'pratyAnezyat':['fap'],
	'pratyAnezyamARa':['fmp', 'fpp'],
	'pratyAninIvas':['pfap'],
	'pratyAninyAna':['pfmp', 'pfpp'],
	'pratyAnIta':['ppp'],
	'pratyAnItavat':['pap'],
	'pratyAnetum':['inf'],
	'pratyAnIya':['abs'],
	'pratyAneya':['ger'],
	'pratyAnetavya':['ger'],
	'pratyAnayanIya':['ger'],
	'prati_A_hf':['vt1am'],
	'pratyAharat':['prap'],
	'pratyAharamARa':['prmp'],
	'pratyAhriyamARa':['prpp'],
	'pratyAharizyat':['fap'],
	'pratyAharizyamARa':['fmp', 'fpp'],
	'pratyAjahfvas':['pfap'],
	'pratyAjahrARa':['pfmp', 'pfpp'],
	'pratyAhfta':['ppp'],
	'pratyAhftavat':['pap'],
	'pratyAhartum':['inf'],
	'pratyAhftya':['abs'],
	'pratyAhArya':['ger'],
	'pratyAhartavya':['ger'],
	'pratyAharaRIya':['ger'],
	'prati_i':['vt2a'],
	'pratiyat':['prap'],
	'pratIyamAna':['prpp'],
	'pratyezyat':['fap'],
	'pratyezyamARa':['fpp'],
	'pratIyivas':['pfap'],
	'pratIyAna':['pfpp'],
	'pratIta':['ppp'],
	'pratItavat':['pap'],
	'pratyetum':['inf'],
	'pratItya':['abs', 'ger'],
	'pratItavya':['ger'],
	'pratyayanIya':['ger'],
	'prati_kram':['vt1a'],
	'pratikrAmat':['prap'],
	'pratikrAmyat':['prap'],
	'pratikramyamARa':['prpp'],
	'pratikraMsyat':['fap'],
	'pratikraMsyamAna':['fpp'],
	'praticakramvas':['pfap'],
	'praticakramARa':['pfpp'],
	'pratikrAnta':['ppp'],
	'pratikrAntavat':['pap'],
	'pratikramitum':['inf'],
	'pratikramya':['abs', 'ger'],
	'pratikramitavya':['ger'],
	'pratikramaRIya':['ger'],
	'prati_gam':['vt1a'],
	'pratigacCat':['prap'],
	'pratigamyamAna':['prpp'],
	'pratigamizyat':['fap'],
	'pratigamizyamARa':['fpp'],
	'pratijagmivas':['pfap'],
	'pratijaganvas':['pfap'],
	'pratijagmAna':['pfpp'],
	'pratigata':['ppp'],
	'pratigatavat':['pap'],
	'pratigantum':['inf'],
	'pratigamya':['abs', 'ger'],
	'pratigatya':['abs'],
	'pratigantavya':['ger'],
	'pratigamanIya':['ger'],
	'pratijYA':['f'],
	'prati_jYA':['vt9m'],
	'pratijAnAna':['prmp'],
	'pratijYAyamAna':['prpp'],
	'pratijYAsyamAna':['fmp', 'fpp'],
	'pratijajYAna':['pfmp', 'pfpp'],
	'pratijYAta':['ppp'],
	'pratijYAtavat':['pap'],
	'pratijYAtum':['inf'],
	'pratijYAya':['abs'],
	'pratijYeya':['ger'],
	'pratijYAtavya':['ger'],
	'pratijYAnIya':['ger'],
	'prati_brU':['vt2am'],
	'pratibruvat':['prap'],
	'pratibruvARa':['prmp'],
	'prati_BAz':['vt1m'],
	'pratiBAzamARa':['prmp'],
	'pratiBAzyamARa':['prpp'],
	'pratiBAzizyamARa':['fmp', 'fpp'],
	'pratibaBAzARa':['pfmp', 'pfpp'],
	'pratiBAzita':['ppp'],
	'pratiBAzitavat':['pap'],
	'pratiBAzitum':['inf'],
	'pratiBAzya':['abs', 'ger'],
	'pratiBAzitavya':['ger'],
	'pratiBAzaRIya':['ger'],
	'prati_laB':['vt1m'],
	'pratilaBamAna':['prmp'],
	'pratilaByamAna':['prpp'],
	'pratilapsyamAna':['fmp', 'fpp'],
	'pratileBAna':['pfmp', 'pfpp'],
	'pratilabDa':['ppp'],
	'pratilabDavat':['pap'],
	'pratilabDum':['inf'],
	'pratilaBya':['abs', 'ger'],
	'pratilabDavya':['ger'],
	'pratilaBanIya':['ger'],
	'prati_vac':['vt2a'],
	'pratyucyamAna':['prpp'],
	'prativakzyat':['fap'],
	'prativakzyamARa':['fpp'],
	'pratyUcivas':['pfap'],
	'pratyUcAna':['pfpp'],
	'pratyukta':['ppp'],
	'pratyuktavat':['pap'],
	'prativaktum':['inf'],
	'pratyucya':['abs'],
	'prativAkya':['ger'],
	'prativaktavya':['ger'],
	'prativacanIya':['ger'],
	'prati_vad':['vt1a'],
	'prativadat':['prap'],
	'pratyudyamAna':['prpp'],
	'prativadizyat':['fap'],
	'prativadizyamARa':['fpp'],
	'pratyUdivas':['pfap'],
	'pratyUdAna':['pfpp'],
	'pratyudita':['ppp'],
	'pratyuditavat':['pap'],
	'prativaditum':['inf'],
	'pratyudya':['abs'],
	'prativAdya':['ger'],
	'prativaditavya':['ger'],
	'prativadanIya':['ger'],
	'prati_sTA':['vt1a'],
	'pratitizWat':['prap'],
	'pratizWIyamAna':['prpp'],
	'pratizWAsyat':['fap'],
	'pratizWAsyamAna':['fpp'],
	'pratitazWivas':['pfap'],
	'pratitazWAna':['pfpp'],
	'pratizWita':['ppp'],
	'pratizWitavat':['pap'],
	'pratizWAtum':['inf'],
	'pratizWAya':['abs'],
	'pratizWeya':['ger'],
	'pratizWAtavya':['ger'],
	'pratizWAnIya':['ger'],
	'prati_hf':['vt1am'],
	'pratiharat':['prap'],
	'pratiharamARa':['prmp'],
	'pratihriyamARa':['prpp'],
	'pratiharizyat':['fap'],
	'pratiharizyamARa':['fmp', 'fpp'],
	'pratijahfvas':['pfap'],
	'pratijahrARa':['pfmp', 'pfpp'],
	'pratihfta':['ppp'],
	'pratihftavat':['pap'],
	'pratihartum':['inf'],
	'pratihftya':['abs'],
	'pratihArya':['ger'],
	'pratihartavya':['ger'],
	'pratiharaRIya':['ger'],
	'pratyaBijYA':['f'],
	'praTama':['ord_num'],
	'pra_dA':['vt3am'],
	'pradadat':['prap'],
	'pradadAna':['prmp', 'pfmp', 'pfpp'],
	'pradIyamAna':['prpp'],
	'pradAsyat':['fap'],
	'pradAsyamAna':['fmp', 'fpp'],
	'pradadivas':['pfap'],
	'pratta':['ppp'],
	'pradattavat':['pap'],
	'pradAtum':['inf'],
	'pradAya':['abs'],
	'pradeya':['ger'],
	'pradAtavya':['ger'],
	'pradAnIya':['ger'],
	'praDAna':['adj'],
	'pra_pat':['vt1a'],
	'prapatat':['prap'],
	'prapatyamAna':['prpp'],
	'prapatizyat':['fap'],
	'prapatizyamARa':['fpp'],
	'prapetivas':['pfap'],
	'prapetAna':['pfpp'],
	'prapatita':['ppp'],
	'prapatitavat':['pap'],
	'prapatitum':['inf'],
	'prapatya':['abs'],
	'prapAtya':['ger'],
	'prapatitavya':['ger'],
	'prapatanIya':['ger'],
	'pra_brU':['vt2am'],
	'prabruvat':['prap'],
	'prabruvARa':['prmp'],
	'pramAda':['m'],
	'pra_yam':['vt1a'],
	'prayacCat':['prap'],
	'prayamyamAna':['prpp'],
	'prayaMsyat':['fap'],
	'prayaMsyamAna':['fpp'],
	'prayemivas':['pfap'],
	'prayemAna':['pfpp'],
	'prayata':['ppp'],
	'prayatavat':['pap'],
	'prayantum':['inf'],
	'prayamya':['abs', 'ger'],
	'prayatya':['abs'],
	'prayantavya':['ger'],
	'prayamaRIya':['ger'],
	'pra_viS':['vt6a'],
	'praviSat':['prap'],
	'praviSyamAna':['prpp'],
	'pravekzyat':['fap'],
	'pravekzyamARa':['fpp'],
	'praviviSvas':['pfap'],
	'praviviSivas':['pfap'],
	'praviviSAna':['pfpp'],
	'pravizwa':['ppp'],
	'pravizwavat':['pap'],
	'pravezwum':['inf'],
	'praviSya':['abs'],
	'praveSya':['ger'],
	'pravezwavya':['ger'],
	'praveSanIya':['ger'],
	'prasTa':['adj'],
	'pra_sTA':['vt1m'],
	'pratizWamAna':['prmp'],
	'prasTIyamAna':['prpp'],
	'prasTAsyamAna':['fmp', 'fpp'],
	'pratasTAna':['pfmp', 'pfpp'],
	'prasTita':['ppp'],
	'prasTitavat':['pap'],
	'prasTAtum':['inf'],
	'prasTAya':['abs'],
	'prasTeya':['ger'],
	'prasTAtavya':['ger'],
	'prasTAnIya':['ger'],
	'pra_hf':['vt1am'],
	'praharat':['prap'],
	'praharamARa':['prmp'],
	'prahriyamARa':['prpp'],
	'praharizyat':['fap'],
	'praharizyamARa':['fmp', 'fpp'],
	'prajahfvas':['pfap'],
	'prajahrARa':['pfmp', 'pfpp'],
	'prahfta':['ppp'],
	'prahftavat':['pap'],
	'prahartum':['inf'],
	'prahftya':['abs'],
	'prahArya':['ger'],
	'prahartavya':['ger'],
	'praharaRIya':['ger'],
	'prAtar':['adv'],
	'priya':['adj'],
	'plu':['vt1m'],
	'plavamAna':['prmp'],
	'plozyamARa':['fmp', 'fpp'],
	'pupluvAna':['pfmp', 'pfpp'],
	'pluta':['ppp'],
	'plutavat':['pap'],
	'plavitum':['inf'],
	'plutvA':['abs'],
	'plavya':['ger'],
	'plavitavya':['ger'],
	'plavanIya':['ger'],
	'Pala':['n'],
	'Palavat':['adj'],
	'banD':['vt9am'],
	'baDnat':['prap'],
	'baDyamAna':['prpp'],
	'Bantsyat':['fap'],
	'BantsyamAna':['fpp'],
	'babanDvas':['pfap'],
	'babanDAna':['pfpp'],
	'badDa':['ppp'],
	'badDavat':['pap'],
	'bandDum':['inf'],
	'badDvA':['abs'],
	'banDya':['ger'],
	'bandDavya':['ger'],
	'banDanIya':['ger'],
	'bala':['n'],
	'balavat':['adj'],
	'balin':['adj'],
	'bahu':['adj', 'adv'],
	'bAla':['m'],
	'budDi':['f'],
	'buD':['vt1am', 'vt4m'],
	'boDat':['prap'],
	'boDamAna':['prmp'],
	'buDyamAna':['prpp', 'prmp'],
	'Botsyat':['fap'],
	'BotsyamAna':['fmp', 'fpp'],
	'bubuDvas':['pfap'],
	'bubuDAna':['pfmp', 'pfpp'],
	'buDita':['ppp'],
	'buDitavat':['pap'],
	'boDitum':['inf'],
	'buDitvA':['abs'],
	'boDitvA':['abs'],
	'boDya':['ger'],
	'boDitavya':['ger'],
	'boDanIya':['ger'],
	'budDa':['ppp'],
	'budDavat':['pap'],
	'bodDum':['inf'],
	'budDvA':['abs'],
	'bodDavya':['ger'],
	'brAhmaRa':['m'],
	'brU':['vt2am'],
	'bruvat':['prap'],
	'bruvARa':['prmp'],
	'Bakz':['vt10a'],
	'Bakzayat':['prap'],
	'BakzyamARa':['prpp'],
	'Bakzayizyat':['fap'],
	'BakzayizyamARa':['fpp'],
	'BakzayAm':['ppf_actn'],
	'Bakzita':['ppp'],
	'Bakzitavat':['pap'],
	'Bakzayitum':['inf'],
	'BakzayitvA':['abs'],
	'Bakzya':['ger'],
	'Bakzitavya':['ger'],
	'BakzaRIya':['ger'],
	'Baya':['n'],
	'Barata':['m_pn'],
	'Bavat':['pers_pron', 'prap'],
	'BAz':['vt1m'],
	'BAzamARa':['prmp'],
	'BAzyamARa':['prpp'],
	'BAzizyamARa':['fmp', 'fpp'],
	'baBAzARa':['pfmp', 'pfpp'],
	'BAzita':['ppp'],
	'BAzitavat':['pap'],
	'BAzitum':['inf'],
	'BAzitvA':['abs'],
	'BAzya':['ger'],
	'BAzitavya':['ger'],
	'BAzaRIya':['ger'],
	'Bid':['vt7am'],
	'Bindat':['prap'],
	'BindAna':['prmp'],
	'BidyamAna':['prpp'],
	'Betsyat':['fap'],
	'BetsyamAna':['fmp', 'fpp'],
	'biBidvas':['pfap'],
	'biBidAna':['pfmp', 'pfpp'],
	'Binna':['ppp'],
	'Binnavat':['pap'],
	'Bettum':['inf'],
	'BittvA':['abs'],
	'Bedya':['ger'],
	'Bettavya':['ger'],
	'BedanIya':['ger'],
	'BI':['vi3a'],
	'biByat':['prap'],
	'BIyamAna':['prpp'],
	'Bezyat':['fap'],
	'BezyamARa':['fpp'],
	'biBayAm':['ppf_actn'],
	'biBIvas':['pfap'],
	'biByAna':['pfpp'],
	'BIta':['ppp'],
	'BItavat':['pap'],
	'Betum':['inf'],
	'BItvA':['abs'],
	'Beya':['ger'],
	'Betavya':['ger'],
	'BayanIya':['ger'],
	'BIru':['adj'],
	'Buj':['vt7a', 'vt7m'],
	'BuYjat':['prap'],
	'BujyamAna':['prpp'],
	'Bokzyat':['fap'],
	'BokzyamARa':['fpp', 'fmp'],
	'buBujvas':['pfap'],
	'buBujAna':['pfpp', 'pfmp'],
	'Bukta':['ppp'],
	'Buktavat':['pap'],
	'Boktum':['inf'],
	'BuktvA':['abs'],
	'Bogya':['ger'],
	'Boktavya':['ger'],
	'BojanIya':['ger'],
	'BuYjAna':['prmp'],
	'BU':['f', 'vi1a'],
	'BUyamAna':['prpp'],
	'Bavizyat':['fap'],
	'BavizyamARa':['fpp'],
	'baBUvas':['pfap'],
	'BUta':['ppp'],
	'BUtavat':['pap'],
	'Bavitum':['inf'],
	'BUtvA':['abs'],
	'Bavya':['ger'],
	'Bavitavya':['ger'],
	'BavanIya':['ger'],
	'Bf':['vt3am'],
	'biBrat':['prap'],
	'biBrARa':['prmp'],
	'BriyamARa':['prpp'],
	'Barizyat':['fap'],
	'BarizyamARa':['fmp', 'fpp'],
	'biBarAm':['ppf_actn'],
	'baBfvas':['pfap'],
	'baBrARa':['pfmp', 'pfpp'],
	'Bfta':['ppp'],
	'Bftavat':['pap'],
	'Bartum':['inf'],
	'BftvA':['abs'],
	'Bftya':['ger'],
	'Bartavya':['ger'],
	'BaraRIya':['ger'],
	'Bojana':['n'],
	'BrAtf':['m'],
	'BrU':['f'],
	'mati':['f'],
	'mad':['vi4a'],
	'mAdyat':['prap'],
	'madyamAna':['prpp'],
	'madizyat':['fap'],
	'madizyamARa':['fpp'],
	'medivas':['pfap'],
	'medAna':['pfpp'],
	'matta':['ppp'],
	'mattavat':['pap'],
	'maditum':['inf'],
	'maditvA':['abs'],
	'madya':['ger'],
	'maditavya':['ger'],
	'madanIya':['ger'],
	'maDu':['adj', 'n'],
	'maDya':['adj', 'n'],
	'man':['vt4m', 'vt8m'],
	'manyamAna':['prmp', 'prpp'],
	'manvAna':['prmp'],
	'maMsyamAna':['fmp', 'fpp'],
	'menAna':['pfmp', 'pfpp'],
	'mata':['ppp'],
	'matavat':['pap'],
	'mantum':['inf'],
	'matvA':['abs'],
	'mAnya':['ger'],
	'mantavya':['ger'],
	'mananIya':['ger'],
	'manas':['n'],
	'manuzya':['m'],
	'mahat':['adj'],
	'mahABArata':['n_pn'],
	'mahAyaSas':['adj'],
	'mA':['neg_pcl'],
	'mAtf':['f'],
	'mAlA':['f'],
	'mAsa':['m'],
	'muKa':['n'],
	'muc':['vt6am'],
	'muYcat':['prap'],
	'muYcamAna':['prmp'],
	'mucyamAna':['prpp'],
	'mokzyat':['fap'],
	'mokzyamARa':['fmp', 'fpp'],
	'mumucvas':['pfap'],
	'mumucAna':['pfmp', 'pfpp'],
	'mukta':['ppp'],
	'muktavat':['pap'],
	'moktum':['inf'],
	'muktvA':['abs'],
	'mocya':['ger'],
	'moktavya':['ger'],
	'mocanIya':['ger'],
	'muhUrta':['m', 'n'],
	'mf':['vi6am'],
	'mriyamARa':['prmp', 'prpp'],
	'marizyat':['fap'],
	'marizyamARa':['fpp'],
	'mamfvas':['pfap'],
	'mamrARa':['pfpp'],
	'mfta':['ppp'],
	'mftavat':['pap'],
	'martum':['inf'],
	'mftvA':['abs'],
	'mArya':['ger'],
	'martavya':['ger'],
	'maraRIya':['ger'],
	'mfga':['m'],
	'mfdu':['adj'],
	'yaj':['vt1am'],
	'yajat':['prap'],
	'yajamAna':['prmp', 'm'],
	'ijyamAna':['prpp'],
	'yakzyat':['fap'],
	'yakzyamARa':['fmp', 'fpp'],
	'Ijivas':['pfap'],
	'IjAna':['pfmp', 'pfpp'],
	'yazwum':['inf'],
	'yAjya':['ger'],
	'yazwavya':['ger'],
	'yajanIya':['ger'],
	'yajurveda':['m'],
	'yajus':['n'],
	'yajYa':['m'],
	'yatas':['rel_adv'],
	'yatra':['rel_adv'],
	'yaTA':['rel_adv'],
	'yad':['rel_pron', 'rel_adj'],
	'yadA':['rel_adv'],
	'yadi':['rel_adv'],
	'yam':['vt1a'],
	'yacCat':['prap'],
	'yamyamAna':['prpp'],
	'yaMsyat':['fap'],
	'yaMsyamAna':['fpp'],
	'yemivas':['pfap'],
	'yemAna':['pfpp'],
	'yata':['ppp'],
	'yatavat':['pap'],
	'yantum':['inf'],
	'yatvA':['abs'],
	'yamya':['ger'],
	'yantavya':['ger'],
	'yamanIya':['ger'],
	'yaSas':['n'],
	'yAvat':['rel_adj', 'rel_adv'],
	'yuj':['vt7am'],
	'yuYjat':['prap'],
	'yuYjAna':['prmp'],
	'yujyamAna':['prpp'],
	'yokzyat':['fap'],
	'yokzyamARa':['fmp', 'fpp'],
	'yuyujvas':['pfap'],
	'yuyujAna':['pfmp', 'pfpp'],
	'yukta':['ppp'],
	'yuktavat':['pap'],
	'yoktum':['inf'],
	'yuktvA':['abs'],
	'yogya':['ger'],
	'yoktavya':['ger'],
	'yojanIya':['ger'],
	'yuD':['vt4m'],
	'yuDyamAna':['prmp', 'prpp'],
	'yotsyamAna':['fmp', 'fpp'],
	'yuyuDAna':['pfmp', 'pfpp'],
	'yudDa':['ppp'],
	'yudDavat':['pap'],
	'yodDum':['inf'],
	'yudDvA':['abs'],
	'yoDya':['ger'],
	'yodDavya':['ger'],
	'yoDanIya':['ger'],
	'yuDizWira':['m_pn'],
	'yuDizWirArjuna':['m'],
	'yuzmad':['pers_pron'],
	'yUpa':['m'],
	'rajju':['f'],
	'raRa':['n'],
	'ratna':['n'],
	'raTa':['m'],
	'ram':['vi1m'],
	'ramamARa':['prmp'],
	'ramyamARa':['prpp'],
	'raMsyamAna':['fmp', 'fpp'],
	'remARa':['pfmp', 'pfpp'],
	'rata':['ppp'],
	'ratavat':['pap'],
	'rantum':['inf'],
	'ratvA':['abs'],
	'ramya':['ger'],
	'rantavya':['ger'],
	'ramaRIya':['ger'],
	'rAjan':['m'],
	'rAjya':['n'],
	'rAtri':['f'],
	'rAmAyaRa':['n_pn'],
	'rAzwra':['n'],
	'ruD':['vt7am'],
	'runDat':['prap'],
	'runDAna':['prmp'],
	'ruDyamAna':['prpp'],
	'rotsyat':['fap'],
	'rotsyamAna':['fmp', 'fpp'],
	'ruruDvas':['pfap'],
	'ruruDAna':['pfmp', 'pfpp'],
	'rudDa':['ppp'],
	'rudDavat':['pap'],
	'rodDum':['inf'],
	'rudDvA':['abs'],
	'roDya':['ger'],
	'rodDavya':['ger'],
	'roDanIya':['ger'],
	'roga':['m'],
	'laB':['vt1m'],
	'laBamAna':['prmp'],
	'laByamAna':['prpp'],
	'lapsyamAna':['fmp', 'fpp'],
	'leBAna':['pfmp', 'pfpp'],
	'labDa':['ppp'],
	'labDavat':['pap'],
	'labDum':['inf'],
	'labDvA':['abs'],
	'laBya':['ger'],
	'labDavya':['ger'],
	'laBanIya':['ger'],
	'loka':['m'],
	'vac':['vt2a'],
	'ucyamAna':['prpp'],
	'vakzyat':['fap'],
	'vakzyamARa':['fpp', 'fmp'],
	'Ucivas':['pfap'],
	'UcAna':['pfpp'],
	'ukta':['ppp'],
	'uktavat':['pap'],
	'vaktum':['inf'],
	'uktvA':['abs'],
	'vAkya':['ger'],
	'vaktavya':['ger'],
	'vacanIya':['ger'],
	'vacana':['n'],
	'vad':['vt1a'],
	'vadat':['prap'],
	'udyamAna':['prpp'],
	'vadizyat':['fap'],
	'vadizyamARa':['fpp'],
	'Udivas':['pfap'],
	'UdAna':['pfpp'],
	'udita':['ppp'],
	'uditavat':['pap'],
	'vaditum':['inf'],
	'uditvA':['abs'],
	'vAdya':['ger'],
	'vaditavya':['ger'],
	'vadanIya':['ger'],
	'vaDU':['f'],
	'vana':['n'],
	'vara':['adj', 'm'],
	'varuRa':['m_pn'],
	'varza':['m'],
	'vas':['vi1a'],
	'vasat':['prap'],
	'uzyamARa':['prpp'],
	'vatsyat':['fap'],
	'vatsyamAna':['fpp'],
	'Uzivas':['pfap'],
	'UzARa':['pfpp'],
	'uzita':['ppp'],
	'uzitavat':['pap'],
	'vastum':['inf'],
	'uzitvA':['abs'],
	'vAsya':['ger'],
	'vasitavya':['ger'],
	'vasanIya':['ger'],
	'vah':['vt1am'],
	'vahat':['prap'],
	'vahamAna':['prmp'],
	'uhyamAna':['prpp'],
	'Uhivas':['pfap'],
	'UhAna':['pfmp', 'pfpp'],
	'UQa':['ppp'],
	'UQavat':['pap'],
	'voQum':['inf'],
	'UQvA':['abs'],
	'vAhya':['ger'],
	'voQavya':['ger'],
	'vAhanIya':['ger'],
	'vA':['disj_pcl'],
	'vAc':['f'],
	'vAra':['m'],
	'vAri':['n'],
	'vAlmIki':['m_pn'],
	'vAsas':['n'],
	'vi':['preverb'],
	'vi_krI':['vt9am'],
	'vikrIRat':['prap'],
	'vikrIRAna':['prmp'],
	'vikrIyamARa':['prpp'],
	'vikrezyat':['fap'],
	'vikrezyamARa':['fmp', 'fpp'],
	'vicikrIvas':['pfap'],
	'vicikriyARa':['pfmp', 'pfpp'],
	'vikrIta':['ppp'],
	'vikrItavat':['pap'],
	'vikretum':['inf'],
	'vikrIya':['abs'],
	'vikreya':['ger'],
	'vikretavya':['ger'],
	'vikrayaRIya':['ger'],
	'vi_gam':['vi1a'],
	'vigacCat':['prap'],
	'vigamyamAna':['prpp'],
	'vigamizyat':['fap'],
	'vigamizyamARa':['fpp'],
	'vijagmivas':['pfap'],
	'vijaganvas':['pfap'],
	'vijagmAna':['pfpp'],
	'vigata':['ppp'],
	'vigatavat':['pap'],
	'vigantum':['inf'],
	'vigamya':['abs', 'ger'],
	'vigatya':['abs'],
	'vigantavya':['ger'],
	'vigamanIya':['ger'],
	'vigama':['m'],
	'vi_jYA':['vt9a'],
	'vijAnat':['prap'],
	'vijYAyamAna':['prpp'],
	'vijYAsyat':['fap'],
	'vijYAsyamAna':['fpp'],
	'vijajYivas':['pfap'],
	'vijajYAna':['pfpp'],
	'vijYAta':['ppp'],
	'vijYAtavat':['pap'],
	'vijYAtum':['inf'],
	'vijYAya':['abs'],
	'vijYeya':['ger'],
	'vijYAtavya':['ger'],
	'vijYAnIya':['ger'],
	'vijYAna':['n'],
	'vid':['vt2a', 'vt6am'],
	'vidvas':['prap'],
	'vidyamAna':['prpp'],
	'vedizyat':['fap'],
	'vedizyamARa':['fpp', 'fmp'],
	'vidAm':['ppf_actn'],
	'vividvas':['pfap'],
	'vividAna':['pfpp', 'pfmp'],
	'vidita':['ppp'],
	'viditavat':['pap'],
	'veditum':['inf'],
	'viditvA':['abs'],
	'vedya':['ger'],
	'veditavya':['ger'],
	'vedanIya':['ger'],
	'vindat':['prap'],
	'vindamAna':['prmp'],
	'vividivas':['pfap'],
	'vitta':['ppp'],
	'vittavat':['pap'],
	'vettum':['inf'],
	'vittvA':['abs'],
	'veditvA':['abs'],
	'vettavya':['ger'],
	'vidyut':['m'],
	'vinA':['pcl'],
	'viS':['m', 'vt6a'],
	'viSat':['prap'],
	'viSyamAna':['prpp'],
	'vekzyat':['fap'],
	'vekzyamARa':['fpp'],
	'viviSvas':['pfap'],
	'viviSivas':['pfap'],
	'viviSAna':['pfpp'],
	'vizwa':['ppp'],
	'vizwavat':['pap'],
	'vezwum':['inf'],
	'vizwvA':['abs'],
	'veSya':['ger'],
	'vezwavya':['ger'],
	'veSanIya':['ger'],
	'vi_sad':['vi1a', 'vi6a'],
	'vizIdat':['prap'],
	'vizadyamAna':['prpp'],
	'vizatsyat':['fap'],
	'vizatsyamAna':['fpp'],
	'vizedivas':['pfap'],
	'vizedAna':['pfpp'],
	'vizaRRa':['ppp'],
	'vizaRRavat':['pap'],
	'vizattum':['inf'],
	'vizadya':['abs'],
	'vizAdya':['ger'],
	'vizattavya':['ger'],
	'vizadanIya':['ger'],
	'vi_smf':['vt1a'],
	'vismarat':['prap'],
	'vismaryamARa':['prpp'],
	'vismarizyat':['fap'],
	'vismarizyamARa':['fpp'],
	'visasmarvas':['pfap'],
	'visasmarARa':['pfpp'],
	'vismfta':['ppp'],
	'vismftavat':['pap'],
	'vismartum':['inf'],
	'vismftya':['abs'],
	'vismArya':['ger'],
	'vismartavya':['ger'],
	'vismaraRIya':['ger'],
	'vIra':['adj', 'm'],
	'vf':['vt5am', 'vt10am', 'vt9m'],
	'vfRvat':['prap'],
	'vfRvAna':['prmp'],
	'vriyamARa':['prpp'],
	'varizyat':['fap'],
	'varIzyat':['fap'],
	'varizyamARa':['fmp', 'fpp'],
	'varIzyamARa':['fmp', 'fpp'],
	'vavfvas':['pfap'],
	'vavrARa':['pfmp', 'pfpp'],
	'vfta':['ppp'],
	'vftavat':['pap'],
	'varitum':['inf'],
	'varItum':['inf'],
	'vftvA':['abs'],
	'vftya':['ger'],
	'varitavya':['ger'],
	'varItavya':['ger'],
	'varaRIya':['ger'],
	'vArayat':['prap'],
	'vArayamARa':['prmp'],
	'vAryamARa':['prpp'],
	'vArayizyat':['fap'],
	'vArayizyamARa':['fmp', 'fpp'],
	'vArayAm':['ppf_actn'],
	'vArita':['ppp'],
	'vAritavat':['pap'],
	'vArayitum':['inf'],
	'vArayitvA':['abs'],
	'vArya':['ger'],
	'vArayitavya':['ger'],
	'vAraRIya':['ger'],
	'vfRAna':['prmp'],
	'vfka':['m'],
	'vfkza':['m'],
	'vft':['vi1m'],
	'vartamAna':['prmp'],
	'vartizyamARa':['fmp', 'fpp'],
	'vavftAna':['pfmp', 'pfpp'],
	'vftta':['ppp'],
	'vfttavat':['pap'],
	'vartitum':['inf'],
	'vartitvA':['abs'],
	'vartitavya':['ger'],
	'vartanIya':['ger'],
	'veda':['m'],
	'vedi':['f'],
	'vE':['pcl'],
	'vyaD':['vt4a'],
	'viDyat':['prap'],
	'viDyamAna':['prpp'],
	'vetsyat':['fap'],
	'vetsyamAna':['fpp'],
	'vivyaDvas':['pfap'],
	'vivyaDAna':['pfpp'],
	'vidDa':['ppp'],
	'vidDavat':['pap'],
	'vedDum':['inf'],
	'vidDvA':['abs'],
	'veDya':['ger'],
	'vedDavya':['ger'],
	'veDanIya':['ger'],
	'vyAsa':['m_pn'],
	'vraj':['vt1a'],
	'vrajat':['prap'],
	'vrajyamAna':['prpp'],
	'vrajizyat':['fap'],
	'vrajizyamARa':['fpp'],
	'vavrajvas':['pfap'],
	'vavrajAna':['pfpp'],
	'vrajita':['ppp'],
	'vrajitavat':['pap'],
	'vrajitum':['inf'],
	'vrajitvA':['abs'],
	'vrAjya':['ger'],
	'vrajitavya':['ger'],
	'vrajanIya':['ger'],
	'Sak':['vi5a'],
	'Saknuvat':['prap'],
	'SakyamAna':['prpp'],
	'Sakta':['ppp'],
	'Saktavat':['pap'],
	'Saktum':['inf'],
	'SaktvA':['abs'],
	'Sakya':['ger'],
	'Saktavya':['ger'],
	'SakanIya':['ger'],
	'SakuntalA':['f_pn'],
	'Sata':['card_num'],
	'Satru':['m'],
	'Sap':['vt1am', 'vt4am'],
	'Sapat':['prap'],
	'Sapyat':['prap'],
	'SapamAna':['prmp'],
	'SapyamAna':['prmp', 'prpp'],
	'Sapsyat':['fap'],
	'SapsyamAna':['fmp', 'fpp'],
	'Sepivas':['pfap'],
	'SepAna':['pfmp', 'pfpp'],
	'Sapta':['ppp'],
	'Saptavat':['pap'],
	'Saptum':['inf'],
	'SaptvA':['abs'],
	'Sapya':['ger'],
	'Saptavya':['ger'],
	'SapanIya':['ger'],
	'Sabda':['m'],
	'Sayana':['n'],
	'Sara':['m'],
	'SAKA':['f'],
	'SApa':['m'],
	'SAlA':['f'],
	'SAs':['vt2a'],
	'SAsat':['prap'],
	'SizyamARa':['prpp'],
	'SAsizyat':['fap'],
	'SAsizyamARa':['fpp'],
	'SaSAsvas':['pfap'],
	'SaSAsAna':['pfpp'],
	'Sizwa':['ppp'],
	'Sizwavat':['pap'],
	'SAsitum':['inf'],
	'SizwvA':['abs'],
	'SAsitvA':['abs'],
	'Sizya':['ger', 'm'],
	'SAsitavya':['ger'],
	'SAsanIya':['ger'],
	'Sita':['adj'],
	'Siva':['adj', 'm_pn'],
	'SI':['vi2m'],
	'SayAna':['prmp'],
	'Sayita':['ppp'],
	'Sayitavat':['pap'],
	'Sayitum':['inf'],
	'SayitvA':['abs'],
	'Sayya':['ger'],
	'Sayitavya':['ger'],
	'SayanIya':['ger'],
	'SIGra':['adj'],
	'SIGram':['adv'],
	'Suc':['vt1a'],
	'Socat':['prap'],
	'SuSucvas':['pfap'],
	'Sucita':['ppp'],
	'Socita':['ppp'],
	'Sucitavat':['pap'],
	'Socitavat':['pap'],
	'Socitum':['inf'],
	'SucitvA':['abs'],
	'SocitvA':['abs'],
	'Socya':['ger'],
	'Socitavya':['ger'],
	'SocanIya':['ger'],
	'SocayitvA':['abs'],
	'Suci':['adj'],
	'Seza':['m'],
	'SrI':['f'],
	'Sru':['vt1am'],
	'SfRvat':['prap'],
	'SrUyamARa':['prpp'],
	'Sruta':['ppp'],
	'Srutavat':['pap'],
	'Srotum':['inf'],
	'SrutvA':['abs'],
	'Sravya':['ger'],
	'Srotavya':['ger'],
	'SravaRIya':['ger'],
	'Svan':['m'],
	'Svas':['adv'],
	'zaz':['card_num'],
	'zazWa':['ord_num'],
	'saMvAda':['m'],
	'saMSaya':['m'],
	'saMskfta':['adj', 'n'],
	'saKI':['f'],
	'saKya':['n'],
	'satya':['adj', 'n'],
	'sad':['vi1a', 'vi6a'],
	'sIdat':['prap'],
	'sadyamAna':['prpp'],
	'satsyat':['fap'],
	'satsyamAna':['fpp'],
	'sedivas':['pfap'],
	'sedAna':['pfpp'],
	'sanna':['ppp'],
	'sannavat':['pap'],
	'sattum':['inf'],
	'sattvA':['abs'],
	'sAdya':['ger'],
	'sattavya':['ger'],
	'sadanIya':['ger'],
	'sadA':['pron_adv'],
	'saptan':['card_num'],
	'saptama':['ord_num'],
	'sam':['preverb'],
	'sam_A_gam':['vi1m'],
	'samAgacCamAna':['prmp'],
	'samAgamyamAna':['prpp'],
	'samAgamizyamARa':['fmp', 'fpp'],
	'samAjagmAna':['pfmp', 'pfpp'],
	'samAgata':['ppp'],
	'samAgatavat':['pap'],
	'samAgantum':['inf'],
	'samAgamya':['abs', 'ger'],
	'samAgatya':['abs'],
	'samAgantavya':['ger'],
	'samAgamanIya':['ger'],
	'samIpa':['adj'],
	'sam_kram':['vi1a'],
	'saMkrAmat':['prap'],
	'saMkrAmyat':['prap'],
	'saMkramyamARa':['prpp'],
	'saMkraMsyat':['fap'],
	'saMkraMsyamAna':['fpp'],
	'saMcakramvas':['pfap'],
	'saMcakramARa':['pfpp'],
	'saMkrAnta':['ppp'],
	'saMkrAntavat':['pap'],
	'saMkramitum':['inf'],
	'saMkramya':['abs', 'ger'],
	'saMkramitavya':['ger'],
	'saMkramaRIya':['ger'],
	'sam_gam':['vi1m'],
	'saMgacCamAna':['prmp'],
	'saMgamyamAna':['prpp'],
	'saMgamizyamARa':['fmp', 'fpp'],
	'saMjagmAna':['pfmp', 'pfpp'],
	'saMgata':['ppp'],
	'saMgatavat':['pap'],
	'saMgantum':['inf'],
	'saMgamya':['abs', 'ger'],
	'saMgatya':['abs'],
	'saMgantavya':['ger'],
	'saMgamanIya':['ger'],
	'sam_pad':['vt4m'],
	'saMpadyamAna':['prmp', 'prpp'],
	'saMpatsyamAna':['fmp', 'fpp'],
	'saMpedAna':['pfmp', 'pfpp'],
	'saMpanna':['ppp'],
	'saMpannavat':['pap'],
	'saMpattum':['inf'],
	'saMpadya':['abs'],
	'saMpAdya':['ger'],
	'saMpattavya':['ger'],
	'saMpadanIya':['ger'],
	'sam_pracC':['vi6m'],
	'saMpfcCamAna':['prmp'],
	'saMpfcCyamAna':['prpp'],
	'saMprakzyamARa':['fmp', 'fpp'],
	'saMpapracCAna':['pfmp', 'pfpp'],
	'saMpfzwa':['ppp'],
	'saMpfzwavat':['pap'],
	'saMprazwum':['inf'],
	'saMpfcCya':['abs', 'ger'],
	'saMprazwavya':['ger'],
	'saMpracCanIya':['ger'],
	'sam_BU':['vi1a'],
	'saMBavat':['prap'],
	'saMBUyamAna':['prpp'],
	'saMBavizyat':['fap'],
	'saMBavizyamARa':['fpp'],
	'saMbaBUvas':['pfap'],
	'saMBUta':['ppp'],
	'saMBUtavat':['pap'],
	'saMBavitum':['inf'],
	'saMBUya':['abs'],
	'saMBavya':['ger'],
	'saMBavitavya':['ger'],
	'saMBavanIya':['ger'],
	'sam_vad':['vi1m'],
	'saMvadamAna':['prmp'],
	'samudyamAna':['prpp'],
	'saMvadizyamARa':['fmp', 'fpp'],
	'samUdAna':['pfmp', 'pfpp'],
	'samudita':['ppp'],
	'samuditavat':['pap'],
	'saMvaditum':['inf'],
	'samudya':['abs'],
	'saMvAdya':['ger'],
	'saMvaditavya':['ger'],
	'saMvadanIya':['ger'],
	'sam_SI':['vi2m'],
	'saMSayAna':['prmp'],
	'saMSayita':['ppp'],
	'saMSayitavat':['pap'],
	'saMSayitum':['inf'],
	'saMSayya':['abs', 'ger'],
	'saMSayitavya':['ger'],
	'saMSayanIya':['ger'],
	'sam_hf':['vt1am'],
	'saMharat':['prap'],
	'saMharamARa':['prmp'],
	'saMhriyamARa':['prpp'],
	'saMharizyat':['fap'],
	'saMharizyamARa':['fmp', 'fpp'],
	'saMjahfvas':['pfap'],
	'saMjahrARa':['pfmp', 'pfpp'],
	'saMhfta':['ppp'],
	'saMhftavat':['pap'],
	'saMhartum':['inf'],
	'saMhftya':['abs'],
	'saMhArya':['ger'],
	'saMhartavya':['ger'],
	'saMharaRIya':['ger'],
	'sarit':['f'],
	'sarva':['pron', 'pron_adj'],
	'sarvatas':['pron_adv'],
	'sarvatra':['pron_adv'],
	'sarvaTA':['pron_adv'],
	'sarvadA':['pron_adv'],
	'saha':['pcl'],
	'sADu':['adj', 'm'],
	'sAman':['n'],
	'sAmaveda':['m'],
	'su':['preverb', 'vt5am'],
	'sunvat':['prap'],
	'sunvAna':['prmp'],
	'sUyamAna':['prpp'],
	'sozyat':['fap'],
	'sozyamARa':['fmp', 'fpp'],
	'suzuvas':['pfap'],
	'suzuvARa':['pfmp', 'pfpp'],
	'suta':['ppp'],
	'sutavat':['pap'],
	'sotum':['inf'],
	'sutvA':['abs'],
	'savya':['ger'],
	'sotavya':['ger'],
	'savanIya':['ger'],
	'suKa':['adj', 'n'],
	'suKam':['adv'],
	'suKin':['adj'],
	'sudeva':['adj'],
	'sundara':['adj'],
	'sumanas':['adj'],
	'suhfd':['adj', 'm', 'f'],
	'sUrya':['m'],
	'sev':['vt1m'],
	'sevamAna':['prmp'],
	'sevyamAna':['prpp'],
	'sevizyamARa':['fmp', 'fpp'],
	'sizevARa':['pfmp', 'pfpp'],
	'sevita':['ppp'],
	'sevitavat':['pap'],
	'sevitum':['inf'],
	'sevitvA':['abs'],
	'sevya':['ger'],
	'sevitavya':['ger'],
	'sevanIya':['ger'],
	'sevA':['f'],
	'sEnya':['n'],
	'soma':['m'],
	'strI':['f'],
	'sTA':['vi1am'],
	'tizWat':['prap'],
	'tizWamAna':['prmp'],
	'sTIyamAna':['prpp'],
	'sTAsyat':['fap'],
	'sTAsyamAna':['fmp', 'fpp'],
	'tasTivas':['pfap'],
	'tasTAna':['pfmp', 'pfpp'],
	'sTita':['ppp'],
	'sTitavat':['pap'],
	'sTAtum':['inf'],
	'sTitvA':['abs'],
	'sTeya':['ger'],
	'sTAtavya':['ger'],
	'sTAnIya':['ger'],
	'sTApayitum':['inf'],
	'sTAna':['n'],
	'sTAlI':['f'],
	'snih':['vi4a'],
	'snihyat':['prap'],
	'snihyamAna':['prpp'],
	'snekzyat':['fap'],
	'snehizyat':['fap'],
	'snekzyamARa':['fpp'],
	'snehizyamARa':['fpp'],
	'sizRihvas':['pfap'],
	'sizRihAna':['pfpp'],
	'snigDa':['ppp'],
	'snigDavat':['pap'],
	'snehitum':['inf'],
	'snihitvA':['abs'],
	'snehitvA':['abs'],
	'snehya':['ger'],
	'snehitavya':['ger'],
	'snehanIya':['ger'],
	'sneha':['m'],
	'spfS':['vt6a'],
	'spfSat':['prap'],
	'spfSyamAna':['prpp'],
	'sprakzyat':['fap'],
	'sprakzyamARa':['fpp'],
	'paspfSvas':['pfap'],
	'paspfSAna':['pfpp'],
	'spfzwa':['ppp'],
	'spfzwavat':['pap'],
	'sprazwum':['inf'],
	'spfzwvA':['abs'],
	'spfSya':['ger'],
	'sprazwavya':['ger'],
	'sparSanIya':['ger'],
	'sma':['pcl'],
	'smf':['vt1a'],
	'smarat':['prap'],
	'smaryamARa':['prpp'],
	'smarizyat':['fap'],
	'smarizyamARa':['fpp'],
	'sasmarvas':['pfap'],
	'sasmarARa':['pfpp'],
	'smfta':['ppp'],
	'smftavat':['pap'],
	'smartum':['inf'],
	'smftvA':['abs'],
	'smArya':['ger'],
	'smartavya':['ger'],
	'smaraRIya':['ger'],
	'smfti':['f'],
	'sraj':['f'],
	'sva':['pron', 'pron_adj'],
	'svarAzwra':['n'],
	'svarga':['m'],
	'svasf':['f'],
	'ha':['pcl'],
	'han':['vt2a'],
	'Gnat':['prap'],
	'hanyamAna':['prpp'],
	'hanizyat':['fap'],
	'hanizyamARa':['fpp'],
	'jaGnivas':['pfap'],
	'jaGanvas':['pfap'],
	'jaGnAna':['pfpp'],
	'hata':['ppp'],
	'hatavat':['pap'],
	'hantum':['inf'],
	'hatvA':['abs'],
	'GAtya':['ger'],
	'hantavya':['ger'],
	'hananIya':['ger'],
	'hari':['adj', 'm', 'm_pn'],
	'havis':['n'],
	'hasta':['m'],
	'hastin':['adj', 'm'],
	'hA':['vt3a'],
	'jahat':['prap'],
	'jahAna':['prmp', 'pfmp', 'pfpp'],
	'hIyamAna':['prpp'],
	'hAsyat':['fap'],
	'hAsyamAna':['fmp', 'fpp'],
	'jahivas':['pfap'],
	'hIna':['ppp'],
	'hInavat':['pap'],
	'hAtum':['inf'],
	'heya':['ger'],
	'hAtavya':['ger'],
	'hAnIya':['ger'],
	'hi':['sub_pcl', 'vt5a'],
	'hinvat':['prap'],
	'hezyat':['fap'],
	'hezyamARa':['fpp'],
	'hetum':['inf'],
	'hetavya':['ger'],
	'hayanIya':['ger'],
	'himAlaya':['m_pn'],
	'hu':['vt3a'],
	'juhvat':['prap'],
	'hUyamAna':['prpp'],
	'hozyat':['fap'],
	'hozyamARa':['fpp'],
	'juhavAm':['ppf_actn'],
	'huta':['ppp'],
	'hutavat':['pap'],
	'hotum':['inf'],
	'hutvA':['abs'],
	'havya':['ger'],
	'hotavya':['ger'],
	'havanIya':['ger'],
	'hf':['vt1am'],
	'harat':['prap'],
	'haramARa':['prmp'],
	'hriyamARa':['prpp'],
	'harizyat':['fap'],
	'harizyamARa':['fmp', 'fpp'],
	'jahfvas':['pfap'],
	'jahrARa':['pfmp', 'pfpp'],
	'hfta':['ppp'],
	'hftavat':['pap'],
	'hartum':['inf'],
	'hftvA':['abs'],
	'hArya':['ger'],
	'hartavya':['ger'],
	'haraRIya':['ger'],
	'he':['pcl'],
	'hetu':['m'],
	'hotf':['m'],
	'hyas':['adv'],
	'hrI':['f'],
	'hve':['vt1am'],
	'hvayat':['prap'],
	'hvayamAna':['prmp'],
	'hvAsyat':['fap'],
	'hvAsyamAna':['fmp', 'fpp'],
	'juhUvas':['pfap'],
	'juhuvAna':['pfmp', 'pfpp'],
	'hUta':['ppp'],
	'hUtavat':['pap'],
	'hvAtum':['inf'],
	'hUtvA':['abs'],
	'hvAya':['ger'],
	'hvAtavya':['ger'],
	'hvAnIya':['ger']
}

export const defs ={
	'agni':['fire'],
	'agra':['tip, beginning'],
	'aNgulIya':['ring'],
	'atas':['hence'],
	'ati':['beyond'],
	'ati_i':['go beyond, pass'],
	'atiyat':['go beyond, pass'],
	'atIyamAna':['go beyond, pass'],
	'atyezyat':['go beyond, pass'],
	'atyezyamARa':['go beyond, pass'],
	'atIyivas':['go beyond, pass'],
	'atIyAna':['go beyond, pass'],
	'atIta':['go beyond, pass'],
	'atItavat':['go beyond, pass'],
	'atyetum':['go beyond, pass'],
	'atItya':['go beyond, pass'],
	'atItavya':['go beyond, pass'],
	'atyayanIya':['go beyond, pass'],
	'atiTi':['guest'],
	'atra':['here'],
	'aTa':['then, now'],
	'ad':['eat'],
	'adat':['eat'],
	'adyamAna':['eat'],
	'atsyat':['eat'],
	'atsyamAna':['eat'],
	'Adivas':['eat'],
	'AdAna':['eat'],
	'anna':['eat', 'food'],
	'annavat':['eat'],
	'attum':['eat'],
	'attvA':['eat'],
	'Adya':['eat'],
	'attavya':['eat'],
	'adanIya':['eat'],
	'adas':['that'],
	'adya':['today'],
	'aDi':['over'],
	'aDi_i':['go over, study, read, recite'],
	'aDIyAna':['go over, study, read, recite'],
	'aDIyamAna':['go over, study, read, recite'],
	'aDyezyamARa':['go over, study, read, recite'],
	'aDijagAna':['go over, study, read, recite'],
	'aDIta':['go over, study, read, recite'],
	'aDItavat':['go over, study, read, recite'],
	'aDyetum':['go over, study, read, recite'],
	'aDItya':['go over, study, read, recite'],
	'aDyeya':['go over, study, read, recite'],
	'aDyetavya':['go over, study, read, recite'],
	'aDyayanIya':['go over, study, read, recite'],
	'aDunA':['now'],
	'aDvaryu':['adhvaryu'],
	'anu':['after'],
	'anu_A_gam':['come after, follow'],
	'anvAgacCat':['come after, follow'],
	'anvAgamyamAna':['come after, follow'],
	'anvAgamizyat':['come after, follow'],
	'anvAgamizyamARa':['come after, follow'],
	'anvAjagmivas':['come after, follow'],
	'anvAjaganvas':['come after, follow'],
	'anvAjagmAna':['come after, follow'],
	'anvAgata':['come after, follow'],
	'anvAgatavat':['come after, follow'],
	'anvAgantum':['come after, follow'],
	'anvAgamya':['come after, follow'],
	'anvAgatya':['come after, follow'],
	'anvAgantavya':['come after, follow'],
	'anvAgamanIya':['come after, follow'],
	'anu_iz':['seek after, search for'],
	'anvicCat':['seek after, search for'],
	'anvizyamARa':['seek after, search for'],
	'anvezizyat':['seek after, search for'],
	'anvezizyamARa':['seek after, search for'],
	'anvIzivas':['seek after, search for'],
	'anvIzARa':['seek after, search for'],
	'anvizwa':['seek after, search for'],
	'anvizwavat':['seek after, search for'],
	'anvezwum':['seek after, search for'],
	'anvizya':['seek after, search for'],
	'anvezya':['seek after, search for'],
	'anvezwavya':['seek after, search for'],
	'anvezaRIya':['seek after, search for'],
	'anu_gam':['go after, follow'],
	'anugacCat':['go after, follow'],
	'anugamyamAna':['go after, follow'],
	'anugamizyat':['go after, follow'],
	'anugamizyamARa':['go after, follow'],
	'anujagmivas':['go after, follow'],
	'anujaganvas':['go after, follow'],
	'anujagmAna':['go after, follow'],
	'anugata':['go after, follow'],
	'anugatavat':['go after, follow'],
	'anugantum':['go after, follow'],
	'anugamya':['go after, follow'],
	'anugatya':['go after, follow'],
	'anugantavya':['go after, follow'],
	'anugamanIya':['go after, follow'],
	'anu_jYA':['permit, assent'],
	'anujAnat':['permit, assent'],
	'anujYAyamAna':['permit, assent'],
	'anujYAsyat':['permit, assent'],
	'anujYAsyamAna':['permit, assent'],
	'anujajYivas':['permit, assent'],
	'anujajYAna':['permit, assent'],
	'anujYAta':['permit, assent'],
	'anujYAtavat':['permit, assent'],
	'anujYAtum':['permit, assent'],
	'anujYAya':['permit, assent'],
	'anujYeya':['permit, assent'],
	'anujYAtavya':['permit, assent'],
	'anujYAnIya':['permit, assent'],
	'anujYA':['permission'],
	'anu_pat':['fly after, chase'],
	'anupatat':['fly after, chase'],
	'anupatyamAna':['fly after, chase'],
	'anupatizyat':['fly after, chase'],
	'anupatizyamARa':['fly after, chase'],
	'anupetivas':['fly after, chase'],
	'anupetAna':['fly after, chase'],
	'anupatita':['fly after, chase'],
	'anupatitavat':['fly after, chase'],
	'anupatitum':['fly after, chase'],
	'anupatya':['fly after, chase'],
	'anupAtya':['fly after, chase'],
	'anupatitavya':['fly after, chase'],
	'anupatanIya':['fly after, chase'],
	'anta':['end, edge, border'],
	'anya':['other, else'],
	'anyatas':['from elsewhere'],
	'anyatra':['elsewhere'],
	'anyaTA':['otherwise'],
	'anyadA':['at another time'],
	'apa':['away'],
	'apa_kram':['go away, retreat'],
	'apakrAmat':['go away, retreat'],
	'apakrAmyat':['go away, retreat'],
	'apakramyamARa':['go away, retreat'],
	'apakraMsyat':['go away, retreat'],
	'apakraMsyamAna':['go away, retreat'],
	'apacakramvas':['go away, retreat'],
	'apacakramARa':['go away, retreat'],
	'apakrAnta':['go away, retreat'],
	'apakrAntavat':['go away, retreat'],
	'apakramitum':['go away, retreat'],
	'apakramya':['go away, retreat'],
	'apakramitavya':['go away, retreat'],
	'apakramaRIya':['go away, retreat'],
	'apa_gam':['go away'],
	'apagacCat':['go away'],
	'apagamyamAna':['go away'],
	'apagamizyat':['go away'],
	'apagamizyamARa':['go away'],
	'apajagmivas':['go away'],
	'apajaganvas':['go away'],
	'apajagmAna':['go away'],
	'apagata':['go away'],
	'apagatavat':['go away'],
	'apagantum':['go away'],
	'apagamya':['go away'],
	'apagatya':['go away'],
	'apagantavya':['go away'],
	'apagamanIya':['go away'],
	'apa_hf':['take away'],
	'apaharat':['take away'],
	'apaharamARa':['take away'],
	'apahriyamARa':['take away'],
	'apaharizyat':['take away'],
	'apaharizyamARa':['take away'],
	'apajahfvas':['take away'],
	'apajahrARa':['take away'],
	'apahfta':['take away'],
	'apahftavat':['take away'],
	'apahartum':['take away'],
	'apahftya':['take away'],
	'apahArya':['take away'],
	'apahartavya':['take away'],
	'apaharaRIya':['take away'],
	'api':['even, also, too'],
	'apsaras':['naiad'],
	'aBi':['toward'],
	'aBi_A_gam':['come up to'],
	'aByAgacCat':['come up to'],
	'aByAgamyamAna':['come up to'],
	'aByAgamizyat':['come up to'],
	'aByAgamizyamARa':['come up to'],
	'aByAjagmivas':['come up to'],
	'aByAjaganvas':['come up to'],
	'aByAjagmAna':['come up to'],
	'aByAgata':['come up to'],
	'aByAgatavat':['come up to'],
	'aByAgantum':['come up to'],
	'aByAgamya':['come up to'],
	'aByAgatya':['come up to'],
	'aByAgantavya':['come up to'],
	'aByAgamanIya':['come up to'],
	'aBi_vad':['speak to'],
	'aBivadat':['speak to'],
	'aByudyamAna':['speak to'],
	'aBivadizyat':['speak to'],
	'aBivadizyamARa':['speak to'],
	'aByUdivas':['speak to'],
	'aByUdAna':['speak to'],
	'aByudita':['speak to'],
	'aByuditavat':['speak to'],
	'aBivaditum':['speak to'],
	'aByudya':['speak to'],
	'aBivAdya':['speak to'],
	'aBivaditavya':['speak to'],
	'aBivadanIya':['speak to'],
	'araRi':['kindling stick'],
	'ari':['enemy'],
	'arjuna':['Arjuna'],
	'arTa':['object, meaning, purpose'],
	'arh':['deserve, be able (inf.)'],
	'arhat':['deserve, be able (inf.)'],
	'arhizyat':['deserve, be able (inf.)'],
	'arhizyamARa':['deserve, be able (inf.)'],
	'Anarhivas':['deserve, be able (inf.)'],
	'AnarhARa':['deserve, be able (inf.)'],
	'arhita':['deserve, be able (inf.)'],
	'arhitavat':['deserve, be able (inf.)'],
	'arhitum':['deserve, be able (inf.)'],
	'arhitvA':['deserve, be able (inf.)'],
	'arhya':['deserve, be able (inf.)'],
	'arhitavya':['deserve, be able (inf.)'],
	'arhaRIya':['deserve, be able (inf.)'],
	'alpa':['small'],
	'ava':['down'],
	'ava_Ap':['reach, attain, obtain'],
	'avApnuvat':['reach, attain, obtain'],
	'avApyamAna':['reach, attain, obtain'],
	'avApsyat':['reach, attain, obtain'],
	'avApsyamAna':['reach, attain, obtain'],
	'avApivas':['reach, attain, obtain'],
	'avApAna':['reach, attain, obtain'],
	'avApta':['reach, attain, obtain'],
	'avAptavat':['reach, attain, obtain'],
	'avAptum':['reach, attain, obtain'],
	'avApya':['reach, attain, obtain'],
	'avAptavya':['reach, attain, obtain'],
	'avApanIya':['reach, attain, obtain'],
	'ava_gam':['go down, descend, understand'],
	'avagacCat':['go down, descend, understand'],
	'avagamyamAna':['go down, descend, understand'],
	'avagamizyat':['go down, descend, understand'],
	'avagamizyamARa':['go down, descend, understand'],
	'avajagmivas':['go down, descend, understand'],
	'avajaganvas':['go down, descend, understand'],
	'avajagmAna':['go down, descend, understand'],
	'avagata':['go down, descend, understand'],
	'avagatavat':['go down, descend, understand'],
	'avagantum':['go down, descend, understand'],
	'avagamya':['go down, descend, understand'],
	'avagatya':['go down, descend, understand'],
	'avagantavya':['go down, descend, understand'],
	'avagamanIya':['go down, descend, understand'],
	'ava_sTA':['remain'],
	'avatizWamAna':['remain'],
	'avasTIyamAna':['remain'],
	'avasTAsyamAna':['remain'],
	'avatasTAna':['remain'],
	'avasTita':['remain'],
	'avasTitavat':['remain'],
	'avasTAtum':['remain'],
	'avasTAya':['remain'],
	'avasTeya':['remain'],
	'avasTAtavya':['remain'],
	'avasTAnIya':['remain'],
	'ava_hf':['take down'],
	'avaharat':['take down'],
	'avaharamARa':['take down'],
	'avahriyamARa':['take down'],
	'avaharizyat':['take down'],
	'avaharizyamARa':['take down'],
	'avajahfvas':['take down'],
	'avajahrARa':['take down'],
	'avahfta':['take down'],
	'avahftavat':['take down'],
	'avahartum':['take down'],
	'avahftya':['take down'],
	'avahArya':['take down'],
	'avahartavya':['take down'],
	'avaharaRIya':['take down'],
	'avi':['ram/ewe'],
	'aSva':['horse'],
	'azwan':['eight'],
	'azwama':['eighth'],
	'as':['be'],
	'sat':['be'],
	'asi':['sword'],
	'asura':['spirit, demon'],
	'asta':['home'],
	'asmad':['I/we'],
	'ah':['say'],
	'ahan':['day'],
	'A':['unto'],
	'A_i':['come'],
	'Ayat':['come'],
	'eyamAna':['come'],
	'Ezyat':['come'],
	'EzyamARa':['come'],
	'eyivas':['come'],
	'eyAna':['come'],
	'eta':['come'],
	'etavat':['come'],
	'Etum':['come'],
	'etya':['come'],
	'Etavya':['come'],
	'AyanIya':['come'],
	'A_kram':['mount, attack; rise'],
	'AkrAmat':['mount, attack; rise'],
	'AkrAmyat':['mount, attack; rise'],
	'AkramamARa':['mount, attack; rise'],
	'AkramyamARa':['mount, attack; rise'],
	'AkraMsyat':['mount, attack; rise'],
	'AkraMsyamAna':['mount, attack; rise'],
	'Acakramvas':['mount, attack; rise'],
	'AcakramARa':['mount, attack; rise'],
	'AkrAnta':['mount, attack; rise'],
	'AkrAntavat':['mount, attack; rise'],
	'AkrAntum':['mount, attack; rise'],
	'Akramitum':['mount, attack; rise'],
	'Akramya':['mount, attack; rise'],
	'Akramitavya':['mount, attack; rise'],
	'AkramaRIya':['mount, attack; rise'],
	'A_gam':['come'],
	'AgacCat':['come'],
	'AgamyamAna':['come'],
	'Agamizyat':['come'],
	'AgamizyamARa':['come'],
	'Ajagmivas':['come'],
	'Ajaganvas':['come'],
	'AjagmAna':['come'],
	'Agata':['come'],
	'Agatavat':['come'],
	'Agantum':['come'],
	'Agamya':['come'],
	'Agatya':['come'],
	'Agantavya':['come'],
	'AgamanIya':['come'],
	'AcArya':['teacher'],
	'AjYA':['command'],
	'A_jYA':['notice; caus.: command'],
	'AjAnat':['notice; caus.: command'],
	'AjYAyamAna':['notice; caus.: command'],
	'AjYAsyat':['notice; caus.: command'],
	'AjYAsyamAna':['notice; caus.: command'],
	'AjajYivas':['notice; caus.: command'],
	'AjajYAna':['notice; caus.: command'],
	'AjYAta':['notice; caus.: command'],
	'AjYAtavat':['notice; caus.: command'],
	'AjYAtum':['notice; caus.: command'],
	'AjYAya':['notice; caus.: command'],
	'AjYeya':['notice; caus.: command'],
	'AjYAtavya':['notice; caus.: command'],
	'AjYAnIya':['notice; caus.: command'],
	'Ati':['duck'],
	'A_dA':['receive, take'],
	'Adadat':['receive, take'],
	'AdadAna':['receive, take'],
	'AdIyamAna':['receive, take'],
	'AdAsyat':['receive, take'],
	'AdAsyamAna':['receive, take'],
	'Adadivas':['receive, take'],
	'Atta':['receive, take'],
	'Adattavat':['receive, take'],
	'AdAtum':['receive, take'],
	'AdAya':['receive, take'],
	'Adeya':['receive, take'],
	'AdAtavya':['receive, take'],
	'AdAnIya':['receive, take'],
	'A_nI':['lead to, bring'],
	'Anayat':['lead to, bring'],
	'AnayamAna':['lead to, bring'],
	'AnIyamAna':['lead to, bring'],
	'Anezyat':['lead to, bring'],
	'AnezyamARa':['lead to, bring'],
	'AninIvas':['lead to, bring'],
	'AninyAna':['lead to, bring'],
	'AnIta':['lead to, bring'],
	'AnItavat':['lead to, bring'],
	'Anetum':['lead to, bring'],
	'AnIya':['lead to, bring'],
	'Aneya':['lead to, bring'],
	'Anetavya':['lead to, bring'],
	'AnayanIya':['lead to, bring'],
	'Ap':['attain'],
	'Apnuvat':['attain'],
	'ApyamAna':['attain'],
	'Apsyat':['attain'],
	'ApsyamAna':['attain'],
	'Apivas':['attain'],
	'ApAna':['attain'],
	'Apta':['attain'],
	'Aptavat':['attain'],
	'Aptum':['attain'],
	'AptvA':['attain'],
	'Apya':['attain'],
	'Aptavya':['attain'],
	'ApanIya':['attain'],
	'ApaRa':['market'],
	'A_pad':['approach'],
	'ApadyamAna':['approach'],
	'ApatsyamAna':['approach'],
	'ApedAna':['approach'],
	'Apanna':['approach'],
	'Apannavat':['approach'],
	'Apattum':['approach'],
	'Apadya':['approach'],
	'ApAdya':['approach'],
	'Apattavya':['approach'],
	'ApadanIya':['approach'],
	'Ayus':['life'],
	'Avis':['clear, manifest'],
	'Avis_BU':['become manifest, appear'],
	'AvirBavat':['become manifest, appear'],
	'AvirBUyamAna':['become manifest, appear'],
	'AvirBavizyat':['become manifest, appear'],
	'AvirBavizyamARa':['become manifest, appear'],
	'AvirbaBUvas':['become manifest, appear'],
	'AvirBUta':['become manifest, appear'],
	'AvirBUtavat':['become manifest, appear'],
	'AvirBavitum':['become manifest, appear'],
	'AvirBUya':['become manifest, appear'],
	'AvirBavya':['become manifest, appear'],
	'AvirBavitavya':['become manifest, appear'],
	'AvirBavanIya':['become manifest, appear'],
	'ASrama':['hermitage'],
	'As':['sit'],
	'AsIna':['sit'],
	'AsyamAna':['sit'],
	'AsizyamARa':['sit'],
	'AsAm':['sit'],
	'Asita':['sit'],
	'Asitavat':['sit'],
	'Asitum':['sit'],
	'AsitvA':['sit'],
	'Asya':['sit'],
	'Astavya':['sit'],
	'AsanIya':['sit'],
	'A_sTA':['resort to'],
	'AtizWat':['resort to'],
	'AsTIyamAna':['resort to'],
	'AsTAsyat':['resort to'],
	'AsTAsyamAna':['resort to'],
	'AtasTivas':['resort to'],
	'AtasTAna':['resort to'],
	'AsTita':['resort to'],
	'AsTitavat':['resort to'],
	'AsTAtum':['resort to'],
	'AsTAya':['resort to'],
	'AsTeya':['resort to'],
	'AsTAtavya':['resort to'],
	'AsTAnIya':['resort to'],
	'A_hf':['bring, fetch'],
	'Aharat':['bring, fetch'],
	'AharamARa':['bring, fetch'],
	'AhriyamARa':['bring, fetch'],
	'Aharizyat':['bring, fetch'],
	'AharizyamARa':['bring, fetch'],
	'Ajahfvas':['bring, fetch'],
	'AjahrARa':['bring, fetch'],
	'Ahfta':['bring, fetch'],
	'Ahftavat':['bring, fetch'],
	'Ahartum':['bring, fetch'],
	'Ahftya':['bring, fetch'],
	'AhArya':['bring, fetch'],
	'Ahartavya':['bring, fetch'],
	'AharaRIya':['bring, fetch'],
	'A_hve':['call, summon'],
	'Ahvayat':['call, summon'],
	'AhvayamAna':['call, summon'],
	'AhUyamAna':['call, summon'],
	'AhvAsyat':['call, summon'],
	'AhvAsyamAna':['call, summon'],
	'AjuhUvas':['call, summon'],
	'AjuhuvAna':['call, summon'],
	'AhUta':['call, summon'],
	'AhUtavat':['call, summon'],
	'AhvAtum':['call, summon'],
	'AhUya':['call, summon'],
	'AhvAya':['call, summon'],
	'AhvAtavya':['call, summon'],
	'AhvAnIya':['call, summon'],
	'i':['go'],
	'yat':['go'],
	'IyamAna':['go'],
	'ezyat':['go'],
	'ezyamARa':['go'],
	'Iyivas':['go'],
	'IyAna':['go'],
	'ita':['go'],
	'itavat':['go'],
	'etum':['go'],
	'itvA':['go'],
	'itya':['go'],
	'etavya':['go'],
	'ayanIya':['go'],
	'itara':['other'],
	'itas':['from this, hence'],
	'iti':['thus, close quote'],
	'itTam':['in this way, thus'],
	'idam':['this'],
	'indra':['Indra'],
	'iva':['like, as'],
	'iz':['desire'],
	'icCat':['desire'],
	'izyamARa':['desire'],
	'ezizyat':['desire'],
	'ezizyamARa':['desire'],
	'Izivas':['desire'],
	'IzARa':['desire'],
	'izwa':['desire', 'worship, perform a Vedic ceremony'],
	'izwavat':['desire', 'worship, perform a Vedic ceremony'],
	'ezwum':['desire'],
	'izwvA':['desire', 'worship, perform a Vedic ceremony'],
	'ezya':['desire'],
	'ezwavya':['desire'],
	'ezaRIya':['desire'],
	'iha':['here'],
	'Ikz':['see'],
	'IkzamARa':['see'],
	'IkzyamARa':['see'],
	'IkzizyamARa':['see'],
	'IkzAm':['see'],
	'Ikzita':['see'],
	'Ikzitavat':['see'],
	'Ikzitum':['see'],
	'IkzitvA':['see'],
	'Ikzya':['see'],
	'Ikzitavya':['see'],
	'IkzaRIya':['see'],
	'ud':['up'],
	'udaka':['water'],
	'ud_kram':['go up, ascend, transgress'],
	'utkrAmat':['go up, ascend, transgress'],
	'utkrAmyat':['go up, ascend, transgress'],
	'utkramyamARa':['go up, ascend, transgress'],
	'utkraMsyat':['go up, ascend, transgress'],
	'utkraMsyamAna':['go up, ascend, transgress'],
	'uccakramvas':['go up, ascend, transgress'],
	'uccakramARa':['go up, ascend, transgress'],
	'utkrAnta':['go up, ascend, transgress'],
	'utkrAntavat':['go up, ascend, transgress'],
	'utkramitum':['go up, ascend, transgress'],
	'utkramya':['go up, ascend, transgress'],
	'utkramitavya':['go up, ascend, transgress'],
	'utkramaRIya':['go up, ascend, transgress'],
	'ud_gam':['go up, rise'],
	'udgacCat':['go up, rise'],
	'udgamyamAna':['go up, rise'],
	'udgamizyat':['go up, rise'],
	'udgamizyamARa':['go up, rise'],
	'ujjagmivas':['go up, rise'],
	'ujjaganvas':['go up, rise'],
	'ujjagmAna':['go up, rise'],
	'udgata':['go up, rise'],
	'udgatavat':['go up, rise'],
	'udgantum':['go up, rise'],
	'udgamya':['go up, rise'],
	'udgatya':['go up, rise'],
	'udgantavya':['go up, rise'],
	'udgamanIya':['go up, rise'],
	'udgAtf':['udgātṛ'],
	'ud_pad':['step up, arise'],
	'utpadyamAna':['step up, arise'],
	'utpatsyamAna':['step up, arise'],
	'utpedAna':['step up, arise'],
	'utpanna':['step up, arise'],
	'utpannavat':['step up, arise'],
	'utpattum':['step up, arise'],
	'utpadya':['step up, arise'],
	'utpAdya':['step up, arise'],
	'utpattavya':['step up, arise'],
	'utpadanIya':['step up, arise'],
	'ud_banD':["bind up, hang one's self"],
	'udbaDnat':["bind up, hang one's self"],
	'udbaDyamAna':["bind up, hang one's self"],
	'udBantsyat':["bind up, hang one's self"],
	'udBantsyamAna':["bind up, hang one's self"],
	'udbabanDvas':["bind up, hang one's self"],
	'udbabanDAna':["bind up, hang one's self"],
	'udbadDa':["bind up, hang one's self"],
	'udbadDavat':["bind up, hang one's self"],
	'udbandDum':["bind up, hang one's self"],
	'udbaDya':["bind up, hang one's self"],
	'udbanDya':["bind up, hang one's self"],
	'udbandDavya':["bind up, hang one's self"],
	'udbanDanIya':["bind up, hang one's self"],
	'ud_yam':['lift up, undertake, strive'],
	'udyacCat':['lift up, undertake, strive'],
	'udyacCamAna':['lift up, undertake, strive'],
	'udyamyamAna':['lift up, undertake, strive'],
	'udyaMsyat':['lift up, undertake, strive'],
	'udyaMsyamAna':['lift up, undertake, strive'],
	'udyemivas':['lift up, undertake, strive'],
	'udyemAna':['lift up, undertake, strive'],
	'udyata':['lift up, undertake, strive'],
	'udyatavat':['lift up, undertake, strive'],
	'udyantum':['lift up, undertake, strive'],
	'udyamya':['lift up, undertake, strive'],
	'udyatya':['lift up, undertake, strive'],
	'udyantavya':['lift up, undertake, strive'],
	'udyamanIya':['lift up, undertake, strive'],
	'ud_sTA':['stand up, arise'],
	'uttizWat':['stand up, arise'],
	'uttizWamAna':['stand up, arise'],
	'utsTIyamAna':['stand up, arise'],
	'utTAsyat':['stand up, arise'],
	'utTAsyamAna':['stand up, arise'],
	'uttasTivas':['stand up, arise'],
	'uttasTAna':['stand up, arise'],
	'utTita':['stand up, arise'],
	'utTitavat':['stand up, arise'],
	'utTAtum':['stand up, arise'],
	'utTAya':['stand up, arise'],
	'utTeya':['stand up, arise'],
	'utTAtavya':['stand up, arise'],
	'utTAnIya':['stand up, arise'],
	'ud_hf':['take up, raise, rescue'],
	'udDarat':['take up, raise, rescue'],
	'udDaramARa':['take up, raise, rescue'],
	'udDriyamARa':['take up, raise, rescue'],
	'udDarizyat':['take up, raise, rescue'],
	'udDarizyamARa':['take up, raise, rescue'],
	'ujjahfvas':['take up, raise, rescue'],
	'ujjahrARa':['take up, raise, rescue'],
	'udDfta':['take up, raise, rescue'],
	'udDftavat':['take up, raise, rescue'],
	'udDartum':['take up, raise, rescue'],
	'udDftya':['take up, raise, rescue'],
	'udDArya':['take up, raise, rescue'],
	'udDartavya':['take up, raise, rescue'],
	'udDaraRIya':['take up, raise, rescue'],
	'upa':['near'],
	'upa_i':['approach'],
	'upayat':['approach'],
	'upeyamAna':['approach'],
	'upEzyat':['approach'],
	'upEzyamARa':['approach'],
	'upeyivas':['approach'],
	'upeyAna':['approach'],
	'upeta':['approach'],
	'upetavat':['approach'],
	'upEtum':['approach'],
	'upetya':['approach'],
	'upEtavya':['approach'],
	'upAyanIya':['approach'],
	'upa_kram':['approach (active); begin (middle)'],
	'upakrAmat':['approach (active); begin (middle)'],
	'upakrAmyat':['approach (active); begin (middle)'],
	'upakramamARa':['approach (active); begin (middle)'],
	'upakramyamARa':['approach (active); begin (middle)'],
	'upakraMsyat':['approach (active); begin (middle)'],
	'upakraMsyamAna':['approach (active); begin (middle)'],
	'upacakramvas':['approach (active); begin (middle)'],
	'upacakramARa':['approach (active); begin (middle)'],
	'upakrAnta':['approach (active); begin (middle)'],
	'upakrAntavat':['approach (active); begin (middle)'],
	'upakrAntum':['approach (active); begin (middle)'],
	'upakramitum':['approach (active); begin (middle)'],
	'upakramya':['approach (active); begin (middle)'],
	'upakramitavya':['approach (active); begin (middle)'],
	'upakramaRIya':['approach (active); begin (middle)'],
	'upa_gam':['approach'],
	'upagacCat':['approach'],
	'upagamyamAna':['approach'],
	'upagamizyat':['approach'],
	'upagamizyamARa':['approach'],
	'upajagmivas':['approach'],
	'upajaganvas':['approach'],
	'upajagmAna':['approach'],
	'upagata':['approach'],
	'upagatavat':['approach'],
	'upagantum':['approach'],
	'upagamya':['approach'],
	'upagatya':['approach'],
	'upagantavya':['approach'],
	'upagamanIya':['approach'],
	'upa_pad':['approach, occur, be suitable'],
	'upapadyamAna':['approach, occur, be suitable'],
	'upapatsyamAna':['approach, occur, be suitable'],
	'upapedAna':['approach, occur, be suitable'],
	'upapanna':['approach, occur, be suitable'],
	'upapannavat':['approach, occur, be suitable'],
	'upapattum':['approach, occur, be suitable'],
	'upapadya':['approach, occur, be suitable'],
	'upapAdya':['approach, occur, be suitable'],
	'upapattavya':['approach, occur, be suitable'],
	'upapadanIya':['approach, occur, be suitable'],
	'upa_banD':['bind near'],
	'upabaDnat':['bind near'],
	'upabaDyamAna':['bind near'],
	'upaBantsyat':['bind near'],
	'upaBantsyamAna':['bind near'],
	'upababanDvas':['bind near'],
	'upababanDAna':['bind near'],
	'upabadDa':['bind near'],
	'upabadDavat':['bind near'],
	'upabandDum':['bind near'],
	'upabaDya':['bind near'],
	'upabanDya':['bind near'],
	'upabandDavya':['bind near'],
	'upabanDanIya':['bind near'],
	'upa_yam':['hold near, take, offer (active), embrace (middle)'],
	'upayacCat':['hold near, take, offer (active), embrace (middle)'],
	'upayacCamAna':['hold near, take, offer (active), embrace (middle)'],
	'upayamyamAna':['hold near, take, offer (active), embrace (middle)'],
	'upayaMsyat':['hold near, take, offer (active), embrace (middle)'],
	'upayaMsyamAna':['hold near, take, offer (active), embrace (middle)'],
	'upayemivas':['hold near, take, offer (active), embrace (middle)'],
	'upayemAna':['hold near, take, offer (active), embrace (middle)'],
	'upayata':['hold near, take, offer (active), embrace (middle)'],
	'upayatavat':['hold near, take, offer (active), embrace (middle)'],
	'upayantum':['hold near, take, offer (active), embrace (middle)'],
	'upayamya':['hold near, take, offer (active), embrace (middle)'],
	'upayatya':['hold near, take, offer (active), embrace (middle)'],
	'upayantavya':['hold near, take, offer (active), embrace (middle)'],
	'upayamanIya':['hold near, take, offer (active), embrace (middle)'],
	'upa_viS':['sit'],
	'upaviSat':['sit'],
	'upaviSyamAna':['sit'],
	'upavekzyat':['sit'],
	'upavekzyamARa':['sit'],
	'upaviviSvas':['sit'],
	'upaviviSivas':['sit'],
	'upaviviSAna':['sit'],
	'upavizwa':['sit'],
	'upavizwavat':['sit'],
	'upavezwum':['sit'],
	'upaviSya':['sit'],
	'upaveSya':['sit'],
	'upavezwavya':['sit'],
	'upaveSanIya':['sit'],
	'upa_sTA':['stay near, be present'],
	'upatizWat':['stay near, be present'],
	'upatizWamAna':['stay near, be present'],
	'upasTIyamAna':['stay near, be present'],
	'upasTAsyat':['stay near, be present'],
	'upasTAsyamAna':['stay near, be present'],
	'upatasTivas':['stay near, be present'],
	'upatasTAna':['stay near, be present'],
	'upasTita':['stay near, be present'],
	'upasTitavat':['stay near, be present'],
	'upasTAtum':['stay near, be present'],
	'upasTAya':['stay near, be present'],
	'upasTeya':['stay near, be present'],
	'upasTAtavya':['stay near, be present'],
	'upasTAnIya':['stay near, be present'],
	'upa_hf':['bring near, offer'],
	'upaharat':['bring near, offer'],
	'upaharamARa':['bring near, offer'],
	'upahriyamARa':['bring near, offer'],
	'upaharizyat':['bring near, offer'],
	'upaharizyamARa':['bring near, offer'],
	'upajahfvas':['bring near, offer'],
	'upajahrARa':['bring near, offer'],
	'upahfta':['bring near, offer'],
	'upahftavat':['bring near, offer'],
	'upahartum':['bring near, offer'],
	'upahftya':['bring near, offer'],
	'upahArya':['bring near, offer'],
	'upahartavya':['bring near, offer'],
	'upaharaRIya':['bring near, offer'],
	'uBa':['both'],
	'uraRa':['lamb'],
	'urvaSI':['Urvaśī'],
	'uzas':['dawn'],
	'uzRa':['warm'],
	'fgveda':['<i>Ṛgveda</i>'],
	'fc':['Ṛgvedic verse'],
	'ftvij':['officiant in Vedic performance'],
	'fzi':['Vedic seer'],
	'eka':['one'],
	'etad':['this'],
	'etarhi':['for this reason'],
	'etAvat':['of this extent', 'to this extent'],
	'enad':['this, it (2, 3s, 6d, 7d)'],
	'eva':['only, just, very'],
	'evam':['in this way, thus'],
	'kaT':['tell, narrate'],
	'kaTayat':['tell, narrate'],
	'kaTayamAna':['tell, narrate'],
	'kaTyamAna':['tell, narrate'],
	'kaTayizyat':['tell, narrate'],
	'kaTayizyamARa':['tell, narrate'],
	'kaTayAm':['tell, narrate'],
	'kaTita':['tell, narrate'],
	'kaTitavat':['tell, narrate'],
	'kaTayitum':['tell, narrate'],
	'kaTayitvA':['tell, narrate'],
	'kaTya':['tell, narrate'],
	'kaTayitavya':['tell, narrate'],
	'kaTanIya':['tell, narrate'],
	'kaTam':['how'],
	'kaTA':['story'],
	'kadA':['when'],
	'kanyA':['girl'],
	'kam':['desire, love'],
	'kAmayamAna':['desire, love'],
	'kAmyamAna':['desire, love'],
	'kamizyamARa':['desire, love'],
	'kAmayizyamARa':['desire, love'],
	'cakamAna':['desire, love'],
	'kAmayAm':['desire, love'],
	'kAnta':['desire, love'],
	'kAntavat':['desire, love'],
	'kamitum':['desire, love'],
	'kAmayitum':['desire, love'],
	'kAntvA':['desire, love'],
	'kamitvA':['desire, love'],
	'kAmayitvA':['desire, love'],
	'kAmya':['desire, love'],
	'kamitavya':['desire, love'],
	'kAmayitavya':['desire, love'],
	'kamanIya':['desire, love'],
	'kAmanIya':['desire, love'],
	'karman':['action, deed'],
	'kAma':['love, the god of love'],
	'kArya':['work', 'do, make'],
	'kAla':['time'],
	'kim':['which/who/what', 'question marker'],
	'kiyat':['of what extent', 'to what extent'],
	'kutas':['whence, why'],
	'kutra':['where'],
	'kumAra':['boy, youth'],
	'kurukzetra':['field of the Kuru s, Kurukṣetra'],
	'kula':['family'],
	'kuSala':['skillful', 'welfare'],
	'kf':['do, make'],
	'kurvat':['do, make'],
	'kurvARa':['do, make'],
	'kriyamARa':['do, make'],
	'karizyat':['do, make'],
	'karizyamARa':['do, make'],
	'cakfvas':['do, make'],
	'cakrARa':['do, make'],
	'kfta':['do, make'],
	'kftavat':['do, make'],
	'kartum':['do, make'],
	'kftvA':['do, make'],
	'kftya':['do, make'],
	'kartavya':['do, make'],
	'karaRIya':['do, make'],
	'kfSa':['thin'],
	'kevala':['alone, lone'],
	'kram':['step (active); continue, go, increase (middle)'],
	'krAmat':['step (active); continue, go, increase (middle)'],
	'krAmyat':['step (active); continue, go, increase (middle)'],
	'kramamARa':['step (active); continue, go, increase (middle)'],
	'kramyamARa':['step (active); continue, go, increase (middle)'],
	'kraMsyat':['step (active); continue, go, increase (middle)'],
	'kraMsyamAna':['step (active); continue, go, increase (middle)'],
	'cakramvas':['step (active); continue, go, increase (middle)'],
	'cakramARa':['step (active); continue, go, increase (middle)'],
	'krAnta':['step (active); continue, go, increase (middle)'],
	'krAntavat':['step (active); continue, go, increase (middle)'],
	'krAntum':['step (active); continue, go, increase (middle)'],
	'kramitum':['step (active); continue, go, increase (middle)'],
	'krantvA':['step (active); continue, go, increase (middle)'],
	'krAntvA':['step (active); continue, go, increase (middle)'],
	'kramitvA':['step (active); continue, go, increase (middle)'],
	'kramya':['step (active); continue, go, increase (middle)'],
	'kramitavya':['step (active); continue, go, increase (middle)'],
	'kramaRIya':['step (active); continue, go, increase (middle)'],
	'krI':['buy'],
	'krIRat':['buy'],
	'krIRAna':['buy'],
	'krIyamARa':['buy'],
	'krezyat':['buy'],
	'krezyamARa':['buy'],
	'cikrIvas':['buy'],
	'cikriyARa':['buy'],
	'krIta':['buy'],
	'krItavat':['buy'],
	'kretum':['buy'],
	'krItvA':['buy'],
	'kreya':['buy'],
	'kretavya':['buy'],
	'krayaRIya':['buy'],
	'kruD':['be angry'],
	'kruDyat':['be angry'],
	'kruDyamAna':['be angry'],
	'krotsyat':['be angry'],
	'krotsyamAna':['be angry'],
	'cukruDvas':['be angry'],
	'cukruDAna':['be angry'],
	'krudDa':['be angry'],
	'krudDavat':['be angry'],
	'krodDum':['be angry'],
	'krudDvA':['be angry'],
	'kroDya':['be angry'],
	'krodDavya':['be angry'],
	'kroDanIya':['be angry'],
	'kva':['where'],
	'kzaRa':['moment'],
	'kzatriya':['warrior'],
	'ganDarva':['gandharva'],
	'gam':['go'],
	'gacCat':['go'],
	'gamyamAna':['go'],
	'gamizyat':['go'],
	'gamizyamARa':['go'],
	'jagmivas':['go'],
	'jaganvas':['go'],
	'jagmAna':['go'],
	'gata':['go'],
	'gatavat':['go'],
	'gantum':['go'],
	'gatvA':['go'],
	'gamya':['go'],
	'gantavya':['go'],
	'gamanIya':['go'],
	'garBa':['womb, embryo'],
	'garBin':['pregnant'],
	'guru':['heavy, important', 'teacher'],
	'gfha':['house'],
	'gF':['sing, praise, recite, invoke'],
	'gfRat':['sing, praise, recite, invoke'],
	'gIryamARa':['sing, praise, recite, invoke'],
	'garizyat':['sing, praise, recite, invoke'],
	'garizyamARa':['sing, praise, recite, invoke'],
	'jagarvas':['sing, praise, recite, invoke'],
	'jagarARa':['sing, praise, recite, invoke'],
	'gIrRa':['sing, praise, recite, invoke'],
	'gIrRavat':['sing, praise, recite, invoke'],
	'garitum':['sing, praise, recite, invoke'],
	'gIrtvA':['sing, praise, recite, invoke'],
	'grah':['grab, grasp, seize'],
	'gfhRat':['grab, grasp, seize'],
	'gfhARa':['grab, grasp, seize'],
	'gfhyamARa':['grab, grasp, seize'],
	'grahIzyat':['grab, grasp, seize'],
	'grahIzyamARa':['grab, grasp, seize'],
	'jagfhvas':['grab, grasp, seize'],
	'jagfhARa':['grab, grasp, seize'],
	'gfhIta':['grab, grasp, seize'],
	'gfhItavat':['grab, grasp, seize'],
	'grahItum':['grab, grasp, seize'],
	'gfhItvA':['grab, grasp, seize'],
	'grAhya':['grab, grasp, seize'],
	'grahItavya':['grab, grasp, seize'],
	'grahaRIya':['grab, grasp, seize'],
	'grAma':['village'],
	'Gawa':['pot'],
	'Gora':['horrible'],
	'ca':['and'],
	'catur':['four'],
	'caturTa':['fourth'],
	'candra':['moon', 'Hari Candra'],
	'car':['move, do'],
	'carat':['move, do'],
	'caramARa':['move, do'],
	'caryamARa':['move, do'],
	'carizyat':['move, do'],
	'carizyamARa':['move, do'],
	'cerivas':['move, do'],
	'cerARa':['move, do'],
	'carita':['move, do'],
	'caritavat':['move, do'],
	'caritum':['move, do'],
	'caritvA':['move, do'],
	'carya':['move, do'],
	'caritavya':['move, do'],
	'caraRIya':['move, do'],
	'cArin':['doing, practicing'],
	'cit':['/'],
	'citra':['conspicuous, bright, variegated, different'],
	'cint':['consider'],
	'cintayat':['consider'],
	'cintayamAna':['consider'],
	'cintyamAna':['consider'],
	'cintayizyat':['consider'],
	'cintayizyamARa':['consider'],
	'cintayAm':['consider'],
	'cintita':['consider'],
	'cintitavat':['consider'],
	'cintitum':['consider'],
	'cintitvA':['consider'],
	'cintya':['consider'],
	'cintitavya':['consider'],
	'cintanIya':['consider'],
	'cira':['long'],
	'cur':['steal'],
	'corayat':['steal'],
	'coryamARa':['steal'],
	'corayizyat':['steal'],
	'corayizyamARa':['steal'],
	'corayAm':['steal'],
	'corita':['steal'],
	'coritavat':['steal'],
	'corayitum':['steal'],
	'corayitvA':['steal'],
	'cOra':['thief'],
	'Cid':['cleave'],
	'Cindat':['cleave'],
	'CindAna':['cleave'],
	'CidyamAna':['cleave'],
	'Cetsyat':['cleave'],
	'CetsyamAna':['cleave'],
	'cicCidvas':['cleave'],
	'cicCidAna':['cleave'],
	'Cinna':['cleave'],
	'Cinnavat':['cleave'],
	'Cettum':['cleave'],
	'CittvA':['cleave'],
	'Cedya':['cleave'],
	'Cettavya':['cleave'],
	'CedanIya':['cleave'],
	'jagat':['moving, world'],
	'jan':['be born, arise; caus. generate'],
	'jAyamAna':['be born, arise; caus. generate'],
	'janizyamARa':['be born, arise; caus. generate'],
	'jajYAna':['be born, arise; caus. generate', 'know'],
	'jAta':['be born, arise; caus. generate'],
	'jAtavat':['be born, arise; caus. generate'],
	'janitum':['be born, arise; caus. generate'],
	'janitvA':['be born, arise; caus. generate'],
	'janya':['be born, arise; caus. generate'],
	'janitavya':['be born, arise; caus. generate'],
	'jananIya':['be born, arise; caus. generate'],
	'janayAm':['be born, arise; caus. generate'],
	'jana':['people or person'],
	'ji':['conquer'],
	'jayat':['conquer'],
	'jIyamAna':['conquer'],
	'jezyat':['conquer'],
	'jezyamARa':['conquer'],
	'jigivas':['conquer'],
	'jigyAna':['conquer'],
	'jita':['conquer'],
	'jitavat':['conquer'],
	'jetum':['conquer'],
	'jitvA':['conquer'],
	'jeya':['conquer'],
	'jetavya':['conquer'],
	'jayanIya':['conquer'],
	'jIv':['live'],
	'jIvat':['live'],
	'jIvyamAna':['live'],
	'jIvizyat':['live'],
	'jIvizyamARa':['live'],
	'jijIvas':['live'],
	'jijIvAna':['live'],
	'jIvita':['live'],
	'jIvitavat':['live'],
	'jIvitum':['live'],
	'jIvitvA':['live'],
	'jIvya':['live'],
	'jIvitavya':['live'],
	'jIvanIya':['live'],
	'jF':['wither, age, grow old'],
	'jIryat':['wither, age, grow old'],
	'jIryamARa':['wither, age, grow old'],
	'jarizyat':['wither, age, grow old'],
	'jarizyamARa':['wither, age, grow old'],
	'jajarvas':['wither, age, grow old'],
	'jerivas':['wither, age, grow old'],
	'jajarARa':['wither, age, grow old'],
	'jerARa':['wither, age, grow old'],
	'jIrRa':['wither, age, grow old'],
	'jIrRavat':['wither, age, grow old'],
	'jaritum':['wither, age, grow old'],
	'jarItum':['wither, age, grow old'],
	'jaritvA':['wither, age, grow old'],
	'jarItvA':['wither, age, grow old'],
	'jarya':['wither, age, grow old'],
	'jArya':['wither, age, grow old'],
	'jaritavya':['wither, age, grow old'],
	'jarItavya':['wither, age, grow old'],
	'jaraRIya':['wither, age, grow old'],
	'jYA':['know'],
	'jAnat':['know'],
	'jYAyamAna':['know'],
	'jYAsyat':['know'],
	'jYAsyamAna':['know'],
	'jajYivas':['know'],
	'jYAta':['know'],
	'jYAtavat':['know'],
	'jYAtum':['know'],
	'jYAtvA':['know'],
	'jYeya':['know'],
	'jYAtavya':['know'],
	'jYAnIya':['know'],
	'jYAna':['knowledge'],
	'tatas':['from that, therefore, then'],
	'tatra':['there'],
	'taTA':['in that manner, thus'],
	'tad':['that, the', 'so'],
	'tadA':['at that time, then'],
	'tan':['extend'],
	'tanvat':['extend'],
	'tanvAna':['extend'],
	'tanyamAna':['extend'],
	'taMsyat':['extend'],
	'tanizyat':['extend'],
	'taMsyamAna':['extend'],
	'tanizyamARa':['extend'],
	'tenivas':['extend'],
	'tenAna':['extend'],
	'tata':['extend'],
	'tatavat':['extend'],
	'tanitum':['extend'],
	'tanitvA':['extend'],
	'tAnya':['extend'],
	'tanitavya':['extend'],
	'tananIya':['extend'],
	'tarhi':['then, therefore'],
	'tAvat':['of that extent', 'to that extent'],
	'tiras':['across, horizontal'],
	'tiras_BU':['become horizontal, disappear'],
	'tiroBavat':['become horizontal, disappear'],
	'tiroBUyamAna':['become horizontal, disappear'],
	'tiroBavizyat':['become horizontal, disappear'],
	'tiroBavizyamARa':['become horizontal, disappear'],
	'tirobaBUvas':['become horizontal, disappear'],
	'tiroBUta':['become horizontal, disappear'],
	'tiroBUtavat':['become horizontal, disappear'],
	'tiroBavitum':['become horizontal, disappear'],
	'tiroBUya':['become horizontal, disappear'],
	'tiroBavya':['become horizontal, disappear'],
	'tiroBavitavya':['become horizontal, disappear'],
	'tiroBavanIya':['become horizontal, disappear'],
	'tu':['but'],
	'tftIya':['third'],
	'tyaj':['abandon'],
	'tyajat':['abandon'],
	'tyajyamAna':['abandon'],
	'tyakzyat':['abandon'],
	'tyakzyamARa':['abandon'],
	'tatyajvas':['abandon'],
	'tityajvas':['abandon'],
	'tatyajAna':['abandon'],
	'tityajAna':['abandon'],
	'tyakta':['abandon'],
	'tyaktavat':['abandon'],
	'tyaktum':['abandon'],
	'tyaktvA':['abandon'],
	'tyAjya':['abandon'],
	'tyaktavya':['abandon'],
	'tyajanIya':['abandon'],
	'tri':['three'],
	'trE':['save'],
	'trAyamARa':['save'],
	'trAsyamAna':['save'],
	'tatrARa':['save'],
	'trAta':['save'],
	'trARa':['save'],
	'trAtavat':['save'],
	'trAtum':['save'],
	'trAtvA':['save'],
	'danta':['tooth, tusk'],
	'daSan':['ten'],
	'daSama':['tenth'],
	'dah':['burn'],
	'dahat':['burn'],
	'dahyamAna':['burn'],
	'Dakzyat':['burn'],
	'DakzyamARa':['burn'],
	'dadahvas':['burn'],
	'dadahAna':['burn'],
	'dagDa':['burn'],
	'dagDavat':['burn'],
	'dagDum':['burn'],
	'dagDvA':['burn'],
	'dAhya':['burn'],
	'dagDavya':['burn'],
	'dahanIya':['burn'],
	'dA':['give'],
	'dadat':['give'],
	'dadAna':['give'],
	'dIyamAna':['give'],
	'dAsyat':['give'],
	'dAsyamAna':['give'],
	'dadivas':['give'],
	'datta':['give'],
	'dattavat':['give'],
	'dAtum':['give'],
	'dattvA':['give'],
	'deya':['give'],
	'dAtavya':['give'],
	'dAnIya':['give'],
	'dina':['day'],
	'div':['sky, heaven, day', 'play'],
	'dIvyat':['play'],
	'dIvyamAna':['play'],
	'devizyat':['play'],
	'devizyamARa':['play'],
	'didivas':['play'],
	'didivAna':['play'],
	'dyUta':['play'],
	'dyUtavat':['play'],
	'devitum':['play'],
	'dyUtvA':['play'],
	'devitvA':['play'],
	'devya':['play'],
	'devitavya':['play'],
	'devanIya':['play'],
	'diS':['direction'],
	'dIrGa':['long'],
	'dIrGAyus':['whose life is long, long-lived'],
	'duHKa':['uncomfortable', 'sorrow'],
	'duHKin':['sorrowful, sad'],
	'durApa':['difficult to obtain'],
	'durmanas':['low-spirited, sad'],
	'duzzanta':['Duṣṣanta'],
	'dus':['ill'],
	'duhitf':['daughter'],
	'dUra':['distant'],
	'dfS':['see, watch'],
	'paSyat':['see, watch'],
	'dfSyamAna':['see, watch'],
	'drakzyat':['see, watch'],
	'drakzyamARa':['see, watch'],
	'dadfSvas':['see, watch'],
	'dadfSivas':['see, watch'],
	'dadfSAna':['see, watch'],
	'dfzwa':['see, watch'],
	'dfzwavat':['see, watch'],
	'drazwum':['see, watch'],
	'dfzwvA':['see, watch'],
	'dfSya':['see, watch'],
	'drazwavya':['see, watch'],
	'darSanIya':['see, watch'],
	'deva':['god'],
	'devI':['goddess'],
	'deSa':['place, country'],
	'deha':['body'],
	'dvi':['two'],
	'dvitIya':['second'],
	'Dana':['wealth, money'],
	'Danin':['wealthy'],
	'Danus':['bow'],
	'Darma':['dharma , duty, right action'],
	'DA':['put'],
	'daDat':['put'],
	'daDAna':['put'],
	'DIyamAna':['put'],
	'DAsyat':['put'],
	'DAsyamAna':['put'],
	'daDivas':['put'],
	'hita':['put', 'impel, send'],
	'hitavat':['put', 'impel, send'],
	'DAtum':['put'],
	'hitvA':['put', 'leave, abandon', 'impel, send'],
	'Deya':['put'],
	'DAtavya':['put'],
	'DAnIya':['put'],
	'DArtarAzwra':['descendant of Dhṛtarāṣṭra'],
	'DI':['intelligence, thought'],
	'DImat':['wise, intelligent'],
	'Denu':['cow'],
	'na':['not'],
	'nagara':['city'],
	'nagna':['naked'],
	'nadI':['river'],
	'nara':['man'],
	'navan':['nine'],
	'navama':['ninth'],
	'nAma':['by name'],
	'nAman':['name'],
	'ni':['in'],
	'ni_DA':['put down, deposit'],
	'nidaDat':['put down, deposit'],
	'nidaDAna':['put down, deposit'],
	'niDIyamAna':['put down, deposit'],
	'niDAsyat':['put down, deposit'],
	'niDAsyamAna':['put down, deposit'],
	'nidaDivas':['put down, deposit'],
	'nihita':['put down, deposit'],
	'nihitavat':['put down, deposit'],
	'niDAtum':['put down, deposit'],
	'niDAya':['put down, deposit'],
	'niDeya':['put down, deposit'],
	'niDAtavya':['put down, deposit'],
	'niDAnIya':['put down, deposit'],
	'nis':['out'],
	'nis_kram':['go out, depart'],
	'nizkrAmat':['go out, depart'],
	'nizkrAmyat':['go out, depart'],
	'nizkramyamARa':['go out, depart'],
	'nizkraMsyat':['go out, depart'],
	'nizkraMsyamAna':['go out, depart'],
	'niScakramvas':['go out, depart'],
	'niScakramARa':['go out, depart'],
	'nizkrAnta':['go out, depart'],
	'nizkrAntavat':['go out, depart'],
	'nizkramitum':['go out, depart'],
	'nizkramya':['go out, depart'],
	'nizkramitavya':['go out, depart'],
	'nizkramaRIya':['go out, depart'],
	'nis_gam':['go out, emerge'],
	'nirgacCat':['go out, emerge'],
	'nirgamyamAna':['go out, emerge'],
	'nirgamizyat':['go out, emerge'],
	'nirgamizyamARa':['go out, emerge'],
	'nirjagmivas':['go out, emerge'],
	'nirjaganvas':['go out, emerge'],
	'nirjagmAna':['go out, emerge'],
	'nirgata':['go out, emerge'],
	'nirgatavat':['go out, emerge'],
	'nirgantum':['go out, emerge'],
	'nirgamya':['go out, emerge'],
	'nirgatya':['go out, emerge'],
	'nirgantavya':['go out, emerge'],
	'nirgamanIya':['go out, emerge'],
	'nI':['lead'],
	'nayat':['lead'],
	'nayamAna':['lead'],
	'nIyamAna':['lead'],
	'nezyat':['lead'],
	'nezyamARa':['lead'],
	'ninIvas':['lead'],
	'ninyAna':['lead'],
	'nIta':['lead'],
	'nItavat':['lead'],
	'netum':['lead'],
	'nItvA':['lead'],
	'neya':['lead'],
	'netavya':['lead'],
	'nayanIya':['lead'],
	'nfpa':['king'],
	'pakza':['wing, side'],
	'pac':['cook'],
	'pacat':['cook'],
	'pacamAna':['cook'],
	'pacyamAna':['cook'],
	'pakzyat':['cook'],
	'pakzyamARa':['cook'],
	'pecivas':['cook'],
	'pecAna':['cook'],
	'pakva':['cook'],
	'pakvavat':['cook'],
	'paktum':['cook'],
	'paktvA':['cook'],
	'pAkya':['cook'],
	'paktavya':['cook'],
	'pacanIya':['cook'],
	'paYcan':['five'],
	'paYcama':['fifth'],
	'paW':['read aloud, recite'],
	'paWat':['read aloud, recite'],
	'paWyamAna':['read aloud, recite'],
	'paWizyat':['read aloud, recite'],
	'paWizyamARa':['read aloud, recite'],
	'peWivas':['read aloud, recite'],
	'peWAna':['read aloud, recite'],
	'paWita':['read aloud, recite'],
	'paWitavat':['read aloud, recite'],
	'paWitum':['read aloud, recite'],
	'paWitvA':['read aloud, recite'],
	'pAWya':['read aloud, recite'],
	'paWitavya':['read aloud, recite'],
	'paWanIya':['read aloud, recite'],
	'pat':['fall, fly'],
	'patat':['fall, fly'],
	'patyamAna':['fall, fly'],
	'patizyat':['fall, fly'],
	'patizyamARa':['fall, fly'],
	'petivas':['fall, fly'],
	'petAna':['fall, fly'],
	'patita':['fall, fly'],
	'patitavat':['fall, fly'],
	'patitum':['fall, fly'],
	'patitvA':['fall, fly'],
	'pAtya':['fall, fly'],
	'patitavya':['fall, fly'],
	'patanIya':['fall, fly'],
	'pati':['lord, husband'],
	'patnI':['wife'],
	'pad':['step'],
	'padyamAna':['step'],
	'patsyamAna':['step'],
	'pedAna':['step'],
	'panna':['step'],
	'pannavat':['step'],
	'pattum':['step'],
	'pattvA':['step'],
	'pAdya':['step'],
	'pattavya':['step'],
	'padanIya':['step'],
	'pada':['step'],
	'para':['farther'],
	'param':['after, beyond'],
	'pari':['around'],
	'pari_car':['move around'],
	'paricarat':['move around'],
	'paricaramARa':['move around'],
	'paricaryamARa':['move around'],
	'paricarizyat':['move around'],
	'paricarizyamARa':['move around'],
	'paricerivas':['move around'],
	'paricerARa':['move around'],
	'paricarita':['move around'],
	'paricaritavat':['move around'],
	'paricaritum':['move around'],
	'paricarya':['move around'],
	'paricaritavya':['move around'],
	'paricaraRIya':['move around'],
	'pari_DA':['wear, put on (clothes)'],
	'paridaDat':['wear, put on (clothes)'],
	'paridaDAna':['wear, put on (clothes)'],
	'pariDIyamAna':['wear, put on (clothes)'],
	'pariDAsyat':['wear, put on (clothes)'],
	'pariDAsyamAna':['wear, put on (clothes)'],
	'paridaDivas':['wear, put on (clothes)'],
	'parihita':['wear, put on (clothes)'],
	'parihitavat':['wear, put on (clothes)'],
	'pariDAtum':['wear, put on (clothes)'],
	'pariDAya':['wear, put on (clothes)'],
	'pariDeya':['wear, put on (clothes)'],
	'pariDAtavya':['wear, put on (clothes)'],
	'pariDAnIya':['wear, put on (clothes)'],
	'parvata':['mountain'],
	'paSu':['domestic animal'],
	'paScAt':['afterwards, later'],
	'pA':['drink'],
	'pibat':['drink'],
	'pIyamAna':['drink'],
	'pAsyat':['drink'],
	'pAsyamAna':['drink'],
	'papivas':['drink'],
	'papAna':['drink'],
	'pIta':['drink'],
	'pItavat':['drink'],
	'pAtum':['drink'],
	'pItvA':['drink'],
	'peya':['drink'],
	'pAtavya':['drink'],
	'pAnIya':['drink'],
	'pARqava':['descendant of Pāṇḍu'],
	'pAwaliputra':['Pāṭaliputra'],
	'pAda':['foot'],
	'pApa':['bad, evil', 'evil, sin'],
	'pArvatI':['Pārvatī'],
	'pAl':['protect'],
	'pAlayat':['protect'],
	'pAlayamAna':['protect'],
	'pAlyamAna':['protect'],
	'pAlayizyat':['protect'],
	'pAlayizyamARa':['protect'],
	'pAlayAm':['protect'],
	'pAlita':['protect'],
	'pAlitavat':['protect'],
	'pAlayitum':['protect'],
	'pAlayitvA':['protect'],
	'pAlya':['protect'],
	'pAlitavya':['protect'],
	'pAlanIya':['protect'],
	'pitAmaha':['grandfather'],
	'pitf':['father'],
	'putra':['son, child'],
	'putrI':['daughter'],
	'punar':['again'],
	'purA':['previously, long ago'],
	'puruza':['man, person, soul'],
	'purUravas':['Purūravas'],
	'puzpa':['flower'],
	'pU':['purify'],
	'punat':['purify'],
	'punAna':['purify'],
	'pUyamAna':['purify'],
	'pavizyat':['purify'],
	'pavizyamARa':['purify'],
	'pupUvas':['purify'],
	'pupuvAna':['purify'],
	'pUta':['purify'],
	'pavita':['purify'],
	'pUtavat':['purify'],
	'pavitavat':['purify'],
	'pavitum':['purify'],
	'pUtvA':['purify'],
	'pavitvA':['purify'],
	'pavya':['purify'],
	'pavitavya':['purify'],
	'pavanIya':['purify'],
	'pUj':['honor, perform a pūjā'],
	'pUjayamAna':['honor, perform a pūjā'],
	'pUjyamAna':['honor, perform a pūjā'],
	'pUjayizyamARa':['honor, perform a pūjā'],
	'pUjayAm':['honor, perform a pūjā'],
	'pUjita':['honor, perform a pūjā'],
	'pUjitavat':['honor, perform a pūjā'],
	'pUjayitum':['honor, perform a pūjā'],
	'pUjayitvA':['honor, perform a pūjā'],
	'pUjya':['honor, perform a pūjā'],
	'pUjitavya':['honor, perform a pūjā'],
	'pUjanIya':['honor, perform a pūjā'],
	'pUjA':['honor, pūjā'],
	'pUrRa':['filled, full', 'fill'],
	'pUrva':['previous'],
	'pUrvam':['before, previously'],
	'pfTivI':['earth, ground'],
	'pfTu':['broad'],
	'pF':['fill'],
	'pfRat':['fill'],
	'pUryamARa':['fill'],
	'parizyat':['fill'],
	'parIzyat':['fill'],
	'parizyamARa':['fill'],
	'parIzyamARa':['fill'],
	'papFvas':['fill'],
	'paparvas':['fill'],
	'paprARa':['fill'],
	'paparARa':['fill'],
	'pUrta':['fill'],
	'pUrtavat':['fill'],
	'pUritum':['fill'],
	'pUrtvA':['fill'],
	'pUrya':['fill'],
	'pUritavya':['fill'],
	'pUraRIya':['fill'],
	'pra':['forth'],
	'pra_ah':['tell, narrate'],
	'pra_Ap':['attain, obtain'],
	'prApnuvat':['attain, obtain'],
	'prApyamAna':['attain, obtain'],
	'prApsyat':['attain, obtain'],
	'prApsyamAna':['attain, obtain'],
	'prApivas':['attain, obtain'],
	'prApARa':['attain, obtain'],
	'prApta':['attain, obtain'],
	'prAptavat':['attain, obtain'],
	'prAptum':['attain, obtain'],
	'prApya':['attain, obtain'],
	'prAptavya':['attain, obtain'],
	'prApaRIya':['attain, obtain'],
	'pra_kram':['set out, proceed, go forth (active); begin (middle)'],
	'prakrAmat':['set out, proceed, go forth (active); begin (middle)'],
	'prakrAmyat':['set out, proceed, go forth (active); begin (middle)'],
	'prakramamARa':['set out, proceed, go forth (active); begin (middle)'],
	'prakramyamARa':['set out, proceed, go forth (active); begin (middle)'],
	'prakraMsyat':['set out, proceed, go forth (active); begin (middle)'],
	'prakraMsyamAna':['set out, proceed, go forth (active); begin (middle)'],
	'pracakramvas':['set out, proceed, go forth (active); begin (middle)'],
	'pracakramARa':['set out, proceed, go forth (active); begin (middle)'],
	'prakrAnta':['set out, proceed, go forth (active); begin (middle)'],
	'prakrAntavat':['set out, proceed, go forth (active); begin (middle)'],
	'prakrAntum':['set out, proceed, go forth (active); begin (middle)'],
	'prakramitum':['set out, proceed, go forth (active); begin (middle)'],
	'prakramya':['set out, proceed, go forth (active); begin (middle)'],
	'prakramitavya':['set out, proceed, go forth (active); begin (middle)'],
	'prakramaRIya':['set out, proceed, go forth (active); begin (middle)'],
	'pra_gam':['go forth, set out'],
	'pragacCat':['go forth, set out'],
	'pragamyamAna':['go forth, set out'],
	'pragamizyat':['go forth, set out'],
	'pragamizyamARa':['go forth, set out'],
	'prajagmivas':['go forth, set out'],
	'prajaganvas':['go forth, set out'],
	'prajagmAna':['go forth, set out'],
	'pragata':['go forth, set out'],
	'pragatavat':['go forth, set out'],
	'pragantum':['go forth, set out'],
	'pragamya':['go forth, set out'],
	'pragatya':['go forth, set out'],
	'pragantavya':['go forth, set out'],
	'pragamanIya':['go forth, set out'],
	'pracC':['ask'],
	'pfcCat':['ask'],
	'pfcCyamAna':['ask'],
	'prakzyat':['ask'],
	'prakzyamARa':['ask'],
	'papracCvas':['ask'],
	'papracCAna':['ask'],
	'pfzwa':['ask'],
	'pfzwavat':['ask'],
	'prazwum':['ask'],
	'pfzwvA':['ask'],
	'pfcCya':['ask'],
	'prazwavya':['ask'],
	'pracCanIya':['ask'],
	'prati':['against'],
	'prati_aBi_jYA':['recognize'],
	'pratyaBijAnat':['recognize'],
	'pratyaBijYAyamAna':['recognize'],
	'pratyaBijYAsyat':['recognize'],
	'pratyaBijYAsyamAna':['recognize'],
	'pratyaBijajYivas':['recognize'],
	'pratyaBijajYAna':['recognize'],
	'pratyaBijYAta':['recognize'],
	'pratyaBijYAtavat':['recognize'],
	'pratyaBijYAtum':['recognize'],
	'pratyaBijYAya':['recognize'],
	'pratyaBijYeya':['recognize'],
	'pratyaBijYAtavya':['recognize'],
	'pratyaBijYAnIya':['recognize'],
	'prati_ah':['answer'],
	'prati_A_i':['come back'],
	'pratyAyat':['come back'],
	'pratyeyamAna':['come back'],
	'pratyEzyat':['come back'],
	'pratyEzyamARa':['come back'],
	'pratyeyivas':['come back'],
	'pratyeyAna':['come back'],
	'pratyeta':['come back'],
	'pratyetavat':['come back'],
	'pratyEtum':['come back'],
	'pratyetya':['come back'],
	'pratyEtavya':['come back'],
	'pratyAyanIya':['come back'],
	'prati_A_gam':['come back, return'],
	'pratyAgacCat':['come back, return'],
	'pratyAgamyamAna':['come back, return'],
	'pratyAgamizyat':['come back, return'],
	'pratyAgamizyamARa':['come back, return'],
	'pratyAjagmivas':['come back, return'],
	'pratyAjaganvas':['come back, return'],
	'pratyAjagmAna':['come back, return'],
	'pratyAgata':['come back, return'],
	'pratyAgatavat':['come back, return'],
	'pratyAgantum':['come back, return'],
	'pratyAgamya':['come back, return'],
	'pratyAgatya':['come back, return'],
	'pratyAgantavya':['come back, return'],
	'pratyAgamanIya':['come back, return'],
	'prati_A_nI':['bring back'],
	'pratyAnayat':['bring back'],
	'pratyAnayamAna':['bring back'],
	'pratyAnIyamAna':['bring back'],
	'pratyAnezyat':['bring back'],
	'pratyAnezyamARa':['bring back'],
	'pratyAninIvas':['bring back'],
	'pratyAninyAna':['bring back'],
	'pratyAnIta':['bring back'],
	'pratyAnItavat':['bring back'],
	'pratyAnetum':['bring back'],
	'pratyAnIya':['bring back'],
	'pratyAneya':['bring back'],
	'pratyAnetavya':['bring back'],
	'pratyAnayanIya':['bring back'],
	'prati_A_hf':['bring back'],
	'pratyAharat':['bring back'],
	'pratyAharamARa':['bring back'],
	'pratyAhriyamARa':['bring back'],
	'pratyAharizyat':['bring back'],
	'pratyAharizyamARa':['bring back'],
	'pratyAjahfvas':['bring back'],
	'pratyAjahrARa':['bring back'],
	'pratyAhfta':['bring back'],
	'pratyAhftavat':['bring back'],
	'pratyAhartum':['bring back'],
	'pratyAhftya':['bring back'],
	'pratyAhArya':['bring back'],
	'pratyAhartavya':['bring back'],
	'pratyAharaRIya':['bring back'],
	'prati_i':['go back, recognize'],
	'pratiyat':['go back, recognize'],
	'pratIyamAna':['go back, recognize'],
	'pratyezyat':['go back, recognize'],
	'pratyezyamARa':['go back, recognize'],
	'pratIyivas':['go back, recognize'],
	'pratIyAna':['go back, recognize'],
	'pratIta':['go back, recognize'],
	'pratItavat':['go back, recognize'],
	'pratyetum':['go back, recognize'],
	'pratItya':['go back, recognize'],
	'pratItavya':['go back, recognize'],
	'pratyayanIya':['go back, recognize'],
	'prati_kram':['come back, return'],
	'pratikrAmat':['come back, return'],
	'pratikrAmyat':['come back, return'],
	'pratikramyamARa':['come back, return'],
	'pratikraMsyat':['come back, return'],
	'pratikraMsyamAna':['come back, return'],
	'praticakramvas':['come back, return'],
	'praticakramARa':['come back, return'],
	'pratikrAnta':['come back, return'],
	'pratikrAntavat':['come back, return'],
	'pratikramitum':['come back, return'],
	'pratikramya':['come back, return'],
	'pratikramitavya':['come back, return'],
	'pratikramaRIya':['come back, return'],
	'prati_gam':['go back, return'],
	'pratigacCat':['go back, return'],
	'pratigamyamAna':['go back, return'],
	'pratigamizyat':['go back, return'],
	'pratigamizyamARa':['go back, return'],
	'pratijagmivas':['go back, return'],
	'pratijaganvas':['go back, return'],
	'pratijagmAna':['go back, return'],
	'pratigata':['go back, return'],
	'pratigatavat':['go back, return'],
	'pratigantum':['go back, return'],
	'pratigamya':['go back, return'],
	'pratigatya':['go back, return'],
	'pratigantavya':['go back, return'],
	'pratigamanIya':['go back, return'],
	'pratijYA':['promise'],
	'prati_jYA':['promise'],
	'pratijAnAna':['promise'],
	'pratijYAyamAna':['promise'],
	'pratijYAsyamAna':['promise'],
	'pratijajYAna':['promise'],
	'pratijYAta':['promise'],
	'pratijYAtavat':['promise'],
	'pratijYAtum':['promise'],
	'pratijYAya':['promise'],
	'pratijYeya':['promise'],
	'pratijYAtavya':['promise'],
	'pratijYAnIya':['promise'],
	'prati_brU':['reply'],
	'pratibruvat':['reply'],
	'pratibruvARa':['reply'],
	'prati_BAz':['answer'],
	'pratiBAzamARa':['answer'],
	'pratiBAzyamARa':['answer'],
	'pratiBAzizyamARa':['answer'],
	'pratibaBAzARa':['answer'],
	'pratiBAzita':['answer'],
	'pratiBAzitavat':['answer'],
	'pratiBAzitum':['answer'],
	'pratiBAzya':['answer'],
	'pratiBAzitavya':['answer'],
	'pratiBAzaRIya':['answer'],
	'prati_laB':['get back, regain'],
	'pratilaBamAna':['get back, regain'],
	'pratilaByamAna':['get back, regain'],
	'pratilapsyamAna':['get back, regain'],
	'pratileBAna':['get back, regain'],
	'pratilabDa':['get back, regain'],
	'pratilabDavat':['get back, regain'],
	'pratilabDum':['get back, regain'],
	'pratilaBya':['get back, regain'],
	'pratilabDavya':['get back, regain'],
	'pratilaBanIya':['get back, regain'],
	'prati_vac':['answer'],
	'pratyucyamAna':['answer'],
	'prativakzyat':['answer'],
	'prativakzyamARa':['answer'],
	'pratyUcivas':['answer'],
	'pratyUcAna':['answer'],
	'pratyukta':['answer'],
	'pratyuktavat':['answer'],
	'prativaktum':['answer'],
	'pratyucya':['answer'],
	'prativAkya':['answer'],
	'prativaktavya':['answer'],
	'prativacanIya':['answer'],
	'prati_vad':['answer'],
	'prativadat':['answer'],
	'pratyudyamAna':['answer'],
	'prativadizyat':['answer'],
	'prativadizyamARa':['answer'],
	'pratyUdivas':['answer'],
	'pratyUdAna':['answer'],
	'pratyudita':['answer'],
	'pratyuditavat':['answer'],
	'prativaditum':['answer'],
	'pratyudya':['answer'],
	'prativAdya':['answer'],
	'prativaditavya':['answer'],
	'prativadanIya':['answer'],
	'prati_sTA':['withstand (acc.); depend (on loc.)'],
	'pratitizWat':['withstand (acc.); depend (on loc.)'],
	'pratizWIyamAna':['withstand (acc.); depend (on loc.)'],
	'pratizWAsyat':['withstand (acc.); depend (on loc.)'],
	'pratizWAsyamAna':['withstand (acc.); depend (on loc.)'],
	'pratitazWivas':['withstand (acc.); depend (on loc.)'],
	'pratitazWAna':['withstand (acc.); depend (on loc.)'],
	'pratizWita':['withstand (acc.); depend (on loc.)'],
	'pratizWitavat':['withstand (acc.); depend (on loc.)'],
	'pratizWAtum':['withstand (acc.); depend (on loc.)'],
	'pratizWAya':['withstand (acc.); depend (on loc.)'],
	'pratizWeya':['withstand (acc.); depend (on loc.)'],
	'pratizWAtavya':['withstand (acc.); depend (on loc.)'],
	'pratizWAnIya':['withstand (acc.); depend (on loc.)'],
	'prati_hf':['strike back or against, take back'],
	'pratiharat':['strike back or against, take back'],
	'pratiharamARa':['strike back or against, take back'],
	'pratihriyamARa':['strike back or against, take back'],
	'pratiharizyat':['strike back or against, take back'],
	'pratiharizyamARa':['strike back or against, take back'],
	'pratijahfvas':['strike back or against, take back'],
	'pratijahrARa':['strike back or against, take back'],
	'pratihfta':['strike back or against, take back'],
	'pratihftavat':['strike back or against, take back'],
	'pratihartum':['strike back or against, take back'],
	'pratihftya':['strike back or against, take back'],
	'pratihArya':['strike back or against, take back'],
	'pratihartavya':['strike back or against, take back'],
	'pratiharaRIya':['strike back or against, take back'],
	'pratyaBijYA':['recognition'],
	'praTama':['first'],
	'pra_dA':['present, bestow'],
	'pradadat':['present, bestow'],
	'pradadAna':['present, bestow'],
	'pradIyamAna':['present, bestow'],
	'pradAsyat':['present, bestow'],
	'pradAsyamAna':['present, bestow'],
	'pradadivas':['present, bestow'],
	'pratta':['present, bestow'],
	'pradattavat':['present, bestow'],
	'pradAtum':['present, bestow'],
	'pradAya':['present, bestow'],
	'pradeya':['present, bestow'],
	'pradAtavya':['present, bestow'],
	'pradAnIya':['present, bestow'],
	'praDAna':['principal'],
	'pra_pat':['fall forth'],
	'prapatat':['fall forth'],
	'prapatyamAna':['fall forth'],
	'prapatizyat':['fall forth'],
	'prapatizyamARa':['fall forth'],
	'prapetivas':['fall forth'],
	'prapetAna':['fall forth'],
	'prapatita':['fall forth'],
	'prapatitavat':['fall forth'],
	'prapatitum':['fall forth'],
	'prapatya':['fall forth'],
	'prapAtya':['fall forth'],
	'prapatitavya':['fall forth'],
	'prapatanIya':['fall forth'],
	'pra_brU':['say, speak to, proclaim'],
	'prabruvat':['say, speak to, proclaim'],
	'prabruvARa':['say, speak to, proclaim'],
	'pramAda':['carelessness, error'],
	'pra_yam':['hold forth, give, offer (acc. to dat.)'],
	'prayacCat':['hold forth, give, offer (acc. to dat.)'],
	'prayamyamAna':['hold forth, give, offer (acc. to dat.)'],
	'prayaMsyat':['hold forth, give, offer (acc. to dat.)'],
	'prayaMsyamAna':['hold forth, give, offer (acc. to dat.)'],
	'prayemivas':['hold forth, give, offer (acc. to dat.)'],
	'prayemAna':['hold forth, give, offer (acc. to dat.)'],
	'prayata':['hold forth, give, offer (acc. to dat.)'],
	'prayatavat':['hold forth, give, offer (acc. to dat.)'],
	'prayantum':['hold forth, give, offer (acc. to dat.)'],
	'prayamya':['hold forth, give, offer (acc. to dat.)'],
	'prayatya':['hold forth, give, offer (acc. to dat.)'],
	'prayantavya':['hold forth, give, offer (acc. to dat.)'],
	'prayamaRIya':['hold forth, give, offer (acc. to dat.)'],
	'pra_viS':['enter'],
	'praviSat':['enter'],
	'praviSyamAna':['enter'],
	'pravekzyat':['enter'],
	'pravekzyamARa':['enter'],
	'praviviSvas':['enter'],
	'praviviSivas':['enter'],
	'praviviSAna':['enter'],
	'pravizwa':['enter'],
	'pravizwavat':['enter'],
	'pravezwum':['enter'],
	'praviSya':['enter'],
	'praveSya':['enter'],
	'pravezwavya':['enter'],
	'praveSanIya':['enter'],
	'prasTa':['setting out or having set out to'],
	'pra_sTA':['set out'],
	'pratizWamAna':['set out'],
	'prasTIyamAna':['set out'],
	'prasTAsyamAna':['set out'],
	'pratasTAna':['set out'],
	'prasTita':['set out'],
	'prasTitavat':['set out'],
	'prasTAtum':['set out'],
	'prasTAya':['set out'],
	'prasTeya':['set out'],
	'prasTAtavya':['set out'],
	'prasTAnIya':['set out'],
	'pra_hf':['throw, attack'],
	'praharat':['throw, attack'],
	'praharamARa':['throw, attack'],
	'prahriyamARa':['throw, attack'],
	'praharizyat':['throw, attack'],
	'praharizyamARa':['throw, attack'],
	'prajahfvas':['throw, attack'],
	'prajahrARa':['throw, attack'],
	'prahfta':['throw, attack'],
	'prahftavat':['throw, attack'],
	'prahartum':['throw, attack'],
	'prahftya':['throw, attack'],
	'prahArya':['throw, attack'],
	'prahartavya':['throw, attack'],
	'praharaRIya':['throw, attack'],
	'prAtar':['at dawn, in the early morning'],
	'priya':['dear'],
	'plu':['float, swim'],
	'plavamAna':['float, swim'],
	'plozyamARa':['float, swim'],
	'pupluvAna':['float, swim'],
	'pluta':['float, swim'],
	'plutavat':['float, swim'],
	'plavitum':['float, swim'],
	'plutvA':['float, swim'],
	'plavya':['float, swim'],
	'plavitavya':['float, swim'],
	'plavanIya':['float, swim'],
	'Pala':['fruit'],
	'Palavat':['fruitful, successful'],
	'banD':['bind'],
	'baDnat':['bind'],
	'baDyamAna':['bind'],
	'Bantsyat':['bind'],
	'BantsyamAna':['bind'],
	'babanDvas':['bind'],
	'babanDAna':['bind'],
	'badDa':['bind'],
	'badDavat':['bind'],
	'bandDum':['bind'],
	'badDvA':['bind'],
	'banDya':['bind'],
	'bandDavya':['bind'],
	'banDanIya':['bind'],
	'bala':['strength'],
	'balavat':['strong'],
	'balin':['strong'],
	'bahu':['much, many, abundant', 'much'],
	'bAla':['boy, child'],
	'budDi':['intelligence, intellect'],
	'buD':['know', 'be aware, know'],
	'boDat':['know'],
	'boDamAna':['know'],
	'buDyamAna':['know', 'be aware, know'],
	'Botsyat':['know'],
	'BotsyamAna':['know', 'be aware, know'],
	'bubuDvas':['know'],
	'bubuDAna':['know', 'be aware, know'],
	'buDita':['know'],
	'buDitavat':['know'],
	'boDitum':['know'],
	'buDitvA':['know'],
	'boDitvA':['know'],
	'boDya':['know', 'be aware, know'],
	'boDitavya':['know'],
	'boDanIya':['know', 'be aware, know'],
	'budDa':['be aware, know'],
	'budDavat':['be aware, know'],
	'bodDum':['be aware, know'],
	'budDvA':['be aware, know'],
	'bodDavya':['be aware, know'],
	'brAhmaRa':['brahmin'],
	'brU':['say, tell'],
	'bruvat':['say, tell'],
	'bruvARa':['say, tell'],
	'Bakz':['eat'],
	'Bakzayat':['eat'],
	'BakzyamARa':['eat'],
	'Bakzayizyat':['eat'],
	'BakzayizyamARa':['eat'],
	'BakzayAm':['eat'],
	'Bakzita':['eat'],
	'Bakzitavat':['eat'],
	'Bakzayitum':['eat'],
	'BakzayitvA':['eat'],
	'Bakzya':['eat'],
	'Bakzitavya':['eat'],
	'BakzaRIya':['eat'],
	'Baya':['fear'],
	'Barata':['Bharata or his descendants'],
	'Bavat':['your lord/ladyship, sir/madam, you', 'be, become'],
	'BAz':['speak, say'],
	'BAzamARa':['speak, say'],
	'BAzyamARa':['speak, say'],
	'BAzizyamARa':['speak, say'],
	'baBAzARa':['speak, say'],
	'BAzita':['speak, say'],
	'BAzitavat':['speak, say'],
	'BAzitum':['speak, say'],
	'BAzitvA':['speak, say'],
	'BAzya':['speak, say'],
	'BAzitavya':['speak, say'],
	'BAzaRIya':['speak, say'],
	'Bid':['split'],
	'Bindat':['split'],
	'BindAna':['split'],
	'BidyamAna':['split'],
	'Betsyat':['split'],
	'BetsyamAna':['split'],
	'biBidvas':['split'],
	'biBidAna':['split'],
	'Binna':['split'],
	'Binnavat':['split'],
	'Bettum':['split'],
	'BittvA':['split'],
	'Bedya':['split'],
	'Bettavya':['split'],
	'BedanIya':['split'],
	'BI':['fear (from abl.)'],
	'biByat':['fear (from abl.)'],
	'BIyamAna':['fear (from abl.)'],
	'Bezyat':['fear (from abl.)'],
	'BezyamARa':['fear (from abl.)'],
	'biBayAm':['fear (from abl.)'],
	'biBIvas':['fear (from abl.)'],
	'biByAna':['fear (from abl.)'],
	'BIta':['fear (from abl.)'],
	'BItavat':['fear (from abl.)'],
	'Betum':['fear (from abl.)'],
	'BItvA':['fear (from abl.)'],
	'Beya':['fear (from abl.)'],
	'Betavya':['fear (from abl.)'],
	'BayanIya':['fear (from abl.)'],
	'BIru':['timid'],
	'Buj':['protect', 'eat'],
	'BuYjat':['protect'],
	'BujyamAna':['protect', 'eat'],
	'Bokzyat':['protect'],
	'BokzyamARa':['protect', 'eat'],
	'buBujvas':['protect'],
	'buBujAna':['protect', 'eat'],
	'Bukta':['protect', 'eat'],
	'Buktavat':['protect', 'eat'],
	'Boktum':['protect', 'eat'],
	'BuktvA':['protect', 'eat'],
	'Bogya':['protect', 'eat'],
	'Boktavya':['protect', 'eat'],
	'BojanIya':['protect', 'eat'],
	'BuYjAna':['eat'],
	'BU':['earth', 'be, become'],
	'BUyamAna':['be, become'],
	'Bavizyat':['be, become'],
	'BavizyamARa':['be, become'],
	'baBUvas':['be, become'],
	'BUta':['be, become'],
	'BUtavat':['be, become'],
	'Bavitum':['be, become'],
	'BUtvA':['be, become'],
	'Bavya':['be, become'],
	'Bavitavya':['be, become'],
	'BavanIya':['be, become'],
	'Bf':['bear'],
	'biBrat':['bear'],
	'biBrARa':['bear'],
	'BriyamARa':['bear'],
	'Barizyat':['bear'],
	'BarizyamARa':['bear'],
	'biBarAm':['bear'],
	'baBfvas':['bear'],
	'baBrARa':['bear'],
	'Bfta':['bear'],
	'Bftavat':['bear'],
	'Bartum':['bear'],
	'BftvA':['bear'],
	'Bftya':['bear'],
	'Bartavya':['bear'],
	'BaraRIya':['bear'],
	'Bojana':['eating, a meal'],
	'BrAtf':['brother'],
	'BrU':['eyebrow'],
	'mati':['thought'],
	'mad':['rejoice'],
	'mAdyat':['rejoice'],
	'madyamAna':['rejoice'],
	'madizyat':['rejoice'],
	'madizyamARa':['rejoice'],
	'medivas':['rejoice'],
	'medAna':['rejoice'],
	'matta':['rejoice'],
	'mattavat':['rejoice'],
	'maditum':['rejoice'],
	'maditvA':['rejoice'],
	'madya':['rejoice'],
	'maditavya':['rejoice'],
	'madanIya':['rejoice'],
	'maDu':['sweet', 'honey'],
	'maDya':['middle', 'middle, waist'],
	'man':['think'],
	'manyamAna':['think'],
	'manvAna':['think'],
	'maMsyamAna':['think'],
	'menAna':['think'],
	'mata':['think'],
	'matavat':['think'],
	'mantum':['think'],
	'matvA':['think'],
	'mAnya':['think'],
	'mantavya':['think'],
	'mananIya':['think'],
	'manas':['mind'],
	'manuzya':['human being'],
	'mahat':['great'],
	'mahABArata':['the <i>Mahābhārata</i>'],
	'mahAyaSas':['whose glory is great, very glorious'],
	'mA':["don't"],
	'mAtf':['mother'],
	'mAlA':['garland'],
	'mAsa':['moon, month'],
	'muKa':['mouth, face'],
	'muc':['release'],
	'muYcat':['release'],
	'muYcamAna':['release'],
	'mucyamAna':['release'],
	'mokzyat':['release'],
	'mokzyamARa':['release'],
	'mumucvas':['release'],
	'mumucAna':['release'],
	'mukta':['release'],
	'muktavat':['release'],
	'moktum':['release'],
	'muktvA':['release'],
	'mocya':['release'],
	'moktavya':['release'],
	'mocanIya':['release'],
	'muhUrta':['1/30th of a day, 48 minutes, hour'],
	'mf':['die'],
	'mriyamARa':['die'],
	'marizyat':['die'],
	'marizyamARa':['die'],
	'mamfvas':['die'],
	'mamrARa':['die'],
	'mfta':['die'],
	'mftavat':['die'],
	'martum':['die'],
	'mftvA':['die'],
	'mArya':['die'],
	'martavya':['die'],
	'maraRIya':['die'],
	'mfga':['deer'],
	'mfdu':['soft'],
	'yaj':['worship, perform a Vedic ceremony'],
	'yajat':['worship, perform a Vedic ceremony'],
	'yajamAna':['worship, perform a Vedic ceremony', 'yajamāna , worshipper, performer and sponsor of a Vedic ceremony'],
	'ijyamAna':['worship, perform a Vedic ceremony'],
	'yakzyat':['worship, perform a Vedic ceremony'],
	'yakzyamARa':['worship, perform a Vedic ceremony'],
	'Ijivas':['worship, perform a Vedic ceremony'],
	'IjAna':['worship, perform a Vedic ceremony'],
	'yazwum':['worship, perform a Vedic ceremony'],
	'yAjya':['worship, perform a Vedic ceremony'],
	'yazwavya':['worship, perform a Vedic ceremony'],
	'yajanIya':['worship, perform a Vedic ceremony'],
	'yajurveda':['<i>Yajurveda</i>'],
	'yajus':['mantra of the <i>Yajurveda</i>'],
	'yajYa':['worship, ceremony'],
	'yatas':['whence, because'],
	'yatra':['where'],
	'yaTA':['in which manner, as'],
	'yad':['which/who/what'],
	'yadA':['at which time, when'],
	'yadi':['if'],
	'yam':['hold'],
	'yacCat':['hold'],
	'yamyamAna':['hold'],
	'yaMsyat':['hold'],
	'yaMsyamAna':['hold'],
	'yemivas':['hold'],
	'yemAna':['hold'],
	'yata':['hold'],
	'yatavat':['hold'],
	'yantum':['hold'],
	'yatvA':['hold'],
	'yamya':['hold'],
	'yantavya':['hold'],
	'yamanIya':['hold'],
	'yaSas':['glory'],
	'yAvat':['of which extent', 'to which extent'],
	'yuj':['yoke, join, unite'],
	'yuYjat':['yoke, join, unite'],
	'yuYjAna':['yoke, join, unite'],
	'yujyamAna':['yoke, join, unite'],
	'yokzyat':['yoke, join, unite'],
	'yokzyamARa':['yoke, join, unite'],
	'yuyujvas':['yoke, join, unite'],
	'yuyujAna':['yoke, join, unite'],
	'yukta':['yoke, join, unite'],
	'yuktavat':['yoke, join, unite'],
	'yoktum':['yoke, join, unite'],
	'yuktvA':['yoke, join, unite'],
	'yogya':['yoke, join, unite'],
	'yoktavya':['yoke, join, unite'],
	'yojanIya':['yoke, join, unite'],
	'yuD':['fight'],
	'yuDyamAna':['fight'],
	'yotsyamAna':['fight'],
	'yuyuDAna':['fight'],
	'yudDa':['fight'],
	'yudDavat':['fight'],
	'yodDum':['fight'],
	'yudDvA':['fight'],
	'yoDya':['fight'],
	'yodDavya':['fight'],
	'yoDanIya':['fight'],
	'yuDizWira':['Yudhiṣṭhira'],
	'yuDizWirArjuna':['Yudhiṣṭhira and Arjuna'],
	'yuzmad':['you'],
	'yUpa':['sacrificial post'],
	'rajju':['rope'],
	'raRa':['battle'],
	'ratna':['gift, gem'],
	'raTa':['chariot'],
	'ram':['stop, rest, enjoy, play'],
	'ramamARa':['stop, rest, enjoy, play'],
	'ramyamARa':['stop, rest, enjoy, play'],
	'raMsyamAna':['stop, rest, enjoy, play'],
	'remARa':['stop, rest, enjoy, play'],
	'rata':['stop, rest, enjoy, play'],
	'ratavat':['stop, rest, enjoy, play'],
	'rantum':['stop, rest, enjoy, play'],
	'ratvA':['stop, rest, enjoy, play'],
	'ramya':['stop, rest, enjoy, play'],
	'rantavya':['stop, rest, enjoy, play'],
	'ramaRIya':['stop, rest, enjoy, play'],
	'rAjan':['king'],
	'rAjya':['kingdom'],
	'rAtri':['night'],
	'rAmAyaRa':['the <i>Rāmāyana</i>'],
	'rAzwra':['kingdom'],
	'ruD':['obstruct'],
	'runDat':['obstruct'],
	'runDAna':['obstruct'],
	'ruDyamAna':['obstruct'],
	'rotsyat':['obstruct'],
	'rotsyamAna':['obstruct'],
	'ruruDvas':['obstruct'],
	'ruruDAna':['obstruct'],
	'rudDa':['obstruct'],
	'rudDavat':['obstruct'],
	'rodDum':['obstruct'],
	'rudDvA':['obstruct'],
	'roDya':['obstruct'],
	'rodDavya':['obstruct'],
	'roDanIya':['obstruct'],
	'roga':['illness'],
	'laB':['grab, get, obtain'],
	'laBamAna':['grab, get, obtain'],
	'laByamAna':['grab, get, obtain'],
	'lapsyamAna':['grab, get, obtain'],
	'leBAna':['grab, get, obtain'],
	'labDa':['grab, get, obtain'],
	'labDavat':['grab, get, obtain'],
	'labDum':['grab, get, obtain'],
	'labDvA':['grab, get, obtain'],
	'laBya':['grab, get, obtain'],
	'labDavya':['grab, get, obtain'],
	'laBanIya':['grab, get, obtain'],
	'loka':['region, world'],
	'vac':['say, speak'],
	'ucyamAna':['say, speak'],
	'vakzyat':['say, speak', 'carry, flow, wed'],
	'vakzyamARa':['say, speak', 'carry, flow, wed'],
	'Ucivas':['say, speak'],
	'UcAna':['say, speak'],
	'ukta':['say, speak'],
	'uktavat':['say, speak'],
	'vaktum':['say, speak'],
	'uktvA':['say, speak'],
	'vAkya':['say, speak'],
	'vaktavya':['say, speak'],
	'vacanIya':['say, speak'],
	'vacana':['speech, word'],
	'vad':['tell'],
	'vadat':['tell'],
	'udyamAna':['tell'],
	'vadizyat':['tell'],
	'vadizyamARa':['tell'],
	'Udivas':['tell'],
	'UdAna':['tell'],
	'udita':['tell'],
	'uditavat':['tell'],
	'vaditum':['tell'],
	'uditvA':['tell'],
	'vAdya':['tell'],
	'vaditavya':['tell'],
	'vadanIya':['tell'],
	'vaDU':['bride, wife'],
	'vana':['forest'],
	'vara':['choice, excellent', 'boon, bridegroom'],
	'varuRa':['Varuṇa'],
	'varza':['rain, year'],
	'vas':['dwell'],
	'vasat':['dwell'],
	'uzyamARa':['dwell'],
	'vatsyat':['dwell'],
	'vatsyamAna':['dwell'],
	'Uzivas':['dwell'],
	'UzARa':['dwell'],
	'uzita':['dwell'],
	'uzitavat':['dwell'],
	'vastum':['dwell'],
	'uzitvA':['dwell'],
	'vAsya':['dwell'],
	'vasitavya':['dwell'],
	'vasanIya':['dwell'],
	'vah':['carry, flow, wed'],
	'vahat':['carry, flow, wed'],
	'vahamAna':['carry, flow, wed'],
	'uhyamAna':['carry, flow, wed'],
	'Uhivas':['carry, flow, wed'],
	'UhAna':['carry, flow, wed'],
	'UQa':['carry, flow, wed'],
	'UQavat':['carry, flow, wed'],
	'voQum':['carry, flow, wed'],
	'UQvA':['carry, flow, wed'],
	'vAhya':['carry, flow, wed'],
	'voQavya':['carry, flow, wed'],
	'vAhanIya':['carry, flow, wed'],
	'vA':['or'],
	'vAc':['speech'],
	'vAra':['occasion, day'],
	'vAri':['water'],
	'vAlmIki':['Vālmīki'],
	'vAsas':['cloth, clothes'],
	'vi':['apart'],
	'vi_krI':['sell'],
	'vikrIRat':['sell'],
	'vikrIRAna':['sell'],
	'vikrIyamARa':['sell'],
	'vikrezyat':['sell'],
	'vikrezyamARa':['sell'],
	'vicikrIvas':['sell'],
	'vicikriyARa':['sell'],
	'vikrIta':['sell'],
	'vikrItavat':['sell'],
	'vikretum':['sell'],
	'vikrIya':['sell'],
	'vikreya':['sell'],
	'vikretavya':['sell'],
	'vikrayaRIya':['sell'],
	'vi_gam':['separate, depart'],
	'vigacCat':['separate, depart'],
	'vigamyamAna':['separate, depart'],
	'vigamizyat':['separate, depart'],
	'vigamizyamARa':['separate, depart'],
	'vijagmivas':['separate, depart'],
	'vijaganvas':['separate, depart'],
	'vijagmAna':['separate, depart'],
	'vigata':['separate, depart'],
	'vigatavat':['separate, depart'],
	'vigantum':['separate, depart'],
	'vigamya':['separate, depart'],
	'vigatya':['separate, depart'],
	'vigantavya':['separate, depart'],
	'vigamanIya':['separate, depart'],
	'vigama':['separation, departure'],
	'vi_jYA':['discern, understand'],
	'vijAnat':['discern, understand'],
	'vijYAyamAna':['discern, understand'],
	'vijYAsyat':['discern, understand'],
	'vijYAsyamAna':['discern, understand'],
	'vijajYivas':['discern, understand'],
	'vijajYAna':['discern, understand'],
	'vijYAta':['discern, understand'],
	'vijYAtavat':['discern, understand'],
	'vijYAtum':['discern, understand'],
	'vijYAya':['discern, understand'],
	'vijYeya':['discern, understand'],
	'vijYAtavya':['discern, understand'],
	'vijYAnIya':['discern, understand'],
	'vijYAna':['understanding'],
	'vid':['know', 'find, get'],
	'vidvas':['know'],
	'vidyamAna':['know', 'find, get'],
	'vedizyat':['know', 'find, get'],
	'vedizyamARa':['know', 'find, get'],
	'vidAm':['know'],
	'vividvas':['know', 'find, get'],
	'vividAna':['know', 'find, get'],
	'vidita':['know'],
	'viditavat':['know'],
	'veditum':['know'],
	'viditvA':['know', 'find, get'],
	'vedya':['know', 'find, get'],
	'veditavya':['know'],
	'vedanIya':['know', 'find, get'],
	'vindat':['find, get'],
	'vindamAna':['find, get'],
	'vividivas':['find, get'],
	'vitta':['find, get'],
	'vittavat':['find, get'],
	'vettum':['find, get'],
	'vittvA':['find, get'],
	'veditvA':['find, get'],
	'vettavya':['find, get'],
	'vidyut':['lightning'],
	'vinA':['without (2/3/5)'],
	'viS':['settler', 'enter'],
	'viSat':['enter'],
	'viSyamAna':['enter'],
	'vekzyat':['enter'],
	'vekzyamARa':['enter'],
	'viviSvas':['enter'],
	'viviSivas':['enter'],
	'viviSAna':['enter'],
	'vizwa':['enter'],
	'vizwavat':['enter'],
	'vezwum':['enter'],
	'vizwvA':['enter'],
	'veSya':['enter'],
	'vezwavya':['enter'],
	'veSanIya':['enter'],
	'vi_sad':['be sad, grieve'],
	'vizIdat':['be sad, grieve'],
	'vizadyamAna':['be sad, grieve'],
	'vizatsyat':['be sad, grieve'],
	'vizatsyamAna':['be sad, grieve'],
	'vizedivas':['be sad, grieve'],
	'vizedAna':['be sad, grieve'],
	'vizaRRa':['be sad, grieve'],
	'vizaRRavat':['be sad, grieve'],
	'vizattum':['be sad, grieve'],
	'vizadya':['be sad, grieve'],
	'vizAdya':['be sad, grieve'],
	'vizattavya':['be sad, grieve'],
	'vizadanIya':['be sad, grieve'],
	'vi_smf':['forget'],
	'vismarat':['forget'],
	'vismaryamARa':['forget'],
	'vismarizyat':['forget'],
	'vismarizyamARa':['forget'],
	'visasmarvas':['forget'],
	'visasmarARa':['forget'],
	'vismfta':['forget'],
	'vismftavat':['forget'],
	'vismartum':['forget'],
	'vismftya':['forget'],
	'vismArya':['forget'],
	'vismartavya':['forget'],
	'vismaraRIya':['forget'],
	'vIra':['heroic', 'hero'],
	'vf':['cover', 'block', 'choose'],
	'vfRvat':['cover'],
	'vfRvAna':['cover'],
	'vriyamARa':['cover', 'choose'],
	'varizyat':['cover'],
	'varIzyat':['cover'],
	'varizyamARa':['cover', 'choose'],
	'varIzyamARa':['cover', 'choose'],
	'vavfvas':['cover'],
	'vavrARa':['cover', 'choose'],
	'vfta':['cover', 'choose'],
	'vftavat':['cover', 'choose'],
	'varitum':['cover', 'choose'],
	'varItum':['cover', 'choose'],
	'vftvA':['cover', 'choose'],
	'vftya':['cover', 'occur, be present'],
	'varitavya':['cover', 'choose'],
	'varItavya':['cover'],
	'varaRIya':['cover', 'choose'],
	'vArayat':['block'],
	'vArayamARa':['block'],
	'vAryamARa':['block'],
	'vArayizyat':['block'],
	'vArayizyamARa':['block'],
	'vArayAm':['block'],
	'vArita':['block'],
	'vAritavat':['block'],
	'vArayitum':['block'],
	'vArayitvA':['block'],
	'vArya':['block', 'choose'],
	'vArayitavya':['block'],
	'vAraRIya':['block'],
	'vfRAna':['choose'],
	'vfka':['wolf'],
	'vfkza':['tree'],
	'vft':['occur, be present'],
	'vartamAna':['occur, be present'],
	'vartizyamARa':['occur, be present'],
	'vavftAna':['occur, be present'],
	'vftta':['occur, be present'],
	'vfttavat':['occur, be present'],
	'vartitum':['occur, be present'],
	'vartitvA':['occur, be present'],
	'vartitavya':['occur, be present'],
	'vartanIya':['occur, be present'],
	'veda':['knowledge, veda , N. of most ancient texts'],
	'vedi':['altar'],
	'vE':['verily, truly'],
	'vyaD':['pierce'],
	'viDyat':['pierce'],
	'viDyamAna':['pierce'],
	'vetsyat':['pierce'],
	'vetsyamAna':['pierce'],
	'vivyaDvas':['pierce'],
	'vivyaDAna':['pierce'],
	'vidDa':['pierce'],
	'vidDavat':['pierce'],
	'vedDum':['pierce'],
	'vidDvA':['pierce'],
	'veDya':['pierce'],
	'vedDavya':['pierce'],
	'veDanIya':['pierce'],
	'vyAsa':['Vyāsa'],
	'vraj':['walk'],
	'vrajat':['walk'],
	'vrajyamAna':['walk'],
	'vrajizyat':['walk'],
	'vrajizyamARa':['walk'],
	'vavrajvas':['walk'],
	'vavrajAna':['walk'],
	'vrajita':['walk'],
	'vrajitavat':['walk'],
	'vrajitum':['walk'],
	'vrajitvA':['walk'],
	'vrAjya':['walk'],
	'vrajitavya':['walk'],
	'vrajanIya':['walk'],
	'Sak':['be able'],
	'Saknuvat':['be able'],
	'SakyamAna':['be able'],
	'Sakta':['be able'],
	'Saktavat':['be able'],
	'Saktum':['be able'],
	'SaktvA':['be able'],
	'Sakya':['be able'],
	'Saktavya':['be able'],
	'SakanIya':['be able'],
	'SakuntalA':['Śakuntalā'],
	'Sata':['hundred'],
	'Satru':['enemy'],
	'Sap':['swear (an oath), curse'],
	'Sapat':['swear (an oath), curse'],
	'Sapyat':['swear (an oath), curse'],
	'SapamAna':['swear (an oath), curse'],
	'SapyamAna':['swear (an oath), curse'],
	'Sapsyat':['swear (an oath), curse'],
	'SapsyamAna':['swear (an oath), curse'],
	'Sepivas':['swear (an oath), curse'],
	'SepAna':['swear (an oath), curse'],
	'Sapta':['swear (an oath), curse'],
	'Saptavat':['swear (an oath), curse'],
	'Saptum':['swear (an oath), curse'],
	'SaptvA':['swear (an oath), curse'],
	'Sapya':['swear (an oath), curse'],
	'Saptavya':['swear (an oath), curse'],
	'SapanIya':['swear (an oath), curse'],
	'Sabda':['speech, word'],
	'Sayana':['bed, couch'],
	'Sara':['arrow'],
	'SAKA':['branch'],
	'SApa':['promise, curse, vow'],
	'SAlA':['hall'],
	'SAs':['rule, govern, teach'],
	'SAsat':['rule, govern, teach'],
	'SizyamARa':['rule, govern, teach'],
	'SAsizyat':['rule, govern, teach'],
	'SAsizyamARa':['rule, govern, teach'],
	'SaSAsvas':['rule, govern, teach'],
	'SaSAsAna':['rule, govern, teach'],
	'Sizwa':['rule, govern, teach'],
	'Sizwavat':['rule, govern, teach'],
	'SAsitum':['rule, govern, teach'],
	'SizwvA':['rule, govern, teach'],
	'SAsitvA':['rule, govern, teach'],
	'Sizya':['rule, govern, teach', 'student'],
	'SAsitavya':['rule, govern, teach'],
	'SAsanIya':['rule, govern, teach'],
	'Sita':['sharp'],
	'Siva':['auspicious', 'Śiva'],
	'SI':['lie, sleep'],
	'SayAna':['lie, sleep'],
	'Sayita':['lie, sleep'],
	'Sayitavat':['lie, sleep'],
	'Sayitum':['lie, sleep'],
	'SayitvA':['lie, sleep'],
	'Sayya':['lie, sleep'],
	'Sayitavya':['lie, sleep'],
	'SayanIya':['lie, sleep'],
	'SIGra':['swift, quick'],
	'SIGram':['swiftly, quickly'],
	'Suc':['shine, burn, grieve, regret'],
	'Socat':['shine, burn, grieve, regret'],
	'SuSucvas':['shine, burn, grieve, regret'],
	'Sucita':['shine, burn, grieve, regret'],
	'Socita':['shine, burn, grieve, regret'],
	'Sucitavat':['shine, burn, grieve, regret'],
	'Socitavat':['shine, burn, grieve, regret'],
	'Socitum':['shine, burn, grieve, regret'],
	'SucitvA':['shine, burn, grieve, regret'],
	'SocitvA':['shine, burn, grieve, regret'],
	'Socya':['shine, burn, grieve, regret'],
	'Socitavya':['shine, burn, grieve, regret'],
	'SocanIya':['shine, burn, grieve, regret'],
	'SocayitvA':['shine, burn, grieve, regret'],
	'Suci':['pure'],
	'Seza':['remainder'],
	'SrI':['glory'],
	'Sru':['hear, listen'],
	'SfRvat':['hear, listen'],
	'SrUyamARa':['hear, listen'],
	'Sruta':['hear, listen'],
	'Srutavat':['hear, listen'],
	'Srotum':['hear, listen'],
	'SrutvA':['hear, listen'],
	'Sravya':['hear, listen'],
	'Srotavya':['hear, listen'],
	'SravaRIya':['hear, listen'],
	'Svan':['dog'],
	'Svas':['tomorrow'],
	'zaz':['six'],
	'zazWa':['sixth'],
	'saMvAda':['conversation'],
	'saMSaya':['doubt'],
	'saMskfta':['refined', 'Sanskrit'],
	'saKI':['friend'],
	'saKya':['friendship'],
	'satya':['true, real', 'truth'],
	'sad':['sit, be sad'],
	'sIdat':['sit, be sad'],
	'sadyamAna':['sit, be sad'],
	'satsyat':['sit, be sad'],
	'satsyamAna':['sit, be sad'],
	'sedivas':['sit, be sad'],
	'sedAna':['sit, be sad'],
	'sanna':['sit, be sad'],
	'sannavat':['sit, be sad'],
	'sattum':['sit, be sad'],
	'sattvA':['sit, be sad'],
	'sAdya':['sit, be sad'],
	'sattavya':['sit, be sad'],
	'sadanIya':['sit, be sad'],
	'sadA':['always'],
	'saptan':['seven'],
	'saptama':['seventh'],
	'sam':['together'],
	'sam_A_gam':['come together, meet'],
	'samAgacCamAna':['come together, meet'],
	'samAgamyamAna':['come together, meet'],
	'samAgamizyamARa':['come together, meet'],
	'samAjagmAna':['come together, meet'],
	'samAgata':['come together, meet'],
	'samAgatavat':['come together, meet'],
	'samAgantum':['come together, meet'],
	'samAgamya':['come together, meet'],
	'samAgatya':['come together, meet'],
	'samAgantavya':['come together, meet'],
	'samAgamanIya':['come together, meet'],
	'samIpa':['near'],
	'sam_kram':['meet'],
	'saMkrAmat':['meet'],
	'saMkrAmyat':['meet'],
	'saMkramyamARa':['meet'],
	'saMkraMsyat':['meet'],
	'saMkraMsyamAna':['meet'],
	'saMcakramvas':['meet'],
	'saMcakramARa':['meet'],
	'saMkrAnta':['meet'],
	'saMkrAntavat':['meet'],
	'saMkramitum':['meet'],
	'saMkramya':['meet'],
	'saMkramitavya':['meet'],
	'saMkramaRIya':['meet'],
	'sam_gam':['go together, unite, meet'],
	'saMgacCamAna':['go together, unite, meet'],
	'saMgamyamAna':['go together, unite, meet'],
	'saMgamizyamARa':['go together, unite, meet'],
	'saMjagmAna':['go together, unite, meet'],
	'saMgata':['go together, unite, meet'],
	'saMgatavat':['go together, unite, meet'],
	'saMgantum':['go together, unite, meet'],
	'saMgamya':['go together, unite, meet'],
	'saMgatya':['go together, unite, meet'],
	'saMgantavya':['go together, unite, meet'],
	'saMgamanIya':['go together, unite, meet'],
	'sam_pad':['succeed, arise'],
	'saMpadyamAna':['succeed, arise'],
	'saMpatsyamAna':['succeed, arise'],
	'saMpedAna':['succeed, arise'],
	'saMpanna':['succeed, arise'],
	'saMpannavat':['succeed, arise'],
	'saMpattum':['succeed, arise'],
	'saMpadya':['succeed, arise'],
	'saMpAdya':['succeed, arise'],
	'saMpattavya':['succeed, arise'],
	'saMpadanIya':['succeed, arise'],
	'sam_pracC':['consult'],
	'saMpfcCamAna':['consult'],
	'saMpfcCyamAna':['consult'],
	'saMprakzyamARa':['consult'],
	'saMpapracCAna':['consult'],
	'saMpfzwa':['consult'],
	'saMpfzwavat':['consult'],
	'saMprazwum':['consult'],
	'saMpfcCya':['consult'],
	'saMprazwavya':['consult'],
	'saMpracCanIya':['consult'],
	'sam_BU':['be together, arise, be possible'],
	'saMBavat':['be together, arise, be possible'],
	'saMBUyamAna':['be together, arise, be possible'],
	'saMBavizyat':['be together, arise, be possible'],
	'saMBavizyamARa':['be together, arise, be possible'],
	'saMbaBUvas':['be together, arise, be possible'],
	'saMBUta':['be together, arise, be possible'],
	'saMBUtavat':['be together, arise, be possible'],
	'saMBavitum':['be together, arise, be possible'],
	'saMBUya':['be together, arise, be possible'],
	'saMBavya':['be together, arise, be possible'],
	'saMBavitavya':['be together, arise, be possible'],
	'saMBavanIya':['be together, arise, be possible'],
	'sam_vad':['speak together, converse, discuss'],
	'saMvadamAna':['speak together, converse, discuss'],
	'samudyamAna':['speak together, converse, discuss'],
	'saMvadizyamARa':['speak together, converse, discuss'],
	'samUdAna':['speak together, converse, discuss'],
	'samudita':['speak together, converse, discuss'],
	'samuditavat':['speak together, converse, discuss'],
	'saMvaditum':['speak together, converse, discuss'],
	'samudya':['speak together, converse, discuss'],
	'saMvAdya':['speak together, converse, discuss'],
	'saMvaditavya':['speak together, converse, discuss'],
	'saMvadanIya':['speak together, converse, discuss'],
	'sam_SI':['lie down, hesitate'],
	'saMSayAna':['lie down, hesitate'],
	'saMSayita':['lie down, hesitate'],
	'saMSayitavat':['lie down, hesitate'],
	'saMSayitum':['lie down, hesitate'],
	'saMSayya':['lie down, hesitate'],
	'saMSayitavya':['lie down, hesitate'],
	'saMSayanIya':['lie down, hesitate'],
	'sam_hf':['collect'],
	'saMharat':['collect'],
	'saMharamARa':['collect'],
	'saMhriyamARa':['collect'],
	'saMharizyat':['collect'],
	'saMharizyamARa':['collect'],
	'saMjahfvas':['collect'],
	'saMjahrARa':['collect'],
	'saMhfta':['collect'],
	'saMhftavat':['collect'],
	'saMhartum':['collect'],
	'saMhftya':['collect'],
	'saMhArya':['collect'],
	'saMhartavya':['collect'],
	'saMharaRIya':['collect'],
	'sarit':['flowing, river'],
	'sarva':['all, whole, every'],
	'sarvatas':['from everywhere'],
	'sarvatra':['everywhere'],
	'sarvaTA':['in every way'],
	'sarvadA':['always'],
	'saha':['with'],
	'sADu':['straight, right, good', 'good man, sage'],
	'sAman':['verse of the <i>Sāmaveda</i>'],
	'sAmaveda':['<i>Sāmaveda</i>'],
	'su':['well', 'press'],
	'sunvat':['press'],
	'sunvAna':['press'],
	'sUyamAna':['press'],
	'sozyat':['press'],
	'sozyamARa':['press'],
	'suzuvas':['press'],
	'suzuvARa':['press'],
	'suta':['press'],
	'sutavat':['press'],
	'sotum':['press'],
	'sutvA':['press'],
	'savya':['press'],
	'sotavya':['press'],
	'savanIya':['press'],
	'suKa':['comfortable, happy', 'happiness'],
	'suKam':['comfortably, happily'],
	'suKin':['happy'],
	'sudeva':['very noble'],
	'sundara':['beautiful'],
	'sumanas':['good-minded, well-disposed, benevolent, cheerful, wise'],
	'suhfd':['good-hearted, well-disposed', 'friend'],
	'sUrya':['sun'],
	'sev':['serve'],
	'sevamAna':['serve'],
	'sevyamAna':['serve'],
	'sevizyamARa':['serve'],
	'sizevARa':['serve'],
	'sevita':['serve'],
	'sevitavat':['serve'],
	'sevitum':['serve'],
	'sevitvA':['serve'],
	'sevya':['serve'],
	'sevitavya':['serve'],
	'sevanIya':['serve'],
	'sevA':['service'],
	'sEnya':['army'],
	'soma':['juice'],
	'strI':['woman'],
	'sTA':['stand, stay, be present'],
	'tizWat':['stand, stay, be present'],
	'tizWamAna':['stand, stay, be present'],
	'sTIyamAna':['stand, stay, be present'],
	'sTAsyat':['stand, stay, be present'],
	'sTAsyamAna':['stand, stay, be present'],
	'tasTivas':['stand, stay, be present'],
	'tasTAna':['stand, stay, be present'],
	'sTita':['stand, stay, be present'],
	'sTitavat':['stand, stay, be present'],
	'sTAtum':['stand, stay, be present'],
	'sTitvA':['stand, stay, be present'],
	'sTeya':['stand, stay, be present'],
	'sTAtavya':['stand, stay, be present'],
	'sTAnIya':['stand, stay, be present'],
	'sTApayitum':['stand, stay, be present'],
	'sTAna':['place'],
	'sTAlI':['pot, pan'],
	'snih':['stick, be affectionate'],
	'snihyat':['stick, be affectionate'],
	'snihyamAna':['stick, be affectionate'],
	'snekzyat':['stick, be affectionate'],
	'snehizyat':['stick, be affectionate'],
	'snekzyamARa':['stick, be affectionate'],
	'snehizyamARa':['stick, be affectionate'],
	'sizRihvas':['stick, be affectionate'],
	'sizRihAna':['stick, be affectionate'],
	'snigDa':['stick, be affectionate'],
	'snigDavat':['stick, be affectionate'],
	'snehitum':['stick, be affectionate'],
	'snihitvA':['stick, be affectionate'],
	'snehitvA':['stick, be affectionate'],
	'snehya':['stick, be affectionate'],
	'snehitavya':['stick, be affectionate'],
	'snehanIya':['stick, be affectionate'],
	'sneha':['affection'],
	'spfS':['touch'],
	'spfSat':['touch'],
	'spfSyamAna':['touch'],
	'sprakzyat':['touch'],
	'sprakzyamARa':['touch'],
	'paspfSvas':['touch'],
	'paspfSAna':['touch'],
	'spfzwa':['touch'],
	'spfzwavat':['touch'],
	'sprazwum':['touch'],
	'spfzwvA':['touch'],
	'spfSya':['touch'],
	'sprazwavya':['touch'],
	'sparSanIya':['touch'],
	'sma':['ever, certainly'],
	'smf':['remember'],
	'smarat':['remember'],
	'smaryamARa':['remember'],
	'smarizyat':['remember'],
	'smarizyamARa':['remember'],
	'sasmarvas':['remember'],
	'sasmarARa':['remember'],
	'smfta':['remember'],
	'smftavat':['remember'],
	'smartum':['remember'],
	'smftvA':['remember'],
	'smArya':['remember'],
	'smartavya':['remember'],
	'smaraRIya':['remember'],
	'smfti':['memory, traditional texts'],
	'sraj':['garland'],
	'sva':["one's own"],
	'svarAzwra':['own kingdom'],
	'svarga':['heaven'],
	'svasf':['sister'],
	'ha':['indeed, of course'],
	'han':['smite'],
	'Gnat':['smite'],
	'hanyamAna':['smite'],
	'hanizyat':['smite'],
	'hanizyamARa':['smite'],
	'jaGnivas':['smite'],
	'jaGanvas':['smite'],
	'jaGnAna':['smite'],
	'hata':['smite'],
	'hatavat':['smite'],
	'hantum':['smite'],
	'hatvA':['smite'],
	'GAtya':['smite'],
	'hantavya':['smite'],
	'hananIya':['smite'],
	'hari':['yellow', 'yellow, horse', 'Hari Candra'],
	'havis':['oblation'],
	'hasta':['hand'],
	'hastin':['having a hand', 'elephant'],
	'hA':['leave, abandon'],
	'jahat':['leave, abandon'],
	'jahAna':['leave, abandon'],
	'hIyamAna':['leave, abandon', 'impel, send'],
	'hAsyat':['leave, abandon'],
	'hAsyamAna':['leave, abandon'],
	'jahivas':['leave, abandon'],
	'hIna':['leave, abandon'],
	'hInavat':['leave, abandon'],
	'hAtum':['leave, abandon'],
	'heya':['leave, abandon', 'impel, send'],
	'hAtavya':['leave, abandon'],
	'hAnIya':['leave, abandon'],
	'hi':['because, for', 'impel, send'],
	'hinvat':['impel, send'],
	'hezyat':['impel, send'],
	'hezyamARa':['impel, send'],
	'hetum':['impel, send'],
	'hetavya':['impel, send'],
	'hayanIya':['impel, send'],
	'himAlaya':['Himālaya , the Himalayan mountain range'],
	'hu':['offer (acc. to dat.), worship (acc. with dat.)'],
	'juhvat':['offer (acc. to dat.), worship (acc. with dat.)'],
	'hUyamAna':['offer (acc. to dat.), worship (acc. with dat.)', 'call'],
	'hozyat':['offer (acc. to dat.), worship (acc. with dat.)'],
	'hozyamARa':['offer (acc. to dat.), worship (acc. with dat.)'],
	'juhavAm':['offer (acc. to dat.), worship (acc. with dat.)'],
	'huta':['offer (acc. to dat.), worship (acc. with dat.)'],
	'hutavat':['offer (acc. to dat.), worship (acc. with dat.)'],
	'hotum':['offer (acc. to dat.), worship (acc. with dat.)'],
	'hutvA':['offer (acc. to dat.), worship (acc. with dat.)'],
	'havya':['offer (acc. to dat.), worship (acc. with dat.)'],
	'hotavya':['offer (acc. to dat.), worship (acc. with dat.)'],
	'havanIya':['offer (acc. to dat.), worship (acc. with dat.)'],
	'hf':['take'],
	'harat':['take'],
	'haramARa':['take'],
	'hriyamARa':['take'],
	'harizyat':['take'],
	'harizyamARa':['take'],
	'jahfvas':['take'],
	'jahrARa':['take'],
	'hfta':['take'],
	'hftavat':['take'],
	'hartum':['take'],
	'hftvA':['take'],
	'hArya':['take'],
	'hartavya':['take'],
	'haraRIya':['take'],
	'he':['hey, O'],
	'hetu':['reason, cause'],
	'hotf':['hotṛ'],
	'hyas':['yesterday'],
	'hrI':['modesty'],
	'hve':['call'],
	'hvayat':['call'],
	'hvayamAna':['call'],
	'hvAsyat':['call'],
	'hvAsyamAna':['call'],
	'juhUvas':['call'],
	'juhuvAna':['call'],
	'hUta':['call'],
	'hUtavat':['call'],
	'hvAtum':['call'],
	'hUtvA':['call'],
	'hvAya':['call'],
	'hvAtavya':['call'],
	'hvAnIya':['call']
}

