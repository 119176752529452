import React, {useContext} from 'react';

// import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
// import Row from 'react-bootstrap/Row';
// import Form from 'react-bootstrap/Form';
// import Button from 'react-bootstrap/Button';
import Navbar from 'react-bootstrap/Navbar';
// import NavDropdown from 'react-bootstrap/NavDropdown';
// import FormControl from 'react-bootstrap/FormControl';

import {auth} from "../fireAuth/firebase";
import  {UserContext} from "../providers/UserProvider";

// import './css/Components.css';
import '../App.css';

const NavBar = () => {
	
	const {currentUser} = useContext(UserContext);
	// console.log("@Navbar", currentUser);

	if (currentUser && currentUser.email.includes('iiit.ac.in')){
		auth.signOut();
	}

	return (
		<Navbar collapseOnSelect fixed="top" variant="dark" className="navbarcss" expand="md" style={{padding: "5px 20px"}}>
			<Navbar.Brand href="https://sanskritlibrary.org/">
				<img  
					src="/slLogo.jpeg"	
					width="60"
					height="30"
					className="d-inline-block align-center"
					alt="SL's"
				/>
			</Navbar.Brand>
			

			<Navbar.Toggle  />
			<Navbar.Collapse>
				<Nav className="me-auto">
					<Nav.Link href="/">Home</Nav.Link>
					<Nav.Link className="ml-2 pl-1" href="/ch">Chapters</Nav.Link>
				</Nav>
				{/*
				<Nav className="mr-auto">
					 <NavDropdown title="Quick Access" >
						<NavDropdown.Item href="#" style={{width: 250}}> 
							<Form.Group as={Row}>
								<Col><Form.Control type="ch" placeholder="Chapter" /></Col>
								<Col><Button variant="outline-primary">Go</Button></Col>
							</Form.Group>
						</NavDropdown.Item>
						<NavDropdown.Divider />
						<NavDropdown.Item href="#"> 
							<Form.Group as={Row}>
								<Col><Form.Control type="ex" placeholder="Exercise" /></Col>
								<Col><Button variant="outline-primary">Go</Button></Col>
							</Form.Group>
						</NavDropdown.Item>
					</NavDropdown>
				</Nav>
				*/}
				
				{/* <Form inline>
					<FormControl type="text" placeholder="Search" className="mr-sm-2" />
					<Button variant="outline-primary">Search</Button>
				</Form> */}

				<Nav>
					{currentUser ?
						<>
							{['dev', 'prof'].includes(currentUser.utype) &&
								<Nav.Link eventKey='3' href="/students">Students</Nav.Link>
							}
							{currentUser.displayName &&
								<Nav.Link eventKey='1' href="/profile">{currentUser.displayName}</Nav.Link>
							}
							{!currentUser.displayName &&
								<Nav.Link eventKey='1' href="/profile">{currentUser.email}</Nav.Link>
							}
							<Nav.Link eventKey='2' href='/' onClick = {() => {auth.signOut()}}>Logout</Nav.Link>
						</>
					:
						<>
							<Nav.Link eventKey='1' href="/login">Login</Nav.Link>
						</>
					}
				</Nav>

			</Navbar.Collapse>
	  </Navbar>
	);	
};

export default NavBar;