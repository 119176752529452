import React, { useState, useEffect } from 'react';

import {Row, Col} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

import { InterfaceError } from '../helpers/blocks';

import '../../css/Components.css';

const LexCatInterface=props=>{
	const oldAns =props.ansstate;
	const [lexCat, setLexCat]=useState('');
	useEffect(()=>{
		setLexCat('');
	}, [setLexCat]);

	const [showInterfaceError, setShowInterfaceError]=useState(false);

	const [interfaceBtn, setInterfaceBtn] =useState(null)
	useEffect(() => {
		if (interfaceBtn){
			// create an eventListener
			window.addEventListener('keydown', e=>handleKeyDown(e));
			// cleanup this component
			return () => {
				window.removeEventListener('keydown', e=>handleKeyDown(e));
			};
		}
	}, [interfaceBtn]);

	const handleKeyDown=(event)=>{
		if(event.key==='Enter'){
			event.preventDefault();
			// console.log('MODAL Enter:')
			interfaceBtn.click();	
		}
	};

	const isDisabled=()=>{
		return lexCat==='';
	};
	const handleSubmit=()=>{
		if (isDisabled()){
			setShowInterfaceError(true);
			return;
		}
		let values = [...oldAns];
		values[props.index].pos=lexCat;

		props.setans(values);
		props.onHide();
	};

	return(
		<Modal
			{...props}
			size="lg"
			centered
		>
			<InterfaceError showInterfaceError={showInterfaceError} setShowInterfaceError={setShowInterfaceError} />

			<Modal.Header closeButton>
				<div className="widthVW interfaceTitle">
					{props.title}
				</div>
			</Modal.Header>
			
			<Modal.Body>
				<div style={{fontSize:'120%', marginBottom:'15px'}}><span className="spChar">L</span>exical <span className="spChar">C</span>ategory</div>
				<Form.Group>
					<Row>
						<Col md className="interfaceCol">
							<div className="colHeader">
								<span className="spChar">N</span>oun
							</div>
							<Form.Check 
								id="m"
								type="radio"
								label="m mascular"
								name="lexcatOptions"
								onClick={()=>setLexCat('m')}
							/>
							<Form.Check 
								id="f"
								type="radio"
								label="f feminine"
								name="lexcatOptions"
								onClick={()=>setLexCat('f')}
							/>
							<Form.Check 
								id="n"
								type="radio"
								label="n neuter"
								name="lexcatOptions"
								onClick={()=>setLexCat('n')}
							/>
						</Col>
						<Col md className="interfaceCol">
							<div className="colHeader">
								<span className="spChar">A</span>djective
							</div>
							<Form.Check 
								id="adj"
								type="radio"
								label="adj adjective"
								name="lexcatOptions"
								onClick={()=>setLexCat('adj')}
							/>
						</Col>
						<Col md className="interfaceCol">
							<div className="colHeader">
								<span className="spChar">A</span>dverb
							</div>
							<Form.Check 
								id="adv"
								type="radio"
								label="adv adverb"
								name="lexcatOptions"
								onClick={()=>setLexCat('adv')}
							/>
						</Col>
					</Row>
				</Form.Group>
			</Modal.Body>
			
			<Modal.Footer>
				<Button variant="primary"
							ref ={node=>setInterfaceBtn(node)}
							onClick={handleSubmit}
				>
					Submit
				</Button>
				<Button onClick={()=>props.onHide()} variant="outline-danger">Close</Button>
			</Modal.Footer>

		</Modal>
	)
};

export default LexCatInterface;