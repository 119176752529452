import React, { useState, useContext, useEffect } from 'react';

import { Modal, Button,
			Accordion, AccordionContext 
		} from 'react-bootstrap';
import { useAccordionButton } from 'react-bootstrap/AccordionButton';

import Verb from './Verb';
import Nominal from './Nominal';

import { InterfaceError } from '../helpers/blocks';

import '../helpers/blocks.css';
import '../../css/Components.css';

const MorphologicalInterface=props=>{
	const [type, setType] =useState('Nominal');
	const [inputParts, setInputParts]=useState({'i':false});

	const [showInterfaceError, setShowInterfaceError]=useState(false);

	useEffect(()=>{
		setInputParts({'i':false});
		// props.setansupdated(true);
	}, [props.ansstate]);

	const [interfaceBtn, setInterfaceBtn] =useState(null)
	useEffect(() => {
		if (interfaceBtn){
			// create an eventListener
			window.addEventListener('keydown', e=>handleKeyDown(e));
			// cleanup this component
			return () => {
				window.removeEventListener('keydown', e=>handleKeyDown(e));
			};
		}
	}, [interfaceBtn]);
	const handleKeyDown=(event)=>{
		if(event.key==='Enter'){
			event.preventDefault();
			// console.log('MODAL Enter:')
			interfaceBtn.click();	
		}
	};
	

	// Used in submit button
	const isDisabled =() =>{
		return ((type==='Nominal' && (
													
						(inputParts['i']===false 
							&& Object.keys(inputParts).length<4)
					))
					
				|| 
				
				(type==='Verb' && Object.keys(inputParts).length<5));
	};

	// Update answer (inflection) and close modal
	const handleSubmit=async () =>{
		if (isDisabled()){
			setShowInterfaceError(true);
			return;
		}

		let values = null;
		if (props.partType && props.partType==="participle-identification"){
			values = props.ansstate;
		}
		else {
			values = [...props.ansstate];
		}

		let input ="";
		if (type==='Nominal'){
			if (inputParts['i'])
				input = 'i'
			else
				input =inputParts['gender']+inputParts['case']+inputParts['number'];
		}
		else if(type==="Verb"){
			input=inputParts['tense']+'_'+inputParts['voice']+inputParts['person']+inputParts['number'];
			if (inputParts['root']!=='p')
				input=inputParts['root']+'_'+input
		}

		// console.log('input', input);
		// console.log('value', props.index[0], props.index[1], values[props.index[0]].anaBoxes[props.index[1]]);
		// console.log('partType', props.partType);
		
		// Update the value
		let done =false;
		if (props.partType) {
			if (["pronoun-declension", "verb-conjugation"].includes(props.partType)){
				done =true;
				values[props.index[0]].anaBoxes[props.index[1]].inflection =input;	
			}
			else if (props.partType === "participle-identification"){
				console.log('ans set')
				done =true;
				values.inflection = input;
			}
		}

		if (!done){
			// console.log('NOT DONE');
			values[props.index].inflection=input;
		}
		props.setans(values);
		props.onHide();
		return;
	};
	
	const CustomToggle = ({ btnPart, eventKey, callback }) => {
		const currentEventKey = useContext(AccordionContext);
		const isCurrentEventKey = currentEventKey.activeEventKey === eventKey;
		const decoratedOnClick = useAccordionButton(eventKey, 
			() => callback && callback(eventKey),
		);
	
		return (
			<Button key={btnPart} variant="outline-primary" 
				active={isCurrentEventKey}
				onClick={() =>{
					decoratedOnClick();
					setType(btnPart);
					console.log('btnPart:', btnPart);
					if (btnPart==="Nominal"){
						console.log('setting default InputParts', btnPart)
						setInputParts({'i':false})
					}
					else if (btnPart==="Verb"){
						console.log('setting default InputParts', btnPart)
						setInputParts({'root':'p'})
					}
				}}>
				{btnPart}
			</Button>
		);
	}
	
	return(
		<>
		<Modal
			{...props}
			size="lg"
			centered
		>
			<InterfaceError showInterfaceError={showInterfaceError} setShowInterfaceError={setShowInterfaceError} />

			<Accordion defaultActiveKey={1}>
				
			<Modal.Header closeButton>
				<div className="widthVW interfaceTitle">
					{props.title}
				</div>
			</Modal.Header>
			
			<Modal.Body>
				<div className="widthVW mb-2">
					<CustomToggle key={1} btnPart="Nominal" eventKey={1} />
					<CustomToggle key={2} btnPart="Verb" eventKey={2} />
				</div>
				<Accordion.Collapse key={1} eventKey={1}>
					<Nominal inputParts={inputParts} setInputParts={setInputParts}/>
				</Accordion.Collapse>
				<Accordion.Collapse key={2} eventKey={2}>
					<Verb inputParts={inputParts} setInputParts={setInputParts}/>
				</Accordion.Collapse>
			</Modal.Body>
			{/* enabled = (i===true) or (i===false && #>=4) */}
			{/* disabled = (i===false && #<4) */}
			{/*console.log(inputParts['i'], Object.keys(inputParts), inputParts['i']===false 
														&& Object.keys(inputParts).length<4) */}
			<Modal.Footer>
				<Button variant="primary"
							ref ={node=>setInterfaceBtn(node)}
							onClick={handleSubmit}
				>
					Submit
				</Button>
				<Button onClick={()=>props.onHide()} variant="outline-danger">Close</Button>
			</Modal.Footer>

			</Accordion>
		</Modal>
		</>
	)
};

export default MorphologicalInterface;