import React, { useState } from "react";

import { Accordion, ListGroup, Modal } from 'react-bootstrap';

import Select, { createFilter } from "react-select";
import createFilterOptions from "react-select-fast-filter-options";

import { colorStyles } from "./qparts/helpers/blocks";

import AuxWords from "./qparts/interfaces/AuxWords";
import {vocab_deva} from './qparts/helpers/glossary_deva';
import {vocab_roman} from './qparts/helpers/glossary_roman';


import '../components/css/Sidebar.css';
import '../components/css/Components.css';

// const pathToDocs ='../docs/';

const RightSidebar = ({curQ, curPi, sidebar}) => {

	// States and functions for showing pdf in modal
	const [open, setOpen]=useState(false);
	const [desc, setDesc]=useState('');
	const [pdfFile, setPDFfile]=useState('appendixB5-Slp1.pdf');

	const displayName=(parts)=>(
		<>
		{/* {console.log('29 parts', parts)} */}
		{parts.map((part,i)=>{
			if(part.script==='en'){
				return(<span key={i} className="name-en">{part.token}</span>)
			}
			else if(part.script==='deva'){
				return(<span key={i} className="name-deva">{part.token}</span>)
			}
		})}
		</>
	)

	const Item=(i, name, file)=>{
		// console.log('ITEM name:', name);
		// console.log('ITEM file:', file);
		return (
			<div key={i}>
				<Modal 
					show={open} 
					onHide={()=>setOpen(false)} 
					dialogClassName="pdfmodal-90w"
				>
					<Modal.Header  closeButton>
						{desc}
					</Modal.Header>
					<embed
						title="helpPdf"
						src={require("../docs/"+pdfFile)+"#zoom=150"}
						frameBorder="0"
						scrolling="auto"
						height="100%"
						width="100%"
					></embed>
				</Modal>
				<ListGroup.Item 
					onClick={()=>{setDesc(displayName(name));setPDFfile(file);setOpen(true)}}>
					<span className="linkLike">{displayName(name)}</span>
				</ListGroup.Item>
			</div>
		);
	};

	// Glossary & Collection of links for each part
	const GlossaryBar =({verbIdentification})=>{
		const [open, setOpen] =useState(false);

		let vocab = null;
		let filterOptions = null;
		
		if (verbIdentification){
			vocab = vocab_roman;
			filterOptions = createFilterOptions({vocab_roman});
		}
		else{
			vocab = vocab_deva;
			filterOptions = createFilterOptions({vocab_deva});			
		}
		const [glossarySelection, setGlossarySelection] =useState('')

		const formatOptionLabel = (data, meta) => (
			<>
			{meta.context ==='menu' &&
				<div className="d-flex flex-column flex-nowrap">
					{verbIdentification &&
						<div>{data.value}</div>
					}
					{!verbIdentification &&
						<div style={{fontFamily:'Praja', fontSize:'24pt'}}>
							{data.value}
						</div>
					}
					
					
					<div  className="d-flex flex-row flex-wrap"
							style={{marginLeft: "5px", color: "rgb(60,60,60)"}}
					>
						{data.attrib.map((oneAttribute, i)=>{
							if (i!==data.attrib.length-1){
								return(
									<div className="d-flex flex-column flex-nowrap"
											style={{padding:"0px 20px", borderRight:"2px solid rgb(70,70,70)"}}
									>
										<div>{oneAttribute['pos']}</div>
										<div dangerouslySetInnerHTML={{__html: oneAttribute['defs']}} />
									</div>
								)
							}
							else{
								return(
									<div className="d-flex flex-column flex-nowrap"
											style={{padding:"0px 20px"}}
									>
										<div>{oneAttribute['pos']}</div>
										<div dangerouslySetInnerHTML={{__html: oneAttribute['defs']}} />
									</div>
								)
							}
						})}

					</div>
				</div>
			}
			{meta.context==='value' &&
				<div>
					{verbIdentification &&
						<div>{data.value}</div>
					}
					{!verbIdentification &&
						<div style={{fontFamily:'Praja', fontSize:'24pt'}}>
							{data.value}
						</div>
					}
				</div>
			}
			</>
		);

		return (
			<>
				<Modal show={open} onHide={()=>setOpen(false)} style={{marginTop:'15vh'}}>
					<Modal.Header closeButton>
						<h2>Glossary</h2>
					</Modal.Header>
					<Modal.Body>
						<Select
							name ="glossary"
							id ="glossary"
							isMulti
							isClearable
							isSearchable
							backspaceRemovesValue

							options ={vocab}
							
							styles ={colorStyles}
							maxMenuHeight={400}
							
							filterOptions ={filterOptions}
							filterOption ={createFilter({ignoreCase:false, ignoreAccents:false, trim:true,  matchFrom:'start'})}
							
							formatOptionLabel={formatOptionLabel}

							placeholder = "Glossary"
							value ={glossarySelection}
							onChange ={(selectedOption) => setGlossarySelection(selectedOption)}
						/>
					</Modal.Body>
				</Modal>
				<span onClick={()=>setOpen(true)} style={{color:'#0d6efd', cursor:'pointer'}}>Glossary</span>
			</>
		);
	};

	const linksDefault =(
		<>
			<ListGroup.Item key='default-1'><GlossaryBar verbIdentification={false}/></ListGroup.Item>
			<ListGroup.Item key='default-2'><AuxWords /></ListGroup.Item>
			<ListGroup.Item key='default-3'><a href="https://www.sanskritlibrary.org/help-text.html" target="_blank" rel="noopener noreferrer">Data entry and display guide</a></ListGroup.Item>
			<ListGroup.Item key='default-4'><a href="https://www.sanskritlibrary.org/Sanskrit/script/devchars/index.html" target="_blank" rel="noopener noreferrer">Devanagri characters</a></ListGroup.Item>
		</>);

	const indexTransliteration=(
		<>
			<ListGroup.Item key='transliteration-1' style={{backgroundColor:'lightgray'}}><p className="Title">Error index</p></ListGroup.Item>
			<ListGroup.Item key='transliteration-2' style={{backgroundColor:"var(--popoverBlue)"}}>Replace</ListGroup.Item>
			<ListGroup.Item key='transliteration-3' style={{backgroundColor:"var(--popoverRed)"}}>Delete</ListGroup.Item>
			<ListGroup.Item key='transliteration-4' style={{backgroundColor:"var(--popoverYellow)"}}>Add</ListGroup.Item>
		</>
	);
	const indexSandhi=(
		<>
			<ListGroup.Item key='sandhi-1' style={{backgroundColor:'lightgray'}}><p className="Title">Error index</p></ListGroup.Item>
			<ListGroup.Item key='sandhi-2' style={{backgroundColor:"var(--popoverRed)"}}>Sandhi error</ListGroup.Item>
			<ListGroup.Item key='sandhi-3' style={{backgroundColor:"var(--popoverBlue)"}}>Spacing error</ListGroup.Item>
		</>
	);
	const indexSandhiAnalysis=(
		<>
			<ListGroup.Item key='sandhiAnalysis-1' style={{backgroundColor:'lightgray'}}><p className="Title">Error index</p></ListGroup.Item>
			<ListGroup.Item key='sandhiAnalysis-2' style={{backgroundColor:"var(--popoverRed)"}}>Wrong analysis</ListGroup.Item>
			<ListGroup.Item key='sandhiAnalysis-3' style={{backgroundColor:"var(--popoverBlue)"}}>Insufficient analysis</ListGroup.Item>
		</>
	);

	const indexTranslation =(
		<>
			<ListGroup.Item key='translation-1' style={{backgroundColor:"white", color:"#DAA520"}}>Primary relation</ListGroup.Item>
			<ListGroup.Item key='translation-2' style={{backgroundColor:"white ", color:"#ACAC9A"}}>Secondary relation</ListGroup.Item>
		</>
	);
	

	// Other variables
	const part =curQ.parts[curPi];

	var links=null;
	var index =null;
	var indexPresent =false;

	// Set index details
	if (["transliteration", "devanagari"].includes(part)) {
		indexPresent =true;
		index = indexTransliteration;
		// links = linksTransliteration;

	} else if (["sandhi", "sandhi-analysis"].includes(part)){
		indexPresent =true;
		// links = linksSandhi;

		part ==="sandhi"
		?index =indexSandhi
		:index =indexSandhiAnalysis;

	} else if (part ==="verb-identification"){
		{}
		// links =linksVerbIdentification;
	} else if(part ==="compound-sandhi-analysis"){
		indexPresent =true;
		index =indexSandhiAnalysis;
		// links =linksCompoundSandhi;
	} else if (curQ.parts.includes('syntax') && part ==="translation"){
		indexPresent =true;
		index =indexTranslation;
	}
	
	// Determine links based on sidebar in helpData.py
	if (sidebar.length===0){
		links =linksDefault;
	} else {
		links =(
			<div>
				{sidebar.map((row, i)=>{
					if(row.tag==='item') {
						console.log(row.item.type);
						if(row.item.type==='glossary'){
							return (<ListGroup.Item key={i}><GlossaryBar verbIdentification={part==='verb-identification'}/></ListGroup.Item>)
						} else if(row.item.type==='auxwords'){
							return (<ListGroup.Item key={i}><AuxWords/></ListGroup.Item>) 
						} else if(row.item.type==='link'){
							return (<ListGroup.Item key={i}><a href={row.item.target} target="_blank" rel="noopener noreferrer">{displayName(row.item.name)}</a></ListGroup.Item>)
						} else if(row.item.type==='file'){
							return (Item(i, row.item.name, row.item.pdf))
						}
					}
					else if(row.tag==='list') {
						return (
								<Accordion key={`accordion-${i}`}>
								<Accordion.Item eventKey={row.name[0].token}>
									<Accordion.Header>
										{displayName(row.name)}
									</Accordion.Header>
									<Accordion.Body>
										{row.list.map((help, j)=>{
											// console.log("help:", help);
											if(help.item.type==='link'){
												return(<ListGroup.Item key={`${i}-${j}`}><a href={help.item.target} target="_blank" rel="noopener noreferrer">{displayName(help.item.name)}</a></ListGroup.Item>)
											} else if(help.item.type==='file'){
												// console.log('showing item')
												return(Item(`${i}-${j}`, help.item.name, help.item.pdf))
											}
										})}
									</Accordion.Body>
								</Accordion.Item>
								</Accordion>
						)
					}
				})}
				{/* {part==="translation" &&
					<ListGroup.Item key={'auxWords'}><AuxWords/></ListGroup.Item>
				} */}
			</div>
		)
	}

	return (
		<>
			{indexPresent&&
				<ListGroup className="mb-2" style={{backgroundColor:'lightgray'}}>
					{index}
				</ListGroup>
			}

			<ListGroup>
				<ListGroup.Item style={{backgroundColor:'lightgray'}}><p className="Title">Help</p></ListGroup.Item>
				<div className="helpLinks">
					{links}
				</div>
			</ListGroup>
		</>
	);
};

export default RightSidebar;
