import React from 'react';
import ClockLoader from "react-spinners/ClockLoader"

import './blocks.css';

const LoadingPage =({loading}) =>{
	
	// const override ={
	// 	padding: "20px",
	// 	background: "white",
	// 	borderRadius: "15px",
	// 	border: "1px white solid",
	// };

	return (
		<>
			{loading &&
				<div className="loading-container">
					<ClockLoader
						loading ={loading}
						size={70} //only for clockLoader

						color={'#007BFF'}
						// cssOverride={override}
					/>
				</div>
			}
		</>
	);
};

export default LoadingPage;