import React, {useState, useEffect, useContext} from 'react';
import {Button, Form, FloatingLabel} from 'react-bootstrap';
import  {UserContext} from "../../../providers/UserProvider";

import { keyListener } from '../helpers/blocks';
import LoadingPage from "../helpers/LoadingPage";

import '../../css/Components.css';

const Vigraha =({curQ, setPartInst, ch, curPi, qIndex, 
						setCurQComplete, btnClicked, setAnsSaved}) =>{
	// To autosave when next or previous buttons are clicked
	useEffect(()=>{
		if (btnClicked===true){
			checkAns();
			setAnsSaved(true);
		};
	}, [btnClicked]);
	
	const [loading, setLoading] =useState(false);

	const {currentUser} = useContext(UserContext);
	
	const [paraphrase, setParaphrase] =useState(curQ.uans[0]);
	const [translation, setTranslation] =useState(curQ.uans[1]);

	// console.log('translation:', translation);
	// console.log('paraphrase:', paraphrase);
	//Check User's Answer
	const checkAns =() => {
		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ uans:[paraphrase, translation], pi: curPi, email:currentUser.email,
											qObj: { 'ch':ch,'exercise':curQ.exercise, 'n':curQ.n, 's': curQ.s,'i':qIndex, 
													'parts': curQ.parts, 'text': curQ.text, 'xmlid':curQ.xmlid, 'total':curQ.total }
										})
		};
		const getFeedback =async () => {
			setLoading(true);

			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			setPartInst(data.analysis.inst);
			setCurQComplete(data.completed);
			
			setLoading(false);
		};
		getFeedback();
	};

	//Enter = Submit
	const [btn, setBtn]=useState(null);
	useEffect(() => {
		if (btn){
			// create an eventListener
			// console.log('vigraha keyListener');
			window.addEventListener('keydown', e=>keyListener(e, btn));
		}
	}, [btn]);
	useEffect(() =>{
		// console.log('mounted vigraha');
		// cleanup this component
		return () => {
			// console.log('unmounted vigraha');
			window.removeEventListener('keydown', e=>keyListener(e, btn));
		};
	}, [])
	const  handleInputChange =(event, type) => {
		const newAns ={...translation};
		switch(type){
			case "translation":
				newAns.translation =event.target.value;
				break;
			case "comment":
				newAns.comment =event.target.value;
				break;
			default:
				break;
		};
		setTranslation(newAns);
	};

	const handleToggle =(checked) =>{
		const newAns ={...translation};
		newAns.addComment =checked;
		// console.log('event', checked);
		if (!newAns.addComment)
			newAns.comment ="";
		
		setTranslation(newAns);
		// console.log('newAns', newAns);
	};
	
	return (
		<div>
			<LoadingPage loading={loading} />
			
			{curQ.takeInput &&
			<>
				<h5><span className="blueText">Q:</span>
					<span className="praja32px">
						{curQ.text}
					</span>
				</h5>
	
				<div className="FlexBox">
					<h5><span className="blueText">A:</span></h5>
					<div className="AnsForm">
						
						<Form>
							<FloatingLabel
								controlId="paraphrase"
								label="Paraphrase"
								className="mb-3"
							>
								<Form.Control autoFocus name="paraphrase" 
													as="textarea" value={paraphrase} 
													onChange={e=>setParaphrase(e.target.value)}			
													onFocus={e => {
														var val = e.target.value;
														e.target.value = '';
														e.target.value = val;
													}}
								/>
							</FloatingLabel>

							<FloatingLabel
									controlId="translation"
									label="Translation"
									className="mb-3"
							>
							<Form.Control autoFocus autoComplete="off" className="form-control"
												name="translation" id={"translation"} as="textarea" 
												value={translation.translation} 
												onChange={e=>handleInputChange(e, "translation")}
												onFocus={e => {
													var val = e.target.value;
													e.target.value = '';
													e.target.value = val;
												}}
								/>
							</FloatingLabel>

							<div className ="KeepRight">
								<label htmlFor ="commentBtn" 
									className ="form-check-label"
									style ={{marginRight:'5px'}}
								>
									Add comment
								</label>
								<input
									type ="checkbox"
									id ="commentBtn"
									name ="commentBtn"
									className ="form-check-input"
									checked ={translation.addComment}
									onChange ={e=>handleToggle(e.target.checked)}
								/>
							</div>
						
							{translation.addComment===true &&
								<Form>
									<FloatingLabel
										controlId="comment"
										label="Comment/Query"
										className="mb-3"
									>
									<Form.Control autoFocus autoComplete="off" className="form-control"
													name="comment" id={"comment"} as="textarea" 
													placeholder="Type your comment" value={translation.comment}
													onChange={e=>handleInputChange(e, "comment")}
													onFocus={e => {
														var val = e.target.value;
														e.target.value = '';
														e.target.value = val;
													}}
										/>
									</FloatingLabel>
								</Form>
							}

							<div className="KeepRight">
								<Button onClick={()=>checkAns()}
											ref={node=>setBtn(node)}
								>
									Submit
								</Button>
							</div>
						</Form>
						
					</div>
				</div>
			</>
			}
		</div>
	);
};

export default Vigraha;