import React, {useState} from "react";
import {Popover, OverlayTrigger, Modal } from 'react-bootstrap';

import '../../css/Components.css';

const TransliterationPopUp = (props) => {
	const [open, setOpen]=useState(false);
	const [desc, setDesc]=useState('');
	const [pdfFile, setPDFfile]=useState('appendixB5-Slp1.pdf');

	// Handle Units:
	let units = props.romanUnits;
	
	// let popover = popoverBlue; #default popover is blue
	let items = [] // going to be displayed in the frontend
	
	for(let i = 0; i < units.length; i++) {
		let unit =units[i];

		if (unit.type==='normal'){
			items.push(<span key={i}>{unit.text}</span>);
			continue;
		}

		// else ERROR
		console.log('error:', unit.popover);
		// Create popover
		let backgroundColor="var(--popoverRed)"; // default is red
		if (unit.popover.color==='blue') {
			backgroundColor="var(--popoverBlue)";
		}
		else if (unit.popover.color==='yellow') {
			backgroundColor="var(--popoverYellow)";
		}
		let popover =(
			<Popover id="popover-basic">
				<Popover.Header as="h3"  style={{backgroundColor: backgroundColor, textAlign:"center"}}>
					{unit.popover.head}
				</Popover.Header>
				<Popover.Body>
					{unit.popover.message}
				</Popover.Body>
			</Popover>
		);
		console.log('popover:', popover);

		console.log('help', unit.help);
		// add to item based on help type
		if (unit.help.type==='file') {
			items.push(
				<a key={i} target="_blank" rel="noopener noreferrer" style={{color:'black'}}
					onClick={()=>{setDesc(unit.help.desc);setPDFfile(unit.help.pdf);setOpen(true)}}
				>
					<OverlayTrigger key={i} trigger={["hover", "focus"]} placement="right" overlay={popover}>
						<span style={{backgroundColor:backgroundColor}}>{unit.text}</span>
					</OverlayTrigger>
				</a>
			);
		}
		else if (unit.help.type==='link') {
			items.push(
				<a key={i} href={unit.help.target} target="_blank" rel="noopener noreferrer" style={{color:'black'}}>
					<OverlayTrigger key={i} trigger={["hover", "focus"]} placement="right" overlay={popover}>
						<span style={{backgroundColor:backgroundColor}}>{unit.text}</span>
					</OverlayTrigger>
				</a>
			);
		}
	}

	return (
		<div>
			<Modal 
				show={open} 
				onHide={()=>setOpen(false)} 
				dialogClassName="pdfmodal-90w"
			>
				<Modal.Header  closeButton>
					{desc}
				</Modal.Header>
				<embed
					title="helpPdf"
					src={require("../../../docs/"+pdfFile)+"#zoom=150"}
					frameBorder="0"
					scrolling="auto"
					height="100%"
					width="100%"
				></embed>
			</Modal>
			<span style={{fontSize:'24px'}}>
				{items.map(ch =>(
					ch
				))}
			</span>
		</div>
	)
}

export default TransliterationPopUp


/** 
const popoverYellow = (
	<Popover id="popoverYellow">
		<Popover.Header as="h3" style={{backgroundColor: "var(--popoverYellow)", textAlign:"center"}}>
			Add
		</Popover.Header>
		<Popover.Body>
			Your answer is missing some character(s). You need to add something in the highlighted portion. Click to view a helpful document.
		</Popover.Body>
	</Popover>
);
const popoverRed = (
	<Popover id="popoverRed">
		<Popover.Header as="h3"  style={{backgroundColor: "var(--popoverRed)", textAlign:"center"}}>
			Delete
		</Popover.Header>
		<Popover.Body>
			The highlighted character needs to be deleted. Click to view a helpful document.
		</Popover.Body>
	</Popover>
);
const popoverBlue = (
	<Popover id="popoverBlue">
		<Popover.Header as="h3" style={{backgroundColor: "var(--popoverBlue)", textAlign:"center"}}>
			Replace
		</Popover.Header>
		<Popover.Body>
			You need to replace the highlighted character. Click to view a helpful document.
		</Popover.Body>
	</Popover>
);
*/