import React from 'react';
import ReactDOM from 'react-dom';

import {UserProvider} from "./providers/UserProvider";

import App from './App';

// Import styles
import 'bootstrap/dist/css/bootstrap.min.css';


ReactDOM.render(
		
	<UserProvider>
		<App />
	</UserProvider>,

	document.getElementById('root')
);

// For v18 (not using because many functions not working)
// const root =ReactDOM.createRoot(document.getElementById('root'));

// root.render(
// 	<React.StrictMode>
		
// 		<UserProvider>
// 			<App />
// 		</UserProvider>

// 	</React.StrictMode>
// );