import React, {useState, useContext} from 'react';

import { Button,OverlayTrigger, 
			Tooltip, InputGroup, Modal
		} from "react-bootstrap";
import ImageFadeIn from "react-image-fade-in";

import {UserContext} from "../../providers/UserProvider";
import {SingleInflection} from "./helpers/blocks";

// import VerbInterface from './interfaces/VerbInterface';
import MorphologicalInterface from './interfaces/MorphologicalInterface';

// import BigText from './helpers/BigText';

import cross from './helpers/cross.png';
import LoadingPage from "./helpers/LoadingPage";
import question from './helpers/questionOrange.png';

import './helpers/blocks.css';
import '../css/Components.css';

// This component is for both Pronoun Declension and Verb Conjugation parts 
// (same applies for all backend functions and endpoints)
const PronounDeclension =({curQ, setPartInst, ch, curPi, qIndex, setCurQComplete}) =>{

	const [loading, setLoading] =useState(false);
	
	const {currentUser} = useContext(UserContext);

	// const [qText, setqText] =useState(curQ.text);
	const partType =curQ.parts[curPi];
	const [interfaceTitle, setInterfaceTitle] =useState(''); 
	const [interfaceIndex, setInterfaceIndex] =useState([0,0]);
	const [showInterface, setShowInterface] =useState(false);
	
	// //Enter = Submit
	// // Disabbled to avoid load on ec2 isntance
	// const [btn, setBtn]=useState(null) // use setBtn ref={node=>setBtn(node)}

	// const interfaceOpen =useRef(null);
	// useEffect(()=>{
	// 	interfaceOpen.current =showInterface;
	// }, [showInterface]);
	
	// const handleKeyDown=(event)=>{
	// 	// console.log('key=', event.key)
	// 	if(event.key==='Enter' & !interfaceOpen.current){
	// 		event.preventDefault();
	// 		btn.click();
	// 	}
	// };
	// useEffect(() => {
	// 	if (btn){
	// 		// create an eventListener
	// 		window.addEventListener('keydown', e=>handleKeyDown(e));
	// 		// cleanup this component
	// 		return () => {
	// 			window.removeEventListener('keydown', e=>handleKeyDown(e));
	// 		};
	// 	}
	// }, [btn]);

	//Misc Functions !
	const getBorderColor =(error, correct)=>{
		if (error)
			return 'red';
		
		if (correct)
			return 'green';

		return 'transparent';
	};

	// This is constructed in the backend (python) and passed as part of the curQ
	const [ansState, setAnsState] = useState(curQ.uans); 

	// useEffect(()=>{
	// 	console.log("newState:", ansState);
	// }, [ansState]);
	
	const handleInputChange = (row, ana, event, word) => {
		const values = [...ansState];
		// console.log('values', values[row]);
		switch(event.target.name){
			case "inflection":
				values[row].anaBoxes[ana].inflection = event.target.value;
				if (event.target.value==='?' ||  event.target.value==='/'){
					setInterfaceTitle(word);
					setInterfaceIndex([row, ana]);
					setShowInterface(true);
				}
				break;
			default:
				break;
		}
	
		setAnsState(values);
	};

	// Checking the user's answers

	//checking box or all depending on checkType
	const check =(index, checkType) =>{	
		// console.log("checking", checkType);
		// console.log("values@", ansState[index]);

		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ uans: ansState, pi: curPi, email: currentUser.email, checkType: checkType,
											qObj: {'ch':ch,'exercise':curQ.exercise, 'n':curQ.n, 's': curQ.s, 'ai':index,
													'i':qIndex, 'parts': curQ.parts, 'text': curQ.text, 'xmlid':curQ.xmlid, 'total':curQ.total}
										})
		}
	
		const getFeedback =async () => {
			setLoading(true);

			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			// console.log("data:", data.analysis.allComplete);

			setPartInst(data.analysis.inst);
			// setqText(data.analysis.afterState[0]);
			setAnsState(data.analysis.afterState[1]);
			setCurQComplete(data.completed);
		
			setLoading(false);
		};
		getFeedback();
	};

	const [showPdf, setShowPdf] =useState(false);
	const [pdfFile, setPDFfile]=useState('agni.pdf');
	const InflectionError =(box, title, message, pdf) => {
		if (message.length===0)
			message ="Your inflectional identifier is incorrect."

		if (box.empty)
			return <OverlayTrigger
						placement="top"
						overlay={<Tooltip>Your inflectional identifier is blank.</Tooltip>}
					>
						<ImageFadeIn opacityTransition={3}
							width={20} height={20} src={question} alt='empty'/>
					</OverlayTrigger>
		else if (pdf.length===0) {
			return <OverlayTrigger
						placement="top"
						overlay={<Tooltip>{message}</Tooltip>}
					>
						<ImageFadeIn opacityTransition={3}
							width={20} height={20} src={cross} alt='wrong'/>
					</OverlayTrigger>
		}
		else {
			return (
				<>
					<Modal 
							show={showPdf} 
							onHide={()=>setShowPdf(false)} 
							dialogClassName="pdfmodal-90w"
					>
						<Modal.Header closeButton> 
							<div style={{display:'flex', justifyContent:'center', width:'100%'}}>
								<span style={{fontSize:'24pt', fontFamily:'Praja'}}>{title}</span>
							</div>
						</Modal.Header>
						<embed
							title="helpPdf"
							src={require("../../docs/"+pdfFile)+"#zoom=150"}
							frameBorder="0"
							scrolling="auto"
							height="100%"
							width="100%"
						></embed>
					</Modal>

					<OverlayTrigger
						placement="top"
						overlay={<Tooltip>{message}</Tooltip>}
					>
						<ImageFadeIn opacityTransition={3} width={20} height={20}
							onClick={()=>{setPDFfile(pdf);setShowPdf(true)}} src={cross} alt='wrong'/>
					</OverlayTrigger>
				</>
			);
		}
	}

	return (
		<div>
			<LoadingPage loading={loading} />

			<MorphologicalInterface
				show={showInterface}
				onHide={()=>setShowInterface(false)}
				title={interfaceTitle}
				index={interfaceIndex}
				ansstate={ansState}
				setans={(newAns)=>setAnsState(newAns)}
				partType={partType}
			/>
			

			{curQ.takeInput &&
			<>
				<div className="d-flex align-items-center">
					<h4 style={{marginRight:'5px'}}><span className="blueText">Q:</span></h4>
					<div className="d-flex flex-row align-items-center">
						<div style={{fontSize:'35px', fontFamily:'Praja'}}>
							{curQ.text.deva}
						</div>
						<div style={{fontSize:'18px', marginLeft:'10px'}}>{curQ.text.pos}</div>
						<div style={{fontSize:'18px', marginLeft:'10px'}}>
							{'\u2018'}{curQ.text.def}{'\u2019'}
						</div>
					</div>
				</div>
				<div className="FlexBox">
					<h4 style={{marginRight:'5px'}}><span className="blueText">A:</span></h4>
	
					<div className="drillTable drillScroll">
						{ansState.map((cur, index)=>(
							<div key={`${cur.title}${index}`} className="drillRow">
								<div className='drillNum'>
									{index+1}
								</div>

								<div className='drillTitle'>
									{cur.title}
								</div>
							
								<div className='drillAnaBoxes'>
									{cur.anaBoxes.map((box, anaIndex)=>(
										<SingleInflection key={anaIndex} borderColor={getBorderColor(box.inflectionError, box.allCorrect)}>
										{/* {console.log("box", cur.title,  box.help)} */}
										<InputGroup className='inputBox'>
											<input
												type="text"
												autoComplete="off"
												className="form-control"

												name="inflection"
												id={`"inflection"${anaIndex}`}
												aria-describedby={`"inflectionError"${anaIndex}`}
												
												// placeholder="Third"
												value={box.inflection}
												onChange={(event) => handleInputChange(index, anaIndex, event, cur.title)}

												style={{padding:'5px 10px'}}
											/>
											{box.inflectionError &&
												<div className='inputBoxError'>										
													{InflectionError(box, cur.title, box.help.message, box.help.pdf)}
												</div>
											}
										</InputGroup>

										</SingleInflection>
									))}
									<Button variant='outline-primary' size='sm' style={{'float':'right', 'margin':'4px 0px 0px 10px', 'maxHeight':'30px'}}
										onClick={()=>check(index, 'Box')}>Check
									</Button>
								</div>
							</div>
						))}
					</div>
				</div>
				
				<div className="KeepRight">
					<Button variant='primary' 
						onClick={()=>check(0, 'All')}>Check all
					</Button>
				</div>

			</>
			}
		</div>
	);
};

export default PronounDeclension;