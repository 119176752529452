import React, {useState, useContext} from "react";
import { ProSidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';

import { Modal } from "react-bootstrap";
import * as FaIcons from 'react-icons/fa';
import  {UserContext} from "../providers/UserProvider";

import 'react-pro-sidebar/dist/css/styles.css';
import './css/Sidebar.css';

const Sidebar = () => {
	const {currentUser} = useContext(UserContext);

	const [toggle, setToggle] = useState(false);
	function onClickToggle() {
		setToggle(!toggle);
	}
	
	const linksDefault=(
		<>
			<MenuItem><a href="https://www.sanskritlibrary.org/help-text.html" target="_blank" rel="noopener noreferrer">Data Entry and Display Guide</a></MenuItem>
			<MenuItem><a href="https://www.sanskritlibrary.org/Sanskrit/script/devchars/index.html" target="_blank" rel="noopener noreferrer">Devanagri Characters</a></MenuItem>
		</>
	)
	
	const visible_version = (
		<div className='sideBack'>
			<div className="sidebar stayLeft" style={{
					backgroundColor: "#343a40", zIndex:'3'
			}}>
				<ProSidebar>
					<FaIcons.FaBars className="icon-cog" onClick={onClickToggle} /> 
					<Menu iconShape="square">
						<MenuItem>
							<a href='/bhatti'>Bhatti</a>
						</MenuItem>
						<SubMenu title="Helpful Links">
							<SubMenu title="General">
								{linksDefault}
							</SubMenu>
						</SubMenu>
					</Menu>
				</ProSidebar>
			</div>
		</div>
	)
	const invisible_version = (
		<div className="toggle-off stayLeft">
			<FaIcons.FaBars className="icon-cog" onClick={onClickToggle} />
		</div>
	);
	return (
		currentUser ?
			toggle ? visible_version : invisible_version
		: null
	);
}

export default Sidebar;
