import React, { useState, useEffect, useContext } from 'react';
import { useHistory } from 'react-router';

import Error from '../Error';
import { FaSearch, FaArrowLeft } from 'react-icons/fa';
import  {UserContext} from "../../providers/UserProvider";
import { Button, OverlayTrigger, Tooltip, Modal, ButtonGroup, ToggleButton } from "react-bootstrap";

// Import react-circular-progressbar module and styles
import {
		CircularProgressbarWithChildren,
		buildStyles
	} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
  
// Animation
import {easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";

// React-pdf
import AnsDocument from './Answers/ansDocument';

import './StudentStyles.css';

const SearchBar =({query, setQuery})=>{
	return (		
		<div className="searchContainer">
			<FaSearch className="searchIcon" />
			<input className="searchInput" type='text' value={query} 
						onChange={(e)=>setQuery(e.target.value)}/>
		</div>
	);
};

const StudentExProgress =({match})=>{
	const history=useHistory();
	const {currentUser} = useContext(UserContext);

	// Display exercises and progress
	const [done, setDone] = useState(false);
	const [exProgress, setExProgress] =useState([]);
	const [filteredExProgress, setFilteredExProgress] =useState([]);

	// exercise answers 
	const [loaded, setLoaded]=useState(false);
	const [data, setData] =useState({}); //response for a specific exercise. exercise's answers
	const [metadata, setMetadata] =useState({}); //response for a specific exercise. exercise's details
	const [showpdf, setShowpdf]=useState(false);

	const [curEx, setcurEx]=useState(0);
	const [ansType, setAnsType]=useState('All Parts');
	const [exErrorMsg, setExErrorMsg]=useState('');

	// General states
	const [errorMsg, setErrorMsg] =useState("");

	const [query, setQuery] =useState([]);

	// Fetches all exercise's progress
	useEffect(()=>{
		var postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ch:match.params.ch, email:match.params.email})
		}
		const getStudents = async()=>{
			const response = await fetch(process.env.REACT_APP_FLASK_API+'get_students_exProgress', postObj);
			
			const data = await response.json();
			// console.log('DATA:', data);

			setErrorMsg(data.errorMsg);

			setFilteredExProgress(data.progress);
			setExProgress(data.progress);

			setDone(true);
		};
		getStudents();
	}, [match.params.ch, match.params.email]);

	// Fetches answers for a given exercise
	const getExAnswers =async(ex, type)=>{
		setAnsType(type);
		setLoaded(false);
		setcurEx(ex);
		// console.log('Getting answers for ', ex);
		const url = `ex_answers?email=${match.params.email}
											&ch=${match.params.ch}
											&ex=${ex}&type=${type}`;
		const response = await fetch(process.env.REACT_APP_FLASK_API+url);
		const data = await response.json();

		setExErrorMsg(data.errorMsg);
		setData(data.exData.userAns);
		setMetadata(data.exData.details);

		setLoaded(true);
		setShowpdf(true);
	};
		

	// Updates list of exercises based on search query
	Object.filter =(obj, predicate) => 
						Object.fromEntries(Object.entries(obj).filter(predicate));
	useEffect(() =>{
		setFilteredExProgress(Object.filter(exProgress, ([exP, details])=>{
			if (query.length===0 || query.trim()==='') {
				return true;
			} else if (details[0].toLowerCase().includes(query.trim().toLowerCase())) {
				return true;
			} else if (exP.toLowerCase().includes(query.trim().toLowerCase())) {
				return true;
			}
		}));
   	}, [query, exProgress]);

	if (!['dev', 'prof'].includes(currentUser.utype))
		return <Error message="No such page" />
	else if (errorMsg.length>0)
		return <Error message={errorMsg} />
	else if (Object.keys(exProgress).length===0 || !done)
		return <Error message="Loading..." />
	return (
		<div>

			{loaded &&
			<Modal
				show={showpdf}
				onHide={() => setShowpdf(false)}
				dialogClassName="modal-90w"
			>
				<Modal.Header closeButton>
					<Modal.Title>	
						Ch. {metadata.chnum} {metadata.type} {metadata.exnum}
						: <span 
								dangerouslySetInnerHTML=
								{{__html: metadata.extitle}}
						/>	
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					{exErrorMsg!=='' &&
						<div style={{textAlign:'center'}}>{exErrorMsg}</div>
					}
					{exErrorMsg==='' &&
						<AnsDocument metadata={metadata} data={data} email={match.params.email}/>
					}
				</Modal.Body>
				<Modal.Footer className="d-flex justify-content-between">
					<ButtonGroup>
						{['Translations', 'All Parts'].map((type, i)=>(
							<ToggleButton
								key={i} 
								type='radio' name='radio'
								variant='outline-primary'
								value={type}
								checked={ansType===type}
								onClick={()=>getExAnswers(curEx, type)}
							>
								{type}
							</ToggleButton>
						))}
					</ButtonGroup>
					<Button 
						variant='outline-primary' 
						href={`data:text/json;charset=utf-8,
								${encodeURIComponent(JSON.stringify(data, null, '\t'))}`}
						download={`${match.params.email.split('@')[0]}_ch${metadata['chnum']}ex${metadata['exnum']}.json`}
					>
						Download
					</Button>
				</Modal.Footer>
			</Modal>
			}

			<div className="studentHeader">
				<div className="studentCountSent">
					<div>Chapter <span className="studentCount">{match.params.ch}</span></div>
					<div>
						<span className="studentCount">{Object.keys(filteredExProgress).length}</span> 
						{filteredExProgress.length===1?'Exercise/Drill':'Exercises/Drills'}
					</div>
				</div>
				
				<div className="studentCenter">
					<SearchBar query={query} setQuery={setQuery} />
				</div>
				
				<div className="studentTitle">{match.params.email}</div>
			</div>
		
			<div className="studentsArea">
				{Object.keys(filteredExProgress).map(ex => (
					<OverlayTrigger
						key={ex}
						placement="right"
						overlay={
						<Tooltip id={`tooltip-${ex}`} style={{width:'100px', wordBreak:'break-word'}}>
							Click to <strong>view</strong> answers
						</Tooltip>
						}
					>
					<div className="studentBox" onClick={()=>getExAnswers(ex, 'All Parts')}>
						{/* {console.log('ex:',ex, filteredExProgress[ex])} */}
						<AnimatedProgressProvider
							valueStart={0}
							valueEnd={filteredExProgress[ex][1]}
							duration={0.75}
							easingFunction={easeQuadInOut}
						>
							{(value) => {
							const roundedValue = Math.round(value);
							return (
								<CircularProgressbarWithChildren
									value={value}
									// text={`${roundedValue}%`}
									circleRatio={0.75}
									styles={buildStyles({
										rotation: 1 / 2 + 1 / 8,
										pathTransition: "none",
										strokeLinecap: "butt",
										trailColor: "#eee",
										pathColor: "rgb(0, 123, 255)"
									})}
								>
									<div className="exKey">{ex}</div>
									<div className="percentage">
										{roundedValue}%
									</div>
								</CircularProgressbarWithChildren>
							);
							}}
						</AnimatedProgressProvider>
						<span className="Name" 
								dangerouslySetInnerHTML=
								{{__html: filteredExProgress[ex][0] }}
						/>
					</div>
					</OverlayTrigger>
				))}
			</div>

			<div className='footButtons'>
				<OverlayTrigger placement='top'
					overlay={
						<Tooltip>
							<strong>Previous</strong> page 
						</Tooltip>
					}
				>
					<Button variant='primary' onClick={(e)=>history.goBack()} className="mr-1"><FaArrowLeft size={21}/></Button>
				</OverlayTrigger>
			</div>

		</div>
	);
};

export default StudentExProgress;