export const vocab_roman = [
	{'value': 'agni', 'label': 'agni', 'attrib': [{'pos': 'm', 'defs': 'fire'}], 'isLemma': 1},
	{'value': 'agra', 'label': 'agra', 'attrib': [{'pos': 'n', 'defs': 'tip, beginning'}], 'isLemma': 1},
	{'value': 'aṅgulīya', 'label': 'aNgulIya', 'attrib': [{'pos': 'n', 'defs': 'ring'}], 'isLemma': 1},
	{'value': 'atas', 'label': 'atas', 'attrib': [{'pos': 'dem_adv', 'defs': 'hence'}], 'isLemma': 1},
	{'value': 'ati', 'label': 'ati', 'attrib': [{'pos': 'preverb', 'defs': 'beyond'}], 'isLemma': 1},
	{'value': 'ati_i', 'label': 'ati_i', 'attrib': [{'pos': 'vt2a', 'defs': 'go beyond, pass'}], 'isLemma': 1},
	{'value': 'atithi', 'label': 'atiTi', 'attrib': [{'pos': 'm', 'defs': 'guest'}], 'isLemma': 1},
	{'value': 'atiyat', 'label': 'atiyat', 'attrib': [{'pos': 'prap', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atīta', 'label': 'atIta', 'attrib': [{'pos': 'ppp', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atītavat', 'label': 'atItavat', 'attrib': [{'pos': 'pap', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atītavya', 'label': 'atItavya', 'attrib': [{'pos': 'ger', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atītya', 'label': 'atItya', 'attrib': [{'pos': 'abs', 'defs': 'go beyond, pass'}, {'pos': 'ger', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atīyamāna', 'label': 'atIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atīyāna', 'label': 'atIyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atīyivas', 'label': 'atIyivas', 'attrib': [{'pos': 'pfap', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'attavya', 'label': 'attavya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'attum', 'label': 'attum', 'attrib': [{'pos': 'inf', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'attvā', 'label': 'attvA', 'attrib': [{'pos': 'abs', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'atyayanīya', 'label': 'atyayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atyetum', 'label': 'atyetum', 'attrib': [{'pos': 'inf', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atyeṣyat', 'label': 'atyezyat', 'attrib': [{'pos': 'fap', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atyeṣyamāṇa', 'label': 'atyezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'atra', 'label': 'atra', 'attrib': [{'pos': 'dem_adv', 'defs': 'here'}], 'isLemma': 1},
	{'value': 'atsyat', 'label': 'atsyat', 'attrib': [{'pos': 'fap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'atsyamāna', 'label': 'atsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'atha', 'label': 'aTa', 'attrib': [{'pos': 'pcl', 'defs': 'then, now'}], 'isLemma': 1},
	{'value': 'ad', 'label': 'ad', 'attrib': [{'pos': 'vt2a', 'defs': 'eat'}], 'isLemma': 1},
	{'value': 'adat', 'label': 'adat', 'attrib': [{'pos': 'prap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'adanīya', 'label': 'adanIya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'adas', 'label': 'adas', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'that'}], 'isLemma': 1},
	{'value': 'adya', 'label': 'adya', 'attrib': [{'pos': 'adv', 'defs': 'today'}], 'isLemma': 1},
	{'value': 'adyamāna', 'label': 'adyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'adhi', 'label': 'aDi', 'attrib': [{'pos': 'preverb', 'defs': 'over'}], 'isLemma': 1},
	{'value': 'adhijagāna', 'label': 'aDijagAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhi_i', 'label': 'aDi_i', 'attrib': [{'pos': 'vt2m', 'defs': 'go over, study, read, recite'}], 'isLemma': 1},
	{'value': 'adhīta', 'label': 'aDIta', 'attrib': [{'pos': 'ppp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhītavat', 'label': 'aDItavat', 'attrib': [{'pos': 'pap', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhītya', 'label': 'aDItya', 'attrib': [{'pos': 'abs', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhīyamāna', 'label': 'aDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhīyāna', 'label': 'aDIyAna', 'attrib': [{'pos': 'prmp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhunā', 'label': 'aDunA', 'attrib': [{'pos': 'adv', 'defs': 'now'}], 'isLemma': 1},
	{'value': 'adhyayanīya', 'label': 'aDyayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhyetavya', 'label': 'aDyetavya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhyetum', 'label': 'aDyetum', 'attrib': [{'pos': 'inf', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhyeya', 'label': 'aDyeya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhyeṣyamāṇa', 'label': 'aDyezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'adhvaryu', 'label': 'aDvaryu', 'attrib': [{'pos': 'm', 'defs': 'adhvaryu'}], 'isLemma': 1},
	{'value': 'anu', 'label': 'anu', 'attrib': [{'pos': 'preverb', 'defs': 'after'}], 'isLemma': 1},
	{'value': 'anugacchat', 'label': 'anugacCat', 'attrib': [{'pos': 'prap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugata', 'label': 'anugata', 'attrib': [{'pos': 'ppp', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugatavat', 'label': 'anugatavat', 'attrib': [{'pos': 'pap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugatya', 'label': 'anugatya', 'attrib': [{'pos': 'abs', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugantavya', 'label': 'anugantavya', 'attrib': [{'pos': 'ger', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugantum', 'label': 'anugantum', 'attrib': [{'pos': 'inf', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugamanīya', 'label': 'anugamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugamiṣyat', 'label': 'anugamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugamiṣyamāṇa', 'label': 'anugamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugamya', 'label': 'anugamya', 'attrib': [{'pos': 'abs', 'defs': 'go after, follow'}, {'pos': 'ger', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anugamyamāna', 'label': 'anugamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anujaganvas', 'label': 'anujaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anujagmāna', 'label': 'anujagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anujagmivas', 'label': 'anujagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'anujajñāna', 'label': 'anujajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujajñivas', 'label': 'anujajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujānat', 'label': 'anujAnat', 'attrib': [{'pos': 'prap', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñā', 'label': 'anujYA', 'attrib': [{'pos': 'f', 'defs': 'permission'}], 'isLemma': 1},
	{'value': 'anujñāta', 'label': 'anujYAta', 'attrib': [{'pos': 'ppp', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñātavat', 'label': 'anujYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñātavya', 'label': 'anujYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñātum', 'label': 'anujYAtum', 'attrib': [{'pos': 'inf', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñānīya', 'label': 'anujYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñāya', 'label': 'anujYAya', 'attrib': [{'pos': 'abs', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñāyamāna', 'label': 'anujYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñāsyat', 'label': 'anujYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñāsyamāna', 'label': 'anujYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anujñeya', 'label': 'anujYeya', 'attrib': [{'pos': 'ger', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'anu_ā_gam', 'label': 'anu_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come after, follow'}], 'isLemma': 1},
	{'value': 'anu_iṣ', 'label': 'anu_iz', 'attrib': [{'pos': 'vt6a', 'defs': 'seek after, search for'}], 'isLemma': 1},
	{'value': 'anu_gam', 'label': 'anu_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go after, follow'}], 'isLemma': 1},
	{'value': 'anu_jñā', 'label': 'anu_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'permit, assent'}], 'isLemma': 1},
	{'value': 'anu_pat', 'label': 'anu_pat', 'attrib': [{'pos': 'vt1a', 'defs': 'fly after, chase'}], 'isLemma': 1},
	{'value': 'anupatat', 'label': 'anupatat', 'attrib': [{'pos': 'prap', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatanīya', 'label': 'anupatanIya', 'attrib': [{'pos': 'ger', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatita', 'label': 'anupatita', 'attrib': [{'pos': 'ppp', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatitavat', 'label': 'anupatitavat', 'attrib': [{'pos': 'pap', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatitavya', 'label': 'anupatitavya', 'attrib': [{'pos': 'ger', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatitum', 'label': 'anupatitum', 'attrib': [{'pos': 'inf', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatiṣyat', 'label': 'anupatizyat', 'attrib': [{'pos': 'fap', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatiṣyamāṇa', 'label': 'anupatizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatya', 'label': 'anupatya', 'attrib': [{'pos': 'abs', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupatyamāna', 'label': 'anupatyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupātya', 'label': 'anupAtya', 'attrib': [{'pos': 'ger', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupetāna', 'label': 'anupetAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anupetivas', 'label': 'anupetivas', 'attrib': [{'pos': 'pfap', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'anta', 'label': 'anta', 'attrib': [{'pos': 'm', 'defs': 'end, edge, border'}], 'isLemma': 1},
	{'value': 'anna', 'label': 'anna', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}, {'pos': 'n', 'defs': 'food'}], 'isLemma': 1},
	{'value': 'anna', 'label': 'anna', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}, {'pos': 'n', 'defs': 'food'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'annavat', 'label': 'annavat', 'attrib': [{'pos': 'pap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'anya', 'label': 'anya', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'other, else'}], 'isLemma': 1},
	{'value': 'anyatas', 'label': 'anyatas', 'attrib': [{'pos': 'pron_adv', 'defs': 'from elsewhere'}], 'isLemma': 1},
	{'value': 'anyatra', 'label': 'anyatra', 'attrib': [{'pos': 'pron_adv', 'defs': 'elsewhere'}], 'isLemma': 1},
	{'value': 'anyathā', 'label': 'anyaTA', 'attrib': [{'pos': 'pron_adv', 'defs': 'otherwise'}], 'isLemma': 1},
	{'value': 'anyadā', 'label': 'anyadA', 'attrib': [{'pos': 'pron_adv', 'defs': 'at another time'}], 'isLemma': 1},
	{'value': 'anvāgacchat', 'label': 'anvAgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvāgata', 'label': 'anvAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvāgatavat', 'label': 'anvAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvāgatya', 'label': 'anvAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvāgantavya', 'label': 'anvAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvāgantum', 'label': 'anvAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvāgamanīya', 'label': 'anvAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvāgamiṣyat', 'label': 'anvAgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvāgamiṣyamāṇa', 'label': 'anvAgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvāgamya', 'label': 'anvAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come after, follow'}, {'pos': 'ger', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvāgamyamāna', 'label': 'anvAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvājaganvas', 'label': 'anvAjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvājagmāna', 'label': 'anvAjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvājagmivas', 'label': 'anvAjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'anvicchat', 'label': 'anvicCat', 'attrib': [{'pos': 'prap', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anviṣṭa', 'label': 'anvizwa', 'attrib': [{'pos': 'ppp', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anviṣṭavat', 'label': 'anvizwavat', 'attrib': [{'pos': 'pap', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anviṣya', 'label': 'anvizya', 'attrib': [{'pos': 'abs', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anviṣyamāṇa', 'label': 'anvizyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvīṣāṇa', 'label': 'anvIzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anvīṣivas', 'label': 'anvIzivas', 'attrib': [{'pos': 'pfap', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anveṣaṇīya', 'label': 'anvezaRIya', 'attrib': [{'pos': 'ger', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anveṣiṣyat', 'label': 'anvezizyat', 'attrib': [{'pos': 'fap', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anveṣiṣyamāṇa', 'label': 'anvezizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anveṣṭavya', 'label': 'anvezwavya', 'attrib': [{'pos': 'ger', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anveṣṭum', 'label': 'anvezwum', 'attrib': [{'pos': 'inf', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'anveṣya', 'label': 'anvezya', 'attrib': [{'pos': 'ger', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'apa', 'label': 'apa', 'attrib': [{'pos': 'preverb', 'defs': 'away'}], 'isLemma': 1},
	{'value': 'apakraṁsyat', 'label': 'apakraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakraṁsyamāna', 'label': 'apakraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakramaṇīya', 'label': 'apakramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakramitavya', 'label': 'apakramitavya', 'attrib': [{'pos': 'ger', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakramitum', 'label': 'apakramitum', 'attrib': [{'pos': 'inf', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakramya', 'label': 'apakramya', 'attrib': [{'pos': 'abs', 'defs': 'go away, retreat'}, {'pos': 'ger', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakramyamāṇa', 'label': 'apakramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakrānta', 'label': 'apakrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakrāntavat', 'label': 'apakrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakrāmat', 'label': 'apakrAmat', 'attrib': [{'pos': 'prap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apakrāmyat', 'label': 'apakrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apagacchat', 'label': 'apagacCat', 'attrib': [{'pos': 'prap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagata', 'label': 'apagata', 'attrib': [{'pos': 'ppp', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagatavat', 'label': 'apagatavat', 'attrib': [{'pos': 'pap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagatya', 'label': 'apagatya', 'attrib': [{'pos': 'abs', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagantavya', 'label': 'apagantavya', 'attrib': [{'pos': 'ger', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagantum', 'label': 'apagantum', 'attrib': [{'pos': 'inf', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagamanīya', 'label': 'apagamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagamiṣyat', 'label': 'apagamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagamiṣyamāṇa', 'label': 'apagamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagamya', 'label': 'apagamya', 'attrib': [{'pos': 'abs', 'defs': 'go away'}, {'pos': 'ger', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apagamyamāna', 'label': 'apagamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apacakramāṇa', 'label': 'apacakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apacakramvas', 'label': 'apacakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'apajaganvas', 'label': 'apajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apajagmāna', 'label': 'apajagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apajagmivas', 'label': 'apajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'apajahṛvas', 'label': 'apajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apajahrāṇa', 'label': 'apajahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apa_kram', 'label': 'apa_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'go away, retreat'}], 'isLemma': 1},
	{'value': 'apa_gam', 'label': 'apa_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go away'}], 'isLemma': 1},
	{'value': 'apa_hṛ', 'label': 'apa_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'take away'}], 'isLemma': 1},
	{'value': 'apaharaṇīya', 'label': 'apaharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apaharat', 'label': 'apaharat', 'attrib': [{'pos': 'prap', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apaharamāṇa', 'label': 'apaharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahariṣyat', 'label': 'apaharizyat', 'attrib': [{'pos': 'fap', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahariṣyamāṇa', 'label': 'apaharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahartavya', 'label': 'apahartavya', 'attrib': [{'pos': 'ger', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahartum', 'label': 'apahartum', 'attrib': [{'pos': 'inf', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahārya', 'label': 'apahArya', 'attrib': [{'pos': 'ger', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahṛta', 'label': 'apahfta', 'attrib': [{'pos': 'ppp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahṛtavat', 'label': 'apahftavat', 'attrib': [{'pos': 'pap', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahṛtya', 'label': 'apahftya', 'attrib': [{'pos': 'abs', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'apahriyamāṇa', 'label': 'apahriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'api', 'label': 'api', 'attrib': [{'pos': 'pcl', 'defs': 'even, also, too'}], 'isLemma': 1},
	{'value': 'apsaras', 'label': 'apsaras', 'attrib': [{'pos': 'f', 'defs': 'naiad'}], 'isLemma': 1},
	{'value': 'abhi', 'label': 'aBi', 'attrib': [{'pos': 'preverb', 'defs': 'toward'}], 'isLemma': 1},
	{'value': 'abhi_ā_gam', 'label': 'aBi_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come up to'}], 'isLemma': 1},
	{'value': 'abhi_vad', 'label': 'aBi_vad', 'attrib': [{'pos': 'vt1a', 'defs': 'speak to'}], 'isLemma': 1},
	{'value': 'abhivadat', 'label': 'aBivadat', 'attrib': [{'pos': 'prap', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'abhivadanīya', 'label': 'aBivadanIya', 'attrib': [{'pos': 'ger', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'abhivaditavya', 'label': 'aBivaditavya', 'attrib': [{'pos': 'ger', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'abhivaditum', 'label': 'aBivaditum', 'attrib': [{'pos': 'inf', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'abhivadiṣyat', 'label': 'aBivadizyat', 'attrib': [{'pos': 'fap', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'abhivadiṣyamāṇa', 'label': 'aBivadizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'abhivādya', 'label': 'aBivAdya', 'attrib': [{'pos': 'ger', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'abhyāgacchat', 'label': 'aByAgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyāgata', 'label': 'aByAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyāgatavat', 'label': 'aByAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyāgatya', 'label': 'aByAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyāgantavya', 'label': 'aByAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyāgantum', 'label': 'aByAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyāgamanīya', 'label': 'aByAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyāgamiṣyat', 'label': 'aByAgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyāgamiṣyamāṇa', 'label': 'aByAgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyāgamya', 'label': 'aByAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come up to'}, {'pos': 'ger', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyāgamyamāna', 'label': 'aByAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyājaganvas', 'label': 'aByAjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyājagmāna', 'label': 'aByAjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyājagmivas', 'label': 'aByAjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'abhyudita', 'label': 'aByudita', 'attrib': [{'pos': 'ppp', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'abhyuditavat', 'label': 'aByuditavat', 'attrib': [{'pos': 'pap', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'abhyudya', 'label': 'aByudya', 'attrib': [{'pos': 'abs', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'abhyudyamāna', 'label': 'aByudyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'abhyūdāna', 'label': 'aByUdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'abhyūdivas', 'label': 'aByUdivas', 'attrib': [{'pos': 'pfap', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'ayanīya', 'label': 'ayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'araṇi', 'label': 'araRi', 'attrib': [{'pos': 'f', 'defs': 'kindling stick'}], 'isLemma': 1},
	{'value': 'ari', 'label': 'ari', 'attrib': [{'pos': 'm', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'arjuna', 'label': 'arjuna', 'attrib': [{'pos': 'm_pn', 'defs': 'Arjuna'}], 'isLemma': 1},
	{'value': 'artha', 'label': 'arTa', 'attrib': [{'pos': 'm', 'defs': 'object, meaning, purpose'}], 'isLemma': 1},
	{'value': 'arh', 'label': 'arh', 'attrib': [{'pos': 'vt1a', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 1},
	{'value': 'arhaṇīya', 'label': 'arhaRIya', 'attrib': [{'pos': 'ger', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhat', 'label': 'arhat', 'attrib': [{'pos': 'prap', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhita', 'label': 'arhita', 'attrib': [{'pos': 'ppp', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhitavat', 'label': 'arhitavat', 'attrib': [{'pos': 'pap', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhitavya', 'label': 'arhitavya', 'attrib': [{'pos': 'ger', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhitum', 'label': 'arhitum', 'attrib': [{'pos': 'inf', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhitvā', 'label': 'arhitvA', 'attrib': [{'pos': 'abs', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhiṣyat', 'label': 'arhizyat', 'attrib': [{'pos': 'fap', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhiṣyamāṇa', 'label': 'arhizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'arhya', 'label': 'arhya', 'attrib': [{'pos': 'ger', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'alpa', 'label': 'alpa', 'attrib': [{'pos': 'adj', 'defs': 'small'}], 'isLemma': 1},
	{'value': 'ava', 'label': 'ava', 'attrib': [{'pos': 'preverb', 'defs': 'down'}], 'isLemma': 1},
	{'value': 'avagacchat', 'label': 'avagacCat', 'attrib': [{'pos': 'prap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagata', 'label': 'avagata', 'attrib': [{'pos': 'ppp', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagatavat', 'label': 'avagatavat', 'attrib': [{'pos': 'pap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagatya', 'label': 'avagatya', 'attrib': [{'pos': 'abs', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagantavya', 'label': 'avagantavya', 'attrib': [{'pos': 'ger', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagantum', 'label': 'avagantum', 'attrib': [{'pos': 'inf', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagamanīya', 'label': 'avagamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagamiṣyat', 'label': 'avagamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagamiṣyamāṇa', 'label': 'avagamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagamya', 'label': 'avagamya', 'attrib': [{'pos': 'abs', 'defs': 'go down, descend, understand'}, {'pos': 'ger', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avagamyamāna', 'label': 'avagamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avajaganvas', 'label': 'avajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avajagmāna', 'label': 'avajagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avajagmivas', 'label': 'avajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'avajahṛvas', 'label': 'avajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avajahrāṇa', 'label': 'avajahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'ava_āp', 'label': 'ava_Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'reach, attain, obtain'}], 'isLemma': 1},
	{'value': 'ava_gam', 'label': 'ava_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go down, descend, understand'}], 'isLemma': 1},
	{'value': 'ava_sthā', 'label': 'ava_sTA', 'attrib': [{'pos': 'vi1m', 'defs': 'remain'}], 'isLemma': 1},
	{'value': 'ava_hṛ', 'label': 'ava_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'take down'}], 'isLemma': 1},
	{'value': 'avatasthāna', 'label': 'avatasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avatiṣṭhamāna', 'label': 'avatizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasthātavya', 'label': 'avasTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasthātum', 'label': 'avasTAtum', 'attrib': [{'pos': 'inf', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasthānīya', 'label': 'avasTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasthāya', 'label': 'avasTAya', 'attrib': [{'pos': 'abs', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasthāsyamāna', 'label': 'avasTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasthita', 'label': 'avasTita', 'attrib': [{'pos': 'ppp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasthitavat', 'label': 'avasTitavat', 'attrib': [{'pos': 'pap', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avasthīyamāna', 'label': 'avasTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avastheya', 'label': 'avasTeya', 'attrib': [{'pos': 'ger', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'avaharaṇīya', 'label': 'avaharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avaharat', 'label': 'avaharat', 'attrib': [{'pos': 'prap', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avaharamāṇa', 'label': 'avaharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahariṣyat', 'label': 'avaharizyat', 'attrib': [{'pos': 'fap', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahariṣyamāṇa', 'label': 'avaharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahartavya', 'label': 'avahartavya', 'attrib': [{'pos': 'ger', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahartum', 'label': 'avahartum', 'attrib': [{'pos': 'inf', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahārya', 'label': 'avahArya', 'attrib': [{'pos': 'ger', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahṛta', 'label': 'avahfta', 'attrib': [{'pos': 'ppp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahṛtavat', 'label': 'avahftavat', 'attrib': [{'pos': 'pap', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahṛtya', 'label': 'avahftya', 'attrib': [{'pos': 'abs', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avahriyamāṇa', 'label': 'avahriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'avāpanīya', 'label': 'avApanIya', 'attrib': [{'pos': 'ger', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avāpāna', 'label': 'avApAna', 'attrib': [{'pos': 'pfpp', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avāpivas', 'label': 'avApivas', 'attrib': [{'pos': 'pfap', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avāpta', 'label': 'avApta', 'attrib': [{'pos': 'ppp', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avāptavat', 'label': 'avAptavat', 'attrib': [{'pos': 'pap', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avāptavya', 'label': 'avAptavya', 'attrib': [{'pos': 'ger', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avāptum', 'label': 'avAptum', 'attrib': [{'pos': 'inf', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avāpnuvat', 'label': 'avApnuvat', 'attrib': [{'pos': 'prap', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avāpya', 'label': 'avApya', 'attrib': [{'pos': 'abs', 'defs': 'reach, attain, obtain'}, {'pos': 'ger', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avāpyamāna', 'label': 'avApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avāpsyat', 'label': 'avApsyat', 'attrib': [{'pos': 'fap', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avāpsyamāna', 'label': 'avApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'avi', 'label': 'avi', 'attrib': [{'pos': 'm, f', 'defs': 'ram/ewe'}], 'isLemma': 1},
	{'value': 'aśva', 'label': 'aSva', 'attrib': [{'pos': 'm', 'defs': 'horse'}], 'isLemma': 1},
	{'value': 'aṣṭan', 'label': 'azwan', 'attrib': [{'pos': 'card_num', 'defs': 'eight'}], 'isLemma': 1},
	{'value': 'aṣṭama', 'label': 'azwama', 'attrib': [{'pos': 'ord_num', 'defs': 'eighth'}], 'isLemma': 1},
	{'value': 'as', 'label': 'as', 'attrib': [{'pos': 'vi2a', 'defs': 'be'}], 'isLemma': 1},
	{'value': 'asi', 'label': 'asi', 'attrib': [{'pos': 'm', 'defs': 'sword'}], 'isLemma': 1},
	{'value': 'asura', 'label': 'asura', 'attrib': [{'pos': 'm', 'defs': 'spirit, demon'}], 'isLemma': 1},
	{'value': 'asta', 'label': 'asta', 'attrib': [{'pos': 'n', 'defs': 'home'}], 'isLemma': 1},
	{'value': 'asmad', 'label': 'asmad', 'attrib': [{'pos': 'pers_pron', 'defs': 'I/we'}], 'isLemma': 1},
	{'value': 'ah', 'label': 'ah', 'attrib': [{'pos': 'vta', 'defs': 'say'}], 'isLemma': 1},
	{'value': 'ahan', 'label': 'ahan', 'attrib': [{'pos': 'n', 'defs': 'day'}], 'isLemma': 1},
	{'value': 'ā', 'label': 'A', 'attrib': [{'pos': 'preverb', 'defs': 'unto'}], 'isLemma': 1},
	{'value': 'ākraṁsyat', 'label': 'AkraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ākraṁsyamāna', 'label': 'AkraMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ākramaṇīya', 'label': 'AkramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ākramamāṇa', 'label': 'AkramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ākramitavya', 'label': 'Akramitavya', 'attrib': [{'pos': 'ger', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ākramitum', 'label': 'Akramitum', 'attrib': [{'pos': 'inf', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ākramya', 'label': 'Akramya', 'attrib': [{'pos': 'abs', 'defs': 'mount, attack; rise'}, {'pos': 'ger', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ākramyamāṇa', 'label': 'AkramyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'mount, attack; rise'}, {'pos': 'prpp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ākrānta', 'label': 'AkrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ākrāntavat', 'label': 'AkrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ākrāntum', 'label': 'AkrAntum', 'attrib': [{'pos': 'inf', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ākrāmat', 'label': 'AkrAmat', 'attrib': [{'pos': 'prap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ākrāmyat', 'label': 'AkrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'āgacchat', 'label': 'AgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'āgata', 'label': 'Agata', 'attrib': [{'pos': 'ppp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'āgatavat', 'label': 'Agatavat', 'attrib': [{'pos': 'pap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'āgatya', 'label': 'Agatya', 'attrib': [{'pos': 'abs', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'āgantavya', 'label': 'Agantavya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'āgantum', 'label': 'Agantum', 'attrib': [{'pos': 'inf', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'āgamanīya', 'label': 'AgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'āgamiṣyat', 'label': 'Agamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'āgamiṣyamāṇa', 'label': 'AgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'āgamya', 'label': 'Agamya', 'attrib': [{'pos': 'abs', 'defs': 'come'}, {'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'āgamyamāna', 'label': 'AgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'ācakramāṇa', 'label': 'AcakramARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ācakramvas', 'label': 'Acakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'ācārya', 'label': 'AcArya', 'attrib': [{'pos': 'm', 'defs': 'teacher'}], 'isLemma': 1},
	{'value': 'ājaganvas', 'label': 'Ajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'ājagmāna', 'label': 'AjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'ājagmivas', 'label': 'Ajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'ājajñāna', 'label': 'AjajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ājajñivas', 'label': 'AjajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ājahṛvas', 'label': 'Ajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'ājahrāṇa', 'label': 'AjahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'ājānat', 'label': 'AjAnat', 'attrib': [{'pos': 'prap', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ājuhuvāna', 'label': 'AjuhuvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'ājuhūvas', 'label': 'AjuhUvas', 'attrib': [{'pos': 'pfap', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'ājñā', 'label': 'AjYA', 'attrib': [{'pos': 'f', 'defs': 'command'}], 'isLemma': 1},
	{'value': 'ājñāta', 'label': 'AjYAta', 'attrib': [{'pos': 'ppp', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ājñātavat', 'label': 'AjYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ājñātavya', 'label': 'AjYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ājñātum', 'label': 'AjYAtum', 'attrib': [{'pos': 'inf', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ājñānīya', 'label': 'AjYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ājñāya', 'label': 'AjYAya', 'attrib': [{'pos': 'abs', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ājñāyamāna', 'label': 'AjYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ājñāsyat', 'label': 'AjYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ājñāsyamāna', 'label': 'AjYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ājñeya', 'label': 'AjYeya', 'attrib': [{'pos': 'ger', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'ā_i', 'label': 'A_i', 'attrib': [{'pos': 'vt2a', 'defs': 'come'}], 'isLemma': 1},
	{'value': 'ā_kram', 'label': 'A_kram', 'attrib': [{'pos': 'vt1am', 'defs': 'mount, attack; rise'}], 'isLemma': 1},
	{'value': 'ā_gam', 'label': 'A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come'}], 'isLemma': 1},
	{'value': 'ā_jñā', 'label': 'A_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'notice; caus.: command'}], 'isLemma': 1},
	{'value': 'ā_dā', 'label': 'A_dA', 'attrib': [{'pos': 'vt3am', 'defs': 'receive, take'}], 'isLemma': 1},
	{'value': 'ā_nī', 'label': 'A_nI', 'attrib': [{'pos': 'vt1am', 'defs': 'lead to, bring'}], 'isLemma': 1},
	{'value': 'ā_pad', 'label': 'A_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'approach'}], 'isLemma': 1},
	{'value': 'ā_sthā', 'label': 'A_sTA', 'attrib': [{'pos': 'vt1a', 'defs': 'resort to'}], 'isLemma': 1},
	{'value': 'ā_hṛ', 'label': 'A_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'bring, fetch'}], 'isLemma': 1},
	{'value': 'ā_hve', 'label': 'A_hve', 'attrib': [{'pos': 'vt1am', 'defs': 'call, summon'}], 'isLemma': 1},
	{'value': 'ātasthāna', 'label': 'AtasTAna', 'attrib': [{'pos': 'pfpp', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'ātasthivas', 'label': 'AtasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'āti', 'label': 'Ati', 'attrib': [{'pos': 'f', 'defs': 'duck'}], 'isLemma': 1},
	{'value': 'ātiṣṭhat', 'label': 'AtizWat', 'attrib': [{'pos': 'prap', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'ātta', 'label': 'Atta', 'attrib': [{'pos': 'ppp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādattavat', 'label': 'Adattavat', 'attrib': [{'pos': 'pap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādadat', 'label': 'Adadat', 'attrib': [{'pos': 'prap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādadāna', 'label': 'AdadAna', 'attrib': [{'pos': 'prmp', 'defs': 'receive, take'}, {'pos': 'pfmp, pfpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādadivas', 'label': 'Adadivas', 'attrib': [{'pos': 'pfap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādātavya', 'label': 'AdAtavya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādātum', 'label': 'AdAtum', 'attrib': [{'pos': 'inf', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādāna', 'label': 'AdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'ādānīya', 'label': 'AdAnIya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādāya', 'label': 'AdAya', 'attrib': [{'pos': 'abs', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādāsyat', 'label': 'AdAsyat', 'attrib': [{'pos': 'fap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādāsyamāna', 'label': 'AdAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādivas', 'label': 'Adivas', 'attrib': [{'pos': 'pfap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'ādīyamāna', 'label': 'AdIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādeya', 'label': 'Adeya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'ādya', 'label': 'Adya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'ānayat', 'label': 'Anayat', 'attrib': [{'pos': 'prap', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'ānayanīya', 'label': 'AnayanIya', 'attrib': [{'pos': 'ger', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'ānayamāna', 'label': 'AnayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'ānarhāṇa', 'label': 'AnarhARa', 'attrib': [{'pos': 'pfpp', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'ānarhivas', 'label': 'Anarhivas', 'attrib': [{'pos': 'pfap', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'āninīvas', 'label': 'AninIvas', 'attrib': [{'pos': 'pfap', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'āninyāna', 'label': 'AninyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'ānīta', 'label': 'AnIta', 'attrib': [{'pos': 'ppp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'ānītavat', 'label': 'AnItavat', 'attrib': [{'pos': 'pap', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'ānīya', 'label': 'AnIya', 'attrib': [{'pos': 'abs', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'ānīyamāna', 'label': 'AnIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'ānetavya', 'label': 'Anetavya', 'attrib': [{'pos': 'ger', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'ānetum', 'label': 'Anetum', 'attrib': [{'pos': 'inf', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'āneya', 'label': 'Aneya', 'attrib': [{'pos': 'ger', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'āneṣyat', 'label': 'Anezyat', 'attrib': [{'pos': 'fap', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'āneṣyamāṇa', 'label': 'AnezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'āp', 'label': 'Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'attain'}], 'isLemma': 1},
	{'value': 'āpaṇa', 'label': 'ApaRa', 'attrib': [{'pos': 'm', 'defs': 'market'}], 'isLemma': 1},
	{'value': 'āpattavya', 'label': 'Apattavya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'āpattum', 'label': 'Apattum', 'attrib': [{'pos': 'inf', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'āpatsyamāna', 'label': 'ApatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'āpadanīya', 'label': 'ApadanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'āpadya', 'label': 'Apadya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'āpadyamāna', 'label': 'ApadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'approach'}, {'pos': 'prpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'āpanīya', 'label': 'ApanIya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpanna', 'label': 'Apanna', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'āpannavat', 'label': 'Apannavat', 'attrib': [{'pos': 'pap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'āpādya', 'label': 'ApAdya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'āpāna', 'label': 'ApAna', 'attrib': [{'pos': 'pfpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpivas', 'label': 'Apivas', 'attrib': [{'pos': 'pfap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpedāna', 'label': 'ApedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'āpta', 'label': 'Apta', 'attrib': [{'pos': 'ppp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āptavat', 'label': 'Aptavat', 'attrib': [{'pos': 'pap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āptavya', 'label': 'Aptavya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āptum', 'label': 'Aptum', 'attrib': [{'pos': 'inf', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āptvā', 'label': 'AptvA', 'attrib': [{'pos': 'abs', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpnuvat', 'label': 'Apnuvat', 'attrib': [{'pos': 'prap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpya', 'label': 'Apya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpyamāna', 'label': 'ApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpsyat', 'label': 'Apsyat', 'attrib': [{'pos': 'fap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āpsyamāna', 'label': 'ApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'āyat', 'label': 'Ayat', 'attrib': [{'pos': 'prap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'āyanīya', 'label': 'AyanIya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'āyus', 'label': 'Ayus', 'attrib': [{'pos': 'n', 'defs': 'life'}], 'isLemma': 1},
	{'value': 'āvirbabhūvas', 'label': 'AvirbaBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'āvirbhavat', 'label': 'AvirBavat', 'attrib': [{'pos': 'prap', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'āvirbhavanīya', 'label': 'AvirBavanIya', 'attrib': [{'pos': 'ger', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'āvirbhavitavya', 'label': 'AvirBavitavya', 'attrib': [{'pos': 'ger', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'āvirbhavitum', 'label': 'AvirBavitum', 'attrib': [{'pos': 'inf', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'āvirbhaviṣyat', 'label': 'AvirBavizyat', 'attrib': [{'pos': 'fap', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'āvirbhaviṣyamāṇa', 'label': 'AvirBavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'āvirbhavya', 'label': 'AvirBavya', 'attrib': [{'pos': 'ger', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'āvirbhūta', 'label': 'AvirBUta', 'attrib': [{'pos': 'ppp', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'āvirbhūtavat', 'label': 'AvirBUtavat', 'attrib': [{'pos': 'pap', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'āvirbhūya', 'label': 'AvirBUya', 'attrib': [{'pos': 'abs', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'āvirbhūyamāna', 'label': 'AvirBUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'āvis', 'label': 'Avis', 'attrib': [{'pos': 'preverb', 'defs': 'clear, manifest'}], 'isLemma': 1},
	{'value': 'āvis_bhū', 'label': 'Avis_BU', 'attrib': [{'pos': 'vi1a', 'defs': 'become manifest, appear'}], 'isLemma': 1},
	{'value': 'āśrama', 'label': 'ASrama', 'attrib': [{'pos': 'm, n', 'defs': 'hermitage'}], 'isLemma': 1},
	{'value': 'ās', 'label': 'As', 'attrib': [{'pos': 'vi2m', 'defs': 'sit'}], 'isLemma': 1},
	{'value': 'āsanīya', 'label': 'AsanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'āsām', 'label': 'AsAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'āsita', 'label': 'Asita', 'attrib': [{'pos': 'ppp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'āsitavat', 'label': 'Asitavat', 'attrib': [{'pos': 'pap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'āsitum', 'label': 'Asitum', 'attrib': [{'pos': 'inf', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'āsitvā', 'label': 'AsitvA', 'attrib': [{'pos': 'abs', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'āsiṣyamāṇa', 'label': 'AsizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'āsīna', 'label': 'AsIna', 'attrib': [{'pos': 'prmp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'āstavya', 'label': 'Astavya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'āsthātavya', 'label': 'AsTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'āsthātum', 'label': 'AsTAtum', 'attrib': [{'pos': 'inf', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'āsthānīya', 'label': 'AsTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'āsthāya', 'label': 'AsTAya', 'attrib': [{'pos': 'abs', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'āsthāsyat', 'label': 'AsTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'āsthāsyamāna', 'label': 'AsTAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'āsthita', 'label': 'AsTita', 'attrib': [{'pos': 'ppp', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'āsthitavat', 'label': 'AsTitavat', 'attrib': [{'pos': 'pap', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'āsthīyamāna', 'label': 'AsTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'āstheya', 'label': 'AsTeya', 'attrib': [{'pos': 'ger', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'āsya', 'label': 'Asya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'āsyamāna', 'label': 'AsyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'āharaṇīya', 'label': 'AharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'āharat', 'label': 'Aharat', 'attrib': [{'pos': 'prap', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'āharamāṇa', 'label': 'AharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'āhariṣyat', 'label': 'Aharizyat', 'attrib': [{'pos': 'fap', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'āhariṣyamāṇa', 'label': 'AharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'āhartavya', 'label': 'Ahartavya', 'attrib': [{'pos': 'ger', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'āhartum', 'label': 'Ahartum', 'attrib': [{'pos': 'inf', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'āhārya', 'label': 'AhArya', 'attrib': [{'pos': 'ger', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'āhūta', 'label': 'AhUta', 'attrib': [{'pos': 'ppp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'āhūtavat', 'label': 'AhUtavat', 'attrib': [{'pos': 'pap', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'āhūya', 'label': 'AhUya', 'attrib': [{'pos': 'abs', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'āhūyamāna', 'label': 'AhUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'āhṛta', 'label': 'Ahfta', 'attrib': [{'pos': 'ppp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'āhṛtavat', 'label': 'Ahftavat', 'attrib': [{'pos': 'pap', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'āhṛtya', 'label': 'Ahftya', 'attrib': [{'pos': 'abs', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'āhriyamāṇa', 'label': 'AhriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'āhvayat', 'label': 'Ahvayat', 'attrib': [{'pos': 'prap', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'āhvayamāna', 'label': 'AhvayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'āhvātavya', 'label': 'AhvAtavya', 'attrib': [{'pos': 'ger', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'āhvātum', 'label': 'AhvAtum', 'attrib': [{'pos': 'inf', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'āhvānīya', 'label': 'AhvAnIya', 'attrib': [{'pos': 'ger', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'āhvāya', 'label': 'AhvAya', 'attrib': [{'pos': 'ger', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'āhvāsyat', 'label': 'AhvAsyat', 'attrib': [{'pos': 'fap', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'āhvāsyamāna', 'label': 'AhvAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'i', 'label': 'i', 'attrib': [{'pos': 'vt2a', 'defs': 'go'}], 'isLemma': 1},
	{'value': 'icchat', 'label': 'icCat', 'attrib': [{'pos': 'prap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ijyamāna', 'label': 'ijyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'ita', 'label': 'ita', 'attrib': [{'pos': 'ppp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'itara', 'label': 'itara', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'other'}], 'isLemma': 1},
	{'value': 'itavat', 'label': 'itavat', 'attrib': [{'pos': 'pap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'itas', 'label': 'itas', 'attrib': [{'pos': 'dem_adv', 'defs': 'from this, hence'}], 'isLemma': 1},
	{'value': 'iti', 'label': 'iti', 'attrib': [{'pos': 'pcl', 'defs': 'thus, close quote'}], 'isLemma': 1},
	{'value': 'ittham', 'label': 'itTam', 'attrib': [{'pos': 'dem_adv', 'defs': 'in this way, thus'}], 'isLemma': 1},
	{'value': 'itya', 'label': 'itya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'itvā', 'label': 'itvA', 'attrib': [{'pos': 'abs', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'idam', 'label': 'idam', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'this'}], 'isLemma': 1},
	{'value': 'indra', 'label': 'indra', 'attrib': [{'pos': 'm', 'defs': 'Indra'}], 'isLemma': 1},
	{'value': 'iva', 'label': 'iva', 'attrib': [{'pos': 'pcl', 'defs': 'like, as'}], 'isLemma': 1},
	{'value': 'iṣ', 'label': 'iz', 'attrib': [{'pos': 'vt6a', 'defs': 'desire'}], 'isLemma': 1},
	{'value': 'iṣṭa', 'label': 'izwa', 'attrib': [{'pos': 'ppp', 'defs': 'desire'}, {'pos': 'ppp', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['iz', 'yaj']},
	{'value': 'iṣṭavat', 'label': 'izwavat', 'attrib': [{'pos': 'pap', 'defs': 'desire'}, {'pos': 'pap', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['iz', 'yaj']},
	{'value': 'iṣṭvā', 'label': 'izwvA', 'attrib': [{'pos': 'abs', 'defs': 'desire'}, {'pos': 'abs', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['iz', 'yaj']},
	{'value': 'iṣyamāṇa', 'label': 'izyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'iha', 'label': 'iha', 'attrib': [{'pos': 'dem_adv', 'defs': 'here'}], 'isLemma': 1},
	{'value': 'īkṣ', 'label': 'Ikz', 'attrib': [{'pos': 'vt1m', 'defs': 'see'}], 'isLemma': 1},
	{'value': 'īkṣaṇīya', 'label': 'IkzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'īkṣamāṇa', 'label': 'IkzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'īkṣām', 'label': 'IkzAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'īkṣita', 'label': 'Ikzita', 'attrib': [{'pos': 'ppp', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'īkṣitavat', 'label': 'Ikzitavat', 'attrib': [{'pos': 'pap', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'īkṣitavya', 'label': 'Ikzitavya', 'attrib': [{'pos': 'ger', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'īkṣitum', 'label': 'Ikzitum', 'attrib': [{'pos': 'inf', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'īkṣitvā', 'label': 'IkzitvA', 'attrib': [{'pos': 'abs', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'īkṣiṣyamāṇa', 'label': 'IkzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'īkṣya', 'label': 'Ikzya', 'attrib': [{'pos': 'ger', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'īkṣyamāṇa', 'label': 'IkzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'ījāna', 'label': 'IjAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'ījivas', 'label': 'Ijivas', 'attrib': [{'pos': 'pfap', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'īyamāna', 'label': 'IyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'īyāna', 'label': 'IyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'īyivas', 'label': 'Iyivas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'īṣāṇa', 'label': 'IzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'īṣivas', 'label': 'Izivas', 'attrib': [{'pos': 'pfap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ukta', 'label': 'ukta', 'attrib': [{'pos': 'ppp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'uktavat', 'label': 'uktavat', 'attrib': [{'pos': 'pap', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'uktvā', 'label': 'uktvA', 'attrib': [{'pos': 'abs', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'uccakramāṇa', 'label': 'uccakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'uccakramvas', 'label': 'uccakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'ucyamāna', 'label': 'ucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'ujjaganvas', 'label': 'ujjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'ujjagmāna', 'label': 'ujjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'ujjagmivas', 'label': 'ujjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'ujjahṛvas', 'label': 'ujjahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'ujjahrāṇa', 'label': 'ujjahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'utkraṁsyat', 'label': 'utkraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkraṁsyamāna', 'label': 'utkraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkramaṇīya', 'label': 'utkramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkramitavya', 'label': 'utkramitavya', 'attrib': [{'pos': 'ger', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkramitum', 'label': 'utkramitum', 'attrib': [{'pos': 'inf', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkramya', 'label': 'utkramya', 'attrib': [{'pos': 'abs', 'defs': 'go up, ascend, transgress'}, {'pos': 'ger', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkramyamāṇa', 'label': 'utkramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkrānta', 'label': 'utkrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkrāntavat', 'label': 'utkrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkrāmat', 'label': 'utkrAmat', 'attrib': [{'pos': 'prap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'utkrāmyat', 'label': 'utkrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'uttasthāna', 'label': 'uttasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttasthivas', 'label': 'uttasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttiṣṭhat', 'label': 'uttizWat', 'attrib': [{'pos': 'prap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttiṣṭhamāna', 'label': 'uttizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthātavya', 'label': 'utTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthātum', 'label': 'utTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthānīya', 'label': 'utTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthāya', 'label': 'utTAya', 'attrib': [{'pos': 'abs', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthāsyat', 'label': 'utTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthāsyamāna', 'label': 'utTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthita', 'label': 'utTita', 'attrib': [{'pos': 'ppp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utthitavat', 'label': 'utTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'uttheya', 'label': 'utTeya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'utpattavya', 'label': 'utpattavya', 'attrib': [{'pos': 'ger', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpattum', 'label': 'utpattum', 'attrib': [{'pos': 'inf', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpatsyamāna', 'label': 'utpatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpadanīya', 'label': 'utpadanIya', 'attrib': [{'pos': 'ger', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpadya', 'label': 'utpadya', 'attrib': [{'pos': 'abs', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpadyamāna', 'label': 'utpadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'step up, arise'}, {'pos': 'prpp', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpanna', 'label': 'utpanna', 'attrib': [{'pos': 'ppp', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpannavat', 'label': 'utpannavat', 'attrib': [{'pos': 'pap', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpādya', 'label': 'utpAdya', 'attrib': [{'pos': 'ger', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utpedāna', 'label': 'utpedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'utsthīyamāna', 'label': 'utsTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'ud', 'label': 'ud', 'attrib': [{'pos': 'preverb', 'defs': 'up'}], 'isLemma': 1},
	{'value': 'udaka', 'label': 'udaka', 'attrib': [{'pos': 'n', 'defs': 'water'}], 'isLemma': 1},
	{'value': 'udita', 'label': 'udita', 'attrib': [{'pos': 'ppp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'uditavat', 'label': 'uditavat', 'attrib': [{'pos': 'pap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'uditvā', 'label': 'uditvA', 'attrib': [{'pos': 'abs', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'udgacchat', 'label': 'udgacCat', 'attrib': [{'pos': 'prap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgata', 'label': 'udgata', 'attrib': [{'pos': 'ppp', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgatavat', 'label': 'udgatavat', 'attrib': [{'pos': 'pap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgatya', 'label': 'udgatya', 'attrib': [{'pos': 'abs', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgantavya', 'label': 'udgantavya', 'attrib': [{'pos': 'ger', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgantum', 'label': 'udgantum', 'attrib': [{'pos': 'inf', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgamanīya', 'label': 'udgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgamiṣyat', 'label': 'udgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgamiṣyamāṇa', 'label': 'udgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgamya', 'label': 'udgamya', 'attrib': [{'pos': 'abs', 'defs': 'go up, rise'}, {'pos': 'ger', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgamyamāna', 'label': 'udgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'udgātṛ', 'label': 'udgAtf', 'attrib': [{'pos': 'm', 'defs': 'udgātṛ'}], 'isLemma': 1},
	{'value': 'ud_kram', 'label': 'ud_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'go up, ascend, transgress'}], 'isLemma': 1},
	{'value': 'ud_gam', 'label': 'ud_gam', 'attrib': [{'pos': 'vi1a', 'defs': 'go up, rise'}], 'isLemma': 1},
	{'value': 'ud_pad', 'label': 'ud_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'step up, arise'}], 'isLemma': 1},
	{'value': 'ud_bandh', 'label': 'ud_banD', 'attrib': [{'pos': 'vt9am', 'defs': "bind up, hang one's self"}], 'isLemma': 1},
	{'value': 'ud_yam', 'label': 'ud_yam', 'attrib': [{'pos': 'vt1am', 'defs': 'lift up, undertake, strive'}], 'isLemma': 1},
	{'value': 'ud_sthā', 'label': 'ud_sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stand up, arise'}], 'isLemma': 1},
	{'value': 'ud_hṛ', 'label': 'ud_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'take up, raise, rescue'}], 'isLemma': 1},
	{'value': 'uddharaṇīya', 'label': 'udDaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'uddharat', 'label': 'udDarat', 'attrib': [{'pos': 'prap', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'uddharamāṇa', 'label': 'udDaramARa', 'attrib': [{'pos': 'prmp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'uddhariṣyat', 'label': 'udDarizyat', 'attrib': [{'pos': 'fap', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'uddhariṣyamāṇa', 'label': 'udDarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'uddhartavya', 'label': 'udDartavya', 'attrib': [{'pos': 'ger', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'uddhartum', 'label': 'udDartum', 'attrib': [{'pos': 'inf', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'uddhārya', 'label': 'udDArya', 'attrib': [{'pos': 'ger', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'uddhṛta', 'label': 'udDfta', 'attrib': [{'pos': 'ppp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'uddhṛtavat', 'label': 'udDftavat', 'attrib': [{'pos': 'pap', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'uddhṛtya', 'label': 'udDftya', 'attrib': [{'pos': 'abs', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'uddhriyamāṇa', 'label': 'udDriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'udbaddha', 'label': 'udbadDa', 'attrib': [{'pos': 'ppp', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbaddhavat', 'label': 'udbadDavat', 'attrib': [{'pos': 'pap', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbadhnat', 'label': 'udbaDnat', 'attrib': [{'pos': 'prap', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbadhya', 'label': 'udbaDya', 'attrib': [{'pos': 'abs', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbadhyamāna', 'label': 'udbaDyamAna', 'attrib': [{'pos': 'prpp', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbanddhavya', 'label': 'udbandDavya', 'attrib': [{'pos': 'ger', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbanddhum', 'label': 'udbandDum', 'attrib': [{'pos': 'inf', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbandhanīya', 'label': 'udbanDanIya', 'attrib': [{'pos': 'ger', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbandhya', 'label': 'udbanDya', 'attrib': [{'pos': 'ger', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbabandhāna', 'label': 'udbabanDAna', 'attrib': [{'pos': 'pfpp', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbabandhvas', 'label': 'udbabanDvas', 'attrib': [{'pos': 'pfap', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbhantsyat', 'label': 'udBantsyat', 'attrib': [{'pos': 'fap', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udbhantsyamāna', 'label': 'udBantsyamAna', 'attrib': [{'pos': 'fpp', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'udyaṁsyat', 'label': 'udyaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyaṁsyamāna', 'label': 'udyaMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyacchat', 'label': 'udyacCat', 'attrib': [{'pos': 'prap', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyacchamāna', 'label': 'udyacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyata', 'label': 'udyata', 'attrib': [{'pos': 'ppp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyatavat', 'label': 'udyatavat', 'attrib': [{'pos': 'pap', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyatya', 'label': 'udyatya', 'attrib': [{'pos': 'abs', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyantavya', 'label': 'udyantavya', 'attrib': [{'pos': 'ger', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyantum', 'label': 'udyantum', 'attrib': [{'pos': 'inf', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyamanīya', 'label': 'udyamanIya', 'attrib': [{'pos': 'ger', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyamāna', 'label': 'udyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'udyamya', 'label': 'udyamya', 'attrib': [{'pos': 'abs', 'defs': 'lift up, undertake, strive'}, {'pos': 'ger', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyamyamāna', 'label': 'udyamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyemāna', 'label': 'udyemAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'udyemivas', 'label': 'udyemivas', 'attrib': [{'pos': 'pfap', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'upa', 'label': 'upa', 'attrib': [{'pos': 'preverb', 'defs': 'near'}], 'isLemma': 1},
	{'value': 'upakraṁsyat', 'label': 'upakraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakraṁsyamāna', 'label': 'upakraMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakramaṇīya', 'label': 'upakramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakramamāṇa', 'label': 'upakramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakramitavya', 'label': 'upakramitavya', 'attrib': [{'pos': 'ger', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakramitum', 'label': 'upakramitum', 'attrib': [{'pos': 'inf', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakramya', 'label': 'upakramya', 'attrib': [{'pos': 'abs', 'defs': 'approach (active); begin (middle)'}, {'pos': 'ger', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakramyamāṇa', 'label': 'upakramyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'approach (active); begin (middle)'}, {'pos': 'prpp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakrānta', 'label': 'upakrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakrāntavat', 'label': 'upakrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakrāntum', 'label': 'upakrAntum', 'attrib': [{'pos': 'inf', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakrāmat', 'label': 'upakrAmat', 'attrib': [{'pos': 'prap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upakrāmyat', 'label': 'upakrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upagacchat', 'label': 'upagacCat', 'attrib': [{'pos': 'prap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagata', 'label': 'upagata', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagatavat', 'label': 'upagatavat', 'attrib': [{'pos': 'pap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagatya', 'label': 'upagatya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagantavya', 'label': 'upagantavya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagantum', 'label': 'upagantum', 'attrib': [{'pos': 'inf', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagamanīya', 'label': 'upagamanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagamiṣyat', 'label': 'upagamizyat', 'attrib': [{'pos': 'fap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagamiṣyamāṇa', 'label': 'upagamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagamya', 'label': 'upagamya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}, {'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upagamyamāna', 'label': 'upagamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upacakramāṇa', 'label': 'upacakramARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upacakramvas', 'label': 'upacakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'upajaganvas', 'label': 'upajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upajagmāna', 'label': 'upajagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upajagmivas', 'label': 'upajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'upajahṛvas', 'label': 'upajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upajahrāṇa', 'label': 'upajahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upa_i', 'label': 'upa_i', 'attrib': [{'pos': 'vt2a', 'defs': 'approach'}], 'isLemma': 1},
	{'value': 'upa_kram', 'label': 'upa_kram', 'attrib': [{'pos': 'vt1am', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 1},
	{'value': 'upa_gam', 'label': 'upa_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'approach'}], 'isLemma': 1},
	{'value': 'upa_pad', 'label': 'upa_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'approach, occur, be suitable'}], 'isLemma': 1},
	{'value': 'upa_bandh', 'label': 'upa_banD', 'attrib': [{'pos': 'vt9am', 'defs': 'bind near'}], 'isLemma': 1},
	{'value': 'upa_yam', 'label': 'upa_yam', 'attrib': [{'pos': 'vt1am', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 1},
	{'value': 'upa_viś', 'label': 'upa_viS', 'attrib': [{'pos': 'vi6a', 'defs': 'sit'}], 'isLemma': 1},
	{'value': 'upa_sthā', 'label': 'upa_sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stay near, be present'}], 'isLemma': 1},
	{'value': 'upa_hṛ', 'label': 'upa_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'bring near, offer'}], 'isLemma': 1},
	{'value': 'upatasthāna', 'label': 'upatasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upatasthivas', 'label': 'upatasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upatiṣṭhat', 'label': 'upatizWat', 'attrib': [{'pos': 'prap', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upatiṣṭhamāna', 'label': 'upatizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upapattavya', 'label': 'upapattavya', 'attrib': [{'pos': 'ger', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapattum', 'label': 'upapattum', 'attrib': [{'pos': 'inf', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapatsyamāna', 'label': 'upapatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapadanīya', 'label': 'upapadanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapadya', 'label': 'upapadya', 'attrib': [{'pos': 'abs', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapadyamāna', 'label': 'upapadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'approach, occur, be suitable'}, {'pos': 'prpp', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapanna', 'label': 'upapanna', 'attrib': [{'pos': 'ppp', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapannavat', 'label': 'upapannavat', 'attrib': [{'pos': 'pap', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapādya', 'label': 'upapAdya', 'attrib': [{'pos': 'ger', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upapedāna', 'label': 'upapedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'upabaddha', 'label': 'upabadDa', 'attrib': [{'pos': 'ppp', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabaddhavat', 'label': 'upabadDavat', 'attrib': [{'pos': 'pap', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabadhnat', 'label': 'upabaDnat', 'attrib': [{'pos': 'prap', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabadhya', 'label': 'upabaDya', 'attrib': [{'pos': 'abs', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabadhyamāna', 'label': 'upabaDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabanddhavya', 'label': 'upabandDavya', 'attrib': [{'pos': 'ger', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabanddhum', 'label': 'upabandDum', 'attrib': [{'pos': 'inf', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabandhanīya', 'label': 'upabanDanIya', 'attrib': [{'pos': 'ger', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabandhya', 'label': 'upabanDya', 'attrib': [{'pos': 'ger', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upababandhāna', 'label': 'upababanDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upababandhvas', 'label': 'upababanDvas', 'attrib': [{'pos': 'pfap', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabhantsyat', 'label': 'upaBantsyat', 'attrib': [{'pos': 'fap', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upabhantsyamāna', 'label': 'upaBantsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'upayaṁsyat', 'label': 'upayaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayaṁsyamāna', 'label': 'upayaMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayacchat', 'label': 'upayacCat', 'attrib': [{'pos': 'prap', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayacchamāna', 'label': 'upayacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayat', 'label': 'upayat', 'attrib': [{'pos': 'prap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upayata', 'label': 'upayata', 'attrib': [{'pos': 'ppp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayatavat', 'label': 'upayatavat', 'attrib': [{'pos': 'pap', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayatya', 'label': 'upayatya', 'attrib': [{'pos': 'abs', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayantavya', 'label': 'upayantavya', 'attrib': [{'pos': 'ger', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayantum', 'label': 'upayantum', 'attrib': [{'pos': 'inf', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayamanīya', 'label': 'upayamanIya', 'attrib': [{'pos': 'ger', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayamya', 'label': 'upayamya', 'attrib': [{'pos': 'abs', 'defs': 'hold near, take, offer (active), embrace (middle)'}, {'pos': 'ger', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayamyamāna', 'label': 'upayamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayemāna', 'label': 'upayemAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upayemivas', 'label': 'upayemivas', 'attrib': [{'pos': 'pfap', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'upaviviśāna', 'label': 'upaviviSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaviviśivas', 'label': 'upaviviSivas', 'attrib': [{'pos': 'pfap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaviviśvas', 'label': 'upaviviSvas', 'attrib': [{'pos': 'pfap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaviśat', 'label': 'upaviSat', 'attrib': [{'pos': 'prap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaviśya', 'label': 'upaviSya', 'attrib': [{'pos': 'abs', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaviśyamāna', 'label': 'upaviSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaviṣṭa', 'label': 'upavizwa', 'attrib': [{'pos': 'ppp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaviṣṭavat', 'label': 'upavizwavat', 'attrib': [{'pos': 'pap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upavekṣyat', 'label': 'upavekzyat', 'attrib': [{'pos': 'fap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upavekṣyamāṇa', 'label': 'upavekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaveśanīya', 'label': 'upaveSanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaveśya', 'label': 'upaveSya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaveṣṭavya', 'label': 'upavezwavya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upaveṣṭum', 'label': 'upavezwum', 'attrib': [{'pos': 'inf', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'upasthātavya', 'label': 'upasTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasthātum', 'label': 'upasTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasthānīya', 'label': 'upasTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasthāya', 'label': 'upasTAya', 'attrib': [{'pos': 'abs', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasthāsyat', 'label': 'upasTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasthāsyamāna', 'label': 'upasTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasthita', 'label': 'upasTita', 'attrib': [{'pos': 'ppp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasthitavat', 'label': 'upasTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upasthīyamāna', 'label': 'upasTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upastheya', 'label': 'upasTeya', 'attrib': [{'pos': 'ger', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'upaharaṇīya', 'label': 'upaharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upaharat', 'label': 'upaharat', 'attrib': [{'pos': 'prap', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upaharamāṇa', 'label': 'upaharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahariṣyat', 'label': 'upaharizyat', 'attrib': [{'pos': 'fap', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahariṣyamāṇa', 'label': 'upaharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahartavya', 'label': 'upahartavya', 'attrib': [{'pos': 'ger', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahartum', 'label': 'upahartum', 'attrib': [{'pos': 'inf', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahārya', 'label': 'upahArya', 'attrib': [{'pos': 'ger', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahṛta', 'label': 'upahfta', 'attrib': [{'pos': 'ppp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahṛtavat', 'label': 'upahftavat', 'attrib': [{'pos': 'pap', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahṛtya', 'label': 'upahftya', 'attrib': [{'pos': 'abs', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upahriyamāṇa', 'label': 'upahriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'upāyanīya', 'label': 'upAyanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeta', 'label': 'upeta', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upetavat', 'label': 'upetavat', 'attrib': [{'pos': 'pap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upetya', 'label': 'upetya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}, {'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeyamāna', 'label': 'upeyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeyāna', 'label': 'upeyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upeyivas', 'label': 'upeyivas', 'attrib': [{'pos': 'pfap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upaitavya', 'label': 'upEtavya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upaitum', 'label': 'upEtum', 'attrib': [{'pos': 'inf', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upaiṣyat', 'label': 'upEzyat', 'attrib': [{'pos': 'fap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'upaiṣyamāṇa', 'label': 'upEzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'ubha', 'label': 'uBa', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'both'}], 'isLemma': 1},
	{'value': 'uraṇa', 'label': 'uraRa', 'attrib': [{'pos': 'm', 'defs': 'lamb'}], 'isLemma': 1},
	{'value': 'urvaśī', 'label': 'urvaSI', 'attrib': [{'pos': 'f_pn', 'defs': 'Urvaśī'}], 'isLemma': 1},
	{'value': 'uṣas', 'label': 'uzas', 'attrib': [{'pos': 'f', 'defs': 'dawn'}], 'isLemma': 1},
	{'value': 'uṣita', 'label': 'uzita', 'attrib': [{'pos': 'ppp', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uṣitavat', 'label': 'uzitavat', 'attrib': [{'pos': 'pap', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uṣitvā', 'label': 'uzitvA', 'attrib': [{'pos': 'abs', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uṣṇa', 'label': 'uzRa', 'attrib': [{'pos': 'adj', 'defs': 'warm'}], 'isLemma': 1},
	{'value': 'uṣyamāṇa', 'label': 'uzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'uhyamāna', 'label': 'uhyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ūcāna', 'label': 'UcAna', 'attrib': [{'pos': 'pfpp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'ūcivas', 'label': 'Ucivas', 'attrib': [{'pos': 'pfap', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'ūḍha', 'label': 'UQa', 'attrib': [{'pos': 'ppp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ūḍhavat', 'label': 'UQavat', 'attrib': [{'pos': 'pap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ūḍhvā', 'label': 'UQvA', 'attrib': [{'pos': 'abs', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ūdāna', 'label': 'UdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'ūdivas', 'label': 'Udivas', 'attrib': [{'pos': 'pfap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'ūṣāṇa', 'label': 'UzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'ūṣivas', 'label': 'Uzivas', 'attrib': [{'pos': 'pfap', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'ūhāna', 'label': 'UhAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ūhivas', 'label': 'Uhivas', 'attrib': [{'pos': 'pfap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ṛgveda', 'label': 'fgveda', 'attrib': [{'pos': 'm_pn', 'defs': '<i>Ṛgveda</i>'}], 'isLemma': 1},
	{'value': 'ṛc', 'label': 'fc', 'attrib': [{'pos': 'f', 'defs': 'Ṛgvedic verse'}], 'isLemma': 1},
	{'value': 'ṛtvij', 'label': 'ftvij', 'attrib': [{'pos': 'm', 'defs': 'officiant in Vedic performance'}], 'isLemma': 1},
	{'value': 'ṛṣi', 'label': 'fzi', 'attrib': [{'pos': 'm', 'defs': 'Vedic seer'}], 'isLemma': 1},
	{'value': 'eka', 'label': 'eka', 'attrib': [{'pos': 'card_num', 'defs': 'one'}], 'isLemma': 1},
	{'value': 'eta', 'label': 'eta', 'attrib': [{'pos': 'ppp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'etad', 'label': 'etad', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'this'}], 'isLemma': 1},
	{'value': 'etarhi', 'label': 'etarhi', 'attrib': [{'pos': 'dem_adv', 'defs': 'for this reason'}], 'isLemma': 1},
	{'value': 'etavat', 'label': 'etavat', 'attrib': [{'pos': 'pap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'etavya', 'label': 'etavya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'etāvat', 'label': 'etAvat', 'attrib': [{'pos': 'dem_adj', 'defs': 'of this extent'}, {'pos': 'dem_adv', 'defs': 'to this extent'}], 'isLemma': 1},
	{'value': 'etum', 'label': 'etum', 'attrib': [{'pos': 'inf', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'etya', 'label': 'etya', 'attrib': [{'pos': 'abs', 'defs': 'come'}, {'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'enad', 'label': 'enad', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'this, it (2, 3s, 6d, 7d)'}], 'isLemma': 1},
	{'value': 'eyamāna', 'label': 'eyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'eyāna', 'label': 'eyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'eyivas', 'label': 'eyivas', 'attrib': [{'pos': 'pfap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'eva', 'label': 'eva', 'attrib': [{'pos': 'pcl', 'defs': 'only, just, very'}], 'isLemma': 1},
	{'value': 'evam', 'label': 'evam', 'attrib': [{'pos': 'dem_adv', 'defs': 'in this way, thus'}], 'isLemma': 1},
	{'value': 'eṣaṇīya', 'label': 'ezaRIya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'eṣiṣyat', 'label': 'ezizyat', 'attrib': [{'pos': 'fap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'eṣiṣyamāṇa', 'label': 'ezizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'eṣṭavya', 'label': 'ezwavya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'eṣṭum', 'label': 'ezwum', 'attrib': [{'pos': 'inf', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'eṣya', 'label': 'ezya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'eṣyat', 'label': 'ezyat', 'attrib': [{'pos': 'fap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'eṣyamāṇa', 'label': 'ezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'aitavya', 'label': 'Etavya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'aitum', 'label': 'Etum', 'attrib': [{'pos': 'inf', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'aiṣyat', 'label': 'Ezyat', 'attrib': [{'pos': 'fap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'aiṣyamāṇa', 'label': 'EzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'kath', 'label': 'kaT', 'attrib': [{'pos': 'vt10am', 'defs': 'tell, narrate'}], 'isLemma': 1},
	{'value': 'kathanīya', 'label': 'kaTanIya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'katham', 'label': 'kaTam', 'attrib': [{'pos': 'int_adv', 'defs': 'how'}], 'isLemma': 1},
	{'value': 'kathayat', 'label': 'kaTayat', 'attrib': [{'pos': 'prap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayamāna', 'label': 'kaTayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayām', 'label': 'kaTayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayitavya', 'label': 'kaTayitavya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayitum', 'label': 'kaTayitum', 'attrib': [{'pos': 'inf', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayitvā', 'label': 'kaTayitvA', 'attrib': [{'pos': 'abs', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayiṣyat', 'label': 'kaTayizyat', 'attrib': [{'pos': 'fap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathayiṣyamāṇa', 'label': 'kaTayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathā', 'label': 'kaTA', 'attrib': [{'pos': 'f', 'defs': 'story'}], 'isLemma': 1},
	{'value': 'kathita', 'label': 'kaTita', 'attrib': [{'pos': 'ppp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathitavat', 'label': 'kaTitavat', 'attrib': [{'pos': 'pap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathya', 'label': 'kaTya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kathyamāna', 'label': 'kaTyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'kadā', 'label': 'kadA', 'attrib': [{'pos': 'int_adv', 'defs': 'when'}], 'isLemma': 1},
	{'value': 'kanyā', 'label': 'kanyA', 'attrib': [{'pos': 'f', 'defs': 'girl'}], 'isLemma': 1},
	{'value': 'kam', 'label': 'kam', 'attrib': [{'pos': 'vt1m', 'defs': 'desire, love'}], 'isLemma': 1},
	{'value': 'kamanīya', 'label': 'kamanIya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kamitavya', 'label': 'kamitavya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kamitum', 'label': 'kamitum', 'attrib': [{'pos': 'inf', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kamitvā', 'label': 'kamitvA', 'attrib': [{'pos': 'abs', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kamiṣyamāṇa', 'label': 'kamizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'karaṇīya', 'label': 'karaRIya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kariṣyat', 'label': 'karizyat', 'attrib': [{'pos': 'fap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kariṣyamāṇa', 'label': 'karizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kartavya', 'label': 'kartavya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kartum', 'label': 'kartum', 'attrib': [{'pos': 'inf', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'karman', 'label': 'karman', 'attrib': [{'pos': 'n', 'defs': 'action, deed'}], 'isLemma': 1},
	{'value': 'kānta', 'label': 'kAnta', 'attrib': [{'pos': 'ppp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kāntavat', 'label': 'kAntavat', 'attrib': [{'pos': 'pap', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kāntvā', 'label': 'kAntvA', 'attrib': [{'pos': 'abs', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kāma', 'label': 'kAma', 'attrib': [{'pos': 'm', 'defs': 'love, the god of love'}], 'isLemma': 1},
	{'value': 'kāmanīya', 'label': 'kAmanIya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kāmayamāna', 'label': 'kAmayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kāmayām', 'label': 'kAmayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kāmayitavya', 'label': 'kAmayitavya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kāmayitum', 'label': 'kAmayitum', 'attrib': [{'pos': 'inf', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kāmayitvā', 'label': 'kAmayitvA', 'attrib': [{'pos': 'abs', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kāmayiṣyamāṇa', 'label': 'kAmayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kāmya', 'label': 'kAmya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kāmyamāna', 'label': 'kAmyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'kārya', 'label': 'kArya', 'attrib': [{'pos': 'n', 'defs': 'work'}, {'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 1},
	{'value': 'kārya', 'label': 'kArya', 'attrib': [{'pos': 'n', 'defs': 'work'}, {'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kāla', 'label': 'kAla', 'attrib': [{'pos': 'm', 'defs': 'time'}], 'isLemma': 1},
	{'value': 'kim', 'label': 'kim', 'attrib': [{'pos': 'int_pron, int_adj', 'defs': 'which/who/what'}, {'pos': 'pcl', 'defs': 'question marker'}], 'isLemma': 1},
	{'value': 'kiyat', 'label': 'kiyat', 'attrib': [{'pos': 'int_adj', 'defs': 'of what extent'}, {'pos': 'int_adv', 'defs': 'to what extent'}], 'isLemma': 1},
	{'value': 'kutas', 'label': 'kutas', 'attrib': [{'pos': 'int_adv', 'defs': 'whence, why'}], 'isLemma': 1},
	{'value': 'kutra', 'label': 'kutra', 'attrib': [{'pos': 'int_adv', 'defs': 'where'}], 'isLemma': 1},
	{'value': 'kumāra', 'label': 'kumAra', 'attrib': [{'pos': 'm', 'defs': 'boy, youth'}], 'isLemma': 1},
	{'value': 'kurukṣetra', 'label': 'kurukzetra', 'attrib': [{'pos': 'n_pn', 'defs': 'field of the Kuru s, Kurukṣetra'}], 'isLemma': 1},
	{'value': 'kurvat', 'label': 'kurvat', 'attrib': [{'pos': 'prap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kurvāṇa', 'label': 'kurvARa', 'attrib': [{'pos': 'prmp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kula', 'label': 'kula', 'attrib': [{'pos': 'n', 'defs': 'family'}], 'isLemma': 1},
	{'value': 'kuśala', 'label': 'kuSala', 'attrib': [{'pos': 'adj', 'defs': 'skillful'}, {'pos': 'n', 'defs': 'welfare'}], 'isLemma': 1},
	{'value': 'kṛ', 'label': 'kf', 'attrib': [{'pos': 'vt8am', 'defs': 'do, make'}], 'isLemma': 1},
	{'value': 'kṛta', 'label': 'kfta', 'attrib': [{'pos': 'ppp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kṛtavat', 'label': 'kftavat', 'attrib': [{'pos': 'pap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kṛtya', 'label': 'kftya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kṛtvā', 'label': 'kftvA', 'attrib': [{'pos': 'abs', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'kṛśa', 'label': 'kfSa', 'attrib': [{'pos': 'adj', 'defs': 'thin'}], 'isLemma': 1},
	{'value': 'kevala', 'label': 'kevala', 'attrib': [{'pos': 'adj', 'defs': 'alone, lone'}], 'isLemma': 1},
	{'value': 'kraṁsyat', 'label': 'kraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kraṁsyamāna', 'label': 'kraMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krantvā', 'label': 'krantvA', 'attrib': [{'pos': 'abs', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kram', 'label': 'kram', 'attrib': [{'pos': 'vt1am', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 1},
	{'value': 'kramaṇīya', 'label': 'kramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kramamāṇa', 'label': 'kramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kramitavya', 'label': 'kramitavya', 'attrib': [{'pos': 'ger', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kramitum', 'label': 'kramitum', 'attrib': [{'pos': 'inf', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kramitvā', 'label': 'kramitvA', 'attrib': [{'pos': 'abs', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kramya', 'label': 'kramya', 'attrib': [{'pos': 'ger', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kramyamāṇa', 'label': 'kramyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'step (active); continue, go, increase (middle)'}, {'pos': 'prpp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krayaṇīya', 'label': 'krayaRIya', 'attrib': [{'pos': 'ger', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krānta', 'label': 'krAnta', 'attrib': [{'pos': 'ppp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krāntavat', 'label': 'krAntavat', 'attrib': [{'pos': 'pap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krāntum', 'label': 'krAntum', 'attrib': [{'pos': 'inf', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krāntvā', 'label': 'krAntvA', 'attrib': [{'pos': 'abs', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krāmat', 'label': 'krAmat', 'attrib': [{'pos': 'prap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'krāmyat', 'label': 'krAmyat', 'attrib': [{'pos': 'prap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'kriyamāṇa', 'label': 'kriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'krī', 'label': 'krI', 'attrib': [{'pos': 'vt9am', 'defs': 'buy'}], 'isLemma': 1},
	{'value': 'krīṇat', 'label': 'krIRat', 'attrib': [{'pos': 'prap', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krīṇāna', 'label': 'krIRAna', 'attrib': [{'pos': 'prmp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krīta', 'label': 'krIta', 'attrib': [{'pos': 'ppp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krītavat', 'label': 'krItavat', 'attrib': [{'pos': 'pap', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krītvā', 'label': 'krItvA', 'attrib': [{'pos': 'abs', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krīyamāṇa', 'label': 'krIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'kruddha', 'label': 'krudDa', 'attrib': [{'pos': 'ppp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kruddhavat', 'label': 'krudDavat', 'attrib': [{'pos': 'pap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kruddhvā', 'label': 'krudDvA', 'attrib': [{'pos': 'abs', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krudh', 'label': 'kruD', 'attrib': [{'pos': 'vi4a', 'defs': 'be angry'}], 'isLemma': 1},
	{'value': 'krudhyat', 'label': 'kruDyat', 'attrib': [{'pos': 'prap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krudhyamāna', 'label': 'kruDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kretavya', 'label': 'kretavya', 'attrib': [{'pos': 'ger', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'kretum', 'label': 'kretum', 'attrib': [{'pos': 'inf', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'kreya', 'label': 'kreya', 'attrib': [{'pos': 'ger', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'kreṣyat', 'label': 'krezyat', 'attrib': [{'pos': 'fap', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'kreṣyamāṇa', 'label': 'krezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'krotsyat', 'label': 'krotsyat', 'attrib': [{'pos': 'fap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krotsyamāna', 'label': 'krotsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kroddhavya', 'label': 'krodDavya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kroddhum', 'label': 'krodDum', 'attrib': [{'pos': 'inf', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krodhanīya', 'label': 'kroDanIya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'krodhya', 'label': 'kroDya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'kva', 'label': 'kva', 'attrib': [{'pos': 'int_adv', 'defs': 'where'}], 'isLemma': 1},
	{'value': 'kṣaṇa', 'label': 'kzaRa', 'attrib': [{'pos': 'm', 'defs': 'moment'}], 'isLemma': 1},
	{'value': 'kṣatriya', 'label': 'kzatriya', 'attrib': [{'pos': 'm', 'defs': 'warrior'}], 'isLemma': 1},
	{'value': 'gacchat', 'label': 'gacCat', 'attrib': [{'pos': 'prap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gata', 'label': 'gata', 'attrib': [{'pos': 'ppp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gatavat', 'label': 'gatavat', 'attrib': [{'pos': 'pap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gatvā', 'label': 'gatvA', 'attrib': [{'pos': 'abs', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gantavya', 'label': 'gantavya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gantum', 'label': 'gantum', 'attrib': [{'pos': 'inf', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gandharva', 'label': 'ganDarva', 'attrib': [{'pos': 'm', 'defs': 'gandharva'}], 'isLemma': 1},
	{'value': 'gam', 'label': 'gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go'}], 'isLemma': 1},
	{'value': 'gamanīya', 'label': 'gamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamiṣyat', 'label': 'gamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamiṣyamāṇa', 'label': 'gamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamya', 'label': 'gamya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'gamyamāna', 'label': 'gamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'garitum', 'label': 'garitum', 'attrib': [{'pos': 'inf', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'gariṣyat', 'label': 'garizyat', 'attrib': [{'pos': 'fap', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'gariṣyamāṇa', 'label': 'garizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'garbha', 'label': 'garBa', 'attrib': [{'pos': 'm', 'defs': 'womb, embryo'}], 'isLemma': 1},
	{'value': 'garbhin', 'label': 'garBin', 'attrib': [{'pos': 'adj', 'defs': 'pregnant'}], 'isLemma': 1},
	{'value': 'gīrṇa', 'label': 'gIrRa', 'attrib': [{'pos': 'ppp', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'gīrṇavat', 'label': 'gIrRavat', 'attrib': [{'pos': 'pap', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'gīrtvā', 'label': 'gIrtvA', 'attrib': [{'pos': 'abs', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'gīryamāṇa', 'label': 'gIryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'guru', 'label': 'guru', 'attrib': [{'pos': 'adj', 'defs': 'heavy, important'}, {'pos': 'm', 'defs': 'teacher'}], 'isLemma': 1},
	{'value': 'gṛṇat', 'label': 'gfRat', 'attrib': [{'pos': 'prap', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'gṛha', 'label': 'gfha', 'attrib': [{'pos': 'n, m', 'defs': 'house'}], 'isLemma': 1},
	{'value': 'gṛhāṇa', 'label': 'gfhARa', 'attrib': [{'pos': 'prmp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'gṛhīta', 'label': 'gfhIta', 'attrib': [{'pos': 'ppp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'gṛhītavat', 'label': 'gfhItavat', 'attrib': [{'pos': 'pap', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'gṛhītvā', 'label': 'gfhItvA', 'attrib': [{'pos': 'abs', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'gṛhṇat', 'label': 'gfhRat', 'attrib': [{'pos': 'prap', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'gṛhyamāṇa', 'label': 'gfhyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'gṝ', 'label': 'gF', 'attrib': [{'pos': 'vt9a', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 1},
	{'value': 'grah', 'label': 'grah', 'attrib': [{'pos': 'vt9am', 'defs': 'grab, grasp, seize'}], 'isLemma': 1},
	{'value': 'grahaṇīya', 'label': 'grahaRIya', 'attrib': [{'pos': 'ger', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'grahītavya', 'label': 'grahItavya', 'attrib': [{'pos': 'ger', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'grahītum', 'label': 'grahItum', 'attrib': [{'pos': 'inf', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'grahīṣyat', 'label': 'grahIzyat', 'attrib': [{'pos': 'fap', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'grahīṣyamāṇa', 'label': 'grahIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'grāma', 'label': 'grAma', 'attrib': [{'pos': 'm', 'defs': 'village'}], 'isLemma': 1},
	{'value': 'grāhya', 'label': 'grAhya', 'attrib': [{'pos': 'ger', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'ghaṭa', 'label': 'Gawa', 'attrib': [{'pos': 'm', 'defs': 'pot'}], 'isLemma': 1},
	{'value': 'ghātya', 'label': 'GAtya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'ghora', 'label': 'Gora', 'attrib': [{'pos': 'adj', 'defs': 'horrible'}], 'isLemma': 1},
	{'value': 'ghnat', 'label': 'Gnat', 'attrib': [{'pos': 'prap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'ca', 'label': 'ca', 'attrib': [{'pos': 'conj_pcl', 'defs': 'and'}], 'isLemma': 1},
	{'value': 'cakamāna', 'label': 'cakamAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'cakṛvas', 'label': 'cakfvas', 'attrib': [{'pos': 'pfap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'cakramāṇa', 'label': 'cakramARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'cakramvas', 'label': 'cakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'cakrāṇa', 'label': 'cakrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'catur', 'label': 'catur', 'attrib': [{'pos': 'card_num', 'defs': 'four'}], 'isLemma': 1},
	{'value': 'caturtha', 'label': 'caturTa', 'attrib': [{'pos': 'ord_num', 'defs': 'fourth'}], 'isLemma': 1},
	{'value': 'candra', 'label': 'candra', 'attrib': [{'pos': 'm', 'defs': 'moon'}, {'pos': 'm_pn', 'defs': 'Hari Candra'}], 'isLemma': 1},
	{'value': 'car', 'label': 'car', 'attrib': [{'pos': 'vt1am', 'defs': 'move, do'}], 'isLemma': 1},
	{'value': 'caraṇīya', 'label': 'caraRIya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carat', 'label': 'carat', 'attrib': [{'pos': 'prap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caramāṇa', 'label': 'caramARa', 'attrib': [{'pos': 'prmp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carita', 'label': 'carita', 'attrib': [{'pos': 'ppp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritavat', 'label': 'caritavat', 'attrib': [{'pos': 'pap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritavya', 'label': 'caritavya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritum', 'label': 'caritum', 'attrib': [{'pos': 'inf', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caritvā', 'label': 'caritvA', 'attrib': [{'pos': 'abs', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'cariṣyat', 'label': 'carizyat', 'attrib': [{'pos': 'fap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'cariṣyamāṇa', 'label': 'carizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'carya', 'label': 'carya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'caryamāṇa', 'label': 'caryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'cārin', 'label': 'cArin', 'attrib': [{'pos': 'adj', 'defs': 'doing, practicing'}], 'isLemma': 1},
	{'value': 'cikriyāṇa', 'label': 'cikriyARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'cikrīvas', 'label': 'cikrIvas', 'attrib': [{'pos': 'pfap', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'cicchidāna', 'label': 'cicCidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'cicchidvas', 'label': 'cicCidvas', 'attrib': [{'pos': 'pfap', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'cit', 'label': 'cit', 'attrib': [{'pos': 'pcl', 'defs': '/'}], 'isLemma': 1},
	{'value': 'citra', 'label': 'citra', 'attrib': [{'pos': 'adj', 'defs': 'conspicuous, bright, variegated, different'}], 'isLemma': 1},
	{'value': 'cint', 'label': 'cint', 'attrib': [{'pos': 'vt10am', 'defs': 'consider'}], 'isLemma': 1},
	{'value': 'cintanīya', 'label': 'cintanIya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayat', 'label': 'cintayat', 'attrib': [{'pos': 'prap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayamāna', 'label': 'cintayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayām', 'label': 'cintayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayiṣyat', 'label': 'cintayizyat', 'attrib': [{'pos': 'fap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintayiṣyamāṇa', 'label': 'cintayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintita', 'label': 'cintita', 'attrib': [{'pos': 'ppp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitavat', 'label': 'cintitavat', 'attrib': [{'pos': 'pap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitavya', 'label': 'cintitavya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitum', 'label': 'cintitum', 'attrib': [{'pos': 'inf', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintitvā', 'label': 'cintitvA', 'attrib': [{'pos': 'abs', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintya', 'label': 'cintya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cintyamāna', 'label': 'cintyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'cira', 'label': 'cira', 'attrib': [{'pos': 'adj', 'defs': 'long'}], 'isLemma': 1},
	{'value': 'cukrudhāna', 'label': 'cukruDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'cukrudhvas', 'label': 'cukruDvas', 'attrib': [{'pos': 'pfap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'cur', 'label': 'cur', 'attrib': [{'pos': 'vt10a', 'defs': 'steal'}], 'isLemma': 1},
	{'value': 'cerāṇa', 'label': 'cerARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'cerivas', 'label': 'cerivas', 'attrib': [{'pos': 'pfap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'corayat', 'label': 'corayat', 'attrib': [{'pos': 'prap', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'corayām', 'label': 'corayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'corayitum', 'label': 'corayitum', 'attrib': [{'pos': 'inf', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'corayitvā', 'label': 'corayitvA', 'attrib': [{'pos': 'abs', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'corayiṣyat', 'label': 'corayizyat', 'attrib': [{'pos': 'fap', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'corayiṣyamāṇa', 'label': 'corayizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'corita', 'label': 'corita', 'attrib': [{'pos': 'ppp', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'coritavat', 'label': 'coritavat', 'attrib': [{'pos': 'pap', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'coryamāṇa', 'label': 'coryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'caura', 'label': 'cOra', 'attrib': [{'pos': 'm', 'defs': 'thief'}], 'isLemma': 1},
	{'value': 'chittvā', 'label': 'CittvA', 'attrib': [{'pos': 'abs', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chid', 'label': 'Cid', 'attrib': [{'pos': 'vt7am', 'defs': 'cleave'}], 'isLemma': 1},
	{'value': 'chidyamāna', 'label': 'CidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chindat', 'label': 'Cindat', 'attrib': [{'pos': 'prap', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chindāna', 'label': 'CindAna', 'attrib': [{'pos': 'prmp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chinna', 'label': 'Cinna', 'attrib': [{'pos': 'ppp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chinnavat', 'label': 'Cinnavat', 'attrib': [{'pos': 'pap', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chettavya', 'label': 'Cettavya', 'attrib': [{'pos': 'ger', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chettum', 'label': 'Cettum', 'attrib': [{'pos': 'inf', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chetsyat', 'label': 'Cetsyat', 'attrib': [{'pos': 'fap', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chetsyamāna', 'label': 'CetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chedanīya', 'label': 'CedanIya', 'attrib': [{'pos': 'ger', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'chedya', 'label': 'Cedya', 'attrib': [{'pos': 'ger', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'jagat', 'label': 'jagat', 'attrib': [{'pos': 'n', 'defs': 'moving, world'}], 'isLemma': 1},
	{'value': 'jaganvas', 'label': 'jaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'jagarāṇa', 'label': 'jagarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'jagarvas', 'label': 'jagarvas', 'attrib': [{'pos': 'pfap', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'jagṛhāṇa', 'label': 'jagfhARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'jagṛhvas', 'label': 'jagfhvas', 'attrib': [{'pos': 'pfap', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'jagmāna', 'label': 'jagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'jagmivas', 'label': 'jagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'jaghanvas', 'label': 'jaGanvas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'jaghnāna', 'label': 'jaGnAna', 'attrib': [{'pos': 'pfpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'jaghnivas', 'label': 'jaGnivas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'jajarāṇa', 'label': 'jajarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jajarvas', 'label': 'jajarvas', 'attrib': [{'pos': 'pfap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jajñāna', 'label': 'jajYAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be born, arise; caus. generate'}, {'pos': 'pfpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jan', 'jYA']},
	{'value': 'jajñivas', 'label': 'jajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jan', 'label': 'jan', 'attrib': [{'pos': 'vi4m', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 1},
	{'value': 'jana', 'label': 'jana', 'attrib': [{'pos': 'm', 'defs': 'people or person'}], 'isLemma': 1},
	{'value': 'jananīya', 'label': 'jananIya', 'attrib': [{'pos': 'ger', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'janayām', 'label': 'janayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'janitavya', 'label': 'janitavya', 'attrib': [{'pos': 'ger', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'janitum', 'label': 'janitum', 'attrib': [{'pos': 'inf', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'janitvā', 'label': 'janitvA', 'attrib': [{'pos': 'abs', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'janiṣyamāṇa', 'label': 'janizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'janya', 'label': 'janya', 'attrib': [{'pos': 'ger', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'jayat', 'label': 'jayat', 'attrib': [{'pos': 'prap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jayanīya', 'label': 'jayanIya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jaraṇīya', 'label': 'jaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jaritavya', 'label': 'jaritavya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jaritum', 'label': 'jaritum', 'attrib': [{'pos': 'inf', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jaritvā', 'label': 'jaritvA', 'attrib': [{'pos': 'abs', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jariṣyat', 'label': 'jarizyat', 'attrib': [{'pos': 'fap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jariṣyamāṇa', 'label': 'jarizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarītavya', 'label': 'jarItavya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarītum', 'label': 'jarItum', 'attrib': [{'pos': 'inf', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarītvā', 'label': 'jarItvA', 'attrib': [{'pos': 'abs', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jarya', 'label': 'jarya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jahat', 'label': 'jahat', 'attrib': [{'pos': 'prap', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'jahāna', 'label': 'jahAna', 'attrib': [{'pos': 'prmp', 'defs': 'leave, abandon'}, {'pos': 'pfmp, pfpp', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'jahivas', 'label': 'jahivas', 'attrib': [{'pos': 'pfap', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'jahṛvas', 'label': 'jahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'jahrāṇa', 'label': 'jahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'jāta', 'label': 'jAta', 'attrib': [{'pos': 'ppp', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'jātavat', 'label': 'jAtavat', 'attrib': [{'pos': 'pap', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'jānat', 'label': 'jAnat', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jāyamāna', 'label': 'jAyamAna', 'attrib': [{'pos': 'prmp, prpp', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'jārya', 'label': 'jArya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'ji', 'label': 'ji', 'attrib': [{'pos': 'vt1a', 'defs': 'conquer'}], 'isLemma': 1},
	{'value': 'jigivas', 'label': 'jigivas', 'attrib': [{'pos': 'pfap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jigyāna', 'label': 'jigyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jijīvas', 'label': 'jijIvas', 'attrib': [{'pos': 'pfap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jijīvāna', 'label': 'jijIvAna', 'attrib': [{'pos': 'pfpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jita', 'label': 'jita', 'attrib': [{'pos': 'ppp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jitavat', 'label': 'jitavat', 'attrib': [{'pos': 'pap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jitvā', 'label': 'jitvA', 'attrib': [{'pos': 'abs', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jīyamāna', 'label': 'jIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jīrṇa', 'label': 'jIrRa', 'attrib': [{'pos': 'ppp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jīrṇavat', 'label': 'jIrRavat', 'attrib': [{'pos': 'pap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jīryat', 'label': 'jIryat', 'attrib': [{'pos': 'prap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jīryamāṇa', 'label': 'jIryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jīv', 'label': 'jIv', 'attrib': [{'pos': 'vi1a', 'defs': 'live'}], 'isLemma': 1},
	{'value': 'jīvat', 'label': 'jIvat', 'attrib': [{'pos': 'prap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvanīya', 'label': 'jIvanIya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvita', 'label': 'jIvita', 'attrib': [{'pos': 'ppp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvitavat', 'label': 'jIvitavat', 'attrib': [{'pos': 'pap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvitavya', 'label': 'jIvitavya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvitum', 'label': 'jIvitum', 'attrib': [{'pos': 'inf', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvitvā', 'label': 'jIvitvA', 'attrib': [{'pos': 'abs', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīviṣyat', 'label': 'jIvizyat', 'attrib': [{'pos': 'fap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīviṣyamāṇa', 'label': 'jIvizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvya', 'label': 'jIvya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'jīvyamāna', 'label': 'jIvyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'juhavām', 'label': 'juhavAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'juhuvāna', 'label': 'juhuvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'juhūvas', 'label': 'juhUvas', 'attrib': [{'pos': 'pfap', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'juhvat', 'label': 'juhvat', 'attrib': [{'pos': 'prap', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'jṝ', 'label': 'jF', 'attrib': [{'pos': 'vi4a', 'defs': 'wither, age, grow old'}], 'isLemma': 1},
	{'value': 'jetavya', 'label': 'jetavya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jetum', 'label': 'jetum', 'attrib': [{'pos': 'inf', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jeya', 'label': 'jeya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jerāṇa', 'label': 'jerARa', 'attrib': [{'pos': 'pfpp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jerivas', 'label': 'jerivas', 'attrib': [{'pos': 'pfap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'jeṣyat', 'label': 'jezyat', 'attrib': [{'pos': 'fap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jeṣyamāṇa', 'label': 'jezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'jñā', 'label': 'jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'know'}], 'isLemma': 1},
	{'value': 'jñāta', 'label': 'jYAta', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñātavat', 'label': 'jYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñātavya', 'label': 'jYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñātum', 'label': 'jYAtum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñātvā', 'label': 'jYAtvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñāna', 'label': 'jYAna', 'attrib': [{'pos': 'n', 'defs': 'knowledge'}], 'isLemma': 1},
	{'value': 'jñānīya', 'label': 'jYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñāyamāna', 'label': 'jYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñāsyat', 'label': 'jYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñāsyamāna', 'label': 'jYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'jñeya', 'label': 'jYeya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'taṁsyat', 'label': 'taMsyat', 'attrib': [{'pos': 'fap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'taṁsyamāna', 'label': 'taMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tata', 'label': 'tata', 'attrib': [{'pos': 'ppp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tatavat', 'label': 'tatavat', 'attrib': [{'pos': 'pap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tatas', 'label': 'tatas', 'attrib': [{'pos': 'dem_adv', 'defs': 'from that, therefore, then'}], 'isLemma': 1},
	{'value': 'tatyajāna', 'label': 'tatyajAna', 'attrib': [{'pos': 'pfpp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tatyajvas', 'label': 'tatyajvas', 'attrib': [{'pos': 'pfap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tatra', 'label': 'tatra', 'attrib': [{'pos': 'dem_adv', 'defs': 'there'}], 'isLemma': 1},
	{'value': 'tatrāṇa', 'label': 'tatrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'tathā', 'label': 'taTA', 'attrib': [{'pos': 'dem_adv', 'defs': 'in that manner, thus'}], 'isLemma': 1},
	{'value': 'tad', 'label': 'tad', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'that, the'}, {'pos': 'dem_adv', 'defs': 'so'}], 'isLemma': 1},
	{'value': 'tadā', 'label': 'tadA', 'attrib': [{'pos': 'dem_adv', 'defs': 'at that time, then'}], 'isLemma': 1},
	{'value': 'tan', 'label': 'tan', 'attrib': [{'pos': 'vt8am', 'defs': 'extend'}], 'isLemma': 1},
	{'value': 'tananīya', 'label': 'tananIya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanitavya', 'label': 'tanitavya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanitum', 'label': 'tanitum', 'attrib': [{'pos': 'inf', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanitvā', 'label': 'tanitvA', 'attrib': [{'pos': 'abs', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'taniṣyat', 'label': 'tanizyat', 'attrib': [{'pos': 'fap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'taniṣyamāṇa', 'label': 'tanizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanyamāna', 'label': 'tanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanvat', 'label': 'tanvat', 'attrib': [{'pos': 'prap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tanvāna', 'label': 'tanvAna', 'attrib': [{'pos': 'prmp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tarhi', 'label': 'tarhi', 'attrib': [{'pos': 'dem_adv', 'defs': 'then, therefore'}], 'isLemma': 1},
	{'value': 'tasthāna', 'label': 'tasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tasthivas', 'label': 'tasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tānya', 'label': 'tAnya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tāvat', 'label': 'tAvat', 'attrib': [{'pos': 'dem_adj', 'defs': 'of that extent'}, {'pos': 'dem_adv', 'defs': 'to that extent'}], 'isLemma': 1},
	{'value': 'tityajāna', 'label': 'tityajAna', 'attrib': [{'pos': 'pfpp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tityajvas', 'label': 'tityajvas', 'attrib': [{'pos': 'pfap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tiras', 'label': 'tiras', 'attrib': [{'pos': 'preverb', 'defs': 'across, horizontal'}], 'isLemma': 1},
	{'value': 'tiras_bhū', 'label': 'tiras_BU', 'attrib': [{'pos': 'vi1a', 'defs': 'become horizontal, disappear'}], 'isLemma': 1},
	{'value': 'tirobabhūvas', 'label': 'tirobaBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tirobhavat', 'label': 'tiroBavat', 'attrib': [{'pos': 'prap', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tirobhavanīya', 'label': 'tiroBavanIya', 'attrib': [{'pos': 'ger', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tirobhavitavya', 'label': 'tiroBavitavya', 'attrib': [{'pos': 'ger', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tirobhavitum', 'label': 'tiroBavitum', 'attrib': [{'pos': 'inf', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tirobhaviṣyat', 'label': 'tiroBavizyat', 'attrib': [{'pos': 'fap', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tirobhaviṣyamāṇa', 'label': 'tiroBavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tirobhavya', 'label': 'tiroBavya', 'attrib': [{'pos': 'ger', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tirobhūta', 'label': 'tiroBUta', 'attrib': [{'pos': 'ppp', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tirobhūtavat', 'label': 'tiroBUtavat', 'attrib': [{'pos': 'pap', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tirobhūya', 'label': 'tiroBUya', 'attrib': [{'pos': 'abs', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tirobhūyamāna', 'label': 'tiroBUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'tiṣṭhat', 'label': 'tizWat', 'attrib': [{'pos': 'prap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tiṣṭhamāna', 'label': 'tizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'tu', 'label': 'tu', 'attrib': [{'pos': 'conj_pcl', 'defs': 'but'}], 'isLemma': 1},
	{'value': 'tṛtīya', 'label': 'tftIya', 'attrib': [{'pos': 'ord_num', 'defs': 'third'}], 'isLemma': 1},
	{'value': 'tenāna', 'label': 'tenAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tenivas', 'label': 'tenivas', 'attrib': [{'pos': 'pfap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'tyakta', 'label': 'tyakta', 'attrib': [{'pos': 'ppp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyaktavat', 'label': 'tyaktavat', 'attrib': [{'pos': 'pap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyaktavya', 'label': 'tyaktavya', 'attrib': [{'pos': 'ger', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyaktum', 'label': 'tyaktum', 'attrib': [{'pos': 'inf', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyaktvā', 'label': 'tyaktvA', 'attrib': [{'pos': 'abs', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyakṣyat', 'label': 'tyakzyat', 'attrib': [{'pos': 'fap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyakṣyamāṇa', 'label': 'tyakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyaj', 'label': 'tyaj', 'attrib': [{'pos': 'vt1a', 'defs': 'abandon'}], 'isLemma': 1},
	{'value': 'tyajat', 'label': 'tyajat', 'attrib': [{'pos': 'prap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyajanīya', 'label': 'tyajanIya', 'attrib': [{'pos': 'ger', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyajyamāna', 'label': 'tyajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'tyājya', 'label': 'tyAjya', 'attrib': [{'pos': 'ger', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'trāṇa', 'label': 'trARa', 'attrib': [{'pos': 'ppp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'trāta', 'label': 'trAta', 'attrib': [{'pos': 'ppp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'trātavat', 'label': 'trAtavat', 'attrib': [{'pos': 'pap', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'trātum', 'label': 'trAtum', 'attrib': [{'pos': 'inf', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'trātvā', 'label': 'trAtvA', 'attrib': [{'pos': 'abs', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'trāyamāṇa', 'label': 'trAyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'save'}, {'pos': 'prpp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'trāsyamāna', 'label': 'trAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'tri', 'label': 'tri', 'attrib': [{'pos': 'card_num', 'defs': 'three'}], 'isLemma': 1},
	{'value': 'trai', 'label': 'trE', 'attrib': [{'pos': 'vt1m', 'defs': 'save'}], 'isLemma': 1},
	{'value': 'dagdha', 'label': 'dagDa', 'attrib': [{'pos': 'ppp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagdhavat', 'label': 'dagDavat', 'attrib': [{'pos': 'pap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagdhavya', 'label': 'dagDavya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagdhum', 'label': 'dagDum', 'attrib': [{'pos': 'inf', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dagdhvā', 'label': 'dagDvA', 'attrib': [{'pos': 'abs', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'datta', 'label': 'datta', 'attrib': [{'pos': 'ppp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dattavat', 'label': 'dattavat', 'attrib': [{'pos': 'pap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dattvā', 'label': 'dattvA', 'attrib': [{'pos': 'abs', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadat', 'label': 'dadat', 'attrib': [{'pos': 'prap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadahāna', 'label': 'dadahAna', 'attrib': [{'pos': 'pfpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dadahvas', 'label': 'dadahvas', 'attrib': [{'pos': 'pfap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dadāna', 'label': 'dadAna', 'attrib': [{'pos': 'prmp', 'defs': 'give'}, {'pos': 'pfmp, pfpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadivas', 'label': 'dadivas', 'attrib': [{'pos': 'pfap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dadṛśāna', 'label': 'dadfSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dadṛśivas', 'label': 'dadfSivas', 'attrib': [{'pos': 'pfap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dadṛśvas', 'label': 'dadfSvas', 'attrib': [{'pos': 'pfap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dadhat', 'label': 'daDat', 'attrib': [{'pos': 'prap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dadhāna', 'label': 'daDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put'}, {'pos': 'pfmp, pfpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dadhivas', 'label': 'daDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'danta', 'label': 'danta', 'attrib': [{'pos': 'm', 'defs': 'tooth, tusk'}], 'isLemma': 1},
	{'value': 'darśanīya', 'label': 'darSanIya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'daśan', 'label': 'daSan', 'attrib': [{'pos': 'card_num', 'defs': 'ten'}], 'isLemma': 1},
	{'value': 'daśama', 'label': 'daSama', 'attrib': [{'pos': 'ord_num', 'defs': 'tenth'}], 'isLemma': 1},
	{'value': 'dah', 'label': 'dah', 'attrib': [{'pos': 'vt1a', 'defs': 'burn'}], 'isLemma': 1},
	{'value': 'dahat', 'label': 'dahat', 'attrib': [{'pos': 'prap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dahanīya', 'label': 'dahanIya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dahyamāna', 'label': 'dahyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dā', 'label': 'dA', 'attrib': [{'pos': 'vt3am', 'defs': 'give'}], 'isLemma': 1},
	{'value': 'dātavya', 'label': 'dAtavya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dātum', 'label': 'dAtum', 'attrib': [{'pos': 'inf', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dānīya', 'label': 'dAnIya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dāsyat', 'label': 'dAsyat', 'attrib': [{'pos': 'fap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dāsyamāna', 'label': 'dAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dāhya', 'label': 'dAhya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'didivas', 'label': 'didivas', 'attrib': [{'pos': 'pfap', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'didivāna', 'label': 'didivAna', 'attrib': [{'pos': 'pfpp', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'dina', 'label': 'dina', 'attrib': [{'pos': 'n', 'defs': 'day'}], 'isLemma': 1},
	{'value': 'div', 'label': 'div', 'attrib': [{'pos': 'm, f', 'defs': 'sky, heaven, day'}, {'pos': 'vt4a', 'defs': 'play'}], 'isLemma': 1},
	{'value': 'diś', 'label': 'diS', 'attrib': [{'pos': 'f', 'defs': 'direction'}], 'isLemma': 1},
	{'value': 'dīyamāna', 'label': 'dIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'dīrgha', 'label': 'dIrGa', 'attrib': [{'pos': 'adj', 'defs': 'long'}], 'isLemma': 1},
	{'value': 'dīrghāyus', 'label': 'dIrGAyus', 'attrib': [{'pos': 'adj', 'defs': 'whose life is long, long-lived'}], 'isLemma': 1},
	{'value': 'dīvyat', 'label': 'dIvyat', 'attrib': [{'pos': 'prap', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'dīvyamāna', 'label': 'dIvyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'duḥkha', 'label': 'duHKa', 'attrib': [{'pos': 'adj', 'defs': 'uncomfortable'}, {'pos': 'n', 'defs': 'sorrow'}], 'isLemma': 1},
	{'value': 'duḥkhin', 'label': 'duHKin', 'attrib': [{'pos': 'adj', 'defs': 'sorrowful, sad'}], 'isLemma': 1},
	{'value': 'durāpa', 'label': 'durApa', 'attrib': [{'pos': 'adj', 'defs': 'difficult to obtain'}], 'isLemma': 1},
	{'value': 'durmanas', 'label': 'durmanas', 'attrib': [{'pos': 'adj', 'defs': 'low-spirited, sad'}], 'isLemma': 1},
	{'value': 'duṣṣanta', 'label': 'duzzanta', 'attrib': [{'pos': 'm_pn', 'defs': 'Duṣṣanta'}], 'isLemma': 1},
	{'value': 'dus', 'label': 'dus', 'attrib': [{'pos': 'preverb', 'defs': 'ill'}], 'isLemma': 1},
	{'value': 'duhitṛ', 'label': 'duhitf', 'attrib': [{'pos': 'f', 'defs': 'daughter'}], 'isLemma': 1},
	{'value': 'dūra', 'label': 'dUra', 'attrib': [{'pos': 'adj', 'defs': 'distant'}], 'isLemma': 1},
	{'value': 'dṛś', 'label': 'dfS', 'attrib': [{'pos': 'vt1a', 'defs': 'see, watch'}], 'isLemma': 1},
	{'value': 'dṛśya', 'label': 'dfSya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dṛśyamāna', 'label': 'dfSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dṛṣṭa', 'label': 'dfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dṛṣṭavat', 'label': 'dfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dṛṣṭvā', 'label': 'dfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'deya', 'label': 'deya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'deva', 'label': 'deva', 'attrib': [{'pos': 'm', 'defs': 'god'}], 'isLemma': 1},
	{'value': 'devanīya', 'label': 'devanIya', 'attrib': [{'pos': 'ger', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'devitavya', 'label': 'devitavya', 'attrib': [{'pos': 'ger', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'devitum', 'label': 'devitum', 'attrib': [{'pos': 'inf', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'devitvā', 'label': 'devitvA', 'attrib': [{'pos': 'abs', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'deviṣyat', 'label': 'devizyat', 'attrib': [{'pos': 'fap', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'deviṣyamāṇa', 'label': 'devizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'devī', 'label': 'devI', 'attrib': [{'pos': 'f', 'defs': 'goddess'}], 'isLemma': 1},
	{'value': 'devya', 'label': 'devya', 'attrib': [{'pos': 'ger', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'deśa', 'label': 'deSa', 'attrib': [{'pos': 'm', 'defs': 'place, country'}], 'isLemma': 1},
	{'value': 'deha', 'label': 'deha', 'attrib': [{'pos': 'm', 'defs': 'body'}], 'isLemma': 1},
	{'value': 'dyūta', 'label': 'dyUta', 'attrib': [{'pos': 'ppp', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'dyūtavat', 'label': 'dyUtavat', 'attrib': [{'pos': 'pap', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'dyūtvā', 'label': 'dyUtvA', 'attrib': [{'pos': 'abs', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'drakṣyat', 'label': 'drakzyat', 'attrib': [{'pos': 'fap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'drakṣyamāṇa', 'label': 'drakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'draṣṭavya', 'label': 'drazwavya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'draṣṭum', 'label': 'drazwum', 'attrib': [{'pos': 'inf', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'dvi', 'label': 'dvi', 'attrib': [{'pos': 'card_num', 'defs': 'two'}], 'isLemma': 1},
	{'value': 'dvitīya', 'label': 'dvitIya', 'attrib': [{'pos': 'ord_num', 'defs': 'second'}], 'isLemma': 1},
	{'value': 'dhakṣyat', 'label': 'Dakzyat', 'attrib': [{'pos': 'fap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dhakṣyamāṇa', 'label': 'DakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'dhana', 'label': 'Dana', 'attrib': [{'pos': 'n', 'defs': 'wealth, money'}], 'isLemma': 1},
	{'value': 'dhanin', 'label': 'Danin', 'attrib': [{'pos': 'adj', 'defs': 'wealthy'}], 'isLemma': 1},
	{'value': 'dhanus', 'label': 'Danus', 'attrib': [{'pos': 'n', 'defs': 'bow'}], 'isLemma': 1},
	{'value': 'dharma', 'label': 'Darma', 'attrib': [{'pos': 'm', 'defs': 'dharma , duty, right action'}], 'isLemma': 1},
	{'value': 'dhā', 'label': 'DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put'}], 'isLemma': 1},
	{'value': 'dhātavya', 'label': 'DAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhātum', 'label': 'DAtum', 'attrib': [{'pos': 'inf', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhānīya', 'label': 'DAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhārtarāṣṭra', 'label': 'DArtarAzwra', 'attrib': [{'pos': 'm', 'defs': 'descendant of Dhṛtarāṣṭra'}], 'isLemma': 1},
	{'value': 'dhāsyat', 'label': 'DAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhāsyamāna', 'label': 'DAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhī', 'label': 'DI', 'attrib': [{'pos': 'f', 'defs': 'intelligence, thought'}], 'isLemma': 1},
	{'value': 'dhīmat', 'label': 'DImat', 'attrib': [{'pos': 'adj', 'defs': 'wise, intelligent'}], 'isLemma': 1},
	{'value': 'dhīyamāna', 'label': 'DIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'dhenu', 'label': 'Denu', 'attrib': [{'pos': 'f', 'defs': 'cow'}], 'isLemma': 1},
	{'value': 'dheya', 'label': 'Deya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'na', 'label': 'na', 'attrib': [{'pos': 'neg_pcl', 'defs': 'not'}], 'isLemma': 1},
	{'value': 'nagara', 'label': 'nagara', 'attrib': [{'pos': 'n', 'defs': 'city'}], 'isLemma': 1},
	{'value': 'nagna', 'label': 'nagna', 'attrib': [{'pos': 'adj', 'defs': 'naked'}], 'isLemma': 1},
	{'value': 'nadī', 'label': 'nadI', 'attrib': [{'pos': 'f', 'defs': 'river'}], 'isLemma': 1},
	{'value': 'nayat', 'label': 'nayat', 'attrib': [{'pos': 'prap', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nayanīya', 'label': 'nayanIya', 'attrib': [{'pos': 'ger', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nayamāna', 'label': 'nayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nara', 'label': 'nara', 'attrib': [{'pos': 'm', 'defs': 'man'}], 'isLemma': 1},
	{'value': 'navan', 'label': 'navan', 'attrib': [{'pos': 'card_num', 'defs': 'nine'}], 'isLemma': 1},
	{'value': 'navama', 'label': 'navama', 'attrib': [{'pos': 'ord_num', 'defs': 'ninth'}], 'isLemma': 1},
	{'value': 'nāma', 'label': 'nAma', 'attrib': [{'pos': 'adv', 'defs': 'by name'}], 'isLemma': 1},
	{'value': 'nāman', 'label': 'nAman', 'attrib': [{'pos': 'n', 'defs': 'name'}], 'isLemma': 1},
	{'value': 'ni', 'label': 'ni', 'attrib': [{'pos': 'preverb', 'defs': 'in'}], 'isLemma': 1},
	{'value': 'ni_dhā', 'label': 'ni_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put down, deposit'}], 'isLemma': 1},
	{'value': 'nidadhat', 'label': 'nidaDat', 'attrib': [{'pos': 'prap', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nidadhāna', 'label': 'nidaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put down, deposit'}, {'pos': 'pfmp, pfpp', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nidadhivas', 'label': 'nidaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nidhātavya', 'label': 'niDAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nidhātum', 'label': 'niDAtum', 'attrib': [{'pos': 'inf', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nidhānīya', 'label': 'niDAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nidhāya', 'label': 'niDAya', 'attrib': [{'pos': 'abs', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nidhāsyat', 'label': 'niDAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nidhāsyamāna', 'label': 'niDAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nidhīyamāna', 'label': 'niDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nidheya', 'label': 'niDeya', 'attrib': [{'pos': 'ger', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'ninīvas', 'label': 'ninIvas', 'attrib': [{'pos': 'pfap', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'ninyāna', 'label': 'ninyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nirgacchat', 'label': 'nirgacCat', 'attrib': [{'pos': 'prap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgata', 'label': 'nirgata', 'attrib': [{'pos': 'ppp', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgatavat', 'label': 'nirgatavat', 'attrib': [{'pos': 'pap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgatya', 'label': 'nirgatya', 'attrib': [{'pos': 'abs', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgantavya', 'label': 'nirgantavya', 'attrib': [{'pos': 'ger', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgantum', 'label': 'nirgantum', 'attrib': [{'pos': 'inf', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgamanīya', 'label': 'nirgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgamiṣyat', 'label': 'nirgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgamiṣyamāṇa', 'label': 'nirgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgamya', 'label': 'nirgamya', 'attrib': [{'pos': 'abs', 'defs': 'go out, emerge'}, {'pos': 'ger', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirgamyamāna', 'label': 'nirgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirjaganvas', 'label': 'nirjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirjagmāna', 'label': 'nirjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'nirjagmivas', 'label': 'nirjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'niścakramāṇa', 'label': 'niScakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niścakramvas', 'label': 'niScakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niṣkraṁsyat', 'label': 'nizkraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niṣkraṁsyamāna', 'label': 'nizkraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niṣkramaṇīya', 'label': 'nizkramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niṣkramitavya', 'label': 'nizkramitavya', 'attrib': [{'pos': 'ger', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niṣkramitum', 'label': 'nizkramitum', 'attrib': [{'pos': 'inf', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niṣkramya', 'label': 'nizkramya', 'attrib': [{'pos': 'abs', 'defs': 'go out, depart'}, {'pos': 'ger', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niṣkramyamāṇa', 'label': 'nizkramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niṣkrānta', 'label': 'nizkrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niṣkrāntavat', 'label': 'nizkrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niṣkrāmat', 'label': 'nizkrAmat', 'attrib': [{'pos': 'prap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'niṣkrāmyat', 'label': 'nizkrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'nis', 'label': 'nis', 'attrib': [{'pos': 'preverb', 'defs': 'out'}], 'isLemma': 1},
	{'value': 'nis_kram', 'label': 'nis_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'go out, depart'}], 'isLemma': 1},
	{'value': 'nis_gam', 'label': 'nis_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go out, emerge'}], 'isLemma': 1},
	{'value': 'nihita', 'label': 'nihita', 'attrib': [{'pos': 'ppp', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nihitavat', 'label': 'nihitavat', 'attrib': [{'pos': 'pap', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'nī', 'label': 'nI', 'attrib': [{'pos': 'vt1am', 'defs': 'lead'}], 'isLemma': 1},
	{'value': 'nīta', 'label': 'nIta', 'attrib': [{'pos': 'ppp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nītavat', 'label': 'nItavat', 'attrib': [{'pos': 'pap', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nītvā', 'label': 'nItvA', 'attrib': [{'pos': 'abs', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nīyamāna', 'label': 'nIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'nṛpa', 'label': 'nfpa', 'attrib': [{'pos': 'm', 'defs': 'king'}], 'isLemma': 1},
	{'value': 'netavya', 'label': 'netavya', 'attrib': [{'pos': 'ger', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'netum', 'label': 'netum', 'attrib': [{'pos': 'inf', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'neya', 'label': 'neya', 'attrib': [{'pos': 'ger', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'neṣyat', 'label': 'nezyat', 'attrib': [{'pos': 'fap', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'neṣyamāṇa', 'label': 'nezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'paktavya', 'label': 'paktavya', 'attrib': [{'pos': 'ger', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'paktum', 'label': 'paktum', 'attrib': [{'pos': 'inf', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'paktvā', 'label': 'paktvA', 'attrib': [{'pos': 'abs', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pakva', 'label': 'pakva', 'attrib': [{'pos': 'ppp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pakvavat', 'label': 'pakvavat', 'attrib': [{'pos': 'pap', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pakṣa', 'label': 'pakza', 'attrib': [{'pos': 'm', 'defs': 'wing, side'}], 'isLemma': 1},
	{'value': 'pakṣyat', 'label': 'pakzyat', 'attrib': [{'pos': 'fap', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pakṣyamāṇa', 'label': 'pakzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pac', 'label': 'pac', 'attrib': [{'pos': 'vt1am', 'defs': 'cook'}], 'isLemma': 1},
	{'value': 'pacat', 'label': 'pacat', 'attrib': [{'pos': 'prap', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pacanīya', 'label': 'pacanIya', 'attrib': [{'pos': 'ger', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pacamāna', 'label': 'pacamAna', 'attrib': [{'pos': 'prmp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pacyamāna', 'label': 'pacyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pañcan', 'label': 'paYcan', 'attrib': [{'pos': 'card_num', 'defs': 'five'}], 'isLemma': 1},
	{'value': 'pañcama', 'label': 'paYcama', 'attrib': [{'pos': 'ord_num', 'defs': 'fifth'}], 'isLemma': 1},
	{'value': 'paṭh', 'label': 'paW', 'attrib': [{'pos': 'vt1a', 'defs': 'read aloud, recite'}], 'isLemma': 1},
	{'value': 'paṭhat', 'label': 'paWat', 'attrib': [{'pos': 'prap', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paṭhanīya', 'label': 'paWanIya', 'attrib': [{'pos': 'ger', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paṭhita', 'label': 'paWita', 'attrib': [{'pos': 'ppp', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paṭhitavat', 'label': 'paWitavat', 'attrib': [{'pos': 'pap', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paṭhitavya', 'label': 'paWitavya', 'attrib': [{'pos': 'ger', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paṭhitum', 'label': 'paWitum', 'attrib': [{'pos': 'inf', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paṭhitvā', 'label': 'paWitvA', 'attrib': [{'pos': 'abs', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paṭhiṣyat', 'label': 'paWizyat', 'attrib': [{'pos': 'fap', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paṭhiṣyamāṇa', 'label': 'paWizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'paṭhyamāna', 'label': 'paWyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'pat', 'label': 'pat', 'attrib': [{'pos': 'vt1a', 'defs': 'fall, fly'}], 'isLemma': 1},
	{'value': 'patat', 'label': 'patat', 'attrib': [{'pos': 'prap', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patanīya', 'label': 'patanIya', 'attrib': [{'pos': 'ger', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'pati', 'label': 'pati', 'attrib': [{'pos': 'm', 'defs': 'lord, husband'}], 'isLemma': 1},
	{'value': 'patita', 'label': 'patita', 'attrib': [{'pos': 'ppp', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patitavat', 'label': 'patitavat', 'attrib': [{'pos': 'pap', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patitavya', 'label': 'patitavya', 'attrib': [{'pos': 'ger', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patitum', 'label': 'patitum', 'attrib': [{'pos': 'inf', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patitvā', 'label': 'patitvA', 'attrib': [{'pos': 'abs', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patiṣyat', 'label': 'patizyat', 'attrib': [{'pos': 'fap', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patiṣyamāṇa', 'label': 'patizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'pattavya', 'label': 'pattavya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pattum', 'label': 'pattum', 'attrib': [{'pos': 'inf', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pattvā', 'label': 'pattvA', 'attrib': [{'pos': 'abs', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'patnī', 'label': 'patnI', 'attrib': [{'pos': 'f', 'defs': 'wife'}], 'isLemma': 1},
	{'value': 'patyamāna', 'label': 'patyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'patsyamāna', 'label': 'patsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pad', 'label': 'pad', 'attrib': [{'pos': 'vt4m', 'defs': 'step'}], 'isLemma': 1},
	{'value': 'pada', 'label': 'pada', 'attrib': [{'pos': 'n', 'defs': 'step'}], 'isLemma': 1},
	{'value': 'padanīya', 'label': 'padanIya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'padyamāna', 'label': 'padyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'step'}, {'pos': 'prpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'panna', 'label': 'panna', 'attrib': [{'pos': 'ppp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pannavat', 'label': 'pannavat', 'attrib': [{'pos': 'pap', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'paparāṇa', 'label': 'paparARa', 'attrib': [{'pos': 'pfpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'paparvas', 'label': 'paparvas', 'attrib': [{'pos': 'pfap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'papāna', 'label': 'papAna', 'attrib': [{'pos': 'pfpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'papivas', 'label': 'papivas', 'attrib': [{'pos': 'pfap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'papṝvas', 'label': 'papFvas', 'attrib': [{'pos': 'pfap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'papracchāna', 'label': 'papracCAna', 'attrib': [{'pos': 'pfpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'papracchvas', 'label': 'papracCvas', 'attrib': [{'pos': 'pfap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'paprāṇa', 'label': 'paprARa', 'attrib': [{'pos': 'pfpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'para', 'label': 'para', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'farther'}], 'isLemma': 1},
	{'value': 'param', 'label': 'param', 'attrib': [{'pos': 'adv', 'defs': 'after, beyond'}], 'isLemma': 1},
	{'value': 'pari', 'label': 'pari', 'attrib': [{'pos': 'preverb', 'defs': 'around'}], 'isLemma': 1},
	{'value': 'paricaraṇīya', 'label': 'paricaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricarat', 'label': 'paricarat', 'attrib': [{'pos': 'prap', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricaramāṇa', 'label': 'paricaramARa', 'attrib': [{'pos': 'prmp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricarita', 'label': 'paricarita', 'attrib': [{'pos': 'ppp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricaritavat', 'label': 'paricaritavat', 'attrib': [{'pos': 'pap', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricaritavya', 'label': 'paricaritavya', 'attrib': [{'pos': 'ger', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricaritum', 'label': 'paricaritum', 'attrib': [{'pos': 'inf', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricariṣyat', 'label': 'paricarizyat', 'attrib': [{'pos': 'fap', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricariṣyamāṇa', 'label': 'paricarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricarya', 'label': 'paricarya', 'attrib': [{'pos': 'abs', 'defs': 'move around'}, {'pos': 'ger', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricaryamāṇa', 'label': 'paricaryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricerāṇa', 'label': 'paricerARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'paricerivas', 'label': 'paricerivas', 'attrib': [{'pos': 'pfap', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'pari_car', 'label': 'pari_car', 'attrib': [{'pos': 'vt1am', 'defs': 'move around'}], 'isLemma': 1},
	{'value': 'pari_dhā', 'label': 'pari_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'wear, put on (clothes)'}], 'isLemma': 1},
	{'value': 'paridadhat', 'label': 'paridaDat', 'attrib': [{'pos': 'prap', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'paridadhāna', 'label': 'paridaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'wear, put on (clothes)'}, {'pos': 'pfmp, pfpp', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'paridadhivas', 'label': 'paridaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'paridhātavya', 'label': 'pariDAtavya', 'attrib': [{'pos': 'ger', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'paridhātum', 'label': 'pariDAtum', 'attrib': [{'pos': 'inf', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'paridhānīya', 'label': 'pariDAnIya', 'attrib': [{'pos': 'ger', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'paridhāya', 'label': 'pariDAya', 'attrib': [{'pos': 'abs', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'paridhāsyat', 'label': 'pariDAsyat', 'attrib': [{'pos': 'fap', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'paridhāsyamāna', 'label': 'pariDAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'paridhīyamāna', 'label': 'pariDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'paridheya', 'label': 'pariDeya', 'attrib': [{'pos': 'ger', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'pariṣyat', 'label': 'parizyat', 'attrib': [{'pos': 'fap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pariṣyamāṇa', 'label': 'parizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'parihita', 'label': 'parihita', 'attrib': [{'pos': 'ppp', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'parihitavat', 'label': 'parihitavat', 'attrib': [{'pos': 'pap', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'parīṣyat', 'label': 'parIzyat', 'attrib': [{'pos': 'fap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'parīṣyamāṇa', 'label': 'parIzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'parvata', 'label': 'parvata', 'attrib': [{'pos': 'm', 'defs': 'mountain'}], 'isLemma': 1},
	{'value': 'pavanīya', 'label': 'pavanIya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavita', 'label': 'pavita', 'attrib': [{'pos': 'ppp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitavat', 'label': 'pavitavat', 'attrib': [{'pos': 'pap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitavya', 'label': 'pavitavya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitum', 'label': 'pavitum', 'attrib': [{'pos': 'inf', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavitvā', 'label': 'pavitvA', 'attrib': [{'pos': 'abs', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'paviṣyat', 'label': 'pavizyat', 'attrib': [{'pos': 'fap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'paviṣyamāṇa', 'label': 'pavizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pavya', 'label': 'pavya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'paśu', 'label': 'paSu', 'attrib': [{'pos': 'm', 'defs': 'domestic animal'}], 'isLemma': 1},
	{'value': 'paścāt', 'label': 'paScAt', 'attrib': [{'pos': 'adv', 'defs': 'afterwards, later'}], 'isLemma': 1},
	{'value': 'paśyat', 'label': 'paSyat', 'attrib': [{'pos': 'prap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'paspṛśāna', 'label': 'paspfSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'paspṛśvas', 'label': 'paspfSvas', 'attrib': [{'pos': 'pfap', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'pā', 'label': 'pA', 'attrib': [{'pos': 'vt1a', 'defs': 'drink'}], 'isLemma': 1},
	{'value': 'pākya', 'label': 'pAkya', 'attrib': [{'pos': 'ger', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pāṭaliputra', 'label': 'pAwaliputra', 'attrib': [{'pos': 'n_pn', 'defs': 'Pāṭaliputra'}], 'isLemma': 1},
	{'value': 'pāṭhya', 'label': 'pAWya', 'attrib': [{'pos': 'ger', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'pāṇḍava', 'label': 'pARqava', 'attrib': [{'pos': 'm', 'defs': 'descendant of Pāṇḍu'}], 'isLemma': 1},
	{'value': 'pātavya', 'label': 'pAtavya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pātum', 'label': 'pAtum', 'attrib': [{'pos': 'inf', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pātya', 'label': 'pAtya', 'attrib': [{'pos': 'ger', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'pāda', 'label': 'pAda', 'attrib': [{'pos': 'm', 'defs': 'foot'}], 'isLemma': 1},
	{'value': 'pādya', 'label': 'pAdya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'pānīya', 'label': 'pAnIya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pāpa', 'label': 'pApa', 'attrib': [{'pos': 'adj', 'defs': 'bad, evil'}, {'pos': 'n', 'defs': 'evil, sin'}], 'isLemma': 1},
	{'value': 'pārvatī', 'label': 'pArvatI', 'attrib': [{'pos': 'f_pn', 'defs': 'Pārvatī'}], 'isLemma': 1},
	{'value': 'pāl', 'label': 'pAl', 'attrib': [{'pos': 'vt10am', 'defs': 'protect'}], 'isLemma': 1},
	{'value': 'pālanīya', 'label': 'pAlanIya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pālayat', 'label': 'pAlayat', 'attrib': [{'pos': 'prap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pālayamāna', 'label': 'pAlayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pālayām', 'label': 'pAlayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pālayitum', 'label': 'pAlayitum', 'attrib': [{'pos': 'inf', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pālayitvā', 'label': 'pAlayitvA', 'attrib': [{'pos': 'abs', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pālayiṣyat', 'label': 'pAlayizyat', 'attrib': [{'pos': 'fap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pālayiṣyamāṇa', 'label': 'pAlayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pālita', 'label': 'pAlita', 'attrib': [{'pos': 'ppp', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pālitavat', 'label': 'pAlitavat', 'attrib': [{'pos': 'pap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pālitavya', 'label': 'pAlitavya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pālya', 'label': 'pAlya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pālyamāna', 'label': 'pAlyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'pāsyat', 'label': 'pAsyat', 'attrib': [{'pos': 'fap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pāsyamāna', 'label': 'pAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pitāmaha', 'label': 'pitAmaha', 'attrib': [{'pos': 'm', 'defs': 'grandfather'}], 'isLemma': 1},
	{'value': 'pitṛ', 'label': 'pitf', 'attrib': [{'pos': 'm', 'defs': 'father'}], 'isLemma': 1},
	{'value': 'pibat', 'label': 'pibat', 'attrib': [{'pos': 'prap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pīta', 'label': 'pIta', 'attrib': [{'pos': 'ppp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pītavat', 'label': 'pItavat', 'attrib': [{'pos': 'pap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pītvā', 'label': 'pItvA', 'attrib': [{'pos': 'abs', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pīyamāna', 'label': 'pIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'putra', 'label': 'putra', 'attrib': [{'pos': 'm', 'defs': 'son, child'}], 'isLemma': 1},
	{'value': 'putrī', 'label': 'putrI', 'attrib': [{'pos': 'f', 'defs': 'daughter'}], 'isLemma': 1},
	{'value': 'punat', 'label': 'punat', 'attrib': [{'pos': 'prap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'punar', 'label': 'punar', 'attrib': [{'pos': 'adv', 'defs': 'again'}], 'isLemma': 1},
	{'value': 'punāna', 'label': 'punAna', 'attrib': [{'pos': 'prmp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pupuvāna', 'label': 'pupuvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pupūvas', 'label': 'pupUvas', 'attrib': [{'pos': 'pfap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pupluvāna', 'label': 'pupluvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'purā', 'label': 'purA', 'attrib': [{'pos': 'adv', 'defs': 'previously, long ago'}], 'isLemma': 1},
	{'value': 'puruṣa', 'label': 'puruza', 'attrib': [{'pos': 'm', 'defs': 'man, person, soul'}], 'isLemma': 1},
	{'value': 'purūravas', 'label': 'purUravas', 'attrib': [{'pos': 'm_pn', 'defs': 'Purūravas'}], 'isLemma': 1},
	{'value': 'puṣpa', 'label': 'puzpa', 'attrib': [{'pos': 'n', 'defs': 'flower'}], 'isLemma': 1},
	{'value': 'pū', 'label': 'pU', 'attrib': [{'pos': 'vt9am', 'defs': 'purify'}], 'isLemma': 1},
	{'value': 'pūj', 'label': 'pUj', 'attrib': [{'pos': 'vt10m', 'defs': 'honor, perform a pūjā'}], 'isLemma': 1},
	{'value': 'pūjanīya', 'label': 'pUjanIya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjayamāna', 'label': 'pUjayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjayām', 'label': 'pUjayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjayitum', 'label': 'pUjayitum', 'attrib': [{'pos': 'inf', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjayitvā', 'label': 'pUjayitvA', 'attrib': [{'pos': 'abs', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjayiṣyamāṇa', 'label': 'pUjayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjā', 'label': 'pUjA', 'attrib': [{'pos': 'f', 'defs': 'honor, pūjā'}], 'isLemma': 1},
	{'value': 'pūjita', 'label': 'pUjita', 'attrib': [{'pos': 'ppp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjitavat', 'label': 'pUjitavat', 'attrib': [{'pos': 'pap', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjitavya', 'label': 'pUjitavya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjya', 'label': 'pUjya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūjyamāna', 'label': 'pUjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'pūta', 'label': 'pUta', 'attrib': [{'pos': 'ppp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pūtavat', 'label': 'pUtavat', 'attrib': [{'pos': 'pap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pūtvā', 'label': 'pUtvA', 'attrib': [{'pos': 'abs', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pūyamāna', 'label': 'pUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'pūraṇīya', 'label': 'pUraRIya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūritavya', 'label': 'pUritavya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūritum', 'label': 'pUritum', 'attrib': [{'pos': 'inf', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūrṇa', 'label': 'pUrRa', 'attrib': [{'pos': 'adj', 'defs': 'filled, full'}, {'pos': 'ppp', 'defs': 'fill'}], 'isLemma': 1},
	{'value': 'pūrṇa', 'label': 'pUrRa', 'attrib': [{'pos': 'adj', 'defs': 'filled, full'}, {'pos': 'ppp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūrta', 'label': 'pUrta', 'attrib': [{'pos': 'ppp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūrtavat', 'label': 'pUrtavat', 'attrib': [{'pos': 'pap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūrtvā', 'label': 'pUrtvA', 'attrib': [{'pos': 'abs', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūrya', 'label': 'pUrya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūryamāṇa', 'label': 'pUryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pūrva', 'label': 'pUrva', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'previous'}], 'isLemma': 1},
	{'value': 'pūrvam', 'label': 'pUrvam', 'attrib': [{'pos': 'adv', 'defs': 'before, previously'}], 'isLemma': 1},
	{'value': 'pṛcchat', 'label': 'pfcCat', 'attrib': [{'pos': 'prap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pṛcchya', 'label': 'pfcCya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pṛcchyamāna', 'label': 'pfcCyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pṛṇat', 'label': 'pfRat', 'attrib': [{'pos': 'prap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'pṛthivī', 'label': 'pfTivI', 'attrib': [{'pos': 'f', 'defs': 'earth, ground'}], 'isLemma': 1},
	{'value': 'pṛthu', 'label': 'pfTu', 'attrib': [{'pos': 'adj', 'defs': 'broad'}], 'isLemma': 1},
	{'value': 'pṛṣṭa', 'label': 'pfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pṛṣṭavat', 'label': 'pfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pṛṣṭvā', 'label': 'pfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pṝ', 'label': 'pF', 'attrib': [{'pos': 'vt9a', 'defs': 'fill'}], 'isLemma': 1},
	{'value': 'pecāna', 'label': 'pecAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'pecivas', 'label': 'pecivas', 'attrib': [{'pos': 'pfap', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'peṭhāna', 'label': 'peWAna', 'attrib': [{'pos': 'pfpp', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'peṭhivas', 'label': 'peWivas', 'attrib': [{'pos': 'pfap', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'petāna', 'label': 'petAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'petivas', 'label': 'petivas', 'attrib': [{'pos': 'pfap', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'pedāna', 'label': 'pedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'peya', 'label': 'peya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'pra', 'label': 'pra', 'attrib': [{'pos': 'preverb', 'defs': 'forth'}], 'isLemma': 1},
	{'value': 'prakraṁsyat', 'label': 'prakraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakraṁsyamāna', 'label': 'prakraMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakramaṇīya', 'label': 'prakramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakramamāṇa', 'label': 'prakramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakramitavya', 'label': 'prakramitavya', 'attrib': [{'pos': 'ger', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakramitum', 'label': 'prakramitum', 'attrib': [{'pos': 'inf', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakramya', 'label': 'prakramya', 'attrib': [{'pos': 'abs', 'defs': 'set out, proceed, go forth (active); begin (middle)'}, {'pos': 'ger', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakramyamāṇa', 'label': 'prakramyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}, {'pos': 'prpp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakrānta', 'label': 'prakrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakrāntavat', 'label': 'prakrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakrāntum', 'label': 'prakrAntum', 'attrib': [{'pos': 'inf', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakrāmat', 'label': 'prakrAmat', 'attrib': [{'pos': 'prap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakrāmyat', 'label': 'prakrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'prakṣyat', 'label': 'prakzyat', 'attrib': [{'pos': 'fap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'prakṣyamāṇa', 'label': 'prakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'pragacchat', 'label': 'pragacCat', 'attrib': [{'pos': 'prap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragata', 'label': 'pragata', 'attrib': [{'pos': 'ppp', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragatavat', 'label': 'pragatavat', 'attrib': [{'pos': 'pap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragatya', 'label': 'pragatya', 'attrib': [{'pos': 'abs', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragantavya', 'label': 'pragantavya', 'attrib': [{'pos': 'ger', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragantum', 'label': 'pragantum', 'attrib': [{'pos': 'inf', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragamanīya', 'label': 'pragamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragamiṣyat', 'label': 'pragamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragamiṣyamāṇa', 'label': 'pragamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragamya', 'label': 'pragamya', 'attrib': [{'pos': 'abs', 'defs': 'go forth, set out'}, {'pos': 'ger', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pragamyamāna', 'label': 'pragamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'pracakramāṇa', 'label': 'pracakramARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'pracakramvas', 'label': 'pracakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'pracch', 'label': 'pracC', 'attrib': [{'pos': 'vt6a', 'defs': 'ask'}], 'isLemma': 1},
	{'value': 'pracchanīya', 'label': 'pracCanIya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'prajaganvas', 'label': 'prajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'prajagmāna', 'label': 'prajagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'prajagmivas', 'label': 'prajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'prajahṛvas', 'label': 'prajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prajahrāṇa', 'label': 'prajahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'pra_ah', 'label': 'pra_ah', 'attrib': [{'pos': 'vta', 'defs': 'tell, narrate'}], 'isLemma': 1},
	{'value': 'pra_āp', 'label': 'pra_Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'attain, obtain'}], 'isLemma': 1},
	{'value': 'pra_kram', 'label': 'pra_kram', 'attrib': [{'pos': 'vt1am', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 1},
	{'value': 'pra_gam', 'label': 'pra_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go forth, set out'}], 'isLemma': 1},
	{'value': 'pra_dā', 'label': 'pra_dA', 'attrib': [{'pos': 'vt3am', 'defs': 'present, bestow'}], 'isLemma': 1},
	{'value': 'pra_pat', 'label': 'pra_pat', 'attrib': [{'pos': 'vt1a', 'defs': 'fall forth'}], 'isLemma': 1},
	{'value': 'pra_brū', 'label': 'pra_brU', 'attrib': [{'pos': 'vt2am', 'defs': 'say, speak to, proclaim'}], 'isLemma': 1},
	{'value': 'pra_yam', 'label': 'pra_yam', 'attrib': [{'pos': 'vt1a', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 1},
	{'value': 'pra_viś', 'label': 'pra_viS', 'attrib': [{'pos': 'vt6a', 'defs': 'enter'}], 'isLemma': 1},
	{'value': 'pra_sthā', 'label': 'pra_sTA', 'attrib': [{'pos': 'vt1m', 'defs': 'set out'}], 'isLemma': 1},
	{'value': 'pra_hṛ', 'label': 'pra_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'throw, attack'}], 'isLemma': 1},
	{'value': 'pratasthāna', 'label': 'pratasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prati', 'label': 'prati', 'attrib': [{'pos': 'preverb', 'defs': 'against'}], 'isLemma': 1},
	{'value': 'pratikraṁsyat', 'label': 'pratikraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikraṁsyamāna', 'label': 'pratikraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikramaṇīya', 'label': 'pratikramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikramitavya', 'label': 'pratikramitavya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikramitum', 'label': 'pratikramitum', 'attrib': [{'pos': 'inf', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikramya', 'label': 'pratikramya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}, {'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikramyamāṇa', 'label': 'pratikramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikrānta', 'label': 'pratikrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikrāntavat', 'label': 'pratikrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikrāmat', 'label': 'pratikrAmat', 'attrib': [{'pos': 'prap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratikrāmyat', 'label': 'pratikrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratigacchat', 'label': 'pratigacCat', 'attrib': [{'pos': 'prap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigata', 'label': 'pratigata', 'attrib': [{'pos': 'ppp', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigatavat', 'label': 'pratigatavat', 'attrib': [{'pos': 'pap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigatya', 'label': 'pratigatya', 'attrib': [{'pos': 'abs', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigantavya', 'label': 'pratigantavya', 'attrib': [{'pos': 'ger', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigantum', 'label': 'pratigantum', 'attrib': [{'pos': 'inf', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigamanīya', 'label': 'pratigamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigamiṣyat', 'label': 'pratigamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigamiṣyamāṇa', 'label': 'pratigamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigamya', 'label': 'pratigamya', 'attrib': [{'pos': 'abs', 'defs': 'go back, return'}, {'pos': 'ger', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratigamyamāna', 'label': 'pratigamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'praticakramāṇa', 'label': 'praticakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'praticakramvas', 'label': 'praticakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'pratijaganvas', 'label': 'pratijaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratijagmāna', 'label': 'pratijagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratijagmivas', 'label': 'pratijagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'pratijajñāna', 'label': 'pratijajYAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijahṛvas', 'label': 'pratijahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratijahrāṇa', 'label': 'pratijahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratijānāna', 'label': 'pratijAnAna', 'attrib': [{'pos': 'prmp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijñā', 'label': 'pratijYA', 'attrib': [{'pos': 'f', 'defs': 'promise'}], 'isLemma': 1},
	{'value': 'pratijñāta', 'label': 'pratijYAta', 'attrib': [{'pos': 'ppp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijñātavat', 'label': 'pratijYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijñātavya', 'label': 'pratijYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijñātum', 'label': 'pratijYAtum', 'attrib': [{'pos': 'inf', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijñānīya', 'label': 'pratijYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijñāya', 'label': 'pratijYAya', 'attrib': [{'pos': 'abs', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijñāyamāna', 'label': 'pratijYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijñāsyamāna', 'label': 'pratijYAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'pratijñeya', 'label': 'pratijYeya', 'attrib': [{'pos': 'ger', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'prati_abhi_jñā', 'label': 'prati_aBi_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'recognize'}], 'isLemma': 1},
	{'value': 'prati_ah', 'label': 'prati_ah', 'attrib': [{'pos': 'vta', 'defs': 'answer'}], 'isLemma': 1},
	{'value': 'prati_ā_i', 'label': 'prati_A_i', 'attrib': [{'pos': 'vt2a', 'defs': 'come back'}], 'isLemma': 1},
	{'value': 'prati_ā_gam', 'label': 'prati_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come back, return'}], 'isLemma': 1},
	{'value': 'prati_ā_nī', 'label': 'prati_A_nI', 'attrib': [{'pos': 'vt1am', 'defs': 'bring back'}], 'isLemma': 1},
	{'value': 'prati_ā_hṛ', 'label': 'prati_A_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'bring back'}], 'isLemma': 1},
	{'value': 'prati_i', 'label': 'prati_i', 'attrib': [{'pos': 'vt2a', 'defs': 'go back, recognize'}], 'isLemma': 1},
	{'value': 'prati_kram', 'label': 'prati_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'come back, return'}], 'isLemma': 1},
	{'value': 'prati_gam', 'label': 'prati_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go back, return'}], 'isLemma': 1},
	{'value': 'prati_jñā', 'label': 'prati_jYA', 'attrib': [{'pos': 'vt9m', 'defs': 'promise'}], 'isLemma': 1},
	{'value': 'prati_brū', 'label': 'prati_brU', 'attrib': [{'pos': 'vt2am', 'defs': 'reply'}], 'isLemma': 1},
	{'value': 'prati_bhāṣ', 'label': 'prati_BAz', 'attrib': [{'pos': 'vt1m', 'defs': 'answer'}], 'isLemma': 1},
	{'value': 'prati_labh', 'label': 'prati_laB', 'attrib': [{'pos': 'vt1m', 'defs': 'get back, regain'}], 'isLemma': 1},
	{'value': 'prati_vac', 'label': 'prati_vac', 'attrib': [{'pos': 'vt2a', 'defs': 'answer'}], 'isLemma': 1},
	{'value': 'prati_vad', 'label': 'prati_vad', 'attrib': [{'pos': 'vt1a', 'defs': 'answer'}], 'isLemma': 1},
	{'value': 'prati_sthā', 'label': 'prati_sTA', 'attrib': [{'pos': 'vt1a', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 1},
	{'value': 'prati_hṛ', 'label': 'prati_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'strike back or against, take back'}], 'isLemma': 1},
	{'value': 'pratitaṣṭhāna', 'label': 'pratitazWAna', 'attrib': [{'pos': 'pfpp', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratitaṣṭhivas', 'label': 'pratitazWivas', 'attrib': [{'pos': 'pfap', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratitiṣṭhat', 'label': 'pratitizWat', 'attrib': [{'pos': 'prap', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratibabhāṣāṇa', 'label': 'pratibaBAzARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratibruvat', 'label': 'pratibruvat', 'attrib': [{'pos': 'prap', 'defs': 'reply'}], 'isLemma': 0, 'parents': ['prati_brU']},
	{'value': 'pratibruvāṇa', 'label': 'pratibruvARa', 'attrib': [{'pos': 'prmp', 'defs': 'reply'}], 'isLemma': 0, 'parents': ['prati_brU']},
	{'value': 'pratibhāṣaṇīya', 'label': 'pratiBAzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratibhāṣamāṇa', 'label': 'pratiBAzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratibhāṣita', 'label': 'pratiBAzita', 'attrib': [{'pos': 'ppp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratibhāṣitavat', 'label': 'pratiBAzitavat', 'attrib': [{'pos': 'pap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratibhāṣitavya', 'label': 'pratiBAzitavya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratibhāṣitum', 'label': 'pratiBAzitum', 'attrib': [{'pos': 'inf', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratibhāṣiṣyamāṇa', 'label': 'pratiBAzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratibhāṣya', 'label': 'pratiBAzya', 'attrib': [{'pos': 'abs', 'defs': 'answer'}, {'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratibhāṣyamāṇa', 'label': 'pratiBAzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'pratiyat', 'label': 'pratiyat', 'attrib': [{'pos': 'prap', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratilapsyamāna', 'label': 'pratilapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilabdha', 'label': 'pratilabDa', 'attrib': [{'pos': 'ppp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilabdhavat', 'label': 'pratilabDavat', 'attrib': [{'pos': 'pap', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilabdhavya', 'label': 'pratilabDavya', 'attrib': [{'pos': 'ger', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilabdhum', 'label': 'pratilabDum', 'attrib': [{'pos': 'inf', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilabhanīya', 'label': 'pratilaBanIya', 'attrib': [{'pos': 'ger', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilabhamāna', 'label': 'pratilaBamAna', 'attrib': [{'pos': 'prmp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilabhya', 'label': 'pratilaBya', 'attrib': [{'pos': 'abs', 'defs': 'get back, regain'}, {'pos': 'ger', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilabhyamāna', 'label': 'pratilaByamAna', 'attrib': [{'pos': 'prpp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'pratilebhāna', 'label': 'pratileBAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'prativaktavya', 'label': 'prativaktavya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'prativaktum', 'label': 'prativaktum', 'attrib': [{'pos': 'inf', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'prativakṣyat', 'label': 'prativakzyat', 'attrib': [{'pos': 'fap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'prativakṣyamāṇa', 'label': 'prativakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'prativacanīya', 'label': 'prativacanIya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'prativadat', 'label': 'prativadat', 'attrib': [{'pos': 'prap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'prativadanīya', 'label': 'prativadanIya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'prativaditavya', 'label': 'prativaditavya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'prativaditum', 'label': 'prativaditum', 'attrib': [{'pos': 'inf', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'prativadiṣyat', 'label': 'prativadizyat', 'attrib': [{'pos': 'fap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'prativadiṣyamāṇa', 'label': 'prativadizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'prativākya', 'label': 'prativAkya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'prativādya', 'label': 'prativAdya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratiṣṭhamāna', 'label': 'pratizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'pratiṣṭhātavya', 'label': 'pratizWAtavya', 'attrib': [{'pos': 'ger', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratiṣṭhātum', 'label': 'pratizWAtum', 'attrib': [{'pos': 'inf', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratiṣṭhānīya', 'label': 'pratizWAnIya', 'attrib': [{'pos': 'ger', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratiṣṭhāya', 'label': 'pratizWAya', 'attrib': [{'pos': 'abs', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratiṣṭhāsyat', 'label': 'pratizWAsyat', 'attrib': [{'pos': 'fap', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratiṣṭhāsyamāna', 'label': 'pratizWAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratiṣṭhita', 'label': 'pratizWita', 'attrib': [{'pos': 'ppp', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratiṣṭhitavat', 'label': 'pratizWitavat', 'attrib': [{'pos': 'pap', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratiṣṭhīyamāna', 'label': 'pratizWIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratiṣṭheya', 'label': 'pratizWeya', 'attrib': [{'pos': 'ger', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'pratiharaṇīya', 'label': 'pratiharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratiharat', 'label': 'pratiharat', 'attrib': [{'pos': 'prap', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratiharamāṇa', 'label': 'pratiharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihariṣyat', 'label': 'pratiharizyat', 'attrib': [{'pos': 'fap', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihariṣyamāṇa', 'label': 'pratiharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihartavya', 'label': 'pratihartavya', 'attrib': [{'pos': 'ger', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihartum', 'label': 'pratihartum', 'attrib': [{'pos': 'inf', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihārya', 'label': 'pratihArya', 'attrib': [{'pos': 'ger', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihṛta', 'label': 'pratihfta', 'attrib': [{'pos': 'ppp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihṛtavat', 'label': 'pratihftavat', 'attrib': [{'pos': 'pap', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihṛtya', 'label': 'pratihftya', 'attrib': [{'pos': 'abs', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratihriyamāṇa', 'label': 'pratihriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'pratīta', 'label': 'pratIta', 'attrib': [{'pos': 'ppp', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratītavat', 'label': 'pratItavat', 'attrib': [{'pos': 'pap', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratītavya', 'label': 'pratItavya', 'attrib': [{'pos': 'ger', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratītya', 'label': 'pratItya', 'attrib': [{'pos': 'abs', 'defs': 'go back, recognize'}, {'pos': 'ger', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratīyamāna', 'label': 'pratIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratīyāna', 'label': 'pratIyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratīyivas', 'label': 'pratIyivas', 'attrib': [{'pos': 'pfap', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratta', 'label': 'pratta', 'attrib': [{'pos': 'ppp', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pratyabhijajñāna', 'label': 'pratyaBijajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyabhijajñivas', 'label': 'pratyaBijajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyabhijānat', 'label': 'pratyaBijAnat', 'attrib': [{'pos': 'prap', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyabhijñā', 'label': 'pratyaBijYA', 'attrib': [{'pos': 'f', 'defs': 'recognition'}], 'isLemma': 1},
	{'value': 'pratyabhijñāta', 'label': 'pratyaBijYAta', 'attrib': [{'pos': 'ppp', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyabhijñātavat', 'label': 'pratyaBijYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyabhijñātavya', 'label': 'pratyaBijYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyabhijñātum', 'label': 'pratyaBijYAtum', 'attrib': [{'pos': 'inf', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyabhijñānīya', 'label': 'pratyaBijYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyabhijñāya', 'label': 'pratyaBijYAya', 'attrib': [{'pos': 'abs', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyabhijñāyamāna', 'label': 'pratyaBijYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyabhijñāsyat', 'label': 'pratyaBijYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyabhijñāsyamāna', 'label': 'pratyaBijYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyabhijñeya', 'label': 'pratyaBijYeya', 'attrib': [{'pos': 'ger', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'pratyayanīya', 'label': 'pratyayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratyāgacchat', 'label': 'pratyAgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgata', 'label': 'pratyAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgatavat', 'label': 'pratyAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgatya', 'label': 'pratyAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgantavya', 'label': 'pratyAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgantum', 'label': 'pratyAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgamanīya', 'label': 'pratyAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgamiṣyat', 'label': 'pratyAgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgamiṣyamāṇa', 'label': 'pratyAgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgamya', 'label': 'pratyAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}, {'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyāgamyamāna', 'label': 'pratyAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyājaganvas', 'label': 'pratyAjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyājagmāna', 'label': 'pratyAjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyājagmivas', 'label': 'pratyAjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'pratyājahṛvas', 'label': 'pratyAjahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyājahrāṇa', 'label': 'pratyAjahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyānayat', 'label': 'pratyAnayat', 'attrib': [{'pos': 'prap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyānayanīya', 'label': 'pratyAnayanIya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyānayamāna', 'label': 'pratyAnayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyāninīvas', 'label': 'pratyAninIvas', 'attrib': [{'pos': 'pfap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyāninyāna', 'label': 'pratyAninyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyānīta', 'label': 'pratyAnIta', 'attrib': [{'pos': 'ppp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyānītavat', 'label': 'pratyAnItavat', 'attrib': [{'pos': 'pap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyānīya', 'label': 'pratyAnIya', 'attrib': [{'pos': 'abs', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyānīyamāna', 'label': 'pratyAnIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyānetavya', 'label': 'pratyAnetavya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyānetum', 'label': 'pratyAnetum', 'attrib': [{'pos': 'inf', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyāneya', 'label': 'pratyAneya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyāneṣyat', 'label': 'pratyAnezyat', 'attrib': [{'pos': 'fap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyāneṣyamāṇa', 'label': 'pratyAnezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'pratyāyat', 'label': 'pratyAyat', 'attrib': [{'pos': 'prap', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyāyanīya', 'label': 'pratyAyanIya', 'attrib': [{'pos': 'ger', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyāharaṇīya', 'label': 'pratyAharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyāharat', 'label': 'pratyAharat', 'attrib': [{'pos': 'prap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyāharamāṇa', 'label': 'pratyAharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyāhariṣyat', 'label': 'pratyAharizyat', 'attrib': [{'pos': 'fap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyāhariṣyamāṇa', 'label': 'pratyAharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyāhartavya', 'label': 'pratyAhartavya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyāhartum', 'label': 'pratyAhartum', 'attrib': [{'pos': 'inf', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyāhārya', 'label': 'pratyAhArya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyāhṛta', 'label': 'pratyAhfta', 'attrib': [{'pos': 'ppp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyāhṛtavat', 'label': 'pratyAhftavat', 'attrib': [{'pos': 'pap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyāhṛtya', 'label': 'pratyAhftya', 'attrib': [{'pos': 'abs', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyāhriyamāṇa', 'label': 'pratyAhriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'pratyukta', 'label': 'pratyukta', 'attrib': [{'pos': 'ppp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'pratyuktavat', 'label': 'pratyuktavat', 'attrib': [{'pos': 'pap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'pratyucya', 'label': 'pratyucya', 'attrib': [{'pos': 'abs', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'pratyucyamāna', 'label': 'pratyucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'pratyudita', 'label': 'pratyudita', 'attrib': [{'pos': 'ppp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratyuditavat', 'label': 'pratyuditavat', 'attrib': [{'pos': 'pap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratyudya', 'label': 'pratyudya', 'attrib': [{'pos': 'abs', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratyudyamāna', 'label': 'pratyudyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratyūcāna', 'label': 'pratyUcAna', 'attrib': [{'pos': 'pfpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'pratyūcivas', 'label': 'pratyUcivas', 'attrib': [{'pos': 'pfap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'pratyūdāna', 'label': 'pratyUdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratyūdivas', 'label': 'pratyUdivas', 'attrib': [{'pos': 'pfap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'pratyeta', 'label': 'pratyeta', 'attrib': [{'pos': 'ppp', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyetavat', 'label': 'pratyetavat', 'attrib': [{'pos': 'pap', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyetum', 'label': 'pratyetum', 'attrib': [{'pos': 'inf', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratyetya', 'label': 'pratyetya', 'attrib': [{'pos': 'abs', 'defs': 'come back'}, {'pos': 'ger', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyeyamāna', 'label': 'pratyeyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyeyāna', 'label': 'pratyeyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyeyivas', 'label': 'pratyeyivas', 'attrib': [{'pos': 'pfap', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyeṣyat', 'label': 'pratyezyat', 'attrib': [{'pos': 'fap', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratyeṣyamāṇa', 'label': 'pratyezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'pratyaitavya', 'label': 'pratyEtavya', 'attrib': [{'pos': 'ger', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyaitum', 'label': 'pratyEtum', 'attrib': [{'pos': 'inf', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyaiṣyat', 'label': 'pratyEzyat', 'attrib': [{'pos': 'fap', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'pratyaiṣyamāṇa', 'label': 'pratyEzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'prathama', 'label': 'praTama', 'attrib': [{'pos': 'ord_num', 'defs': 'first'}], 'isLemma': 1},
	{'value': 'pradattavat', 'label': 'pradattavat', 'attrib': [{'pos': 'pap', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradadat', 'label': 'pradadat', 'attrib': [{'pos': 'prap', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradadāna', 'label': 'pradadAna', 'attrib': [{'pos': 'prmp', 'defs': 'present, bestow'}, {'pos': 'pfmp, pfpp', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradadivas', 'label': 'pradadivas', 'attrib': [{'pos': 'pfap', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradātavya', 'label': 'pradAtavya', 'attrib': [{'pos': 'ger', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradātum', 'label': 'pradAtum', 'attrib': [{'pos': 'inf', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradānīya', 'label': 'pradAnIya', 'attrib': [{'pos': 'ger', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradāya', 'label': 'pradAya', 'attrib': [{'pos': 'abs', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradāsyat', 'label': 'pradAsyat', 'attrib': [{'pos': 'fap', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradāsyamāna', 'label': 'pradAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradīyamāna', 'label': 'pradIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradeya', 'label': 'pradeya', 'attrib': [{'pos': 'ger', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'pradhāna', 'label': 'praDAna', 'attrib': [{'pos': 'adj', 'defs': 'principal'}], 'isLemma': 1},
	{'value': 'prapatat', 'label': 'prapatat', 'attrib': [{'pos': 'prap', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatanīya', 'label': 'prapatanIya', 'attrib': [{'pos': 'ger', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatita', 'label': 'prapatita', 'attrib': [{'pos': 'ppp', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatitavat', 'label': 'prapatitavat', 'attrib': [{'pos': 'pap', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatitavya', 'label': 'prapatitavya', 'attrib': [{'pos': 'ger', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatitum', 'label': 'prapatitum', 'attrib': [{'pos': 'inf', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatiṣyat', 'label': 'prapatizyat', 'attrib': [{'pos': 'fap', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatiṣyamāṇa', 'label': 'prapatizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatya', 'label': 'prapatya', 'attrib': [{'pos': 'abs', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapatyamāna', 'label': 'prapatyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapātya', 'label': 'prapAtya', 'attrib': [{'pos': 'ger', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapetāna', 'label': 'prapetAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prapetivas', 'label': 'prapetivas', 'attrib': [{'pos': 'pfap', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'prabruvat', 'label': 'prabruvat', 'attrib': [{'pos': 'prap', 'defs': 'say, speak to, proclaim'}], 'isLemma': 0, 'parents': ['pra_brU']},
	{'value': 'prabruvāṇa', 'label': 'prabruvARa', 'attrib': [{'pos': 'prmp', 'defs': 'say, speak to, proclaim'}], 'isLemma': 0, 'parents': ['pra_brU']},
	{'value': 'pramāda', 'label': 'pramAda', 'attrib': [{'pos': 'm', 'defs': 'carelessness, error'}], 'isLemma': 1},
	{'value': 'prayaṁsyat', 'label': 'prayaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayaṁsyamāna', 'label': 'prayaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayacchat', 'label': 'prayacCat', 'attrib': [{'pos': 'prap', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayata', 'label': 'prayata', 'attrib': [{'pos': 'ppp', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayatavat', 'label': 'prayatavat', 'attrib': [{'pos': 'pap', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayatya', 'label': 'prayatya', 'attrib': [{'pos': 'abs', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayantavya', 'label': 'prayantavya', 'attrib': [{'pos': 'ger', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayantum', 'label': 'prayantum', 'attrib': [{'pos': 'inf', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayamaṇīya', 'label': 'prayamaRIya', 'attrib': [{'pos': 'ger', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayamya', 'label': 'prayamya', 'attrib': [{'pos': 'abs', 'defs': 'hold forth, give, offer (acc. to dat.)'}, {'pos': 'ger', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayamyamāna', 'label': 'prayamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayemāna', 'label': 'prayemAna', 'attrib': [{'pos': 'pfpp', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'prayemivas', 'label': 'prayemivas', 'attrib': [{'pos': 'pfap', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'praviviśāna', 'label': 'praviviSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praviviśivas', 'label': 'praviviSivas', 'attrib': [{'pos': 'pfap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praviviśvas', 'label': 'praviviSvas', 'attrib': [{'pos': 'pfap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praviśat', 'label': 'praviSat', 'attrib': [{'pos': 'prap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praviśya', 'label': 'praviSya', 'attrib': [{'pos': 'abs', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praviśyamāna', 'label': 'praviSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praviṣṭa', 'label': 'pravizwa', 'attrib': [{'pos': 'ppp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praviṣṭavat', 'label': 'pravizwavat', 'attrib': [{'pos': 'pap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'pravekṣyat', 'label': 'pravekzyat', 'attrib': [{'pos': 'fap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'pravekṣyamāṇa', 'label': 'pravekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praveśanīya', 'label': 'praveSanIya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praveśya', 'label': 'praveSya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praveṣṭavya', 'label': 'pravezwavya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praveṣṭum', 'label': 'pravezwum', 'attrib': [{'pos': 'inf', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'praṣṭavya', 'label': 'prazwavya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'praṣṭum', 'label': 'prazwum', 'attrib': [{'pos': 'inf', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'prastha', 'label': 'prasTa', 'attrib': [{'pos': 'adj', 'defs': 'setting out or having set out to'}], 'isLemma': 1},
	{'value': 'prasthātavya', 'label': 'prasTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthātum', 'label': 'prasTAtum', 'attrib': [{'pos': 'inf', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthānīya', 'label': 'prasTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthāya', 'label': 'prasTAya', 'attrib': [{'pos': 'abs', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthāsyamāna', 'label': 'prasTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthita', 'label': 'prasTita', 'attrib': [{'pos': 'ppp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthitavat', 'label': 'prasTitavat', 'attrib': [{'pos': 'pap', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prasthīyamāna', 'label': 'prasTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'prastheya', 'label': 'prasTeya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'praharaṇīya', 'label': 'praharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'praharat', 'label': 'praharat', 'attrib': [{'pos': 'prap', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'praharamāṇa', 'label': 'praharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahariṣyat', 'label': 'praharizyat', 'attrib': [{'pos': 'fap', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahariṣyamāṇa', 'label': 'praharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahartavya', 'label': 'prahartavya', 'attrib': [{'pos': 'ger', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahartum', 'label': 'prahartum', 'attrib': [{'pos': 'inf', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahārya', 'label': 'prahArya', 'attrib': [{'pos': 'ger', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahṛta', 'label': 'prahfta', 'attrib': [{'pos': 'ppp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahṛtavat', 'label': 'prahftavat', 'attrib': [{'pos': 'pap', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahṛtya', 'label': 'prahftya', 'attrib': [{'pos': 'abs', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prahriyamāṇa', 'label': 'prahriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'prātar', 'label': 'prAtar', 'attrib': [{'pos': 'adv', 'defs': 'at dawn, in the early morning'}], 'isLemma': 1},
	{'value': 'prāpaṇīya', 'label': 'prApaRIya', 'attrib': [{'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpāṇa', 'label': 'prApARa', 'attrib': [{'pos': 'pfpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpivas', 'label': 'prApivas', 'attrib': [{'pos': 'pfap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpta', 'label': 'prApta', 'attrib': [{'pos': 'ppp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāptavat', 'label': 'prAptavat', 'attrib': [{'pos': 'pap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāptavya', 'label': 'prAptavya', 'attrib': [{'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāptum', 'label': 'prAptum', 'attrib': [{'pos': 'inf', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpnuvat', 'label': 'prApnuvat', 'attrib': [{'pos': 'prap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpya', 'label': 'prApya', 'attrib': [{'pos': 'abs', 'defs': 'attain, obtain'}, {'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpyamāna', 'label': 'prApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpsyat', 'label': 'prApsyat', 'attrib': [{'pos': 'fap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'prāpsyamāna', 'label': 'prApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'priya', 'label': 'priya', 'attrib': [{'pos': 'adj', 'defs': 'dear'}], 'isLemma': 1},
	{'value': 'plavanīya', 'label': 'plavanIya', 'attrib': [{'pos': 'ger', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plavamāna', 'label': 'plavamAna', 'attrib': [{'pos': 'prmp', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plavitavya', 'label': 'plavitavya', 'attrib': [{'pos': 'ger', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plavitum', 'label': 'plavitum', 'attrib': [{'pos': 'inf', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plavya', 'label': 'plavya', 'attrib': [{'pos': 'ger', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plu', 'label': 'plu', 'attrib': [{'pos': 'vt1m', 'defs': 'float, swim'}], 'isLemma': 1},
	{'value': 'pluta', 'label': 'pluta', 'attrib': [{'pos': 'ppp', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plutavat', 'label': 'plutavat', 'attrib': [{'pos': 'pap', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'plutvā', 'label': 'plutvA', 'attrib': [{'pos': 'abs', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'ploṣyamāṇa', 'label': 'plozyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'phala', 'label': 'Pala', 'attrib': [{'pos': 'n', 'defs': 'fruit'}], 'isLemma': 1},
	{'value': 'phalavat', 'label': 'Palavat', 'attrib': [{'pos': 'adj', 'defs': 'fruitful, successful'}], 'isLemma': 1},
	{'value': 'baddha', 'label': 'badDa', 'attrib': [{'pos': 'ppp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'baddhavat', 'label': 'badDavat', 'attrib': [{'pos': 'pap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'baddhvā', 'label': 'badDvA', 'attrib': [{'pos': 'abs', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'badhnat', 'label': 'baDnat', 'attrib': [{'pos': 'prap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'badhyamāna', 'label': 'baDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'banddhavya', 'label': 'bandDavya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'banddhum', 'label': 'bandDum', 'attrib': [{'pos': 'inf', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'bandh', 'label': 'banD', 'attrib': [{'pos': 'vt9am', 'defs': 'bind'}], 'isLemma': 1},
	{'value': 'bandhanīya', 'label': 'banDanIya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'bandhya', 'label': 'banDya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'babandhāna', 'label': 'babanDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'babandhvas', 'label': 'babanDvas', 'attrib': [{'pos': 'pfap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'babhāṣāṇa', 'label': 'baBAzARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'babhūvas', 'label': 'baBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'babhṛvas', 'label': 'baBfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'babhrāṇa', 'label': 'baBrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bala', 'label': 'bala', 'attrib': [{'pos': 'n', 'defs': 'strength'}], 'isLemma': 1},
	{'value': 'balavat', 'label': 'balavat', 'attrib': [{'pos': 'adj', 'defs': 'strong'}], 'isLemma': 1},
	{'value': 'balin', 'label': 'balin', 'attrib': [{'pos': 'adj', 'defs': 'strong'}], 'isLemma': 1},
	{'value': 'bahu', 'label': 'bahu', 'attrib': [{'pos': 'adj', 'defs': 'much, many, abundant'}, {'pos': 'adv', 'defs': 'much'}], 'isLemma': 1},
	{'value': 'bāla', 'label': 'bAla', 'attrib': [{'pos': 'm', 'defs': 'boy, child'}], 'isLemma': 1},
	{'value': 'bibhayām', 'label': 'biBayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bibharām', 'label': 'biBarAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bibhidāna', 'label': 'biBidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bibhidvas', 'label': 'biBidvas', 'attrib': [{'pos': 'pfap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bibhīvas', 'label': 'biBIvas', 'attrib': [{'pos': 'pfap', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bibhyat', 'label': 'biByat', 'attrib': [{'pos': 'prap', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bibhyāna', 'label': 'biByAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bibhrat', 'label': 'biBrat', 'attrib': [{'pos': 'prap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bibhrāṇa', 'label': 'biBrARa', 'attrib': [{'pos': 'prmp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'buddha', 'label': 'budDa', 'attrib': [{'pos': 'ppp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buddhavat', 'label': 'budDavat', 'attrib': [{'pos': 'pap', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'buddhi', 'label': 'budDi', 'attrib': [{'pos': 'f', 'defs': 'intelligence, intellect'}], 'isLemma': 1},
	{'value': 'buddhvā', 'label': 'budDvA', 'attrib': [{'pos': 'abs', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'budh', 'label': 'buD', 'attrib': [{'pos': 'vt1am', 'defs': 'know'}, {'pos': 'vt4m', 'defs': 'be aware, know'}], 'isLemma': 1},
	{'value': 'budhita', 'label': 'buDita', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'budhitavat', 'label': 'buDitavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'budhitvā', 'label': 'buDitvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'budhyamāna', 'label': 'buDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}, {'pos': 'prmp', 'defs': 'be aware, know'}, {'pos': 'prpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bubudhāna', 'label': 'bubuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'know'}, {'pos': 'pfmp, pfpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bubudhvas', 'label': 'bubuDvas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bubhujāna', 'label': 'buBujAna', 'attrib': [{'pos': 'pfpp', 'defs': 'protect'}, {'pos': 'pfmp, pfpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bubhujvas', 'label': 'buBujvas', 'attrib': [{'pos': 'pfap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'boddhavya', 'label': 'bodDavya', 'attrib': [{'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'boddhum', 'label': 'bodDum', 'attrib': [{'pos': 'inf', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhat', 'label': 'boDat', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhanīya', 'label': 'boDanIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhamāna', 'label': 'boDamAna', 'attrib': [{'pos': 'prmp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhitavya', 'label': 'boDitavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhitum', 'label': 'boDitum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhitvā', 'label': 'boDitvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bodhya', 'label': 'boDya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'brāhmaṇa', 'label': 'brAhmaRa', 'attrib': [{'pos': 'm', 'defs': 'brahmin'}], 'isLemma': 1},
	{'value': 'bruvat', 'label': 'bruvat', 'attrib': [{'pos': 'prap', 'defs': 'say, tell'}], 'isLemma': 0, 'parents': ['brU']},
	{'value': 'bruvāṇa', 'label': 'bruvARa', 'attrib': [{'pos': 'prmp', 'defs': 'say, tell'}], 'isLemma': 0, 'parents': ['brU']},
	{'value': 'brū', 'label': 'brU', 'attrib': [{'pos': 'vt2am', 'defs': 'say, tell'}], 'isLemma': 1},
	{'value': 'bhakṣ', 'label': 'Bakz', 'attrib': [{'pos': 'vt10a', 'defs': 'eat'}], 'isLemma': 1},
	{'value': 'bhakṣaṇīya', 'label': 'BakzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'bhakṣayat', 'label': 'Bakzayat', 'attrib': [{'pos': 'prap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'bhakṣayām', 'label': 'BakzayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'bhakṣayitum', 'label': 'Bakzayitum', 'attrib': [{'pos': 'inf', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'bhakṣayitvā', 'label': 'BakzayitvA', 'attrib': [{'pos': 'abs', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'bhakṣayiṣyat', 'label': 'Bakzayizyat', 'attrib': [{'pos': 'fap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'bhakṣayiṣyamāṇa', 'label': 'BakzayizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'bhakṣita', 'label': 'Bakzita', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'bhakṣitavat', 'label': 'Bakzitavat', 'attrib': [{'pos': 'pap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'bhakṣitavya', 'label': 'Bakzitavya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'bhakṣya', 'label': 'Bakzya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'bhakṣyamāṇa', 'label': 'BakzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'bhantsyat', 'label': 'Bantsyat', 'attrib': [{'pos': 'fap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'bhantsyamāna', 'label': 'BantsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'bhaya', 'label': 'Baya', 'attrib': [{'pos': 'n', 'defs': 'fear'}], 'isLemma': 1},
	{'value': 'bhayanīya', 'label': 'BayanIya', 'attrib': [{'pos': 'ger', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bharaṇīya', 'label': 'BaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bharata', 'label': 'Barata', 'attrib': [{'pos': 'm_pn', 'defs': 'Bharata or his descendants'}], 'isLemma': 1},
	{'value': 'bhariṣyat', 'label': 'Barizyat', 'attrib': [{'pos': 'fap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhariṣyamāṇa', 'label': 'BarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhartavya', 'label': 'Bartavya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhartum', 'label': 'Bartum', 'attrib': [{'pos': 'inf', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhavat', 'label': 'Bavat', 'attrib': [{'pos': 'pers_pron', 'defs': 'your lord/ladyship, sir/madam, you'}, {'pos': 'prap', 'defs': 'be, become'}], 'isLemma': 1},
	{'value': 'bhavat', 'label': 'Bavat', 'attrib': [{'pos': 'pers_pron', 'defs': 'your lord/ladyship, sir/madam, you'}, {'pos': 'prap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhavanīya', 'label': 'BavanIya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhavitavya', 'label': 'Bavitavya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhavitum', 'label': 'Bavitum', 'attrib': [{'pos': 'inf', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhaviṣyat', 'label': 'Bavizyat', 'attrib': [{'pos': 'fap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhaviṣyamāṇa', 'label': 'BavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhavya', 'label': 'Bavya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhāṣ', 'label': 'BAz', 'attrib': [{'pos': 'vt1m', 'defs': 'speak, say'}], 'isLemma': 1},
	{'value': 'bhāṣaṇīya', 'label': 'BAzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣamāṇa', 'label': 'BAzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣita', 'label': 'BAzita', 'attrib': [{'pos': 'ppp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣitavat', 'label': 'BAzitavat', 'attrib': [{'pos': 'pap', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣitavya', 'label': 'BAzitavya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣitum', 'label': 'BAzitum', 'attrib': [{'pos': 'inf', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣitvā', 'label': 'BAzitvA', 'attrib': [{'pos': 'abs', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣiṣyamāṇa', 'label': 'BAzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣya', 'label': 'BAzya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhāṣyamāṇa', 'label': 'BAzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'bhittvā', 'label': 'BittvA', 'attrib': [{'pos': 'abs', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhid', 'label': 'Bid', 'attrib': [{'pos': 'vt7am', 'defs': 'split'}], 'isLemma': 1},
	{'value': 'bhidyamāna', 'label': 'BidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhindat', 'label': 'Bindat', 'attrib': [{'pos': 'prap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhindāna', 'label': 'BindAna', 'attrib': [{'pos': 'prmp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhinna', 'label': 'Binna', 'attrib': [{'pos': 'ppp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhinnavat', 'label': 'Binnavat', 'attrib': [{'pos': 'pap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhī', 'label': 'BI', 'attrib': [{'pos': 'vi3a', 'defs': 'fear (from abl.)'}], 'isLemma': 1},
	{'value': 'bhīta', 'label': 'BIta', 'attrib': [{'pos': 'ppp', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhītavat', 'label': 'BItavat', 'attrib': [{'pos': 'pap', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhītvā', 'label': 'BItvA', 'attrib': [{'pos': 'abs', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhīyamāna', 'label': 'BIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhīru', 'label': 'BIru', 'attrib': [{'pos': 'adj', 'defs': 'timid'}], 'isLemma': 1},
	{'value': 'bhukta', 'label': 'Bukta', 'attrib': [{'pos': 'ppp', 'defs': 'protect'}, {'pos': 'ppp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bhuktavat', 'label': 'Buktavat', 'attrib': [{'pos': 'pap', 'defs': 'protect'}, {'pos': 'pap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bhuktvā', 'label': 'BuktvA', 'attrib': [{'pos': 'abs', 'defs': 'protect'}, {'pos': 'abs', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bhuj', 'label': 'Buj', 'attrib': [{'pos': 'vt7a', 'defs': 'protect'}, {'pos': 'vt7m', 'defs': 'eat'}], 'isLemma': 1},
	{'value': 'bhujyamāna', 'label': 'BujyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'protect'}, {'pos': 'prpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bhuñjat', 'label': 'BuYjat', 'attrib': [{'pos': 'prap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bhuñjāna', 'label': 'BuYjAna', 'attrib': [{'pos': 'prmp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bhū', 'label': 'BU', 'attrib': [{'pos': 'f', 'defs': 'earth'}, {'pos': 'vi1a', 'defs': 'be, become'}], 'isLemma': 1},
	{'value': 'bhūta', 'label': 'BUta', 'attrib': [{'pos': 'ppp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhūtavat', 'label': 'BUtavat', 'attrib': [{'pos': 'pap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhūtvā', 'label': 'BUtvA', 'attrib': [{'pos': 'abs', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhūyamāna', 'label': 'BUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'bhṛ', 'label': 'Bf', 'attrib': [{'pos': 'vt3am', 'defs': 'bear'}], 'isLemma': 1},
	{'value': 'bhṛta', 'label': 'Bfta', 'attrib': [{'pos': 'ppp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhṛtavat', 'label': 'Bftavat', 'attrib': [{'pos': 'pap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhṛtya', 'label': 'Bftya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhṛtvā', 'label': 'BftvA', 'attrib': [{'pos': 'abs', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhetavya', 'label': 'Betavya', 'attrib': [{'pos': 'ger', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhetum', 'label': 'Betum', 'attrib': [{'pos': 'inf', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhettavya', 'label': 'Bettavya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhettum', 'label': 'Bettum', 'attrib': [{'pos': 'inf', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhetsyat', 'label': 'Betsyat', 'attrib': [{'pos': 'fap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhetsyamāna', 'label': 'BetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhedanīya', 'label': 'BedanIya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bhedya', 'label': 'Bedya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'bheya', 'label': 'Beya', 'attrib': [{'pos': 'ger', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bheṣyat', 'label': 'Bezyat', 'attrib': [{'pos': 'fap', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bheṣyamāṇa', 'label': 'BezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'bhoktavya', 'label': 'Boktavya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}, {'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bhoktum', 'label': 'Boktum', 'attrib': [{'pos': 'inf', 'defs': 'protect'}, {'pos': 'inf', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bhokṣyat', 'label': 'Bokzyat', 'attrib': [{'pos': 'fap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bhokṣyamāṇa', 'label': 'BokzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'protect'}, {'pos': 'fmp, fpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bhogya', 'label': 'Bogya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}, {'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bhojana', 'label': 'Bojana', 'attrib': [{'pos': 'n', 'defs': 'eating, a meal'}], 'isLemma': 1},
	{'value': 'bhojanīya', 'label': 'BojanIya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}, {'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'bhotsyat', 'label': 'Botsyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bhotsyamāna', 'label': 'BotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'know'}, {'pos': 'fmp, fpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'bhrātṛ', 'label': 'BrAtf', 'attrib': [{'pos': 'm', 'defs': 'brother'}], 'isLemma': 1},
	{'value': 'bhriyamāṇa', 'label': 'BriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'bhrū', 'label': 'BrU', 'attrib': [{'pos': 'f', 'defs': 'eyebrow'}], 'isLemma': 1},
	{'value': 'maṁsyamāna', 'label': 'maMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mata', 'label': 'mata', 'attrib': [{'pos': 'ppp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'matavat', 'label': 'matavat', 'attrib': [{'pos': 'pap', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mati', 'label': 'mati', 'attrib': [{'pos': 'f', 'defs': 'thought'}], 'isLemma': 1},
	{'value': 'matta', 'label': 'matta', 'attrib': [{'pos': 'ppp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'mattavat', 'label': 'mattavat', 'attrib': [{'pos': 'pap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'matvā', 'label': 'matvA', 'attrib': [{'pos': 'abs', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mad', 'label': 'mad', 'attrib': [{'pos': 'vi4a', 'defs': 'rejoice'}], 'isLemma': 1},
	{'value': 'madanīya', 'label': 'madanIya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maditavya', 'label': 'maditavya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maditum', 'label': 'maditum', 'attrib': [{'pos': 'inf', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'maditvā', 'label': 'maditvA', 'attrib': [{'pos': 'abs', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madiṣyat', 'label': 'madizyat', 'attrib': [{'pos': 'fap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madiṣyamāṇa', 'label': 'madizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madya', 'label': 'madya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madyamāna', 'label': 'madyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'madhu', 'label': 'maDu', 'attrib': [{'pos': 'adj', 'defs': 'sweet'}, {'pos': 'n', 'defs': 'honey'}], 'isLemma': 1},
	{'value': 'madhya', 'label': 'maDya', 'attrib': [{'pos': 'adj', 'defs': 'middle'}, {'pos': 'n', 'defs': 'middle, waist'}], 'isLemma': 1},
	{'value': 'man', 'label': 'man', 'attrib': [{'pos': 'vt4m, vt8m', 'defs': 'think'}], 'isLemma': 1},
	{'value': 'mananīya', 'label': 'mananIya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'manas', 'label': 'manas', 'attrib': [{'pos': 'n', 'defs': 'mind'}], 'isLemma': 1},
	{'value': 'manuṣya', 'label': 'manuzya', 'attrib': [{'pos': 'm', 'defs': 'human being'}], 'isLemma': 1},
	{'value': 'mantavya', 'label': 'mantavya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mantum', 'label': 'mantum', 'attrib': [{'pos': 'inf', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'manyamāna', 'label': 'manyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'think'}, {'pos': 'prpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'manvāna', 'label': 'manvAna', 'attrib': [{'pos': 'prmp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mamṛvas', 'label': 'mamfvas', 'attrib': [{'pos': 'pfap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mamrāṇa', 'label': 'mamrARa', 'attrib': [{'pos': 'pfpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'maraṇīya', 'label': 'maraRIya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mariṣyat', 'label': 'marizyat', 'attrib': [{'pos': 'fap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mariṣyamāṇa', 'label': 'marizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'martavya', 'label': 'martavya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'martum', 'label': 'martum', 'attrib': [{'pos': 'inf', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mahat', 'label': 'mahat', 'attrib': [{'pos': 'adj', 'defs': 'great'}], 'isLemma': 1},
	{'value': 'mahābhārata', 'label': 'mahABArata', 'attrib': [{'pos': 'n_pn', 'defs': 'the <i>Mahābhārata</i>'}], 'isLemma': 1},
	{'value': 'mahāyaśas', 'label': 'mahAyaSas', 'attrib': [{'pos': 'adj', 'defs': 'whose glory is great, very glorious'}], 'isLemma': 1},
	{'value': 'mā', 'label': 'mA', 'attrib': [{'pos': 'neg_pcl', 'defs': "don't"}], 'isLemma': 1},
	{'value': 'mātṛ', 'label': 'mAtf', 'attrib': [{'pos': 'f', 'defs': 'mother'}], 'isLemma': 1},
	{'value': 'mādyat', 'label': 'mAdyat', 'attrib': [{'pos': 'prap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'mānya', 'label': 'mAnya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'mārya', 'label': 'mArya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mālā', 'label': 'mAlA', 'attrib': [{'pos': 'f', 'defs': 'garland'}], 'isLemma': 1},
	{'value': 'māsa', 'label': 'mAsa', 'attrib': [{'pos': 'm', 'defs': 'moon, month'}], 'isLemma': 1},
	{'value': 'mukta', 'label': 'mukta', 'attrib': [{'pos': 'ppp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muktavat', 'label': 'muktavat', 'attrib': [{'pos': 'pap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muktvā', 'label': 'muktvA', 'attrib': [{'pos': 'abs', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mukha', 'label': 'muKa', 'attrib': [{'pos': 'n', 'defs': 'mouth, face'}], 'isLemma': 1},
	{'value': 'muc', 'label': 'muc', 'attrib': [{'pos': 'vt6am', 'defs': 'release'}], 'isLemma': 1},
	{'value': 'mucyamāna', 'label': 'mucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muñcat', 'label': 'muYcat', 'attrib': [{'pos': 'prap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muñcamāna', 'label': 'muYcamAna', 'attrib': [{'pos': 'prmp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mumucāna', 'label': 'mumucAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mumucvas', 'label': 'mumucvas', 'attrib': [{'pos': 'pfap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'muhūrta', 'label': 'muhUrta', 'attrib': [{'pos': 'm, n', 'defs': '1/30th of a day, 48 minutes, hour'}], 'isLemma': 1},
	{'value': 'mṛ', 'label': 'mf', 'attrib': [{'pos': 'vi6am', 'defs': 'die'}], 'isLemma': 1},
	{'value': 'mṛga', 'label': 'mfga', 'attrib': [{'pos': 'm', 'defs': 'deer'}], 'isLemma': 1},
	{'value': 'mṛta', 'label': 'mfta', 'attrib': [{'pos': 'ppp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mṛtavat', 'label': 'mftavat', 'attrib': [{'pos': 'pap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mṛtvā', 'label': 'mftvA', 'attrib': [{'pos': 'abs', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'mṛdu', 'label': 'mfdu', 'attrib': [{'pos': 'adj', 'defs': 'soft'}], 'isLemma': 1},
	{'value': 'medāna', 'label': 'medAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'medivas', 'label': 'medivas', 'attrib': [{'pos': 'pfap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'menāna', 'label': 'menAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'moktavya', 'label': 'moktavya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'moktum', 'label': 'moktum', 'attrib': [{'pos': 'inf', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mokṣyat', 'label': 'mokzyat', 'attrib': [{'pos': 'fap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mokṣyamāṇa', 'label': 'mokzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mocanīya', 'label': 'mocanIya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mocya', 'label': 'mocya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'mriyamāṇa', 'label': 'mriyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'die'}, {'pos': 'prpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'yaṁsyat', 'label': 'yaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yaṁsyamāna', 'label': 'yaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yakṣyat', 'label': 'yakzyat', 'attrib': [{'pos': 'fap', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yakṣyamāṇa', 'label': 'yakzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yacchat', 'label': 'yacCat', 'attrib': [{'pos': 'prap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yaj', 'label': 'yaj', 'attrib': [{'pos': 'vt1am', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 1},
	{'value': 'yajat', 'label': 'yajat', 'attrib': [{'pos': 'prap', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yajanīya', 'label': 'yajanIya', 'attrib': [{'pos': 'ger', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yajamāna', 'label': 'yajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'worship, perform a Vedic ceremony'}, {'pos': 'm', 'defs': 'yajamāna , worshipper, performer and sponsor of a Vedic ceremony'}], 'isLemma': 1},
	{'value': 'yajamāna', 'label': 'yajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'worship, perform a Vedic ceremony'}, {'pos': 'm', 'defs': 'yajamāna , worshipper, performer and sponsor of a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yajurveda', 'label': 'yajurveda', 'attrib': [{'pos': 'm', 'defs': '<i>Yajurveda</i>'}], 'isLemma': 1},
	{'value': 'yajus', 'label': 'yajus', 'attrib': [{'pos': 'n', 'defs': 'mantra of the <i>Yajurveda</i>'}], 'isLemma': 1},
	{'value': 'yajña', 'label': 'yajYa', 'attrib': [{'pos': 'm', 'defs': 'worship, ceremony'}], 'isLemma': 1},
	{'value': 'yat', 'label': 'yat', 'attrib': [{'pos': 'prap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'yata', 'label': 'yata', 'attrib': [{'pos': 'ppp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yatavat', 'label': 'yatavat', 'attrib': [{'pos': 'pap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yatas', 'label': 'yatas', 'attrib': [{'pos': 'rel_adv', 'defs': 'whence, because'}], 'isLemma': 1},
	{'value': 'yatra', 'label': 'yatra', 'attrib': [{'pos': 'rel_adv', 'defs': 'where'}], 'isLemma': 1},
	{'value': 'yatvā', 'label': 'yatvA', 'attrib': [{'pos': 'abs', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yathā', 'label': 'yaTA', 'attrib': [{'pos': 'rel_adv', 'defs': 'in which manner, as'}], 'isLemma': 1},
	{'value': 'yad', 'label': 'yad', 'attrib': [{'pos': 'rel_pron, rel_adj', 'defs': 'which/who/what'}], 'isLemma': 1},
	{'value': 'yadā', 'label': 'yadA', 'attrib': [{'pos': 'rel_adv', 'defs': 'at which time, when'}], 'isLemma': 1},
	{'value': 'yadi', 'label': 'yadi', 'attrib': [{'pos': 'rel_adv', 'defs': 'if'}], 'isLemma': 1},
	{'value': 'yantavya', 'label': 'yantavya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yantum', 'label': 'yantum', 'attrib': [{'pos': 'inf', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yam', 'label': 'yam', 'attrib': [{'pos': 'vt1a', 'defs': 'hold'}], 'isLemma': 1},
	{'value': 'yamanīya', 'label': 'yamanIya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yamya', 'label': 'yamya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yamyamāna', 'label': 'yamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yaśas', 'label': 'yaSas', 'attrib': [{'pos': 'n', 'defs': 'glory'}], 'isLemma': 1},
	{'value': 'yaṣṭavya', 'label': 'yazwavya', 'attrib': [{'pos': 'ger', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yaṣṭum', 'label': 'yazwum', 'attrib': [{'pos': 'inf', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yājya', 'label': 'yAjya', 'attrib': [{'pos': 'ger', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'yāvat', 'label': 'yAvat', 'attrib': [{'pos': 'rel_adj', 'defs': 'of which extent'}, {'pos': 'rel_adv', 'defs': 'to which extent'}], 'isLemma': 1},
	{'value': 'yukta', 'label': 'yukta', 'attrib': [{'pos': 'ppp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuktavat', 'label': 'yuktavat', 'attrib': [{'pos': 'pap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuktvā', 'label': 'yuktvA', 'attrib': [{'pos': 'abs', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuj', 'label': 'yuj', 'attrib': [{'pos': 'vt7am', 'defs': 'yoke, join, unite'}], 'isLemma': 1},
	{'value': 'yujyamāna', 'label': 'yujyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuñjat', 'label': 'yuYjat', 'attrib': [{'pos': 'prap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuñjāna', 'label': 'yuYjAna', 'attrib': [{'pos': 'prmp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuddha', 'label': 'yudDa', 'attrib': [{'pos': 'ppp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuddhavat', 'label': 'yudDavat', 'attrib': [{'pos': 'pap', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuddhvā', 'label': 'yudDvA', 'attrib': [{'pos': 'abs', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yudh', 'label': 'yuD', 'attrib': [{'pos': 'vt4m', 'defs': 'fight'}], 'isLemma': 1},
	{'value': 'yudhiṣṭhira', 'label': 'yuDizWira', 'attrib': [{'pos': 'm_pn', 'defs': 'Yudhiṣṭhira'}], 'isLemma': 1},
	{'value': 'yudhiṣṭhirārjuna', 'label': 'yuDizWirArjuna', 'attrib': [{'pos': 'm', 'defs': 'Yudhiṣṭhira and Arjuna'}], 'isLemma': 1},
	{'value': 'yudhyamāna', 'label': 'yuDyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'fight'}, {'pos': 'prpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuyujāna', 'label': 'yuyujAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuyujvas', 'label': 'yuyujvas', 'attrib': [{'pos': 'pfap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yuyudhāna', 'label': 'yuyuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yuṣmad', 'label': 'yuzmad', 'attrib': [{'pos': 'pers_pron', 'defs': 'you'}], 'isLemma': 1},
	{'value': 'yūpa', 'label': 'yUpa', 'attrib': [{'pos': 'm', 'defs': 'sacrificial post'}], 'isLemma': 1},
	{'value': 'yemāna', 'label': 'yemAna', 'attrib': [{'pos': 'pfpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yemivas', 'label': 'yemivas', 'attrib': [{'pos': 'pfap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'yoktavya', 'label': 'yoktavya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yoktum', 'label': 'yoktum', 'attrib': [{'pos': 'inf', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yokṣyat', 'label': 'yokzyat', 'attrib': [{'pos': 'fap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yokṣyamāṇa', 'label': 'yokzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yogya', 'label': 'yogya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yojanīya', 'label': 'yojanIya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'yotsyamāna', 'label': 'yotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yoddhavya', 'label': 'yodDavya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yoddhum', 'label': 'yodDum', 'attrib': [{'pos': 'inf', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yodhanīya', 'label': 'yoDanIya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'yodhya', 'label': 'yoDya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'raṁsyamāna', 'label': 'raMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'rajju', 'label': 'rajju', 'attrib': [{'pos': 'f', 'defs': 'rope'}], 'isLemma': 1},
	{'value': 'raṇa', 'label': 'raRa', 'attrib': [{'pos': 'n', 'defs': 'battle'}], 'isLemma': 1},
	{'value': 'rata', 'label': 'rata', 'attrib': [{'pos': 'ppp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ratavat', 'label': 'ratavat', 'attrib': [{'pos': 'pap', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ratna', 'label': 'ratna', 'attrib': [{'pos': 'n', 'defs': 'gift, gem'}], 'isLemma': 1},
	{'value': 'ratvā', 'label': 'ratvA', 'attrib': [{'pos': 'abs', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ratha', 'label': 'raTa', 'attrib': [{'pos': 'm', 'defs': 'chariot'}], 'isLemma': 1},
	{'value': 'rantavya', 'label': 'rantavya', 'attrib': [{'pos': 'ger', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'rantum', 'label': 'rantum', 'attrib': [{'pos': 'inf', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ram', 'label': 'ram', 'attrib': [{'pos': 'vi1m', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 1},
	{'value': 'ramaṇīya', 'label': 'ramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ramamāṇa', 'label': 'ramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ramya', 'label': 'ramya', 'attrib': [{'pos': 'ger', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'ramyamāṇa', 'label': 'ramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'rājan', 'label': 'rAjan', 'attrib': [{'pos': 'm', 'defs': 'king'}], 'isLemma': 1},
	{'value': 'rājya', 'label': 'rAjya', 'attrib': [{'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 1},
	{'value': 'rātri', 'label': 'rAtri', 'attrib': [{'pos': 'f', 'defs': 'night'}], 'isLemma': 1},
	{'value': 'rāmāyaṇa', 'label': 'rAmAyaRa', 'attrib': [{'pos': 'n_pn', 'defs': 'the <i>Rāmāyana</i>'}], 'isLemma': 1},
	{'value': 'rāṣṭra', 'label': 'rAzwra', 'attrib': [{'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 1},
	{'value': 'ruddha', 'label': 'rudDa', 'attrib': [{'pos': 'ppp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'ruddhavat', 'label': 'rudDavat', 'attrib': [{'pos': 'pap', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'ruddhvā', 'label': 'rudDvA', 'attrib': [{'pos': 'abs', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rudh', 'label': 'ruD', 'attrib': [{'pos': 'vt7am', 'defs': 'obstruct'}], 'isLemma': 1},
	{'value': 'rudhyamāna', 'label': 'ruDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rundhat', 'label': 'runDat', 'attrib': [{'pos': 'prap', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rundhāna', 'label': 'runDAna', 'attrib': [{'pos': 'prmp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rurudhāna', 'label': 'ruruDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rurudhvas', 'label': 'ruruDvas', 'attrib': [{'pos': 'pfap', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'remāṇa', 'label': 'remARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'roga', 'label': 'roga', 'attrib': [{'pos': 'm', 'defs': 'illness'}], 'isLemma': 1},
	{'value': 'rotsyat', 'label': 'rotsyat', 'attrib': [{'pos': 'fap', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rotsyamāna', 'label': 'rotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'roddhavya', 'label': 'rodDavya', 'attrib': [{'pos': 'ger', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'roddhum', 'label': 'rodDum', 'attrib': [{'pos': 'inf', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rodhanīya', 'label': 'roDanIya', 'attrib': [{'pos': 'ger', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'rodhya', 'label': 'roDya', 'attrib': [{'pos': 'ger', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'lapsyamāna', 'label': 'lapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labdha', 'label': 'labDa', 'attrib': [{'pos': 'ppp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labdhavat', 'label': 'labDavat', 'attrib': [{'pos': 'pap', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labdhavya', 'label': 'labDavya', 'attrib': [{'pos': 'ger', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labdhum', 'label': 'labDum', 'attrib': [{'pos': 'inf', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labdhvā', 'label': 'labDvA', 'attrib': [{'pos': 'abs', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labh', 'label': 'laB', 'attrib': [{'pos': 'vt1m', 'defs': 'grab, get, obtain'}], 'isLemma': 1},
	{'value': 'labhanīya', 'label': 'laBanIya', 'attrib': [{'pos': 'ger', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labhamāna', 'label': 'laBamAna', 'attrib': [{'pos': 'prmp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labhya', 'label': 'laBya', 'attrib': [{'pos': 'ger', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'labhyamāna', 'label': 'laByamAna', 'attrib': [{'pos': 'prpp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'lebhāna', 'label': 'leBAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'loka', 'label': 'loka', 'attrib': [{'pos': 'm', 'defs': 'region, world'}], 'isLemma': 1},
	{'value': 'vaktavya', 'label': 'vaktavya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vaktum', 'label': 'vaktum', 'attrib': [{'pos': 'inf', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vakṣyat', 'label': 'vakzyat', 'attrib': [{'pos': 'fap', 'defs': 'say, speak'}, {'pos': 'fap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vac', 'vah']},
	{'value': 'vakṣyamāṇa', 'label': 'vakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'say, speak'}, {'pos': 'fmp, fpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vac', 'vah']},
	{'value': 'vac', 'label': 'vac', 'attrib': [{'pos': 'vt2a', 'defs': 'say, speak'}], 'isLemma': 1},
	{'value': 'vacana', 'label': 'vacana', 'attrib': [{'pos': 'n', 'defs': 'speech, word'}], 'isLemma': 1},
	{'value': 'vacanīya', 'label': 'vacanIya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vatsyat', 'label': 'vatsyat', 'attrib': [{'pos': 'fap', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vatsyamāna', 'label': 'vatsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vad', 'label': 'vad', 'attrib': [{'pos': 'vt1a', 'defs': 'tell'}], 'isLemma': 1},
	{'value': 'vadat', 'label': 'vadat', 'attrib': [{'pos': 'prap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadanīya', 'label': 'vadanIya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vaditavya', 'label': 'vaditavya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vaditum', 'label': 'vaditum', 'attrib': [{'pos': 'inf', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadiṣyat', 'label': 'vadizyat', 'attrib': [{'pos': 'fap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadiṣyamāṇa', 'label': 'vadizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vadhū', 'label': 'vaDU', 'attrib': [{'pos': 'f', 'defs': 'bride, wife'}], 'isLemma': 1},
	{'value': 'vana', 'label': 'vana', 'attrib': [{'pos': 'n', 'defs': 'forest'}], 'isLemma': 1},
	{'value': 'vara', 'label': 'vara', 'attrib': [{'pos': 'adj', 'defs': 'choice, excellent'}, {'pos': 'm', 'defs': 'boon, bridegroom'}], 'isLemma': 1},
	{'value': 'varaṇīya', 'label': 'varaRIya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varitavya', 'label': 'varitavya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varitum', 'label': 'varitum', 'attrib': [{'pos': 'inf', 'defs': 'cover'}, {'pos': 'inf', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'variṣyat', 'label': 'varizyat', 'attrib': [{'pos': 'fap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'variṣyamāṇa', 'label': 'varizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover'}, {'pos': 'fmp, fpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varītavya', 'label': 'varItavya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varītum', 'label': 'varItum', 'attrib': [{'pos': 'inf', 'defs': 'cover'}, {'pos': 'inf', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varīṣyat', 'label': 'varIzyat', 'attrib': [{'pos': 'fap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varīṣyamāṇa', 'label': 'varIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover'}, {'pos': 'fmp, fpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'varuṇa', 'label': 'varuRa', 'attrib': [{'pos': 'm_pn', 'defs': 'Varuṇa'}], 'isLemma': 1},
	{'value': 'vartanīya', 'label': 'vartanIya', 'attrib': [{'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartamāna', 'label': 'vartamAna', 'attrib': [{'pos': 'prmp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartitavya', 'label': 'vartitavya', 'attrib': [{'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartitum', 'label': 'vartitum', 'attrib': [{'pos': 'inf', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartitvā', 'label': 'vartitvA', 'attrib': [{'pos': 'abs', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vartiṣyamāṇa', 'label': 'vartizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'varṣa', 'label': 'varza', 'attrib': [{'pos': 'm', 'defs': 'rain, year'}], 'isLemma': 1},
	{'value': 'vavṛtāna', 'label': 'vavftAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vavṛvas', 'label': 'vavfvas', 'attrib': [{'pos': 'pfap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vavrajāna', 'label': 'vavrajAna', 'attrib': [{'pos': 'pfpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vavrajvas', 'label': 'vavrajvas', 'attrib': [{'pos': 'pfap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vavrāṇa', 'label': 'vavrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cover'}, {'pos': 'pfmp, pfpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vas', 'label': 'vas', 'attrib': [{'pos': 'vi1a', 'defs': 'dwell'}], 'isLemma': 1},
	{'value': 'vasat', 'label': 'vasat', 'attrib': [{'pos': 'prap', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vasanīya', 'label': 'vasanIya', 'attrib': [{'pos': 'ger', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vasitavya', 'label': 'vasitavya', 'attrib': [{'pos': 'ger', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vastum', 'label': 'vastum', 'attrib': [{'pos': 'inf', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vah', 'label': 'vah', 'attrib': [{'pos': 'vt1am', 'defs': 'carry, flow, wed'}], 'isLemma': 1},
	{'value': 'vahat', 'label': 'vahat', 'attrib': [{'pos': 'prap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vahamāna', 'label': 'vahamAna', 'attrib': [{'pos': 'prmp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vā', 'label': 'vA', 'attrib': [{'pos': 'disj_pcl', 'defs': 'or'}], 'isLemma': 1},
	{'value': 'vākya', 'label': 'vAkya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'vāc', 'label': 'vAc', 'attrib': [{'pos': 'f', 'defs': 'speech'}], 'isLemma': 1},
	{'value': 'vādya', 'label': 'vAdya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'vāra', 'label': 'vAra', 'attrib': [{'pos': 'm', 'defs': 'occasion, day'}], 'isLemma': 1},
	{'value': 'vāraṇīya', 'label': 'vAraRIya', 'attrib': [{'pos': 'ger', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayat', 'label': 'vArayat', 'attrib': [{'pos': 'prap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayamāṇa', 'label': 'vArayamARa', 'attrib': [{'pos': 'prmp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayām', 'label': 'vArayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayitavya', 'label': 'vArayitavya', 'attrib': [{'pos': 'ger', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayitum', 'label': 'vArayitum', 'attrib': [{'pos': 'inf', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayitvā', 'label': 'vArayitvA', 'attrib': [{'pos': 'abs', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayiṣyat', 'label': 'vArayizyat', 'attrib': [{'pos': 'fap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārayiṣyamāṇa', 'label': 'vArayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vāri', 'label': 'vAri', 'attrib': [{'pos': 'n', 'defs': 'water'}], 'isLemma': 1},
	{'value': 'vārita', 'label': 'vArita', 'attrib': [{'pos': 'ppp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vāritavat', 'label': 'vAritavat', 'attrib': [{'pos': 'pap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vārya', 'label': 'vArya', 'attrib': [{'pos': 'ger', 'defs': 'block'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vāryamāṇa', 'label': 'vAryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vālmīki', 'label': 'vAlmIki', 'attrib': [{'pos': 'm_pn', 'defs': 'Vālmīki'}], 'isLemma': 1},
	{'value': 'vāsas', 'label': 'vAsas', 'attrib': [{'pos': 'n', 'defs': 'cloth, clothes'}], 'isLemma': 1},
	{'value': 'vāsya', 'label': 'vAsya', 'attrib': [{'pos': 'ger', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'vāhanīya', 'label': 'vAhanIya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vāhya', 'label': 'vAhya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vi', 'label': 'vi', 'attrib': [{'pos': 'preverb', 'defs': 'apart'}], 'isLemma': 1},
	{'value': 'vikrayaṇīya', 'label': 'vikrayaRIya', 'attrib': [{'pos': 'ger', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrīṇat', 'label': 'vikrIRat', 'attrib': [{'pos': 'prap', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrīṇāna', 'label': 'vikrIRAna', 'attrib': [{'pos': 'prmp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrīta', 'label': 'vikrIta', 'attrib': [{'pos': 'ppp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrītavat', 'label': 'vikrItavat', 'attrib': [{'pos': 'pap', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrīya', 'label': 'vikrIya', 'attrib': [{'pos': 'abs', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikrīyamāṇa', 'label': 'vikrIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikretavya', 'label': 'vikretavya', 'attrib': [{'pos': 'ger', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikretum', 'label': 'vikretum', 'attrib': [{'pos': 'inf', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikreya', 'label': 'vikreya', 'attrib': [{'pos': 'ger', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikreṣyat', 'label': 'vikrezyat', 'attrib': [{'pos': 'fap', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vikreṣyamāṇa', 'label': 'vikrezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vigacchat', 'label': 'vigacCat', 'attrib': [{'pos': 'prap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigata', 'label': 'vigata', 'attrib': [{'pos': 'ppp', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigatavat', 'label': 'vigatavat', 'attrib': [{'pos': 'pap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigatya', 'label': 'vigatya', 'attrib': [{'pos': 'abs', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigantavya', 'label': 'vigantavya', 'attrib': [{'pos': 'ger', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigantum', 'label': 'vigantum', 'attrib': [{'pos': 'inf', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigama', 'label': 'vigama', 'attrib': [{'pos': 'm', 'defs': 'separation, departure'}], 'isLemma': 1},
	{'value': 'vigamanīya', 'label': 'vigamanIya', 'attrib': [{'pos': 'ger', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigamiṣyat', 'label': 'vigamizyat', 'attrib': [{'pos': 'fap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigamiṣyamāṇa', 'label': 'vigamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigamya', 'label': 'vigamya', 'attrib': [{'pos': 'abs', 'defs': 'separate, depart'}, {'pos': 'ger', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vigamyamāna', 'label': 'vigamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vicikriyāṇa', 'label': 'vicikriyARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vicikrīvas', 'label': 'vicikrIvas', 'attrib': [{'pos': 'pfap', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'vijaganvas', 'label': 'vijaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vijagmāna', 'label': 'vijagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vijagmivas', 'label': 'vijagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'vijajñāna', 'label': 'vijajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijajñivas', 'label': 'vijajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijānat', 'label': 'vijAnat', 'attrib': [{'pos': 'prap', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijñāta', 'label': 'vijYAta', 'attrib': [{'pos': 'ppp', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijñātavat', 'label': 'vijYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijñātavya', 'label': 'vijYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijñātum', 'label': 'vijYAtum', 'attrib': [{'pos': 'inf', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijñāna', 'label': 'vijYAna', 'attrib': [{'pos': 'n', 'defs': 'understanding'}], 'isLemma': 1},
	{'value': 'vijñānīya', 'label': 'vijYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijñāya', 'label': 'vijYAya', 'attrib': [{'pos': 'abs', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijñāyamāna', 'label': 'vijYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijñāsyat', 'label': 'vijYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijñāsyamāna', 'label': 'vijYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vijñeya', 'label': 'vijYeya', 'attrib': [{'pos': 'ger', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'vi_krī', 'label': 'vi_krI', 'attrib': [{'pos': 'vt9am', 'defs': 'sell'}], 'isLemma': 1},
	{'value': 'vi_gam', 'label': 'vi_gam', 'attrib': [{'pos': 'vi1a', 'defs': 'separate, depart'}], 'isLemma': 1},
	{'value': 'vi_jñā', 'label': 'vi_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'discern, understand'}], 'isLemma': 1},
	{'value': 'vi_sad', 'label': 'vi_sad', 'attrib': [{'pos': 'vi1a, vi6a', 'defs': 'be sad, grieve'}], 'isLemma': 1},
	{'value': 'vi_smṛ', 'label': 'vi_smf', 'attrib': [{'pos': 'vt1a', 'defs': 'forget'}], 'isLemma': 1},
	{'value': 'vitta', 'label': 'vitta', 'attrib': [{'pos': 'ppp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vittavat', 'label': 'vittavat', 'attrib': [{'pos': 'pap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vittvā', 'label': 'vittvA', 'attrib': [{'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vid', 'label': 'vid', 'attrib': [{'pos': 'vt2a', 'defs': 'know'}, {'pos': 'vt6am', 'defs': 'find, get'}], 'isLemma': 1},
	{'value': 'vidām', 'label': 'vidAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidita', 'label': 'vidita', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viditavat', 'label': 'viditavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viditvā', 'label': 'viditvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}, {'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viddha', 'label': 'vidDa', 'attrib': [{'pos': 'ppp', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'viddhavat', 'label': 'vidDavat', 'attrib': [{'pos': 'pap', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'viddhvā', 'label': 'vidDvA', 'attrib': [{'pos': 'abs', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vidyamāna', 'label': 'vidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}, {'pos': 'prpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidyut', 'label': 'vidyut', 'attrib': [{'pos': 'm', 'defs': 'lightning'}], 'isLemma': 1},
	{'value': 'vidvas', 'label': 'vidvas', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vidhyat', 'label': 'viDyat', 'attrib': [{'pos': 'prap', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vidhyamāna', 'label': 'viDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vinā', 'label': 'vinA', 'attrib': [{'pos': 'pcl', 'defs': 'without (2/3/5)'}], 'isLemma': 1},
	{'value': 'vindat', 'label': 'vindat', 'attrib': [{'pos': 'prap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vindamāna', 'label': 'vindamAna', 'attrib': [{'pos': 'prmp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vividāna', 'label': 'vividAna', 'attrib': [{'pos': 'pfpp', 'defs': 'know'}, {'pos': 'pfmp, pfpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vividivas', 'label': 'vividivas', 'attrib': [{'pos': 'pfap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vividvas', 'label': 'vividvas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}, {'pos': 'pfap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'viviśāna', 'label': 'viviSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'viviśivas', 'label': 'viviSivas', 'attrib': [{'pos': 'pfap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'viviśvas', 'label': 'viviSvas', 'attrib': [{'pos': 'pfap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vivyadhāna', 'label': 'vivyaDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vivyadhvas', 'label': 'vivyaDvas', 'attrib': [{'pos': 'pfap', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'viś', 'label': 'viS', 'attrib': [{'pos': 'm', 'defs': 'settler'}, {'pos': 'vt6a', 'defs': 'enter'}], 'isLemma': 1},
	{'value': 'viśat', 'label': 'viSat', 'attrib': [{'pos': 'prap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'viśyamāna', 'label': 'viSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'viṣaṇṇa', 'label': 'vizaRRa', 'attrib': [{'pos': 'ppp', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣaṇṇavat', 'label': 'vizaRRavat', 'attrib': [{'pos': 'pap', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣattavya', 'label': 'vizattavya', 'attrib': [{'pos': 'ger', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣattum', 'label': 'vizattum', 'attrib': [{'pos': 'inf', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣatsyat', 'label': 'vizatsyat', 'attrib': [{'pos': 'fap', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣatsyamāna', 'label': 'vizatsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣadanīya', 'label': 'vizadanIya', 'attrib': [{'pos': 'ger', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣadya', 'label': 'vizadya', 'attrib': [{'pos': 'abs', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣadyamāna', 'label': 'vizadyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣādya', 'label': 'vizAdya', 'attrib': [{'pos': 'ger', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣīdat', 'label': 'vizIdat', 'attrib': [{'pos': 'prap', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣedāna', 'label': 'vizedAna', 'attrib': [{'pos': 'pfpp', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣedivas', 'label': 'vizedivas', 'attrib': [{'pos': 'pfap', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'viṣṭa', 'label': 'vizwa', 'attrib': [{'pos': 'ppp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'viṣṭavat', 'label': 'vizwavat', 'attrib': [{'pos': 'pap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'viṣṭvā', 'label': 'vizwvA', 'attrib': [{'pos': 'abs', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'visasmarāṇa', 'label': 'visasmarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'visasmarvas', 'label': 'visasmarvas', 'attrib': [{'pos': 'pfap', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismaraṇīya', 'label': 'vismaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismarat', 'label': 'vismarat', 'attrib': [{'pos': 'prap', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismariṣyat', 'label': 'vismarizyat', 'attrib': [{'pos': 'fap', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismariṣyamāṇa', 'label': 'vismarizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismartavya', 'label': 'vismartavya', 'attrib': [{'pos': 'ger', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismartum', 'label': 'vismartum', 'attrib': [{'pos': 'inf', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismaryamāṇa', 'label': 'vismaryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismārya', 'label': 'vismArya', 'attrib': [{'pos': 'ger', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismṛta', 'label': 'vismfta', 'attrib': [{'pos': 'ppp', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismṛtavat', 'label': 'vismftavat', 'attrib': [{'pos': 'pap', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vismṛtya', 'label': 'vismftya', 'attrib': [{'pos': 'abs', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'vīra', 'label': 'vIra', 'attrib': [{'pos': 'adj', 'defs': 'heroic'}, {'pos': 'm', 'defs': 'hero'}], 'isLemma': 1},
	{'value': 'vṛ', 'label': 'vf', 'attrib': [{'pos': 'vt5am', 'defs': 'cover'}, {'pos': 'vt10am', 'defs': 'block'}, {'pos': 'vt9m', 'defs': 'choose'}], 'isLemma': 1},
	{'value': 'vṛka', 'label': 'vfka', 'attrib': [{'pos': 'm', 'defs': 'wolf'}], 'isLemma': 1},
	{'value': 'vṛkṣa', 'label': 'vfkza', 'attrib': [{'pos': 'm', 'defs': 'tree'}], 'isLemma': 1},
	{'value': 'vṛṇāna', 'label': 'vfRAna', 'attrib': [{'pos': 'prmp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vṛṇvat', 'label': 'vfRvat', 'attrib': [{'pos': 'prap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vṛṇvāna', 'label': 'vfRvAna', 'attrib': [{'pos': 'prmp', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vṛt', 'label': 'vft', 'attrib': [{'pos': 'vi1m', 'defs': 'occur, be present'}], 'isLemma': 1},
	{'value': 'vṛta', 'label': 'vfta', 'attrib': [{'pos': 'ppp', 'defs': 'cover'}, {'pos': 'ppp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vṛtavat', 'label': 'vftavat', 'attrib': [{'pos': 'pap', 'defs': 'cover'}, {'pos': 'pap', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vṛtta', 'label': 'vftta', 'attrib': [{'pos': 'ppp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vṛttavat', 'label': 'vfttavat', 'attrib': [{'pos': 'pap', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'vṛtya', 'label': 'vftya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vf', 'vft']},
	{'value': 'vṛtvā', 'label': 'vftvA', 'attrib': [{'pos': 'abs', 'defs': 'cover'}, {'pos': 'abs', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'vekṣyat', 'label': 'vekzyat', 'attrib': [{'pos': 'fap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vekṣyamāṇa', 'label': 'vekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vettavya', 'label': 'vettavya', 'attrib': [{'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vettum', 'label': 'vettum', 'attrib': [{'pos': 'inf', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vetsyat', 'label': 'vetsyat', 'attrib': [{'pos': 'fap', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vetsyamāna', 'label': 'vetsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'veda', 'label': 'veda', 'attrib': [{'pos': 'm', 'defs': 'knowledge, veda , N. of most ancient texts'}], 'isLemma': 1},
	{'value': 'vedanīya', 'label': 'vedanIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vedi', 'label': 'vedi', 'attrib': [{'pos': 'f', 'defs': 'altar'}], 'isLemma': 1},
	{'value': 'veditavya', 'label': 'veditavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veditum', 'label': 'veditum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veditvā', 'label': 'veditvA', 'attrib': [{'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vediṣyat', 'label': 'vedizyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}, {'pos': 'fap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vediṣyamāṇa', 'label': 'vedizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'know'}, {'pos': 'fmp, fpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'veddhavya', 'label': 'vedDavya', 'attrib': [{'pos': 'ger', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'veddhum', 'label': 'vedDum', 'attrib': [{'pos': 'inf', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vedya', 'label': 'vedya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'vedhanīya', 'label': 'veDanIya', 'attrib': [{'pos': 'ger', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'vedhya', 'label': 'veDya', 'attrib': [{'pos': 'ger', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'veśanīya', 'label': 'veSanIya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'veśya', 'label': 'veSya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'veṣṭavya', 'label': 'vezwavya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'veṣṭum', 'label': 'vezwum', 'attrib': [{'pos': 'inf', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'vai', 'label': 'vE', 'attrib': [{'pos': 'pcl', 'defs': 'verily, truly'}], 'isLemma': 1},
	{'value': 'voḍhavya', 'label': 'voQavya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'voḍhum', 'label': 'voQum', 'attrib': [{'pos': 'inf', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'vyadh', 'label': 'vyaD', 'attrib': [{'pos': 'vt4a', 'defs': 'pierce'}], 'isLemma': 1},
	{'value': 'vyāsa', 'label': 'vyAsa', 'attrib': [{'pos': 'm_pn', 'defs': 'Vyāsa'}], 'isLemma': 1},
	{'value': 'vraj', 'label': 'vraj', 'attrib': [{'pos': 'vt1a', 'defs': 'walk'}], 'isLemma': 1},
	{'value': 'vrajat', 'label': 'vrajat', 'attrib': [{'pos': 'prap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajanīya', 'label': 'vrajanIya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajita', 'label': 'vrajita', 'attrib': [{'pos': 'ppp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitavat', 'label': 'vrajitavat', 'attrib': [{'pos': 'pap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitavya', 'label': 'vrajitavya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitum', 'label': 'vrajitum', 'attrib': [{'pos': 'inf', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajitvā', 'label': 'vrajitvA', 'attrib': [{'pos': 'abs', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajiṣyat', 'label': 'vrajizyat', 'attrib': [{'pos': 'fap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajiṣyamāṇa', 'label': 'vrajizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrajyamāna', 'label': 'vrajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vrājya', 'label': 'vrAjya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'vriyamāṇa', 'label': 'vriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'cover'}, {'pos': 'prpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'śak', 'label': 'Sak', 'attrib': [{'pos': 'vi5a', 'defs': 'be able'}], 'isLemma': 1},
	{'value': 'śakanīya', 'label': 'SakanIya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śakuntalā', 'label': 'SakuntalA', 'attrib': [{'pos': 'f_pn', 'defs': 'Śakuntalā'}], 'isLemma': 1},
	{'value': 'śakta', 'label': 'Sakta', 'attrib': [{'pos': 'ppp', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śaktavat', 'label': 'Saktavat', 'attrib': [{'pos': 'pap', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śaktavya', 'label': 'Saktavya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śaktum', 'label': 'Saktum', 'attrib': [{'pos': 'inf', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śaktvā', 'label': 'SaktvA', 'attrib': [{'pos': 'abs', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śaknuvat', 'label': 'Saknuvat', 'attrib': [{'pos': 'prap', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śakya', 'label': 'Sakya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śakyamāna', 'label': 'SakyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'śata', 'label': 'Sata', 'attrib': [{'pos': 'card_num', 'defs': 'hundred'}], 'isLemma': 1},
	{'value': 'śatru', 'label': 'Satru', 'attrib': [{'pos': 'm', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'śap', 'label': 'Sap', 'attrib': [{'pos': 'vt1am, vt4am', 'defs': 'swear (an oath), curse'}], 'isLemma': 1},
	{'value': 'śapat', 'label': 'Sapat', 'attrib': [{'pos': 'prap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śapanīya', 'label': 'SapanIya', 'attrib': [{'pos': 'ger', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śapamāna', 'label': 'SapamAna', 'attrib': [{'pos': 'prmp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śapta', 'label': 'Sapta', 'attrib': [{'pos': 'ppp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śaptavat', 'label': 'Saptavat', 'attrib': [{'pos': 'pap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śaptavya', 'label': 'Saptavya', 'attrib': [{'pos': 'ger', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śaptum', 'label': 'Saptum', 'attrib': [{'pos': 'inf', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śaptvā', 'label': 'SaptvA', 'attrib': [{'pos': 'abs', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śapya', 'label': 'Sapya', 'attrib': [{'pos': 'ger', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śapyat', 'label': 'Sapyat', 'attrib': [{'pos': 'prap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śapyamāna', 'label': 'SapyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'swear (an oath), curse'}, {'pos': 'prpp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śapsyat', 'label': 'Sapsyat', 'attrib': [{'pos': 'fap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śapsyamāna', 'label': 'SapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śabda', 'label': 'Sabda', 'attrib': [{'pos': 'm', 'defs': 'speech, word'}], 'isLemma': 1},
	{'value': 'śayana', 'label': 'Sayana', 'attrib': [{'pos': 'n', 'defs': 'bed, couch'}], 'isLemma': 1},
	{'value': 'śayanīya', 'label': 'SayanIya', 'attrib': [{'pos': 'ger', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'śayāna', 'label': 'SayAna', 'attrib': [{'pos': 'prmp', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'śayita', 'label': 'Sayita', 'attrib': [{'pos': 'ppp', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'śayitavat', 'label': 'Sayitavat', 'attrib': [{'pos': 'pap', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'śayitavya', 'label': 'Sayitavya', 'attrib': [{'pos': 'ger', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'śayitum', 'label': 'Sayitum', 'attrib': [{'pos': 'inf', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'śayitvā', 'label': 'SayitvA', 'attrib': [{'pos': 'abs', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'śayya', 'label': 'Sayya', 'attrib': [{'pos': 'ger', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'śara', 'label': 'Sara', 'attrib': [{'pos': 'm', 'defs': 'arrow'}], 'isLemma': 1},
	{'value': 'śaśāsāna', 'label': 'SaSAsAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śaśāsvas', 'label': 'SaSAsvas', 'attrib': [{'pos': 'pfap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śākhā', 'label': 'SAKA', 'attrib': [{'pos': 'f', 'defs': 'branch'}], 'isLemma': 1},
	{'value': 'śāpa', 'label': 'SApa', 'attrib': [{'pos': 'm', 'defs': 'promise, curse, vow'}], 'isLemma': 1},
	{'value': 'śālā', 'label': 'SAlA', 'attrib': [{'pos': 'f', 'defs': 'hall'}], 'isLemma': 1},
	{'value': 'śās', 'label': 'SAs', 'attrib': [{'pos': 'vt2a', 'defs': 'rule, govern, teach'}], 'isLemma': 1},
	{'value': 'śāsat', 'label': 'SAsat', 'attrib': [{'pos': 'prap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śāsanīya', 'label': 'SAsanIya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śāsitavya', 'label': 'SAsitavya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śāsitum', 'label': 'SAsitum', 'attrib': [{'pos': 'inf', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śāsitvā', 'label': 'SAsitvA', 'attrib': [{'pos': 'abs', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śāsiṣyat', 'label': 'SAsizyat', 'attrib': [{'pos': 'fap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śāsiṣyamāṇa', 'label': 'SAsizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śita', 'label': 'Sita', 'attrib': [{'pos': 'adj', 'defs': 'sharp'}], 'isLemma': 1},
	{'value': 'śiva', 'label': 'Siva', 'attrib': [{'pos': 'adj', 'defs': 'auspicious'}, {'pos': 'm_pn', 'defs': 'Śiva'}], 'isLemma': 1},
	{'value': 'śiṣṭa', 'label': 'Sizwa', 'attrib': [{'pos': 'ppp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śiṣṭavat', 'label': 'Sizwavat', 'attrib': [{'pos': 'pap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śiṣṭvā', 'label': 'SizwvA', 'attrib': [{'pos': 'abs', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śiṣya', 'label': 'Sizya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}, {'pos': 'm', 'defs': 'student'}], 'isLemma': 1},
	{'value': 'śiṣya', 'label': 'Sizya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}, {'pos': 'm', 'defs': 'student'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śiṣyamāṇa', 'label': 'SizyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'śī', 'label': 'SI', 'attrib': [{'pos': 'vi2m', 'defs': 'lie, sleep'}], 'isLemma': 1},
	{'value': 'śīghra', 'label': 'SIGra', 'attrib': [{'pos': 'adj', 'defs': 'swift, quick'}], 'isLemma': 1},
	{'value': 'śīghram', 'label': 'SIGram', 'attrib': [{'pos': 'adv', 'defs': 'swiftly, quickly'}], 'isLemma': 1},
	{'value': 'śuc', 'label': 'Suc', 'attrib': [{'pos': 'vt1a', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 1},
	{'value': 'śuci', 'label': 'Suci', 'attrib': [{'pos': 'adj', 'defs': 'pure'}], 'isLemma': 1},
	{'value': 'śucita', 'label': 'Sucita', 'attrib': [{'pos': 'ppp', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śucitavat', 'label': 'Sucitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śucitvā', 'label': 'SucitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śuśucvas', 'label': 'SuSucvas', 'attrib': [{'pos': 'pfap', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śṛṇvat', 'label': 'SfRvat', 'attrib': [{'pos': 'prap', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śepāna', 'label': 'SepAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śepivas', 'label': 'Sepivas', 'attrib': [{'pos': 'pfap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'śeṣa', 'label': 'Seza', 'attrib': [{'pos': 'm', 'defs': 'remainder'}], 'isLemma': 1},
	{'value': 'śocat', 'label': 'Socat', 'attrib': [{'pos': 'prap', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śocanīya', 'label': 'SocanIya', 'attrib': [{'pos': 'ger', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śocayitvā', 'label': 'SocayitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śocita', 'label': 'Socita', 'attrib': [{'pos': 'ppp', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śocitavat', 'label': 'Socitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śocitavya', 'label': 'Socitavya', 'attrib': [{'pos': 'ger', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śocitum', 'label': 'Socitum', 'attrib': [{'pos': 'inf', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śocitvā', 'label': 'SocitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śocya', 'label': 'Socya', 'attrib': [{'pos': 'ger', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'śravaṇīya', 'label': 'SravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śravya', 'label': 'Sravya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrī', 'label': 'SrI', 'attrib': [{'pos': 'f', 'defs': 'glory'}], 'isLemma': 1},
	{'value': 'śru', 'label': 'Sru', 'attrib': [{'pos': 'vt1am', 'defs': 'hear, listen'}], 'isLemma': 1},
	{'value': 'śruta', 'label': 'Sruta', 'attrib': [{'pos': 'ppp', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrutavat', 'label': 'Srutavat', 'attrib': [{'pos': 'pap', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrutvā', 'label': 'SrutvA', 'attrib': [{'pos': 'abs', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrūyamāṇa', 'label': 'SrUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrotavya', 'label': 'Srotavya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śrotum', 'label': 'Srotum', 'attrib': [{'pos': 'inf', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'śvan', 'label': 'Svan', 'attrib': [{'pos': 'm', 'defs': 'dog'}], 'isLemma': 1},
	{'value': 'śvas', 'label': 'Svas', 'attrib': [{'pos': 'adv', 'defs': 'tomorrow'}], 'isLemma': 1},
	{'value': 'ṣaṣ', 'label': 'zaz', 'attrib': [{'pos': 'card_num', 'defs': 'six'}], 'isLemma': 1},
	{'value': 'ṣaṣṭha', 'label': 'zazWa', 'attrib': [{'pos': 'ord_num', 'defs': 'sixth'}], 'isLemma': 1},
	{'value': 'saṁkraṁsyat', 'label': 'saMkraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁkraṁsyamāna', 'label': 'saMkraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁkramaṇīya', 'label': 'saMkramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁkramitavya', 'label': 'saMkramitavya', 'attrib': [{'pos': 'ger', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁkramitum', 'label': 'saMkramitum', 'attrib': [{'pos': 'inf', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁkramya', 'label': 'saMkramya', 'attrib': [{'pos': 'abs', 'defs': 'meet'}, {'pos': 'ger', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁkramyamāṇa', 'label': 'saMkramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁkrānta', 'label': 'saMkrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁkrāntavat', 'label': 'saMkrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁkrāmat', 'label': 'saMkrAmat', 'attrib': [{'pos': 'prap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁkrāmyat', 'label': 'saMkrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁgacchamāna', 'label': 'saMgacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgata', 'label': 'saMgata', 'attrib': [{'pos': 'ppp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgatavat', 'label': 'saMgatavat', 'attrib': [{'pos': 'pap', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgatya', 'label': 'saMgatya', 'attrib': [{'pos': 'abs', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgantavya', 'label': 'saMgantavya', 'attrib': [{'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgantum', 'label': 'saMgantum', 'attrib': [{'pos': 'inf', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgamanīya', 'label': 'saMgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgamiṣyamāṇa', 'label': 'saMgamizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgamya', 'label': 'saMgamya', 'attrib': [{'pos': 'abs', 'defs': 'go together, unite, meet'}, {'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁgamyamāna', 'label': 'saMgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁcakramāṇa', 'label': 'saMcakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁcakramvas', 'label': 'saMcakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'saṁjagmāna', 'label': 'saMjagmAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'saṁjahṛvas', 'label': 'saMjahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁjahrāṇa', 'label': 'saMjahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁpattavya', 'label': 'saMpattavya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpattum', 'label': 'saMpattum', 'attrib': [{'pos': 'inf', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpatsyamāna', 'label': 'saMpatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpadanīya', 'label': 'saMpadanIya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpadya', 'label': 'saMpadya', 'attrib': [{'pos': 'abs', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpadyamāna', 'label': 'saMpadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'succeed, arise'}, {'pos': 'prpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpanna', 'label': 'saMpanna', 'attrib': [{'pos': 'ppp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpannavat', 'label': 'saMpannavat', 'attrib': [{'pos': 'pap', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpapracchāna', 'label': 'saMpapracCAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saṁpādya', 'label': 'saMpAdya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁpṛcchamāna', 'label': 'saMpfcCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saṁpṛcchya', 'label': 'saMpfcCya', 'attrib': [{'pos': 'abs', 'defs': 'consult'}, {'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saṁpṛcchyamāna', 'label': 'saMpfcCyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saṁpṛṣṭa', 'label': 'saMpfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saṁpṛṣṭavat', 'label': 'saMpfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saṁpedāna', 'label': 'saMpedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'saṁprakṣyamāṇa', 'label': 'saMprakzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saṁpracchanīya', 'label': 'saMpracCanIya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saṁpraṣṭavya', 'label': 'saMprazwavya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saṁpraṣṭum', 'label': 'saMprazwum', 'attrib': [{'pos': 'inf', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'saṁbabhūvas', 'label': 'saMbaBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhavat', 'label': 'saMBavat', 'attrib': [{'pos': 'prap', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhavanīya', 'label': 'saMBavanIya', 'attrib': [{'pos': 'ger', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhavitavya', 'label': 'saMBavitavya', 'attrib': [{'pos': 'ger', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhavitum', 'label': 'saMBavitum', 'attrib': [{'pos': 'inf', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhaviṣyat', 'label': 'saMBavizyat', 'attrib': [{'pos': 'fap', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhaviṣyamāṇa', 'label': 'saMBavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhavya', 'label': 'saMBavya', 'attrib': [{'pos': 'ger', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhūta', 'label': 'saMBUta', 'attrib': [{'pos': 'ppp', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhūtavat', 'label': 'saMBUtavat', 'attrib': [{'pos': 'pap', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhūya', 'label': 'saMBUya', 'attrib': [{'pos': 'abs', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁbhūyamāna', 'label': 'saMBUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'saṁvadanīya', 'label': 'saMvadanIya', 'attrib': [{'pos': 'ger', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'saṁvadamāna', 'label': 'saMvadamAna', 'attrib': [{'pos': 'prmp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'saṁvaditavya', 'label': 'saMvaditavya', 'attrib': [{'pos': 'ger', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'saṁvaditum', 'label': 'saMvaditum', 'attrib': [{'pos': 'inf', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'saṁvadiṣyamāṇa', 'label': 'saMvadizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'saṁvāda', 'label': 'saMvAda', 'attrib': [{'pos': 'm', 'defs': 'conversation'}], 'isLemma': 1},
	{'value': 'saṁvādya', 'label': 'saMvAdya', 'attrib': [{'pos': 'ger', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'saṁśaya', 'label': 'saMSaya', 'attrib': [{'pos': 'm', 'defs': 'doubt'}], 'isLemma': 1},
	{'value': 'saṁśayanīya', 'label': 'saMSayanIya', 'attrib': [{'pos': 'ger', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saṁśayāna', 'label': 'saMSayAna', 'attrib': [{'pos': 'prmp', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saṁśayita', 'label': 'saMSayita', 'attrib': [{'pos': 'ppp', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saṁśayitavat', 'label': 'saMSayitavat', 'attrib': [{'pos': 'pap', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saṁśayitavya', 'label': 'saMSayitavya', 'attrib': [{'pos': 'ger', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saṁśayitum', 'label': 'saMSayitum', 'attrib': [{'pos': 'inf', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saṁśayya', 'label': 'saMSayya', 'attrib': [{'pos': 'abs', 'defs': 'lie down, hesitate'}, {'pos': 'ger', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'saṁskṛta', 'label': 'saMskfta', 'attrib': [{'pos': 'adj', 'defs': 'refined'}, {'pos': 'n', 'defs': 'Sanskrit'}], 'isLemma': 1},
	{'value': 'saṁharaṇīya', 'label': 'saMharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁharat', 'label': 'saMharat', 'attrib': [{'pos': 'prap', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁharamāṇa', 'label': 'saMharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁhariṣyat', 'label': 'saMharizyat', 'attrib': [{'pos': 'fap', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁhariṣyamāṇa', 'label': 'saMharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁhartavya', 'label': 'saMhartavya', 'attrib': [{'pos': 'ger', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁhartum', 'label': 'saMhartum', 'attrib': [{'pos': 'inf', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁhārya', 'label': 'saMhArya', 'attrib': [{'pos': 'ger', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁhṛta', 'label': 'saMhfta', 'attrib': [{'pos': 'ppp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁhṛtavat', 'label': 'saMhftavat', 'attrib': [{'pos': 'pap', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁhṛtya', 'label': 'saMhftya', 'attrib': [{'pos': 'abs', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'saṁhriyamāṇa', 'label': 'saMhriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'sakhī', 'label': 'saKI', 'attrib': [{'pos': 'f', 'defs': 'friend'}], 'isLemma': 1},
	{'value': 'sakhya', 'label': 'saKya', 'attrib': [{'pos': 'n', 'defs': 'friendship'}], 'isLemma': 1},
	{'value': 'sat', 'label': 'sat', 'attrib': [{'pos': 'prap', 'defs': 'be'}], 'isLemma': 0, 'parents': ['as']},
	{'value': 'sattavya', 'label': 'sattavya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sattum', 'label': 'sattum', 'attrib': [{'pos': 'inf', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sattvā', 'label': 'sattvA', 'attrib': [{'pos': 'abs', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'satya', 'label': 'satya', 'attrib': [{'pos': 'adj', 'defs': 'true, real'}, {'pos': 'n', 'defs': 'truth'}], 'isLemma': 1},
	{'value': 'satsyat', 'label': 'satsyat', 'attrib': [{'pos': 'fap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'satsyamāna', 'label': 'satsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sad', 'label': 'sad', 'attrib': [{'pos': 'vi1a, vi6a', 'defs': 'sit, be sad'}], 'isLemma': 1},
	{'value': 'sadanīya', 'label': 'sadanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sadā', 'label': 'sadA', 'attrib': [{'pos': 'pron_adv', 'defs': 'always'}], 'isLemma': 1},
	{'value': 'sadyamāna', 'label': 'sadyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sanna', 'label': 'sanna', 'attrib': [{'pos': 'ppp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sannavat', 'label': 'sannavat', 'attrib': [{'pos': 'pap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'saptan', 'label': 'saptan', 'attrib': [{'pos': 'card_num', 'defs': 'seven'}], 'isLemma': 1},
	{'value': 'saptama', 'label': 'saptama', 'attrib': [{'pos': 'ord_num', 'defs': 'seventh'}], 'isLemma': 1},
	{'value': 'sam', 'label': 'sam', 'attrib': [{'pos': 'preverb', 'defs': 'together'}], 'isLemma': 1},
	{'value': 'samāgacchamāna', 'label': 'samAgacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samāgata', 'label': 'samAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samāgatavat', 'label': 'samAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samāgatya', 'label': 'samAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samāgantavya', 'label': 'samAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samāgantum', 'label': 'samAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samāgamanīya', 'label': 'samAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samāgamiṣyamāṇa', 'label': 'samAgamizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samāgamya', 'label': 'samAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come together, meet'}, {'pos': 'ger', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samāgamyamāna', 'label': 'samAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samājagmāna', 'label': 'samAjagmAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'samīpa', 'label': 'samIpa', 'attrib': [{'pos': 'adj', 'defs': 'near'}], 'isLemma': 1},
	{'value': 'samudita', 'label': 'samudita', 'attrib': [{'pos': 'ppp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'samuditavat', 'label': 'samuditavat', 'attrib': [{'pos': 'pap', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'samudya', 'label': 'samudya', 'attrib': [{'pos': 'abs', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'samudyamāna', 'label': 'samudyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'samūdāna', 'label': 'samUdAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'sam_ā_gam', 'label': 'sam_A_gam', 'attrib': [{'pos': 'vi1m', 'defs': 'come together, meet'}], 'isLemma': 1},
	{'value': 'sam_kram', 'label': 'sam_kram', 'attrib': [{'pos': 'vi1a', 'defs': 'meet'}], 'isLemma': 1},
	{'value': 'sam_gam', 'label': 'sam_gam', 'attrib': [{'pos': 'vi1m', 'defs': 'go together, unite, meet'}], 'isLemma': 1},
	{'value': 'sam_pad', 'label': 'sam_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'succeed, arise'}], 'isLemma': 1},
	{'value': 'sam_pracch', 'label': 'sam_pracC', 'attrib': [{'pos': 'vi6m', 'defs': 'consult'}], 'isLemma': 1},
	{'value': 'sam_bhū', 'label': 'sam_BU', 'attrib': [{'pos': 'vi1a', 'defs': 'be together, arise, be possible'}], 'isLemma': 1},
	{'value': 'sam_vad', 'label': 'sam_vad', 'attrib': [{'pos': 'vi1m', 'defs': 'speak together, converse, discuss'}], 'isLemma': 1},
	{'value': 'sam_śī', 'label': 'sam_SI', 'attrib': [{'pos': 'vi2m', 'defs': 'lie down, hesitate'}], 'isLemma': 1},
	{'value': 'sam_hṛ', 'label': 'sam_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'collect'}], 'isLemma': 1},
	{'value': 'sarit', 'label': 'sarit', 'attrib': [{'pos': 'f', 'defs': 'flowing, river'}], 'isLemma': 1},
	{'value': 'sarva', 'label': 'sarva', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'all, whole, every'}], 'isLemma': 1},
	{'value': 'sarvatas', 'label': 'sarvatas', 'attrib': [{'pos': 'pron_adv', 'defs': 'from everywhere'}], 'isLemma': 1},
	{'value': 'sarvatra', 'label': 'sarvatra', 'attrib': [{'pos': 'pron_adv', 'defs': 'everywhere'}], 'isLemma': 1},
	{'value': 'sarvathā', 'label': 'sarvaTA', 'attrib': [{'pos': 'pron_adv', 'defs': 'in every way'}], 'isLemma': 1},
	{'value': 'sarvadā', 'label': 'sarvadA', 'attrib': [{'pos': 'pron_adv', 'defs': 'always'}], 'isLemma': 1},
	{'value': 'savanīya', 'label': 'savanIya', 'attrib': [{'pos': 'ger', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'savya', 'label': 'savya', 'attrib': [{'pos': 'ger', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sasmarāṇa', 'label': 'sasmarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'sasmarvas', 'label': 'sasmarvas', 'attrib': [{'pos': 'pfap', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'saha', 'label': 'saha', 'attrib': [{'pos': 'pcl', 'defs': 'with'}], 'isLemma': 1},
	{'value': 'sādya', 'label': 'sAdya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sādhu', 'label': 'sADu', 'attrib': [{'pos': 'adj', 'defs': 'straight, right, good'}, {'pos': 'm', 'defs': 'good man, sage'}], 'isLemma': 1},
	{'value': 'sāman', 'label': 'sAman', 'attrib': [{'pos': 'n', 'defs': 'verse of the <i>Sāmaveda</i>'}], 'isLemma': 1},
	{'value': 'sāmaveda', 'label': 'sAmaveda', 'attrib': [{'pos': 'm', 'defs': '<i>Sāmaveda</i>'}], 'isLemma': 1},
	{'value': 'siṣevāṇa', 'label': 'sizevARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'siṣṇihāna', 'label': 'sizRihAna', 'attrib': [{'pos': 'pfpp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'siṣṇihvas', 'label': 'sizRihvas', 'attrib': [{'pos': 'pfap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'sīdat', 'label': 'sIdat', 'attrib': [{'pos': 'prap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'su', 'label': 'su', 'attrib': [{'pos': 'preverb', 'defs': 'well'}, {'pos': 'vt5am', 'defs': 'press'}], 'isLemma': 1},
	{'value': 'sukha', 'label': 'suKa', 'attrib': [{'pos': 'adj', 'defs': 'comfortable, happy'}, {'pos': 'n', 'defs': 'happiness'}], 'isLemma': 1},
	{'value': 'sukham', 'label': 'suKam', 'attrib': [{'pos': 'adv', 'defs': 'comfortably, happily'}], 'isLemma': 1},
	{'value': 'sukhin', 'label': 'suKin', 'attrib': [{'pos': 'adj', 'defs': 'happy'}], 'isLemma': 1},
	{'value': 'suta', 'label': 'suta', 'attrib': [{'pos': 'ppp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sutavat', 'label': 'sutavat', 'attrib': [{'pos': 'pap', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sutvā', 'label': 'sutvA', 'attrib': [{'pos': 'abs', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sudeva', 'label': 'sudeva', 'attrib': [{'pos': 'adj', 'defs': 'very noble'}], 'isLemma': 1},
	{'value': 'sundara', 'label': 'sundara', 'attrib': [{'pos': 'adj', 'defs': 'beautiful'}], 'isLemma': 1},
	{'value': 'sunvat', 'label': 'sunvat', 'attrib': [{'pos': 'prap', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sunvāna', 'label': 'sunvAna', 'attrib': [{'pos': 'prmp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sumanas', 'label': 'sumanas', 'attrib': [{'pos': 'adj', 'defs': 'good-minded, well-disposed, benevolent, cheerful, wise'}], 'isLemma': 1},
	{'value': 'suṣuvas', 'label': 'suzuvas', 'attrib': [{'pos': 'pfap', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'suṣuvāṇa', 'label': 'suzuvARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'suhṛd', 'label': 'suhfd', 'attrib': [{'pos': 'adj', 'defs': 'good-hearted, well-disposed'}, {'pos': 'm, f', 'defs': 'friend'}], 'isLemma': 1},
	{'value': 'sūyamāna', 'label': 'sUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sūrya', 'label': 'sUrya', 'attrib': [{'pos': 'm', 'defs': 'sun'}], 'isLemma': 1},
	{'value': 'sedāna', 'label': 'sedAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sedivas', 'label': 'sedivas', 'attrib': [{'pos': 'pfap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'sev', 'label': 'sev', 'attrib': [{'pos': 'vt1m', 'defs': 'serve'}], 'isLemma': 1},
	{'value': 'sevanīya', 'label': 'sevanIya', 'attrib': [{'pos': 'ger', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevamāna', 'label': 'sevamAna', 'attrib': [{'pos': 'prmp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevā', 'label': 'sevA', 'attrib': [{'pos': 'f', 'defs': 'service'}], 'isLemma': 1},
	{'value': 'sevita', 'label': 'sevita', 'attrib': [{'pos': 'ppp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevitavat', 'label': 'sevitavat', 'attrib': [{'pos': 'pap', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevitavya', 'label': 'sevitavya', 'attrib': [{'pos': 'ger', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevitum', 'label': 'sevitum', 'attrib': [{'pos': 'inf', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevitvā', 'label': 'sevitvA', 'attrib': [{'pos': 'abs', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'seviṣyamāṇa', 'label': 'sevizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevya', 'label': 'sevya', 'attrib': [{'pos': 'ger', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sevyamāna', 'label': 'sevyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'sainya', 'label': 'sEnya', 'attrib': [{'pos': 'n', 'defs': 'army'}], 'isLemma': 1},
	{'value': 'sotavya', 'label': 'sotavya', 'attrib': [{'pos': 'ger', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'sotum', 'label': 'sotum', 'attrib': [{'pos': 'inf', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'soma', 'label': 'soma', 'attrib': [{'pos': 'm', 'defs': 'juice'}], 'isLemma': 1},
	{'value': 'soṣyat', 'label': 'sozyat', 'attrib': [{'pos': 'fap', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'soṣyamāṇa', 'label': 'sozyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'strī', 'label': 'strI', 'attrib': [{'pos': 'f', 'defs': 'woman'}], 'isLemma': 1},
	{'value': 'sthā', 'label': 'sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stand, stay, be present'}], 'isLemma': 1},
	{'value': 'sthātavya', 'label': 'sTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthātum', 'label': 'sTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthāna', 'label': 'sTAna', 'attrib': [{'pos': 'n', 'defs': 'place'}], 'isLemma': 1},
	{'value': 'sthānīya', 'label': 'sTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthāpayitum', 'label': 'sTApayitum', 'attrib': [{'pos': 'inf', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthālī', 'label': 'sTAlI', 'attrib': [{'pos': 'f', 'defs': 'pot, pan'}], 'isLemma': 1},
	{'value': 'sthāsyat', 'label': 'sTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthāsyamāna', 'label': 'sTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthita', 'label': 'sTita', 'attrib': [{'pos': 'ppp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthitavat', 'label': 'sTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthitvā', 'label': 'sTitvA', 'attrib': [{'pos': 'abs', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'sthīyamāna', 'label': 'sTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'stheya', 'label': 'sTeya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'snigdha', 'label': 'snigDa', 'attrib': [{'pos': 'ppp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snigdhavat', 'label': 'snigDavat', 'attrib': [{'pos': 'pap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snih', 'label': 'snih', 'attrib': [{'pos': 'vi4a', 'defs': 'stick, be affectionate'}], 'isLemma': 1},
	{'value': 'snihitvā', 'label': 'snihitvA', 'attrib': [{'pos': 'abs', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snihyat', 'label': 'snihyat', 'attrib': [{'pos': 'prap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snihyamāna', 'label': 'snihyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snekṣyat', 'label': 'snekzyat', 'attrib': [{'pos': 'fap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snekṣyamāṇa', 'label': 'snekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'sneha', 'label': 'sneha', 'attrib': [{'pos': 'm', 'defs': 'affection'}], 'isLemma': 1},
	{'value': 'snehanīya', 'label': 'snehanIya', 'attrib': [{'pos': 'ger', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snehitavya', 'label': 'snehitavya', 'attrib': [{'pos': 'ger', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snehitum', 'label': 'snehitum', 'attrib': [{'pos': 'inf', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snehitvā', 'label': 'snehitvA', 'attrib': [{'pos': 'abs', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snehiṣyat', 'label': 'snehizyat', 'attrib': [{'pos': 'fap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snehiṣyamāṇa', 'label': 'snehizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'snehya', 'label': 'snehya', 'attrib': [{'pos': 'ger', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'sparśanīya', 'label': 'sparSanIya', 'attrib': [{'pos': 'ger', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spṛś', 'label': 'spfS', 'attrib': [{'pos': 'vt6a', 'defs': 'touch'}], 'isLemma': 1},
	{'value': 'spṛśat', 'label': 'spfSat', 'attrib': [{'pos': 'prap', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spṛśya', 'label': 'spfSya', 'attrib': [{'pos': 'ger', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spṛśyamāna', 'label': 'spfSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spṛṣṭa', 'label': 'spfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spṛṣṭavat', 'label': 'spfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spṛṣṭvā', 'label': 'spfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'sprakṣyat', 'label': 'sprakzyat', 'attrib': [{'pos': 'fap', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'sprakṣyamāṇa', 'label': 'sprakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spraṣṭavya', 'label': 'sprazwavya', 'attrib': [{'pos': 'ger', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'spraṣṭum', 'label': 'sprazwum', 'attrib': [{'pos': 'inf', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'sma', 'label': 'sma', 'attrib': [{'pos': 'pcl', 'defs': 'ever, certainly'}], 'isLemma': 1},
	{'value': 'smaraṇīya', 'label': 'smaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smarat', 'label': 'smarat', 'attrib': [{'pos': 'prap', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smariṣyat', 'label': 'smarizyat', 'attrib': [{'pos': 'fap', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smariṣyamāṇa', 'label': 'smarizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smartavya', 'label': 'smartavya', 'attrib': [{'pos': 'ger', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smartum', 'label': 'smartum', 'attrib': [{'pos': 'inf', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smaryamāṇa', 'label': 'smaryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smārya', 'label': 'smArya', 'attrib': [{'pos': 'ger', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smṛ', 'label': 'smf', 'attrib': [{'pos': 'vt1a', 'defs': 'remember'}], 'isLemma': 1},
	{'value': 'smṛta', 'label': 'smfta', 'attrib': [{'pos': 'ppp', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smṛtavat', 'label': 'smftavat', 'attrib': [{'pos': 'pap', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'smṛti', 'label': 'smfti', 'attrib': [{'pos': 'f', 'defs': 'memory, traditional texts'}], 'isLemma': 1},
	{'value': 'smṛtvā', 'label': 'smftvA', 'attrib': [{'pos': 'abs', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'sraj', 'label': 'sraj', 'attrib': [{'pos': 'f', 'defs': 'garland'}], 'isLemma': 1},
	{'value': 'sva', 'label': 'sva', 'attrib': [{'pos': 'pron, pron_adj', 'defs': "one's own"}], 'isLemma': 1},
	{'value': 'svarāṣṭra', 'label': 'svarAzwra', 'attrib': [{'pos': 'n', 'defs': 'own kingdom'}], 'isLemma': 1},
	{'value': 'svarga', 'label': 'svarga', 'attrib': [{'pos': 'm', 'defs': 'heaven'}], 'isLemma': 1},
	{'value': 'svasṛ', 'label': 'svasf', 'attrib': [{'pos': 'f', 'defs': 'sister'}], 'isLemma': 1},
	{'value': 'ha', 'label': 'ha', 'attrib': [{'pos': 'pcl', 'defs': 'indeed, of course'}], 'isLemma': 1},
	{'value': 'hata', 'label': 'hata', 'attrib': [{'pos': 'ppp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hatavat', 'label': 'hatavat', 'attrib': [{'pos': 'pap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hatvā', 'label': 'hatvA', 'attrib': [{'pos': 'abs', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'han', 'label': 'han', 'attrib': [{'pos': 'vt2a', 'defs': 'smite'}], 'isLemma': 1},
	{'value': 'hananīya', 'label': 'hananIya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'haniṣyat', 'label': 'hanizyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'haniṣyamāṇa', 'label': 'hanizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hantavya', 'label': 'hantavya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hantum', 'label': 'hantum', 'attrib': [{'pos': 'inf', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hanyamāna', 'label': 'hanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'hayanīya', 'label': 'hayanIya', 'attrib': [{'pos': 'ger', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'haraṇīya', 'label': 'haraRIya', 'attrib': [{'pos': 'ger', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'harat', 'label': 'harat', 'attrib': [{'pos': 'prap', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'haramāṇa', 'label': 'haramARa', 'attrib': [{'pos': 'prmp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hari', 'label': 'hari', 'attrib': [{'pos': 'adj', 'defs': 'yellow'}, {'pos': 'm', 'defs': 'yellow, horse'}, {'pos': 'm_pn', 'defs': 'Hari Candra'}], 'isLemma': 1},
	{'value': 'hariṣyat', 'label': 'harizyat', 'attrib': [{'pos': 'fap', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hariṣyamāṇa', 'label': 'harizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hartavya', 'label': 'hartavya', 'attrib': [{'pos': 'ger', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hartum', 'label': 'hartum', 'attrib': [{'pos': 'inf', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'havanīya', 'label': 'havanIya', 'attrib': [{'pos': 'ger', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'havis', 'label': 'havis', 'attrib': [{'pos': 'n', 'defs': 'oblation'}], 'isLemma': 1},
	{'value': 'havya', 'label': 'havya', 'attrib': [{'pos': 'ger', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hasta', 'label': 'hasta', 'attrib': [{'pos': 'm', 'defs': 'hand'}], 'isLemma': 1},
	{'value': 'hastin', 'label': 'hastin', 'attrib': [{'pos': 'adj', 'defs': 'having a hand'}, {'pos': 'm', 'defs': 'elephant'}], 'isLemma': 1},
	{'value': 'hā', 'label': 'hA', 'attrib': [{'pos': 'vt3a', 'defs': 'leave, abandon'}], 'isLemma': 1},
	{'value': 'hātavya', 'label': 'hAtavya', 'attrib': [{'pos': 'ger', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hātum', 'label': 'hAtum', 'attrib': [{'pos': 'inf', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hānīya', 'label': 'hAnIya', 'attrib': [{'pos': 'ger', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hārya', 'label': 'hArya', 'attrib': [{'pos': 'ger', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hāsyat', 'label': 'hAsyat', 'attrib': [{'pos': 'fap', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hāsyamāna', 'label': 'hAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hi', 'label': 'hi', 'attrib': [{'pos': 'sub_pcl', 'defs': 'because, for'}, {'pos': 'vt5a', 'defs': 'impel, send'}], 'isLemma': 1},
	{'value': 'hita', 'label': 'hita', 'attrib': [{'pos': 'ppp', 'defs': 'put'}, {'pos': 'ppp', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['DA', 'hi']},
	{'value': 'hitavat', 'label': 'hitavat', 'attrib': [{'pos': 'pap', 'defs': 'put'}, {'pos': 'pap', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['DA', 'hi']},
	{'value': 'hitvā', 'label': 'hitvA', 'attrib': [{'pos': 'abs', 'defs': 'put'}, {'pos': 'abs', 'defs': 'leave, abandon'}, {'pos': 'abs', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['DA', 'hA', 'hi']},
	{'value': 'hinvat', 'label': 'hinvat', 'attrib': [{'pos': 'prap', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'himālaya', 'label': 'himAlaya', 'attrib': [{'pos': 'm_pn', 'defs': 'Himālaya , the Himalayan mountain range'}], 'isLemma': 1},
	{'value': 'hīna', 'label': 'hIna', 'attrib': [{'pos': 'ppp', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hīnavat', 'label': 'hInavat', 'attrib': [{'pos': 'pap', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'hīyamāna', 'label': 'hIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'leave, abandon'}, {'pos': 'prpp', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hA', 'hi']},
	{'value': 'hu', 'label': 'hu', 'attrib': [{'pos': 'vt3a', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 1},
	{'value': 'huta', 'label': 'huta', 'attrib': [{'pos': 'ppp', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hutavat', 'label': 'hutavat', 'attrib': [{'pos': 'pap', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hutvā', 'label': 'hutvA', 'attrib': [{'pos': 'abs', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hūta', 'label': 'hUta', 'attrib': [{'pos': 'ppp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hūtavat', 'label': 'hUtavat', 'attrib': [{'pos': 'pap', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hūtvā', 'label': 'hUtvA', 'attrib': [{'pos': 'abs', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hūyamāna', 'label': 'hUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}, {'pos': 'prpp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hu', 'hve']},
	{'value': 'hṛ', 'label': 'hf', 'attrib': [{'pos': 'vt1am', 'defs': 'take'}], 'isLemma': 1},
	{'value': 'hṛta', 'label': 'hfta', 'attrib': [{'pos': 'ppp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hṛtavat', 'label': 'hftavat', 'attrib': [{'pos': 'pap', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hṛtvā', 'label': 'hftvA', 'attrib': [{'pos': 'abs', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'he', 'label': 'he', 'attrib': [{'pos': 'pcl', 'defs': 'hey, O'}], 'isLemma': 1},
	{'value': 'hetavya', 'label': 'hetavya', 'attrib': [{'pos': 'ger', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'hetu', 'label': 'hetu', 'attrib': [{'pos': 'm', 'defs': 'reason, cause'}], 'isLemma': 1},
	{'value': 'hetum', 'label': 'hetum', 'attrib': [{'pos': 'inf', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'heya', 'label': 'heya', 'attrib': [{'pos': 'ger', 'defs': 'leave, abandon'}, {'pos': 'ger', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hA', 'hi']},
	{'value': 'heṣyat', 'label': 'hezyat', 'attrib': [{'pos': 'fap', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'heṣyamāṇa', 'label': 'hezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'hotavya', 'label': 'hotavya', 'attrib': [{'pos': 'ger', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hotum', 'label': 'hotum', 'attrib': [{'pos': 'inf', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hotṛ', 'label': 'hotf', 'attrib': [{'pos': 'm', 'defs': 'hotṛ'}], 'isLemma': 1},
	{'value': 'hoṣyat', 'label': 'hozyat', 'attrib': [{'pos': 'fap', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hoṣyamāṇa', 'label': 'hozyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'hyas', 'label': 'hyas', 'attrib': [{'pos': 'adv', 'defs': 'yesterday'}], 'isLemma': 1},
	{'value': 'hriyamāṇa', 'label': 'hriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'hrī', 'label': 'hrI', 'attrib': [{'pos': 'f', 'defs': 'modesty'}], 'isLemma': 1},
	{'value': 'hvayat', 'label': 'hvayat', 'attrib': [{'pos': 'prap', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvayamāna', 'label': 'hvayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvātavya', 'label': 'hvAtavya', 'attrib': [{'pos': 'ger', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvātum', 'label': 'hvAtum', 'attrib': [{'pos': 'inf', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvānīya', 'label': 'hvAnIya', 'attrib': [{'pos': 'ger', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvāya', 'label': 'hvAya', 'attrib': [{'pos': 'ger', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvāsyat', 'label': 'hvAsyat', 'attrib': [{'pos': 'fap', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hvāsyamāna', 'label': 'hvAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'hve', 'label': 'hve', 'attrib': [{'pos': 'vt1am', 'defs': 'call'}], 'isLemma': 1}
]

