import React, { useEffect, useState, useContext } from 'react';

import { MdRefresh } from 'react-icons/md';
import { Button, OverlayTrigger, Tooltip } from 'react-bootstrap';

import { UserContext } from '../../providers/UserProvider';

import './Profile.css';
import '../css/Components.css';

const ListActivities=({data, loading})=>{
	if (loading){
		return (
			<>Loading...</>
		);
	}
	else if (data.length>0){
		return (
			<>
			{data.map(recent =>(
				<div className="oneActivity" key={recent.ch+'.'+recent.ex}>
					<div className="oneActivityData">
						<div className="oneActivityEx" onClick={()=>window.location.href=`/ch=${recent.ch}/${recent.ex}`}>
							Ex {recent.ex}: {recent.exTitle}
						</div>
						<div className="oneActivityCh" onClick={()=>window.location.href=`/ch=${recent.ch}`}>
							Ch {recent.ch}: {recent.chTitle}
						</div>
					</div>
					<div className="oneActivityTime">
						<div>{recent.time[0]}</div>
						<div>{recent.time[1]}</div>
					</div>
				</div>
			))}
			</>
		);
	}
	else {
		return(
			<>
				No recent activity.
			</>
		);
	}
}

const RecentActivity=()=>{

	const [data, setData]=useState([]);
	const [refresh, setRefresh]=useState(0);
	const [loading, setLoading]=useState(false);

	const {currentUser} =useContext(UserContext);
	
	useEffect(()=>{
		// console.log('data updated:', data);
		setLoading(false);
	}, [data]);

	useEffect(()=>{
		const getDetails=async ()=>{
			// console.log("FETCHING");
			setLoading(true);
			const response = await fetch(process.env.REACT_APP_FLASK_API+`recent_activity/${currentUser.email}`);
			const data = await response.json();
			setData(data.data);
			// console.log('0data:', data);
		};
		getDetails();
	}, [currentUser.email, refresh]);

	return (
		<div className="activityContainer">
		
			<div className="activities">
				<ListActivities data={data} loading={loading}/>
			</div>

			<div className="d-flex justify-content-end" style={{marginTop:'auto'}}>
				<OverlayTrigger
					placement="top"
					overlay={<Tooltip>Click to refresh</Tooltip>}
				>
					<Button size="sm">
						<MdRefresh size={24} onClick={()=>setRefresh(1-refresh)}/>
					</Button>
				</OverlayTrigger>
			</div>

		</div>
	);
};

export default RecentActivity;