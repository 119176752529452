import React, { useState } from 'react';

import chroma from "chroma-js";
import Select, { createFilter } from "react-select";
import createFilterOptions from "react-select-fast-filter-options";

import {bhattiVocab} from './qparts/helpers/bhattiData';

import './css/Sidebar.css';
import './css/Components.css';

// Color Styles specific for bhatti
const colorStyles = {
	control: (styles) => ({ 
		...styles, 
		backgroundColor: "white", 
		// font
		fontSize: '20px',
		fontFamily: 'Praja'
	}),
	option: (styles, { data, isFocused, isSelected }) => {
		const color =chroma('#007BFF');
		// const color =chroma('#FF8400');
		return {
			...styles,
			backgroundColor: isSelected
			? color.alpha(0.8).css()
			: isFocused
			? color.alpha(0.5).css()
			: color.alpha(0.1).css(),

			color: isSelected
			? 'white'
			: 'black',

			":active": {
				...styles[":active"],

				backgroundColor:
					isSelected ? color.alpha(0.9).css() : color.alpha(0.3).css()
			}
		};
	},
	menu: base => ({
		...base,
		// font
		fontSize: '28px',
		fontFamily: 'Praja',
		// override border radius to match the box
		borderRadius: 0,
		// beautify the word cut by adding a dash see https://caniuse.com/#search=hyphens for the compatibility
		hyphens: "auto",
		// kill the gap
		marginTop: 0,
		textAlign: "left",
		// prevent menu to scroll y
		wordWrap: "break-word", 
		//z-index
		zIndex: 100,
		position: "absolute"
	}),
	menuList: base => ({
		...base,
		// kill the white space on first and last option
		padding: 0
	}),
	input: styles => ({ ...styles, minWidth: "400px", maxWidth:"500px" }),
 	placeholder: styles => ({ ...styles }),
	singleValue: (styles, { data }) => ({ ...styles })
	// menuPortal: (base) => ({ ...base, zIndex: 9999 }) 
};

// Bhatti Component
const Bhatti=()=>{

	const filterOptions = createFilterOptions({bhattiVocab});
	const [glossarySelection, setGlossarySelection] =useState('')

	const formatOptionLabel = ({ slp1, deva, def }) => (
		<div style={{ display: "flex", flexFlow: "column nowrap" }}>
			<div>{deva}</div>
			<div style={{ marginLeft: "15px", color: "rgb(60,60,60)" }}>
				{def.join(", ")}
			</div>
		</div>
	);

	return (
		<div style={{marginTop:'10vh'}}>
			<h1><span className='spChar'>B</span>hatti</h1>
			<div className='glassArea'>
				<Select
					name ="bhatti"
					id ="bhatti"
					isMulti
					isClearable
					isSearchable
					backspaceRemovesValue

					styles ={colorStyles}
					options ={bhattiVocab}
					getOptionLabel={(option)=>option['slp1']}
					getOptionValue={(option)=>option['deva']}
					maxMenuHeight={400}
					
					filterOptions ={filterOptions}
					filterOption ={createFilter({ignoreCase:false, ignoreAccents:false, trim:true,  matchFrom:'start'})}
					
					formatOptionLabel={formatOptionLabel}

					placeholder = "Dictionary"
					value ={glossarySelection}
					onChange ={(selectedOption) => setGlossarySelection(selectedOption)}
				/>
			</div>
		</div>
	);
};

export default Bhatti;