import React, {useContext, useEffect, useState} from 'react';

import Form from 'react-bootstrap/Form';
import Button from 'react-bootstrap/Button';

import BigText from './helpers/BigText';
import { keyListener } from './helpers/blocks';
import LoadingPage from "./helpers/LoadingPage";

import SandhiPopUp from './errorPopUp/SandhiPopUp';
import SandhiAnalysisPopUp from './errorPopUp/SandhiAnalysisPopUp';

import '../css/Components.css';
import { UserContext } from '../../providers/UserProvider';


// This function handles both sandhi and sandhi analysis type of question's parts
const Sandhi =({curQ, setPartInst, ch, curPi, qIndex, 
						setCurQComplete, btnClicked, setAnsSaved}) =>{
	// To autosave when next or previous buttons are clicked
	useEffect(()=>{
		if (btnClicked===true){
			checkAns();
			setAnsSaved(true);
		};
	}, [btnClicked]);

	const [loading, setLoading] =useState(false);

	const {currentUser} =useContext(UserContext);

	//Check User's Answer
	const [uans, setUans] =useState(curQ.uans);
	const [analysis, setAnalysis] = useState({});
	const checkAns = () => {
		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ uans:uans, pi: curPi,email:currentUser.email,
											qObj: {'ch':ch,'exercise':curQ.exercise, 'n':curQ.n,'s': curQ.s,'i':qIndex, 
													'parts': curQ.parts, 'text': curQ.text, 'xmlid':curQ.xmlid, 'total':curQ.total }
										})
		}
		const getFeedback =async () => {
			setLoading(true);

			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			// console.log("data.analysis:", data.analysis);
			if(Object.keys(data.analysis).length) {
				// console.log("data.analysis found !");
				setAnalysis(data.analysis);
				setPartInst(data.analysis.inst);
				setCurQComplete(data.completed);
			}

			setLoading(false);
		}
		getFeedback();
	}

	//Enter = Submit
	const [btn, setBtn]=useState(null);
	useEffect(() => {
		if (btn){
			// create an eventListener
			window.addEventListener('keydown', e=>keyListener(e, btn));
			// cleanup this component
			return () => {
				window.removeEventListener('keydown', e=>keyListener(e, btn));
			};
		}
	}, [btn]);
	
	return (
		<div>
			<LoadingPage loading={loading} />

			{curQ.takeInput &&
			<>
				<span style={{fontFamily:'Praja'}}>
					{typeof(curQ.text)==='object'
					?
						<div className="d-flex align-items-center">
							<BigText ch={ch} sp="Q:"/>
							<div className="analysis">
								<BigText ch={ch} text={curQ.text[0]} />
								<h4>{curQ.text[1]}</h4>
							</div>
						</div>
					:
						<BigText ch={ch} sp="Q:" text={curQ.text}/>
					}
				</span>
				
				<div className="FlexBox">
					<BigText ch={ch} sp="A:" />
					<div className="AnsForm">
						{analysis.show && 
							// <Feedback />
							<div>
								<div className="analysis" style={{fontFamily:'Praja'}}>
									{curQ.parts[curPi]==='sandhi' &&									
										<SandhiPopUp ch={ch}
											devaUnits={analysis.text.devaUnits}
											romanUnits={analysis.text.romanUnits}
										/>
									}
									{curQ.parts[curPi]==='sandhi-analysis' &&							
										<SandhiAnalysisPopUp ch={ch}
											devaUnits={analysis.text.devaUnits}
											romanUnits={analysis.text.romanUnits}
										/>
									}
								</div>
							</div>
						}
						{console.log('analysis', analysis)}
						{analysis.show===false &&
							<>
								{curQ.parts[curPi]==='sandhi' &&
									<div style={{marginBottom:'10px'}}>
										<div style={{fontFamily:'Praja'}}>
											<BigText ch={ch} text={analysis.transcodings.deva} />
										</div>
										<div>
											{analysis.transcodings.roman}
										</div>
									</div>
								}
							</>
						}

						<Form>
							<Form.Control autoFocus as="textarea" value={uans}
												placeholder="Type your answer"
												onChange={e=>setUans(e.target.value)}
												onFocus={e => {
													var val = e.target.value;
													e.target.value = '';
													e.target.value = val;
												}}
							/>
							<div className="KeepRight">
								<Button onClick={()=>checkAns()} ref={node=>setBtn(node)}>Check</Button>
							</div>
						</Form>
					</div>
				</div>
			</>
			}
		</div>
	);
};

export default Sandhi;