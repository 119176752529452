import React, { useState } from "react";
import {Popover, OverlayTrigger } from 'react-bootstrap';

import BigText  from "../helpers/BigText";

import '../../css/Components.css';
import SandhiHelp from "./SandhiHelp";

//for sandhi errors
const generateTokens =(units, setHelpOpen) =>{
	let tokens = [];
	console.log('units:', units);
	for (let i=0; i<units.length; i++){
		let unit = units[i];
		// if true, no need of popover and highlighting
		if(unit['type'] === 'm')
			tokens.push(<span key={i}>{unit['text']}</span>);
		
		// else, error which needs to be highlighted
		else{	
			let styleColor ={backgroundColor:"red"};
			let backgroundColor ="var(--popoverRed)"
			if(unit['type']==='b'){
				styleColor={backgroundColor:"var(--primary-color)"};
				backgroundColor= "var(--popoverBlue)"
			}

			let popover =(
				<Popover id="popover-basic">
					<Popover.Header as="h3"  style={{backgroundColor: backgroundColor, textAlign:"center"}}>
						{unit['popover']['head']}
					</Popover.Header>
					<Popover.Body>
						{unit['popover']['message']}
					</Popover.Body>
				</Popover>
			);
			
			tokens.push(
				<a key={i} target="_blank" rel="noopener noreferrer" onClick={()=>setHelpOpen(true)}>
					<OverlayTrigger key={i} trigger={["hover", "focus"]} placement="right" overlay={popover}>
						<span style={styleColor}>
							{unit['text']}
						</span>
					</OverlayTrigger>
				</a>
			);
		}
	};

	return tokens;
};

const SandhiPopUp = (props) => {
	const [helpOpen, setHelpOpen] =useState(false);
	const [pdfFile, setPDFfile]=useState("appendixD2.1-VowelSandhi.pdf");

	let devaTokens = generateTokens(props.devaUnits, setHelpOpen);
	let romanTokens =generateTokens(props.romanUnits, setHelpOpen);

	return(
		<>
			<span style={{fontFamily:'Praja'}}>
				<BigText ch={props.ch} text={devaTokens} />
			</span>
			<br/>
			<SandhiHelp open={helpOpen} setOpen={setHelpOpen} helpPDF={pdfFile} setHelpPDF={setPDFfile}/>
			{romanTokens}
		</>
	);	
};

export default SandhiPopUp;