import React, { useState, useEffect } from 'react';

import {Row, Col} from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Modal from 'react-bootstrap/Modal'
import Button from 'react-bootstrap/Button';

import { InterfaceError } from '../helpers/blocks';

import '../../css/Components.css';

const CompoundTypeInterface=props=>{
	const [ans, setAns]=useState('');
	
	const [showInterfaceError, setShowInterfaceError]=useState(false);

	const [interfaceBtn, setInterfaceBtn] =useState(null)
	useEffect(() => {
		if (interfaceBtn){
			// create an eventListener
			window.addEventListener('keydown', e=>handleKeyDown(e));
			// cleanup this component
			return () => {
				window.removeEventListener('keydown', e=>handleKeyDown(e));
			};
		}
	}, [interfaceBtn]);

	const handleKeyDown=(event)=>{
		if(event.key==='Enter'){
			event.preventDefault();
			// console.log('MODAL Enter:')
			interfaceBtn.click();	
		}
	};

	const isDisabled=()=>{
		return ans==='';
	};
	const handleSubmit=()=>{
		if (isDisabled()){
			setShowInterfaceError(true);
			return;
		}
		let values = [...props.ansstate];
		values[props.index].cType=ans;

		props.setans(values);
		props.onHide();
	};

	return(
		<Modal
			{...props}
			size="md"
			centered
		>
			<InterfaceError showInterfaceError={showInterfaceError} setShowInterfaceError={setShowInterfaceError} />

			<Modal.Header closeButton>
				<div className="widthVW interfaceTitle">
					{props.title}
				</div>
			</Modal.Header>
			
			<Modal.Body>
				<div style={{fontSize:'120%', marginBottom:'15px'}}><span className="spChar">C</span>ompound <span className="spChar">T</span>ype</div>
				<Form.Group>
					<Row>
						<Col md className="interfaceCol">
							<div className="colHeader">
								<strong><span className="spChar">cd</span> dvandva</strong>
							</div>
							<Form.Check 
								id="cdi"
								type="radio"
								label="cdi itaretara-dvandva"
								name="ctypeOptions"
								onClick={()=>setAns('cdi')}
								style={{margin:'0px 10px'}}
							/>
							<Form.Check 
								id="cds"
								type="radio"
								label="cds samāhāra-dvandva"
								name="ctypeOptions"
								onClick={()=>setAns('cds')}
								style={{margin:'0px 10px'}}
							/>
							<div className="colHeader">
								<strong><span className="spChar">ct</span> tatpuruṣa</strong>
							</div>
							<Form.Check 
								id="ck"
								type="radio"
								label="ck karmadhāraya"
								name="ctypeOptions"
								onClick={()=>setAns('ck')}
								style={{margin:'0px 10px'}}
							/>
							<Form.Check 
								id="ctp2"
								type="radio"
								label="ctp2 dvitīyā-tatpuruṣa"
								name="ctypeOptions"
								onClick={()=>setAns('ctp2')}
								style={{margin:'0px 10px'}}
							/>
							<Form.Check 
								id="ctp3"
								type="radio"
								label="ctp3 tr̥tīyā-tatpuruṣa"
								name="ctypeOptions"
								onClick={()=>setAns('ctp3')}
								style={{margin:'0px 10px'}}
							/>
							<Form.Check 
								id="ctp4"
								type="radio"
								label="ctp4 caturthī-tatpuruṣa"
								name="ctypeOptions"
								onClick={()=>setAns('ctp4')}
								style={{margin:'0px 10px'}}
							/>
							<Form.Check 
								id="ctp5"
								type="radio"
								label="ctp5 pañcamī-tatpuruṣa"
								name="ctypeOptions"
								onClick={()=>setAns('ctp5')}
								style={{margin:'0px 10px'}}
							/>
							<Form.Check 
								id="ctp6"
								type="radio"
								label="ctp6 ṣaṣṭhī-tatpuruṣa"
								name="ctypeOptions"
								onClick={()=>setAns('ctp6')}
								style={{margin:'0px 10px'}}
							/>
							<Form.Check 
								id="ctp7"
								type="radio"
								label="ctp7 saptamī-tatpuruṣa"
								name="ctypeOptions"
								onClick={()=>setAns('ctp7')}
								style={{margin:'0px 10px'}}
							/>
							<Form.Check 
								id="ctp_na"
								type="radio"
								label="ctp_na nañ-tatpuruṣa"
								name="ctypeOptions"
								onClick={()=>setAns('ctp_na')}
								style={{margin:'0px 10px'}}
							/>
							<Form.Check 
								id="ctp_upapada"
								type="radio"
								label="ctp_upapada upapada-tatpuruṣa"
								name="ctypeOptions"
								onClick={()=>setAns('ctp_upapada')}
								style={{margin:'0px 10px'}}
							/>
							<Form.Check 
								id="ctp_gati"
								type="radio"
								label="ctp_gati gati-tatpuruṣa"
								name="ctypeOptions"
								onClick={()=>setAns('ctp_gati')}
								style={{margin:'0px 10px'}}
							/>
							<Form.Check 
								id="ctp_"
								type="radio"
								label="ctp_ prādi-tatpuruṣa"
								name="ctypeOptions"
								onClick={()=>setAns('ctp_')}
								style={{margin:'0px 10px'}}
							/>
							<Form.Check 
								id="c_"
								type="radio"
								label="c_ dvigu"
								name="ctypeOptions"
								onClick={()=>setAns('c_')}
								style={{margin:'0px 10px'}}
							/>

							<Form.Check 
								id="cbv"
								type="radio"
								label="cbv bahuvrīhi"
								name="ctypeOptions"
								onClick={()=>setAns('cbv')}
								style={{fontWeight:'bold', margin:'10px 0px'}}
							/>

							<Form.Check 
								id="cab"
								type="radio"
								label="cab avyayībhāva"
								name="ctypeOptions"
								onClick={()=>setAns('cab')}
								style={{fontWeight:'bold', margin:'10px 0px'}}
							/>
						</Col>
					</Row>
				</Form.Group>
			</Modal.Body>
			
			<Modal.Footer>
				<Button variant="primary"
							ref ={node=>setInterfaceBtn(node)}
							onClick={handleSubmit}
				>
					Submit
				</Button>
				<Button onClick={()=>props.onHide()} variant="outline-danger">Close</Button>
			</Modal.Footer>

		</Modal>
	)
};

export default CompoundTypeInterface;