import React, {useContext} from 'react';

import Button from 'react-bootstrap/Button';
import {UserContext} from '../providers/UserProvider';

import './css/Components.css';

const Home = () => {
	const {currentUser} =useContext(UserContext);
	const greeting =<h3>Hi !
								<br/> Welcome to The Sanskrit Library's
								<br /> Śabdabrahman Exercise Platform !
							</h3>
	
	
	const notLoggedIn =() =>(
		<>
			{greeting}
			<br/>
			<h5>Please Login to proceed !</h5>
			<div className="text-center mt-3">
				<Button href="/login" variant="primary" size="lg" style={{color:'white'}}>Login</Button>
			</div>
		</>
	);
	const loggedIn =() =>(
		<>
			{greeting}
			<br/>
			<h5>Please proceed to the chapters</h5>
			<div className="text-center mt-3">
				<Button href="/ch" variant="primary" size="lg" style={{color:'white'}}>Chapters</Button>
			</div>
		</>
	);

	return(
		
		<div className="Home"  style={{ wordWrap:"break-word" }}>
			{/* {console.log('@Home.js:', currentUser)} */}
			{currentUser===null && notLoggedIn()}
			{currentUser!==null && loggedIn()}
		</div>
	
	);
};

export default Home;
