import React, {useState, useEffect, useContext} from "react";
import  {UserContext} from "../../providers/UserProvider";

import { Button,  Form} from "react-bootstrap";
import ImageFadeIn from "react-image-fade-in";

import Select, { createFilter } from "react-select";

import BigText from './helpers/BigText';
import LoadingPage from "./helpers/LoadingPage";

import cross from './helpers/cross.png';
import question from './helpers/questionOrange.png';

import { Box, getBorderColor, keyListener, ShowTree } from "./helpers/blocks";

import './helpers/blocks.css';
import '../css/Components.css';
import '../css/Syntax.css';

const Syntax =({curQ, ch, curPi, qIndex, setPartInst, curQComplete,
						setCurQComplete, btnClicked, setAnsSaved}) =>{
	// curQ.text.boxes is a list. 0th element is the qDeva and the rest of them are boxes
	const {currentUser} = useContext(UserContext);
	
	const [loading, setLoading] =useState(false);
	const [ansState, setAnsState] =useState(curQ.uans);
	const [completed, setCompleted] =useState('');

	useEffect(()=>{
		if (curQ.text.allComplete)
			setCompleted('all');
		else if (curQ.text.primaryComplete)
			setCompleted('primary');
		else
			setCompleted('');
	}, [curQ]);
	// console.log('completed', completed);
	// Helper Functions
	const getPlaceholder =(index, key, defaultVal)=>{
		if (key ==="relation" && ansState[index][key]){
			let robj =curQ.text.primaryOptions[index].find(o=>o.value ===ansState[index][key])
			return robj.deva;
		}
		if (key ==="secondaryRelation" && ansState[index][key]){
			let robj =curQ.text.secondaryOptions[index].find(o=>o.value ===ansState[index][key])
			return robj.deva;
		}

		else if (key==="parent" && ansState[index]['parentVal'].length >0){
			return ansState[index]['parentVal'].join(', ');
		}
		else if (key==="targets" && ansState[index]['targetsVal'].length >0){
			return ansState[index]['targetsVal'].join(', ');
		}
		else
			return '';
	};

	const formatRelationLabel =(data, meta)=>{
		if(meta.context === 'menu'){
			return (
				<div className="d-flex flex-column flex-nowrap lessMargins">
					<div className="mainLabel">
						{data.deva}
					</div>
					<div className="subLabel">
						{data.english}
					</div>
				</div>
			);
		}
		if(meta.context ==='value'){
			return(
				<div className="mainLabel lessMargins">
					{data.deva}
				</div>
			);
		}
	};

	const formatParentLabel =(data, meta)=>{
		if (meta.context ==='value'){
			return (
				<div className="mainLabel lessMargins">
					{data.label}
				</div>
			)
		}
		if (meta.context ==='menu'){
			if (data.parentIdx ===0 || data.parentIdx > 100){
				return (
					<div className="d-flex flex-column flex-nowrap lessMargins">
						<div className="mainLabel">
							{data.label}
						</div>
						<div className="subLabel">
							{data.value}
						</div>
					</div>
				)
			}
			else{
				return(
					<div className="mainLabel lessMargins">
						{data.label}
					</div>
				)
			}
		}
	};

	// Handle change
	const handleSelectChange = (index, name, selected) => {
		console.log(selected);
		const values = {...ansState};

		if (selected===null)
			selected ={value:''};
		
		// console.log('selected', selected);
		// console.log('values', values[index]);
		switch(name){
			case "relation":
				values[index]['relation'] = selected.value;
				if (selected.value ==='praDAnakriyA'){
					values[index]['disableParent'] = true;
					values[index]['parentIdx'] =[];
					values[index]['parentVal'] =[];
				}
				else{
					values[index]['disableParent'] = false;
				}
				break;
			case "parent":
				values[index]['parentVal'] =[];
				values[index]['parentIdx'] =[];
				selected.map(cur =>{
					values[index]['parentVal'].push(cur.label);
					values[index]['parentIdx'].push(cur.parentIdx);
					return null;
				});
				break;

			case "secondaryRelation":
				values[index]['secondaryRelation'] = selected.value;
				break;
			case "targets":
				values[index]['targetsVal'] =[];
				values[index]['targetsIdx'] =[];
				selected.map(cur =>{
					values[index]['targetsVal'].push(cur.label);
					values[index]['targetsIdx'].push(cur.parentIdx);
					return null;
				});
				break;

			default:
				break;
		}
		
		setAnsState(values);
	};

	//Check User's Answer
	// Checking the user's answers

	//checking box or all depending on checkType
	const check =(index, checkType) =>{	
		// console.log("checking", checkType);
		// console.log('sending', ansState);
		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({ uans: ansState, pi: curPi, email: currentUser.email, checkType: checkType,
											qObj: {'ch':ch,'exercise':curQ.exercise, 'n':curQ.n, 's': curQ.s, 'ai':index,
													'i':qIndex, 'parts': curQ.parts, 'text': curQ.text, 'xmlid':curQ.xmlid, 'total':curQ.total}
										})
		};
	
		const getFeedback =async () => {
			setLoading(true);

			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			console.log("data:", data);

			setPartInst(data.analysis.inst);
			setAnsState(data.analysis.afterState);
			setCurQComplete(data.completed);

			if (data.analysis.allComplete)
				setCompleted('all');
			else if(data.analysis.primaryComplete)
				setCompleted('primary');
			else
				setCompleted('');

			setLoading(false);
		};
		getFeedback();
	};

	//Enter = Submit
	const [btn, setBtn]=useState(null)
	useEffect(() => {
		if (btn){
			// create an eventListener
			console.log('syntax keyListener');
			window.addEventListener('keydown', e=>keyListener(e, btn));
		}
	}, [btn]);
	useEffect(()=>{
		console.log('mounting syntax');
		return () => {
			// Cleanup
			console.log('unmounting syntax');
			window.removeEventListener('keydown', e=>keyListener(e, btn));
		};
	}, []);

	// To autosave when next or previous buttons are clicked
	useEffect(()=>{
		if (btnClicked===true){
			check(0, 'All');
			setAnsSaved(true);
		};
	}, [btnClicked, check, setAnsSaved]);

	// Select styles object
	const selectStyles ={
		placeholder: defaultStyles=>{
			return {
				...defaultStyles,
				fontSize: '20pt',
				fontFamily: 'Praja',

				marginTop: '3px'
			}
		}
	};

	return (
		<div>
			<ShowTree xmlid={curQ.xmlid} completed={completed}/>

			<LoadingPage loading={loading} />
			
			{curQ.takeInput &&
			<>
				<div className="d-flex align-items-center">
					<BigText ch={ch} sp="Q:" />
					<span style={{fontFamily:'Praja'}}>
						<BigText ch={ch} text={curQ.text.boxes[0]} />
					</span>
				</div>
	
				<div className="FlexBox">
					<BigText ch={ch} sp="A:" />
					<div className="AnsForm">
						<div className="d-flex-column">
							
							<div className="containerBox">
								{curQ.text.boxes.slice(1).map((cur, index)=>{
									
									let uans =ansState[index+1];
									let borderColor =getBorderColor((uans.relationError || uans.parentError || uans.secondaryError || uans.targetsError), uans.allCorrect);

									return (
										<Box key={`${cur.title}${index}`} borderColor={borderColor}>
											<div style={{'textAlign':'center'}}>
												<div style={{fontFamily:'Praja', fontSize:'20pt'}}>
													{cur.title}
												</div>
												<div className='line'/>
												
												{[cur.inflection, cur.pos].map((value, i)=>{
													if (value.length)
														return (<div key={i}>{value}</div>);
													else
														return (<br key={i}/>);
												})}
												{cur.addRoot===true &&
													<div>{cur.root}, {cur.rootPos}</div>
												}
												<Form.Control className="text-center" type="text" value={cur.trans} readOnly />
											</div>

											{curQ.text.validIdx.includes(index+1) &&
												<>
												{ansState[index+1]['primary'] && 
													<div className="inputGroup">
														<div style={{minWidth:'100%'}}>
															<label htmlFor={`"relation"${index}`} 
																	className="boxLabel smText">
															Syntactic role
															{ansState[index+1]['relationError']===true&&
																<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
																width={25} height={25} src={cross} alt='wrong'/>
															}
															{ansState[index+1]['relationError']==='?'&&
																<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
																width={25} height={25} src={question} alt='empty'/>
															}
															</label>
															<Select
																isClearable
																backspaceRemovesValue
																
																name ="relation"
																id ={`"relation"${index+1}`}
																aria-describedby ={`"relationError"${index+1}`}
																
																styles ={selectStyles}
																options ={curQ.text.primaryOptions[index+1]}
																maxMenuHeight ={200}

																filterOption ={createFilter({ignoreCase:false, ignoreAccents:false, trim:true,  matchFrom:'start'})}
																formatOptionLabel ={formatRelationLabel}

																placeholder ={getPlaceholder(index+1, "relation", "Syntactic role")}
																onChange ={(selectedOption) => handleSelectChange(index+1, "relation", selectedOption)}
															/>
															
														</div>
														
														{!ansState[index+1]['disableParent'] &&
														<div style={{minWidth:'100%'}}>
															<label htmlFor={`"relation"${index}`} 
																	className="boxLabel smText">
															Parent
															{ansState[index+1]['parentError']===true&&
																<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
																width={25} height={25} src={cross} alt='wrong'/>
															}
															{ansState[index+1]['parentError']==='?'&&
																<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
																width={25} height={25} src={question} alt='empty'/>
															}
															</label>
															<Select
																isMulti
																isClearable
																backspaceRemovesValue

																isDisabled ={ansState[index+1]['disableParent']}
																
																name="parent"
																id={`"parent"${index+1}`}
																aria-describedby={`"parentError"${index+1}`}
																
																styles ={selectStyles}
																options={curQ.text.parents}
																maxMenuHeight={200}

																filterOption={createFilter({ignoreCase:false, ignoreAccents:false, trim:true,  matchFrom:'start'})}
																formatOptionLabel ={formatParentLabel}

																placeholder={getPlaceholder(index+1, "parent", "Parent")}
																onChange={(selectedOption) => handleSelectChange(index+1, "parent", selectedOption)}
															/>
															
														</div>
														}
													</div>
												}
												{ansState[index+1]['secondary'] && 
													<div className="inputGroup">
														<div style={{minWidth:'100%'}}>
															<label htmlFor={`"secondaryRelation"${index}`} 
																	className="boxLabel smText">
															Secondary role
															{ansState[index+1]['secondaryError']===true&&
																<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
																width={25} height={25} src={cross} alt='wrong'/>
															}
															{ansState[index+1]['secondaryError']==='?'&&
																<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
																width={25} height={25} src={question} alt='empty'/>
															}
															</label>
															<Select
																isClearable
																backspaceRemovesValue
																
																name ="secondaryRelation"
																id ={`"secondaryRelation"${index+1}`}
																aria-describedby ={`"secondaryError"${index+1}`}
																
																styles ={selectStyles}
																options ={curQ.text.secondaryOptions[index+1]}
																maxMenuHeight ={200}

																filterOption ={createFilter({ignoreCase:false, ignoreAccents:false, trim:true,  matchFrom:'start'})}
																formatOptionLabel ={formatRelationLabel}

																placeholder ={getPlaceholder(index+1, "secondaryRelation", "Secondary role")}
																onChange ={(selectedOption) => handleSelectChange(index+1, "secondaryRelation", selectedOption)}
															/>
															
														</div>
														
														<div style={{minWidth:'100%'}}>
															<label htmlFor={`"relation"${index}`} 
																	className="boxLabel smText">
															Targets
															{ansState[index+1]['targetsError']===true&&
																<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
																width={25} height={25} src={cross} alt='wrong'/>
															}
															{ansState[index+1]['targetsError']==='?'&&
																<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
																width={25} height={25} src={question} alt='empty'/>
															}
															</label>
															<Select
																isMulti
																isClearable
																backspaceRemovesValue

																name="targets"
																id={`"targets"${index+1}`}
																aria-describedby={`"targetsError"${index+1}`}
																
																styles ={selectStyles}
																options={curQ.text.parents}
																maxMenuHeight={200}

																filterOption={createFilter({ignoreCase:false, ignoreAccents:false, trim:true,  matchFrom:'start'})}
																formatOptionLabel ={formatParentLabel}

																placeholder={getPlaceholder(index+1, "targets", "Targets")}
																onChange={(selectedOption) => handleSelectChange(index+1, "targets", selectedOption)}
															/>
															
														</div>
													</div>
												}
												<div style={{minWidth:'100%', 
														display:'flex', justifyContent:'flex-end'}}>
													<Button variant='outline-primary' size='sm' 
														style={{marginTop:'10px'}}
														onClick={e=>check(index+1, 'Box')}>Check
													</Button>
												</div>
												</>
											}

										</Box>
									)
								})}
							</div>
						</div>
					
						<div className="KeepRight">
							<Button variant='primary' onClick={e=>check(0, 'All')} 
										ref={node=>setBtn(node)}>
									Check all
							</Button>
						</div>
						
					</div>

				</div>
			</>
			}
		</div>
	);
};

export default Syntax;