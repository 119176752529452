import firebase from "firebase/app";
import "firebase/auth";
// import "firebase/firestore";

const firebaseConfig = {
	apiKey: "AIzaSyCZjhzPcsAiP59M9mYWdcykFjskD0pZQLk",
	authDomain: "sanskrit-textbook.firebaseapp.com",
	databaseURL: "https://sanskrit-textbook.firebaseio.com",
	projectId: "sanskrit-textbook",
	storageBucket: "sanskrit-textbook.appspot.com",
	messagingSenderId: "192932766337",
	appId: "1:192932766337:web:20469bee8cd76e785be380",
	measurementId: "G-FDXN3RJLY8"
};
firebase.initializeApp(firebaseConfig);

export const auth = firebase.auth();
// export const firestore = firebase.firestore();

// Second app instance to create new users without signing them in
export const appImage =firebase.initializeApp(firebaseConfig, 'Secondary').auth();