import React from 'react';
import {useHistory} from 'react-router-dom';

import Button from 'react-bootstrap/Button';

const Error =({message='Something is wrong'}) =>{
	let history=useHistory();
	return(
		<div style={{marginTop:'20vh'}}>
			<h1 style={{maxWidth:'max(500px, 65vw)', textAlign:'center'}}>{message}</h1>
			<div className="d-flex justify-content-center mt-4">
				<Button onClick={() => {history.goBack();}}>
					Go Back
				</Button>
			</div>
		</div>
	);
};

export default Error;