import React from 'react';

import {Row, Col} from "react-bootstrap";
import Form from "react-bootstrap/Form";

import "../../css/Components.css";

const RootOptions=({updateInput})=>(
	<Form.Group>
		<Form.Check 
			id="root_p"
			type="radio"
			label="primary"
			name="rootOptions"
			defaultChecked={true}
			onClick={()=>updateInput('root', 'p')}
		/>
		<Form.Check 
			id="root_c"
			type="radio"
			label="c causitive"
			name="rootOptions"
			onClick={()=>updateInput('root', 'c')}
		/>
		<Form.Check 
			id="root_d"
			type="radio"
			label="d desiderative"
			name="rootOptions"
			onClick={()=>updateInput('root', 'd')}
		/>
		<Form.Check 
			id="root_i"
			type="radio"
			label="i intensive"
			name="rootOptions"
			onClick={()=>updateInput('root', 'i')}
		/>
	</Form.Group>
);

const TenseOptions=({updateInput})=>(
	<Form.Group>
		<Form.Check 
			id="tense_pre"
			type="radio"
			label="pre present"
			name="tenseOptions"
			onClick={()=>updateInput('tense', 'pre')}
		/>
		<Form.Check 
			id="tense_ipf"
			type="radio"
			label="ipf imperfect"
			name="tenseOptions"
			onClick={()=>updateInput('tense', 'ipf')}
		/>
		<Form.Check 
			id="tense_ipv"
			type="radio"
			label="ipv imperative"
			name="tenseOptions"
			onClick={()=>updateInput('tense', 'ipv')}
		/>
		<Form.Check 
			id="tense_pop"
			type="radio"
			label="pop present optative"
			name="tenseOptions"
			onClick={()=>updateInput('tense', 'pop')}
		/>
		<Form.Check 
			id="tense_pij"
			type="radio"
			label="pij present injuctive"
			name="tenseOptions"
			onClick={()=>updateInput('tense', 'pij')}
		/>
		<Form.Check 
			id="tense_fut"
			type="radio"
			label="fut future"
			name="tenseOptions"
			onClick={()=>updateInput('tense', 'fut')}
		/>
		<Form.Check 
			id="tense_con"
			type="radio"
			label="con conditional"
			name="tenseOptions"
			onClick={()=>updateInput('tense', 'con')}
		/>
		<Form.Check 
			id="tense_pft"
			type="radio"
			label="pft periphrastic future"
			name="tenseOptions"
			onClick={()=>updateInput('tense', 'pft')}
		/>
		<Form.Check 
			id="tense_prf"
			type="radio"
			label="prf perfect"
			name="tenseOptions"
			onClick={()=>updateInput('tense', 'prf')}
		/>
		<Form.Check 
			id="tense_aor"
			type="radio"
			label="aor aorist"
			name="tenseOptions"
			onClick={()=>updateInput('tense', 'aor')}
		/>
		<Form.Check 
			id="tense_aop"
			type="radio"
			label="aop aorist optative"
			name="tenseOptions"
			onClick={()=>updateInput('tense', 'aop')}
		/>
		<Form.Check 
			id="tense_aij"
			type="radio"
			label="aij aorist injuctive"
			name="tenseOptions"
			onClick={()=>updateInput('tense', 'aij')}
		/>

	</Form.Group>
);

const VoiceOptions=({updateInput})=>(
	<Form.Group>
		<Form.Check 
			id="voice_a"
			type="radio"
			label="a active"
			name="voiceOptions"
			onClick={()=>updateInput('voice', 'a')}
		/>
		<Form.Check 
			id="voice_m"
			type="radio"
			label="m middle"
			name="voiceOptions"
			onClick={()=>updateInput('voice', 'm')}
		/>
		<Form.Check 
			id="voice_p"
			type="radio"
			label="p passive"
			name="voiceOptions"
			onClick={()=>updateInput('voice', 'p')}
		/>
	</Form.Group>
);
const PersonOptions=({updateInput})=>(
	<Form.Group>
		<Form.Check 
			id="person_1"
			type="radio"
			label="1 first"
			name="personOptions"
			onClick={()=>updateInput('person', '1')}
		/>
		<Form.Check 
			id="person_2"
			type="radio"
			label="2 second"
			name="personOptions"
			onClick={()=>updateInput('person', '2')}
		/>
		<Form.Check 
			id="person_3"
			type="radio"
			label="3 third"
			name="personOptions"
			onClick={()=>updateInput('person', '3')}
		/>
	</Form.Group>
);
const NumberOptions=({updateInput})=>(
	<Form.Group>
		<Form.Check 
			id="number_s"
			type="radio"
			label="s singular"
			name="numberOptions"
			onClick={()=>updateInput('number', 's')}
		/>
		<Form.Check 
			id="number_d"
			type="radio"
			label="d dual"
			name="numberOptions"
			onClick={()=>updateInput('number', 'd')}
		/>
		<Form.Check 
			id="number_p"
			type="radio"
			label="p plural"
			name="numberOptions"
			onClick={()=>updateInput('number', 'p')}
		/>
	</Form.Group>
);

const Verb=({inputParts, setInputParts})=>{

	const updateInput=(key, value)=>{
		let newParts = {...inputParts};
		newParts[key]=value;
		setInputParts(newParts);
		// console.log(newParts);
	}

	return (
		<>
		<Row>
			<Col md="auto" className="interfaceCol">
				<div className="colHeader">
					<span className="spChar">R</span>oot type
				</div>
				<RootOptions updateInput={(key, value)=>updateInput(key, value)}/>
			</Col>
			<Col md='auto' className="interfaceCol">
				<div className="colHeader">
					<span className="spChar">T</span>ense
				</div>
				<TenseOptions updateInput={(key, value)=>updateInput(key, value)}/>
			</Col>
			<Col md className="interfaceCol">
				<div className="colHeader">
					<span className="spChar">V</span>oice
				</div>
				<VoiceOptions updateInput={(key, value)=>updateInput(key, value)}/>
			</Col>
			<Col md className="interfaceCol">
				<div className="colHeader">
					<span className="spChar">P</span>erson
				</div>
				<PersonOptions updateInput={(key, value)=>updateInput(key, value)}/>
			</Col>
			<Col md className="interfaceCol">
				<div className="colHeader">
					<span className="spChar">N</span>umber
				</div>
				<NumberOptions updateInput={(key, value)=>updateInput(key, value)}/>
			</Col>
		</Row>
		
		</>
	);
};

export default Verb;