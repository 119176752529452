export const vocab_deva = [
	{'value': 'अग्नि', 'label': 'agni', 'attrib': [{'pos': 'm', 'defs': 'fire'}], 'isLemma': 1},
	{'value': 'अग्र', 'label': 'agra', 'attrib': [{'pos': 'n', 'defs': 'tip, beginning'}], 'isLemma': 1},
	{'value': 'अङ्गुलीय', 'label': 'aNgulIya', 'attrib': [{'pos': 'n', 'defs': 'ring'}], 'isLemma': 1},
	{'value': 'अतस्', 'label': 'atas', 'attrib': [{'pos': 'dem_adv', 'defs': 'hence'}], 'isLemma': 1},
	{'value': 'अति', 'label': 'ati', 'attrib': [{'pos': 'preverb', 'defs': 'beyond'}], 'isLemma': 1},
	{'value': 'अति_इ', 'label': 'ati_i', 'attrib': [{'pos': 'vt2a', 'defs': 'go beyond, pass'}], 'isLemma': 1},
	{'value': 'अतिथि', 'label': 'atiTi', 'attrib': [{'pos': 'm', 'defs': 'guest'}], 'isLemma': 1},
	{'value': 'अतियत्', 'label': 'atiyat', 'attrib': [{'pos': 'prap', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'अतीत', 'label': 'atIta', 'attrib': [{'pos': 'ppp', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'अतीतवत्', 'label': 'atItavat', 'attrib': [{'pos': 'pap', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'अतीतव्य', 'label': 'atItavya', 'attrib': [{'pos': 'ger', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'अतीत्य', 'label': 'atItya', 'attrib': [{'pos': 'abs', 'defs': 'go beyond, pass'}, {'pos': 'ger', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'अतीयमान', 'label': 'atIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'अतीयान', 'label': 'atIyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'अतीयिवस्', 'label': 'atIyivas', 'attrib': [{'pos': 'pfap', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'अत्तव्य', 'label': 'attavya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अत्तुम्', 'label': 'attum', 'attrib': [{'pos': 'inf', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अत्त्वा', 'label': 'attvA', 'attrib': [{'pos': 'abs', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अत्ययनीय', 'label': 'atyayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'अत्येतुम्', 'label': 'atyetum', 'attrib': [{'pos': 'inf', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'अत्येष्यत्', 'label': 'atyezyat', 'attrib': [{'pos': 'fap', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'अत्येष्यमाण', 'label': 'atyezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go beyond, pass'}], 'isLemma': 0, 'parents': ['ati_i']},
	{'value': 'अत्र', 'label': 'atra', 'attrib': [{'pos': 'dem_adv', 'defs': 'here'}], 'isLemma': 1},
	{'value': 'अत्स्यत्', 'label': 'atsyat', 'attrib': [{'pos': 'fap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अत्स्यमान', 'label': 'atsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अथ', 'label': 'aTa', 'attrib': [{'pos': 'pcl', 'defs': 'then, now'}], 'isLemma': 1},
	{'value': 'अद्', 'label': 'ad', 'attrib': [{'pos': 'vt2a', 'defs': 'eat'}], 'isLemma': 1},
	{'value': 'अदत्', 'label': 'adat', 'attrib': [{'pos': 'prap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अदनीय', 'label': 'adanIya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अदस्', 'label': 'adas', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'that'}], 'isLemma': 1},
	{'value': 'अद्य', 'label': 'adya', 'attrib': [{'pos': 'adv', 'defs': 'today'}], 'isLemma': 1},
	{'value': 'अद्यमान', 'label': 'adyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अधि', 'label': 'aDi', 'attrib': [{'pos': 'preverb', 'defs': 'over'}], 'isLemma': 1},
	{'value': 'अधिजगान', 'label': 'aDijagAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अधि_इ', 'label': 'aDi_i', 'attrib': [{'pos': 'vt2m', 'defs': 'go over, study, read, recite'}], 'isLemma': 1},
	{'value': 'अधीत', 'label': 'aDIta', 'attrib': [{'pos': 'ppp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अधीतवत्', 'label': 'aDItavat', 'attrib': [{'pos': 'pap', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अधीत्य', 'label': 'aDItya', 'attrib': [{'pos': 'abs', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अधीयमान', 'label': 'aDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अधीयान', 'label': 'aDIyAna', 'attrib': [{'pos': 'prmp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अधुना', 'label': 'aDunA', 'attrib': [{'pos': 'adv', 'defs': 'now'}], 'isLemma': 1},
	{'value': 'अध्ययनीय', 'label': 'aDyayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अध्येतव्य', 'label': 'aDyetavya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अध्येतुम्', 'label': 'aDyetum', 'attrib': [{'pos': 'inf', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अध्येय', 'label': 'aDyeya', 'attrib': [{'pos': 'ger', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अध्येष्यमाण', 'label': 'aDyezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'go over, study, read, recite'}], 'isLemma': 0, 'parents': ['aDi_i']},
	{'value': 'अध्वर्यु', 'label': 'aDvaryu', 'attrib': [{'pos': 'm', 'defs': 'adhvaryu'}], 'isLemma': 1},
	{'value': 'अनु', 'label': 'anu', 'attrib': [{'pos': 'preverb', 'defs': 'after'}], 'isLemma': 1},
	{'value': 'अनुगच्छत्', 'label': 'anugacCat', 'attrib': [{'pos': 'prap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुगत', 'label': 'anugata', 'attrib': [{'pos': 'ppp', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुगतवत्', 'label': 'anugatavat', 'attrib': [{'pos': 'pap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुगत्य', 'label': 'anugatya', 'attrib': [{'pos': 'abs', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुगन्तव्य', 'label': 'anugantavya', 'attrib': [{'pos': 'ger', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुगन्तुम्', 'label': 'anugantum', 'attrib': [{'pos': 'inf', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुगमनीय', 'label': 'anugamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुगमिष्यत्', 'label': 'anugamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुगमिष्यमाण', 'label': 'anugamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुगम्य', 'label': 'anugamya', 'attrib': [{'pos': 'abs', 'defs': 'go after, follow'}, {'pos': 'ger', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुगम्यमान', 'label': 'anugamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुजगन्वस्', 'label': 'anujaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुजग्मान', 'label': 'anujagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुजग्मिवस्', 'label': 'anujagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go after, follow'}], 'isLemma': 0, 'parents': ['anu_gam']},
	{'value': 'अनुजज्ञान', 'label': 'anujajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुजज्ञिवस्', 'label': 'anujajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुजानत्', 'label': 'anujAnat', 'attrib': [{'pos': 'prap', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञा', 'label': 'anujYA', 'attrib': [{'pos': 'f', 'defs': 'permission'}], 'isLemma': 1},
	{'value': 'अनुज्ञात', 'label': 'anujYAta', 'attrib': [{'pos': 'ppp', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञातवत्', 'label': 'anujYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञातव्य', 'label': 'anujYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञातुम्', 'label': 'anujYAtum', 'attrib': [{'pos': 'inf', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञानीय', 'label': 'anujYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञाय', 'label': 'anujYAya', 'attrib': [{'pos': 'abs', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञायमान', 'label': 'anujYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञास्यत्', 'label': 'anujYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञास्यमान', 'label': 'anujYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनुज्ञेय', 'label': 'anujYeya', 'attrib': [{'pos': 'ger', 'defs': 'permit, assent'}], 'isLemma': 0, 'parents': ['anu_jYA']},
	{'value': 'अनु_आ_गम्', 'label': 'anu_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come after, follow'}], 'isLemma': 1},
	{'value': 'अनु_इष्', 'label': 'anu_iz', 'attrib': [{'pos': 'vt6a', 'defs': 'seek after, search for'}], 'isLemma': 1},
	{'value': 'अनु_गम्', 'label': 'anu_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go after, follow'}], 'isLemma': 1},
	{'value': 'अनु_ज्ञा', 'label': 'anu_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'permit, assent'}], 'isLemma': 1},
	{'value': 'अनु_पत्', 'label': 'anu_pat', 'attrib': [{'pos': 'vt1a', 'defs': 'fly after, chase'}], 'isLemma': 1},
	{'value': 'अनुपतत्', 'label': 'anupatat', 'attrib': [{'pos': 'prap', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अनुपतनीय', 'label': 'anupatanIya', 'attrib': [{'pos': 'ger', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अनुपतित', 'label': 'anupatita', 'attrib': [{'pos': 'ppp', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अनुपतितवत्', 'label': 'anupatitavat', 'attrib': [{'pos': 'pap', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अनुपतितव्य', 'label': 'anupatitavya', 'attrib': [{'pos': 'ger', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अनुपतितुम्', 'label': 'anupatitum', 'attrib': [{'pos': 'inf', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अनुपतिष्यत्', 'label': 'anupatizyat', 'attrib': [{'pos': 'fap', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अनुपतिष्यमाण', 'label': 'anupatizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अनुपत्य', 'label': 'anupatya', 'attrib': [{'pos': 'abs', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अनुपत्यमान', 'label': 'anupatyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अनुपात्य', 'label': 'anupAtya', 'attrib': [{'pos': 'ger', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अनुपेतान', 'label': 'anupetAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अनुपेतिवस्', 'label': 'anupetivas', 'attrib': [{'pos': 'pfap', 'defs': 'fly after, chase'}], 'isLemma': 0, 'parents': ['anu_pat']},
	{'value': 'अन्त', 'label': 'anta', 'attrib': [{'pos': 'm', 'defs': 'end, edge, border'}], 'isLemma': 1},
	{'value': 'अन्न', 'label': 'anna', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}, {'pos': 'n', 'defs': 'food'}], 'isLemma': 1},
	{'value': 'अन्न', 'label': 'anna', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}, {'pos': 'n', 'defs': 'food'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अन्नवत्', 'label': 'annavat', 'attrib': [{'pos': 'pap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'अन्य', 'label': 'anya', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'other, else'}], 'isLemma': 1},
	{'value': 'अन्यतस्', 'label': 'anyatas', 'attrib': [{'pos': 'pron_adv', 'defs': 'from elsewhere'}], 'isLemma': 1},
	{'value': 'अन्यत्र', 'label': 'anyatra', 'attrib': [{'pos': 'pron_adv', 'defs': 'elsewhere'}], 'isLemma': 1},
	{'value': 'अन्यथा', 'label': 'anyaTA', 'attrib': [{'pos': 'pron_adv', 'defs': 'otherwise'}], 'isLemma': 1},
	{'value': 'अन्यदा', 'label': 'anyadA', 'attrib': [{'pos': 'pron_adv', 'defs': 'at another time'}], 'isLemma': 1},
	{'value': 'अन्वागच्छत्', 'label': 'anvAgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वागत', 'label': 'anvAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वागतवत्', 'label': 'anvAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वागत्य', 'label': 'anvAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वागन्तव्य', 'label': 'anvAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वागन्तुम्', 'label': 'anvAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वागमनीय', 'label': 'anvAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वागमिष्यत्', 'label': 'anvAgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वागमिष्यमाण', 'label': 'anvAgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वागम्य', 'label': 'anvAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come after, follow'}, {'pos': 'ger', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वागम्यमान', 'label': 'anvAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वाजगन्वस्', 'label': 'anvAjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वाजग्मान', 'label': 'anvAjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्वाजग्मिवस्', 'label': 'anvAjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come after, follow'}], 'isLemma': 0, 'parents': ['anu_A_gam']},
	{'value': 'अन्विच्छत्', 'label': 'anvicCat', 'attrib': [{'pos': 'prap', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अन्विष्ट', 'label': 'anvizwa', 'attrib': [{'pos': 'ppp', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अन्विष्टवत्', 'label': 'anvizwavat', 'attrib': [{'pos': 'pap', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अन्विष्य', 'label': 'anvizya', 'attrib': [{'pos': 'abs', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अन्विष्यमाण', 'label': 'anvizyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अन्वीषाण', 'label': 'anvIzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अन्वीषिवस्', 'label': 'anvIzivas', 'attrib': [{'pos': 'pfap', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अन्वेषणीय', 'label': 'anvezaRIya', 'attrib': [{'pos': 'ger', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अन्वेषिष्यत्', 'label': 'anvezizyat', 'attrib': [{'pos': 'fap', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अन्वेषिष्यमाण', 'label': 'anvezizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अन्वेष्टव्य', 'label': 'anvezwavya', 'attrib': [{'pos': 'ger', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अन्वेष्टुम्', 'label': 'anvezwum', 'attrib': [{'pos': 'inf', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अन्वेष्य', 'label': 'anvezya', 'attrib': [{'pos': 'ger', 'defs': 'seek after, search for'}], 'isLemma': 0, 'parents': ['anu_iz']},
	{'value': 'अप', 'label': 'apa', 'attrib': [{'pos': 'preverb', 'defs': 'away'}], 'isLemma': 1},
	{'value': 'अपक्रंस्यत्', 'label': 'apakraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपक्रंस्यमान', 'label': 'apakraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपक्रमणीय', 'label': 'apakramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपक्रमितव्य', 'label': 'apakramitavya', 'attrib': [{'pos': 'ger', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपक्रमितुम्', 'label': 'apakramitum', 'attrib': [{'pos': 'inf', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपक्रम्य', 'label': 'apakramya', 'attrib': [{'pos': 'abs', 'defs': 'go away, retreat'}, {'pos': 'ger', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपक्रम्यमाण', 'label': 'apakramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपक्रान्त', 'label': 'apakrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपक्रान्तवत्', 'label': 'apakrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपक्रामत्', 'label': 'apakrAmat', 'attrib': [{'pos': 'prap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपक्राम्यत्', 'label': 'apakrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपगच्छत्', 'label': 'apagacCat', 'attrib': [{'pos': 'prap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपगत', 'label': 'apagata', 'attrib': [{'pos': 'ppp', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपगतवत्', 'label': 'apagatavat', 'attrib': [{'pos': 'pap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपगत्य', 'label': 'apagatya', 'attrib': [{'pos': 'abs', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपगन्तव्य', 'label': 'apagantavya', 'attrib': [{'pos': 'ger', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपगन्तुम्', 'label': 'apagantum', 'attrib': [{'pos': 'inf', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपगमनीय', 'label': 'apagamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपगमिष्यत्', 'label': 'apagamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपगमिष्यमाण', 'label': 'apagamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपगम्य', 'label': 'apagamya', 'attrib': [{'pos': 'abs', 'defs': 'go away'}, {'pos': 'ger', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपगम्यमान', 'label': 'apagamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपचक्रमाण', 'label': 'apacakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपचक्रम्वस्', 'label': 'apacakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'go away, retreat'}], 'isLemma': 0, 'parents': ['apa_kram']},
	{'value': 'अपजगन्वस्', 'label': 'apajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपजग्मान', 'label': 'apajagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपजग्मिवस्', 'label': 'apajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go away'}], 'isLemma': 0, 'parents': ['apa_gam']},
	{'value': 'अपजहृवस्', 'label': 'apajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपजह्राण', 'label': 'apajahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अप_क्रम्', 'label': 'apa_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'go away, retreat'}], 'isLemma': 1},
	{'value': 'अप_गम्', 'label': 'apa_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go away'}], 'isLemma': 1},
	{'value': 'अप_हृ', 'label': 'apa_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'take away'}], 'isLemma': 1},
	{'value': 'अपहरणीय', 'label': 'apaharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपहरत्', 'label': 'apaharat', 'attrib': [{'pos': 'prap', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपहरमाण', 'label': 'apaharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपहरिष्यत्', 'label': 'apaharizyat', 'attrib': [{'pos': 'fap', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपहरिष्यमाण', 'label': 'apaharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपहर्तव्य', 'label': 'apahartavya', 'attrib': [{'pos': 'ger', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपहर्तुम्', 'label': 'apahartum', 'attrib': [{'pos': 'inf', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपहार्य', 'label': 'apahArya', 'attrib': [{'pos': 'ger', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपहृत', 'label': 'apahfta', 'attrib': [{'pos': 'ppp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपहृतवत्', 'label': 'apahftavat', 'attrib': [{'pos': 'pap', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपहृत्य', 'label': 'apahftya', 'attrib': [{'pos': 'abs', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपह्रियमाण', 'label': 'apahriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'take away'}], 'isLemma': 0, 'parents': ['apa_hf']},
	{'value': 'अपि', 'label': 'api', 'attrib': [{'pos': 'pcl', 'defs': 'even, also, too'}], 'isLemma': 1},
	{'value': 'अप्सरस्', 'label': 'apsaras', 'attrib': [{'pos': 'f', 'defs': 'naiad'}], 'isLemma': 1},
	{'value': 'अभि', 'label': 'aBi', 'attrib': [{'pos': 'preverb', 'defs': 'toward'}], 'isLemma': 1},
	{'value': 'अभि_आ_गम्', 'label': 'aBi_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come up to'}], 'isLemma': 1},
	{'value': 'अभि_वद्', 'label': 'aBi_vad', 'attrib': [{'pos': 'vt1a', 'defs': 'speak to'}], 'isLemma': 1},
	{'value': 'अभिवदत्', 'label': 'aBivadat', 'attrib': [{'pos': 'prap', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अभिवदनीय', 'label': 'aBivadanIya', 'attrib': [{'pos': 'ger', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अभिवदितव्य', 'label': 'aBivaditavya', 'attrib': [{'pos': 'ger', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अभिवदितुम्', 'label': 'aBivaditum', 'attrib': [{'pos': 'inf', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अभिवदिष्यत्', 'label': 'aBivadizyat', 'attrib': [{'pos': 'fap', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अभिवदिष्यमाण', 'label': 'aBivadizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अभिवाद्य', 'label': 'aBivAdya', 'attrib': [{'pos': 'ger', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अभ्यागच्छत्', 'label': 'aByAgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्यागत', 'label': 'aByAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्यागतवत्', 'label': 'aByAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्यागत्य', 'label': 'aByAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्यागन्तव्य', 'label': 'aByAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्यागन्तुम्', 'label': 'aByAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्यागमनीय', 'label': 'aByAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्यागमिष्यत्', 'label': 'aByAgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्यागमिष्यमाण', 'label': 'aByAgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्यागम्य', 'label': 'aByAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come up to'}, {'pos': 'ger', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्यागम्यमान', 'label': 'aByAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्याजगन्वस्', 'label': 'aByAjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्याजग्मान', 'label': 'aByAjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्याजग्मिवस्', 'label': 'aByAjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come up to'}], 'isLemma': 0, 'parents': ['aBi_A_gam']},
	{'value': 'अभ्युदित', 'label': 'aByudita', 'attrib': [{'pos': 'ppp', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अभ्युदितवत्', 'label': 'aByuditavat', 'attrib': [{'pos': 'pap', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अभ्युद्य', 'label': 'aByudya', 'attrib': [{'pos': 'abs', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अभ्युद्यमान', 'label': 'aByudyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अभ्यूदान', 'label': 'aByUdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अभ्यूदिवस्', 'label': 'aByUdivas', 'attrib': [{'pos': 'pfap', 'defs': 'speak to'}], 'isLemma': 0, 'parents': ['aBi_vad']},
	{'value': 'अयनीय', 'label': 'ayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'अरणि', 'label': 'araRi', 'attrib': [{'pos': 'f', 'defs': 'kindling stick'}], 'isLemma': 1},
	{'value': 'अरि', 'label': 'ari', 'attrib': [{'pos': 'm', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'अर्जुन', 'label': 'arjuna', 'attrib': [{'pos': 'm_pn', 'defs': 'Arjuna'}], 'isLemma': 1},
	{'value': 'अर्थ', 'label': 'arTa', 'attrib': [{'pos': 'm', 'defs': 'object, meaning, purpose'}], 'isLemma': 1},
	{'value': 'अर्ह्', 'label': 'arh', 'attrib': [{'pos': 'vt1a', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 1},
	{'value': 'अर्हणीय', 'label': 'arhaRIya', 'attrib': [{'pos': 'ger', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'अर्हत्', 'label': 'arhat', 'attrib': [{'pos': 'prap', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'अर्हित', 'label': 'arhita', 'attrib': [{'pos': 'ppp', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'अर्हितवत्', 'label': 'arhitavat', 'attrib': [{'pos': 'pap', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'अर्हितव्य', 'label': 'arhitavya', 'attrib': [{'pos': 'ger', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'अर्हितुम्', 'label': 'arhitum', 'attrib': [{'pos': 'inf', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'अर्हित्वा', 'label': 'arhitvA', 'attrib': [{'pos': 'abs', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'अर्हिष्यत्', 'label': 'arhizyat', 'attrib': [{'pos': 'fap', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'अर्हिष्यमाण', 'label': 'arhizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'अर्ह्य', 'label': 'arhya', 'attrib': [{'pos': 'ger', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'अल्प', 'label': 'alpa', 'attrib': [{'pos': 'adj', 'defs': 'small'}], 'isLemma': 1},
	{'value': 'अव', 'label': 'ava', 'attrib': [{'pos': 'preverb', 'defs': 'down'}], 'isLemma': 1},
	{'value': 'अवगच्छत्', 'label': 'avagacCat', 'attrib': [{'pos': 'prap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवगत', 'label': 'avagata', 'attrib': [{'pos': 'ppp', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवगतवत्', 'label': 'avagatavat', 'attrib': [{'pos': 'pap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवगत्य', 'label': 'avagatya', 'attrib': [{'pos': 'abs', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवगन्तव्य', 'label': 'avagantavya', 'attrib': [{'pos': 'ger', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवगन्तुम्', 'label': 'avagantum', 'attrib': [{'pos': 'inf', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवगमनीय', 'label': 'avagamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवगमिष्यत्', 'label': 'avagamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवगमिष्यमाण', 'label': 'avagamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवगम्य', 'label': 'avagamya', 'attrib': [{'pos': 'abs', 'defs': 'go down, descend, understand'}, {'pos': 'ger', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवगम्यमान', 'label': 'avagamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवजगन्वस्', 'label': 'avajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवजग्मान', 'label': 'avajagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवजग्मिवस्', 'label': 'avajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go down, descend, understand'}], 'isLemma': 0, 'parents': ['ava_gam']},
	{'value': 'अवजहृवस्', 'label': 'avajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवजह्राण', 'label': 'avajahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अव_आप्', 'label': 'ava_Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'reach, attain, obtain'}], 'isLemma': 1},
	{'value': 'अव_गम्', 'label': 'ava_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go down, descend, understand'}], 'isLemma': 1},
	{'value': 'अव_स्था', 'label': 'ava_sTA', 'attrib': [{'pos': 'vi1m', 'defs': 'remain'}], 'isLemma': 1},
	{'value': 'अव_हृ', 'label': 'ava_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'take down'}], 'isLemma': 1},
	{'value': 'अवतस्थान', 'label': 'avatasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'अवतिष्ठमान', 'label': 'avatizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'अवस्थातव्य', 'label': 'avasTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'अवस्थातुम्', 'label': 'avasTAtum', 'attrib': [{'pos': 'inf', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'अवस्थानीय', 'label': 'avasTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'अवस्थाय', 'label': 'avasTAya', 'attrib': [{'pos': 'abs', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'अवस्थास्यमान', 'label': 'avasTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'अवस्थित', 'label': 'avasTita', 'attrib': [{'pos': 'ppp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'अवस्थितवत्', 'label': 'avasTitavat', 'attrib': [{'pos': 'pap', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'अवस्थीयमान', 'label': 'avasTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'अवस्थेय', 'label': 'avasTeya', 'attrib': [{'pos': 'ger', 'defs': 'remain'}], 'isLemma': 0, 'parents': ['ava_sTA']},
	{'value': 'अवहरणीय', 'label': 'avaharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवहरत्', 'label': 'avaharat', 'attrib': [{'pos': 'prap', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवहरमाण', 'label': 'avaharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवहरिष्यत्', 'label': 'avaharizyat', 'attrib': [{'pos': 'fap', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवहरिष्यमाण', 'label': 'avaharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवहर्तव्य', 'label': 'avahartavya', 'attrib': [{'pos': 'ger', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवहर्तुम्', 'label': 'avahartum', 'attrib': [{'pos': 'inf', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवहार्य', 'label': 'avahArya', 'attrib': [{'pos': 'ger', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवहृत', 'label': 'avahfta', 'attrib': [{'pos': 'ppp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवहृतवत्', 'label': 'avahftavat', 'attrib': [{'pos': 'pap', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवहृत्य', 'label': 'avahftya', 'attrib': [{'pos': 'abs', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवह्रियमाण', 'label': 'avahriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'take down'}], 'isLemma': 0, 'parents': ['ava_hf']},
	{'value': 'अवापनीय', 'label': 'avApanIya', 'attrib': [{'pos': 'ger', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'अवापान', 'label': 'avApAna', 'attrib': [{'pos': 'pfpp', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'अवापिवस्', 'label': 'avApivas', 'attrib': [{'pos': 'pfap', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'अवाप्त', 'label': 'avApta', 'attrib': [{'pos': 'ppp', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'अवाप्तवत्', 'label': 'avAptavat', 'attrib': [{'pos': 'pap', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'अवाप्तव्य', 'label': 'avAptavya', 'attrib': [{'pos': 'ger', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'अवाप्तुम्', 'label': 'avAptum', 'attrib': [{'pos': 'inf', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'अवाप्नुवत्', 'label': 'avApnuvat', 'attrib': [{'pos': 'prap', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'अवाप्य', 'label': 'avApya', 'attrib': [{'pos': 'abs', 'defs': 'reach, attain, obtain'}, {'pos': 'ger', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'अवाप्यमान', 'label': 'avApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'अवाप्स्यत्', 'label': 'avApsyat', 'attrib': [{'pos': 'fap', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'अवाप्स्यमान', 'label': 'avApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'reach, attain, obtain'}], 'isLemma': 0, 'parents': ['ava_Ap']},
	{'value': 'अवि', 'label': 'avi', 'attrib': [{'pos': 'm, f', 'defs': 'ram/ewe'}], 'isLemma': 1},
	{'value': 'अश्व', 'label': 'aSva', 'attrib': [{'pos': 'm', 'defs': 'horse'}], 'isLemma': 1},
	{'value': 'अष्टन्', 'label': 'azwan', 'attrib': [{'pos': 'card_num', 'defs': 'eight'}], 'isLemma': 1},
	{'value': 'अष्टम', 'label': 'azwama', 'attrib': [{'pos': 'ord_num', 'defs': 'eighth'}], 'isLemma': 1},
	{'value': 'अस्', 'label': 'as', 'attrib': [{'pos': 'vi2a', 'defs': 'be'}], 'isLemma': 1},
	{'value': 'असि', 'label': 'asi', 'attrib': [{'pos': 'm', 'defs': 'sword'}], 'isLemma': 1},
	{'value': 'असुर', 'label': 'asura', 'attrib': [{'pos': 'm', 'defs': 'spirit, demon'}], 'isLemma': 1},
	{'value': 'अस्त', 'label': 'asta', 'attrib': [{'pos': 'n', 'defs': 'home'}], 'isLemma': 1},
	{'value': 'अस्मद्', 'label': 'asmad', 'attrib': [{'pos': 'pers_pron', 'defs': 'I/we'}], 'isLemma': 1},
	{'value': 'अह्', 'label': 'ah', 'attrib': [{'pos': 'vta', 'defs': 'say'}], 'isLemma': 1},
	{'value': 'अहन्', 'label': 'ahan', 'attrib': [{'pos': 'n', 'defs': 'day'}], 'isLemma': 1},
	{'value': 'आ', 'label': 'A', 'attrib': [{'pos': 'preverb', 'defs': 'unto'}], 'isLemma': 1},
	{'value': 'आक्रंस्यत्', 'label': 'AkraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आक्रंस्यमान', 'label': 'AkraMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आक्रमणीय', 'label': 'AkramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आक्रममाण', 'label': 'AkramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आक्रमितव्य', 'label': 'Akramitavya', 'attrib': [{'pos': 'ger', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आक्रमितुम्', 'label': 'Akramitum', 'attrib': [{'pos': 'inf', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आक्रम्य', 'label': 'Akramya', 'attrib': [{'pos': 'abs', 'defs': 'mount, attack; rise'}, {'pos': 'ger', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आक्रम्यमाण', 'label': 'AkramyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'mount, attack; rise'}, {'pos': 'prpp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आक्रान्त', 'label': 'AkrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आक्रान्तवत्', 'label': 'AkrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आक्रान्तुम्', 'label': 'AkrAntum', 'attrib': [{'pos': 'inf', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आक्रामत्', 'label': 'AkrAmat', 'attrib': [{'pos': 'prap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आक्राम्यत्', 'label': 'AkrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आगच्छत्', 'label': 'AgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आगत', 'label': 'Agata', 'attrib': [{'pos': 'ppp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आगतवत्', 'label': 'Agatavat', 'attrib': [{'pos': 'pap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आगत्य', 'label': 'Agatya', 'attrib': [{'pos': 'abs', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आगन्तव्य', 'label': 'Agantavya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आगन्तुम्', 'label': 'Agantum', 'attrib': [{'pos': 'inf', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आगमनीय', 'label': 'AgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आगमिष्यत्', 'label': 'Agamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आगमिष्यमाण', 'label': 'AgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आगम्य', 'label': 'Agamya', 'attrib': [{'pos': 'abs', 'defs': 'come'}, {'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आगम्यमान', 'label': 'AgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आचक्रमाण', 'label': 'AcakramARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आचक्रम्वस्', 'label': 'Acakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'mount, attack; rise'}], 'isLemma': 0, 'parents': ['A_kram']},
	{'value': 'आचार्य', 'label': 'AcArya', 'attrib': [{'pos': 'm', 'defs': 'teacher'}], 'isLemma': 1},
	{'value': 'आजगन्वस्', 'label': 'Ajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आजग्मान', 'label': 'AjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आजग्मिवस्', 'label': 'Ajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_gam']},
	{'value': 'आजज्ञान', 'label': 'AjajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आजज्ञिवस्', 'label': 'AjajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आजहृवस्', 'label': 'Ajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आजह्राण', 'label': 'AjahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आजानत्', 'label': 'AjAnat', 'attrib': [{'pos': 'prap', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आजुहुवान', 'label': 'AjuhuvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आजुहूवस्', 'label': 'AjuhUvas', 'attrib': [{'pos': 'pfap', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आज्ञा', 'label': 'AjYA', 'attrib': [{'pos': 'f', 'defs': 'command'}], 'isLemma': 1},
	{'value': 'आज्ञात', 'label': 'AjYAta', 'attrib': [{'pos': 'ppp', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आज्ञातवत्', 'label': 'AjYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आज्ञातव्य', 'label': 'AjYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आज्ञातुम्', 'label': 'AjYAtum', 'attrib': [{'pos': 'inf', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आज्ञानीय', 'label': 'AjYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आज्ञाय', 'label': 'AjYAya', 'attrib': [{'pos': 'abs', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आज्ञायमान', 'label': 'AjYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आज्ञास्यत्', 'label': 'AjYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आज्ञास्यमान', 'label': 'AjYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आज्ञेय', 'label': 'AjYeya', 'attrib': [{'pos': 'ger', 'defs': 'notice; caus.: command'}], 'isLemma': 0, 'parents': ['A_jYA']},
	{'value': 'आ_इ', 'label': 'A_i', 'attrib': [{'pos': 'vt2a', 'defs': 'come'}], 'isLemma': 1},
	{'value': 'आ_क्रम्', 'label': 'A_kram', 'attrib': [{'pos': 'vt1am', 'defs': 'mount, attack; rise'}], 'isLemma': 1},
	{'value': 'आ_गम्', 'label': 'A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come'}], 'isLemma': 1},
	{'value': 'आ_ज्ञा', 'label': 'A_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'notice; caus.: command'}], 'isLemma': 1},
	{'value': 'आ_दा', 'label': 'A_dA', 'attrib': [{'pos': 'vt3am', 'defs': 'receive, take'}], 'isLemma': 1},
	{'value': 'आ_नी', 'label': 'A_nI', 'attrib': [{'pos': 'vt1am', 'defs': 'lead to, bring'}], 'isLemma': 1},
	{'value': 'आ_पद्', 'label': 'A_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'approach'}], 'isLemma': 1},
	{'value': 'आ_स्था', 'label': 'A_sTA', 'attrib': [{'pos': 'vt1a', 'defs': 'resort to'}], 'isLemma': 1},
	{'value': 'आ_हृ', 'label': 'A_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'bring, fetch'}], 'isLemma': 1},
	{'value': 'आ_ह्वे', 'label': 'A_hve', 'attrib': [{'pos': 'vt1am', 'defs': 'call, summon'}], 'isLemma': 1},
	{'value': 'आतस्थान', 'label': 'AtasTAna', 'attrib': [{'pos': 'pfpp', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आतस्थिवस्', 'label': 'AtasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आति', 'label': 'Ati', 'attrib': [{'pos': 'f', 'defs': 'duck'}], 'isLemma': 1},
	{'value': 'आतिष्ठत्', 'label': 'AtizWat', 'attrib': [{'pos': 'prap', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आत्त', 'label': 'Atta', 'attrib': [{'pos': 'ppp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदत्तवत्', 'label': 'Adattavat', 'attrib': [{'pos': 'pap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आददत्', 'label': 'Adadat', 'attrib': [{'pos': 'prap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आददान', 'label': 'AdadAna', 'attrib': [{'pos': 'prmp', 'defs': 'receive, take'}, {'pos': 'pfmp, pfpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आददिवस्', 'label': 'Adadivas', 'attrib': [{'pos': 'pfap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदातव्य', 'label': 'AdAtavya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदातुम्', 'label': 'AdAtum', 'attrib': [{'pos': 'inf', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदान', 'label': 'AdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'आदानीय', 'label': 'AdAnIya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदाय', 'label': 'AdAya', 'attrib': [{'pos': 'abs', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदास्यत्', 'label': 'AdAsyat', 'attrib': [{'pos': 'fap', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदास्यमान', 'label': 'AdAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदिवस्', 'label': 'Adivas', 'attrib': [{'pos': 'pfap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'आदीयमान', 'label': 'AdIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आदेय', 'label': 'Adeya', 'attrib': [{'pos': 'ger', 'defs': 'receive, take'}], 'isLemma': 0, 'parents': ['A_dA']},
	{'value': 'आद्य', 'label': 'Adya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['ad']},
	{'value': 'आनयत्', 'label': 'Anayat', 'attrib': [{'pos': 'prap', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनयनीय', 'label': 'AnayanIya', 'attrib': [{'pos': 'ger', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनयमान', 'label': 'AnayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनर्हाण', 'label': 'AnarhARa', 'attrib': [{'pos': 'pfpp', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'आनर्हिवस्', 'label': 'Anarhivas', 'attrib': [{'pos': 'pfap', 'defs': 'deserve, be able (inf.)'}], 'isLemma': 0, 'parents': ['arh']},
	{'value': 'आनिनीवस्', 'label': 'AninIvas', 'attrib': [{'pos': 'pfap', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनिन्यान', 'label': 'AninyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनीत', 'label': 'AnIta', 'attrib': [{'pos': 'ppp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनीतवत्', 'label': 'AnItavat', 'attrib': [{'pos': 'pap', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनीय', 'label': 'AnIya', 'attrib': [{'pos': 'abs', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनीयमान', 'label': 'AnIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनेतव्य', 'label': 'Anetavya', 'attrib': [{'pos': 'ger', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनेतुम्', 'label': 'Anetum', 'attrib': [{'pos': 'inf', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनेय', 'label': 'Aneya', 'attrib': [{'pos': 'ger', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनेष्यत्', 'label': 'Anezyat', 'attrib': [{'pos': 'fap', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आनेष्यमाण', 'label': 'AnezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'lead to, bring'}], 'isLemma': 0, 'parents': ['A_nI']},
	{'value': 'आप्', 'label': 'Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'attain'}], 'isLemma': 1},
	{'value': 'आपण', 'label': 'ApaRa', 'attrib': [{'pos': 'm', 'defs': 'market'}], 'isLemma': 1},
	{'value': 'आपत्तव्य', 'label': 'Apattavya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'आपत्तुम्', 'label': 'Apattum', 'attrib': [{'pos': 'inf', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'आपत्स्यमान', 'label': 'ApatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'आपदनीय', 'label': 'ApadanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'आपद्य', 'label': 'Apadya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'आपद्यमान', 'label': 'ApadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'approach'}, {'pos': 'prpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'आपनीय', 'label': 'ApanIya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आपन्न', 'label': 'Apanna', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'आपन्नवत्', 'label': 'Apannavat', 'attrib': [{'pos': 'pap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'आपाद्य', 'label': 'ApAdya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'आपान', 'label': 'ApAna', 'attrib': [{'pos': 'pfpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आपिवस्', 'label': 'Apivas', 'attrib': [{'pos': 'pfap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आपेदान', 'label': 'ApedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['A_pad']},
	{'value': 'आप्त', 'label': 'Apta', 'attrib': [{'pos': 'ppp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्तवत्', 'label': 'Aptavat', 'attrib': [{'pos': 'pap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्तव्य', 'label': 'Aptavya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्तुम्', 'label': 'Aptum', 'attrib': [{'pos': 'inf', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्त्वा', 'label': 'AptvA', 'attrib': [{'pos': 'abs', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्नुवत्', 'label': 'Apnuvat', 'attrib': [{'pos': 'prap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्य', 'label': 'Apya', 'attrib': [{'pos': 'ger', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्यमान', 'label': 'ApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्स्यत्', 'label': 'Apsyat', 'attrib': [{'pos': 'fap', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आप्स्यमान', 'label': 'ApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'attain'}], 'isLemma': 0, 'parents': ['Ap']},
	{'value': 'आयत्', 'label': 'Ayat', 'attrib': [{'pos': 'prap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'आयनीय', 'label': 'AyanIya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'आयुस्', 'label': 'Ayus', 'attrib': [{'pos': 'n', 'defs': 'life'}], 'isLemma': 1},
	{'value': 'आविर्बभूवस्', 'label': 'AvirbaBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'आविर्भवत्', 'label': 'AvirBavat', 'attrib': [{'pos': 'prap', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'आविर्भवनीय', 'label': 'AvirBavanIya', 'attrib': [{'pos': 'ger', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'आविर्भवितव्य', 'label': 'AvirBavitavya', 'attrib': [{'pos': 'ger', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'आविर्भवितुम्', 'label': 'AvirBavitum', 'attrib': [{'pos': 'inf', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'आविर्भविष्यत्', 'label': 'AvirBavizyat', 'attrib': [{'pos': 'fap', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'आविर्भविष्यमाण', 'label': 'AvirBavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'आविर्भव्य', 'label': 'AvirBavya', 'attrib': [{'pos': 'ger', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'आविर्भूत', 'label': 'AvirBUta', 'attrib': [{'pos': 'ppp', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'आविर्भूतवत्', 'label': 'AvirBUtavat', 'attrib': [{'pos': 'pap', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'आविर्भूय', 'label': 'AvirBUya', 'attrib': [{'pos': 'abs', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'आविर्भूयमान', 'label': 'AvirBUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'become manifest, appear'}], 'isLemma': 0, 'parents': ['Avis_BU']},
	{'value': 'आविस्', 'label': 'Avis', 'attrib': [{'pos': 'preverb', 'defs': 'clear, manifest'}], 'isLemma': 1},
	{'value': 'आविस्_भू', 'label': 'Avis_BU', 'attrib': [{'pos': 'vi1a', 'defs': 'become manifest, appear'}], 'isLemma': 1},
	{'value': 'आश्रम', 'label': 'ASrama', 'attrib': [{'pos': 'm, n', 'defs': 'hermitage'}], 'isLemma': 1},
	{'value': 'आस्', 'label': 'As', 'attrib': [{'pos': 'vi2m', 'defs': 'sit'}], 'isLemma': 1},
	{'value': 'आसनीय', 'label': 'AsanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'आसाम्', 'label': 'AsAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'आसित', 'label': 'Asita', 'attrib': [{'pos': 'ppp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'आसितवत्', 'label': 'Asitavat', 'attrib': [{'pos': 'pap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'आसितुम्', 'label': 'Asitum', 'attrib': [{'pos': 'inf', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'आसित्वा', 'label': 'AsitvA', 'attrib': [{'pos': 'abs', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'आसिष्यमाण', 'label': 'AsizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'आसीन', 'label': 'AsIna', 'attrib': [{'pos': 'prmp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'आस्तव्य', 'label': 'Astavya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'आस्थातव्य', 'label': 'AsTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आस्थातुम्', 'label': 'AsTAtum', 'attrib': [{'pos': 'inf', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आस्थानीय', 'label': 'AsTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आस्थाय', 'label': 'AsTAya', 'attrib': [{'pos': 'abs', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आस्थास्यत्', 'label': 'AsTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आस्थास्यमान', 'label': 'AsTAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आस्थित', 'label': 'AsTita', 'attrib': [{'pos': 'ppp', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आस्थितवत्', 'label': 'AsTitavat', 'attrib': [{'pos': 'pap', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आस्थीयमान', 'label': 'AsTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आस्थेय', 'label': 'AsTeya', 'attrib': [{'pos': 'ger', 'defs': 'resort to'}], 'isLemma': 0, 'parents': ['A_sTA']},
	{'value': 'आस्य', 'label': 'Asya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'आस्यमान', 'label': 'AsyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['As']},
	{'value': 'आहरणीय', 'label': 'AharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आहरत्', 'label': 'Aharat', 'attrib': [{'pos': 'prap', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आहरमाण', 'label': 'AharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आहरिष्यत्', 'label': 'Aharizyat', 'attrib': [{'pos': 'fap', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आहरिष्यमाण', 'label': 'AharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आहर्तव्य', 'label': 'Ahartavya', 'attrib': [{'pos': 'ger', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आहर्तुम्', 'label': 'Ahartum', 'attrib': [{'pos': 'inf', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आहार्य', 'label': 'AhArya', 'attrib': [{'pos': 'ger', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आहूत', 'label': 'AhUta', 'attrib': [{'pos': 'ppp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आहूतवत्', 'label': 'AhUtavat', 'attrib': [{'pos': 'pap', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आहूय', 'label': 'AhUya', 'attrib': [{'pos': 'abs', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आहूयमान', 'label': 'AhUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आहृत', 'label': 'Ahfta', 'attrib': [{'pos': 'ppp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आहृतवत्', 'label': 'Ahftavat', 'attrib': [{'pos': 'pap', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आहृत्य', 'label': 'Ahftya', 'attrib': [{'pos': 'abs', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आह्रियमाण', 'label': 'AhriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bring, fetch'}], 'isLemma': 0, 'parents': ['A_hf']},
	{'value': 'आह्वयत्', 'label': 'Ahvayat', 'attrib': [{'pos': 'prap', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आह्वयमान', 'label': 'AhvayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आह्वातव्य', 'label': 'AhvAtavya', 'attrib': [{'pos': 'ger', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आह्वातुम्', 'label': 'AhvAtum', 'attrib': [{'pos': 'inf', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आह्वानीय', 'label': 'AhvAnIya', 'attrib': [{'pos': 'ger', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आह्वाय', 'label': 'AhvAya', 'attrib': [{'pos': 'ger', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आह्वास्यत्', 'label': 'AhvAsyat', 'attrib': [{'pos': 'fap', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'आह्वास्यमान', 'label': 'AhvAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'call, summon'}], 'isLemma': 0, 'parents': ['A_hve']},
	{'value': 'इ', 'label': 'i', 'attrib': [{'pos': 'vt2a', 'defs': 'go'}], 'isLemma': 1},
	{'value': 'इच्छत्', 'label': 'icCat', 'attrib': [{'pos': 'prap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'इज्यमान', 'label': 'ijyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'इत', 'label': 'ita', 'attrib': [{'pos': 'ppp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'इतर', 'label': 'itara', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'other'}], 'isLemma': 1},
	{'value': 'इतवत्', 'label': 'itavat', 'attrib': [{'pos': 'pap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'इतस्', 'label': 'itas', 'attrib': [{'pos': 'dem_adv', 'defs': 'from this, hence'}], 'isLemma': 1},
	{'value': 'इति', 'label': 'iti', 'attrib': [{'pos': 'pcl', 'defs': 'thus, close quote'}], 'isLemma': 1},
	{'value': 'इत्थम्', 'label': 'itTam', 'attrib': [{'pos': 'dem_adv', 'defs': 'in this way, thus'}], 'isLemma': 1},
	{'value': 'इत्य', 'label': 'itya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'इत्वा', 'label': 'itvA', 'attrib': [{'pos': 'abs', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'इदम्', 'label': 'idam', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'this'}], 'isLemma': 1},
	{'value': 'इन्द्र', 'label': 'indra', 'attrib': [{'pos': 'm', 'defs': 'Indra'}], 'isLemma': 1},
	{'value': 'इव', 'label': 'iva', 'attrib': [{'pos': 'pcl', 'defs': 'like, as'}], 'isLemma': 1},
	{'value': 'इष्', 'label': 'iz', 'attrib': [{'pos': 'vt6a', 'defs': 'desire'}], 'isLemma': 1},
	{'value': 'इष्ट', 'label': 'izwa', 'attrib': [{'pos': 'ppp', 'defs': 'desire'}, {'pos': 'ppp', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['iz', 'yaj']},
	{'value': 'इष्टवत्', 'label': 'izwavat', 'attrib': [{'pos': 'pap', 'defs': 'desire'}, {'pos': 'pap', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['iz', 'yaj']},
	{'value': 'इष्ट्वा', 'label': 'izwvA', 'attrib': [{'pos': 'abs', 'defs': 'desire'}, {'pos': 'abs', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['iz', 'yaj']},
	{'value': 'इष्यमाण', 'label': 'izyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'इह', 'label': 'iha', 'attrib': [{'pos': 'dem_adv', 'defs': 'here'}], 'isLemma': 1},
	{'value': 'ईक्ष्', 'label': 'Ikz', 'attrib': [{'pos': 'vt1m', 'defs': 'see'}], 'isLemma': 1},
	{'value': 'ईक्षणीय', 'label': 'IkzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'ईक्षमाण', 'label': 'IkzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'ईक्षाम्', 'label': 'IkzAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'ईक्षित', 'label': 'Ikzita', 'attrib': [{'pos': 'ppp', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'ईक्षितवत्', 'label': 'Ikzitavat', 'attrib': [{'pos': 'pap', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'ईक्षितव्य', 'label': 'Ikzitavya', 'attrib': [{'pos': 'ger', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'ईक्षितुम्', 'label': 'Ikzitum', 'attrib': [{'pos': 'inf', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'ईक्षित्वा', 'label': 'IkzitvA', 'attrib': [{'pos': 'abs', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'ईक्षिष्यमाण', 'label': 'IkzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'ईक्ष्य', 'label': 'Ikzya', 'attrib': [{'pos': 'ger', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'ईक्ष्यमाण', 'label': 'IkzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'see'}], 'isLemma': 0, 'parents': ['Ikz']},
	{'value': 'ईजान', 'label': 'IjAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'ईजिवस्', 'label': 'Ijivas', 'attrib': [{'pos': 'pfap', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'ईयमान', 'label': 'IyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'ईयान', 'label': 'IyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'ईयिवस्', 'label': 'Iyivas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'ईषाण', 'label': 'IzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'ईषिवस्', 'label': 'Izivas', 'attrib': [{'pos': 'pfap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'उक्त', 'label': 'ukta', 'attrib': [{'pos': 'ppp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'उक्तवत्', 'label': 'uktavat', 'attrib': [{'pos': 'pap', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'उक्त्वा', 'label': 'uktvA', 'attrib': [{'pos': 'abs', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'उच्चक्रमाण', 'label': 'uccakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उच्चक्रम्वस्', 'label': 'uccakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उच्यमान', 'label': 'ucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'उज्जगन्वस्', 'label': 'ujjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उज्जग्मान', 'label': 'ujjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उज्जग्मिवस्', 'label': 'ujjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उज्जहृवस्', 'label': 'ujjahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उज्जह्राण', 'label': 'ujjahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उत्क्रंस्यत्', 'label': 'utkraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उत्क्रंस्यमान', 'label': 'utkraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उत्क्रमणीय', 'label': 'utkramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उत्क्रमितव्य', 'label': 'utkramitavya', 'attrib': [{'pos': 'ger', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उत्क्रमितुम्', 'label': 'utkramitum', 'attrib': [{'pos': 'inf', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उत्क्रम्य', 'label': 'utkramya', 'attrib': [{'pos': 'abs', 'defs': 'go up, ascend, transgress'}, {'pos': 'ger', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उत्क्रम्यमाण', 'label': 'utkramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उत्क्रान्त', 'label': 'utkrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उत्क्रान्तवत्', 'label': 'utkrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उत्क्रामत्', 'label': 'utkrAmat', 'attrib': [{'pos': 'prap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उत्क्राम्यत्', 'label': 'utkrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'go up, ascend, transgress'}], 'isLemma': 0, 'parents': ['ud_kram']},
	{'value': 'उत्तस्थान', 'label': 'uttasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्तस्थिवस्', 'label': 'uttasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्तिष्ठत्', 'label': 'uttizWat', 'attrib': [{'pos': 'prap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्तिष्ठमान', 'label': 'uttizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थातव्य', 'label': 'utTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थातुम्', 'label': 'utTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थानीय', 'label': 'utTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थाय', 'label': 'utTAya', 'attrib': [{'pos': 'abs', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थास्यत्', 'label': 'utTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थास्यमान', 'label': 'utTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थित', 'label': 'utTita', 'attrib': [{'pos': 'ppp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थितवत्', 'label': 'utTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्थेय', 'label': 'utTeya', 'attrib': [{'pos': 'ger', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उत्पत्तव्य', 'label': 'utpattavya', 'attrib': [{'pos': 'ger', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'उत्पत्तुम्', 'label': 'utpattum', 'attrib': [{'pos': 'inf', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'उत्पत्स्यमान', 'label': 'utpatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'उत्पदनीय', 'label': 'utpadanIya', 'attrib': [{'pos': 'ger', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'उत्पद्य', 'label': 'utpadya', 'attrib': [{'pos': 'abs', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'उत्पद्यमान', 'label': 'utpadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'step up, arise'}, {'pos': 'prpp', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'उत्पन्न', 'label': 'utpanna', 'attrib': [{'pos': 'ppp', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'उत्पन्नवत्', 'label': 'utpannavat', 'attrib': [{'pos': 'pap', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'उत्पाद्य', 'label': 'utpAdya', 'attrib': [{'pos': 'ger', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'उत्पेदान', 'label': 'utpedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step up, arise'}], 'isLemma': 0, 'parents': ['ud_pad']},
	{'value': 'उत्स्थीयमान', 'label': 'utsTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stand up, arise'}], 'isLemma': 0, 'parents': ['ud_sTA']},
	{'value': 'उद्', 'label': 'ud', 'attrib': [{'pos': 'preverb', 'defs': 'up'}], 'isLemma': 1},
	{'value': 'उदक', 'label': 'udaka', 'attrib': [{'pos': 'n', 'defs': 'water'}], 'isLemma': 1},
	{'value': 'उदित', 'label': 'udita', 'attrib': [{'pos': 'ppp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'उदितवत्', 'label': 'uditavat', 'attrib': [{'pos': 'pap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'उदित्वा', 'label': 'uditvA', 'attrib': [{'pos': 'abs', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'उद्गच्छत्', 'label': 'udgacCat', 'attrib': [{'pos': 'prap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उद्गत', 'label': 'udgata', 'attrib': [{'pos': 'ppp', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उद्गतवत्', 'label': 'udgatavat', 'attrib': [{'pos': 'pap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उद्गत्य', 'label': 'udgatya', 'attrib': [{'pos': 'abs', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उद्गन्तव्य', 'label': 'udgantavya', 'attrib': [{'pos': 'ger', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उद्गन्तुम्', 'label': 'udgantum', 'attrib': [{'pos': 'inf', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उद्गमनीय', 'label': 'udgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उद्गमिष्यत्', 'label': 'udgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उद्गमिष्यमाण', 'label': 'udgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उद्गम्य', 'label': 'udgamya', 'attrib': [{'pos': 'abs', 'defs': 'go up, rise'}, {'pos': 'ger', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उद्गम्यमान', 'label': 'udgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go up, rise'}], 'isLemma': 0, 'parents': ['ud_gam']},
	{'value': 'उद्गातृ', 'label': 'udgAtf', 'attrib': [{'pos': 'm', 'defs': 'udgātṛ'}], 'isLemma': 1},
	{'value': 'उद्_क्रम्', 'label': 'ud_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'go up, ascend, transgress'}], 'isLemma': 1},
	{'value': 'उद्_गम्', 'label': 'ud_gam', 'attrib': [{'pos': 'vi1a', 'defs': 'go up, rise'}], 'isLemma': 1},
	{'value': 'उद्_पद्', 'label': 'ud_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'step up, arise'}], 'isLemma': 1},
	{'value': 'उद्_बन्ध्', 'label': 'ud_banD', 'attrib': [{'pos': 'vt9am', 'defs': "bind up, hang one's self"}], 'isLemma': 1},
	{'value': 'उद्_यम्', 'label': 'ud_yam', 'attrib': [{'pos': 'vt1am', 'defs': 'lift up, undertake, strive'}], 'isLemma': 1},
	{'value': 'उद्_स्था', 'label': 'ud_sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stand up, arise'}], 'isLemma': 1},
	{'value': 'उद्_हृ', 'label': 'ud_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'take up, raise, rescue'}], 'isLemma': 1},
	{'value': 'उद्धरणीय', 'label': 'udDaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उद्धरत्', 'label': 'udDarat', 'attrib': [{'pos': 'prap', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उद्धरमाण', 'label': 'udDaramARa', 'attrib': [{'pos': 'prmp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उद्धरिष्यत्', 'label': 'udDarizyat', 'attrib': [{'pos': 'fap', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उद्धरिष्यमाण', 'label': 'udDarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उद्धर्तव्य', 'label': 'udDartavya', 'attrib': [{'pos': 'ger', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उद्धर्तुम्', 'label': 'udDartum', 'attrib': [{'pos': 'inf', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उद्धार्य', 'label': 'udDArya', 'attrib': [{'pos': 'ger', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उद्धृत', 'label': 'udDfta', 'attrib': [{'pos': 'ppp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उद्धृतवत्', 'label': 'udDftavat', 'attrib': [{'pos': 'pap', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उद्धृत्य', 'label': 'udDftya', 'attrib': [{'pos': 'abs', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उद्ध्रियमाण', 'label': 'udDriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'take up, raise, rescue'}], 'isLemma': 0, 'parents': ['ud_hf']},
	{'value': 'उद्बद्ध', 'label': 'udbadDa', 'attrib': [{'pos': 'ppp', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्बद्धवत्', 'label': 'udbadDavat', 'attrib': [{'pos': 'pap', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्बध्नत्', 'label': 'udbaDnat', 'attrib': [{'pos': 'prap', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्बध्य', 'label': 'udbaDya', 'attrib': [{'pos': 'abs', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्बध्यमान', 'label': 'udbaDyamAna', 'attrib': [{'pos': 'prpp', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्बन्द्धव्य', 'label': 'udbandDavya', 'attrib': [{'pos': 'ger', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्बन्द्धुम्', 'label': 'udbandDum', 'attrib': [{'pos': 'inf', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्बन्धनीय', 'label': 'udbanDanIya', 'attrib': [{'pos': 'ger', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्बन्ध्य', 'label': 'udbanDya', 'attrib': [{'pos': 'ger', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्बबन्धान', 'label': 'udbabanDAna', 'attrib': [{'pos': 'pfpp', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्बबन्ध्वस्', 'label': 'udbabanDvas', 'attrib': [{'pos': 'pfap', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्भन्त्स्यत्', 'label': 'udBantsyat', 'attrib': [{'pos': 'fap', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्भन्त्स्यमान', 'label': 'udBantsyamAna', 'attrib': [{'pos': 'fpp', 'defs': "bind up, hang one's self"}], 'isLemma': 0, 'parents': ['ud_banD']},
	{'value': 'उद्यंस्यत्', 'label': 'udyaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्यंस्यमान', 'label': 'udyaMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्यच्छत्', 'label': 'udyacCat', 'attrib': [{'pos': 'prap', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्यच्छमान', 'label': 'udyacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्यत', 'label': 'udyata', 'attrib': [{'pos': 'ppp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्यतवत्', 'label': 'udyatavat', 'attrib': [{'pos': 'pap', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्यत्य', 'label': 'udyatya', 'attrib': [{'pos': 'abs', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्यन्तव्य', 'label': 'udyantavya', 'attrib': [{'pos': 'ger', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्यन्तुम्', 'label': 'udyantum', 'attrib': [{'pos': 'inf', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्यमनीय', 'label': 'udyamanIya', 'attrib': [{'pos': 'ger', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्यमान', 'label': 'udyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'उद्यम्य', 'label': 'udyamya', 'attrib': [{'pos': 'abs', 'defs': 'lift up, undertake, strive'}, {'pos': 'ger', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्यम्यमान', 'label': 'udyamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्येमान', 'label': 'udyemAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उद्येमिवस्', 'label': 'udyemivas', 'attrib': [{'pos': 'pfap', 'defs': 'lift up, undertake, strive'}], 'isLemma': 0, 'parents': ['ud_yam']},
	{'value': 'उप', 'label': 'upa', 'attrib': [{'pos': 'preverb', 'defs': 'near'}], 'isLemma': 1},
	{'value': 'उपक्रंस्यत्', 'label': 'upakraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपक्रंस्यमान', 'label': 'upakraMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपक्रमणीय', 'label': 'upakramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपक्रममाण', 'label': 'upakramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपक्रमितव्य', 'label': 'upakramitavya', 'attrib': [{'pos': 'ger', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपक्रमितुम्', 'label': 'upakramitum', 'attrib': [{'pos': 'inf', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपक्रम्य', 'label': 'upakramya', 'attrib': [{'pos': 'abs', 'defs': 'approach (active); begin (middle)'}, {'pos': 'ger', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपक्रम्यमाण', 'label': 'upakramyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'approach (active); begin (middle)'}, {'pos': 'prpp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपक्रान्त', 'label': 'upakrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपक्रान्तवत्', 'label': 'upakrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपक्रान्तुम्', 'label': 'upakrAntum', 'attrib': [{'pos': 'inf', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपक्रामत्', 'label': 'upakrAmat', 'attrib': [{'pos': 'prap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपक्राम्यत्', 'label': 'upakrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपगच्छत्', 'label': 'upagacCat', 'attrib': [{'pos': 'prap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपगत', 'label': 'upagata', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपगतवत्', 'label': 'upagatavat', 'attrib': [{'pos': 'pap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपगत्य', 'label': 'upagatya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपगन्तव्य', 'label': 'upagantavya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपगन्तुम्', 'label': 'upagantum', 'attrib': [{'pos': 'inf', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपगमनीय', 'label': 'upagamanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपगमिष्यत्', 'label': 'upagamizyat', 'attrib': [{'pos': 'fap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपगमिष्यमाण', 'label': 'upagamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपगम्य', 'label': 'upagamya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}, {'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपगम्यमान', 'label': 'upagamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपचक्रमाण', 'label': 'upacakramARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपचक्रम्वस्', 'label': 'upacakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 0, 'parents': ['upa_kram']},
	{'value': 'उपजगन्वस्', 'label': 'upajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपजग्मान', 'label': 'upajagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपजग्मिवस्', 'label': 'upajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_gam']},
	{'value': 'उपजहृवस्', 'label': 'upajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपजह्राण', 'label': 'upajahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उप_इ', 'label': 'upa_i', 'attrib': [{'pos': 'vt2a', 'defs': 'approach'}], 'isLemma': 1},
	{'value': 'उप_क्रम्', 'label': 'upa_kram', 'attrib': [{'pos': 'vt1am', 'defs': 'approach (active); begin (middle)'}], 'isLemma': 1},
	{'value': 'उप_गम्', 'label': 'upa_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'approach'}], 'isLemma': 1},
	{'value': 'उप_पद्', 'label': 'upa_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'approach, occur, be suitable'}], 'isLemma': 1},
	{'value': 'उप_बन्ध्', 'label': 'upa_banD', 'attrib': [{'pos': 'vt9am', 'defs': 'bind near'}], 'isLemma': 1},
	{'value': 'उप_यम्', 'label': 'upa_yam', 'attrib': [{'pos': 'vt1am', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 1},
	{'value': 'उप_विश्', 'label': 'upa_viS', 'attrib': [{'pos': 'vi6a', 'defs': 'sit'}], 'isLemma': 1},
	{'value': 'उप_स्था', 'label': 'upa_sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stay near, be present'}], 'isLemma': 1},
	{'value': 'उप_हृ', 'label': 'upa_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'bring near, offer'}], 'isLemma': 1},
	{'value': 'उपतस्थान', 'label': 'upatasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपतस्थिवस्', 'label': 'upatasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपतिष्ठत्', 'label': 'upatizWat', 'attrib': [{'pos': 'prap', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपतिष्ठमान', 'label': 'upatizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपपत्तव्य', 'label': 'upapattavya', 'attrib': [{'pos': 'ger', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'उपपत्तुम्', 'label': 'upapattum', 'attrib': [{'pos': 'inf', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'उपपत्स्यमान', 'label': 'upapatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'उपपदनीय', 'label': 'upapadanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'उपपद्य', 'label': 'upapadya', 'attrib': [{'pos': 'abs', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'उपपद्यमान', 'label': 'upapadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'approach, occur, be suitable'}, {'pos': 'prpp', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'उपपन्न', 'label': 'upapanna', 'attrib': [{'pos': 'ppp', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'उपपन्नवत्', 'label': 'upapannavat', 'attrib': [{'pos': 'pap', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'उपपाद्य', 'label': 'upapAdya', 'attrib': [{'pos': 'ger', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'उपपेदान', 'label': 'upapedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'approach, occur, be suitable'}], 'isLemma': 0, 'parents': ['upa_pad']},
	{'value': 'उपबद्ध', 'label': 'upabadDa', 'attrib': [{'pos': 'ppp', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपबद्धवत्', 'label': 'upabadDavat', 'attrib': [{'pos': 'pap', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपबध्नत्', 'label': 'upabaDnat', 'attrib': [{'pos': 'prap', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपबध्य', 'label': 'upabaDya', 'attrib': [{'pos': 'abs', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपबध्यमान', 'label': 'upabaDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपबन्द्धव्य', 'label': 'upabandDavya', 'attrib': [{'pos': 'ger', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपबन्द्धुम्', 'label': 'upabandDum', 'attrib': [{'pos': 'inf', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपबन्धनीय', 'label': 'upabanDanIya', 'attrib': [{'pos': 'ger', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपबन्ध्य', 'label': 'upabanDya', 'attrib': [{'pos': 'ger', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपबबन्धान', 'label': 'upababanDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपबबन्ध्वस्', 'label': 'upababanDvas', 'attrib': [{'pos': 'pfap', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपभन्त्स्यत्', 'label': 'upaBantsyat', 'attrib': [{'pos': 'fap', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपभन्त्स्यमान', 'label': 'upaBantsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'bind near'}], 'isLemma': 0, 'parents': ['upa_banD']},
	{'value': 'उपयंस्यत्', 'label': 'upayaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयंस्यमान', 'label': 'upayaMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयच्छत्', 'label': 'upayacCat', 'attrib': [{'pos': 'prap', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयच्छमान', 'label': 'upayacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयत्', 'label': 'upayat', 'attrib': [{'pos': 'prap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपयत', 'label': 'upayata', 'attrib': [{'pos': 'ppp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयतवत्', 'label': 'upayatavat', 'attrib': [{'pos': 'pap', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयत्य', 'label': 'upayatya', 'attrib': [{'pos': 'abs', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयन्तव्य', 'label': 'upayantavya', 'attrib': [{'pos': 'ger', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयन्तुम्', 'label': 'upayantum', 'attrib': [{'pos': 'inf', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयमनीय', 'label': 'upayamanIya', 'attrib': [{'pos': 'ger', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयम्य', 'label': 'upayamya', 'attrib': [{'pos': 'abs', 'defs': 'hold near, take, offer (active), embrace (middle)'}, {'pos': 'ger', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयम्यमान', 'label': 'upayamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयेमान', 'label': 'upayemAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपयेमिवस्', 'label': 'upayemivas', 'attrib': [{'pos': 'pfap', 'defs': 'hold near, take, offer (active), embrace (middle)'}], 'isLemma': 0, 'parents': ['upa_yam']},
	{'value': 'उपविविशान', 'label': 'upaviviSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपविविशिवस्', 'label': 'upaviviSivas', 'attrib': [{'pos': 'pfap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपविविश्वस्', 'label': 'upaviviSvas', 'attrib': [{'pos': 'pfap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपविशत्', 'label': 'upaviSat', 'attrib': [{'pos': 'prap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपविश्य', 'label': 'upaviSya', 'attrib': [{'pos': 'abs', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपविश्यमान', 'label': 'upaviSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपविष्ट', 'label': 'upavizwa', 'attrib': [{'pos': 'ppp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपविष्टवत्', 'label': 'upavizwavat', 'attrib': [{'pos': 'pap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपवेक्ष्यत्', 'label': 'upavekzyat', 'attrib': [{'pos': 'fap', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपवेक्ष्यमाण', 'label': 'upavekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपवेशनीय', 'label': 'upaveSanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपवेश्य', 'label': 'upaveSya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपवेष्टव्य', 'label': 'upavezwavya', 'attrib': [{'pos': 'ger', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपवेष्टुम्', 'label': 'upavezwum', 'attrib': [{'pos': 'inf', 'defs': 'sit'}], 'isLemma': 0, 'parents': ['upa_viS']},
	{'value': 'उपस्थातव्य', 'label': 'upasTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपस्थातुम्', 'label': 'upasTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपस्थानीय', 'label': 'upasTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपस्थाय', 'label': 'upasTAya', 'attrib': [{'pos': 'abs', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपस्थास्यत्', 'label': 'upasTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपस्थास्यमान', 'label': 'upasTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपस्थित', 'label': 'upasTita', 'attrib': [{'pos': 'ppp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपस्थितवत्', 'label': 'upasTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपस्थीयमान', 'label': 'upasTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपस्थेय', 'label': 'upasTeya', 'attrib': [{'pos': 'ger', 'defs': 'stay near, be present'}], 'isLemma': 0, 'parents': ['upa_sTA']},
	{'value': 'उपहरणीय', 'label': 'upaharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपहरत्', 'label': 'upaharat', 'attrib': [{'pos': 'prap', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपहरमाण', 'label': 'upaharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपहरिष्यत्', 'label': 'upaharizyat', 'attrib': [{'pos': 'fap', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपहरिष्यमाण', 'label': 'upaharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपहर्तव्य', 'label': 'upahartavya', 'attrib': [{'pos': 'ger', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपहर्तुम्', 'label': 'upahartum', 'attrib': [{'pos': 'inf', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपहार्य', 'label': 'upahArya', 'attrib': [{'pos': 'ger', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपहृत', 'label': 'upahfta', 'attrib': [{'pos': 'ppp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपहृतवत्', 'label': 'upahftavat', 'attrib': [{'pos': 'pap', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपहृत्य', 'label': 'upahftya', 'attrib': [{'pos': 'abs', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपह्रियमाण', 'label': 'upahriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bring near, offer'}], 'isLemma': 0, 'parents': ['upa_hf']},
	{'value': 'उपायनीय', 'label': 'upAyanIya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपेत', 'label': 'upeta', 'attrib': [{'pos': 'ppp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपेतवत्', 'label': 'upetavat', 'attrib': [{'pos': 'pap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपेत्य', 'label': 'upetya', 'attrib': [{'pos': 'abs', 'defs': 'approach'}, {'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपेयमान', 'label': 'upeyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपेयान', 'label': 'upeyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपेयिवस्', 'label': 'upeyivas', 'attrib': [{'pos': 'pfap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपैतव्य', 'label': 'upEtavya', 'attrib': [{'pos': 'ger', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपैतुम्', 'label': 'upEtum', 'attrib': [{'pos': 'inf', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपैष्यत्', 'label': 'upEzyat', 'attrib': [{'pos': 'fap', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उपैष्यमाण', 'label': 'upEzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'approach'}], 'isLemma': 0, 'parents': ['upa_i']},
	{'value': 'उभ', 'label': 'uBa', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'both'}], 'isLemma': 1},
	{'value': 'उरण', 'label': 'uraRa', 'attrib': [{'pos': 'm', 'defs': 'lamb'}], 'isLemma': 1},
	{'value': 'उर्वशी', 'label': 'urvaSI', 'attrib': [{'pos': 'f_pn', 'defs': 'Urvaśī'}], 'isLemma': 1},
	{'value': 'उषस्', 'label': 'uzas', 'attrib': [{'pos': 'f', 'defs': 'dawn'}], 'isLemma': 1},
	{'value': 'उषित', 'label': 'uzita', 'attrib': [{'pos': 'ppp', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'उषितवत्', 'label': 'uzitavat', 'attrib': [{'pos': 'pap', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'उषित्वा', 'label': 'uzitvA', 'attrib': [{'pos': 'abs', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'उष्ण', 'label': 'uzRa', 'attrib': [{'pos': 'adj', 'defs': 'warm'}], 'isLemma': 1},
	{'value': 'उष्यमाण', 'label': 'uzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'उह्यमान', 'label': 'uhyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ऊचान', 'label': 'UcAna', 'attrib': [{'pos': 'pfpp', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'ऊचिवस्', 'label': 'Ucivas', 'attrib': [{'pos': 'pfap', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'ऊढ', 'label': 'UQa', 'attrib': [{'pos': 'ppp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ऊढवत्', 'label': 'UQavat', 'attrib': [{'pos': 'pap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ऊढ्वा', 'label': 'UQvA', 'attrib': [{'pos': 'abs', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ऊदान', 'label': 'UdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'ऊदिवस्', 'label': 'Udivas', 'attrib': [{'pos': 'pfap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'ऊषाण', 'label': 'UzARa', 'attrib': [{'pos': 'pfpp', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'ऊषिवस्', 'label': 'Uzivas', 'attrib': [{'pos': 'pfap', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'ऊहान', 'label': 'UhAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ऊहिवस्', 'label': 'Uhivas', 'attrib': [{'pos': 'pfap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'ऋग्वेद', 'label': 'fgveda', 'attrib': [{'pos': 'm_pn', 'defs': '<i>Ṛgveda</i>'}], 'isLemma': 1},
	{'value': 'ऋच्', 'label': 'fc', 'attrib': [{'pos': 'f', 'defs': 'Ṛgvedic verse'}], 'isLemma': 1},
	{'value': 'ऋत्विज्', 'label': 'ftvij', 'attrib': [{'pos': 'm', 'defs': 'officiant in Vedic performance'}], 'isLemma': 1},
	{'value': 'ऋषि', 'label': 'fzi', 'attrib': [{'pos': 'm', 'defs': 'Vedic seer'}], 'isLemma': 1},
	{'value': 'एक', 'label': 'eka', 'attrib': [{'pos': 'card_num', 'defs': 'one'}], 'isLemma': 1},
	{'value': 'एत', 'label': 'eta', 'attrib': [{'pos': 'ppp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'एतद्', 'label': 'etad', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'this'}], 'isLemma': 1},
	{'value': 'एतर्हि', 'label': 'etarhi', 'attrib': [{'pos': 'dem_adv', 'defs': 'for this reason'}], 'isLemma': 1},
	{'value': 'एतवत्', 'label': 'etavat', 'attrib': [{'pos': 'pap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'एतव्य', 'label': 'etavya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'एतावत्', 'label': 'etAvat', 'attrib': [{'pos': 'dem_adj', 'defs': 'of this extent'}, {'pos': 'dem_adv', 'defs': 'to this extent'}], 'isLemma': 1},
	{'value': 'एतुम्', 'label': 'etum', 'attrib': [{'pos': 'inf', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'एत्य', 'label': 'etya', 'attrib': [{'pos': 'abs', 'defs': 'come'}, {'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'एनद्', 'label': 'enad', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'this, it (2, 3s, 6d, 7d)'}], 'isLemma': 1},
	{'value': 'एयमान', 'label': 'eyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'एयान', 'label': 'eyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'एयिवस्', 'label': 'eyivas', 'attrib': [{'pos': 'pfap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'एव', 'label': 'eva', 'attrib': [{'pos': 'pcl', 'defs': 'only, just, very'}], 'isLemma': 1},
	{'value': 'एवम्', 'label': 'evam', 'attrib': [{'pos': 'dem_adv', 'defs': 'in this way, thus'}], 'isLemma': 1},
	{'value': 'एषणीय', 'label': 'ezaRIya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'एषिष्यत्', 'label': 'ezizyat', 'attrib': [{'pos': 'fap', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'एषिष्यमाण', 'label': 'ezizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'एष्टव्य', 'label': 'ezwavya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'एष्टुम्', 'label': 'ezwum', 'attrib': [{'pos': 'inf', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'एष्य', 'label': 'ezya', 'attrib': [{'pos': 'ger', 'defs': 'desire'}], 'isLemma': 0, 'parents': ['iz']},
	{'value': 'एष्यत्', 'label': 'ezyat', 'attrib': [{'pos': 'fap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'एष्यमाण', 'label': 'ezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'ऐतव्य', 'label': 'Etavya', 'attrib': [{'pos': 'ger', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'ऐतुम्', 'label': 'Etum', 'attrib': [{'pos': 'inf', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'ऐष्यत्', 'label': 'Ezyat', 'attrib': [{'pos': 'fap', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'ऐष्यमाण', 'label': 'EzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come'}], 'isLemma': 0, 'parents': ['A_i']},
	{'value': 'कथ्', 'label': 'kaT', 'attrib': [{'pos': 'vt10am', 'defs': 'tell, narrate'}], 'isLemma': 1},
	{'value': 'कथनीय', 'label': 'kaTanIya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथम्', 'label': 'kaTam', 'attrib': [{'pos': 'int_adv', 'defs': 'how'}], 'isLemma': 1},
	{'value': 'कथयत्', 'label': 'kaTayat', 'attrib': [{'pos': 'prap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयमान', 'label': 'kaTayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयाम्', 'label': 'kaTayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयितव्य', 'label': 'kaTayitavya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयितुम्', 'label': 'kaTayitum', 'attrib': [{'pos': 'inf', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयित्वा', 'label': 'kaTayitvA', 'attrib': [{'pos': 'abs', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयिष्यत्', 'label': 'kaTayizyat', 'attrib': [{'pos': 'fap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथयिष्यमाण', 'label': 'kaTayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथा', 'label': 'kaTA', 'attrib': [{'pos': 'f', 'defs': 'story'}], 'isLemma': 1},
	{'value': 'कथित', 'label': 'kaTita', 'attrib': [{'pos': 'ppp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथितवत्', 'label': 'kaTitavat', 'attrib': [{'pos': 'pap', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथ्य', 'label': 'kaTya', 'attrib': [{'pos': 'ger', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कथ्यमान', 'label': 'kaTyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'tell, narrate'}], 'isLemma': 0, 'parents': ['kaT']},
	{'value': 'कदा', 'label': 'kadA', 'attrib': [{'pos': 'int_adv', 'defs': 'when'}], 'isLemma': 1},
	{'value': 'कन्या', 'label': 'kanyA', 'attrib': [{'pos': 'f', 'defs': 'girl'}], 'isLemma': 1},
	{'value': 'कम्', 'label': 'kam', 'attrib': [{'pos': 'vt1m', 'defs': 'desire, love'}], 'isLemma': 1},
	{'value': 'कमनीय', 'label': 'kamanIya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कमितव्य', 'label': 'kamitavya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कमितुम्', 'label': 'kamitum', 'attrib': [{'pos': 'inf', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कमित्वा', 'label': 'kamitvA', 'attrib': [{'pos': 'abs', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कमिष्यमाण', 'label': 'kamizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'करणीय', 'label': 'karaRIya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'करिष्यत्', 'label': 'karizyat', 'attrib': [{'pos': 'fap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'करिष्यमाण', 'label': 'karizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कर्तव्य', 'label': 'kartavya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कर्तुम्', 'label': 'kartum', 'attrib': [{'pos': 'inf', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कर्मन्', 'label': 'karman', 'attrib': [{'pos': 'n', 'defs': 'action, deed'}], 'isLemma': 1},
	{'value': 'कान्त', 'label': 'kAnta', 'attrib': [{'pos': 'ppp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कान्तवत्', 'label': 'kAntavat', 'attrib': [{'pos': 'pap', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कान्त्वा', 'label': 'kAntvA', 'attrib': [{'pos': 'abs', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'काम', 'label': 'kAma', 'attrib': [{'pos': 'm', 'defs': 'love, the god of love'}], 'isLemma': 1},
	{'value': 'कामनीय', 'label': 'kAmanIya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कामयमान', 'label': 'kAmayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कामयाम्', 'label': 'kAmayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कामयितव्य', 'label': 'kAmayitavya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कामयितुम्', 'label': 'kAmayitum', 'attrib': [{'pos': 'inf', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कामयित्वा', 'label': 'kAmayitvA', 'attrib': [{'pos': 'abs', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कामयिष्यमाण', 'label': 'kAmayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'काम्य', 'label': 'kAmya', 'attrib': [{'pos': 'ger', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'काम्यमान', 'label': 'kAmyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'कार्य', 'label': 'kArya', 'attrib': [{'pos': 'n', 'defs': 'work'}, {'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 1},
	{'value': 'कार्य', 'label': 'kArya', 'attrib': [{'pos': 'n', 'defs': 'work'}, {'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'काल', 'label': 'kAla', 'attrib': [{'pos': 'm', 'defs': 'time'}], 'isLemma': 1},
	{'value': 'किम्', 'label': 'kim', 'attrib': [{'pos': 'int_pron, int_adj', 'defs': 'which/who/what'}, {'pos': 'pcl', 'defs': 'question marker'}], 'isLemma': 1},
	{'value': 'कियत्', 'label': 'kiyat', 'attrib': [{'pos': 'int_adj', 'defs': 'of what extent'}, {'pos': 'int_adv', 'defs': 'to what extent'}], 'isLemma': 1},
	{'value': 'कुतस्', 'label': 'kutas', 'attrib': [{'pos': 'int_adv', 'defs': 'whence, why'}], 'isLemma': 1},
	{'value': 'कुत्र', 'label': 'kutra', 'attrib': [{'pos': 'int_adv', 'defs': 'where'}], 'isLemma': 1},
	{'value': 'कुमार', 'label': 'kumAra', 'attrib': [{'pos': 'm', 'defs': 'boy, youth'}], 'isLemma': 1},
	{'value': 'कुरुक्षेत्र', 'label': 'kurukzetra', 'attrib': [{'pos': 'n_pn', 'defs': 'field of the Kuru s, Kurukṣetra'}], 'isLemma': 1},
	{'value': 'कुर्वत्', 'label': 'kurvat', 'attrib': [{'pos': 'prap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कुर्वाण', 'label': 'kurvARa', 'attrib': [{'pos': 'prmp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कुल', 'label': 'kula', 'attrib': [{'pos': 'n', 'defs': 'family'}], 'isLemma': 1},
	{'value': 'कुशल', 'label': 'kuSala', 'attrib': [{'pos': 'adj', 'defs': 'skillful'}, {'pos': 'n', 'defs': 'welfare'}], 'isLemma': 1},
	{'value': 'कृ', 'label': 'kf', 'attrib': [{'pos': 'vt8am', 'defs': 'do, make'}], 'isLemma': 1},
	{'value': 'कृत', 'label': 'kfta', 'attrib': [{'pos': 'ppp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कृतवत्', 'label': 'kftavat', 'attrib': [{'pos': 'pap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कृत्य', 'label': 'kftya', 'attrib': [{'pos': 'ger', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कृत्वा', 'label': 'kftvA', 'attrib': [{'pos': 'abs', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'कृश', 'label': 'kfSa', 'attrib': [{'pos': 'adj', 'defs': 'thin'}], 'isLemma': 1},
	{'value': 'केवल', 'label': 'kevala', 'attrib': [{'pos': 'adj', 'defs': 'alone, lone'}], 'isLemma': 1},
	{'value': 'क्रंस्यत्', 'label': 'kraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रंस्यमान', 'label': 'kraMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रन्त्वा', 'label': 'krantvA', 'attrib': [{'pos': 'abs', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रम्', 'label': 'kram', 'attrib': [{'pos': 'vt1am', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 1},
	{'value': 'क्रमणीय', 'label': 'kramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रममाण', 'label': 'kramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रमितव्य', 'label': 'kramitavya', 'attrib': [{'pos': 'ger', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रमितुम्', 'label': 'kramitum', 'attrib': [{'pos': 'inf', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रमित्वा', 'label': 'kramitvA', 'attrib': [{'pos': 'abs', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रम्य', 'label': 'kramya', 'attrib': [{'pos': 'ger', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रम्यमाण', 'label': 'kramyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'step (active); continue, go, increase (middle)'}, {'pos': 'prpp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रयणीय', 'label': 'krayaRIya', 'attrib': [{'pos': 'ger', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'क्रान्त', 'label': 'krAnta', 'attrib': [{'pos': 'ppp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रान्तवत्', 'label': 'krAntavat', 'attrib': [{'pos': 'pap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रान्तुम्', 'label': 'krAntum', 'attrib': [{'pos': 'inf', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रान्त्वा', 'label': 'krAntvA', 'attrib': [{'pos': 'abs', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रामत्', 'label': 'krAmat', 'attrib': [{'pos': 'prap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्राम्यत्', 'label': 'krAmyat', 'attrib': [{'pos': 'prap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'क्रियमाण', 'label': 'kriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'क्री', 'label': 'krI', 'attrib': [{'pos': 'vt9am', 'defs': 'buy'}], 'isLemma': 1},
	{'value': 'क्रीणत्', 'label': 'krIRat', 'attrib': [{'pos': 'prap', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'क्रीणान', 'label': 'krIRAna', 'attrib': [{'pos': 'prmp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'क्रीत', 'label': 'krIta', 'attrib': [{'pos': 'ppp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'क्रीतवत्', 'label': 'krItavat', 'attrib': [{'pos': 'pap', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'क्रीत्वा', 'label': 'krItvA', 'attrib': [{'pos': 'abs', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'क्रीयमाण', 'label': 'krIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'क्रुद्ध', 'label': 'krudDa', 'attrib': [{'pos': 'ppp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रुद्धवत्', 'label': 'krudDavat', 'attrib': [{'pos': 'pap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रुद्ध्वा', 'label': 'krudDvA', 'attrib': [{'pos': 'abs', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रुध्', 'label': 'kruD', 'attrib': [{'pos': 'vi4a', 'defs': 'be angry'}], 'isLemma': 1},
	{'value': 'क्रुध्यत्', 'label': 'kruDyat', 'attrib': [{'pos': 'prap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रुध्यमान', 'label': 'kruDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रेतव्य', 'label': 'kretavya', 'attrib': [{'pos': 'ger', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'क्रेतुम्', 'label': 'kretum', 'attrib': [{'pos': 'inf', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'क्रेय', 'label': 'kreya', 'attrib': [{'pos': 'ger', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'क्रेष्यत्', 'label': 'krezyat', 'attrib': [{'pos': 'fap', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'क्रेष्यमाण', 'label': 'krezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'क्रोत्स्यत्', 'label': 'krotsyat', 'attrib': [{'pos': 'fap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रोत्स्यमान', 'label': 'krotsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रोद्धव्य', 'label': 'krodDavya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रोद्धुम्', 'label': 'krodDum', 'attrib': [{'pos': 'inf', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रोधनीय', 'label': 'kroDanIya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्रोध्य', 'label': 'kroDya', 'attrib': [{'pos': 'ger', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'क्व', 'label': 'kva', 'attrib': [{'pos': 'int_adv', 'defs': 'where'}], 'isLemma': 1},
	{'value': 'क्षण', 'label': 'kzaRa', 'attrib': [{'pos': 'm', 'defs': 'moment'}], 'isLemma': 1},
	{'value': 'क्षत्रिय', 'label': 'kzatriya', 'attrib': [{'pos': 'm', 'defs': 'warrior'}], 'isLemma': 1},
	{'value': 'गच्छत्', 'label': 'gacCat', 'attrib': [{'pos': 'prap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गत', 'label': 'gata', 'attrib': [{'pos': 'ppp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गतवत्', 'label': 'gatavat', 'attrib': [{'pos': 'pap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गत्वा', 'label': 'gatvA', 'attrib': [{'pos': 'abs', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गन्तव्य', 'label': 'gantavya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गन्तुम्', 'label': 'gantum', 'attrib': [{'pos': 'inf', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गन्धर्व', 'label': 'ganDarva', 'attrib': [{'pos': 'm', 'defs': 'gandharva'}], 'isLemma': 1},
	{'value': 'गम्', 'label': 'gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go'}], 'isLemma': 1},
	{'value': 'गमनीय', 'label': 'gamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गमिष्यत्', 'label': 'gamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गमिष्यमाण', 'label': 'gamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गम्य', 'label': 'gamya', 'attrib': [{'pos': 'ger', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गम्यमान', 'label': 'gamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'गरितुम्', 'label': 'garitum', 'attrib': [{'pos': 'inf', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'गरिष्यत्', 'label': 'garizyat', 'attrib': [{'pos': 'fap', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'गरिष्यमाण', 'label': 'garizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'गर्भ', 'label': 'garBa', 'attrib': [{'pos': 'm', 'defs': 'womb, embryo'}], 'isLemma': 1},
	{'value': 'गर्भिन्', 'label': 'garBin', 'attrib': [{'pos': 'adj', 'defs': 'pregnant'}], 'isLemma': 1},
	{'value': 'गीर्ण', 'label': 'gIrRa', 'attrib': [{'pos': 'ppp', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'गीर्णवत्', 'label': 'gIrRavat', 'attrib': [{'pos': 'pap', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'गीर्त्वा', 'label': 'gIrtvA', 'attrib': [{'pos': 'abs', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'गीर्यमाण', 'label': 'gIryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'गुरु', 'label': 'guru', 'attrib': [{'pos': 'adj', 'defs': 'heavy, important'}, {'pos': 'm', 'defs': 'teacher'}], 'isLemma': 1},
	{'value': 'गृणत्', 'label': 'gfRat', 'attrib': [{'pos': 'prap', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'गृह', 'label': 'gfha', 'attrib': [{'pos': 'n, m', 'defs': 'house'}], 'isLemma': 1},
	{'value': 'गृहाण', 'label': 'gfhARa', 'attrib': [{'pos': 'prmp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'गृहीत', 'label': 'gfhIta', 'attrib': [{'pos': 'ppp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'गृहीतवत्', 'label': 'gfhItavat', 'attrib': [{'pos': 'pap', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'गृहीत्वा', 'label': 'gfhItvA', 'attrib': [{'pos': 'abs', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'गृह्णत्', 'label': 'gfhRat', 'attrib': [{'pos': 'prap', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'गृह्यमाण', 'label': 'gfhyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'गॄ', 'label': 'gF', 'attrib': [{'pos': 'vt9a', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 1},
	{'value': 'ग्रह्', 'label': 'grah', 'attrib': [{'pos': 'vt9am', 'defs': 'grab, grasp, seize'}], 'isLemma': 1},
	{'value': 'ग्रहणीय', 'label': 'grahaRIya', 'attrib': [{'pos': 'ger', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'ग्रहीतव्य', 'label': 'grahItavya', 'attrib': [{'pos': 'ger', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'ग्रहीतुम्', 'label': 'grahItum', 'attrib': [{'pos': 'inf', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'ग्रहीष्यत्', 'label': 'grahIzyat', 'attrib': [{'pos': 'fap', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'ग्रहीष्यमाण', 'label': 'grahIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'ग्राम', 'label': 'grAma', 'attrib': [{'pos': 'm', 'defs': 'village'}], 'isLemma': 1},
	{'value': 'ग्राह्य', 'label': 'grAhya', 'attrib': [{'pos': 'ger', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'घट', 'label': 'Gawa', 'attrib': [{'pos': 'm', 'defs': 'pot'}], 'isLemma': 1},
	{'value': 'घात्य', 'label': 'GAtya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'घोर', 'label': 'Gora', 'attrib': [{'pos': 'adj', 'defs': 'horrible'}], 'isLemma': 1},
	{'value': 'घ्नत्', 'label': 'Gnat', 'attrib': [{'pos': 'prap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'च', 'label': 'ca', 'attrib': [{'pos': 'conj_pcl', 'defs': 'and'}], 'isLemma': 1},
	{'value': 'चकमान', 'label': 'cakamAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'desire, love'}], 'isLemma': 0, 'parents': ['kam']},
	{'value': 'चकृवस्', 'label': 'cakfvas', 'attrib': [{'pos': 'pfap', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'चक्रमाण', 'label': 'cakramARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'चक्रम्वस्', 'label': 'cakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'step (active); continue, go, increase (middle)'}], 'isLemma': 0, 'parents': ['kram']},
	{'value': 'चक्राण', 'label': 'cakrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'do, make'}], 'isLemma': 0, 'parents': ['kf']},
	{'value': 'चतुर्', 'label': 'catur', 'attrib': [{'pos': 'card_num', 'defs': 'four'}], 'isLemma': 1},
	{'value': 'चतुर्थ', 'label': 'caturTa', 'attrib': [{'pos': 'ord_num', 'defs': 'fourth'}], 'isLemma': 1},
	{'value': 'चन्द्र', 'label': 'candra', 'attrib': [{'pos': 'm', 'defs': 'moon'}, {'pos': 'm_pn', 'defs': 'Hari Candra'}], 'isLemma': 1},
	{'value': 'चर्', 'label': 'car', 'attrib': [{'pos': 'vt1am', 'defs': 'move, do'}], 'isLemma': 1},
	{'value': 'चरणीय', 'label': 'caraRIya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरत्', 'label': 'carat', 'attrib': [{'pos': 'prap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरमाण', 'label': 'caramARa', 'attrib': [{'pos': 'prmp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरित', 'label': 'carita', 'attrib': [{'pos': 'ppp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरितवत्', 'label': 'caritavat', 'attrib': [{'pos': 'pap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरितव्य', 'label': 'caritavya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरितुम्', 'label': 'caritum', 'attrib': [{'pos': 'inf', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरित्वा', 'label': 'caritvA', 'attrib': [{'pos': 'abs', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरिष्यत्', 'label': 'carizyat', 'attrib': [{'pos': 'fap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चरिष्यमाण', 'label': 'carizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चर्य', 'label': 'carya', 'attrib': [{'pos': 'ger', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चर्यमाण', 'label': 'caryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चारिन्', 'label': 'cArin', 'attrib': [{'pos': 'adj', 'defs': 'doing, practicing'}], 'isLemma': 1},
	{'value': 'चिक्रियाण', 'label': 'cikriyARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'चिक्रीवस्', 'label': 'cikrIvas', 'attrib': [{'pos': 'pfap', 'defs': 'buy'}], 'isLemma': 0, 'parents': ['krI']},
	{'value': 'चिच्छिदान', 'label': 'cicCidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'चिच्छिद्वस्', 'label': 'cicCidvas', 'attrib': [{'pos': 'pfap', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'चित्', 'label': 'cit', 'attrib': [{'pos': 'pcl', 'defs': '/'}], 'isLemma': 1},
	{'value': 'चित्र', 'label': 'citra', 'attrib': [{'pos': 'adj', 'defs': 'conspicuous, bright, variegated, different'}], 'isLemma': 1},
	{'value': 'चिन्त्', 'label': 'cint', 'attrib': [{'pos': 'vt10am', 'defs': 'consider'}], 'isLemma': 1},
	{'value': 'चिन्तनीय', 'label': 'cintanIya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तयत्', 'label': 'cintayat', 'attrib': [{'pos': 'prap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तयमान', 'label': 'cintayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तयाम्', 'label': 'cintayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तयिष्यत्', 'label': 'cintayizyat', 'attrib': [{'pos': 'fap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तयिष्यमाण', 'label': 'cintayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तित', 'label': 'cintita', 'attrib': [{'pos': 'ppp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तितवत्', 'label': 'cintitavat', 'attrib': [{'pos': 'pap', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तितव्य', 'label': 'cintitavya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तितुम्', 'label': 'cintitum', 'attrib': [{'pos': 'inf', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्तित्वा', 'label': 'cintitvA', 'attrib': [{'pos': 'abs', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्त्य', 'label': 'cintya', 'attrib': [{'pos': 'ger', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिन्त्यमान', 'label': 'cintyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consider'}], 'isLemma': 0, 'parents': ['cint']},
	{'value': 'चिर', 'label': 'cira', 'attrib': [{'pos': 'adj', 'defs': 'long'}], 'isLemma': 1},
	{'value': 'चुक्रुधान', 'label': 'cukruDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'चुक्रुध्वस्', 'label': 'cukruDvas', 'attrib': [{'pos': 'pfap', 'defs': 'be angry'}], 'isLemma': 0, 'parents': ['kruD']},
	{'value': 'चुर्', 'label': 'cur', 'attrib': [{'pos': 'vt10a', 'defs': 'steal'}], 'isLemma': 1},
	{'value': 'चेराण', 'label': 'cerARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चेरिवस्', 'label': 'cerivas', 'attrib': [{'pos': 'pfap', 'defs': 'move, do'}], 'isLemma': 0, 'parents': ['car']},
	{'value': 'चोरयत्', 'label': 'corayat', 'attrib': [{'pos': 'prap', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'चोरयाम्', 'label': 'corayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'चोरयितुम्', 'label': 'corayitum', 'attrib': [{'pos': 'inf', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'चोरयित्वा', 'label': 'corayitvA', 'attrib': [{'pos': 'abs', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'चोरयिष्यत्', 'label': 'corayizyat', 'attrib': [{'pos': 'fap', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'चोरयिष्यमाण', 'label': 'corayizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'चोरित', 'label': 'corita', 'attrib': [{'pos': 'ppp', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'चोरितवत्', 'label': 'coritavat', 'attrib': [{'pos': 'pap', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'चोर्यमाण', 'label': 'coryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'steal'}], 'isLemma': 0, 'parents': ['cur']},
	{'value': 'चौर', 'label': 'cOra', 'attrib': [{'pos': 'm', 'defs': 'thief'}], 'isLemma': 1},
	{'value': 'छित्त्वा', 'label': 'CittvA', 'attrib': [{'pos': 'abs', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छिद्', 'label': 'Cid', 'attrib': [{'pos': 'vt7am', 'defs': 'cleave'}], 'isLemma': 1},
	{'value': 'छिद्यमान', 'label': 'CidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छिन्दत्', 'label': 'Cindat', 'attrib': [{'pos': 'prap', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छिन्दान', 'label': 'CindAna', 'attrib': [{'pos': 'prmp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छिन्न', 'label': 'Cinna', 'attrib': [{'pos': 'ppp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छिन्नवत्', 'label': 'Cinnavat', 'attrib': [{'pos': 'pap', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छेत्तव्य', 'label': 'Cettavya', 'attrib': [{'pos': 'ger', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छेत्तुम्', 'label': 'Cettum', 'attrib': [{'pos': 'inf', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छेत्स्यत्', 'label': 'Cetsyat', 'attrib': [{'pos': 'fap', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छेत्स्यमान', 'label': 'CetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छेदनीय', 'label': 'CedanIya', 'attrib': [{'pos': 'ger', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'छेद्य', 'label': 'Cedya', 'attrib': [{'pos': 'ger', 'defs': 'cleave'}], 'isLemma': 0, 'parents': ['Cid']},
	{'value': 'जगत्', 'label': 'jagat', 'attrib': [{'pos': 'n', 'defs': 'moving, world'}], 'isLemma': 1},
	{'value': 'जगन्वस्', 'label': 'jaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'जगराण', 'label': 'jagarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'जगर्वस्', 'label': 'jagarvas', 'attrib': [{'pos': 'pfap', 'defs': 'sing, praise, recite, invoke'}], 'isLemma': 0, 'parents': ['gF']},
	{'value': 'जगृहाण', 'label': 'jagfhARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'जगृह्वस्', 'label': 'jagfhvas', 'attrib': [{'pos': 'pfap', 'defs': 'grab, grasp, seize'}], 'isLemma': 0, 'parents': ['grah']},
	{'value': 'जग्मान', 'label': 'jagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'जग्मिवस्', 'label': 'jagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['gam']},
	{'value': 'जघन्वस्', 'label': 'jaGanvas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'जघ्नान', 'label': 'jaGnAna', 'attrib': [{'pos': 'pfpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'जघ्निवस्', 'label': 'jaGnivas', 'attrib': [{'pos': 'pfap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'जजराण', 'label': 'jajarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जजर्वस्', 'label': 'jajarvas', 'attrib': [{'pos': 'pfap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जज्ञान', 'label': 'jajYAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'be born, arise; caus. generate'}, {'pos': 'pfpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jan', 'jYA']},
	{'value': 'जज्ञिवस्', 'label': 'jajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'जन्', 'label': 'jan', 'attrib': [{'pos': 'vi4m', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 1},
	{'value': 'जन', 'label': 'jana', 'attrib': [{'pos': 'm', 'defs': 'people or person'}], 'isLemma': 1},
	{'value': 'जननीय', 'label': 'jananIya', 'attrib': [{'pos': 'ger', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'जनयाम्', 'label': 'janayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'जनितव्य', 'label': 'janitavya', 'attrib': [{'pos': 'ger', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'जनितुम्', 'label': 'janitum', 'attrib': [{'pos': 'inf', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'जनित्वा', 'label': 'janitvA', 'attrib': [{'pos': 'abs', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'जनिष्यमाण', 'label': 'janizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'जन्य', 'label': 'janya', 'attrib': [{'pos': 'ger', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'जयत्', 'label': 'jayat', 'attrib': [{'pos': 'prap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जयनीय', 'label': 'jayanIya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जरणीय', 'label': 'jaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरितव्य', 'label': 'jaritavya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरितुम्', 'label': 'jaritum', 'attrib': [{'pos': 'inf', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरित्वा', 'label': 'jaritvA', 'attrib': [{'pos': 'abs', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरिष्यत्', 'label': 'jarizyat', 'attrib': [{'pos': 'fap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरिष्यमाण', 'label': 'jarizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरीतव्य', 'label': 'jarItavya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरीतुम्', 'label': 'jarItum', 'attrib': [{'pos': 'inf', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जरीत्वा', 'label': 'jarItvA', 'attrib': [{'pos': 'abs', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जर्य', 'label': 'jarya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जहत्', 'label': 'jahat', 'attrib': [{'pos': 'prap', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'जहान', 'label': 'jahAna', 'attrib': [{'pos': 'prmp', 'defs': 'leave, abandon'}, {'pos': 'pfmp, pfpp', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'जहिवस्', 'label': 'jahivas', 'attrib': [{'pos': 'pfap', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'जहृवस्', 'label': 'jahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'जह्राण', 'label': 'jahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'जात', 'label': 'jAta', 'attrib': [{'pos': 'ppp', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'जातवत्', 'label': 'jAtavat', 'attrib': [{'pos': 'pap', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'जानत्', 'label': 'jAnat', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'जायमान', 'label': 'jAyamAna', 'attrib': [{'pos': 'prmp, prpp', 'defs': 'be born, arise; caus. generate'}], 'isLemma': 0, 'parents': ['jan']},
	{'value': 'जार्य', 'label': 'jArya', 'attrib': [{'pos': 'ger', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जि', 'label': 'ji', 'attrib': [{'pos': 'vt1a', 'defs': 'conquer'}], 'isLemma': 1},
	{'value': 'जिगिवस्', 'label': 'jigivas', 'attrib': [{'pos': 'pfap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जिग्यान', 'label': 'jigyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जिजीवस्', 'label': 'jijIvas', 'attrib': [{'pos': 'pfap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जिजीवान', 'label': 'jijIvAna', 'attrib': [{'pos': 'pfpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जित', 'label': 'jita', 'attrib': [{'pos': 'ppp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जितवत्', 'label': 'jitavat', 'attrib': [{'pos': 'pap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जित्वा', 'label': 'jitvA', 'attrib': [{'pos': 'abs', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जीयमान', 'label': 'jIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जीर्ण', 'label': 'jIrRa', 'attrib': [{'pos': 'ppp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जीर्णवत्', 'label': 'jIrRavat', 'attrib': [{'pos': 'pap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जीर्यत्', 'label': 'jIryat', 'attrib': [{'pos': 'prap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जीर्यमाण', 'label': 'jIryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जीव्', 'label': 'jIv', 'attrib': [{'pos': 'vi1a', 'defs': 'live'}], 'isLemma': 1},
	{'value': 'जीवत्', 'label': 'jIvat', 'attrib': [{'pos': 'prap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीवनीय', 'label': 'jIvanIya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीवित', 'label': 'jIvita', 'attrib': [{'pos': 'ppp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीवितवत्', 'label': 'jIvitavat', 'attrib': [{'pos': 'pap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीवितव्य', 'label': 'jIvitavya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीवितुम्', 'label': 'jIvitum', 'attrib': [{'pos': 'inf', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीवित्वा', 'label': 'jIvitvA', 'attrib': [{'pos': 'abs', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीविष्यत्', 'label': 'jIvizyat', 'attrib': [{'pos': 'fap', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीविष्यमाण', 'label': 'jIvizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीव्य', 'label': 'jIvya', 'attrib': [{'pos': 'ger', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जीव्यमान', 'label': 'jIvyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'live'}], 'isLemma': 0, 'parents': ['jIv']},
	{'value': 'जुहवाम्', 'label': 'juhavAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'जुहुवान', 'label': 'juhuvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'जुहूवस्', 'label': 'juhUvas', 'attrib': [{'pos': 'pfap', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'जुह्वत्', 'label': 'juhvat', 'attrib': [{'pos': 'prap', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'जॄ', 'label': 'jF', 'attrib': [{'pos': 'vi4a', 'defs': 'wither, age, grow old'}], 'isLemma': 1},
	{'value': 'जेतव्य', 'label': 'jetavya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जेतुम्', 'label': 'jetum', 'attrib': [{'pos': 'inf', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जेय', 'label': 'jeya', 'attrib': [{'pos': 'ger', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जेराण', 'label': 'jerARa', 'attrib': [{'pos': 'pfpp', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जेरिवस्', 'label': 'jerivas', 'attrib': [{'pos': 'pfap', 'defs': 'wither, age, grow old'}], 'isLemma': 0, 'parents': ['jF']},
	{'value': 'जेष्यत्', 'label': 'jezyat', 'attrib': [{'pos': 'fap', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'जेष्यमाण', 'label': 'jezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'conquer'}], 'isLemma': 0, 'parents': ['ji']},
	{'value': 'ज्ञा', 'label': 'jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'know'}], 'isLemma': 1},
	{'value': 'ज्ञात', 'label': 'jYAta', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञातवत्', 'label': 'jYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञातव्य', 'label': 'jYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञातुम्', 'label': 'jYAtum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञात्वा', 'label': 'jYAtvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञान', 'label': 'jYAna', 'attrib': [{'pos': 'n', 'defs': 'knowledge'}], 'isLemma': 1},
	{'value': 'ज्ञानीय', 'label': 'jYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञायमान', 'label': 'jYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञास्यत्', 'label': 'jYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञास्यमान', 'label': 'jYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'ज्ञेय', 'label': 'jYeya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['jYA']},
	{'value': 'तंस्यत्', 'label': 'taMsyat', 'attrib': [{'pos': 'fap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तंस्यमान', 'label': 'taMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तत', 'label': 'tata', 'attrib': [{'pos': 'ppp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'ततवत्', 'label': 'tatavat', 'attrib': [{'pos': 'pap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'ततस्', 'label': 'tatas', 'attrib': [{'pos': 'dem_adv', 'defs': 'from that, therefore, then'}], 'isLemma': 1},
	{'value': 'तत्यजान', 'label': 'tatyajAna', 'attrib': [{'pos': 'pfpp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'तत्यज्वस्', 'label': 'tatyajvas', 'attrib': [{'pos': 'pfap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'तत्र', 'label': 'tatra', 'attrib': [{'pos': 'dem_adv', 'defs': 'there'}], 'isLemma': 1},
	{'value': 'तत्राण', 'label': 'tatrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'तथा', 'label': 'taTA', 'attrib': [{'pos': 'dem_adv', 'defs': 'in that manner, thus'}], 'isLemma': 1},
	{'value': 'तद्', 'label': 'tad', 'attrib': [{'pos': 'dem_pron, dem_adj', 'defs': 'that, the'}, {'pos': 'dem_adv', 'defs': 'so'}], 'isLemma': 1},
	{'value': 'तदा', 'label': 'tadA', 'attrib': [{'pos': 'dem_adv', 'defs': 'at that time, then'}], 'isLemma': 1},
	{'value': 'तन्', 'label': 'tan', 'attrib': [{'pos': 'vt8am', 'defs': 'extend'}], 'isLemma': 1},
	{'value': 'तननीय', 'label': 'tananIya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तनितव्य', 'label': 'tanitavya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तनितुम्', 'label': 'tanitum', 'attrib': [{'pos': 'inf', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तनित्वा', 'label': 'tanitvA', 'attrib': [{'pos': 'abs', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तनिष्यत्', 'label': 'tanizyat', 'attrib': [{'pos': 'fap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तनिष्यमाण', 'label': 'tanizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तन्यमान', 'label': 'tanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तन्वत्', 'label': 'tanvat', 'attrib': [{'pos': 'prap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तन्वान', 'label': 'tanvAna', 'attrib': [{'pos': 'prmp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तर्हि', 'label': 'tarhi', 'attrib': [{'pos': 'dem_adv', 'defs': 'then, therefore'}], 'isLemma': 1},
	{'value': 'तस्थान', 'label': 'tasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'तस्थिवस्', 'label': 'tasTivas', 'attrib': [{'pos': 'pfap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'तान्य', 'label': 'tAnya', 'attrib': [{'pos': 'ger', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तावत्', 'label': 'tAvat', 'attrib': [{'pos': 'dem_adj', 'defs': 'of that extent'}, {'pos': 'dem_adv', 'defs': 'to that extent'}], 'isLemma': 1},
	{'value': 'तित्यजान', 'label': 'tityajAna', 'attrib': [{'pos': 'pfpp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'तित्यज्वस्', 'label': 'tityajvas', 'attrib': [{'pos': 'pfap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'तिरस्', 'label': 'tiras', 'attrib': [{'pos': 'preverb', 'defs': 'across, horizontal'}], 'isLemma': 1},
	{'value': 'तिरस्_भू', 'label': 'tiras_BU', 'attrib': [{'pos': 'vi1a', 'defs': 'become horizontal, disappear'}], 'isLemma': 1},
	{'value': 'तिरोबभूवस्', 'label': 'tirobaBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'तिरोभवत्', 'label': 'tiroBavat', 'attrib': [{'pos': 'prap', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'तिरोभवनीय', 'label': 'tiroBavanIya', 'attrib': [{'pos': 'ger', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'तिरोभवितव्य', 'label': 'tiroBavitavya', 'attrib': [{'pos': 'ger', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'तिरोभवितुम्', 'label': 'tiroBavitum', 'attrib': [{'pos': 'inf', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'तिरोभविष्यत्', 'label': 'tiroBavizyat', 'attrib': [{'pos': 'fap', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'तिरोभविष्यमाण', 'label': 'tiroBavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'तिरोभव्य', 'label': 'tiroBavya', 'attrib': [{'pos': 'ger', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'तिरोभूत', 'label': 'tiroBUta', 'attrib': [{'pos': 'ppp', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'तिरोभूतवत्', 'label': 'tiroBUtavat', 'attrib': [{'pos': 'pap', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'तिरोभूय', 'label': 'tiroBUya', 'attrib': [{'pos': 'abs', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'तिरोभूयमान', 'label': 'tiroBUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'become horizontal, disappear'}], 'isLemma': 0, 'parents': ['tiras_BU']},
	{'value': 'तिष्ठत्', 'label': 'tizWat', 'attrib': [{'pos': 'prap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'तिष्ठमान', 'label': 'tizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'तु', 'label': 'tu', 'attrib': [{'pos': 'conj_pcl', 'defs': 'but'}], 'isLemma': 1},
	{'value': 'तृतीय', 'label': 'tftIya', 'attrib': [{'pos': 'ord_num', 'defs': 'third'}], 'isLemma': 1},
	{'value': 'तेनान', 'label': 'tenAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'तेनिवस्', 'label': 'tenivas', 'attrib': [{'pos': 'pfap', 'defs': 'extend'}], 'isLemma': 0, 'parents': ['tan']},
	{'value': 'त्यक्त', 'label': 'tyakta', 'attrib': [{'pos': 'ppp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'त्यक्तवत्', 'label': 'tyaktavat', 'attrib': [{'pos': 'pap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'त्यक्तव्य', 'label': 'tyaktavya', 'attrib': [{'pos': 'ger', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'त्यक्तुम्', 'label': 'tyaktum', 'attrib': [{'pos': 'inf', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'त्यक्त्वा', 'label': 'tyaktvA', 'attrib': [{'pos': 'abs', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'त्यक्ष्यत्', 'label': 'tyakzyat', 'attrib': [{'pos': 'fap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'त्यक्ष्यमाण', 'label': 'tyakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'त्यज्', 'label': 'tyaj', 'attrib': [{'pos': 'vt1a', 'defs': 'abandon'}], 'isLemma': 1},
	{'value': 'त्यजत्', 'label': 'tyajat', 'attrib': [{'pos': 'prap', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'त्यजनीय', 'label': 'tyajanIya', 'attrib': [{'pos': 'ger', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'त्यज्यमान', 'label': 'tyajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'त्याज्य', 'label': 'tyAjya', 'attrib': [{'pos': 'ger', 'defs': 'abandon'}], 'isLemma': 0, 'parents': ['tyaj']},
	{'value': 'त्राण', 'label': 'trARa', 'attrib': [{'pos': 'ppp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'त्रात', 'label': 'trAta', 'attrib': [{'pos': 'ppp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'त्रातवत्', 'label': 'trAtavat', 'attrib': [{'pos': 'pap', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'त्रातुम्', 'label': 'trAtum', 'attrib': [{'pos': 'inf', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'त्रात्वा', 'label': 'trAtvA', 'attrib': [{'pos': 'abs', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'त्रायमाण', 'label': 'trAyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'save'}, {'pos': 'prpp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'त्रास्यमान', 'label': 'trAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'save'}], 'isLemma': 0, 'parents': ['trE']},
	{'value': 'त्रि', 'label': 'tri', 'attrib': [{'pos': 'card_num', 'defs': 'three'}], 'isLemma': 1},
	{'value': 'त्रै', 'label': 'trE', 'attrib': [{'pos': 'vt1m', 'defs': 'save'}], 'isLemma': 1},
	{'value': 'दग्ध', 'label': 'dagDa', 'attrib': [{'pos': 'ppp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दग्धवत्', 'label': 'dagDavat', 'attrib': [{'pos': 'pap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दग्धव्य', 'label': 'dagDavya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दग्धुम्', 'label': 'dagDum', 'attrib': [{'pos': 'inf', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दग्ध्वा', 'label': 'dagDvA', 'attrib': [{'pos': 'abs', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दत्त', 'label': 'datta', 'attrib': [{'pos': 'ppp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दत्तवत्', 'label': 'dattavat', 'attrib': [{'pos': 'pap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दत्त्वा', 'label': 'dattvA', 'attrib': [{'pos': 'abs', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'ददत्', 'label': 'dadat', 'attrib': [{'pos': 'prap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'ददहान', 'label': 'dadahAna', 'attrib': [{'pos': 'pfpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'ददह्वस्', 'label': 'dadahvas', 'attrib': [{'pos': 'pfap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'ददान', 'label': 'dadAna', 'attrib': [{'pos': 'prmp', 'defs': 'give'}, {'pos': 'pfmp, pfpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'ददिवस्', 'label': 'dadivas', 'attrib': [{'pos': 'pfap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'ददृशान', 'label': 'dadfSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'ददृशिवस्', 'label': 'dadfSivas', 'attrib': [{'pos': 'pfap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'ददृश्वस्', 'label': 'dadfSvas', 'attrib': [{'pos': 'pfap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'दधत्', 'label': 'daDat', 'attrib': [{'pos': 'prap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'दधान', 'label': 'daDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put'}, {'pos': 'pfmp, pfpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'दधिवस्', 'label': 'daDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'दन्त', 'label': 'danta', 'attrib': [{'pos': 'm', 'defs': 'tooth, tusk'}], 'isLemma': 1},
	{'value': 'दर्शनीय', 'label': 'darSanIya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'दशन्', 'label': 'daSan', 'attrib': [{'pos': 'card_num', 'defs': 'ten'}], 'isLemma': 1},
	{'value': 'दशम', 'label': 'daSama', 'attrib': [{'pos': 'ord_num', 'defs': 'tenth'}], 'isLemma': 1},
	{'value': 'दह्', 'label': 'dah', 'attrib': [{'pos': 'vt1a', 'defs': 'burn'}], 'isLemma': 1},
	{'value': 'दहत्', 'label': 'dahat', 'attrib': [{'pos': 'prap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दहनीय', 'label': 'dahanIya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दह्यमान', 'label': 'dahyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दा', 'label': 'dA', 'attrib': [{'pos': 'vt3am', 'defs': 'give'}], 'isLemma': 1},
	{'value': 'दातव्य', 'label': 'dAtavya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दातुम्', 'label': 'dAtum', 'attrib': [{'pos': 'inf', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दानीय', 'label': 'dAnIya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दास्यत्', 'label': 'dAsyat', 'attrib': [{'pos': 'fap', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दास्यमान', 'label': 'dAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दाह्य', 'label': 'dAhya', 'attrib': [{'pos': 'ger', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'दिदिवस्', 'label': 'didivas', 'attrib': [{'pos': 'pfap', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'दिदिवान', 'label': 'didivAna', 'attrib': [{'pos': 'pfpp', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'दिन', 'label': 'dina', 'attrib': [{'pos': 'n', 'defs': 'day'}], 'isLemma': 1},
	{'value': 'दिव्', 'label': 'div', 'attrib': [{'pos': 'm, f', 'defs': 'sky, heaven, day'}, {'pos': 'vt4a', 'defs': 'play'}], 'isLemma': 1},
	{'value': 'दिश्', 'label': 'diS', 'attrib': [{'pos': 'f', 'defs': 'direction'}], 'isLemma': 1},
	{'value': 'दीयमान', 'label': 'dIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'दीर्घ', 'label': 'dIrGa', 'attrib': [{'pos': 'adj', 'defs': 'long'}], 'isLemma': 1},
	{'value': 'दीर्घायुस्', 'label': 'dIrGAyus', 'attrib': [{'pos': 'adj', 'defs': 'whose life is long, long-lived'}], 'isLemma': 1},
	{'value': 'दीव्यत्', 'label': 'dIvyat', 'attrib': [{'pos': 'prap', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'दीव्यमान', 'label': 'dIvyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'दुःख', 'label': 'duHKa', 'attrib': [{'pos': 'adj', 'defs': 'uncomfortable'}, {'pos': 'n', 'defs': 'sorrow'}], 'isLemma': 1},
	{'value': 'दुःखिन्', 'label': 'duHKin', 'attrib': [{'pos': 'adj', 'defs': 'sorrowful, sad'}], 'isLemma': 1},
	{'value': 'दुराप', 'label': 'durApa', 'attrib': [{'pos': 'adj', 'defs': 'difficult to obtain'}], 'isLemma': 1},
	{'value': 'दुर्मनस्', 'label': 'durmanas', 'attrib': [{'pos': 'adj', 'defs': 'low-spirited, sad'}], 'isLemma': 1},
	{'value': 'दुष्षन्त', 'label': 'duzzanta', 'attrib': [{'pos': 'm_pn', 'defs': 'Duṣṣanta'}], 'isLemma': 1},
	{'value': 'दुस्', 'label': 'dus', 'attrib': [{'pos': 'preverb', 'defs': 'ill'}], 'isLemma': 1},
	{'value': 'दुहितृ', 'label': 'duhitf', 'attrib': [{'pos': 'f', 'defs': 'daughter'}], 'isLemma': 1},
	{'value': 'दूर', 'label': 'dUra', 'attrib': [{'pos': 'adj', 'defs': 'distant'}], 'isLemma': 1},
	{'value': 'दृश्', 'label': 'dfS', 'attrib': [{'pos': 'vt1a', 'defs': 'see, watch'}], 'isLemma': 1},
	{'value': 'दृश्य', 'label': 'dfSya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'दृश्यमान', 'label': 'dfSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'दृष्ट', 'label': 'dfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'दृष्टवत्', 'label': 'dfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'दृष्ट्वा', 'label': 'dfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'देय', 'label': 'deya', 'attrib': [{'pos': 'ger', 'defs': 'give'}], 'isLemma': 0, 'parents': ['dA']},
	{'value': 'देव', 'label': 'deva', 'attrib': [{'pos': 'm', 'defs': 'god'}], 'isLemma': 1},
	{'value': 'देवनीय', 'label': 'devanIya', 'attrib': [{'pos': 'ger', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'देवितव्य', 'label': 'devitavya', 'attrib': [{'pos': 'ger', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'देवितुम्', 'label': 'devitum', 'attrib': [{'pos': 'inf', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'देवित्वा', 'label': 'devitvA', 'attrib': [{'pos': 'abs', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'देविष्यत्', 'label': 'devizyat', 'attrib': [{'pos': 'fap', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'देविष्यमाण', 'label': 'devizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'देवी', 'label': 'devI', 'attrib': [{'pos': 'f', 'defs': 'goddess'}], 'isLemma': 1},
	{'value': 'देव्य', 'label': 'devya', 'attrib': [{'pos': 'ger', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'देश', 'label': 'deSa', 'attrib': [{'pos': 'm', 'defs': 'place, country'}], 'isLemma': 1},
	{'value': 'देह', 'label': 'deha', 'attrib': [{'pos': 'm', 'defs': 'body'}], 'isLemma': 1},
	{'value': 'द्यूत', 'label': 'dyUta', 'attrib': [{'pos': 'ppp', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'द्यूतवत्', 'label': 'dyUtavat', 'attrib': [{'pos': 'pap', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'द्यूत्वा', 'label': 'dyUtvA', 'attrib': [{'pos': 'abs', 'defs': 'play'}], 'isLemma': 0, 'parents': ['div']},
	{'value': 'द्रक्ष्यत्', 'label': 'drakzyat', 'attrib': [{'pos': 'fap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'द्रक्ष्यमाण', 'label': 'drakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'द्रष्टव्य', 'label': 'drazwavya', 'attrib': [{'pos': 'ger', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'द्रष्टुम्', 'label': 'drazwum', 'attrib': [{'pos': 'inf', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'द्वि', 'label': 'dvi', 'attrib': [{'pos': 'card_num', 'defs': 'two'}], 'isLemma': 1},
	{'value': 'द्वितीय', 'label': 'dvitIya', 'attrib': [{'pos': 'ord_num', 'defs': 'second'}], 'isLemma': 1},
	{'value': 'धक्ष्यत्', 'label': 'Dakzyat', 'attrib': [{'pos': 'fap', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'धक्ष्यमाण', 'label': 'DakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'burn'}], 'isLemma': 0, 'parents': ['dah']},
	{'value': 'धन', 'label': 'Dana', 'attrib': [{'pos': 'n', 'defs': 'wealth, money'}], 'isLemma': 1},
	{'value': 'धनिन्', 'label': 'Danin', 'attrib': [{'pos': 'adj', 'defs': 'wealthy'}], 'isLemma': 1},
	{'value': 'धनुस्', 'label': 'Danus', 'attrib': [{'pos': 'n', 'defs': 'bow'}], 'isLemma': 1},
	{'value': 'धर्म', 'label': 'Darma', 'attrib': [{'pos': 'm', 'defs': 'dharma , duty, right action'}], 'isLemma': 1},
	{'value': 'धा', 'label': 'DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put'}], 'isLemma': 1},
	{'value': 'धातव्य', 'label': 'DAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'धातुम्', 'label': 'DAtum', 'attrib': [{'pos': 'inf', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'धानीय', 'label': 'DAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'धार्तराष्ट्र', 'label': 'DArtarAzwra', 'attrib': [{'pos': 'm', 'defs': 'descendant of Dhṛtarāṣṭra'}], 'isLemma': 1},
	{'value': 'धास्यत्', 'label': 'DAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'धास्यमान', 'label': 'DAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'धी', 'label': 'DI', 'attrib': [{'pos': 'f', 'defs': 'intelligence, thought'}], 'isLemma': 1},
	{'value': 'धीमत्', 'label': 'DImat', 'attrib': [{'pos': 'adj', 'defs': 'wise, intelligent'}], 'isLemma': 1},
	{'value': 'धीयमान', 'label': 'DIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'धेनु', 'label': 'Denu', 'attrib': [{'pos': 'f', 'defs': 'cow'}], 'isLemma': 1},
	{'value': 'धेय', 'label': 'Deya', 'attrib': [{'pos': 'ger', 'defs': 'put'}], 'isLemma': 0, 'parents': ['DA']},
	{'value': 'न', 'label': 'na', 'attrib': [{'pos': 'neg_pcl', 'defs': 'not'}], 'isLemma': 1},
	{'value': 'नगर', 'label': 'nagara', 'attrib': [{'pos': 'n', 'defs': 'city'}], 'isLemma': 1},
	{'value': 'नग्न', 'label': 'nagna', 'attrib': [{'pos': 'adj', 'defs': 'naked'}], 'isLemma': 1},
	{'value': 'नदी', 'label': 'nadI', 'attrib': [{'pos': 'f', 'defs': 'river'}], 'isLemma': 1},
	{'value': 'नयत्', 'label': 'nayat', 'attrib': [{'pos': 'prap', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'नयनीय', 'label': 'nayanIya', 'attrib': [{'pos': 'ger', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'नयमान', 'label': 'nayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'नर', 'label': 'nara', 'attrib': [{'pos': 'm', 'defs': 'man'}], 'isLemma': 1},
	{'value': 'नवन्', 'label': 'navan', 'attrib': [{'pos': 'card_num', 'defs': 'nine'}], 'isLemma': 1},
	{'value': 'नवम', 'label': 'navama', 'attrib': [{'pos': 'ord_num', 'defs': 'ninth'}], 'isLemma': 1},
	{'value': 'नाम', 'label': 'nAma', 'attrib': [{'pos': 'adv', 'defs': 'by name'}], 'isLemma': 1},
	{'value': 'नामन्', 'label': 'nAman', 'attrib': [{'pos': 'n', 'defs': 'name'}], 'isLemma': 1},
	{'value': 'नि', 'label': 'ni', 'attrib': [{'pos': 'preverb', 'defs': 'in'}], 'isLemma': 1},
	{'value': 'नि_धा', 'label': 'ni_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'put down, deposit'}], 'isLemma': 1},
	{'value': 'निदधत्', 'label': 'nidaDat', 'attrib': [{'pos': 'prap', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'निदधान', 'label': 'nidaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'put down, deposit'}, {'pos': 'pfmp, pfpp', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'निदधिवस्', 'label': 'nidaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'निधातव्य', 'label': 'niDAtavya', 'attrib': [{'pos': 'ger', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'निधातुम्', 'label': 'niDAtum', 'attrib': [{'pos': 'inf', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'निधानीय', 'label': 'niDAnIya', 'attrib': [{'pos': 'ger', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'निधाय', 'label': 'niDAya', 'attrib': [{'pos': 'abs', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'निधास्यत्', 'label': 'niDAsyat', 'attrib': [{'pos': 'fap', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'निधास्यमान', 'label': 'niDAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'निधीयमान', 'label': 'niDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'निधेय', 'label': 'niDeya', 'attrib': [{'pos': 'ger', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'निनीवस्', 'label': 'ninIvas', 'attrib': [{'pos': 'pfap', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'निन्यान', 'label': 'ninyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'निर्गच्छत्', 'label': 'nirgacCat', 'attrib': [{'pos': 'prap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्गत', 'label': 'nirgata', 'attrib': [{'pos': 'ppp', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्गतवत्', 'label': 'nirgatavat', 'attrib': [{'pos': 'pap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्गत्य', 'label': 'nirgatya', 'attrib': [{'pos': 'abs', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्गन्तव्य', 'label': 'nirgantavya', 'attrib': [{'pos': 'ger', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्गन्तुम्', 'label': 'nirgantum', 'attrib': [{'pos': 'inf', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्गमनीय', 'label': 'nirgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्गमिष्यत्', 'label': 'nirgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्गमिष्यमाण', 'label': 'nirgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्गम्य', 'label': 'nirgamya', 'attrib': [{'pos': 'abs', 'defs': 'go out, emerge'}, {'pos': 'ger', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्गम्यमान', 'label': 'nirgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्जगन्वस्', 'label': 'nirjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्जग्मान', 'label': 'nirjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निर्जग्मिवस्', 'label': 'nirjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go out, emerge'}], 'isLemma': 0, 'parents': ['nis_gam']},
	{'value': 'निश्चक्रमाण', 'label': 'niScakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निश्चक्रम्वस्', 'label': 'niScakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निष्क्रंस्यत्', 'label': 'nizkraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निष्क्रंस्यमान', 'label': 'nizkraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निष्क्रमणीय', 'label': 'nizkramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निष्क्रमितव्य', 'label': 'nizkramitavya', 'attrib': [{'pos': 'ger', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निष्क्रमितुम्', 'label': 'nizkramitum', 'attrib': [{'pos': 'inf', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निष्क्रम्य', 'label': 'nizkramya', 'attrib': [{'pos': 'abs', 'defs': 'go out, depart'}, {'pos': 'ger', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निष्क्रम्यमाण', 'label': 'nizkramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निष्क्रान्त', 'label': 'nizkrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निष्क्रान्तवत्', 'label': 'nizkrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निष्क्रामत्', 'label': 'nizkrAmat', 'attrib': [{'pos': 'prap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निष्क्राम्यत्', 'label': 'nizkrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'go out, depart'}], 'isLemma': 0, 'parents': ['nis_kram']},
	{'value': 'निस्', 'label': 'nis', 'attrib': [{'pos': 'preverb', 'defs': 'out'}], 'isLemma': 1},
	{'value': 'निस्_क्रम्', 'label': 'nis_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'go out, depart'}], 'isLemma': 1},
	{'value': 'निस्_गम्', 'label': 'nis_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go out, emerge'}], 'isLemma': 1},
	{'value': 'निहित', 'label': 'nihita', 'attrib': [{'pos': 'ppp', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'निहितवत्', 'label': 'nihitavat', 'attrib': [{'pos': 'pap', 'defs': 'put down, deposit'}], 'isLemma': 0, 'parents': ['ni_DA']},
	{'value': 'नी', 'label': 'nI', 'attrib': [{'pos': 'vt1am', 'defs': 'lead'}], 'isLemma': 1},
	{'value': 'नीत', 'label': 'nIta', 'attrib': [{'pos': 'ppp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'नीतवत्', 'label': 'nItavat', 'attrib': [{'pos': 'pap', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'नीत्वा', 'label': 'nItvA', 'attrib': [{'pos': 'abs', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'नीयमान', 'label': 'nIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'नृप', 'label': 'nfpa', 'attrib': [{'pos': 'm', 'defs': 'king'}], 'isLemma': 1},
	{'value': 'नेतव्य', 'label': 'netavya', 'attrib': [{'pos': 'ger', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'नेतुम्', 'label': 'netum', 'attrib': [{'pos': 'inf', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'नेय', 'label': 'neya', 'attrib': [{'pos': 'ger', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'नेष्यत्', 'label': 'nezyat', 'attrib': [{'pos': 'fap', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'नेष्यमाण', 'label': 'nezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'lead'}], 'isLemma': 0, 'parents': ['nI']},
	{'value': 'पक्तव्य', 'label': 'paktavya', 'attrib': [{'pos': 'ger', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पक्तुम्', 'label': 'paktum', 'attrib': [{'pos': 'inf', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पक्त्वा', 'label': 'paktvA', 'attrib': [{'pos': 'abs', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पक्व', 'label': 'pakva', 'attrib': [{'pos': 'ppp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पक्ववत्', 'label': 'pakvavat', 'attrib': [{'pos': 'pap', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पक्ष', 'label': 'pakza', 'attrib': [{'pos': 'm', 'defs': 'wing, side'}], 'isLemma': 1},
	{'value': 'पक्ष्यत्', 'label': 'pakzyat', 'attrib': [{'pos': 'fap', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पक्ष्यमाण', 'label': 'pakzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पच्', 'label': 'pac', 'attrib': [{'pos': 'vt1am', 'defs': 'cook'}], 'isLemma': 1},
	{'value': 'पचत्', 'label': 'pacat', 'attrib': [{'pos': 'prap', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पचनीय', 'label': 'pacanIya', 'attrib': [{'pos': 'ger', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पचमान', 'label': 'pacamAna', 'attrib': [{'pos': 'prmp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पच्यमान', 'label': 'pacyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पञ्चन्', 'label': 'paYcan', 'attrib': [{'pos': 'card_num', 'defs': 'five'}], 'isLemma': 1},
	{'value': 'पञ्चम', 'label': 'paYcama', 'attrib': [{'pos': 'ord_num', 'defs': 'fifth'}], 'isLemma': 1},
	{'value': 'पठ्', 'label': 'paW', 'attrib': [{'pos': 'vt1a', 'defs': 'read aloud, recite'}], 'isLemma': 1},
	{'value': 'पठत्', 'label': 'paWat', 'attrib': [{'pos': 'prap', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पठनीय', 'label': 'paWanIya', 'attrib': [{'pos': 'ger', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पठित', 'label': 'paWita', 'attrib': [{'pos': 'ppp', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पठितवत्', 'label': 'paWitavat', 'attrib': [{'pos': 'pap', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पठितव्य', 'label': 'paWitavya', 'attrib': [{'pos': 'ger', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पठितुम्', 'label': 'paWitum', 'attrib': [{'pos': 'inf', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पठित्वा', 'label': 'paWitvA', 'attrib': [{'pos': 'abs', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पठिष्यत्', 'label': 'paWizyat', 'attrib': [{'pos': 'fap', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पठिष्यमाण', 'label': 'paWizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पठ्यमान', 'label': 'paWyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पत्', 'label': 'pat', 'attrib': [{'pos': 'vt1a', 'defs': 'fall, fly'}], 'isLemma': 1},
	{'value': 'पतत्', 'label': 'patat', 'attrib': [{'pos': 'prap', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पतनीय', 'label': 'patanIya', 'attrib': [{'pos': 'ger', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पति', 'label': 'pati', 'attrib': [{'pos': 'm', 'defs': 'lord, husband'}], 'isLemma': 1},
	{'value': 'पतित', 'label': 'patita', 'attrib': [{'pos': 'ppp', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पतितवत्', 'label': 'patitavat', 'attrib': [{'pos': 'pap', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पतितव्य', 'label': 'patitavya', 'attrib': [{'pos': 'ger', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पतितुम्', 'label': 'patitum', 'attrib': [{'pos': 'inf', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पतित्वा', 'label': 'patitvA', 'attrib': [{'pos': 'abs', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पतिष्यत्', 'label': 'patizyat', 'attrib': [{'pos': 'fap', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पतिष्यमाण', 'label': 'patizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पत्तव्य', 'label': 'pattavya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पत्तुम्', 'label': 'pattum', 'attrib': [{'pos': 'inf', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पत्त्वा', 'label': 'pattvA', 'attrib': [{'pos': 'abs', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पत्नी', 'label': 'patnI', 'attrib': [{'pos': 'f', 'defs': 'wife'}], 'isLemma': 1},
	{'value': 'पत्यमान', 'label': 'patyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पत्स्यमान', 'label': 'patsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पद्', 'label': 'pad', 'attrib': [{'pos': 'vt4m', 'defs': 'step'}], 'isLemma': 1},
	{'value': 'पद', 'label': 'pada', 'attrib': [{'pos': 'n', 'defs': 'step'}], 'isLemma': 1},
	{'value': 'पदनीय', 'label': 'padanIya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पद्यमान', 'label': 'padyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'step'}, {'pos': 'prpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पन्न', 'label': 'panna', 'attrib': [{'pos': 'ppp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पन्नवत्', 'label': 'pannavat', 'attrib': [{'pos': 'pap', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पपराण', 'label': 'paparARa', 'attrib': [{'pos': 'pfpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पपर्वस्', 'label': 'paparvas', 'attrib': [{'pos': 'pfap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पपान', 'label': 'papAna', 'attrib': [{'pos': 'pfpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पपिवस्', 'label': 'papivas', 'attrib': [{'pos': 'pfap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पपॄवस्', 'label': 'papFvas', 'attrib': [{'pos': 'pfap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पप्रच्छान', 'label': 'papracCAna', 'attrib': [{'pos': 'pfpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पप्रच्छ्वस्', 'label': 'papracCvas', 'attrib': [{'pos': 'pfap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पप्राण', 'label': 'paprARa', 'attrib': [{'pos': 'pfpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पर', 'label': 'para', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'farther'}], 'isLemma': 1},
	{'value': 'परम्', 'label': 'param', 'attrib': [{'pos': 'adv', 'defs': 'after, beyond'}], 'isLemma': 1},
	{'value': 'परि', 'label': 'pari', 'attrib': [{'pos': 'preverb', 'defs': 'around'}], 'isLemma': 1},
	{'value': 'परिचरणीय', 'label': 'paricaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परिचरत्', 'label': 'paricarat', 'attrib': [{'pos': 'prap', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परिचरमाण', 'label': 'paricaramARa', 'attrib': [{'pos': 'prmp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परिचरित', 'label': 'paricarita', 'attrib': [{'pos': 'ppp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परिचरितवत्', 'label': 'paricaritavat', 'attrib': [{'pos': 'pap', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परिचरितव्य', 'label': 'paricaritavya', 'attrib': [{'pos': 'ger', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परिचरितुम्', 'label': 'paricaritum', 'attrib': [{'pos': 'inf', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परिचरिष्यत्', 'label': 'paricarizyat', 'attrib': [{'pos': 'fap', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परिचरिष्यमाण', 'label': 'paricarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परिचर्य', 'label': 'paricarya', 'attrib': [{'pos': 'abs', 'defs': 'move around'}, {'pos': 'ger', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परिचर्यमाण', 'label': 'paricaryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परिचेराण', 'label': 'paricerARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परिचेरिवस्', 'label': 'paricerivas', 'attrib': [{'pos': 'pfap', 'defs': 'move around'}], 'isLemma': 0, 'parents': ['pari_car']},
	{'value': 'परि_चर्', 'label': 'pari_car', 'attrib': [{'pos': 'vt1am', 'defs': 'move around'}], 'isLemma': 1},
	{'value': 'परि_धा', 'label': 'pari_DA', 'attrib': [{'pos': 'vt3am', 'defs': 'wear, put on (clothes)'}], 'isLemma': 1},
	{'value': 'परिदधत्', 'label': 'paridaDat', 'attrib': [{'pos': 'prap', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परिदधान', 'label': 'paridaDAna', 'attrib': [{'pos': 'prmp', 'defs': 'wear, put on (clothes)'}, {'pos': 'pfmp, pfpp', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परिदधिवस्', 'label': 'paridaDivas', 'attrib': [{'pos': 'pfap', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परिधातव्य', 'label': 'pariDAtavya', 'attrib': [{'pos': 'ger', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परिधातुम्', 'label': 'pariDAtum', 'attrib': [{'pos': 'inf', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परिधानीय', 'label': 'pariDAnIya', 'attrib': [{'pos': 'ger', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परिधाय', 'label': 'pariDAya', 'attrib': [{'pos': 'abs', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परिधास्यत्', 'label': 'pariDAsyat', 'attrib': [{'pos': 'fap', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परिधास्यमान', 'label': 'pariDAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परिधीयमान', 'label': 'pariDIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परिधेय', 'label': 'pariDeya', 'attrib': [{'pos': 'ger', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परिष्यत्', 'label': 'parizyat', 'attrib': [{'pos': 'fap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'परिष्यमाण', 'label': 'parizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'परिहित', 'label': 'parihita', 'attrib': [{'pos': 'ppp', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परिहितवत्', 'label': 'parihitavat', 'attrib': [{'pos': 'pap', 'defs': 'wear, put on (clothes)'}], 'isLemma': 0, 'parents': ['pari_DA']},
	{'value': 'परीष्यत्', 'label': 'parIzyat', 'attrib': [{'pos': 'fap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'परीष्यमाण', 'label': 'parIzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पर्वत', 'label': 'parvata', 'attrib': [{'pos': 'm', 'defs': 'mountain'}], 'isLemma': 1},
	{'value': 'पवनीय', 'label': 'pavanIya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पवित', 'label': 'pavita', 'attrib': [{'pos': 'ppp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पवितवत्', 'label': 'pavitavat', 'attrib': [{'pos': 'pap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पवितव्य', 'label': 'pavitavya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पवितुम्', 'label': 'pavitum', 'attrib': [{'pos': 'inf', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पवित्वा', 'label': 'pavitvA', 'attrib': [{'pos': 'abs', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पविष्यत्', 'label': 'pavizyat', 'attrib': [{'pos': 'fap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पविष्यमाण', 'label': 'pavizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पव्य', 'label': 'pavya', 'attrib': [{'pos': 'ger', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पशु', 'label': 'paSu', 'attrib': [{'pos': 'm', 'defs': 'domestic animal'}], 'isLemma': 1},
	{'value': 'पश्चात्', 'label': 'paScAt', 'attrib': [{'pos': 'adv', 'defs': 'afterwards, later'}], 'isLemma': 1},
	{'value': 'पश्यत्', 'label': 'paSyat', 'attrib': [{'pos': 'prap', 'defs': 'see, watch'}], 'isLemma': 0, 'parents': ['dfS']},
	{'value': 'पस्पृशान', 'label': 'paspfSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'पस्पृश्वस्', 'label': 'paspfSvas', 'attrib': [{'pos': 'pfap', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'पा', 'label': 'pA', 'attrib': [{'pos': 'vt1a', 'defs': 'drink'}], 'isLemma': 1},
	{'value': 'पाक्य', 'label': 'pAkya', 'attrib': [{'pos': 'ger', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पाटलिपुत्र', 'label': 'pAwaliputra', 'attrib': [{'pos': 'n_pn', 'defs': 'Pāṭaliputra'}], 'isLemma': 1},
	{'value': 'पाठ्य', 'label': 'pAWya', 'attrib': [{'pos': 'ger', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पाण्डव', 'label': 'pARqava', 'attrib': [{'pos': 'm', 'defs': 'descendant of Pāṇḍu'}], 'isLemma': 1},
	{'value': 'पातव्य', 'label': 'pAtavya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पातुम्', 'label': 'pAtum', 'attrib': [{'pos': 'inf', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पात्य', 'label': 'pAtya', 'attrib': [{'pos': 'ger', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पाद', 'label': 'pAda', 'attrib': [{'pos': 'm', 'defs': 'foot'}], 'isLemma': 1},
	{'value': 'पाद्य', 'label': 'pAdya', 'attrib': [{'pos': 'ger', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पानीय', 'label': 'pAnIya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पाप', 'label': 'pApa', 'attrib': [{'pos': 'adj', 'defs': 'bad, evil'}, {'pos': 'n', 'defs': 'evil, sin'}], 'isLemma': 1},
	{'value': 'पार्वती', 'label': 'pArvatI', 'attrib': [{'pos': 'f_pn', 'defs': 'Pārvatī'}], 'isLemma': 1},
	{'value': 'पाल्', 'label': 'pAl', 'attrib': [{'pos': 'vt10am', 'defs': 'protect'}], 'isLemma': 1},
	{'value': 'पालनीय', 'label': 'pAlanIya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पालयत्', 'label': 'pAlayat', 'attrib': [{'pos': 'prap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पालयमान', 'label': 'pAlayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पालयाम्', 'label': 'pAlayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पालयितुम्', 'label': 'pAlayitum', 'attrib': [{'pos': 'inf', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पालयित्वा', 'label': 'pAlayitvA', 'attrib': [{'pos': 'abs', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पालयिष्यत्', 'label': 'pAlayizyat', 'attrib': [{'pos': 'fap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पालयिष्यमाण', 'label': 'pAlayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पालित', 'label': 'pAlita', 'attrib': [{'pos': 'ppp', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पालितवत्', 'label': 'pAlitavat', 'attrib': [{'pos': 'pap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पालितव्य', 'label': 'pAlitavya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पाल्य', 'label': 'pAlya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पाल्यमान', 'label': 'pAlyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['pAl']},
	{'value': 'पास्यत्', 'label': 'pAsyat', 'attrib': [{'pos': 'fap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पास्यमान', 'label': 'pAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पितामह', 'label': 'pitAmaha', 'attrib': [{'pos': 'm', 'defs': 'grandfather'}], 'isLemma': 1},
	{'value': 'पितृ', 'label': 'pitf', 'attrib': [{'pos': 'm', 'defs': 'father'}], 'isLemma': 1},
	{'value': 'पिबत्', 'label': 'pibat', 'attrib': [{'pos': 'prap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पीत', 'label': 'pIta', 'attrib': [{'pos': 'ppp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पीतवत्', 'label': 'pItavat', 'attrib': [{'pos': 'pap', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पीत्वा', 'label': 'pItvA', 'attrib': [{'pos': 'abs', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पीयमान', 'label': 'pIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'पुत्र', 'label': 'putra', 'attrib': [{'pos': 'm', 'defs': 'son, child'}], 'isLemma': 1},
	{'value': 'पुत्री', 'label': 'putrI', 'attrib': [{'pos': 'f', 'defs': 'daughter'}], 'isLemma': 1},
	{'value': 'पुनत्', 'label': 'punat', 'attrib': [{'pos': 'prap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पुनर्', 'label': 'punar', 'attrib': [{'pos': 'adv', 'defs': 'again'}], 'isLemma': 1},
	{'value': 'पुनान', 'label': 'punAna', 'attrib': [{'pos': 'prmp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पुपुवान', 'label': 'pupuvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पुपूवस्', 'label': 'pupUvas', 'attrib': [{'pos': 'pfap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पुप्लुवान', 'label': 'pupluvAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'पुरा', 'label': 'purA', 'attrib': [{'pos': 'adv', 'defs': 'previously, long ago'}], 'isLemma': 1},
	{'value': 'पुरुष', 'label': 'puruza', 'attrib': [{'pos': 'm', 'defs': 'man, person, soul'}], 'isLemma': 1},
	{'value': 'पुरूरवस्', 'label': 'purUravas', 'attrib': [{'pos': 'm_pn', 'defs': 'Purūravas'}], 'isLemma': 1},
	{'value': 'पुष्प', 'label': 'puzpa', 'attrib': [{'pos': 'n', 'defs': 'flower'}], 'isLemma': 1},
	{'value': 'पू', 'label': 'pU', 'attrib': [{'pos': 'vt9am', 'defs': 'purify'}], 'isLemma': 1},
	{'value': 'पूज्', 'label': 'pUj', 'attrib': [{'pos': 'vt10m', 'defs': 'honor, perform a pūjā'}], 'isLemma': 1},
	{'value': 'पूजनीय', 'label': 'pUjanIya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजयमान', 'label': 'pUjayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजयाम्', 'label': 'pUjayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजयितुम्', 'label': 'pUjayitum', 'attrib': [{'pos': 'inf', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजयित्वा', 'label': 'pUjayitvA', 'attrib': [{'pos': 'abs', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजयिष्यमाण', 'label': 'pUjayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजा', 'label': 'pUjA', 'attrib': [{'pos': 'f', 'defs': 'honor, pūjā'}], 'isLemma': 1},
	{'value': 'पूजित', 'label': 'pUjita', 'attrib': [{'pos': 'ppp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजितवत्', 'label': 'pUjitavat', 'attrib': [{'pos': 'pap', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूजितव्य', 'label': 'pUjitavya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूज्य', 'label': 'pUjya', 'attrib': [{'pos': 'ger', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूज्यमान', 'label': 'pUjyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'honor, perform a pūjā'}], 'isLemma': 0, 'parents': ['pUj']},
	{'value': 'पूत', 'label': 'pUta', 'attrib': [{'pos': 'ppp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पूतवत्', 'label': 'pUtavat', 'attrib': [{'pos': 'pap', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पूत्वा', 'label': 'pUtvA', 'attrib': [{'pos': 'abs', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पूयमान', 'label': 'pUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'purify'}], 'isLemma': 0, 'parents': ['pU']},
	{'value': 'पूरणीय', 'label': 'pUraRIya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूरितव्य', 'label': 'pUritavya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूरितुम्', 'label': 'pUritum', 'attrib': [{'pos': 'inf', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूर्ण', 'label': 'pUrRa', 'attrib': [{'pos': 'adj', 'defs': 'filled, full'}, {'pos': 'ppp', 'defs': 'fill'}], 'isLemma': 1},
	{'value': 'पूर्ण', 'label': 'pUrRa', 'attrib': [{'pos': 'adj', 'defs': 'filled, full'}, {'pos': 'ppp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूर्त', 'label': 'pUrta', 'attrib': [{'pos': 'ppp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूर्तवत्', 'label': 'pUrtavat', 'attrib': [{'pos': 'pap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूर्त्वा', 'label': 'pUrtvA', 'attrib': [{'pos': 'abs', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूर्य', 'label': 'pUrya', 'attrib': [{'pos': 'ger', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूर्यमाण', 'label': 'pUryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पूर्व', 'label': 'pUrva', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'previous'}], 'isLemma': 1},
	{'value': 'पूर्वम्', 'label': 'pUrvam', 'attrib': [{'pos': 'adv', 'defs': 'before, previously'}], 'isLemma': 1},
	{'value': 'पृच्छत्', 'label': 'pfcCat', 'attrib': [{'pos': 'prap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पृच्छ्य', 'label': 'pfcCya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पृच्छ्यमान', 'label': 'pfcCyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पृणत्', 'label': 'pfRat', 'attrib': [{'pos': 'prap', 'defs': 'fill'}], 'isLemma': 0, 'parents': ['pF']},
	{'value': 'पृथिवी', 'label': 'pfTivI', 'attrib': [{'pos': 'f', 'defs': 'earth, ground'}], 'isLemma': 1},
	{'value': 'पृथु', 'label': 'pfTu', 'attrib': [{'pos': 'adj', 'defs': 'broad'}], 'isLemma': 1},
	{'value': 'पृष्ट', 'label': 'pfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पृष्टवत्', 'label': 'pfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पृष्ट्वा', 'label': 'pfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'पॄ', 'label': 'pF', 'attrib': [{'pos': 'vt9a', 'defs': 'fill'}], 'isLemma': 1},
	{'value': 'पेचान', 'label': 'pecAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पेचिवस्', 'label': 'pecivas', 'attrib': [{'pos': 'pfap', 'defs': 'cook'}], 'isLemma': 0, 'parents': ['pac']},
	{'value': 'पेठान', 'label': 'peWAna', 'attrib': [{'pos': 'pfpp', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पेठिवस्', 'label': 'peWivas', 'attrib': [{'pos': 'pfap', 'defs': 'read aloud, recite'}], 'isLemma': 0, 'parents': ['paW']},
	{'value': 'पेतान', 'label': 'petAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पेतिवस्', 'label': 'petivas', 'attrib': [{'pos': 'pfap', 'defs': 'fall, fly'}], 'isLemma': 0, 'parents': ['pat']},
	{'value': 'पेदान', 'label': 'pedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'step'}], 'isLemma': 0, 'parents': ['pad']},
	{'value': 'पेय', 'label': 'peya', 'attrib': [{'pos': 'ger', 'defs': 'drink'}], 'isLemma': 0, 'parents': ['pA']},
	{'value': 'प्र', 'label': 'pra', 'attrib': [{'pos': 'preverb', 'defs': 'forth'}], 'isLemma': 1},
	{'value': 'प्रक्रंस्यत्', 'label': 'prakraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्रंस्यमान', 'label': 'prakraMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्रमणीय', 'label': 'prakramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्रममाण', 'label': 'prakramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्रमितव्य', 'label': 'prakramitavya', 'attrib': [{'pos': 'ger', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्रमितुम्', 'label': 'prakramitum', 'attrib': [{'pos': 'inf', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्रम्य', 'label': 'prakramya', 'attrib': [{'pos': 'abs', 'defs': 'set out, proceed, go forth (active); begin (middle)'}, {'pos': 'ger', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्रम्यमाण', 'label': 'prakramyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}, {'pos': 'prpp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्रान्त', 'label': 'prakrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्रान्तवत्', 'label': 'prakrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्रान्तुम्', 'label': 'prakrAntum', 'attrib': [{'pos': 'inf', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्रामत्', 'label': 'prakrAmat', 'attrib': [{'pos': 'prap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्राम्यत्', 'label': 'prakrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रक्ष्यत्', 'label': 'prakzyat', 'attrib': [{'pos': 'fap', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'प्रक्ष्यमाण', 'label': 'prakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'प्रगच्छत्', 'label': 'pragacCat', 'attrib': [{'pos': 'prap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रगत', 'label': 'pragata', 'attrib': [{'pos': 'ppp', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रगतवत्', 'label': 'pragatavat', 'attrib': [{'pos': 'pap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रगत्य', 'label': 'pragatya', 'attrib': [{'pos': 'abs', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रगन्तव्य', 'label': 'pragantavya', 'attrib': [{'pos': 'ger', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रगन्तुम्', 'label': 'pragantum', 'attrib': [{'pos': 'inf', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रगमनीय', 'label': 'pragamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रगमिष्यत्', 'label': 'pragamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रगमिष्यमाण', 'label': 'pragamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रगम्य', 'label': 'pragamya', 'attrib': [{'pos': 'abs', 'defs': 'go forth, set out'}, {'pos': 'ger', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रगम्यमान', 'label': 'pragamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रचक्रमाण', 'label': 'pracakramARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रचक्रम्वस्', 'label': 'pracakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 0, 'parents': ['pra_kram']},
	{'value': 'प्रच्छ्', 'label': 'pracC', 'attrib': [{'pos': 'vt6a', 'defs': 'ask'}], 'isLemma': 1},
	{'value': 'प्रच्छनीय', 'label': 'pracCanIya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'प्रजगन्वस्', 'label': 'prajaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रजग्मान', 'label': 'prajagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रजग्मिवस्', 'label': 'prajagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go forth, set out'}], 'isLemma': 0, 'parents': ['pra_gam']},
	{'value': 'प्रजहृवस्', 'label': 'prajahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रजह्राण', 'label': 'prajahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्र_अह्', 'label': 'pra_ah', 'attrib': [{'pos': 'vta', 'defs': 'tell, narrate'}], 'isLemma': 1},
	{'value': 'प्र_आप्', 'label': 'pra_Ap', 'attrib': [{'pos': 'vt5a', 'defs': 'attain, obtain'}], 'isLemma': 1},
	{'value': 'प्र_क्रम्', 'label': 'pra_kram', 'attrib': [{'pos': 'vt1am', 'defs': 'set out, proceed, go forth (active); begin (middle)'}], 'isLemma': 1},
	{'value': 'प्र_गम्', 'label': 'pra_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go forth, set out'}], 'isLemma': 1},
	{'value': 'प्र_दा', 'label': 'pra_dA', 'attrib': [{'pos': 'vt3am', 'defs': 'present, bestow'}], 'isLemma': 1},
	{'value': 'प्र_पत्', 'label': 'pra_pat', 'attrib': [{'pos': 'vt1a', 'defs': 'fall forth'}], 'isLemma': 1},
	{'value': 'प्र_ब्रू', 'label': 'pra_brU', 'attrib': [{'pos': 'vt2am', 'defs': 'say, speak to, proclaim'}], 'isLemma': 1},
	{'value': 'प्र_यम्', 'label': 'pra_yam', 'attrib': [{'pos': 'vt1a', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 1},
	{'value': 'प्र_विश्', 'label': 'pra_viS', 'attrib': [{'pos': 'vt6a', 'defs': 'enter'}], 'isLemma': 1},
	{'value': 'प्र_स्था', 'label': 'pra_sTA', 'attrib': [{'pos': 'vt1m', 'defs': 'set out'}], 'isLemma': 1},
	{'value': 'प्र_हृ', 'label': 'pra_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'throw, attack'}], 'isLemma': 1},
	{'value': 'प्रतस्थान', 'label': 'pratasTAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रति', 'label': 'prati', 'attrib': [{'pos': 'preverb', 'defs': 'against'}], 'isLemma': 1},
	{'value': 'प्रतिक्रंस्यत्', 'label': 'pratikraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिक्रंस्यमान', 'label': 'pratikraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिक्रमणीय', 'label': 'pratikramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिक्रमितव्य', 'label': 'pratikramitavya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिक्रमितुम्', 'label': 'pratikramitum', 'attrib': [{'pos': 'inf', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिक्रम्य', 'label': 'pratikramya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}, {'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिक्रम्यमाण', 'label': 'pratikramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिक्रान्त', 'label': 'pratikrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिक्रान्तवत्', 'label': 'pratikrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिक्रामत्', 'label': 'pratikrAmat', 'attrib': [{'pos': 'prap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिक्राम्यत्', 'label': 'pratikrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिगच्छत्', 'label': 'pratigacCat', 'attrib': [{'pos': 'prap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिगत', 'label': 'pratigata', 'attrib': [{'pos': 'ppp', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिगतवत्', 'label': 'pratigatavat', 'attrib': [{'pos': 'pap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिगत्य', 'label': 'pratigatya', 'attrib': [{'pos': 'abs', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिगन्तव्य', 'label': 'pratigantavya', 'attrib': [{'pos': 'ger', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिगन्तुम्', 'label': 'pratigantum', 'attrib': [{'pos': 'inf', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिगमनीय', 'label': 'pratigamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिगमिष्यत्', 'label': 'pratigamizyat', 'attrib': [{'pos': 'fap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिगमिष्यमाण', 'label': 'pratigamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिगम्य', 'label': 'pratigamya', 'attrib': [{'pos': 'abs', 'defs': 'go back, return'}, {'pos': 'ger', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिगम्यमान', 'label': 'pratigamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिचक्रमाण', 'label': 'praticakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिचक्रम्वस्', 'label': 'praticakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_kram']},
	{'value': 'प्रतिजगन्वस्', 'label': 'pratijaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिजग्मान', 'label': 'pratijagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिजग्मिवस्', 'label': 'pratijagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'go back, return'}], 'isLemma': 0, 'parents': ['prati_gam']},
	{'value': 'प्रतिजज्ञान', 'label': 'pratijajYAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'प्रतिजहृवस्', 'label': 'pratijahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिजह्राण', 'label': 'pratijahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिजानान', 'label': 'pratijAnAna', 'attrib': [{'pos': 'prmp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'प्रतिज्ञा', 'label': 'pratijYA', 'attrib': [{'pos': 'f', 'defs': 'promise'}], 'isLemma': 1},
	{'value': 'प्रतिज्ञात', 'label': 'pratijYAta', 'attrib': [{'pos': 'ppp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'प्रतिज्ञातवत्', 'label': 'pratijYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'प्रतिज्ञातव्य', 'label': 'pratijYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'प्रतिज्ञातुम्', 'label': 'pratijYAtum', 'attrib': [{'pos': 'inf', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'प्रतिज्ञानीय', 'label': 'pratijYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'प्रतिज्ञाय', 'label': 'pratijYAya', 'attrib': [{'pos': 'abs', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'प्रतिज्ञायमान', 'label': 'pratijYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'प्रतिज्ञास्यमान', 'label': 'pratijYAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'प्रतिज्ञेय', 'label': 'pratijYeya', 'attrib': [{'pos': 'ger', 'defs': 'promise'}], 'isLemma': 0, 'parents': ['prati_jYA']},
	{'value': 'प्रति_अभि_ज्ञा', 'label': 'prati_aBi_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'recognize'}], 'isLemma': 1},
	{'value': 'प्रति_अह्', 'label': 'prati_ah', 'attrib': [{'pos': 'vta', 'defs': 'answer'}], 'isLemma': 1},
	{'value': 'प्रति_आ_इ', 'label': 'prati_A_i', 'attrib': [{'pos': 'vt2a', 'defs': 'come back'}], 'isLemma': 1},
	{'value': 'प्रति_आ_गम्', 'label': 'prati_A_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'come back, return'}], 'isLemma': 1},
	{'value': 'प्रति_आ_नी', 'label': 'prati_A_nI', 'attrib': [{'pos': 'vt1am', 'defs': 'bring back'}], 'isLemma': 1},
	{'value': 'प्रति_आ_हृ', 'label': 'prati_A_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'bring back'}], 'isLemma': 1},
	{'value': 'प्रति_इ', 'label': 'prati_i', 'attrib': [{'pos': 'vt2a', 'defs': 'go back, recognize'}], 'isLemma': 1},
	{'value': 'प्रति_क्रम्', 'label': 'prati_kram', 'attrib': [{'pos': 'vt1a', 'defs': 'come back, return'}], 'isLemma': 1},
	{'value': 'प्रति_गम्', 'label': 'prati_gam', 'attrib': [{'pos': 'vt1a', 'defs': 'go back, return'}], 'isLemma': 1},
	{'value': 'प्रति_ज्ञा', 'label': 'prati_jYA', 'attrib': [{'pos': 'vt9m', 'defs': 'promise'}], 'isLemma': 1},
	{'value': 'प्रति_ब्रू', 'label': 'prati_brU', 'attrib': [{'pos': 'vt2am', 'defs': 'reply'}], 'isLemma': 1},
	{'value': 'प्रति_भाष्', 'label': 'prati_BAz', 'attrib': [{'pos': 'vt1m', 'defs': 'answer'}], 'isLemma': 1},
	{'value': 'प्रति_लभ्', 'label': 'prati_laB', 'attrib': [{'pos': 'vt1m', 'defs': 'get back, regain'}], 'isLemma': 1},
	{'value': 'प्रति_वच्', 'label': 'prati_vac', 'attrib': [{'pos': 'vt2a', 'defs': 'answer'}], 'isLemma': 1},
	{'value': 'प्रति_वद्', 'label': 'prati_vad', 'attrib': [{'pos': 'vt1a', 'defs': 'answer'}], 'isLemma': 1},
	{'value': 'प्रति_स्था', 'label': 'prati_sTA', 'attrib': [{'pos': 'vt1a', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 1},
	{'value': 'प्रति_हृ', 'label': 'prati_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'strike back or against, take back'}], 'isLemma': 1},
	{'value': 'प्रतितष्ठान', 'label': 'pratitazWAna', 'attrib': [{'pos': 'pfpp', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतितष्ठिवस्', 'label': 'pratitazWivas', 'attrib': [{'pos': 'pfap', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतितिष्ठत्', 'label': 'pratitizWat', 'attrib': [{'pos': 'prap', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतिबभाषाण', 'label': 'pratibaBAzARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'प्रतिब्रुवत्', 'label': 'pratibruvat', 'attrib': [{'pos': 'prap', 'defs': 'reply'}], 'isLemma': 0, 'parents': ['prati_brU']},
	{'value': 'प्रतिब्रुवाण', 'label': 'pratibruvARa', 'attrib': [{'pos': 'prmp', 'defs': 'reply'}], 'isLemma': 0, 'parents': ['prati_brU']},
	{'value': 'प्रतिभाषणीय', 'label': 'pratiBAzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'प्रतिभाषमाण', 'label': 'pratiBAzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'प्रतिभाषित', 'label': 'pratiBAzita', 'attrib': [{'pos': 'ppp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'प्रतिभाषितवत्', 'label': 'pratiBAzitavat', 'attrib': [{'pos': 'pap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'प्रतिभाषितव्य', 'label': 'pratiBAzitavya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'प्रतिभाषितुम्', 'label': 'pratiBAzitum', 'attrib': [{'pos': 'inf', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'प्रतिभाषिष्यमाण', 'label': 'pratiBAzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'प्रतिभाष्य', 'label': 'pratiBAzya', 'attrib': [{'pos': 'abs', 'defs': 'answer'}, {'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'प्रतिभाष्यमाण', 'label': 'pratiBAzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_BAz']},
	{'value': 'प्रतियत्', 'label': 'pratiyat', 'attrib': [{'pos': 'prap', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'प्रतिलप्स्यमान', 'label': 'pratilapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'प्रतिलब्ध', 'label': 'pratilabDa', 'attrib': [{'pos': 'ppp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'प्रतिलब्धवत्', 'label': 'pratilabDavat', 'attrib': [{'pos': 'pap', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'प्रतिलब्धव्य', 'label': 'pratilabDavya', 'attrib': [{'pos': 'ger', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'प्रतिलब्धुम्', 'label': 'pratilabDum', 'attrib': [{'pos': 'inf', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'प्रतिलभनीय', 'label': 'pratilaBanIya', 'attrib': [{'pos': 'ger', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'प्रतिलभमान', 'label': 'pratilaBamAna', 'attrib': [{'pos': 'prmp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'प्रतिलभ्य', 'label': 'pratilaBya', 'attrib': [{'pos': 'abs', 'defs': 'get back, regain'}, {'pos': 'ger', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'प्रतिलभ्यमान', 'label': 'pratilaByamAna', 'attrib': [{'pos': 'prpp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'प्रतिलेभान', 'label': 'pratileBAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'get back, regain'}], 'isLemma': 0, 'parents': ['prati_laB']},
	{'value': 'प्रतिवक्तव्य', 'label': 'prativaktavya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'प्रतिवक्तुम्', 'label': 'prativaktum', 'attrib': [{'pos': 'inf', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'प्रतिवक्ष्यत्', 'label': 'prativakzyat', 'attrib': [{'pos': 'fap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'प्रतिवक्ष्यमाण', 'label': 'prativakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'प्रतिवचनीय', 'label': 'prativacanIya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'प्रतिवदत्', 'label': 'prativadat', 'attrib': [{'pos': 'prap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रतिवदनीय', 'label': 'prativadanIya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रतिवदितव्य', 'label': 'prativaditavya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रतिवदितुम्', 'label': 'prativaditum', 'attrib': [{'pos': 'inf', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रतिवदिष्यत्', 'label': 'prativadizyat', 'attrib': [{'pos': 'fap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रतिवदिष्यमाण', 'label': 'prativadizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रतिवाक्य', 'label': 'prativAkya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'प्रतिवाद्य', 'label': 'prativAdya', 'attrib': [{'pos': 'ger', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रतिष्ठमान', 'label': 'pratizWamAna', 'attrib': [{'pos': 'prmp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रतिष्ठातव्य', 'label': 'pratizWAtavya', 'attrib': [{'pos': 'ger', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतिष्ठातुम्', 'label': 'pratizWAtum', 'attrib': [{'pos': 'inf', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतिष्ठानीय', 'label': 'pratizWAnIya', 'attrib': [{'pos': 'ger', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतिष्ठाय', 'label': 'pratizWAya', 'attrib': [{'pos': 'abs', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतिष्ठास्यत्', 'label': 'pratizWAsyat', 'attrib': [{'pos': 'fap', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतिष्ठास्यमान', 'label': 'pratizWAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतिष्ठित', 'label': 'pratizWita', 'attrib': [{'pos': 'ppp', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतिष्ठितवत्', 'label': 'pratizWitavat', 'attrib': [{'pos': 'pap', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतिष्ठीयमान', 'label': 'pratizWIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतिष्ठेय', 'label': 'pratizWeya', 'attrib': [{'pos': 'ger', 'defs': 'withstand (acc.); depend (on loc.)'}], 'isLemma': 0, 'parents': ['prati_sTA']},
	{'value': 'प्रतिहरणीय', 'label': 'pratiharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिहरत्', 'label': 'pratiharat', 'attrib': [{'pos': 'prap', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिहरमाण', 'label': 'pratiharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिहरिष्यत्', 'label': 'pratiharizyat', 'attrib': [{'pos': 'fap', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिहरिष्यमाण', 'label': 'pratiharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिहर्तव्य', 'label': 'pratihartavya', 'attrib': [{'pos': 'ger', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिहर्तुम्', 'label': 'pratihartum', 'attrib': [{'pos': 'inf', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिहार्य', 'label': 'pratihArya', 'attrib': [{'pos': 'ger', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिहृत', 'label': 'pratihfta', 'attrib': [{'pos': 'ppp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिहृतवत्', 'label': 'pratihftavat', 'attrib': [{'pos': 'pap', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिहृत्य', 'label': 'pratihftya', 'attrib': [{'pos': 'abs', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतिह्रियमाण', 'label': 'pratihriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'strike back or against, take back'}], 'isLemma': 0, 'parents': ['prati_hf']},
	{'value': 'प्रतीत', 'label': 'pratIta', 'attrib': [{'pos': 'ppp', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'प्रतीतवत्', 'label': 'pratItavat', 'attrib': [{'pos': 'pap', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'प्रतीतव्य', 'label': 'pratItavya', 'attrib': [{'pos': 'ger', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'प्रतीत्य', 'label': 'pratItya', 'attrib': [{'pos': 'abs', 'defs': 'go back, recognize'}, {'pos': 'ger', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'प्रतीयमान', 'label': 'pratIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'प्रतीयान', 'label': 'pratIyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'प्रतीयिवस्', 'label': 'pratIyivas', 'attrib': [{'pos': 'pfap', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'प्रत्त', 'label': 'pratta', 'attrib': [{'pos': 'ppp', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रत्यभिजज्ञान', 'label': 'pratyaBijajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्यभिजज्ञिवस्', 'label': 'pratyaBijajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्यभिजानत्', 'label': 'pratyaBijAnat', 'attrib': [{'pos': 'prap', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्यभिज्ञा', 'label': 'pratyaBijYA', 'attrib': [{'pos': 'f', 'defs': 'recognition'}], 'isLemma': 1},
	{'value': 'प्रत्यभिज्ञात', 'label': 'pratyaBijYAta', 'attrib': [{'pos': 'ppp', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्यभिज्ञातवत्', 'label': 'pratyaBijYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्यभिज्ञातव्य', 'label': 'pratyaBijYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्यभिज्ञातुम्', 'label': 'pratyaBijYAtum', 'attrib': [{'pos': 'inf', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्यभिज्ञानीय', 'label': 'pratyaBijYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्यभिज्ञाय', 'label': 'pratyaBijYAya', 'attrib': [{'pos': 'abs', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्यभिज्ञायमान', 'label': 'pratyaBijYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्यभिज्ञास्यत्', 'label': 'pratyaBijYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्यभिज्ञास्यमान', 'label': 'pratyaBijYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्यभिज्ञेय', 'label': 'pratyaBijYeya', 'attrib': [{'pos': 'ger', 'defs': 'recognize'}], 'isLemma': 0, 'parents': ['prati_aBi_jYA']},
	{'value': 'प्रत्ययनीय', 'label': 'pratyayanIya', 'attrib': [{'pos': 'ger', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'प्रत्यागच्छत्', 'label': 'pratyAgacCat', 'attrib': [{'pos': 'prap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागत', 'label': 'pratyAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागतवत्', 'label': 'pratyAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागत्य', 'label': 'pratyAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागन्तव्य', 'label': 'pratyAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागन्तुम्', 'label': 'pratyAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागमनीय', 'label': 'pratyAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागमिष्यत्', 'label': 'pratyAgamizyat', 'attrib': [{'pos': 'fap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागमिष्यमाण', 'label': 'pratyAgamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागम्य', 'label': 'pratyAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come back, return'}, {'pos': 'ger', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्यागम्यमान', 'label': 'pratyAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्याजगन्वस्', 'label': 'pratyAjaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्याजग्मान', 'label': 'pratyAjagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्याजग्मिवस्', 'label': 'pratyAjagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'come back, return'}], 'isLemma': 0, 'parents': ['prati_A_gam']},
	{'value': 'प्रत्याजहृवस्', 'label': 'pratyAjahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्याजह्राण', 'label': 'pratyAjahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्यानयत्', 'label': 'pratyAnayat', 'attrib': [{'pos': 'prap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानयनीय', 'label': 'pratyAnayanIya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानयमान', 'label': 'pratyAnayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानिनीवस्', 'label': 'pratyAninIvas', 'attrib': [{'pos': 'pfap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानिन्यान', 'label': 'pratyAninyAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानीत', 'label': 'pratyAnIta', 'attrib': [{'pos': 'ppp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानीतवत्', 'label': 'pratyAnItavat', 'attrib': [{'pos': 'pap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानीय', 'label': 'pratyAnIya', 'attrib': [{'pos': 'abs', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानीयमान', 'label': 'pratyAnIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानेतव्य', 'label': 'pratyAnetavya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानेतुम्', 'label': 'pratyAnetum', 'attrib': [{'pos': 'inf', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानेय', 'label': 'pratyAneya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानेष्यत्', 'label': 'pratyAnezyat', 'attrib': [{'pos': 'fap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यानेष्यमाण', 'label': 'pratyAnezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_nI']},
	{'value': 'प्रत्यायत्', 'label': 'pratyAyat', 'attrib': [{'pos': 'prap', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'प्रत्यायनीय', 'label': 'pratyAyanIya', 'attrib': [{'pos': 'ger', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'प्रत्याहरणीय', 'label': 'pratyAharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्याहरत्', 'label': 'pratyAharat', 'attrib': [{'pos': 'prap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्याहरमाण', 'label': 'pratyAharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्याहरिष्यत्', 'label': 'pratyAharizyat', 'attrib': [{'pos': 'fap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्याहरिष्यमाण', 'label': 'pratyAharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्याहर्तव्य', 'label': 'pratyAhartavya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्याहर्तुम्', 'label': 'pratyAhartum', 'attrib': [{'pos': 'inf', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्याहार्य', 'label': 'pratyAhArya', 'attrib': [{'pos': 'ger', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्याहृत', 'label': 'pratyAhfta', 'attrib': [{'pos': 'ppp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्याहृतवत्', 'label': 'pratyAhftavat', 'attrib': [{'pos': 'pap', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्याहृत्य', 'label': 'pratyAhftya', 'attrib': [{'pos': 'abs', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्याह्रियमाण', 'label': 'pratyAhriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bring back'}], 'isLemma': 0, 'parents': ['prati_A_hf']},
	{'value': 'प्रत्युक्त', 'label': 'pratyukta', 'attrib': [{'pos': 'ppp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'प्रत्युक्तवत्', 'label': 'pratyuktavat', 'attrib': [{'pos': 'pap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'प्रत्युच्य', 'label': 'pratyucya', 'attrib': [{'pos': 'abs', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'प्रत्युच्यमान', 'label': 'pratyucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'प्रत्युदित', 'label': 'pratyudita', 'attrib': [{'pos': 'ppp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रत्युदितवत्', 'label': 'pratyuditavat', 'attrib': [{'pos': 'pap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रत्युद्य', 'label': 'pratyudya', 'attrib': [{'pos': 'abs', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रत्युद्यमान', 'label': 'pratyudyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रत्यूचान', 'label': 'pratyUcAna', 'attrib': [{'pos': 'pfpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'प्रत्यूचिवस्', 'label': 'pratyUcivas', 'attrib': [{'pos': 'pfap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vac']},
	{'value': 'प्रत्यूदान', 'label': 'pratyUdAna', 'attrib': [{'pos': 'pfpp', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रत्यूदिवस्', 'label': 'pratyUdivas', 'attrib': [{'pos': 'pfap', 'defs': 'answer'}], 'isLemma': 0, 'parents': ['prati_vad']},
	{'value': 'प्रत्येत', 'label': 'pratyeta', 'attrib': [{'pos': 'ppp', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'प्रत्येतवत्', 'label': 'pratyetavat', 'attrib': [{'pos': 'pap', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'प्रत्येतुम्', 'label': 'pratyetum', 'attrib': [{'pos': 'inf', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'प्रत्येत्य', 'label': 'pratyetya', 'attrib': [{'pos': 'abs', 'defs': 'come back'}, {'pos': 'ger', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'प्रत्येयमान', 'label': 'pratyeyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'प्रत्येयान', 'label': 'pratyeyAna', 'attrib': [{'pos': 'pfpp', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'प्रत्येयिवस्', 'label': 'pratyeyivas', 'attrib': [{'pos': 'pfap', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'प्रत्येष्यत्', 'label': 'pratyezyat', 'attrib': [{'pos': 'fap', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'प्रत्येष्यमाण', 'label': 'pratyezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'go back, recognize'}], 'isLemma': 0, 'parents': ['prati_i']},
	{'value': 'प्रत्यैतव्य', 'label': 'pratyEtavya', 'attrib': [{'pos': 'ger', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'प्रत्यैतुम्', 'label': 'pratyEtum', 'attrib': [{'pos': 'inf', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'प्रत्यैष्यत्', 'label': 'pratyEzyat', 'attrib': [{'pos': 'fap', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'प्रत्यैष्यमाण', 'label': 'pratyEzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'come back'}], 'isLemma': 0, 'parents': ['prati_A_i']},
	{'value': 'प्रथम', 'label': 'praTama', 'attrib': [{'pos': 'ord_num', 'defs': 'first'}], 'isLemma': 1},
	{'value': 'प्रदत्तवत्', 'label': 'pradattavat', 'attrib': [{'pos': 'pap', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रददत्', 'label': 'pradadat', 'attrib': [{'pos': 'prap', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रददान', 'label': 'pradadAna', 'attrib': [{'pos': 'prmp', 'defs': 'present, bestow'}, {'pos': 'pfmp, pfpp', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रददिवस्', 'label': 'pradadivas', 'attrib': [{'pos': 'pfap', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रदातव्य', 'label': 'pradAtavya', 'attrib': [{'pos': 'ger', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रदातुम्', 'label': 'pradAtum', 'attrib': [{'pos': 'inf', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रदानीय', 'label': 'pradAnIya', 'attrib': [{'pos': 'ger', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रदाय', 'label': 'pradAya', 'attrib': [{'pos': 'abs', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रदास्यत्', 'label': 'pradAsyat', 'attrib': [{'pos': 'fap', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रदास्यमान', 'label': 'pradAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रदीयमान', 'label': 'pradIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रदेय', 'label': 'pradeya', 'attrib': [{'pos': 'ger', 'defs': 'present, bestow'}], 'isLemma': 0, 'parents': ['pra_dA']},
	{'value': 'प्रधान', 'label': 'praDAna', 'attrib': [{'pos': 'adj', 'defs': 'principal'}], 'isLemma': 1},
	{'value': 'प्रपतत्', 'label': 'prapatat', 'attrib': [{'pos': 'prap', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रपतनीय', 'label': 'prapatanIya', 'attrib': [{'pos': 'ger', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रपतित', 'label': 'prapatita', 'attrib': [{'pos': 'ppp', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रपतितवत्', 'label': 'prapatitavat', 'attrib': [{'pos': 'pap', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रपतितव्य', 'label': 'prapatitavya', 'attrib': [{'pos': 'ger', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रपतितुम्', 'label': 'prapatitum', 'attrib': [{'pos': 'inf', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रपतिष्यत्', 'label': 'prapatizyat', 'attrib': [{'pos': 'fap', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रपतिष्यमाण', 'label': 'prapatizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रपत्य', 'label': 'prapatya', 'attrib': [{'pos': 'abs', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रपत्यमान', 'label': 'prapatyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रपात्य', 'label': 'prapAtya', 'attrib': [{'pos': 'ger', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रपेतान', 'label': 'prapetAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रपेतिवस्', 'label': 'prapetivas', 'attrib': [{'pos': 'pfap', 'defs': 'fall forth'}], 'isLemma': 0, 'parents': ['pra_pat']},
	{'value': 'प्रब्रुवत्', 'label': 'prabruvat', 'attrib': [{'pos': 'prap', 'defs': 'say, speak to, proclaim'}], 'isLemma': 0, 'parents': ['pra_brU']},
	{'value': 'प्रब्रुवाण', 'label': 'prabruvARa', 'attrib': [{'pos': 'prmp', 'defs': 'say, speak to, proclaim'}], 'isLemma': 0, 'parents': ['pra_brU']},
	{'value': 'प्रमाद', 'label': 'pramAda', 'attrib': [{'pos': 'm', 'defs': 'carelessness, error'}], 'isLemma': 1},
	{'value': 'प्रयंस्यत्', 'label': 'prayaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रयंस्यमान', 'label': 'prayaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रयच्छत्', 'label': 'prayacCat', 'attrib': [{'pos': 'prap', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रयत', 'label': 'prayata', 'attrib': [{'pos': 'ppp', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रयतवत्', 'label': 'prayatavat', 'attrib': [{'pos': 'pap', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रयत्य', 'label': 'prayatya', 'attrib': [{'pos': 'abs', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रयन्तव्य', 'label': 'prayantavya', 'attrib': [{'pos': 'ger', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रयन्तुम्', 'label': 'prayantum', 'attrib': [{'pos': 'inf', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रयमणीय', 'label': 'prayamaRIya', 'attrib': [{'pos': 'ger', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रयम्य', 'label': 'prayamya', 'attrib': [{'pos': 'abs', 'defs': 'hold forth, give, offer (acc. to dat.)'}, {'pos': 'ger', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रयम्यमान', 'label': 'prayamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रयेमान', 'label': 'prayemAna', 'attrib': [{'pos': 'pfpp', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रयेमिवस्', 'label': 'prayemivas', 'attrib': [{'pos': 'pfap', 'defs': 'hold forth, give, offer (acc. to dat.)'}], 'isLemma': 0, 'parents': ['pra_yam']},
	{'value': 'प्रविविशान', 'label': 'praviviSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रविविशिवस्', 'label': 'praviviSivas', 'attrib': [{'pos': 'pfap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रविविश्वस्', 'label': 'praviviSvas', 'attrib': [{'pos': 'pfap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रविशत्', 'label': 'praviSat', 'attrib': [{'pos': 'prap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रविश्य', 'label': 'praviSya', 'attrib': [{'pos': 'abs', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रविश्यमान', 'label': 'praviSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रविष्ट', 'label': 'pravizwa', 'attrib': [{'pos': 'ppp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रविष्टवत्', 'label': 'pravizwavat', 'attrib': [{'pos': 'pap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रवेक्ष्यत्', 'label': 'pravekzyat', 'attrib': [{'pos': 'fap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रवेक्ष्यमाण', 'label': 'pravekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रवेशनीय', 'label': 'praveSanIya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रवेश्य', 'label': 'praveSya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रवेष्टव्य', 'label': 'pravezwavya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रवेष्टुम्', 'label': 'pravezwum', 'attrib': [{'pos': 'inf', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['pra_viS']},
	{'value': 'प्रष्टव्य', 'label': 'prazwavya', 'attrib': [{'pos': 'ger', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'प्रष्टुम्', 'label': 'prazwum', 'attrib': [{'pos': 'inf', 'defs': 'ask'}], 'isLemma': 0, 'parents': ['pracC']},
	{'value': 'प्रस्थ', 'label': 'prasTa', 'attrib': [{'pos': 'adj', 'defs': 'setting out or having set out to'}], 'isLemma': 1},
	{'value': 'प्रस्थातव्य', 'label': 'prasTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थातुम्', 'label': 'prasTAtum', 'attrib': [{'pos': 'inf', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थानीय', 'label': 'prasTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थाय', 'label': 'prasTAya', 'attrib': [{'pos': 'abs', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थास्यमान', 'label': 'prasTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थित', 'label': 'prasTita', 'attrib': [{'pos': 'ppp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थितवत्', 'label': 'prasTitavat', 'attrib': [{'pos': 'pap', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थीयमान', 'label': 'prasTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रस्थेय', 'label': 'prasTeya', 'attrib': [{'pos': 'ger', 'defs': 'set out'}], 'isLemma': 0, 'parents': ['pra_sTA']},
	{'value': 'प्रहरणीय', 'label': 'praharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रहरत्', 'label': 'praharat', 'attrib': [{'pos': 'prap', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रहरमाण', 'label': 'praharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रहरिष्यत्', 'label': 'praharizyat', 'attrib': [{'pos': 'fap', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रहरिष्यमाण', 'label': 'praharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रहर्तव्य', 'label': 'prahartavya', 'attrib': [{'pos': 'ger', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रहर्तुम्', 'label': 'prahartum', 'attrib': [{'pos': 'inf', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रहार्य', 'label': 'prahArya', 'attrib': [{'pos': 'ger', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रहृत', 'label': 'prahfta', 'attrib': [{'pos': 'ppp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रहृतवत्', 'label': 'prahftavat', 'attrib': [{'pos': 'pap', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रहृत्य', 'label': 'prahftya', 'attrib': [{'pos': 'abs', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रह्रियमाण', 'label': 'prahriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'throw, attack'}], 'isLemma': 0, 'parents': ['pra_hf']},
	{'value': 'प्रातर्', 'label': 'prAtar', 'attrib': [{'pos': 'adv', 'defs': 'at dawn, in the early morning'}], 'isLemma': 1},
	{'value': 'प्रापणीय', 'label': 'prApaRIya', 'attrib': [{'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्रापाण', 'label': 'prApARa', 'attrib': [{'pos': 'pfpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्रापिवस्', 'label': 'prApivas', 'attrib': [{'pos': 'pfap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्त', 'label': 'prApta', 'attrib': [{'pos': 'ppp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्तवत्', 'label': 'prAptavat', 'attrib': [{'pos': 'pap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्तव्य', 'label': 'prAptavya', 'attrib': [{'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्तुम्', 'label': 'prAptum', 'attrib': [{'pos': 'inf', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्नुवत्', 'label': 'prApnuvat', 'attrib': [{'pos': 'prap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्य', 'label': 'prApya', 'attrib': [{'pos': 'abs', 'defs': 'attain, obtain'}, {'pos': 'ger', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्यमान', 'label': 'prApyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्स्यत्', 'label': 'prApsyat', 'attrib': [{'pos': 'fap', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्राप्स्यमान', 'label': 'prApsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'attain, obtain'}], 'isLemma': 0, 'parents': ['pra_Ap']},
	{'value': 'प्रिय', 'label': 'priya', 'attrib': [{'pos': 'adj', 'defs': 'dear'}], 'isLemma': 1},
	{'value': 'प्लवनीय', 'label': 'plavanIya', 'attrib': [{'pos': 'ger', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'प्लवमान', 'label': 'plavamAna', 'attrib': [{'pos': 'prmp', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'प्लवितव्य', 'label': 'plavitavya', 'attrib': [{'pos': 'ger', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'प्लवितुम्', 'label': 'plavitum', 'attrib': [{'pos': 'inf', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'प्लव्य', 'label': 'plavya', 'attrib': [{'pos': 'ger', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'प्लु', 'label': 'plu', 'attrib': [{'pos': 'vt1m', 'defs': 'float, swim'}], 'isLemma': 1},
	{'value': 'प्लुत', 'label': 'pluta', 'attrib': [{'pos': 'ppp', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'प्लुतवत्', 'label': 'plutavat', 'attrib': [{'pos': 'pap', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'प्लुत्वा', 'label': 'plutvA', 'attrib': [{'pos': 'abs', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'प्लोष्यमाण', 'label': 'plozyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'float, swim'}], 'isLemma': 0, 'parents': ['plu']},
	{'value': 'फल', 'label': 'Pala', 'attrib': [{'pos': 'n', 'defs': 'fruit'}], 'isLemma': 1},
	{'value': 'फलवत्', 'label': 'Palavat', 'attrib': [{'pos': 'adj', 'defs': 'fruitful, successful'}], 'isLemma': 1},
	{'value': 'बद्ध', 'label': 'badDa', 'attrib': [{'pos': 'ppp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बद्धवत्', 'label': 'badDavat', 'attrib': [{'pos': 'pap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बद्ध्वा', 'label': 'badDvA', 'attrib': [{'pos': 'abs', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बध्नत्', 'label': 'baDnat', 'attrib': [{'pos': 'prap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बध्यमान', 'label': 'baDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बन्द्धव्य', 'label': 'bandDavya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बन्द्धुम्', 'label': 'bandDum', 'attrib': [{'pos': 'inf', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बन्ध्', 'label': 'banD', 'attrib': [{'pos': 'vt9am', 'defs': 'bind'}], 'isLemma': 1},
	{'value': 'बन्धनीय', 'label': 'banDanIya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बन्ध्य', 'label': 'banDya', 'attrib': [{'pos': 'ger', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बबन्धान', 'label': 'babanDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बबन्ध्वस्', 'label': 'babanDvas', 'attrib': [{'pos': 'pfap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'बभाषाण', 'label': 'baBAzARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'बभूवस्', 'label': 'baBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'बभृवस्', 'label': 'baBfvas', 'attrib': [{'pos': 'pfap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'बभ्राण', 'label': 'baBrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'बल', 'label': 'bala', 'attrib': [{'pos': 'n', 'defs': 'strength'}], 'isLemma': 1},
	{'value': 'बलवत्', 'label': 'balavat', 'attrib': [{'pos': 'adj', 'defs': 'strong'}], 'isLemma': 1},
	{'value': 'बलिन्', 'label': 'balin', 'attrib': [{'pos': 'adj', 'defs': 'strong'}], 'isLemma': 1},
	{'value': 'बहु', 'label': 'bahu', 'attrib': [{'pos': 'adj', 'defs': 'much, many, abundant'}, {'pos': 'adv', 'defs': 'much'}], 'isLemma': 1},
	{'value': 'बाल', 'label': 'bAla', 'attrib': [{'pos': 'm', 'defs': 'boy, child'}], 'isLemma': 1},
	{'value': 'बिभयाम्', 'label': 'biBayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'बिभराम्', 'label': 'biBarAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'बिभिदान', 'label': 'biBidAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'बिभिद्वस्', 'label': 'biBidvas', 'attrib': [{'pos': 'pfap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'बिभीवस्', 'label': 'biBIvas', 'attrib': [{'pos': 'pfap', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'बिभ्यत्', 'label': 'biByat', 'attrib': [{'pos': 'prap', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'बिभ्यान', 'label': 'biByAna', 'attrib': [{'pos': 'pfpp', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'बिभ्रत्', 'label': 'biBrat', 'attrib': [{'pos': 'prap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'बिभ्राण', 'label': 'biBrARa', 'attrib': [{'pos': 'prmp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'बुद्ध', 'label': 'budDa', 'attrib': [{'pos': 'ppp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुद्धवत्', 'label': 'budDavat', 'attrib': [{'pos': 'pap', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुद्धि', 'label': 'budDi', 'attrib': [{'pos': 'f', 'defs': 'intelligence, intellect'}], 'isLemma': 1},
	{'value': 'बुद्ध्वा', 'label': 'budDvA', 'attrib': [{'pos': 'abs', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुध्', 'label': 'buD', 'attrib': [{'pos': 'vt1am', 'defs': 'know'}, {'pos': 'vt4m', 'defs': 'be aware, know'}], 'isLemma': 1},
	{'value': 'बुधित', 'label': 'buDita', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुधितवत्', 'label': 'buDitavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुधित्वा', 'label': 'buDitvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुध्यमान', 'label': 'buDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}, {'pos': 'prmp', 'defs': 'be aware, know'}, {'pos': 'prpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुबुधान', 'label': 'bubuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'know'}, {'pos': 'pfmp, pfpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुबुध्वस्', 'label': 'bubuDvas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बुभुजान', 'label': 'buBujAna', 'attrib': [{'pos': 'pfpp', 'defs': 'protect'}, {'pos': 'pfmp, pfpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'बुभुज्वस्', 'label': 'buBujvas', 'attrib': [{'pos': 'pfap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'बोद्धव्य', 'label': 'bodDavya', 'attrib': [{'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोद्धुम्', 'label': 'bodDum', 'attrib': [{'pos': 'inf', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोधत्', 'label': 'boDat', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोधनीय', 'label': 'boDanIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोधमान', 'label': 'boDamAna', 'attrib': [{'pos': 'prmp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोधितव्य', 'label': 'boDitavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोधितुम्', 'label': 'boDitum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोधित्वा', 'label': 'boDitvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'बोध्य', 'label': 'boDya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'ब्राह्मण', 'label': 'brAhmaRa', 'attrib': [{'pos': 'm', 'defs': 'brahmin'}], 'isLemma': 1},
	{'value': 'ब्रुवत्', 'label': 'bruvat', 'attrib': [{'pos': 'prap', 'defs': 'say, tell'}], 'isLemma': 0, 'parents': ['brU']},
	{'value': 'ब्रुवाण', 'label': 'bruvARa', 'attrib': [{'pos': 'prmp', 'defs': 'say, tell'}], 'isLemma': 0, 'parents': ['brU']},
	{'value': 'ब्रू', 'label': 'brU', 'attrib': [{'pos': 'vt2am', 'defs': 'say, tell'}], 'isLemma': 1},
	{'value': 'भक्ष्', 'label': 'Bakz', 'attrib': [{'pos': 'vt10a', 'defs': 'eat'}], 'isLemma': 1},
	{'value': 'भक्षणीय', 'label': 'BakzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'भक्षयत्', 'label': 'Bakzayat', 'attrib': [{'pos': 'prap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'भक्षयाम्', 'label': 'BakzayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'भक्षयितुम्', 'label': 'Bakzayitum', 'attrib': [{'pos': 'inf', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'भक्षयित्वा', 'label': 'BakzayitvA', 'attrib': [{'pos': 'abs', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'भक्षयिष्यत्', 'label': 'Bakzayizyat', 'attrib': [{'pos': 'fap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'भक्षयिष्यमाण', 'label': 'BakzayizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'भक्षित', 'label': 'Bakzita', 'attrib': [{'pos': 'ppp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'भक्षितवत्', 'label': 'Bakzitavat', 'attrib': [{'pos': 'pap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'भक्षितव्य', 'label': 'Bakzitavya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'भक्ष्य', 'label': 'Bakzya', 'attrib': [{'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'भक्ष्यमाण', 'label': 'BakzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Bakz']},
	{'value': 'भन्त्स्यत्', 'label': 'Bantsyat', 'attrib': [{'pos': 'fap', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'भन्त्स्यमान', 'label': 'BantsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'bind'}], 'isLemma': 0, 'parents': ['banD']},
	{'value': 'भय', 'label': 'Baya', 'attrib': [{'pos': 'n', 'defs': 'fear'}], 'isLemma': 1},
	{'value': 'भयनीय', 'label': 'BayanIya', 'attrib': [{'pos': 'ger', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भरणीय', 'label': 'BaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भरत', 'label': 'Barata', 'attrib': [{'pos': 'm_pn', 'defs': 'Bharata or his descendants'}], 'isLemma': 1},
	{'value': 'भरिष्यत्', 'label': 'Barizyat', 'attrib': [{'pos': 'fap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भरिष्यमाण', 'label': 'BarizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भर्तव्य', 'label': 'Bartavya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भर्तुम्', 'label': 'Bartum', 'attrib': [{'pos': 'inf', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भवत्', 'label': 'Bavat', 'attrib': [{'pos': 'pers_pron', 'defs': 'your lord/ladyship, sir/madam, you'}, {'pos': 'prap', 'defs': 'be, become'}], 'isLemma': 1},
	{'value': 'भवत्', 'label': 'Bavat', 'attrib': [{'pos': 'pers_pron', 'defs': 'your lord/ladyship, sir/madam, you'}, {'pos': 'prap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भवनीय', 'label': 'BavanIya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भवितव्य', 'label': 'Bavitavya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भवितुम्', 'label': 'Bavitum', 'attrib': [{'pos': 'inf', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भविष्यत्', 'label': 'Bavizyat', 'attrib': [{'pos': 'fap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भविष्यमाण', 'label': 'BavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भव्य', 'label': 'Bavya', 'attrib': [{'pos': 'ger', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भाष्', 'label': 'BAz', 'attrib': [{'pos': 'vt1m', 'defs': 'speak, say'}], 'isLemma': 1},
	{'value': 'भाषणीय', 'label': 'BAzaRIya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषमाण', 'label': 'BAzamARa', 'attrib': [{'pos': 'prmp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषित', 'label': 'BAzita', 'attrib': [{'pos': 'ppp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषितवत्', 'label': 'BAzitavat', 'attrib': [{'pos': 'pap', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषितव्य', 'label': 'BAzitavya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषितुम्', 'label': 'BAzitum', 'attrib': [{'pos': 'inf', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषित्वा', 'label': 'BAzitvA', 'attrib': [{'pos': 'abs', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाषिष्यमाण', 'label': 'BAzizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाष्य', 'label': 'BAzya', 'attrib': [{'pos': 'ger', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भाष्यमाण', 'label': 'BAzyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'speak, say'}], 'isLemma': 0, 'parents': ['BAz']},
	{'value': 'भित्त्वा', 'label': 'BittvA', 'attrib': [{'pos': 'abs', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भिद्', 'label': 'Bid', 'attrib': [{'pos': 'vt7am', 'defs': 'split'}], 'isLemma': 1},
	{'value': 'भिद्यमान', 'label': 'BidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भिन्दत्', 'label': 'Bindat', 'attrib': [{'pos': 'prap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भिन्दान', 'label': 'BindAna', 'attrib': [{'pos': 'prmp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भिन्न', 'label': 'Binna', 'attrib': [{'pos': 'ppp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भिन्नवत्', 'label': 'Binnavat', 'attrib': [{'pos': 'pap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भी', 'label': 'BI', 'attrib': [{'pos': 'vi3a', 'defs': 'fear (from abl.)'}], 'isLemma': 1},
	{'value': 'भीत', 'label': 'BIta', 'attrib': [{'pos': 'ppp', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भीतवत्', 'label': 'BItavat', 'attrib': [{'pos': 'pap', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भीत्वा', 'label': 'BItvA', 'attrib': [{'pos': 'abs', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भीयमान', 'label': 'BIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भीरु', 'label': 'BIru', 'attrib': [{'pos': 'adj', 'defs': 'timid'}], 'isLemma': 1},
	{'value': 'भुक्त', 'label': 'Bukta', 'attrib': [{'pos': 'ppp', 'defs': 'protect'}, {'pos': 'ppp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'भुक्तवत्', 'label': 'Buktavat', 'attrib': [{'pos': 'pap', 'defs': 'protect'}, {'pos': 'pap', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'भुक्त्वा', 'label': 'BuktvA', 'attrib': [{'pos': 'abs', 'defs': 'protect'}, {'pos': 'abs', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'भुज्', 'label': 'Buj', 'attrib': [{'pos': 'vt7a', 'defs': 'protect'}, {'pos': 'vt7m', 'defs': 'eat'}], 'isLemma': 1},
	{'value': 'भुज्यमान', 'label': 'BujyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'protect'}, {'pos': 'prpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'भुञ्जत्', 'label': 'BuYjat', 'attrib': [{'pos': 'prap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'भुञ्जान', 'label': 'BuYjAna', 'attrib': [{'pos': 'prmp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'भू', 'label': 'BU', 'attrib': [{'pos': 'f', 'defs': 'earth'}, {'pos': 'vi1a', 'defs': 'be, become'}], 'isLemma': 1},
	{'value': 'भूत', 'label': 'BUta', 'attrib': [{'pos': 'ppp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भूतवत्', 'label': 'BUtavat', 'attrib': [{'pos': 'pap', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भूत्वा', 'label': 'BUtvA', 'attrib': [{'pos': 'abs', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भूयमान', 'label': 'BUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be, become'}], 'isLemma': 0, 'parents': ['BU']},
	{'value': 'भृ', 'label': 'Bf', 'attrib': [{'pos': 'vt3am', 'defs': 'bear'}], 'isLemma': 1},
	{'value': 'भृत', 'label': 'Bfta', 'attrib': [{'pos': 'ppp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भृतवत्', 'label': 'Bftavat', 'attrib': [{'pos': 'pap', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भृत्य', 'label': 'Bftya', 'attrib': [{'pos': 'ger', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भृत्वा', 'label': 'BftvA', 'attrib': [{'pos': 'abs', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भेतव्य', 'label': 'Betavya', 'attrib': [{'pos': 'ger', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भेतुम्', 'label': 'Betum', 'attrib': [{'pos': 'inf', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भेत्तव्य', 'label': 'Bettavya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भेत्तुम्', 'label': 'Bettum', 'attrib': [{'pos': 'inf', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भेत्स्यत्', 'label': 'Betsyat', 'attrib': [{'pos': 'fap', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भेत्स्यमान', 'label': 'BetsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भेदनीय', 'label': 'BedanIya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भेद्य', 'label': 'Bedya', 'attrib': [{'pos': 'ger', 'defs': 'split'}], 'isLemma': 0, 'parents': ['Bid']},
	{'value': 'भेय', 'label': 'Beya', 'attrib': [{'pos': 'ger', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भेष्यत्', 'label': 'Bezyat', 'attrib': [{'pos': 'fap', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भेष्यमाण', 'label': 'BezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'fear (from abl.)'}], 'isLemma': 0, 'parents': ['BI']},
	{'value': 'भोक्तव्य', 'label': 'Boktavya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}, {'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'भोक्तुम्', 'label': 'Boktum', 'attrib': [{'pos': 'inf', 'defs': 'protect'}, {'pos': 'inf', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'भोक्ष्यत्', 'label': 'Bokzyat', 'attrib': [{'pos': 'fap', 'defs': 'protect'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'भोक्ष्यमाण', 'label': 'BokzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'protect'}, {'pos': 'fmp, fpp', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'भोग्य', 'label': 'Bogya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}, {'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'भोजन', 'label': 'Bojana', 'attrib': [{'pos': 'n', 'defs': 'eating, a meal'}], 'isLemma': 1},
	{'value': 'भोजनीय', 'label': 'BojanIya', 'attrib': [{'pos': 'ger', 'defs': 'protect'}, {'pos': 'ger', 'defs': 'eat'}], 'isLemma': 0, 'parents': ['Buj']},
	{'value': 'भोत्स्यत्', 'label': 'Botsyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'भोत्स्यमान', 'label': 'BotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'know'}, {'pos': 'fmp, fpp', 'defs': 'be aware, know'}], 'isLemma': 0, 'parents': ['buD']},
	{'value': 'भ्रातृ', 'label': 'BrAtf', 'attrib': [{'pos': 'm', 'defs': 'brother'}], 'isLemma': 1},
	{'value': 'भ्रियमाण', 'label': 'BriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'bear'}], 'isLemma': 0, 'parents': ['Bf']},
	{'value': 'भ्रू', 'label': 'BrU', 'attrib': [{'pos': 'f', 'defs': 'eyebrow'}], 'isLemma': 1},
	{'value': 'मंस्यमान', 'label': 'maMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मत', 'label': 'mata', 'attrib': [{'pos': 'ppp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मतवत्', 'label': 'matavat', 'attrib': [{'pos': 'pap', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मति', 'label': 'mati', 'attrib': [{'pos': 'f', 'defs': 'thought'}], 'isLemma': 1},
	{'value': 'मत्त', 'label': 'matta', 'attrib': [{'pos': 'ppp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मत्तवत्', 'label': 'mattavat', 'attrib': [{'pos': 'pap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मत्वा', 'label': 'matvA', 'attrib': [{'pos': 'abs', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मद्', 'label': 'mad', 'attrib': [{'pos': 'vi4a', 'defs': 'rejoice'}], 'isLemma': 1},
	{'value': 'मदनीय', 'label': 'madanIya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मदितव्य', 'label': 'maditavya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मदितुम्', 'label': 'maditum', 'attrib': [{'pos': 'inf', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मदित्वा', 'label': 'maditvA', 'attrib': [{'pos': 'abs', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मदिष्यत्', 'label': 'madizyat', 'attrib': [{'pos': 'fap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मदिष्यमाण', 'label': 'madizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मद्य', 'label': 'madya', 'attrib': [{'pos': 'ger', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मद्यमान', 'label': 'madyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मधु', 'label': 'maDu', 'attrib': [{'pos': 'adj', 'defs': 'sweet'}, {'pos': 'n', 'defs': 'honey'}], 'isLemma': 1},
	{'value': 'मध्य', 'label': 'maDya', 'attrib': [{'pos': 'adj', 'defs': 'middle'}, {'pos': 'n', 'defs': 'middle, waist'}], 'isLemma': 1},
	{'value': 'मन्', 'label': 'man', 'attrib': [{'pos': 'vt4m, vt8m', 'defs': 'think'}], 'isLemma': 1},
	{'value': 'मननीय', 'label': 'mananIya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मनस्', 'label': 'manas', 'attrib': [{'pos': 'n', 'defs': 'mind'}], 'isLemma': 1},
	{'value': 'मनुष्य', 'label': 'manuzya', 'attrib': [{'pos': 'm', 'defs': 'human being'}], 'isLemma': 1},
	{'value': 'मन्तव्य', 'label': 'mantavya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मन्तुम्', 'label': 'mantum', 'attrib': [{'pos': 'inf', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मन्यमान', 'label': 'manyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'think'}, {'pos': 'prpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मन्वान', 'label': 'manvAna', 'attrib': [{'pos': 'prmp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'ममृवस्', 'label': 'mamfvas', 'attrib': [{'pos': 'pfap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मम्राण', 'label': 'mamrARa', 'attrib': [{'pos': 'pfpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मरणीय', 'label': 'maraRIya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मरिष्यत्', 'label': 'marizyat', 'attrib': [{'pos': 'fap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मरिष्यमाण', 'label': 'marizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मर्तव्य', 'label': 'martavya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मर्तुम्', 'label': 'martum', 'attrib': [{'pos': 'inf', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'महत्', 'label': 'mahat', 'attrib': [{'pos': 'adj', 'defs': 'great'}], 'isLemma': 1},
	{'value': 'महाभारत', 'label': 'mahABArata', 'attrib': [{'pos': 'n_pn', 'defs': 'the <i>Mahābhārata</i>'}], 'isLemma': 1},
	{'value': 'महायशस्', 'label': 'mahAyaSas', 'attrib': [{'pos': 'adj', 'defs': 'whose glory is great, very glorious'}], 'isLemma': 1},
	{'value': 'मा', 'label': 'mA', 'attrib': [{'pos': 'neg_pcl', 'defs': "don't"}], 'isLemma': 1},
	{'value': 'मातृ', 'label': 'mAtf', 'attrib': [{'pos': 'f', 'defs': 'mother'}], 'isLemma': 1},
	{'value': 'माद्यत्', 'label': 'mAdyat', 'attrib': [{'pos': 'prap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मान्य', 'label': 'mAnya', 'attrib': [{'pos': 'ger', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मार्य', 'label': 'mArya', 'attrib': [{'pos': 'ger', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'माला', 'label': 'mAlA', 'attrib': [{'pos': 'f', 'defs': 'garland'}], 'isLemma': 1},
	{'value': 'मास', 'label': 'mAsa', 'attrib': [{'pos': 'm', 'defs': 'moon, month'}], 'isLemma': 1},
	{'value': 'मुक्त', 'label': 'mukta', 'attrib': [{'pos': 'ppp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुक्तवत्', 'label': 'muktavat', 'attrib': [{'pos': 'pap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुक्त्वा', 'label': 'muktvA', 'attrib': [{'pos': 'abs', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुख', 'label': 'muKa', 'attrib': [{'pos': 'n', 'defs': 'mouth, face'}], 'isLemma': 1},
	{'value': 'मुच्', 'label': 'muc', 'attrib': [{'pos': 'vt6am', 'defs': 'release'}], 'isLemma': 1},
	{'value': 'मुच्यमान', 'label': 'mucyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुञ्चत्', 'label': 'muYcat', 'attrib': [{'pos': 'prap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुञ्चमान', 'label': 'muYcamAna', 'attrib': [{'pos': 'prmp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुमुचान', 'label': 'mumucAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुमुच्वस्', 'label': 'mumucvas', 'attrib': [{'pos': 'pfap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मुहूर्त', 'label': 'muhUrta', 'attrib': [{'pos': 'm, n', 'defs': '1/30th of a day, 48 minutes, hour'}], 'isLemma': 1},
	{'value': 'मृ', 'label': 'mf', 'attrib': [{'pos': 'vi6am', 'defs': 'die'}], 'isLemma': 1},
	{'value': 'मृग', 'label': 'mfga', 'attrib': [{'pos': 'm', 'defs': 'deer'}], 'isLemma': 1},
	{'value': 'मृत', 'label': 'mfta', 'attrib': [{'pos': 'ppp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मृतवत्', 'label': 'mftavat', 'attrib': [{'pos': 'pap', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मृत्वा', 'label': 'mftvA', 'attrib': [{'pos': 'abs', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'मृदु', 'label': 'mfdu', 'attrib': [{'pos': 'adj', 'defs': 'soft'}], 'isLemma': 1},
	{'value': 'मेदान', 'label': 'medAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मेदिवस्', 'label': 'medivas', 'attrib': [{'pos': 'pfap', 'defs': 'rejoice'}], 'isLemma': 0, 'parents': ['mad']},
	{'value': 'मेनान', 'label': 'menAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'think'}], 'isLemma': 0, 'parents': ['man']},
	{'value': 'मोक्तव्य', 'label': 'moktavya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मोक्तुम्', 'label': 'moktum', 'attrib': [{'pos': 'inf', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मोक्ष्यत्', 'label': 'mokzyat', 'attrib': [{'pos': 'fap', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मोक्ष्यमाण', 'label': 'mokzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मोचनीय', 'label': 'mocanIya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'मोच्य', 'label': 'mocya', 'attrib': [{'pos': 'ger', 'defs': 'release'}], 'isLemma': 0, 'parents': ['muc']},
	{'value': 'म्रियमाण', 'label': 'mriyamARa', 'attrib': [{'pos': 'prmp', 'defs': 'die'}, {'pos': 'prpp', 'defs': 'die'}], 'isLemma': 0, 'parents': ['mf']},
	{'value': 'यंस्यत्', 'label': 'yaMsyat', 'attrib': [{'pos': 'fap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'यंस्यमान', 'label': 'yaMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'यक्ष्यत्', 'label': 'yakzyat', 'attrib': [{'pos': 'fap', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'यक्ष्यमाण', 'label': 'yakzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'यच्छत्', 'label': 'yacCat', 'attrib': [{'pos': 'prap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'यज्', 'label': 'yaj', 'attrib': [{'pos': 'vt1am', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 1},
	{'value': 'यजत्', 'label': 'yajat', 'attrib': [{'pos': 'prap', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'यजनीय', 'label': 'yajanIya', 'attrib': [{'pos': 'ger', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'यजमान', 'label': 'yajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'worship, perform a Vedic ceremony'}, {'pos': 'm', 'defs': 'yajamāna , worshipper, performer and sponsor of a Vedic ceremony'}], 'isLemma': 1},
	{'value': 'यजमान', 'label': 'yajamAna', 'attrib': [{'pos': 'prmp', 'defs': 'worship, perform a Vedic ceremony'}, {'pos': 'm', 'defs': 'yajamāna , worshipper, performer and sponsor of a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'यजुर्वेद', 'label': 'yajurveda', 'attrib': [{'pos': 'm', 'defs': '<i>Yajurveda</i>'}], 'isLemma': 1},
	{'value': 'यजुस्', 'label': 'yajus', 'attrib': [{'pos': 'n', 'defs': 'mantra of the <i>Yajurveda</i>'}], 'isLemma': 1},
	{'value': 'यज्ञ', 'label': 'yajYa', 'attrib': [{'pos': 'm', 'defs': 'worship, ceremony'}], 'isLemma': 1},
	{'value': 'यत्', 'label': 'yat', 'attrib': [{'pos': 'prap', 'defs': 'go'}], 'isLemma': 0, 'parents': ['i']},
	{'value': 'यत', 'label': 'yata', 'attrib': [{'pos': 'ppp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'यतवत्', 'label': 'yatavat', 'attrib': [{'pos': 'pap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'यतस्', 'label': 'yatas', 'attrib': [{'pos': 'rel_adv', 'defs': 'whence, because'}], 'isLemma': 1},
	{'value': 'यत्र', 'label': 'yatra', 'attrib': [{'pos': 'rel_adv', 'defs': 'where'}], 'isLemma': 1},
	{'value': 'यत्वा', 'label': 'yatvA', 'attrib': [{'pos': 'abs', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'यथा', 'label': 'yaTA', 'attrib': [{'pos': 'rel_adv', 'defs': 'in which manner, as'}], 'isLemma': 1},
	{'value': 'यद्', 'label': 'yad', 'attrib': [{'pos': 'rel_pron, rel_adj', 'defs': 'which/who/what'}], 'isLemma': 1},
	{'value': 'यदा', 'label': 'yadA', 'attrib': [{'pos': 'rel_adv', 'defs': 'at which time, when'}], 'isLemma': 1},
	{'value': 'यदि', 'label': 'yadi', 'attrib': [{'pos': 'rel_adv', 'defs': 'if'}], 'isLemma': 1},
	{'value': 'यन्तव्य', 'label': 'yantavya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'यन्तुम्', 'label': 'yantum', 'attrib': [{'pos': 'inf', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'यम्', 'label': 'yam', 'attrib': [{'pos': 'vt1a', 'defs': 'hold'}], 'isLemma': 1},
	{'value': 'यमनीय', 'label': 'yamanIya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'यम्य', 'label': 'yamya', 'attrib': [{'pos': 'ger', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'यम्यमान', 'label': 'yamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'यशस्', 'label': 'yaSas', 'attrib': [{'pos': 'n', 'defs': 'glory'}], 'isLemma': 1},
	{'value': 'यष्टव्य', 'label': 'yazwavya', 'attrib': [{'pos': 'ger', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'यष्टुम्', 'label': 'yazwum', 'attrib': [{'pos': 'inf', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'याज्य', 'label': 'yAjya', 'attrib': [{'pos': 'ger', 'defs': 'worship, perform a Vedic ceremony'}], 'isLemma': 0, 'parents': ['yaj']},
	{'value': 'यावत्', 'label': 'yAvat', 'attrib': [{'pos': 'rel_adj', 'defs': 'of which extent'}, {'pos': 'rel_adv', 'defs': 'to which extent'}], 'isLemma': 1},
	{'value': 'युक्त', 'label': 'yukta', 'attrib': [{'pos': 'ppp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युक्तवत्', 'label': 'yuktavat', 'attrib': [{'pos': 'pap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युक्त्वा', 'label': 'yuktvA', 'attrib': [{'pos': 'abs', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युज्', 'label': 'yuj', 'attrib': [{'pos': 'vt7am', 'defs': 'yoke, join, unite'}], 'isLemma': 1},
	{'value': 'युज्यमान', 'label': 'yujyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युञ्जत्', 'label': 'yuYjat', 'attrib': [{'pos': 'prap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युञ्जान', 'label': 'yuYjAna', 'attrib': [{'pos': 'prmp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युद्ध', 'label': 'yudDa', 'attrib': [{'pos': 'ppp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'युद्धवत्', 'label': 'yudDavat', 'attrib': [{'pos': 'pap', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'युद्ध्वा', 'label': 'yudDvA', 'attrib': [{'pos': 'abs', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'युध्', 'label': 'yuD', 'attrib': [{'pos': 'vt4m', 'defs': 'fight'}], 'isLemma': 1},
	{'value': 'युधिष्ठिर', 'label': 'yuDizWira', 'attrib': [{'pos': 'm_pn', 'defs': 'Yudhiṣṭhira'}], 'isLemma': 1},
	{'value': 'युधिष्ठिरार्जुन', 'label': 'yuDizWirArjuna', 'attrib': [{'pos': 'm', 'defs': 'Yudhiṣṭhira and Arjuna'}], 'isLemma': 1},
	{'value': 'युध्यमान', 'label': 'yuDyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'fight'}, {'pos': 'prpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'युयुजान', 'label': 'yuyujAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युयुज्वस्', 'label': 'yuyujvas', 'attrib': [{'pos': 'pfap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'युयुधान', 'label': 'yuyuDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'युष्मद्', 'label': 'yuzmad', 'attrib': [{'pos': 'pers_pron', 'defs': 'you'}], 'isLemma': 1},
	{'value': 'यूप', 'label': 'yUpa', 'attrib': [{'pos': 'm', 'defs': 'sacrificial post'}], 'isLemma': 1},
	{'value': 'येमान', 'label': 'yemAna', 'attrib': [{'pos': 'pfpp', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'येमिवस्', 'label': 'yemivas', 'attrib': [{'pos': 'pfap', 'defs': 'hold'}], 'isLemma': 0, 'parents': ['yam']},
	{'value': 'योक्तव्य', 'label': 'yoktavya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'योक्तुम्', 'label': 'yoktum', 'attrib': [{'pos': 'inf', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'योक्ष्यत्', 'label': 'yokzyat', 'attrib': [{'pos': 'fap', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'योक्ष्यमाण', 'label': 'yokzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'योग्य', 'label': 'yogya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'योजनीय', 'label': 'yojanIya', 'attrib': [{'pos': 'ger', 'defs': 'yoke, join, unite'}], 'isLemma': 0, 'parents': ['yuj']},
	{'value': 'योत्स्यमान', 'label': 'yotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'योद्धव्य', 'label': 'yodDavya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'योद्धुम्', 'label': 'yodDum', 'attrib': [{'pos': 'inf', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'योधनीय', 'label': 'yoDanIya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'योध्य', 'label': 'yoDya', 'attrib': [{'pos': 'ger', 'defs': 'fight'}], 'isLemma': 0, 'parents': ['yuD']},
	{'value': 'रंस्यमान', 'label': 'raMsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'रज्जु', 'label': 'rajju', 'attrib': [{'pos': 'f', 'defs': 'rope'}], 'isLemma': 1},
	{'value': 'रण', 'label': 'raRa', 'attrib': [{'pos': 'n', 'defs': 'battle'}], 'isLemma': 1},
	{'value': 'रत', 'label': 'rata', 'attrib': [{'pos': 'ppp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'रतवत्', 'label': 'ratavat', 'attrib': [{'pos': 'pap', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'रत्न', 'label': 'ratna', 'attrib': [{'pos': 'n', 'defs': 'gift, gem'}], 'isLemma': 1},
	{'value': 'रत्वा', 'label': 'ratvA', 'attrib': [{'pos': 'abs', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'रथ', 'label': 'raTa', 'attrib': [{'pos': 'm', 'defs': 'chariot'}], 'isLemma': 1},
	{'value': 'रन्तव्य', 'label': 'rantavya', 'attrib': [{'pos': 'ger', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'रन्तुम्', 'label': 'rantum', 'attrib': [{'pos': 'inf', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'रम्', 'label': 'ram', 'attrib': [{'pos': 'vi1m', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 1},
	{'value': 'रमणीय', 'label': 'ramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'रममाण', 'label': 'ramamARa', 'attrib': [{'pos': 'prmp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'रम्य', 'label': 'ramya', 'attrib': [{'pos': 'ger', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'रम्यमाण', 'label': 'ramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'राजन्', 'label': 'rAjan', 'attrib': [{'pos': 'm', 'defs': 'king'}], 'isLemma': 1},
	{'value': 'राज्य', 'label': 'rAjya', 'attrib': [{'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 1},
	{'value': 'रात्रि', 'label': 'rAtri', 'attrib': [{'pos': 'f', 'defs': 'night'}], 'isLemma': 1},
	{'value': 'रामायण', 'label': 'rAmAyaRa', 'attrib': [{'pos': 'n_pn', 'defs': 'the <i>Rāmāyana</i>'}], 'isLemma': 1},
	{'value': 'राष्ट्र', 'label': 'rAzwra', 'attrib': [{'pos': 'n', 'defs': 'kingdom'}], 'isLemma': 1},
	{'value': 'रुद्ध', 'label': 'rudDa', 'attrib': [{'pos': 'ppp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रुद्धवत्', 'label': 'rudDavat', 'attrib': [{'pos': 'pap', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रुद्ध्वा', 'label': 'rudDvA', 'attrib': [{'pos': 'abs', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रुध्', 'label': 'ruD', 'attrib': [{'pos': 'vt7am', 'defs': 'obstruct'}], 'isLemma': 1},
	{'value': 'रुध्यमान', 'label': 'ruDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रुन्धत्', 'label': 'runDat', 'attrib': [{'pos': 'prap', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रुन्धान', 'label': 'runDAna', 'attrib': [{'pos': 'prmp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रुरुधान', 'label': 'ruruDAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रुरुध्वस्', 'label': 'ruruDvas', 'attrib': [{'pos': 'pfap', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रेमाण', 'label': 'remARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'stop, rest, enjoy, play'}], 'isLemma': 0, 'parents': ['ram']},
	{'value': 'रोग', 'label': 'roga', 'attrib': [{'pos': 'm', 'defs': 'illness'}], 'isLemma': 1},
	{'value': 'रोत्स्यत्', 'label': 'rotsyat', 'attrib': [{'pos': 'fap', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रोत्स्यमान', 'label': 'rotsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रोद्धव्य', 'label': 'rodDavya', 'attrib': [{'pos': 'ger', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रोद्धुम्', 'label': 'rodDum', 'attrib': [{'pos': 'inf', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रोधनीय', 'label': 'roDanIya', 'attrib': [{'pos': 'ger', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'रोध्य', 'label': 'roDya', 'attrib': [{'pos': 'ger', 'defs': 'obstruct'}], 'isLemma': 0, 'parents': ['ruD']},
	{'value': 'लप्स्यमान', 'label': 'lapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लब्ध', 'label': 'labDa', 'attrib': [{'pos': 'ppp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लब्धवत्', 'label': 'labDavat', 'attrib': [{'pos': 'pap', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लब्धव्य', 'label': 'labDavya', 'attrib': [{'pos': 'ger', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लब्धुम्', 'label': 'labDum', 'attrib': [{'pos': 'inf', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लब्ध्वा', 'label': 'labDvA', 'attrib': [{'pos': 'abs', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लभ्', 'label': 'laB', 'attrib': [{'pos': 'vt1m', 'defs': 'grab, get, obtain'}], 'isLemma': 1},
	{'value': 'लभनीय', 'label': 'laBanIya', 'attrib': [{'pos': 'ger', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लभमान', 'label': 'laBamAna', 'attrib': [{'pos': 'prmp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लभ्य', 'label': 'laBya', 'attrib': [{'pos': 'ger', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लभ्यमान', 'label': 'laByamAna', 'attrib': [{'pos': 'prpp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लेभान', 'label': 'leBAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'grab, get, obtain'}], 'isLemma': 0, 'parents': ['laB']},
	{'value': 'लोक', 'label': 'loka', 'attrib': [{'pos': 'm', 'defs': 'region, world'}], 'isLemma': 1},
	{'value': 'वक्तव्य', 'label': 'vaktavya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'वक्तुम्', 'label': 'vaktum', 'attrib': [{'pos': 'inf', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'वक्ष्यत्', 'label': 'vakzyat', 'attrib': [{'pos': 'fap', 'defs': 'say, speak'}, {'pos': 'fap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vac', 'vah']},
	{'value': 'वक्ष्यमाण', 'label': 'vakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'say, speak'}, {'pos': 'fmp, fpp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vac', 'vah']},
	{'value': 'वच्', 'label': 'vac', 'attrib': [{'pos': 'vt2a', 'defs': 'say, speak'}], 'isLemma': 1},
	{'value': 'वचन', 'label': 'vacana', 'attrib': [{'pos': 'n', 'defs': 'speech, word'}], 'isLemma': 1},
	{'value': 'वचनीय', 'label': 'vacanIya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'वत्स्यत्', 'label': 'vatsyat', 'attrib': [{'pos': 'fap', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वत्स्यमान', 'label': 'vatsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वद्', 'label': 'vad', 'attrib': [{'pos': 'vt1a', 'defs': 'tell'}], 'isLemma': 1},
	{'value': 'वदत्', 'label': 'vadat', 'attrib': [{'pos': 'prap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वदनीय', 'label': 'vadanIya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वदितव्य', 'label': 'vaditavya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वदितुम्', 'label': 'vaditum', 'attrib': [{'pos': 'inf', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वदिष्यत्', 'label': 'vadizyat', 'attrib': [{'pos': 'fap', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वदिष्यमाण', 'label': 'vadizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वधू', 'label': 'vaDU', 'attrib': [{'pos': 'f', 'defs': 'bride, wife'}], 'isLemma': 1},
	{'value': 'वन', 'label': 'vana', 'attrib': [{'pos': 'n', 'defs': 'forest'}], 'isLemma': 1},
	{'value': 'वर', 'label': 'vara', 'attrib': [{'pos': 'adj', 'defs': 'choice, excellent'}, {'pos': 'm', 'defs': 'boon, bridegroom'}], 'isLemma': 1},
	{'value': 'वरणीय', 'label': 'varaRIya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरितव्य', 'label': 'varitavya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरितुम्', 'label': 'varitum', 'attrib': [{'pos': 'inf', 'defs': 'cover'}, {'pos': 'inf', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरिष्यत्', 'label': 'varizyat', 'attrib': [{'pos': 'fap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरिष्यमाण', 'label': 'varizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover'}, {'pos': 'fmp, fpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरीतव्य', 'label': 'varItavya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरीतुम्', 'label': 'varItum', 'attrib': [{'pos': 'inf', 'defs': 'cover'}, {'pos': 'inf', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरीष्यत्', 'label': 'varIzyat', 'attrib': [{'pos': 'fap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरीष्यमाण', 'label': 'varIzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'cover'}, {'pos': 'fmp, fpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वरुण', 'label': 'varuRa', 'attrib': [{'pos': 'm_pn', 'defs': 'Varuṇa'}], 'isLemma': 1},
	{'value': 'वर्तनीय', 'label': 'vartanIya', 'attrib': [{'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वर्तमान', 'label': 'vartamAna', 'attrib': [{'pos': 'prmp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वर्तितव्य', 'label': 'vartitavya', 'attrib': [{'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वर्तितुम्', 'label': 'vartitum', 'attrib': [{'pos': 'inf', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वर्तित्वा', 'label': 'vartitvA', 'attrib': [{'pos': 'abs', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वर्तिष्यमाण', 'label': 'vartizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वर्ष', 'label': 'varza', 'attrib': [{'pos': 'm', 'defs': 'rain, year'}], 'isLemma': 1},
	{'value': 'ववृतान', 'label': 'vavftAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'ववृवस्', 'label': 'vavfvas', 'attrib': [{'pos': 'pfap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वव्रजान', 'label': 'vavrajAna', 'attrib': [{'pos': 'pfpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'वव्रज्वस्', 'label': 'vavrajvas', 'attrib': [{'pos': 'pfap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'वव्राण', 'label': 'vavrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'cover'}, {'pos': 'pfmp, pfpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वस्', 'label': 'vas', 'attrib': [{'pos': 'vi1a', 'defs': 'dwell'}], 'isLemma': 1},
	{'value': 'वसत्', 'label': 'vasat', 'attrib': [{'pos': 'prap', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वसनीय', 'label': 'vasanIya', 'attrib': [{'pos': 'ger', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वसितव्य', 'label': 'vasitavya', 'attrib': [{'pos': 'ger', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वस्तुम्', 'label': 'vastum', 'attrib': [{'pos': 'inf', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वह्', 'label': 'vah', 'attrib': [{'pos': 'vt1am', 'defs': 'carry, flow, wed'}], 'isLemma': 1},
	{'value': 'वहत्', 'label': 'vahat', 'attrib': [{'pos': 'prap', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'वहमान', 'label': 'vahamAna', 'attrib': [{'pos': 'prmp', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'वा', 'label': 'vA', 'attrib': [{'pos': 'disj_pcl', 'defs': 'or'}], 'isLemma': 1},
	{'value': 'वाक्य', 'label': 'vAkya', 'attrib': [{'pos': 'ger', 'defs': 'say, speak'}], 'isLemma': 0, 'parents': ['vac']},
	{'value': 'वाच्', 'label': 'vAc', 'attrib': [{'pos': 'f', 'defs': 'speech'}], 'isLemma': 1},
	{'value': 'वाद्य', 'label': 'vAdya', 'attrib': [{'pos': 'ger', 'defs': 'tell'}], 'isLemma': 0, 'parents': ['vad']},
	{'value': 'वार', 'label': 'vAra', 'attrib': [{'pos': 'm', 'defs': 'occasion, day'}], 'isLemma': 1},
	{'value': 'वारणीय', 'label': 'vAraRIya', 'attrib': [{'pos': 'ger', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयत्', 'label': 'vArayat', 'attrib': [{'pos': 'prap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयमाण', 'label': 'vArayamARa', 'attrib': [{'pos': 'prmp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयाम्', 'label': 'vArayAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयितव्य', 'label': 'vArayitavya', 'attrib': [{'pos': 'ger', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयितुम्', 'label': 'vArayitum', 'attrib': [{'pos': 'inf', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयित्वा', 'label': 'vArayitvA', 'attrib': [{'pos': 'abs', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयिष्यत्', 'label': 'vArayizyat', 'attrib': [{'pos': 'fap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारयिष्यमाण', 'label': 'vArayizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारि', 'label': 'vAri', 'attrib': [{'pos': 'n', 'defs': 'water'}], 'isLemma': 1},
	{'value': 'वारित', 'label': 'vArita', 'attrib': [{'pos': 'ppp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वारितवत्', 'label': 'vAritavat', 'attrib': [{'pos': 'pap', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वार्य', 'label': 'vArya', 'attrib': [{'pos': 'ger', 'defs': 'block'}, {'pos': 'ger', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वार्यमाण', 'label': 'vAryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'block'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वाल्मीकि', 'label': 'vAlmIki', 'attrib': [{'pos': 'm_pn', 'defs': 'Vālmīki'}], 'isLemma': 1},
	{'value': 'वासस्', 'label': 'vAsas', 'attrib': [{'pos': 'n', 'defs': 'cloth, clothes'}], 'isLemma': 1},
	{'value': 'वास्य', 'label': 'vAsya', 'attrib': [{'pos': 'ger', 'defs': 'dwell'}], 'isLemma': 0, 'parents': ['vas']},
	{'value': 'वाहनीय', 'label': 'vAhanIya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'वाह्य', 'label': 'vAhya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'वि', 'label': 'vi', 'attrib': [{'pos': 'preverb', 'defs': 'apart'}], 'isLemma': 1},
	{'value': 'विक्रयणीय', 'label': 'vikrayaRIya', 'attrib': [{'pos': 'ger', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विक्रीणत्', 'label': 'vikrIRat', 'attrib': [{'pos': 'prap', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विक्रीणान', 'label': 'vikrIRAna', 'attrib': [{'pos': 'prmp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विक्रीत', 'label': 'vikrIta', 'attrib': [{'pos': 'ppp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विक्रीतवत्', 'label': 'vikrItavat', 'attrib': [{'pos': 'pap', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विक्रीय', 'label': 'vikrIya', 'attrib': [{'pos': 'abs', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विक्रीयमाण', 'label': 'vikrIyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विक्रेतव्य', 'label': 'vikretavya', 'attrib': [{'pos': 'ger', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विक्रेतुम्', 'label': 'vikretum', 'attrib': [{'pos': 'inf', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विक्रेय', 'label': 'vikreya', 'attrib': [{'pos': 'ger', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विक्रेष्यत्', 'label': 'vikrezyat', 'attrib': [{'pos': 'fap', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विक्रेष्यमाण', 'label': 'vikrezyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विगच्छत्', 'label': 'vigacCat', 'attrib': [{'pos': 'prap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विगत', 'label': 'vigata', 'attrib': [{'pos': 'ppp', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विगतवत्', 'label': 'vigatavat', 'attrib': [{'pos': 'pap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विगत्य', 'label': 'vigatya', 'attrib': [{'pos': 'abs', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विगन्तव्य', 'label': 'vigantavya', 'attrib': [{'pos': 'ger', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विगन्तुम्', 'label': 'vigantum', 'attrib': [{'pos': 'inf', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विगम', 'label': 'vigama', 'attrib': [{'pos': 'm', 'defs': 'separation, departure'}], 'isLemma': 1},
	{'value': 'विगमनीय', 'label': 'vigamanIya', 'attrib': [{'pos': 'ger', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विगमिष्यत्', 'label': 'vigamizyat', 'attrib': [{'pos': 'fap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विगमिष्यमाण', 'label': 'vigamizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विगम्य', 'label': 'vigamya', 'attrib': [{'pos': 'abs', 'defs': 'separate, depart'}, {'pos': 'ger', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विगम्यमान', 'label': 'vigamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विचिक्रियाण', 'label': 'vicikriyARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विचिक्रीवस्', 'label': 'vicikrIvas', 'attrib': [{'pos': 'pfap', 'defs': 'sell'}], 'isLemma': 0, 'parents': ['vi_krI']},
	{'value': 'विजगन्वस्', 'label': 'vijaganvas', 'attrib': [{'pos': 'pfap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विजग्मान', 'label': 'vijagmAna', 'attrib': [{'pos': 'pfpp', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विजग्मिवस्', 'label': 'vijagmivas', 'attrib': [{'pos': 'pfap', 'defs': 'separate, depart'}], 'isLemma': 0, 'parents': ['vi_gam']},
	{'value': 'विजज्ञान', 'label': 'vijajYAna', 'attrib': [{'pos': 'pfpp', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'विजज्ञिवस्', 'label': 'vijajYivas', 'attrib': [{'pos': 'pfap', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'विजानत्', 'label': 'vijAnat', 'attrib': [{'pos': 'prap', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'विज्ञात', 'label': 'vijYAta', 'attrib': [{'pos': 'ppp', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'विज्ञातवत्', 'label': 'vijYAtavat', 'attrib': [{'pos': 'pap', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'विज्ञातव्य', 'label': 'vijYAtavya', 'attrib': [{'pos': 'ger', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'विज्ञातुम्', 'label': 'vijYAtum', 'attrib': [{'pos': 'inf', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'विज्ञान', 'label': 'vijYAna', 'attrib': [{'pos': 'n', 'defs': 'understanding'}], 'isLemma': 1},
	{'value': 'विज्ञानीय', 'label': 'vijYAnIya', 'attrib': [{'pos': 'ger', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'विज्ञाय', 'label': 'vijYAya', 'attrib': [{'pos': 'abs', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'विज्ञायमान', 'label': 'vijYAyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'विज्ञास्यत्', 'label': 'vijYAsyat', 'attrib': [{'pos': 'fap', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'विज्ञास्यमान', 'label': 'vijYAsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'विज्ञेय', 'label': 'vijYeya', 'attrib': [{'pos': 'ger', 'defs': 'discern, understand'}], 'isLemma': 0, 'parents': ['vi_jYA']},
	{'value': 'वि_क्री', 'label': 'vi_krI', 'attrib': [{'pos': 'vt9am', 'defs': 'sell'}], 'isLemma': 1},
	{'value': 'वि_गम्', 'label': 'vi_gam', 'attrib': [{'pos': 'vi1a', 'defs': 'separate, depart'}], 'isLemma': 1},
	{'value': 'वि_ज्ञा', 'label': 'vi_jYA', 'attrib': [{'pos': 'vt9a', 'defs': 'discern, understand'}], 'isLemma': 1},
	{'value': 'वि_सद्', 'label': 'vi_sad', 'attrib': [{'pos': 'vi1a, vi6a', 'defs': 'be sad, grieve'}], 'isLemma': 1},
	{'value': 'वि_स्मृ', 'label': 'vi_smf', 'attrib': [{'pos': 'vt1a', 'defs': 'forget'}], 'isLemma': 1},
	{'value': 'वित्त', 'label': 'vitta', 'attrib': [{'pos': 'ppp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वित्तवत्', 'label': 'vittavat', 'attrib': [{'pos': 'pap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वित्त्वा', 'label': 'vittvA', 'attrib': [{'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विद्', 'label': 'vid', 'attrib': [{'pos': 'vt2a', 'defs': 'know'}, {'pos': 'vt6am', 'defs': 'find, get'}], 'isLemma': 1},
	{'value': 'विदाम्', 'label': 'vidAm', 'attrib': [{'pos': 'ppf_actn', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विदित', 'label': 'vidita', 'attrib': [{'pos': 'ppp', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विदितवत्', 'label': 'viditavat', 'attrib': [{'pos': 'pap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विदित्वा', 'label': 'viditvA', 'attrib': [{'pos': 'abs', 'defs': 'know'}, {'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विद्ध', 'label': 'vidDa', 'attrib': [{'pos': 'ppp', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'विद्धवत्', 'label': 'vidDavat', 'attrib': [{'pos': 'pap', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'विद्ध्वा', 'label': 'vidDvA', 'attrib': [{'pos': 'abs', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'विद्यमान', 'label': 'vidyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'know'}, {'pos': 'prpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विद्युत्', 'label': 'vidyut', 'attrib': [{'pos': 'm', 'defs': 'lightning'}], 'isLemma': 1},
	{'value': 'विद्वस्', 'label': 'vidvas', 'attrib': [{'pos': 'prap', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विध्यत्', 'label': 'viDyat', 'attrib': [{'pos': 'prap', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'विध्यमान', 'label': 'viDyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'विना', 'label': 'vinA', 'attrib': [{'pos': 'pcl', 'defs': 'without (2/3/5)'}], 'isLemma': 1},
	{'value': 'विन्दत्', 'label': 'vindat', 'attrib': [{'pos': 'prap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विन्दमान', 'label': 'vindamAna', 'attrib': [{'pos': 'prmp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विविदान', 'label': 'vividAna', 'attrib': [{'pos': 'pfpp', 'defs': 'know'}, {'pos': 'pfmp, pfpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विविदिवस्', 'label': 'vividivas', 'attrib': [{'pos': 'pfap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विविद्वस्', 'label': 'vividvas', 'attrib': [{'pos': 'pfap', 'defs': 'know'}, {'pos': 'pfap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'विविशान', 'label': 'viviSAna', 'attrib': [{'pos': 'pfpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'विविशिवस्', 'label': 'viviSivas', 'attrib': [{'pos': 'pfap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'विविश्वस्', 'label': 'viviSvas', 'attrib': [{'pos': 'pfap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'विव्यधान', 'label': 'vivyaDAna', 'attrib': [{'pos': 'pfpp', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'विव्यध्वस्', 'label': 'vivyaDvas', 'attrib': [{'pos': 'pfap', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'विश्', 'label': 'viS', 'attrib': [{'pos': 'm', 'defs': 'settler'}, {'pos': 'vt6a', 'defs': 'enter'}], 'isLemma': 1},
	{'value': 'विशत्', 'label': 'viSat', 'attrib': [{'pos': 'prap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'विश्यमान', 'label': 'viSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'विषण्ण', 'label': 'vizaRRa', 'attrib': [{'pos': 'ppp', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विषण्णवत्', 'label': 'vizaRRavat', 'attrib': [{'pos': 'pap', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विषत्तव्य', 'label': 'vizattavya', 'attrib': [{'pos': 'ger', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विषत्तुम्', 'label': 'vizattum', 'attrib': [{'pos': 'inf', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विषत्स्यत्', 'label': 'vizatsyat', 'attrib': [{'pos': 'fap', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विषत्स्यमान', 'label': 'vizatsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विषदनीय', 'label': 'vizadanIya', 'attrib': [{'pos': 'ger', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विषद्य', 'label': 'vizadya', 'attrib': [{'pos': 'abs', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विषद्यमान', 'label': 'vizadyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विषाद्य', 'label': 'vizAdya', 'attrib': [{'pos': 'ger', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विषीदत्', 'label': 'vizIdat', 'attrib': [{'pos': 'prap', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विषेदान', 'label': 'vizedAna', 'attrib': [{'pos': 'pfpp', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विषेदिवस्', 'label': 'vizedivas', 'attrib': [{'pos': 'pfap', 'defs': 'be sad, grieve'}], 'isLemma': 0, 'parents': ['vi_sad']},
	{'value': 'विष्ट', 'label': 'vizwa', 'attrib': [{'pos': 'ppp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'विष्टवत्', 'label': 'vizwavat', 'attrib': [{'pos': 'pap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'विष्ट्वा', 'label': 'vizwvA', 'attrib': [{'pos': 'abs', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'विसस्मराण', 'label': 'visasmarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'विसस्मर्वस्', 'label': 'visasmarvas', 'attrib': [{'pos': 'pfap', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'विस्मरणीय', 'label': 'vismaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'विस्मरत्', 'label': 'vismarat', 'attrib': [{'pos': 'prap', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'विस्मरिष्यत्', 'label': 'vismarizyat', 'attrib': [{'pos': 'fap', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'विस्मरिष्यमाण', 'label': 'vismarizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'विस्मर्तव्य', 'label': 'vismartavya', 'attrib': [{'pos': 'ger', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'विस्मर्तुम्', 'label': 'vismartum', 'attrib': [{'pos': 'inf', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'विस्मर्यमाण', 'label': 'vismaryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'विस्मार्य', 'label': 'vismArya', 'attrib': [{'pos': 'ger', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'विस्मृत', 'label': 'vismfta', 'attrib': [{'pos': 'ppp', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'विस्मृतवत्', 'label': 'vismftavat', 'attrib': [{'pos': 'pap', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'विस्मृत्य', 'label': 'vismftya', 'attrib': [{'pos': 'abs', 'defs': 'forget'}], 'isLemma': 0, 'parents': ['vi_smf']},
	{'value': 'वीर', 'label': 'vIra', 'attrib': [{'pos': 'adj', 'defs': 'heroic'}, {'pos': 'm', 'defs': 'hero'}], 'isLemma': 1},
	{'value': 'वृ', 'label': 'vf', 'attrib': [{'pos': 'vt5am', 'defs': 'cover'}, {'pos': 'vt10am', 'defs': 'block'}, {'pos': 'vt9m', 'defs': 'choose'}], 'isLemma': 1},
	{'value': 'वृक', 'label': 'vfka', 'attrib': [{'pos': 'm', 'defs': 'wolf'}], 'isLemma': 1},
	{'value': 'वृक्ष', 'label': 'vfkza', 'attrib': [{'pos': 'm', 'defs': 'tree'}], 'isLemma': 1},
	{'value': 'वृणान', 'label': 'vfRAna', 'attrib': [{'pos': 'prmp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वृण्वत्', 'label': 'vfRvat', 'attrib': [{'pos': 'prap', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वृण्वान', 'label': 'vfRvAna', 'attrib': [{'pos': 'prmp', 'defs': 'cover'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वृत्', 'label': 'vft', 'attrib': [{'pos': 'vi1m', 'defs': 'occur, be present'}], 'isLemma': 1},
	{'value': 'वृत', 'label': 'vfta', 'attrib': [{'pos': 'ppp', 'defs': 'cover'}, {'pos': 'ppp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वृतवत्', 'label': 'vftavat', 'attrib': [{'pos': 'pap', 'defs': 'cover'}, {'pos': 'pap', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वृत्त', 'label': 'vftta', 'attrib': [{'pos': 'ppp', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वृत्तवत्', 'label': 'vfttavat', 'attrib': [{'pos': 'pap', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vft']},
	{'value': 'वृत्य', 'label': 'vftya', 'attrib': [{'pos': 'ger', 'defs': 'cover'}, {'pos': 'ger', 'defs': 'occur, be present'}], 'isLemma': 0, 'parents': ['vf', 'vft']},
	{'value': 'वृत्वा', 'label': 'vftvA', 'attrib': [{'pos': 'abs', 'defs': 'cover'}, {'pos': 'abs', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'वेक्ष्यत्', 'label': 'vekzyat', 'attrib': [{'pos': 'fap', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'वेक्ष्यमाण', 'label': 'vekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'वेत्तव्य', 'label': 'vettavya', 'attrib': [{'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेत्तुम्', 'label': 'vettum', 'attrib': [{'pos': 'inf', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेत्स्यत्', 'label': 'vetsyat', 'attrib': [{'pos': 'fap', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'वेत्स्यमान', 'label': 'vetsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'वेद', 'label': 'veda', 'attrib': [{'pos': 'm', 'defs': 'knowledge, veda , N. of most ancient texts'}], 'isLemma': 1},
	{'value': 'वेदनीय', 'label': 'vedanIya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेदि', 'label': 'vedi', 'attrib': [{'pos': 'f', 'defs': 'altar'}], 'isLemma': 1},
	{'value': 'वेदितव्य', 'label': 'veditavya', 'attrib': [{'pos': 'ger', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेदितुम्', 'label': 'veditum', 'attrib': [{'pos': 'inf', 'defs': 'know'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेदित्वा', 'label': 'veditvA', 'attrib': [{'pos': 'abs', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेदिष्यत्', 'label': 'vedizyat', 'attrib': [{'pos': 'fap', 'defs': 'know'}, {'pos': 'fap', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेदिष्यमाण', 'label': 'vedizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'know'}, {'pos': 'fmp, fpp', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेद्धव्य', 'label': 'vedDavya', 'attrib': [{'pos': 'ger', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'वेद्धुम्', 'label': 'vedDum', 'attrib': [{'pos': 'inf', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'वेद्य', 'label': 'vedya', 'attrib': [{'pos': 'ger', 'defs': 'know'}, {'pos': 'ger', 'defs': 'find, get'}], 'isLemma': 0, 'parents': ['vid']},
	{'value': 'वेधनीय', 'label': 'veDanIya', 'attrib': [{'pos': 'ger', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'वेध्य', 'label': 'veDya', 'attrib': [{'pos': 'ger', 'defs': 'pierce'}], 'isLemma': 0, 'parents': ['vyaD']},
	{'value': 'वेशनीय', 'label': 'veSanIya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'वेश्य', 'label': 'veSya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'वेष्टव्य', 'label': 'vezwavya', 'attrib': [{'pos': 'ger', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'वेष्टुम्', 'label': 'vezwum', 'attrib': [{'pos': 'inf', 'defs': 'enter'}], 'isLemma': 0, 'parents': ['viS']},
	{'value': 'वै', 'label': 'vE', 'attrib': [{'pos': 'pcl', 'defs': 'verily, truly'}], 'isLemma': 1},
	{'value': 'वोढव्य', 'label': 'voQavya', 'attrib': [{'pos': 'ger', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'वोढुम्', 'label': 'voQum', 'attrib': [{'pos': 'inf', 'defs': 'carry, flow, wed'}], 'isLemma': 0, 'parents': ['vah']},
	{'value': 'व्यध्', 'label': 'vyaD', 'attrib': [{'pos': 'vt4a', 'defs': 'pierce'}], 'isLemma': 1},
	{'value': 'व्यास', 'label': 'vyAsa', 'attrib': [{'pos': 'm_pn', 'defs': 'Vyāsa'}], 'isLemma': 1},
	{'value': 'व्रज्', 'label': 'vraj', 'attrib': [{'pos': 'vt1a', 'defs': 'walk'}], 'isLemma': 1},
	{'value': 'व्रजत्', 'label': 'vrajat', 'attrib': [{'pos': 'prap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजनीय', 'label': 'vrajanIya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजित', 'label': 'vrajita', 'attrib': [{'pos': 'ppp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजितवत्', 'label': 'vrajitavat', 'attrib': [{'pos': 'pap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजितव्य', 'label': 'vrajitavya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजितुम्', 'label': 'vrajitum', 'attrib': [{'pos': 'inf', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजित्वा', 'label': 'vrajitvA', 'attrib': [{'pos': 'abs', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजिष्यत्', 'label': 'vrajizyat', 'attrib': [{'pos': 'fap', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रजिष्यमाण', 'label': 'vrajizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रज्यमान', 'label': 'vrajyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्राज्य', 'label': 'vrAjya', 'attrib': [{'pos': 'ger', 'defs': 'walk'}], 'isLemma': 0, 'parents': ['vraj']},
	{'value': 'व्रियमाण', 'label': 'vriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'cover'}, {'pos': 'prpp', 'defs': 'choose'}], 'isLemma': 0, 'parents': ['vf']},
	{'value': 'शक्', 'label': 'Sak', 'attrib': [{'pos': 'vi5a', 'defs': 'be able'}], 'isLemma': 1},
	{'value': 'शकनीय', 'label': 'SakanIya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शकुन्तला', 'label': 'SakuntalA', 'attrib': [{'pos': 'f_pn', 'defs': 'Śakuntalā'}], 'isLemma': 1},
	{'value': 'शक्त', 'label': 'Sakta', 'attrib': [{'pos': 'ppp', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्तवत्', 'label': 'Saktavat', 'attrib': [{'pos': 'pap', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्तव्य', 'label': 'Saktavya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्तुम्', 'label': 'Saktum', 'attrib': [{'pos': 'inf', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्त्वा', 'label': 'SaktvA', 'attrib': [{'pos': 'abs', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्नुवत्', 'label': 'Saknuvat', 'attrib': [{'pos': 'prap', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्य', 'label': 'Sakya', 'attrib': [{'pos': 'ger', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शक्यमान', 'label': 'SakyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be able'}], 'isLemma': 0, 'parents': ['Sak']},
	{'value': 'शत', 'label': 'Sata', 'attrib': [{'pos': 'card_num', 'defs': 'hundred'}], 'isLemma': 1},
	{'value': 'शत्रु', 'label': 'Satru', 'attrib': [{'pos': 'm', 'defs': 'enemy'}], 'isLemma': 1},
	{'value': 'शप्', 'label': 'Sap', 'attrib': [{'pos': 'vt1am, vt4am', 'defs': 'swear (an oath), curse'}], 'isLemma': 1},
	{'value': 'शपत्', 'label': 'Sapat', 'attrib': [{'pos': 'prap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शपनीय', 'label': 'SapanIya', 'attrib': [{'pos': 'ger', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शपमान', 'label': 'SapamAna', 'attrib': [{'pos': 'prmp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शप्त', 'label': 'Sapta', 'attrib': [{'pos': 'ppp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शप्तवत्', 'label': 'Saptavat', 'attrib': [{'pos': 'pap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शप्तव्य', 'label': 'Saptavya', 'attrib': [{'pos': 'ger', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शप्तुम्', 'label': 'Saptum', 'attrib': [{'pos': 'inf', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शप्त्वा', 'label': 'SaptvA', 'attrib': [{'pos': 'abs', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शप्य', 'label': 'Sapya', 'attrib': [{'pos': 'ger', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शप्यत्', 'label': 'Sapyat', 'attrib': [{'pos': 'prap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शप्यमान', 'label': 'SapyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'swear (an oath), curse'}, {'pos': 'prpp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शप्स्यत्', 'label': 'Sapsyat', 'attrib': [{'pos': 'fap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शप्स्यमान', 'label': 'SapsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शब्द', 'label': 'Sabda', 'attrib': [{'pos': 'm', 'defs': 'speech, word'}], 'isLemma': 1},
	{'value': 'शयन', 'label': 'Sayana', 'attrib': [{'pos': 'n', 'defs': 'bed, couch'}], 'isLemma': 1},
	{'value': 'शयनीय', 'label': 'SayanIya', 'attrib': [{'pos': 'ger', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'शयान', 'label': 'SayAna', 'attrib': [{'pos': 'prmp', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'शयित', 'label': 'Sayita', 'attrib': [{'pos': 'ppp', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'शयितवत्', 'label': 'Sayitavat', 'attrib': [{'pos': 'pap', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'शयितव्य', 'label': 'Sayitavya', 'attrib': [{'pos': 'ger', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'शयितुम्', 'label': 'Sayitum', 'attrib': [{'pos': 'inf', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'शयित्वा', 'label': 'SayitvA', 'attrib': [{'pos': 'abs', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'शय्य', 'label': 'Sayya', 'attrib': [{'pos': 'ger', 'defs': 'lie, sleep'}], 'isLemma': 0, 'parents': ['SI']},
	{'value': 'शर', 'label': 'Sara', 'attrib': [{'pos': 'm', 'defs': 'arrow'}], 'isLemma': 1},
	{'value': 'शशासान', 'label': 'SaSAsAna', 'attrib': [{'pos': 'pfpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शशास्वस्', 'label': 'SaSAsvas', 'attrib': [{'pos': 'pfap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शाखा', 'label': 'SAKA', 'attrib': [{'pos': 'f', 'defs': 'branch'}], 'isLemma': 1},
	{'value': 'शाप', 'label': 'SApa', 'attrib': [{'pos': 'm', 'defs': 'promise, curse, vow'}], 'isLemma': 1},
	{'value': 'शाला', 'label': 'SAlA', 'attrib': [{'pos': 'f', 'defs': 'hall'}], 'isLemma': 1},
	{'value': 'शास्', 'label': 'SAs', 'attrib': [{'pos': 'vt2a', 'defs': 'rule, govern, teach'}], 'isLemma': 1},
	{'value': 'शासत्', 'label': 'SAsat', 'attrib': [{'pos': 'prap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शासनीय', 'label': 'SAsanIya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शासितव्य', 'label': 'SAsitavya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शासितुम्', 'label': 'SAsitum', 'attrib': [{'pos': 'inf', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शासित्वा', 'label': 'SAsitvA', 'attrib': [{'pos': 'abs', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शासिष्यत्', 'label': 'SAsizyat', 'attrib': [{'pos': 'fap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शासिष्यमाण', 'label': 'SAsizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शित', 'label': 'Sita', 'attrib': [{'pos': 'adj', 'defs': 'sharp'}], 'isLemma': 1},
	{'value': 'शिव', 'label': 'Siva', 'attrib': [{'pos': 'adj', 'defs': 'auspicious'}, {'pos': 'm_pn', 'defs': 'Śiva'}], 'isLemma': 1},
	{'value': 'शिष्ट', 'label': 'Sizwa', 'attrib': [{'pos': 'ppp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शिष्टवत्', 'label': 'Sizwavat', 'attrib': [{'pos': 'pap', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शिष्ट्वा', 'label': 'SizwvA', 'attrib': [{'pos': 'abs', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शिष्य', 'label': 'Sizya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}, {'pos': 'm', 'defs': 'student'}], 'isLemma': 1},
	{'value': 'शिष्य', 'label': 'Sizya', 'attrib': [{'pos': 'ger', 'defs': 'rule, govern, teach'}, {'pos': 'm', 'defs': 'student'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शिष्यमाण', 'label': 'SizyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'rule, govern, teach'}], 'isLemma': 0, 'parents': ['SAs']},
	{'value': 'शी', 'label': 'SI', 'attrib': [{'pos': 'vi2m', 'defs': 'lie, sleep'}], 'isLemma': 1},
	{'value': 'शीघ्र', 'label': 'SIGra', 'attrib': [{'pos': 'adj', 'defs': 'swift, quick'}], 'isLemma': 1},
	{'value': 'शीघ्रम्', 'label': 'SIGram', 'attrib': [{'pos': 'adv', 'defs': 'swiftly, quickly'}], 'isLemma': 1},
	{'value': 'शुच्', 'label': 'Suc', 'attrib': [{'pos': 'vt1a', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 1},
	{'value': 'शुचि', 'label': 'Suci', 'attrib': [{'pos': 'adj', 'defs': 'pure'}], 'isLemma': 1},
	{'value': 'शुचित', 'label': 'Sucita', 'attrib': [{'pos': 'ppp', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'शुचितवत्', 'label': 'Sucitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'शुचित्वा', 'label': 'SucitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'शुशुच्वस्', 'label': 'SuSucvas', 'attrib': [{'pos': 'pfap', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'शृण्वत्', 'label': 'SfRvat', 'attrib': [{'pos': 'prap', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'शेपान', 'label': 'SepAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शेपिवस्', 'label': 'Sepivas', 'attrib': [{'pos': 'pfap', 'defs': 'swear (an oath), curse'}], 'isLemma': 0, 'parents': ['Sap']},
	{'value': 'शेष', 'label': 'Seza', 'attrib': [{'pos': 'm', 'defs': 'remainder'}], 'isLemma': 1},
	{'value': 'शोचत्', 'label': 'Socat', 'attrib': [{'pos': 'prap', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'शोचनीय', 'label': 'SocanIya', 'attrib': [{'pos': 'ger', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'शोचयित्वा', 'label': 'SocayitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'शोचित', 'label': 'Socita', 'attrib': [{'pos': 'ppp', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'शोचितवत्', 'label': 'Socitavat', 'attrib': [{'pos': 'pap', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'शोचितव्य', 'label': 'Socitavya', 'attrib': [{'pos': 'ger', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'शोचितुम्', 'label': 'Socitum', 'attrib': [{'pos': 'inf', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'शोचित्वा', 'label': 'SocitvA', 'attrib': [{'pos': 'abs', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'शोच्य', 'label': 'Socya', 'attrib': [{'pos': 'ger', 'defs': 'shine, burn, grieve, regret'}], 'isLemma': 0, 'parents': ['Suc']},
	{'value': 'श्रवणीय', 'label': 'SravaRIya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रव्य', 'label': 'Sravya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्री', 'label': 'SrI', 'attrib': [{'pos': 'f', 'defs': 'glory'}], 'isLemma': 1},
	{'value': 'श्रु', 'label': 'Sru', 'attrib': [{'pos': 'vt1am', 'defs': 'hear, listen'}], 'isLemma': 1},
	{'value': 'श्रुत', 'label': 'Sruta', 'attrib': [{'pos': 'ppp', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रुतवत्', 'label': 'Srutavat', 'attrib': [{'pos': 'pap', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रुत्वा', 'label': 'SrutvA', 'attrib': [{'pos': 'abs', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रूयमाण', 'label': 'SrUyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रोतव्य', 'label': 'Srotavya', 'attrib': [{'pos': 'ger', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्रोतुम्', 'label': 'Srotum', 'attrib': [{'pos': 'inf', 'defs': 'hear, listen'}], 'isLemma': 0, 'parents': ['Sru']},
	{'value': 'श्वन्', 'label': 'Svan', 'attrib': [{'pos': 'm', 'defs': 'dog'}], 'isLemma': 1},
	{'value': 'श्वस्', 'label': 'Svas', 'attrib': [{'pos': 'adv', 'defs': 'tomorrow'}], 'isLemma': 1},
	{'value': 'षष्', 'label': 'zaz', 'attrib': [{'pos': 'card_num', 'defs': 'six'}], 'isLemma': 1},
	{'value': 'षष्ठ', 'label': 'zazWa', 'attrib': [{'pos': 'ord_num', 'defs': 'sixth'}], 'isLemma': 1},
	{'value': 'संक्रंस्यत्', 'label': 'saMkraMsyat', 'attrib': [{'pos': 'fap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संक्रंस्यमान', 'label': 'saMkraMsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संक्रमणीय', 'label': 'saMkramaRIya', 'attrib': [{'pos': 'ger', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संक्रमितव्य', 'label': 'saMkramitavya', 'attrib': [{'pos': 'ger', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संक्रमितुम्', 'label': 'saMkramitum', 'attrib': [{'pos': 'inf', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संक्रम्य', 'label': 'saMkramya', 'attrib': [{'pos': 'abs', 'defs': 'meet'}, {'pos': 'ger', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संक्रम्यमाण', 'label': 'saMkramyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संक्रान्त', 'label': 'saMkrAnta', 'attrib': [{'pos': 'ppp', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संक्रान्तवत्', 'label': 'saMkrAntavat', 'attrib': [{'pos': 'pap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संक्रामत्', 'label': 'saMkrAmat', 'attrib': [{'pos': 'prap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संक्राम्यत्', 'label': 'saMkrAmyat', 'attrib': [{'pos': 'prap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संगच्छमान', 'label': 'saMgacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगत', 'label': 'saMgata', 'attrib': [{'pos': 'ppp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगतवत्', 'label': 'saMgatavat', 'attrib': [{'pos': 'pap', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगत्य', 'label': 'saMgatya', 'attrib': [{'pos': 'abs', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगन्तव्य', 'label': 'saMgantavya', 'attrib': [{'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगन्तुम्', 'label': 'saMgantum', 'attrib': [{'pos': 'inf', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगमनीय', 'label': 'saMgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगमिष्यमाण', 'label': 'saMgamizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगम्य', 'label': 'saMgamya', 'attrib': [{'pos': 'abs', 'defs': 'go together, unite, meet'}, {'pos': 'ger', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संगम्यमान', 'label': 'saMgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संचक्रमाण', 'label': 'saMcakramARa', 'attrib': [{'pos': 'pfpp', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संचक्रम्वस्', 'label': 'saMcakramvas', 'attrib': [{'pos': 'pfap', 'defs': 'meet'}], 'isLemma': 0, 'parents': ['sam_kram']},
	{'value': 'संजग्मान', 'label': 'saMjagmAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'go together, unite, meet'}], 'isLemma': 0, 'parents': ['sam_gam']},
	{'value': 'संजहृवस्', 'label': 'saMjahfvas', 'attrib': [{'pos': 'pfap', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संजह्राण', 'label': 'saMjahrARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संपत्तव्य', 'label': 'saMpattavya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपत्तुम्', 'label': 'saMpattum', 'attrib': [{'pos': 'inf', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपत्स्यमान', 'label': 'saMpatsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपदनीय', 'label': 'saMpadanIya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपद्य', 'label': 'saMpadya', 'attrib': [{'pos': 'abs', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपद्यमान', 'label': 'saMpadyamAna', 'attrib': [{'pos': 'prmp', 'defs': 'succeed, arise'}, {'pos': 'prpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपन्न', 'label': 'saMpanna', 'attrib': [{'pos': 'ppp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपन्नवत्', 'label': 'saMpannavat', 'attrib': [{'pos': 'pap', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपप्रच्छान', 'label': 'saMpapracCAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'संपाद्य', 'label': 'saMpAdya', 'attrib': [{'pos': 'ger', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संपृच्छमान', 'label': 'saMpfcCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'संपृच्छ्य', 'label': 'saMpfcCya', 'attrib': [{'pos': 'abs', 'defs': 'consult'}, {'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'संपृच्छ्यमान', 'label': 'saMpfcCyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'संपृष्ट', 'label': 'saMpfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'संपृष्टवत्', 'label': 'saMpfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'संपेदान', 'label': 'saMpedAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'succeed, arise'}], 'isLemma': 0, 'parents': ['sam_pad']},
	{'value': 'संप्रक्ष्यमाण', 'label': 'saMprakzyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'संप्रच्छनीय', 'label': 'saMpracCanIya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'संप्रष्टव्य', 'label': 'saMprazwavya', 'attrib': [{'pos': 'ger', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'संप्रष्टुम्', 'label': 'saMprazwum', 'attrib': [{'pos': 'inf', 'defs': 'consult'}], 'isLemma': 0, 'parents': ['sam_pracC']},
	{'value': 'संबभूवस्', 'label': 'saMbaBUvas', 'attrib': [{'pos': 'pfap', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभवत्', 'label': 'saMBavat', 'attrib': [{'pos': 'prap', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभवनीय', 'label': 'saMBavanIya', 'attrib': [{'pos': 'ger', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभवितव्य', 'label': 'saMBavitavya', 'attrib': [{'pos': 'ger', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभवितुम्', 'label': 'saMBavitum', 'attrib': [{'pos': 'inf', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभविष्यत्', 'label': 'saMBavizyat', 'attrib': [{'pos': 'fap', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभविष्यमाण', 'label': 'saMBavizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभव्य', 'label': 'saMBavya', 'attrib': [{'pos': 'ger', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभूत', 'label': 'saMBUta', 'attrib': [{'pos': 'ppp', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभूतवत्', 'label': 'saMBUtavat', 'attrib': [{'pos': 'pap', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभूय', 'label': 'saMBUya', 'attrib': [{'pos': 'abs', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संभूयमान', 'label': 'saMBUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'be together, arise, be possible'}], 'isLemma': 0, 'parents': ['sam_BU']},
	{'value': 'संवदनीय', 'label': 'saMvadanIya', 'attrib': [{'pos': 'ger', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'संवदमान', 'label': 'saMvadamAna', 'attrib': [{'pos': 'prmp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'संवदितव्य', 'label': 'saMvaditavya', 'attrib': [{'pos': 'ger', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'संवदितुम्', 'label': 'saMvaditum', 'attrib': [{'pos': 'inf', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'संवदिष्यमाण', 'label': 'saMvadizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'संवाद', 'label': 'saMvAda', 'attrib': [{'pos': 'm', 'defs': 'conversation'}], 'isLemma': 1},
	{'value': 'संवाद्य', 'label': 'saMvAdya', 'attrib': [{'pos': 'ger', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'संशय', 'label': 'saMSaya', 'attrib': [{'pos': 'm', 'defs': 'doubt'}], 'isLemma': 1},
	{'value': 'संशयनीय', 'label': 'saMSayanIya', 'attrib': [{'pos': 'ger', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'संशयान', 'label': 'saMSayAna', 'attrib': [{'pos': 'prmp', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'संशयित', 'label': 'saMSayita', 'attrib': [{'pos': 'ppp', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'संशयितवत्', 'label': 'saMSayitavat', 'attrib': [{'pos': 'pap', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'संशयितव्य', 'label': 'saMSayitavya', 'attrib': [{'pos': 'ger', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'संशयितुम्', 'label': 'saMSayitum', 'attrib': [{'pos': 'inf', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'संशय्य', 'label': 'saMSayya', 'attrib': [{'pos': 'abs', 'defs': 'lie down, hesitate'}, {'pos': 'ger', 'defs': 'lie down, hesitate'}], 'isLemma': 0, 'parents': ['sam_SI']},
	{'value': 'संस्कृत', 'label': 'saMskfta', 'attrib': [{'pos': 'adj', 'defs': 'refined'}, {'pos': 'n', 'defs': 'Sanskrit'}], 'isLemma': 1},
	{'value': 'संहरणीय', 'label': 'saMharaRIya', 'attrib': [{'pos': 'ger', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संहरत्', 'label': 'saMharat', 'attrib': [{'pos': 'prap', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संहरमाण', 'label': 'saMharamARa', 'attrib': [{'pos': 'prmp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संहरिष्यत्', 'label': 'saMharizyat', 'attrib': [{'pos': 'fap', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संहरिष्यमाण', 'label': 'saMharizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संहर्तव्य', 'label': 'saMhartavya', 'attrib': [{'pos': 'ger', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संहर्तुम्', 'label': 'saMhartum', 'attrib': [{'pos': 'inf', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संहार्य', 'label': 'saMhArya', 'attrib': [{'pos': 'ger', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संहृत', 'label': 'saMhfta', 'attrib': [{'pos': 'ppp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संहृतवत्', 'label': 'saMhftavat', 'attrib': [{'pos': 'pap', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संहृत्य', 'label': 'saMhftya', 'attrib': [{'pos': 'abs', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'संह्रियमाण', 'label': 'saMhriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'collect'}], 'isLemma': 0, 'parents': ['sam_hf']},
	{'value': 'सखी', 'label': 'saKI', 'attrib': [{'pos': 'f', 'defs': 'friend'}], 'isLemma': 1},
	{'value': 'सख्य', 'label': 'saKya', 'attrib': [{'pos': 'n', 'defs': 'friendship'}], 'isLemma': 1},
	{'value': 'सत्', 'label': 'sat', 'attrib': [{'pos': 'prap', 'defs': 'be'}], 'isLemma': 0, 'parents': ['as']},
	{'value': 'सत्तव्य', 'label': 'sattavya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सत्तुम्', 'label': 'sattum', 'attrib': [{'pos': 'inf', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सत्त्वा', 'label': 'sattvA', 'attrib': [{'pos': 'abs', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सत्य', 'label': 'satya', 'attrib': [{'pos': 'adj', 'defs': 'true, real'}, {'pos': 'n', 'defs': 'truth'}], 'isLemma': 1},
	{'value': 'सत्स्यत्', 'label': 'satsyat', 'attrib': [{'pos': 'fap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सत्स्यमान', 'label': 'satsyamAna', 'attrib': [{'pos': 'fpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सद्', 'label': 'sad', 'attrib': [{'pos': 'vi1a, vi6a', 'defs': 'sit, be sad'}], 'isLemma': 1},
	{'value': 'सदनीय', 'label': 'sadanIya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सदा', 'label': 'sadA', 'attrib': [{'pos': 'pron_adv', 'defs': 'always'}], 'isLemma': 1},
	{'value': 'सद्यमान', 'label': 'sadyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सन्न', 'label': 'sanna', 'attrib': [{'pos': 'ppp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सन्नवत्', 'label': 'sannavat', 'attrib': [{'pos': 'pap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सप्तन्', 'label': 'saptan', 'attrib': [{'pos': 'card_num', 'defs': 'seven'}], 'isLemma': 1},
	{'value': 'सप्तम', 'label': 'saptama', 'attrib': [{'pos': 'ord_num', 'defs': 'seventh'}], 'isLemma': 1},
	{'value': 'सम्', 'label': 'sam', 'attrib': [{'pos': 'preverb', 'defs': 'together'}], 'isLemma': 1},
	{'value': 'समागच्छमान', 'label': 'samAgacCamAna', 'attrib': [{'pos': 'prmp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'समागत', 'label': 'samAgata', 'attrib': [{'pos': 'ppp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'समागतवत्', 'label': 'samAgatavat', 'attrib': [{'pos': 'pap', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'समागत्य', 'label': 'samAgatya', 'attrib': [{'pos': 'abs', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'समागन्तव्य', 'label': 'samAgantavya', 'attrib': [{'pos': 'ger', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'समागन्तुम्', 'label': 'samAgantum', 'attrib': [{'pos': 'inf', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'समागमनीय', 'label': 'samAgamanIya', 'attrib': [{'pos': 'ger', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'समागमिष्यमाण', 'label': 'samAgamizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'समागम्य', 'label': 'samAgamya', 'attrib': [{'pos': 'abs', 'defs': 'come together, meet'}, {'pos': 'ger', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'समागम्यमान', 'label': 'samAgamyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'समाजग्मान', 'label': 'samAjagmAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'come together, meet'}], 'isLemma': 0, 'parents': ['sam_A_gam']},
	{'value': 'समीप', 'label': 'samIpa', 'attrib': [{'pos': 'adj', 'defs': 'near'}], 'isLemma': 1},
	{'value': 'समुदित', 'label': 'samudita', 'attrib': [{'pos': 'ppp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'समुदितवत्', 'label': 'samuditavat', 'attrib': [{'pos': 'pap', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'समुद्य', 'label': 'samudya', 'attrib': [{'pos': 'abs', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'समुद्यमान', 'label': 'samudyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'समूदान', 'label': 'samUdAna', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'speak together, converse, discuss'}], 'isLemma': 0, 'parents': ['sam_vad']},
	{'value': 'सम्_आ_गम्', 'label': 'sam_A_gam', 'attrib': [{'pos': 'vi1m', 'defs': 'come together, meet'}], 'isLemma': 1},
	{'value': 'सम्_क्रम्', 'label': 'sam_kram', 'attrib': [{'pos': 'vi1a', 'defs': 'meet'}], 'isLemma': 1},
	{'value': 'सम्_गम्', 'label': 'sam_gam', 'attrib': [{'pos': 'vi1m', 'defs': 'go together, unite, meet'}], 'isLemma': 1},
	{'value': 'सम्_पद्', 'label': 'sam_pad', 'attrib': [{'pos': 'vt4m', 'defs': 'succeed, arise'}], 'isLemma': 1},
	{'value': 'सम्_प्रच्छ्', 'label': 'sam_pracC', 'attrib': [{'pos': 'vi6m', 'defs': 'consult'}], 'isLemma': 1},
	{'value': 'सम्_भू', 'label': 'sam_BU', 'attrib': [{'pos': 'vi1a', 'defs': 'be together, arise, be possible'}], 'isLemma': 1},
	{'value': 'सम्_वद्', 'label': 'sam_vad', 'attrib': [{'pos': 'vi1m', 'defs': 'speak together, converse, discuss'}], 'isLemma': 1},
	{'value': 'सम्_शी', 'label': 'sam_SI', 'attrib': [{'pos': 'vi2m', 'defs': 'lie down, hesitate'}], 'isLemma': 1},
	{'value': 'सम्_हृ', 'label': 'sam_hf', 'attrib': [{'pos': 'vt1am', 'defs': 'collect'}], 'isLemma': 1},
	{'value': 'सरित्', 'label': 'sarit', 'attrib': [{'pos': 'f', 'defs': 'flowing, river'}], 'isLemma': 1},
	{'value': 'सर्व', 'label': 'sarva', 'attrib': [{'pos': 'pron, pron_adj', 'defs': 'all, whole, every'}], 'isLemma': 1},
	{'value': 'सर्वतस्', 'label': 'sarvatas', 'attrib': [{'pos': 'pron_adv', 'defs': 'from everywhere'}], 'isLemma': 1},
	{'value': 'सर्वत्र', 'label': 'sarvatra', 'attrib': [{'pos': 'pron_adv', 'defs': 'everywhere'}], 'isLemma': 1},
	{'value': 'सर्वथा', 'label': 'sarvaTA', 'attrib': [{'pos': 'pron_adv', 'defs': 'in every way'}], 'isLemma': 1},
	{'value': 'सर्वदा', 'label': 'sarvadA', 'attrib': [{'pos': 'pron_adv', 'defs': 'always'}], 'isLemma': 1},
	{'value': 'सवनीय', 'label': 'savanIya', 'attrib': [{'pos': 'ger', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सव्य', 'label': 'savya', 'attrib': [{'pos': 'ger', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सस्मराण', 'label': 'sasmarARa', 'attrib': [{'pos': 'pfpp', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'सस्मर्वस्', 'label': 'sasmarvas', 'attrib': [{'pos': 'pfap', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'सह', 'label': 'saha', 'attrib': [{'pos': 'pcl', 'defs': 'with'}], 'isLemma': 1},
	{'value': 'साद्य', 'label': 'sAdya', 'attrib': [{'pos': 'ger', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'साधु', 'label': 'sADu', 'attrib': [{'pos': 'adj', 'defs': 'straight, right, good'}, {'pos': 'm', 'defs': 'good man, sage'}], 'isLemma': 1},
	{'value': 'सामन्', 'label': 'sAman', 'attrib': [{'pos': 'n', 'defs': 'verse of the <i>Sāmaveda</i>'}], 'isLemma': 1},
	{'value': 'सामवेद', 'label': 'sAmaveda', 'attrib': [{'pos': 'm', 'defs': '<i>Sāmaveda</i>'}], 'isLemma': 1},
	{'value': 'सिषेवाण', 'label': 'sizevARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'सिष्णिहान', 'label': 'sizRihAna', 'attrib': [{'pos': 'pfpp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'सिष्णिह्वस्', 'label': 'sizRihvas', 'attrib': [{'pos': 'pfap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'सीदत्', 'label': 'sIdat', 'attrib': [{'pos': 'prap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सु', 'label': 'su', 'attrib': [{'pos': 'preverb', 'defs': 'well'}, {'pos': 'vt5am', 'defs': 'press'}], 'isLemma': 1},
	{'value': 'सुख', 'label': 'suKa', 'attrib': [{'pos': 'adj', 'defs': 'comfortable, happy'}, {'pos': 'n', 'defs': 'happiness'}], 'isLemma': 1},
	{'value': 'सुखम्', 'label': 'suKam', 'attrib': [{'pos': 'adv', 'defs': 'comfortably, happily'}], 'isLemma': 1},
	{'value': 'सुखिन्', 'label': 'suKin', 'attrib': [{'pos': 'adj', 'defs': 'happy'}], 'isLemma': 1},
	{'value': 'सुत', 'label': 'suta', 'attrib': [{'pos': 'ppp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सुतवत्', 'label': 'sutavat', 'attrib': [{'pos': 'pap', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सुत्वा', 'label': 'sutvA', 'attrib': [{'pos': 'abs', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सुदेव', 'label': 'sudeva', 'attrib': [{'pos': 'adj', 'defs': 'very noble'}], 'isLemma': 1},
	{'value': 'सुन्दर', 'label': 'sundara', 'attrib': [{'pos': 'adj', 'defs': 'beautiful'}], 'isLemma': 1},
	{'value': 'सुन्वत्', 'label': 'sunvat', 'attrib': [{'pos': 'prap', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सुन्वान', 'label': 'sunvAna', 'attrib': [{'pos': 'prmp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सुमनस्', 'label': 'sumanas', 'attrib': [{'pos': 'adj', 'defs': 'good-minded, well-disposed, benevolent, cheerful, wise'}], 'isLemma': 1},
	{'value': 'सुषुवस्', 'label': 'suzuvas', 'attrib': [{'pos': 'pfap', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सुषुवाण', 'label': 'suzuvARa', 'attrib': [{'pos': 'pfmp, pfpp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सुहृद्', 'label': 'suhfd', 'attrib': [{'pos': 'adj', 'defs': 'good-hearted, well-disposed'}, {'pos': 'm, f', 'defs': 'friend'}], 'isLemma': 1},
	{'value': 'सूयमान', 'label': 'sUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सूर्य', 'label': 'sUrya', 'attrib': [{'pos': 'm', 'defs': 'sun'}], 'isLemma': 1},
	{'value': 'सेदान', 'label': 'sedAna', 'attrib': [{'pos': 'pfpp', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सेदिवस्', 'label': 'sedivas', 'attrib': [{'pos': 'pfap', 'defs': 'sit, be sad'}], 'isLemma': 0, 'parents': ['sad']},
	{'value': 'सेव्', 'label': 'sev', 'attrib': [{'pos': 'vt1m', 'defs': 'serve'}], 'isLemma': 1},
	{'value': 'सेवनीय', 'label': 'sevanIya', 'attrib': [{'pos': 'ger', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'सेवमान', 'label': 'sevamAna', 'attrib': [{'pos': 'prmp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'सेवा', 'label': 'sevA', 'attrib': [{'pos': 'f', 'defs': 'service'}], 'isLemma': 1},
	{'value': 'सेवित', 'label': 'sevita', 'attrib': [{'pos': 'ppp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'सेवितवत्', 'label': 'sevitavat', 'attrib': [{'pos': 'pap', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'सेवितव्य', 'label': 'sevitavya', 'attrib': [{'pos': 'ger', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'सेवितुम्', 'label': 'sevitum', 'attrib': [{'pos': 'inf', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'सेवित्वा', 'label': 'sevitvA', 'attrib': [{'pos': 'abs', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'सेविष्यमाण', 'label': 'sevizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'सेव्य', 'label': 'sevya', 'attrib': [{'pos': 'ger', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'सेव्यमान', 'label': 'sevyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'serve'}], 'isLemma': 0, 'parents': ['sev']},
	{'value': 'सैन्य', 'label': 'sEnya', 'attrib': [{'pos': 'n', 'defs': 'army'}], 'isLemma': 1},
	{'value': 'सोतव्य', 'label': 'sotavya', 'attrib': [{'pos': 'ger', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सोतुम्', 'label': 'sotum', 'attrib': [{'pos': 'inf', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सोम', 'label': 'soma', 'attrib': [{'pos': 'm', 'defs': 'juice'}], 'isLemma': 1},
	{'value': 'सोष्यत्', 'label': 'sozyat', 'attrib': [{'pos': 'fap', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'सोष्यमाण', 'label': 'sozyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'press'}], 'isLemma': 0, 'parents': ['su']},
	{'value': 'स्त्री', 'label': 'strI', 'attrib': [{'pos': 'f', 'defs': 'woman'}], 'isLemma': 1},
	{'value': 'स्था', 'label': 'sTA', 'attrib': [{'pos': 'vi1am', 'defs': 'stand, stay, be present'}], 'isLemma': 1},
	{'value': 'स्थातव्य', 'label': 'sTAtavya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थातुम्', 'label': 'sTAtum', 'attrib': [{'pos': 'inf', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थान', 'label': 'sTAna', 'attrib': [{'pos': 'n', 'defs': 'place'}], 'isLemma': 1},
	{'value': 'स्थानीय', 'label': 'sTAnIya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थापयितुम्', 'label': 'sTApayitum', 'attrib': [{'pos': 'inf', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थाली', 'label': 'sTAlI', 'attrib': [{'pos': 'f', 'defs': 'pot, pan'}], 'isLemma': 1},
	{'value': 'स्थास्यत्', 'label': 'sTAsyat', 'attrib': [{'pos': 'fap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थास्यमान', 'label': 'sTAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थित', 'label': 'sTita', 'attrib': [{'pos': 'ppp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थितवत्', 'label': 'sTitavat', 'attrib': [{'pos': 'pap', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थित्वा', 'label': 'sTitvA', 'attrib': [{'pos': 'abs', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थीयमान', 'label': 'sTIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्थेय', 'label': 'sTeya', 'attrib': [{'pos': 'ger', 'defs': 'stand, stay, be present'}], 'isLemma': 0, 'parents': ['sTA']},
	{'value': 'स्निग्ध', 'label': 'snigDa', 'attrib': [{'pos': 'ppp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्निग्धवत्', 'label': 'snigDavat', 'attrib': [{'pos': 'pap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्निह्', 'label': 'snih', 'attrib': [{'pos': 'vi4a', 'defs': 'stick, be affectionate'}], 'isLemma': 1},
	{'value': 'स्निहित्वा', 'label': 'snihitvA', 'attrib': [{'pos': 'abs', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्निह्यत्', 'label': 'snihyat', 'attrib': [{'pos': 'prap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्निह्यमान', 'label': 'snihyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्नेक्ष्यत्', 'label': 'snekzyat', 'attrib': [{'pos': 'fap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्नेक्ष्यमाण', 'label': 'snekzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्नेह', 'label': 'sneha', 'attrib': [{'pos': 'm', 'defs': 'affection'}], 'isLemma': 1},
	{'value': 'स्नेहनीय', 'label': 'snehanIya', 'attrib': [{'pos': 'ger', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्नेहितव्य', 'label': 'snehitavya', 'attrib': [{'pos': 'ger', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्नेहितुम्', 'label': 'snehitum', 'attrib': [{'pos': 'inf', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्नेहित्वा', 'label': 'snehitvA', 'attrib': [{'pos': 'abs', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्नेहिष्यत्', 'label': 'snehizyat', 'attrib': [{'pos': 'fap', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्नेहिष्यमाण', 'label': 'snehizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्नेह्य', 'label': 'snehya', 'attrib': [{'pos': 'ger', 'defs': 'stick, be affectionate'}], 'isLemma': 0, 'parents': ['snih']},
	{'value': 'स्पर्शनीय', 'label': 'sparSanIya', 'attrib': [{'pos': 'ger', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'स्पृश्', 'label': 'spfS', 'attrib': [{'pos': 'vt6a', 'defs': 'touch'}], 'isLemma': 1},
	{'value': 'स्पृशत्', 'label': 'spfSat', 'attrib': [{'pos': 'prap', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'स्पृश्य', 'label': 'spfSya', 'attrib': [{'pos': 'ger', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'स्पृश्यमान', 'label': 'spfSyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'स्पृष्ट', 'label': 'spfzwa', 'attrib': [{'pos': 'ppp', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'स्पृष्टवत्', 'label': 'spfzwavat', 'attrib': [{'pos': 'pap', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'स्पृष्ट्वा', 'label': 'spfzwvA', 'attrib': [{'pos': 'abs', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'स्प्रक्ष्यत्', 'label': 'sprakzyat', 'attrib': [{'pos': 'fap', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'स्प्रक्ष्यमाण', 'label': 'sprakzyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'स्प्रष्टव्य', 'label': 'sprazwavya', 'attrib': [{'pos': 'ger', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'स्प्रष्टुम्', 'label': 'sprazwum', 'attrib': [{'pos': 'inf', 'defs': 'touch'}], 'isLemma': 0, 'parents': ['spfS']},
	{'value': 'स्म', 'label': 'sma', 'attrib': [{'pos': 'pcl', 'defs': 'ever, certainly'}], 'isLemma': 1},
	{'value': 'स्मरणीय', 'label': 'smaraRIya', 'attrib': [{'pos': 'ger', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'स्मरत्', 'label': 'smarat', 'attrib': [{'pos': 'prap', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'स्मरिष्यत्', 'label': 'smarizyat', 'attrib': [{'pos': 'fap', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'स्मरिष्यमाण', 'label': 'smarizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'स्मर्तव्य', 'label': 'smartavya', 'attrib': [{'pos': 'ger', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'स्मर्तुम्', 'label': 'smartum', 'attrib': [{'pos': 'inf', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'स्मर्यमाण', 'label': 'smaryamARa', 'attrib': [{'pos': 'prpp', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'स्मार्य', 'label': 'smArya', 'attrib': [{'pos': 'ger', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'स्मृ', 'label': 'smf', 'attrib': [{'pos': 'vt1a', 'defs': 'remember'}], 'isLemma': 1},
	{'value': 'स्मृत', 'label': 'smfta', 'attrib': [{'pos': 'ppp', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'स्मृतवत्', 'label': 'smftavat', 'attrib': [{'pos': 'pap', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'स्मृति', 'label': 'smfti', 'attrib': [{'pos': 'f', 'defs': 'memory, traditional texts'}], 'isLemma': 1},
	{'value': 'स्मृत्वा', 'label': 'smftvA', 'attrib': [{'pos': 'abs', 'defs': 'remember'}], 'isLemma': 0, 'parents': ['smf']},
	{'value': 'स्रज्', 'label': 'sraj', 'attrib': [{'pos': 'f', 'defs': 'garland'}], 'isLemma': 1},
	{'value': 'स्व', 'label': 'sva', 'attrib': [{'pos': 'pron, pron_adj', 'defs': "one's own"}], 'isLemma': 1},
	{'value': 'स्वराष्ट्र', 'label': 'svarAzwra', 'attrib': [{'pos': 'n', 'defs': 'own kingdom'}], 'isLemma': 1},
	{'value': 'स्वर्ग', 'label': 'svarga', 'attrib': [{'pos': 'm', 'defs': 'heaven'}], 'isLemma': 1},
	{'value': 'स्वसृ', 'label': 'svasf', 'attrib': [{'pos': 'f', 'defs': 'sister'}], 'isLemma': 1},
	{'value': 'ह', 'label': 'ha', 'attrib': [{'pos': 'pcl', 'defs': 'indeed, of course'}], 'isLemma': 1},
	{'value': 'हत', 'label': 'hata', 'attrib': [{'pos': 'ppp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हतवत्', 'label': 'hatavat', 'attrib': [{'pos': 'pap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हत्वा', 'label': 'hatvA', 'attrib': [{'pos': 'abs', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हन्', 'label': 'han', 'attrib': [{'pos': 'vt2a', 'defs': 'smite'}], 'isLemma': 1},
	{'value': 'हननीय', 'label': 'hananIya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हनिष्यत्', 'label': 'hanizyat', 'attrib': [{'pos': 'fap', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हनिष्यमाण', 'label': 'hanizyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हन्तव्य', 'label': 'hantavya', 'attrib': [{'pos': 'ger', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हन्तुम्', 'label': 'hantum', 'attrib': [{'pos': 'inf', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हन्यमान', 'label': 'hanyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'smite'}], 'isLemma': 0, 'parents': ['han']},
	{'value': 'हयनीय', 'label': 'hayanIya', 'attrib': [{'pos': 'ger', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'हरणीय', 'label': 'haraRIya', 'attrib': [{'pos': 'ger', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'हरत्', 'label': 'harat', 'attrib': [{'pos': 'prap', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'हरमाण', 'label': 'haramARa', 'attrib': [{'pos': 'prmp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'हरि', 'label': 'hari', 'attrib': [{'pos': 'adj', 'defs': 'yellow'}, {'pos': 'm', 'defs': 'yellow, horse'}, {'pos': 'm_pn', 'defs': 'Hari Candra'}], 'isLemma': 1},
	{'value': 'हरिष्यत्', 'label': 'harizyat', 'attrib': [{'pos': 'fap', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'हरिष्यमाण', 'label': 'harizyamARa', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'हर्तव्य', 'label': 'hartavya', 'attrib': [{'pos': 'ger', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'हर्तुम्', 'label': 'hartum', 'attrib': [{'pos': 'inf', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'हवनीय', 'label': 'havanIya', 'attrib': [{'pos': 'ger', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'हविस्', 'label': 'havis', 'attrib': [{'pos': 'n', 'defs': 'oblation'}], 'isLemma': 1},
	{'value': 'हव्य', 'label': 'havya', 'attrib': [{'pos': 'ger', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'हस्त', 'label': 'hasta', 'attrib': [{'pos': 'm', 'defs': 'hand'}], 'isLemma': 1},
	{'value': 'हस्तिन्', 'label': 'hastin', 'attrib': [{'pos': 'adj', 'defs': 'having a hand'}, {'pos': 'm', 'defs': 'elephant'}], 'isLemma': 1},
	{'value': 'हा', 'label': 'hA', 'attrib': [{'pos': 'vt3a', 'defs': 'leave, abandon'}], 'isLemma': 1},
	{'value': 'हातव्य', 'label': 'hAtavya', 'attrib': [{'pos': 'ger', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'हातुम्', 'label': 'hAtum', 'attrib': [{'pos': 'inf', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'हानीय', 'label': 'hAnIya', 'attrib': [{'pos': 'ger', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'हार्य', 'label': 'hArya', 'attrib': [{'pos': 'ger', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'हास्यत्', 'label': 'hAsyat', 'attrib': [{'pos': 'fap', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'हास्यमान', 'label': 'hAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'हि', 'label': 'hi', 'attrib': [{'pos': 'sub_pcl', 'defs': 'because, for'}, {'pos': 'vt5a', 'defs': 'impel, send'}], 'isLemma': 1},
	{'value': 'हित', 'label': 'hita', 'attrib': [{'pos': 'ppp', 'defs': 'put'}, {'pos': 'ppp', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['DA', 'hi']},
	{'value': 'हितवत्', 'label': 'hitavat', 'attrib': [{'pos': 'pap', 'defs': 'put'}, {'pos': 'pap', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['DA', 'hi']},
	{'value': 'हित्वा', 'label': 'hitvA', 'attrib': [{'pos': 'abs', 'defs': 'put'}, {'pos': 'abs', 'defs': 'leave, abandon'}, {'pos': 'abs', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['DA', 'hA', 'hi']},
	{'value': 'हिन्वत्', 'label': 'hinvat', 'attrib': [{'pos': 'prap', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'हिमालय', 'label': 'himAlaya', 'attrib': [{'pos': 'm_pn', 'defs': 'Himālaya , the Himalayan mountain range'}], 'isLemma': 1},
	{'value': 'हीन', 'label': 'hIna', 'attrib': [{'pos': 'ppp', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'हीनवत्', 'label': 'hInavat', 'attrib': [{'pos': 'pap', 'defs': 'leave, abandon'}], 'isLemma': 0, 'parents': ['hA']},
	{'value': 'हीयमान', 'label': 'hIyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'leave, abandon'}, {'pos': 'prpp', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hA', 'hi']},
	{'value': 'हु', 'label': 'hu', 'attrib': [{'pos': 'vt3a', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 1},
	{'value': 'हुत', 'label': 'huta', 'attrib': [{'pos': 'ppp', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'हुतवत्', 'label': 'hutavat', 'attrib': [{'pos': 'pap', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'हुत्वा', 'label': 'hutvA', 'attrib': [{'pos': 'abs', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'हूत', 'label': 'hUta', 'attrib': [{'pos': 'ppp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'हूतवत्', 'label': 'hUtavat', 'attrib': [{'pos': 'pap', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'हूत्वा', 'label': 'hUtvA', 'attrib': [{'pos': 'abs', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'हूयमान', 'label': 'hUyamAna', 'attrib': [{'pos': 'prpp', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}, {'pos': 'prpp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hu', 'hve']},
	{'value': 'हृ', 'label': 'hf', 'attrib': [{'pos': 'vt1am', 'defs': 'take'}], 'isLemma': 1},
	{'value': 'हृत', 'label': 'hfta', 'attrib': [{'pos': 'ppp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'हृतवत्', 'label': 'hftavat', 'attrib': [{'pos': 'pap', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'हृत्वा', 'label': 'hftvA', 'attrib': [{'pos': 'abs', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'हे', 'label': 'he', 'attrib': [{'pos': 'pcl', 'defs': 'hey, O'}], 'isLemma': 1},
	{'value': 'हेतव्य', 'label': 'hetavya', 'attrib': [{'pos': 'ger', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'हेतु', 'label': 'hetu', 'attrib': [{'pos': 'm', 'defs': 'reason, cause'}], 'isLemma': 1},
	{'value': 'हेतुम्', 'label': 'hetum', 'attrib': [{'pos': 'inf', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'हेय', 'label': 'heya', 'attrib': [{'pos': 'ger', 'defs': 'leave, abandon'}, {'pos': 'ger', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hA', 'hi']},
	{'value': 'हेष्यत्', 'label': 'hezyat', 'attrib': [{'pos': 'fap', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'हेष्यमाण', 'label': 'hezyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'impel, send'}], 'isLemma': 0, 'parents': ['hi']},
	{'value': 'होतव्य', 'label': 'hotavya', 'attrib': [{'pos': 'ger', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'होतुम्', 'label': 'hotum', 'attrib': [{'pos': 'inf', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'होतृ', 'label': 'hotf', 'attrib': [{'pos': 'm', 'defs': 'hotṛ'}], 'isLemma': 1},
	{'value': 'होष्यत्', 'label': 'hozyat', 'attrib': [{'pos': 'fap', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'होष्यमाण', 'label': 'hozyamARa', 'attrib': [{'pos': 'fpp', 'defs': 'offer (acc. to dat.), worship (acc. with dat.)'}], 'isLemma': 0, 'parents': ['hu']},
	{'value': 'ह्यस्', 'label': 'hyas', 'attrib': [{'pos': 'adv', 'defs': 'yesterday'}], 'isLemma': 1},
	{'value': 'ह्रियमाण', 'label': 'hriyamARa', 'attrib': [{'pos': 'prpp', 'defs': 'take'}], 'isLemma': 0, 'parents': ['hf']},
	{'value': 'ह्री', 'label': 'hrI', 'attrib': [{'pos': 'f', 'defs': 'modesty'}], 'isLemma': 1},
	{'value': 'ह्वयत्', 'label': 'hvayat', 'attrib': [{'pos': 'prap', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'ह्वयमान', 'label': 'hvayamAna', 'attrib': [{'pos': 'prmp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'ह्वातव्य', 'label': 'hvAtavya', 'attrib': [{'pos': 'ger', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'ह्वातुम्', 'label': 'hvAtum', 'attrib': [{'pos': 'inf', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'ह्वानीय', 'label': 'hvAnIya', 'attrib': [{'pos': 'ger', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'ह्वाय', 'label': 'hvAya', 'attrib': [{'pos': 'ger', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'ह्वास्यत्', 'label': 'hvAsyat', 'attrib': [{'pos': 'fap', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'ह्वास्यमान', 'label': 'hvAsyamAna', 'attrib': [{'pos': 'fmp, fpp', 'defs': 'call'}], 'isLemma': 0, 'parents': ['hve']},
	{'value': 'ह्वे', 'label': 'hve', 'attrib': [{'pos': 'vt1am', 'defs': 'call'}], 'isLemma': 1}
]

