// setHandled is never set to true-  figure out where to set it to true i.e. how to catch success 
// in the funtions handleRegister and handleLoging
import React, {useState, useContext} from "react";
import {auth} from "./firebase";
import {useHistory} from "react-router";
import { Link } from "react-router-dom";

import  {UserContext} from "../providers/UserProvider";

import Button from 'react-bootstrap/Button';

import '../components/css/Form.css';
import { Alert } from "react-bootstrap";

const Login =(props) => {
	const {setShow2} = props;

	const {currentUser} = useContext(UserContext);

	const [email, setEmail] = useState('');
	const [password, setPassword] = useState('');
	const [emailError, setEmailError] = useState(null);
	const [passwordError, setPasswordError] = useState(null);
	const [handled, setHandled] = useState(false);

	const [errorMSG, setErrorMSG] =useState('');


	const history = useHistory();

	const clearInputs = () =>{
		setEmail('');
		setPassword('');
	}
	const clearErrors = () =>{
		setEmailError(null);
		setPasswordError(null);
	}

	const handleLogin = (event) =>{
		event.preventDefault();
		clearErrors();
		setHandled(false);
		
		if (email.includes('iiit.ac.in')) {
			setErrorMSG('The course is completed !');
		}
		else {
		auth
			.signInWithEmailAndPassword(email, password)
			.catch(err => {
				console.log("error-",err.code, err.message);
				switch(err.code){
					case "auth/invalid-email":
					case "auth/user-disabled":
					case "auth/user-not-found":
						setEmailError(err.message);
						clearInputs();
						break;
					case "auth/wrong-password":
						setPasswordError(err.message);
						setPassword('');
						break;
					default:
						setErrorMSG(err.message);
						break;
				}
			});
		}
	};

	if(currentUser){
		try {
			history.push(props.location.state.from.pathname);
		} catch {
			history.push('/');
		}
	};

	if(handled===true && emailError===null && passwordError===null){
		setShow2(true);
	}
	if(emailError || passwordError){
		setShow2(false);
	}

	return(
		
		<form className="wholeForm">
			
			<h2 style={{textAlign: "center"}}>Login</h2>
			
			{errorMSG &&
				<Alert variant="danger"
					onClose={()=>setErrorMSG('')}
					dismissible
					style={{width:'400px', textAlign:'center'}}
				> 
					{errorMSG}
				</Alert>
			}
			<div className="innerForm">
			<div className="formItem">
				<input type="text" name="email" autoFocus required value={email}  
						onChange={e =>setEmail(e.target.value)} style={{backgroundColor: "transparent"}}/>
				<label htmlFor="email" className="label-name">
					<span className="content-name" style={{backgroundColor: "transparent"}}>Email</span>
				</label>
			</div>
			{/* {console.log("@p", emailError, passwordError)} */}
			<p className="errorMsg">{emailError}</p>

			<div className="formItem">
				<input type="password" name="password" autoComplete="off" required 
						value={password} onChange={e => setPassword(e.target.value)} style={{backgroundColor: "transparent"}}/>
				<label htmlFor="password" className="label-name">
					<span className="content-name" style={{backgroundColor: "transparent"}}>Password</span>
				</label>
			</div>
			<p className="errorMsg">{passwordError}</p>
			
			<div className="btnContainer">		
				<Button size="md" block onClick={(event) => {return handleLogin(event)}}>Login</Button>
				<div className="text-center mt-3 mb-3">
					<Link to="/forgotPassword">Forgot Password ?</Link>
				</div>	
			</div>
			</div>

		</form>
		
	);
}

export default Login;