import React from 'react';
import styled from 'styled-components';
import './ans.css';

// Styled Components
const APrefix = styled.span`
	font-size: 28px;

	color:${props=>props.complete?'green':'black'};
`;
const AQue = styled.span`
	font-family: Praja;
	font-size: 40px;

	color:${props=>props.complete?'green':'black'};
`;
const Atext =styled.li`
	font-size: 18px;
	color:${props=>props.status==='complete'?'green':'red'};
`;
const Arow=styled.li`
	display: flex;
	flex-flow: row wrap;
`;
const Acol=styled.div`
	margin-right: 30px;
	display: flex;
	flex-flow: column nowrap;
	text-align: center;
	
	color:${props=>props.correct?'green':'red'};
`;


// Main Component
const AnsDocument=({metadata, data, email})=>{
	
	console.log('metadata', metadata);
	console.log('data', data);
	// console.log('email', email);
	return (
		<div className='ansMain'>
			
			<div className='ansMeta'>
				{/* Chapter & Exercise & Student email info */}
			</div>

			{Object.keys(data).map((que,i) =>(
				<div className='wrapper' key={i}>

					<APrefix complete={data[que]['meta']['complete']}>
						{data[que]['meta']['prefix']+' '}
					</APrefix>
					<AQue complete={data[que]['meta']['complete']}>
						{data[que]['meta']['qText']}
					</AQue>

					{data[que]['ans'].map((part, j)=>(
						<ul className='ansSection' key={`${i}${j}`}>
							
							{part['type']==='text'&&
								<Atext status={part['status']}>{part['ans']}</Atext>		
							}
							{part['type']==='translation'&&
								<div>
									<Atext status={part['status']}>Translation: {part['ans']['translation']}</Atext>
									{part['ans']['addComment']&&
										<p>Comment: {part['ans']['comment']}</p>
									}
								</div>
							}
							{part['type']==='row'&&
								<Arow status={part['status']}>
									{part['ans'].map((col, k)=>(
										
										<Acol correct={col['allCorrect']} key={`${i}${j}${k}`}>
											{col['ans'].map((token, l)=>(
												<div key={`${i}${j}${k}${l}`}>	
													{l===0 && <span style={{color:'green'}}>{token}</span>}

													{l!==0 &&
														<>
															{token===""
																?<span style={{color:'white'}}>-</span>
																: <span>{token}</span>
															}
														</>
													}
												</div>
											))}
										</Acol>
									))}
								</Arow>
							}

						</ul>
					))}
					
				</div>
			))}


		</div>
	);
};

export default AnsDocument;