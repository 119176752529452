import React, { useState, useEffect, useContext } from 'react';
import { Link } from 'react-router-dom';

import Error from '../Error';
import { FaSearch } from 'react-icons/fa';
import  {UserContext} from "../../providers/UserProvider";

// Import react-circular-progressbar module and styles
import {
		CircularProgressbarWithChildren,
		buildStyles
	} from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
  
// Animation
import {easeQuadInOut } from "d3-ease";
import AnimatedProgressProvider from "./AnimatedProgressProvider";
 
import './StudentStyles.css';

const SearchBar =({query, setQuery})=>{
	return (		
		<div className="searchContainer">
			<FaSearch className="searchIcon" />
			<input className="searchInput" type='text' value={query} 
						onChange={(e)=>setQuery(e.target.value)}/>
		</div>
	);
};

const StudentChProgress =({match})=>{
	const {currentUser} = useContext(UserContext);

	const [done, setDone] = useState(false);
	const [chProgress, setChProgress] =useState([]);
	const [filteredChProgress, setFilteredChProgress] =useState([]);

	const [errorMsg, setErrorMsg] =useState("");

	const [query, setQuery] =useState([]);

	useEffect(()=>{
		var postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify({email:match.params.email})
		}
		const getStudents = async() =>{
			const response = await fetch(process.env.REACT_APP_FLASK_API+'get_students_chProgress', postObj);
			
			const data = await response.json();
			console.log('DATA:', data);

			setErrorMsg(data.errorMsg);

			setFilteredChProgress(data.progress);
			setChProgress(data.progress);

			setDone(true);
		};
		getStudents();
	}, [match.params.email]);

	Object.filter =(obj, predicate) => 
						Object.fromEntries(Object.entries(obj).filter(predicate));
	useEffect(() =>{
		setFilteredChProgress(Object.filter(chProgress, ([chP, details])=>{
			if (query.length===0 || query.trim()==='') {
				return true;
			} else if (details[0].toLowerCase().includes(query.trim().toLowerCase())) {
				return true;
			} else if (chP.toLowerCase().includes(query.trim().toLowerCase())) {
				return true;
			}
		}));
   	}, [query, chProgress]);

	if (!['dev', 'prof'].includes(currentUser.utype))
		return <Error message="No such page" />
	else if (errorMsg.length>0)
	   return <Error message={errorMsg} />
	else if (Object.keys(chProgress).length===0 || !done)
		return <Error message="Loading..." />
	return (
		<div>
			<div className="studentHeader">
				<div className="studentCountSent">
					<span className="studentCount">{Object.keys(filteredChProgress).length}</span> 
					{filteredChProgress.length===1?'Chapter':'Chapters'}
				</div>

				<div className="studentCenter">
					<SearchBar query={query} setQuery={setQuery} />
				</div>

				<div className="studentTitle">{match.params.email}</div>
			</div>
		
			<div className="studentsArea">
				{Object.keys(filteredChProgress).map(ch => (
					<Link to={`/student=${match.params.email}/ch=${ch.split(' ')[1]}`} 
							key={ch} className="studentBox">
						<AnimatedProgressProvider
							valueStart={0}
							valueEnd={filteredChProgress[ch][1]}
							duration={0.75}
							easingFunction={easeQuadInOut}
						>
							{(value) => {
							const roundedValue = Math.round(value);
							return (
								<CircularProgressbarWithChildren
									value={value}
									// text={`${roundedValue}%`}
									circleRatio={0.75}
									styles={buildStyles({
										rotation: 1 / 2 + 1 / 8,
										pathTransition: "none",
										strokeLinecap: "butt",
										trailColor: "#eee",
										pathColor: "rgb(0, 123, 255)"
									})}
									>
									<div className="chKey">{ch}</div>
									<div className="percentage">
										{roundedValue}%
									</div>
								</CircularProgressbarWithChildren>
							);
							}}
						</AnimatedProgressProvider>
						<span className="Name" 
								dangerouslySetInnerHTML=
								{{__html: filteredChProgress[ch][0] }}
						/>
					</Link>
				))}
			</div>
		</div>
	);
};

export default StudentChProgress;