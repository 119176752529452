import React, {useState, useContext} from 'react';

import { Button, Modal, InputGroup,
			OverlayTrigger, Tooltip
		} from "react-bootstrap";
import ImageFadeIn from "react-image-fade-in";

import Select, { createFilter } from "react-select";
import createFilterOptions from "react-select-fast-filter-options";

import {vocab, pos} from './helpers/data';
import {UserContext} from "../../providers/UserProvider";
import {Box, SingleInflection, colorStyles} from "./helpers/blocks";

import VerbInterface from './interfaces/VerbInterface';

import cross from './helpers/cross.png';
import LoadingPage from "./helpers/LoadingPage";
import question from './helpers/questionOrange.png';

import './helpers/blocks.css';
import '../css/Components.css';

const VerbIdentificaitonDrill =({curQ, setPartInst, ch, curPi, qIndex, setCurQComplete}) =>{

	const [loading, setLoading] =useState(false);

	const {currentUser} = useContext(UserContext);

	const partType =curQ.parts[curPi];
	const [interfaceTitle, setInterfaceTitle] =useState(''); 
	const [interfaceIndex, setInterfaceIndex] =useState([0,0]);
	const [showInterface, setShowInterface] =useState(false);
	
	// //Enter = Submit
	// // Disabbled to avoid load on ec2 isntance
	// const [btn, setBtn]=useState(null) // use setBtn ref={node=>setBtn(node)}

	// const interfaceOpen =useRef(null);
	// useEffect(()=>{
	// 	interfaceOpen.current =showInterface;
	// }, [showInterface]);
	
	// const handleKeyDown=(event)=>{
	// 	// console.log('key=', event.key)
	// 	if(event.key==='Enter' & !interfaceOpen.current){
	// 		event.preventDefault();
	// 		btn.click();
	// 	}
	// };
	// useEffect(() => {
	// 	if (btn){
	// 		// create an eventListener
	// 		window.addEventListener('keydown', e=>handleKeyDown(e));
	// 		// cleanup this component
	// 		return () => {
	// 			window.removeEventListener('keydown', e=>handleKeyDown(e));
	// 		};
	// 	}
	// }, [btn]);

	//Misc Functions !
	const filterOptions = createFilterOptions({vocab});

	const getBorderColor =(error, correct)=>{
		if (error)
			return 'red';
		
		if (correct)
			return 'green';

		return 'transparent';
	};

	const getPlaceholder =(selected, temp, options=[])=>{
		if (!selected)
			return temp
		// console.log(selected, temp, options);
		if (selected.length>0)
			return selected
		else if (options.length>1)
			return options[0].value+'?';
		else
			return temp;
	};

	// This is constructed in the backend (python) and passed as part of the curQ
	const [ansState, setAnsState] = useState(curQ.uans); 

	// useEffect(()=>{
	// 	console.log("newState:", ansState);
	// }, [ansState]);
	
	const handleInputChange = (idx, ana, event, word) => {
		const values = [...ansState];
		switch(event.target.name){
			case "inflection":
				values[idx].inflectionBoxes[ana].inflection = event.target.value;
				if (event.target.value==='?' ||  event.target.value==='/'){
					setInterfaceTitle(word);
					setInterfaceIndex([idx, ana]);
					setShowInterface(true);
				}
				break;
			default:
				break;
		}
	
		setAnsState(values);
	};

	const handleSelectChange = (index, name, selected) => {
		const values = [...ansState];

		// console.log('selected:', selected);
		if (selected===null){
			return;
		}

		var options =[];
		switch(name){
			case "lemma":
				values[index].lemma = selected.value;
				// console.log("lemma", values[index].lemma);

				options =[];
				pos[selected.value].forEach(tag =>{
					options.push({ label:tag, value: tag })
				});
				values[index].posOptions =options
				if (options.length===1)
					values[index].pos =options[0].value;
				else
					values[index].pos =''
				// console.log("posOptions", values[index].posOptions);

				if(!selected.isLemma) {
					//Add rootOptions
					values[index].addRoot=true;
					var rootOptions = [];
					selected.parents.forEach(root =>{
						rootOptions.push({ label:root, value: root })
					});
					values[index].rootOptions =rootOptions;
					if (rootOptions.length===1)
						values[index].root =rootOptions[0].value;
					else
						values[index].root=''

					// Add rootPosOptions
					var rootPosOptions =[]; //for rootPos
					pos[values[index].root].forEach(tag =>{
						rootPosOptions.push({ label:tag, value: tag })
					});
					values[index].rootPosOptions = rootPosOptions;
					if (rootPosOptions.length===1)
						values[index].rootPos =rootPosOptions[0].value;
					else
						values[index].rootPos =''
				}
				else{
					//Remove rootOptions
					values[index].addRoot=false;
					values[index].root ='';
					values[index].rootPos='';
					values[index].rootOptions =[];
					values[index].rootPosOptions =[];
				}
				break;

			case "pos":
				values[index].pos =selected.value;
				break;

			case "root":
				values[index].root = selected.value;
				// console.log("root", values[index].root);

				options =[]; //for rootPos
				pos[selected.value].forEach(tag =>{
					options.push({ label:tag, value: tag })
				});
				values[index].rootPosOptions = options
				if (options.length===1)
						values[index].rootPos =options[0].value;
					else
						values[index].rootPos =''
				// console.log("rootPosOptions", values[index].rootPosOptions);
				break

			case "rootPos":
				values[index].rootPos =selected.value;
				break;
			
			default:
				break;
		}
		
		setAnsState(values);
	};

	// Checking the user's answers

	//checking box or all depending on checkType
	const check =(index, checkType, curXMLid) =>{	
		// console.log("checking", checkType);
		// console.log("values@", ansState[index]);

		let body ={ uans: ansState, pi: curPi, email: currentUser.email, checkType: checkType,
							qObj: {'ch':ch,'exercise':curQ.exercise, 'n':curQ.n, 's': curQ.s, 'ai':index,
									'i':qIndex, 'parts': curQ.parts, 'text': curQ.text, 'xmlid':curXMLid, 'total':curQ.total}
						}
		if (checkType ==='Box')
			body.uans = body.uans[index]

		// console.log('sending body', body);

		const postObj = {
			method: 'POST',
			cache: 'no-cache',
			headers: {'Content-Type': 'application/json'},
			body: JSON.stringify(body)
		}
	
		const getFeedback =async () => {
			setLoading(true);

			const response = await fetch(process.env.REACT_APP_FLASK_API+'check_uans', postObj);
			const data = await response.json();
			// console.log("data:", data.analysis.allComplete);

			setPartInst(data.analysis.inst);
			if (checkType==="All")
				setAnsState(data.analysis.afterState[1]);
			else {
				const values = [...ansState];
				values[index] =data.analysis.afterState[1];
				setAnsState(values);
			}
			
			setCurQComplete(data.completed);

			setLoading(false);
		};
		getFeedback();
	};

	const [showPdf, setShowPdf] =useState(false);
	const [pdfFile, setPDFfile]=useState('agni.pdf');
	const InflectionError =(box, title, message, pdf) => {
		if (message.length===0)
			message ="Your inflectional identifier is incorrect."

		if (box.empty)
			return <OverlayTrigger
						placement="top"
						overlay={<Tooltip>Your inflectional identifier is blank.</Tooltip>}
					>
						<ImageFadeIn opacityTransition={3}
							width={20} height={20} src={question} alt='empty'/>
					</OverlayTrigger>
		else if (pdf.length===0) {
			return <OverlayTrigger
						placement="top"
						overlay={<Tooltip>{message}</Tooltip>}
					>
						<ImageFadeIn opacityTransition={3}
							width={20} height={20} src={cross} alt='wrong'/>
					</OverlayTrigger>
		}
		else {
			return (
				<>
					<Modal 
							show={showPdf} 
							onHide={()=>setShowPdf(false)} 
							dialogClassName="pdfmodal-90w"
					>
						<Modal.Header closeButton> 
							<div style={{display:'flex', justifyContent:'center', width:'100%'}}>
								<span style={{fontSize:'24pt', fontFamily:'Praja'}}>{title}</span>
							</div>
						</Modal.Header>
						<embed
							title="helpPdf"
							src={require("../../docs/"+pdfFile)+"#zoom=150"}
							frameBorder="0"
							scrolling="auto"
							height="100%"
							width="100%"
						></embed>
					</Modal>

					<OverlayTrigger
						placement="top"
						overlay={<Tooltip>{message}</Tooltip>}
					>
						<ImageFadeIn opacityTransition={3} width={20} height={20}
							onClick={()=>{setPDFfile(pdf);setShowPdf(true)}} src={cross} alt='wrong'/>
					</OverlayTrigger>
				</>
			);
		}
	}
	
	return (
		<div>
			<LoadingPage loading={loading} />
			
			<VerbInterface
				show={showInterface}
				onHide={()=>setShowInterface(false)}
				title={interfaceTitle}
				index={interfaceIndex}
				ansstate={ansState}
				setans={(newAns)=>setAnsState(newAns)}
				partType={partType}
			/>
			
			{curQ.takeInput &&
			<>
				<div className="FlexBox">
					<h4 style={{marginRight:'5px'}}><span className="blueText">A:</span></h4>
					<div className="containerBox drillScroll">
						{ansState.map((cur, index)=>(		
							<Box key={`${cur.title}${index}`} borderColor={getBorderColor(cur.errorFound, cur.allCorrect)}>
								<div className='boxTitle'>
									<div className='numberAndTitle'>
										<div>
											{index+1}
										</div>
										<div style={{fontSize:'28px', fontFamily:'Praja', 
												marginLeft:'auto', marginRight:'auto'}}>
											{cur.title}
										</div>
									</div>
									<div className="line" />
								</div>
		
								<div className="form-group">
									<label htmlFor={`inflection:${index}`} className="smText">Inflection</label>
									
									<div id={`inflection:${index}`} style={{display:'flex', flexFlow:'row wrap'}}>
										{cur.inflectionBoxes.map((box, anaIndex)=>(
											<SingleInflection key={`${index}:${anaIndex}`} borderColor={getBorderColor(box.inflectionError, box.allCorrect)}>
											<InputGroup className='inputBox'>
												<input
													type="text"
													autoComplete="off"
													className="form-control"

													name="inflection"
													// id={`inflection${index}:${anaIndex}`}
													// aria-describedby={```inflectionError${index}:${anaIndex}`}
													
													// placeholder="Third"
													value={box.inflection}
													onChange={(event) => handleInputChange(index, anaIndex, event, cur.title)}

													style={{padding:'5px 10px'}}
												/>
												{box.inflectionError &&
													<div className='inputBoxError'>										
														{InflectionError(box, cur.title, box.help.message, box.help.pdf)}
													</div>
												}
											
											</InputGroup>
											</SingleInflection>
										))}
									</div>

								</div>

								<div className="form-group">
									<label htmlFor={`"lemma"${index}`} className="smText">Lemma
									{cur.lemmaError===true&&
										<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
										width={25} height={25} src={cross} alt='wrong'/>
									}
									{cur.lemmaError==='?'&&
										<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
										width={25} height={25} src={question} alt='empty'/>
									}
									</label>
									<Select
										backspaceRemovesValue
										autoFocus={index===0}
										isClearable
										
										name="lemma"
										id={`"lemma"${index}`}
										aria-describedby={`"lemmaError"${index}`}
										
										options={vocab}
										styles={colorStyles}
										maxMenuHeight={200}

										filterOptions={filterOptions}
										filterOption={createFilter({ignoreCase:false, ignoreAccents:false, trim:true,  matchFrom:'start'})}
										
										placeholder={getPlaceholder(cur.lemma, "First")}
										onChange={(selectedOption) => handleSelectChange(index, "lemma", selectedOption )}

										// menuPortalTarget={document.body}
									/>
									
								</div>

								<div className="form-group">
									<label htmlFor={`"pos"${index}`} className="smText">Lexical category
									{cur.posError===true&&
										<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
										width={25} height={25} src={cross} alt='wrong'/>
									}
									{cur.posError==='?'&&
										<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
										width={25} height={25} src={question} alt='empty'/>
									}
									</label>
									<Select
										backspaceRemovesValue
										name="pos"
										id={`"pos"${index}`}
										aria-describedby={`"posError"${index}`}

										options={cur.posOptions}

										placeholder={getPlaceholder(cur.pos, "Second", cur.posOptions)}
										// selectValue ={cur.pos}
										onChange={(selectedOption) => handleSelectChange(index, "pos", selectedOption )}

										// menuPortalTarget={document.querySelector("body")}
									/>	
								</div>

								
								{cur.addRoot && 
									<>
										<div className="form-group">
											<label htmlFor={`"root"${index}`} className="smText">Root
											{cur.rootError===true&&
												<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
												width={25} height={25} src={cross} alt='wrong'/>
											}
											{cur.rootError==='?'&&
												<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
												width={25} height={25} src={question} alt='empty'/>
											}
											</label>
											<Select
												backspaceRemovesValue
												name="root"
												id={`"root"${index}`}
												aria-describedby={`"rootError"${index}`}

												options={cur.rootOptions}

												placeholder={getPlaceholder(cur.root, "Fourth", cur.rootOptions)}
												// selectValue ={cur.root}
												onChange={(selectedOption) => handleSelectChange(index, "root", selectedOption )}

												// menuPortalTarget={document.querySelector("body")}
											/>
										</div>
										<div className="form-group">
											<label htmlFor={`"rootPos"${index}`} className="smText">
												Root's lexical category
												{cur.rootPosError===true&&
													<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
													width={25} height={25} src={cross} alt='wrong'/>
												}
												{cur.rootPosError==='?'&&
													<ImageFadeIn opacityTransition={3} style={{float:'right'}} 
													width={25} height={25} src={question} alt='empty'/>
												}
											</label>
											<Select
												backspaceRemovesValue
												name="rootPos"
												id={`"rootPos"${index}`}
												aria-describedby={`"rootPosError"${index}`}

												options={cur.rootPosOptions}

												placeholder={getPlaceholder(cur.rootPos, "Fifth", cur.rootPosOptions)}
												// selectValue ={cur.rootPos}
												onChange={(selectedOption) => handleSelectChange(index, "rootPos", selectedOption )}

												// menuPortalTarget={document.querySelector("body")}
											/>
										</div>
									</>
								}

								<Button variant='outline-primary' size='sm' style={{'float':'right', 'marginTop':'5px'}}
									onClick={e=>check(index, 'Box', cur.xmlid)}>Check
								</Button>

							</Box>
						))}
					</div>
				</div>
				
				<div className="KeepRight">
					<Button variant='primary' 
						onClick={()=>check(0, 'All', curQ.xmlid)}>Check all
					</Button>
				</div>

			</>
			}
		</div>
	);
};

export default VerbIdentificaitonDrill;
